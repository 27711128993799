import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import { pricetag } from "ionicons/icons";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import { formatPrice } from "../../../../../helpingFunctions/formatPrice";
import { useTheme } from "../../../../../hooks/useTheme";

const TreatYourselfSelectedDetailsCardDesktop = ({ treatDetails }) => {
  const { typography } = useTheme();
  return (
    <IonRow style={{ height: "250px" }} className="ion-align-items-center">
      <IonCol size={6} style={{ height: `250px` }}>
        <BLImageCarousel
          content={treatDetails.Image}
          borderRadius="5px 0px 0px 5px"
        />
      </IonCol>
      <IonCol
        style={{
          height: `250px`,
          overflow: "hidden",
        }}
      >
        <BLContentCardWrapper
          dimensions={{ padding: ["s"] }}
          newStyles={{
            height: "100%",
            overflow: "auto",
            overflowX: "hidden",
            width: "100%",
          }}
        >
          <IonGrid>
            <IonRow>
              <IonCol className="bl-flex ion-align-items-center">
                <BLParagraph
                  color="primary"
                  txt={treatDetails.Title}
                  textVariant={typography.h2}
                  // newClasses={`x-large bold crimson-pro`} 
                  //TODO: Unclear typography
                />
              </IonCol>
            </IonRow>
            <IonRow className="bl-margin-t-s bl-margin-b-m">
              <IonCol
                className="bl-flex ion-align-items-center ion-justify-content-start"
                style={{ gap: "5px" }}
              >
                <BLIcon color="primary" iconSource={pricetag} />
                <BLParagraph
                  txt={formatPrice(treatDetails.Price)}
                  textVariant={typography.BODY_TEXT}
                  color="text-grey"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <BLParagraph
                  color="text-grey"
                  txt={treatDetails.Description}
                  textVariant={typography.BODY_TEXT}
                  markDown
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </BLContentCardWrapper>
      </IonCol>
    </IonRow>
  );
};

export default TreatYourselfSelectedDetailsCardDesktop;
