import React from "react";
import BLParagraph from "../shared/Ionic/Paragraph/BLParagraph";
import { formatPrice } from "./formatPrice";
import {
  MAESTRO_BACKEND_NAMING_VIEWS,
  SERVICES_TYPES,
} from "../constants/constants";

export const getTotalPriceSummaryText = (
  source,
  singleUnitPrice,
  selections,
  view,
  isProcessingBookings,
  typography
) => {
  let count = 0;

  selections.forEach((sel) => {
    if (source === "confirmationScreen") {
      if (view === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
        count += sel.on.filter(
          (row) => row.action !== "remove" && row.Status === "success"
        ).length;
      } else if (view === SERVICES_TYPES.TREATS) {
        sel.options.forEach((row) => {
          if (!["remove", "none"].includes(row.action)) {
            if (
              isProcessingBookings ||
              (!isProcessingBookings && row.Status === "success")
            ) {
              count += row.quantity;
            }
          }
        });
      } else {
        sel.on.forEach((row) => {
          if (row.action !== "remove" && row.Status === "success") {
            count += row.quantity;
          }
        });
      }
    } else {
      if (view === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
        count += sel.on.filter((row) => row.action !== "remove").length;
      } else if (view === SERVICES_TYPES.TREATS) {
        sel.options.forEach((row) => {
          if (row.action !== "remove") {
            count += row.quantity;
          }
        });
      } else {
        sel.on.forEach((row) => {
          if (row.action !== "remove") {
            count += row.quantity;
          }
        });
      }
    }
  });

  return singleUnitPrice === "Inclusive" ? (
    <BLParagraph
      txt="Inclusive"
      textVariant={typography?.h4}
      color="text-grey"
      dimensions={{
        margin: ["l-s"],
        padding: ["t-md", "b-md"],
      }}
    />
  ) : (
    <BLParagraph
      txt={`${formatPrice(
        count * Number(singleUnitPrice)
      )} (${count} x ${formatPrice(singleUnitPrice)})`}
      textVariant={typography?.h4}
      color="text-grey"
      dimensions={{
        margin: ["l-s"],
        padding: ["t-md", "b-md"],
      }}
    />
  );
};
