import React, { createContext, useState } from "react";
import { ACTION_TYPES, MAESTRO_BACKEND_NAMING_VIEWS, SERVICES_TYPES } from "../constants/constants";
import { useDataContext } from "./DataContext";

import ChainMethodsLogic from "../helpingFunctions/conflictManagement/conflictChainingMethods/ChainMethodsLogic";
import { CONFLICT_ACTIVITIES_TYPES, CONFLICT_ALL_TYPES, CONFLICT_INCLUDED_TYPES, CONFLICT_SERVICES_TYPES, DATE_TYPES, FLOW_EVALUATION_PLANS, ID_TYPES, TIME_TYPES } from "../helpingFunctions/conflictManagement/conflictConstants";
import { combineMultiplePersonSpaBookings, conflictSpaSamePersonDurationOverlapValidation } from "../helpingFunctions/conflictManagement/evaluationMethods/specialEdgeEvaluationMethods";
import { conflictExceededQuantityValidation, conflictOverlapingPeriodValidation, conflictSameDaySameTimeValidation } from "../helpingFunctions/conflictManagement/evaluationMethods/basicEvaluationMethods";
import { getEndTime, getSimplifiedSelectionObj } from "../helpingFunctions/conflictManagement/globalConflictHelpersMethods";
import { prepareAverageConflictObj, prepareSoftConflictObj, prepareStrongConflictObj } from "../helpingFunctions/conflictManagement/namingMethods/namingMethods";
import RequestHook from "../hooks/RequestHook";

export const ConflictManagementContext = createContext({});

// ...selected/selection.. keyword will indicate the selection that is to be booked;
// ..confirmed.. keyword will indicate the selection that is already confirmed in maestro;

export const ConflictManagementProvider = ({
  children,
  conflictOccuringView,
}) => {
  const [prohibitRQToMaestro, setProhibitRQToMaestro] = useState(false);
  const [replacedBookings, setReplacedBookings] = useState([]);
  const [foundConflicts, setFoundConflicts] = useState(null);
  // disableReplaceInChainedSpaView bool val only usage when we have CHAINED type of conflict that is triggered while trying to book a SPA
  const [disableReplaceInChainedSpaView, setDisableReplaceInChainedSpaView] = useState(false);
  // The current conflict we are working with (current opened popup)
  const [spotlitConflict, setSpotlitConflict] = useState(null);
  const {
    reservationDetails,
    itineraryDetails,
    facilitiesDataDetails,
    conflictManagementTexts,
  } = useDataContext();
  const { chainConflictsResult } = ChainMethodsLogic(setDisableReplaceInChainedSpaView);
  const { applyCancellationPolicy } = RequestHook();

  // Due to the different selections structures of Dining and Entertainment compared to Activities and Spa
  const FLOW_EVALUATION_PLAN = [
    MAESTRO_BACKEND_NAMING_VIEWS.SPA,
    MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY,
  ].includes(conflictOccuringView)
    ? FLOW_EVALUATION_PLANS.NESTED
    : FLOW_EVALUATION_PLANS.SINGLE;

  // Due to the different naming of the id property accross different pages
  const idKeyword = [SERVICES_TYPES.BREAKFAST, SERVICES_TYPES.DINNER].includes(
    conflictOccuringView
  )
    ? ID_TYPES.DINING
    : [MAESTRO_BACKEND_NAMING_VIEWS.SPA].includes(conflictOccuringView)
      ? ID_TYPES.SPA
      : [SERVICES_TYPES.ENTERTAINMENT].includes(conflictOccuringView)
        ? ID_TYPES.ENTERTAINMENT
        : [
          MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY,
        ].includes(conflictOccuringView)
          ? ID_TYPES.ACTIVITY
          : [SERVICES_TYPES.ITINERARY].includes(conflictOccuringView)
            ? ID_TYPES.ITINERARY
            : null;

  // Due to the capitalization differences
  const dateKeyWord = [SERVICES_TYPES.ITINERARY].includes(
    conflictOccuringView
  )
    ? DATE_TYPES.ITINERARY
    : DATE_TYPES.NON_ITINERARY;

  const timeKeyWord = [SERVICES_TYPES.ITINERARY].includes(
    conflictOccuringView
  )
    ? TIME_TYPES.ITINERARY
    : TIME_TYPES.NON_ITINERARY;

  const isInItinerary = SERVICES_TYPES.ITINERARY === conflictOccuringView;
  const configObj = {
    datePar: dateKeyWord,
    timePar: timeKeyWord,
    isInItinerary: isInItinerary,
  };

  /** 
    Entry point for identifying conflicts between newly made selections and already booked services
  */
  const findPageConflicts = async (selections) => {
    // Revert state whenever evaluation is triggered
    setProhibitRQToMaestro(false);
    setReplacedBookings([]);

    let conflicts = [];

    const isMultiplePersonsSpaBookingCase =
      selections.length > 1 && conflictOccuringView === MAESTRO_BACKEND_NAMING_VIEWS.SPA;

    if (isMultiplePersonsSpaBookingCase) {
      selections = combineMultiplePersonSpaBookings(
        selections,
        facilitiesDataDetails
      );
    }

    for (const selection of selections) {
      // Skip conflict validation if reservation is to be cancelled or doesnt have any changes
      if (
        [ACTION_TYPES.REMOVE, ACTION_TYPES.NOT_REQ].includes(selection.action)
      ) {
        continue;
      }

      if (idKeyword && selection[idKeyword]) {
        if (
          FLOW_EVALUATION_PLAN === "single" ||
          isMultiplePersonsSpaBookingCase
        ) {
          if ([ACTION_TYPES.NONE].includes(selection.action) &&
            ![SERVICES_TYPES.ITINERARY].includes(conflictOccuringView)) {
            continue;
          }
          const serviceDetails = facilitiesDataDetails.find(
            (row) => row.id === selection[idKeyword]
          );
          if (!serviceDetails) continue;

          const simplifiedSelectionObj = getSimplifiedSelectionObj(
            selection,
            serviceDetails,
            configObj
          );

          const bookedDayRecords =
            JSON.parse(JSON.stringify(itineraryDetails.Dates[simplifiedSelectionObj.date].All))
              .filter((record) => record.Type !== MAESTRO_BACKEND_NAMING_VIEWS.TREAT);

          const confirmedConflicts = handleConflictValidation(
            serviceDetails,
            simplifiedSelectionObj,
            bookedDayRecords
          );

          const foundConflicts = await prepareConflictsResult(
            confirmedConflicts,
            {
              ...selection,
              title: simplifiedSelectionObj.title,
              singleUnitPrice: simplifiedSelectionObj.singleUnitPrice,
              Quantity: simplifiedSelectionObj.quantity,
            }
          );

          // If we are in itinerary - find and extract conflict messages and return the result for itinerary update
          if ([SERVICES_TYPES.ITINERARY].includes(conflictOccuringView)) {
            return foundConflicts;
          }

          if (foundConflicts) {
            conflicts = [...conflicts, foundConflicts];
          }
        } else {
          const serviceDetails = facilitiesDataDetails.find(
            (row) => row.id === selection[idKeyword]
          );
          if (!serviceDetails) continue;

          for (const dateTimeSel of selection.on) {
            // Skip conflict validation if reservation is to be cancelled
            if (
              [
                ACTION_TYPES.NONE,
                ACTION_TYPES.REMOVE,
                ACTION_TYPES.NOT_REQ,
              ].includes(dateTimeSel.action)
            ) {
              continue;
            }

            const simplifiedSelectionObj = getSimplifiedSelectionObj(
              {
                ...dateTimeSel,
                FirstName: selection.fName,
                LastName: selection.lName,
              },
              serviceDetails,
              configObj
            );

            const bookedDayRecords =
              JSON.parse(JSON.stringify(itineraryDetails.Dates[simplifiedSelectionObj.date].All))
                .filter((record) => record.Type !== MAESTRO_BACKEND_NAMING_VIEWS.TREAT);

            const confirmedConflicts = handleConflictValidation(
              serviceDetails,
              simplifiedSelectionObj,
              bookedDayRecords
            );

            const foundConflicts = await prepareConflictsResult(
              confirmedConflicts,
              {
                FirstName: selection.fName,
                LastName: selection.lName,
                Quantity: simplifiedSelectionObj.quantity,
                action: dateTimeSel.action,
                date: dateTimeSel.date,
                activityId: selection.activityId,
                time: dateTimeSel.time,
                title: simplifiedSelectionObj.title,
                singleUnitPrice: simplifiedSelectionObj.singleUnitPrice,
              }
            );

            if (foundConflicts) {
              conflicts = [...conflicts, foundConflicts];
            }
          }
        }
      } else {
        if ([ACTION_TYPES.NONE].includes(selection.action)) {
          continue;
        }
        console.error(
          `Invalid id: Keyword: ${idKeyword}, Source type: ${conflictOccuringView}`
        );
      }
    }

    setFoundConflicts(conflicts);
  };

  /** 
    Method for handling distribution of data between different evaluation scenarios
  */
  const handleConflictValidation = (
    selectedServiceDetails,
    selectionDetails,
    bookedDayRecords
  ) => {
    let conflicts = [];

    /*
      Step 1 (MANDATORY): Validate if the newly made selection is conflicting by same day and same time rule
      EXAMPLE:
        If the selection start time equals the booked start time
    */
    const sameDaySameTimeBookedRecords = conflictSameDaySameTimeValidation(
      selectionDetails,
      bookedDayRecords,
      conflictOccuringView
    );

    /*
      Step 2 (MANDATORY): Validate if the newly made selection is conflicting by its start or end time overlaping with the duration of the booked item 
      -OR-
      if the booked item start or end time is overlaping with the duration of the newly made selection
      EXAMPLE: 
        We have reservation with 2 Adults and they have booked: 
          case 1: activity A between 08 AM and 09 AM for 1 person and activity B between 08:30 AM and 09:30 AM for 1 person
          case 2: activity A between 08 AM and 09 AM for 2 persons and they try to book something else for the same time period

        MORE CASE ARE AVAILABLE
    */
    const overlapingBookedRecords = conflictOverlapingPeriodValidation(
      selectedServiceDetails,
      selectionDetails,
      bookedDayRecords,
      facilitiesDataDetails,
      conflictOccuringView
    );

    const allBookedRecords = [
      ...new Set([...overlapingBookedRecords, ...sameDaySameTimeBookedRecords]),
    ];

    /*
      Step 3 (MANDATORY):  If overlaping conflicts exist, additionally check if any of them are overlapping in total quantity as well
      EXAMPLE: 
        We have reservation with 2 Adults and they have booked activity A between 08 AM and 09 AM for 1 person and activity B between 08:30 AM and 09:30 AM for 1 person
          case 1: If they try to book another activity/spa between 08:30 AM and 09 AM for 1 or more persons - a conflict should be found -> (1 + 1 + N) > reservationDetails.Adults
          case 2: If they try to book breakfast between 08 AM and 09:30 AM - a conflict should be found -> (1 + reservationDetails.Adults) > reservationDetails.Adults 

        MORE CASE ARE AVAILABLE
    */
    if (allBookedRecords.length > 0) {
      const confirmedRestConflictsBookings = allBookedRecords.filter(
        (row) => row.Type !== MAESTRO_BACKEND_NAMING_VIEWS.SPA
      );

      const bookingRecords =
      MAESTRO_BACKEND_NAMING_VIEWS.SPA === conflictOccuringView
        ? confirmedRestConflictsBookings
        : allBookedRecords;

      const confirmedConflicts = conflictExceededQuantityValidation(
        bookingRecords,
        selectionDetails,
        selectedServiceDetails,
        facilitiesDataDetails,
        reservationDetails,
        CONFLICT_SERVICES_TYPES.includes(conflictOccuringView)
      );

      /*
        Step 4 (CONDITIONAL):  If the user is trying to book a spa service,
        check if any of the found conlicts are with a booked spa service and
        if the selected spa is conflicting with a booked spa - validate if the names are matching
        
        Conflict goal - The same person can NOT have 2 spa bookings with overlaping duration
      */
      if (
        MAESTRO_BACKEND_NAMING_VIEWS.SPA === conflictOccuringView ||
        SERVICES_TYPES.ITINERARY === conflictOccuringView
      ) {
        const confirmedSpaConflictsBookings = allBookedRecords.filter(
          (row) => row.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA
        );

        if (confirmedSpaConflictsBookings.length > 0) {
          const confirmedSpaConflicts =
            conflictSpaSamePersonDurationOverlapValidation(
              confirmedSpaConflictsBookings,
              selectionDetails,
              selectedServiceDetails,
              facilitiesDataDetails
            );

          conflicts = [...conflicts, ...confirmedSpaConflicts];
        }
      }

      conflicts = [...conflicts, ...confirmedConflicts];
    }

    // TODO Step 5: Evaluate special and edge cases

    return [...new Set(conflicts)];
  };

  /** 
    Method for typization, naming and preparation of the final version of the evaluated conflicts
  */
  const prepareConflictsResult = async (conflicts, selection) => {
    const conflictedSelection = {
      selectionDate: selection[configObj.datePar],
      selectionTime: selection[configObj.timePar],
      selectionAction: selection.action,
      selectionQuantity: selection.Quantity,
      selectionFirstName: selection.FirstName,
      selectionLastName: selection.LastName,
      selectionTitle: selection.title,

      conflicts: [],
    };

    //Conflicts, applicable for a cancellation policy will have a property "cancellationPolicyMessage" containing policy params
    const conflictsWithAppliedCancellationPolicy = conflicts.some(
      (conflict) =>
        CONFLICT_SERVICES_TYPES.includes(conflict.Type) && !isInItinerary
    )
      ? await applyCancellationPolicy(conflicts)
      : conflicts;

    conflictsWithAppliedCancellationPolicy.forEach((confirmed) => {
      switch (conflictOccuringView) {
        // Trying to book ...
        case SERVICES_TYPES.BREAKFAST:
        case SERVICES_TYPES.DINNER:
        case SERVICES_TYPES.ENTERTAINMENT:
          // ...when I have
          if (CONFLICT_ALL_TYPES.includes(confirmed.Type)) {
            prepareSoftConflictObj(
              confirmed,
              selection,
              conflictedSelection,
              conflictManagementTexts,
              reservationDetails,
              configObj
            );
          }
          break;

        // TODO - when done, check if the below cases can be combined (same code)
        // Trying to book ...
        case MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY:
        case MAESTRO_BACKEND_NAMING_VIEWS.SPA:
          // ...when I have

          if (
            CONFLICT_SERVICES_TYPES.includes(confirmed.Type) &&
            confirmed.cancellationPolicyMessage
          ) {
            prepareStrongConflictObj(
              confirmed,
              selection,
              conflictedSelection,
              conflictManagementTexts,
              reservationDetails,
              configObj
            );
            break;
          }

          if (CONFLICT_INCLUDED_TYPES.includes(confirmed.Type)) {
            prepareSoftConflictObj(
              confirmed,
              selection,
              conflictedSelection,
              conflictManagementTexts,
              reservationDetails,
              configObj
            );
          }

          if (CONFLICT_SERVICES_TYPES.includes(confirmed.Type)) {
            prepareAverageConflictObj(
              confirmed,
              selection,
              conflictedSelection,
              conflictManagementTexts,
              reservationDetails,
              configObj
            );
          }
          break;

        case SERVICES_TYPES.ITINERARY:
          if (CONFLICT_ALL_TYPES.includes(confirmed.Type)) {
            prepareSoftConflictObj(
              confirmed,
              selection,
              conflictedSelection,
              conflictManagementTexts,
              reservationDetails,
              configObj
            );
          }
          break;

        default:
          break;
      }
    });

    if (conflictedSelection.conflicts.length > 0) {
      const adjustedConflictObj = isInItinerary
        ? conflictedSelection
        : chainConflictsResult(
          conflictedSelection,
          reservationDetails,
          selection,
          configObj,
          conflictOccuringView === MAESTRO_BACKEND_NAMING_VIEWS.SPA
        );

      adjustedConflictObj.conflicts.sort(
        (a, b) => a.conflictPriority - b.conflictPriority
      );

      return adjustedConflictObj;
    }
  };

  /** 
    Entry point for identifying conflicts between items in itinerary
    TODO
  */
  const findItineraryConflicts = async () => {
    const shouldSkipSpaConflict = (confirmed, booking) => {
      const conflictingSpaFullName = confirmed.isParticipant
        ? `${confirmed.ParticipantFirstName} ${confirmed.ParticipantLastName}`
        : `${confirmed.FirstName} ${confirmed.LastName}`;
      const bookingFullName = booking.isParticipant
        ? `${booking.ParticipantFirstName} ${booking.ParticipantLastName}`
        : `${booking.FirstName} ${booking.LastName}`;

      const areSpas =
        booking.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA &&
        confirmed.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA;

      if (conflictingSpaFullName !== bookingFullName) {
        return areSpas && conflictingSpaFullName !== bookingFullName;
      } else {
        if (areSpas) {
          const currentConflictStartTime = booking.Time.substring(0, 5);
          const currentConflictEndTime = getEndTime(
            booking.Time.substring(0, 5),
            booking.Duration
          );
          const otherConflictStartTime = confirmed.Time.substring(0, 5);
          const otherConflictEndTime = getEndTime(
            confirmed.Time.substring(0, 5),
            confirmed.Duration
          );

          if (
            otherConflictEndTime.substring(0, 5) ===
            currentConflictStartTime.substring(0, 5) ||
            otherConflictStartTime.substring(0, 5) ===
            currentConflictEndTime.substring(0, 5)
          ) {
            return true;
          } else {
            return conflictingSpaFullName !== bookingFullName;
          }
        }
      }
    };

    const areActivityBookingsLessThanReservation = (confirmed, booking) => {
      return (
        CONFLICT_ACTIVITIES_TYPES.includes(booking.Type) &&
        CONFLICT_SERVICES_TYPES.includes(confirmed.Type) &&
        Number(booking.Quantity) + Number(confirmed.Quantity) <=
          Number(reservationDetails.Adults)
      );
    };

    const itinerary = JSON.parse(JSON.stringify(itineraryDetails));
    for (const date of Object.keys(itinerary.Dates)) {
      for (const booking of itinerary.Dates[date].All) {
        if (![MAESTRO_BACKEND_NAMING_VIEWS.TREAT].includes(booking.Type)) {
          booking.conflicts = [];
          const foundConflicts = await findPageConflicts([booking]);
          foundConflicts?.conflicts?.forEach((conflict) => {
            const confirmed = conflict.confirmedBookingDetails;
            // Skip if spa names are different OR if spa names are the same but overlaping by the same start/end time
            if (shouldSkipSpaConflict(confirmed, booking)) {
              return;
            }
            //Skip if activity bookings number of people are less than reservation adults
            if (areActivityBookingsLessThanReservation(confirmed, booking)) {
              return;
            }

            booking.conflicts.push(conflict.conflictMsg);
          });
        }
      }
    }

    return itinerary;
  };

  const loadNextConflict = (conflictObjs) => {
    conflictObjs.forEach((obj) => {
      const hasUnresolvedConf = obj.conflicts.find((row) => !row.isResolved);

      if (hasUnresolvedConf) {
        setSpotlitConflict({
          ...obj.conflicts.find((row) => !row.isResolved),
          selFirstName: obj.selectionFirstName,
          selLastName: obj.selectionLastName,
        });
        return;
      }
    });
  };

  return (
    <ConflictManagementContext.Provider
      value={{
        spotlitConflict,
        foundConflicts,
        prohibitRQToMaestro,
        replacedBookings,
        disableReplaceInChainedSpaView,
        setReplacedBookings,
        setProhibitRQToMaestro,
        setSpotlitConflict,
        setFoundConflicts,
        findPageConflicts,
        findItineraryConflicts,
        loadNextConflict,
      }}
    >
      {children}
    </ConflictManagementContext.Provider>
  );
};
