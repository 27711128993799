import { useEffect, useState } from "react";
import axiosInstace from "../../../url/createAxios";
import { constructArrayOutOfGrouping, orderByValue } from "../../../helpingFunctions/arrays";
import { checkForMatch } from "../../../helpingFunctions/helpingFunctions";

const HelpPageLogic = () => {
  const instance = axiosInstace();
  const [isLoading, setIsLoading] = useState(true);
  const [videoSectionData, setVideoSectionData] = useState({});
  const [faqData, setFaqData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const getVideoSectionData = async () => {
    const { data } = await instance.get("/pages-configuration/help-page", {withCredentials: true});

    return data;
  };

  const getFaqData = async () => {
    const { data } = await instance.get("/faqs", {withCredentials: true});

    const orderedData = orderByValue(data, "RowNumber");
    const groupedByCategory = constructArrayOutOfGrouping(
      orderedData,
      ["Category"],
      ["Category", "CategoryImage"],
      "Category"
    );

    return groupedByCategory;
  };

  const getData = async () => {
    const videoSection = await getVideoSectionData();
    const faq = await getFaqData();

    setVideoSectionData(videoSection);
    setFaqData(faq);
    setFilteredData(faq);
    setIsLoading(false);
  };

  const manageSearch = async (e = null, value) => {
    try {
      e?.preventDefault();
      if (!value) {
        setFilteredData(faqData);
        return;
      }

      const filtered = faqData
        .map(({ Category, CategoryImage, objects }) => {
          const value_split = value.split(" ");
        
          const matches = objects.filter(({ Question, Keywords }) => {
            const isInQuestion = Question.toLowerCase().includes(
              value.toLowerCase()
            );
            const isInKeyword = Keywords?.filter(({ Title }) =>
              value_split.find((value_word) =>
                checkForMatch(Title, value_word, 2, 0)
              )
            );

            return isInQuestion || isInKeyword?.length > 0;
          });

          const categoryMatch = value_split.some((value_word) => {
            if (!Category) return;

            return checkForMatch(Category, value_word, 2, 0);
          });

          if (categoryMatch) {
            return {
              Category,
              CategoryImage,
              objects,
            };
          }

          if (matches.length > 0) {
            return {
              Category,
              CategoryImage,
              objects: matches,
            };
          }
        })
        .filter((i) => i);

      setFilteredData(orderByValue(filtered, "RowNumber"));
    } catch (e) {
      console.error(
        `${new Date()} Error in manageSearch func in HelpPageLogic.js file ${e}`
      );
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    filteredData,
    videoSectionData,
    isLoading,
    manageSearch,
  };
};

export default HelpPageLogic;
