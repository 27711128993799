import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import { pricetag } from "ionicons/icons";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import { formatPrice } from "../../../../../helpingFunctions/formatPrice";
import { useTheme } from "../../../../../hooks/useTheme";

const TreatYourselfSelectedDetailsCardMobile = ({ treatDetails }) => {
  const { typography } = useTheme();
  return (
    <>
      <IonRow className="ion-align-items-center">
        <IonCol style={{ height: "137px" }}>
          <BLImageCarousel
            content={treatDetails.Image}
            borderRadius="5px 5px 0px 0px"
          />
        </IonCol>
      </IonRow>

      <IonRow className="bl-margin-b-s">
        <IonCol>
          <IonGrid
            className="bl-padding-xs"
            style={{
              background: "#F2F2F4",
            }}
          >
            <IonRow className="ion-text-center">
              <IonCol>
                <BLParagraph
                  color="primary"
                  txt={treatDetails.Title}
                  textVariant={typography.h2}
                  // newClasses={`x-large bold crimson-pro`}
                  //TODO: Unclear typography
                />
              </IonCol>
            </IonRow>
            <IonRow className="bl-margin-t-xs bl-margin-b-xs">
              <IonCol
                className="bl-flex ion-align-items-center ion-justify-content-start"
                style={{ gap: "5px" }}
              >
                <BLIcon color="primary" iconSource={pricetag} />
                <BLParagraph
                  txt={formatPrice(treatDetails.Price)}
                  textVariant={typography.BODY_TEXT}
                  color="text-grey"
                />
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                height: "120px",
                overflow: "hidden auto",
              }}
            >
              <IonCol>
                <BLParagraph
                  color="text-grey"
                  txt={treatDetails.Description}
                  textVariant={typography.BODY_TEXT}
                  markDown
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCol>
      </IonRow>
    </>
  );
};

export default TreatYourselfSelectedDetailsCardMobile;
