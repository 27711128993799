import React from "react";

import { handleNullDisplay } from "../../../../../helpingFunctions/helpingFunctions";
import Heading from "../../../../../shared/Heading_OLD/Heading";
import ImageCarousel from "../../../../../shared/ImageCarousel_OLD/ImageCarousel";
import InformationText from "../../../../../shared/InformationText_OLD/InformationText";
import BookActivity from "../BookActivity/BookActivity";
import { durationLabel, expand_collapse } from "../shared/helpingFunctions";

import { formatTime, getCurrentDate } from "../../../../../helpingFunctions/dateTimeUtilities";
import { ALL_DAY_ACTIVITY } from "../../constants/constants";
import styles from "./GuideItemMobile.module.scss";
import { useContext } from "react";
import { WhatsOnDataContext } from "../../../../../context/WhatsOnDataContext";

const GuideItemMobile = ({
  selectedItemId,
  setSelectedItemId,
  itemIndex,
  setView,
  guideItem,
  internalActivityItinerary,
  internalActivityItineraryHandler
}) => {
  const {
    Description,
    Location,
    Price,
    Title,
    Images,
    activityId,
    time,
    duration,
    StartTime,
    EndTime
  } = guideItem;
  const { selectedDate } = useContext(WhatsOnDataContext);
  const isAllDayActivity = ALL_DAY_ACTIVITY === guideItem.Type;
  const id = `${activityId}_${itemIndex}_${selectedDate}`;
  const isClicked = selectedItemId === id;

  return (
    <article
      className={`${styles.GuideItem} ${
        isClicked ? styles.GuideItem_opened : ""
      }`}
      onClick={expand_collapse.bind(this, setSelectedItemId, id)}
    >
      <section
        className={styles.GuideItem__imgWrapper}
        onClick={(e) => e.stopPropagation()}
      >
        <ImageCarousel sliderImages={Images} view={"WhatsOnGuide_Card"} />
      </section>
      <section className={styles.GuideItem__details}>
        <img
          className={styles.GuideItem__dropDown_closed}
          src="/img/drop-down-arrow_1.svg"
        />
        <Heading
          txt={Title}
          customHeadingClass={styles.GuideItem__heading}
        />
        <ul
          className={styles.GuideItem__details_list}
          style={{ flexDirection: "column" }}
        >
          <li>
            <strong>Price:</strong> {handleNullDisplay(Price, "£", "Inclusive")}
          </li>
          <li>
            <strong>Start:</strong>{" "}
            {isAllDayActivity ? formatTime(`${getCurrentDate()} ${StartTime}`) : time}
          </li>
          <li>
            <strong>{isAllDayActivity ? "End:" : "Duration:"}</strong>{" "}
            {isAllDayActivity
              ? formatTime(`${getCurrentDate()} ${EndTime}`)
              : durationLabel(duration)}
          </li>
          <li>
            <strong>Location:</strong>{" "}
            {isAllDayActivity ? Location : Location?.locationName}
          </li>
        </ul>
        <div className={styles.expandShow}>
          <InformationText
            content={Description}
            className={styles.GuideItem__informationText}
          />
          <div className={styles.GuideItem__expandEnding}>
            <img
              className={styles.GuideItem__dropDown_opened}
              src="/img/drop-down-arrow_1.svg"
            />
          </div>
          {!isAllDayActivity && (
            <BookActivity
              guideItem={guideItem}
              isInternalActivity={activityId?.includes("internalAct_facility")}
              setView={setView}
              internalActivityItinerary={internalActivityItinerary}
              internalActivityItineraryHandler={
                internalActivityItineraryHandler
              }
            />
          )}
        </div>
      </section>
    </article>
  );
};

export default GuideItemMobile;
