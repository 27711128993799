import React from "react";
import { AUTHENTICATED_KEY } from "../../constants/constants";
import MainWrapper from "../MainWrapper";
import { isDesktop } from "../../helpingFunctions/utilities";

const PlatformDependentPage = ({ children }) => {
  const isLogedIn = localStorage.getItem(AUTHENTICATED_KEY);

  return isDesktop() && isLogedIn ? <MainWrapper>{children}</MainWrapper> : children;
};

export default PlatformDependentPage;
