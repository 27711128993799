import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch";
import BLIcon from "../BLIcon";
import { addCircle, removeCircle } from "ionicons/icons";

const ZoomImageContainer = ({ image, containerHeight, maxHeight, height }) => {
  const controlsRef = useRef(null);
  const [controlsHeight, setControlsHeight] = useState(null);

  const resizeObserver = new ResizeObserver((entries) => {
    setControlsHeight(entries[0].target.clientHeight);
  });

  useEffect(() => {
    resizeObserver.observe(controlsRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const Controls = () => {
    const { zoomIn, zoomOut } = useControls();
    return (
      <>
        <BLIcon
          iconSource={addCircle}
          iconSize="md"
          color="secondary"
          onClick={() => zoomIn()}
        />
        <BLIcon
          className="bl-padding-l-s"
          iconSource={removeCircle}
          iconSize="md"
          color="secondary"
          onClick={() => zoomOut()}
        />
      </>
    );
  };
  return (
    <TransformWrapper centerZoomedOut>
      <>
        <TransformComponent
          wrapperStyle={{
            height: height
              ? height
              : `calc(${
                containerHeight ? `${containerHeight}px` : "100%"
              } - ${controlsHeight}px)`,
            maxHeight: maxHeight,
            width: "100%",
          }}
          contentStyle={{ width: "unset"}}
        >
          <img src={image} alt="" height={"100%"} width={"100%"} />
        </TransformComponent>
        <IonRow
          id="controls"
          ref={controlsRef}
          className="bl-flex bl-padding-t-s ion-justify-content-end"
        >
          <IonCol
            size="auto"
            className="ion-justify-content-end bl-margin-b-s bl-margin-r-s"
            style={{
              borderRadius: 10,
              background: "var(--ion-color-light)",
            }}
          >
            <IonGrid
              className="bl-flex ion-justify-content-center ion-align-items-center"
              style={{ padding: "8px 16px" }}
            >
              <Controls />
            </IonGrid>
          </IonCol>
        </IonRow>
      </>
    </TransformWrapper>
  );
};

export default ZoomImageContainer;
