import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { NavigatorContext } from "../../../../../context/NavigatorContext";

import {
  ITEM_POPULAR_STATUSES, SERVICES_TYPES,
} from "../../../../../constants/constants";
import { ConflictManagementContext } from "../../../../../context/ConflictManagementContext";
import { useDataContext } from "../../../../../context/DataContext";
import { getCurrentDate, getDatesBetween, getDayOfWeek } from "../../../../../helpingFunctions/dateTimeUtilities";
import { ROUTE_VIEWS } from "../../../../../routes/routeViews";
import {
  BOOKING_SELECTIONS_BACK,
  BOOKING_SELECTIONS_CANCEL,
  BOOKING_SELECTIONS_CHANGE,
  BOOKING_SELECTIONS_CLOSE,
  BOOKING_SELECTIONS_CONFIRM,
  BOOKING_SELECTIONS_CONTINUE,
  BOOKING_SELECTIONS_GO_BACK,
  BOOKING_SELECTIONS_ITINERARY,
} from "../../../DiningBookingPage/DiningBookingConstants";
import { EntertainmentsPageContext } from "../../EntertainmentsPageContext";
import { isDesktop } from "../../../../../helpingFunctions/utilities";

const StayNavigatorLogic = () => {
  const { redirectBetweenPages, redirectToItinerary } = useContext(NavigatorContext);
  const { reservationDetails, isLoadingItinerary, isLoadingAvailability } = useDataContext();
  const {
    entertainmentBookingSelections,
    handleSelectedDateSelections,
    skippedDays,
    selectedDate,
    setSelectedDate,
    mobileDaySlider,
    mainStatus,
    setMainStatus,
    prepareSelectionObjects,
    handleBooking,
    setClickedRow,
  } = useContext(EntertainmentsPageContext);
  const { findPageConflicts } = useContext(ConflictManagementContext);

  const history = useHistory();

  const [navButtons, setNavButtons] = useState(null);

  const datesList = getDatesBetween(
    reservationDetails.ArrivalDate,
    reservationDetails.DepartureDate,
    SERVICES_TYPES.ENTERTAINMENT
  );

  const getNearItems = () => {
    try {
      const date = selectedDate;
      const currentDayBookingIndex = datesList.findIndex(
        (bookingSelection) => bookingSelection === date
      );

      const nextItem = datesList[currentDayBookingIndex + 1];
      const prevItem = datesList[currentDayBookingIndex - 1];

      if (nextItem && prevItem) {
        return {
          type: "bothSides",
          items: [prevItem, nextItem],
        };
      }

      return nextItem
        ? {
          type: "next",
          items: [nextItem],
        }
        : {
          type: "prev",
          items: [prevItem],
        };
    } catch (error) {
      console.error(
        `${new Date()} Error in getNearItems func in StayNavigatorLogic.js ${error.message}`
      );
    }
  };

  const getActionBtnDisabilityStatus = (isLastDay) => {
    if (isLoadingAvailability) {
      return true;
    }

    if (isLastDay && reservationDetails.Venue) {
      return !entertainmentBookingSelections.find(
        (booking) => booking.action !== "none"
      );
    }
    if (reservationDetails.Venue) {
      return false;
    }
    return (
      !entertainmentBookingSelections.find(
        (booking) => booking.date === selectedDate
      ) && !skippedDays.includes(selectedDate)
    );
  };

  const entertainmentButtons = () => {
    try {
      if (!selectedDate && !reservationDetails.Venue && !entertainmentBookingSelections?.length && !skippedDays.length) {
        setNavButtons([
          {
            txt: BOOKING_SELECTIONS_GO_BACK,
            type: "underlined",
            onClick: () => redirectBetweenPages(ROUTE_VIEWS.THINGS_TO_DO),
            disabled: false,
            dimensions: {},
          },
        ]);
        return;
      }

      if (reservationDetails.Nights === 1) {
        setNavButtons([
          {
            txt: BOOKING_SELECTIONS_BACK,
            type: "outlined",
            onClick: () => redirectBetweenPages(ROUTE_VIEWS.LANDING_PAGE),
            disabled: false,
            dimensions: {},
          },
          {
            txt: BOOKING_SELECTIONS_CONTINUE,
            onClick: () => {
              setMainStatus(ITEM_POPULAR_STATUSES.PENDING);
              setSelectedDate("");
            },
            type: "primary",
            disabled: getActionBtnDisabilityStatus(true),
            dimensions: { margin: ["l-s"] },
          },
        ]);
      }

      switch (mainStatus) {
        case ITEM_POPULAR_STATUSES.INIT: {
          if (reservationDetails.Venue) {
            setNavButtons([
              {
                txt: BOOKING_SELECTIONS_GO_BACK,
                type: "underlined",
                onClick: () => redirectBetweenPages(ROUTE_VIEWS.THINGS_TO_DO),
                disabled: false,
                dimensions: {},
              },
              {
                txt: BOOKING_SELECTIONS_CHANGE,
                type: "icon-text",
                onClick: () => setMainStatus(ITEM_POPULAR_STATUSES.EDITING),
                disabled: isLoadingItinerary,
                dimensions: {},
              },
            ]);
            return;
          }
          break;
        }
        case ITEM_POPULAR_STATUSES.SUCCESS:
          {
            const buttons = [
              {
                txt: BOOKING_SELECTIONS_CHANGE,
                type: "icon-text",
                onClick: () => {
                  setClickedRow("daysRow");
                  setMainStatus(ITEM_POPULAR_STATUSES.EDITING);
                },
                disabled: isLoadingItinerary,
                dimensions: {},
              },
            ];
            if (
              entertainmentBookingSelections.findIndex(
                (booking) => booking.status === ITEM_POPULAR_STATUSES.FAILURE
              ) === -1
            ) {
              buttons.push({
                txt: BOOKING_SELECTIONS_ITINERARY,
                onClick: () => redirectToItinerary(),
                classes: "primary primary-highlighted",
                disabled: false,
                dimensions: { margin: ["l-s"] },
              });
            } else {
              buttons.push({
                txt: "Retry failed bookings",
                onClick: () => handleBooking(true),
                type: "icon-text",
                disabled: false,
              });
            }
            setNavButtons(buttons);
          }
          return;
        case ITEM_POPULAR_STATUSES.PENDING:
          setNavButtons([
            {
              txt: BOOKING_SELECTIONS_CANCEL,
              type: "underlined",
              onClick: () => {
                prepareSelectionObjects();
                const currentDate = getCurrentDate();
                const firstDate = datesList[0];
                const firstFutureDate = firstDate > currentDate ? firstDate : currentDate;
                setSelectedDate(firstFutureDate);
                setMainStatus(ITEM_POPULAR_STATUSES.INIT);
              },
              disabled: false,
              dimensions: {},
            },
            {
              txt: BOOKING_SELECTIONS_CONFIRM,
              type: "primary",
              onClick: () => {
                // When the user hits 'Confirm' on the preview screen, first step would be to evaluate for conflicts
                // Whether there are conflicts or not, the 'handleBooking()' method will be called via useEffect in the EntertainmentPageContext
                findPageConflicts(entertainmentBookingSelections);
                // handleBooking();
              },
              disabled: false,
              dimensions: { margin: ["l-s"] },
            },
          ]);
          return;
        case ITEM_POPULAR_STATUSES.LOAD:
          setNavButtons([
            {
              txt: BOOKING_SELECTIONS_CANCEL,
              type: "underlined",
              onClick: null,
              disabled: true,
              dimensions: {},
            },
            {
              txt: BOOKING_SELECTIONS_CONFIRM,
              type: "primary",
              onClick: null,
              disabled: true,
              dimensions: { margin: ["l-s"] },
            },
          ]);
          return;
        case ITEM_POPULAR_STATUSES.FAILURE:
          setNavButtons([
            {
              txt: BOOKING_SELECTIONS_CLOSE,
              type: "underlined",
              onClick: () => redirectBetweenPages(ROUTE_VIEWS.LANDING_PAGE),
              disabled: false,
              dimensions: {},
            },
          ]);
          return;
        default:
          break;
      }

      const { type, items } = getNearItems();

      switch (type) {
        case "next":
          {
            const [nextItem] = items;

            const btns = [
              {
                txt: BOOKING_SELECTIONS_GO_BACK,
                onClick: () => redirectBetweenPages(history.location.state.isItineraryRedirect ? ROUTE_VIEWS.ITINERARY
                  : ROUTE_VIEWS.THINGS_TO_DO),
                type: "underlined",
                disabled: false,
                dimensions: { margin: ["t-s"] },
              },
            ];

            btns.push({
              txt: getDayOfWeek(nextItem),
              type: "primary",
              onClick: () => {
                handleSelectedDateSelections(nextItem);
                if (!isDesktop()) mobileDaySlider?.slideNext();
              },
              disabled: getActionBtnDisabilityStatus(),
              dimensions: { margin: ["l-s", "t-s"] },
            });

            setNavButtons(btns);
          }
          return;
        case "prev": {
          const [prevItem] = items;

          if (prevItem) {
            setNavButtons([
              {
                txt: BOOKING_SELECTIONS_CANCEL,
                type: "outlined",
                onClick: () => redirectBetweenPages(history.location.state.isItineraryRedirect ? ROUTE_VIEWS.ITINERARY
                  : ROUTE_VIEWS.THINGS_TO_DO),
                disabled: false,
                dimensions: { margin: ["r-s", "t-s"] },
              },
              {
                txt: getDayOfWeek(prevItem),
                type: "outlined",
                onClick: () => {
                  handleSelectedDateSelections(prevItem);
                  if (!isDesktop()) mobileDaySlider?.slidePrev();
                },
                disabled: prevItem < getCurrentDate(),
                dimensions: { margin: ["t-s"] },
              },
              {
                txt: BOOKING_SELECTIONS_CONTINUE,
                onClick: () => {
                  setMainStatus(ITEM_POPULAR_STATUSES.PENDING);
                  setSelectedDate("");
                },
                type: "primary",
                disabled: getActionBtnDisabilityStatus(true),
                dimensions: { margin: ["l-s", "t-s"] },
              },
            ]);
          }
          return;
        }
        default: {
          const [prevItem, nextItem] = items;
          const btns = [
            {
              txt: BOOKING_SELECTIONS_CANCEL,
              type: "outlined",
              onClick: () => redirectBetweenPages(history.location.state.isItineraryRedirect ? ROUTE_VIEWS.ITINERARY
                : ROUTE_VIEWS.THINGS_TO_DO),
              disabled: false,
              dimensions: { margin: ["r-s", "t-s"] },
            },
            {
              txt: getDayOfWeek(prevItem),
              onClick: () => {
                handleSelectedDateSelections(prevItem);
                if (!isDesktop()) mobileDaySlider?.slidePrev();
              },
              type: "outlined",
              disabled: prevItem < getCurrentDate(),
              dimensions: { margin: ["t-s"] },
            },
          ];

          btns.push({
            txt: getDayOfWeek(nextItem),
            type: "primary",
            onClick: () => {
              handleSelectedDateSelections(nextItem);
              if (!isDesktop()) mobileDaySlider?.slideNext();
            },
            disabled: getActionBtnDisabilityStatus(),
            dimensions: { margin: ["l-s", "t-s"] },
          });
          setNavButtons(btns);
        }
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in diningButtons func in StayNavigatorLogic.js file ${error.message}`
      );
    }
  };

  useEffect(() => {
    entertainmentButtons();
  }, [entertainmentBookingSelections, selectedDate, mainStatus, skippedDays]);

  return {
    navButtons,
    mainStatus,
  };
};

export default StayNavigatorLogic;
