import { useContext, useEffect, useState } from "react";

import {
  ITINERARY_OBJECT_DATE_ATTR,
} from "./constants";

import { ConflictManagementContext } from "../../../context/ConflictManagementContext";
import { useDataContext } from "../../../context/DataContext";
import { calcTotalGuests } from "../../../helpingFunctions/groups";
import { CHECKINCHECKOUT_TYPES, CHECKIN_TYPE, CHECKOUT_TYPE, MAESTRO_BACKEND_NAMING_VIEWS } from "../../../constants/constants";
import { DINING_TYPES } from "../../../constants/constants";
import { ACTIVITIES_TYPES } from "../../../constants/constants";
import { convertItineraryObject } from "./helpers";

const ItineraryLogic = () => {
  const [itinerary, setItinerary] = useState([]);
  const [calendarLinksData, setCalendarLinksData] = useState(null);

  const { itineraryDetails } = useDataContext();
  const { findItineraryConflicts } = useContext(ConflictManagementContext);

  const extractItinerary = (itineraryData) => {
    try {
      const { itineraryDates } = itineraryData;
      const formattedItinerary = [];

      for (let index = 0; index < itineraryDates.length; index++) {
        const dayItinerary = itineraryDates[index];
        const bookings = dayItinerary.All;

        const hasConflicts = bookings.some(booking => booking.conflicts && booking.conflicts.length > 0);

        formattedItinerary.push({
          [ITINERARY_OBJECT_DATE_ATTR]:
            dayItinerary[ITINERARY_OBJECT_DATE_ATTR],
          bookings,
          hasConflicts
        });
      }

      const sortedFormattedItinerary = formattedItinerary.sort((a, b) => a.Data.localeCompare(b.Data));

      //Add check in and check out to itinerary object
      sortedFormattedItinerary[0]?.bookings.push({
        Type: CHECKIN_TYPE,
        Date: sortedFormattedItinerary[0]?.Data,
        Time: "15:00:00-04:00",
        Title: "Arrival",
        Info: "Your room is ready"
      });

      sortedFormattedItinerary[sortedFormattedItinerary.length - 1]?.bookings.push({
        Type: CHECKOUT_TYPE,
        Time: "10:00:00-04:00",
        Date: sortedFormattedItinerary[sortedFormattedItinerary.length - 1]?.Data,
        Title: "Check out time",
      });
      groupSpaBookings(sortedFormattedItinerary);
      setItinerary(sortedFormattedItinerary);
    } catch (error) {
      console.error(
        `${new Date()} Error in extractItinerary func in ItineraryLogic.js ${error.message}`
      );
    }
  };

  const groupSpaBookings = (itinerary) => {
    try {
      for (let index = 0; index < itinerary.length; index++) {
        const { bookings } = itinerary[index];

        const nonSpaBookings = bookings.filter(
          ({ Type }) => Type !== MAESTRO_BACKEND_NAMING_VIEWS.SPA
        );

        const spaBookings = bookings.filter(
          ({ Type }) => Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA
        );

        const bookingCombos = new Set();
        spaBookings.forEach((row) => {
          bookingCombos.add(
            `${row.Id} | ${row.Date} | ${row.Time.substring(0, 5)}`
          );
        });

        const reworkedDayBookingsCollection = [];
        for (const row of bookingCombos) {
          const parVals = row.split(" | ");
          const id = parVals[0];
          const date = parVals[1];
          const time = parVals[2];

          const AllBooksByParams = spaBookings.filter(
            (booking) =>
              booking.Id == id &&
              booking.Date === date &&
              booking.Time.substring(0, 5) === time
          );

          if (AllBooksByParams.length <= 1) {
            if (AllBooksByParams.length === 1) {
              if (AllBooksByParams[0].isParticipant) {
                AllBooksByParams[0].LastName = AllBooksByParams[0].ParticipantLastName;
                AllBooksByParams[0].FirstName = AllBooksByParams[0].ParticipantFirstName;
              }

              reworkedDayBookingsCollection.push(AllBooksByParams[0]);
            }
            continue;
          }

          const hasLeadBooksIndex = AllBooksByParams.findIndex(
            (row) => !row.isParticipant
          );
          const participantBooks = AllBooksByParams.filter((row) => row.isParticipant);
          const totalAddedParticipants = calcTotalGuests(
            participantBooks,
            "Quantity"
          );

          if (hasLeadBooksIndex >= 0) {
            reworkedDayBookingsCollection.push({
              ...AllBooksByParams[hasLeadBooksIndex],
              others: JSON.parse(JSON.stringify(participantBooks)),
              totalParticipants:
                totalAddedParticipants +
                Number(AllBooksByParams[hasLeadBooksIndex].Quantity),
            });
          } else {
            reworkedDayBookingsCollection.push({
              ...AllBooksByParams[0],
              others: JSON.parse(JSON.stringify(participantBooks)),
              totalParticipants:
                totalAddedParticipants,
            });
          }
        }

        itinerary[index].bookings = [...nonSpaBookings, ...reworkedDayBookingsCollection].sort(
          (a, b) =>
            new Date(`${a.Date}T${a.Time}`).getTime() -
            new Date(`${b.Date}T${b.Time}`).getTime()
        );
      }

    } catch (error) {
      console.error(
        `${new Date()} Error in groupSpaBookings func in ItineraryLogic.js ${error.message}`
      );
    }
  };

  const filterItinerary = (itinerary, currentTab) => {
    const itineraryCopy = JSON.parse(JSON.stringify(itinerary));
    if (currentTab === "Dining") {
      for (const date of itineraryCopy) {
        date.bookings = date.bookings.filter(booking => [...DINING_TYPES, ...CHECKINCHECKOUT_TYPES].includes(booking.Type));
      }
    } else if (currentTab === "Activities") {
      for (const date of itineraryCopy) {
        date.bookings = date.bookings.filter(booking => [...ACTIVITIES_TYPES, ...CHECKINCHECKOUT_TYPES].includes(booking.Type));
      }
    } else if (currentTab === "Spa") {
      for (const date of itineraryCopy) {
        date.bookings = date.bookings.filter(booking => ["Spa", ...CHECKINCHECKOUT_TYPES].includes(booking.Type));
      }
    }

    return itineraryCopy;
  };

  useEffect(() => {
    const findConflicts = async () => {
      return await findItineraryConflicts();
    };

    if (itineraryDetails) {
      findConflicts()
        .then((itineraryWithConflictsData) => {
          const itineraryData = convertItineraryObject(itineraryWithConflictsData);
          extractItinerary(itineraryData);
        });
    }
  }, [itineraryDetails]);

  return {
    itinerary,
    calendarLinksData,
    setCalendarLinksData,
    filterItinerary,
  };
};

export default ItineraryLogic;
