
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../constants/constants";
import { formatDate, formatTime } from "./dateTimeUtilities";
import { formatPrice } from "./formatPrice";

/**
	replacePlaceholders
	
	@brief Replaces all occurrences of strapi placeholders
	@params
		* text - String containing placeholders
		* replaceObject - Key-value pairs:
    - the keys are the placeholders coming from strapi for ex. SERVICE_NAME ({{SERVICE_NAME}} in Strapi)
    - the values are the replace texts
	@return String containing the replaced placeholders
**/
const replacePlaceholders = (text = "", replaceObject) => {
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  for (const key in replaceObject) {
    text = text.replace(new RegExp(escapeRegExp(`{{${key}}}`), 'g'), typeof replaceObject[key] === 'string'  ? replaceObject[key].trim() : replaceObject[key]);
  }

  return text;
};

/**
	generateConflictReplacementObj
	
	@brief Prepares the mapped obj in the order of placeholder: value for the conflicts
	@params
		* bookedObj - Booked item properties
		* selectedObj - Selected item properties
    * cxlPolicy - Cancellation policy properties
	@return Object containing the mapped values
**/
const generateConflictReplacementObj = (
  bookedObj,
  selectedObj,
  cxlPolicy,
  others,
  selectionConfigObj
) => {
  const confirmedBookFullName =
    bookedObj?.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA && bookedObj?.isParticipant
      ? `${bookedObj?.ParticipantFirstName} ${bookedObj?.ParticipantLastName}`
      : `${bookedObj?.FirstName} ${bookedObj?.LastName}`;
  const obj = {
    CONFIRMED_TYPE: bookedObj?.Type,
    CONFIRMED_TIME: formatTime(
      `${bookedObj?.Date}T${bookedObj?.Time}`.slice(0, 16),
      "h:mm"
    ),
    CONFIRMED_DATE: formatDate(bookedObj?.Date),
    CONFIRMED_DURATION: bookedObj?.Duration,
    CONFIRMED_TITLE: bookedObj?.ShowTitle ? bookedObj?.ShowTitle : bookedObj?.Title,
    CONFIRMED_FULL_NAME: confirmedBookFullName,

    SELECTION_TITLE: selectedObj?.title,
    SELECTION_SINGLE_UNIT_PRICE:
      selectedObj?.singleUnitPrice &&
      ["0", "0.00", "00.00", ""].includes(selectedObj?.singleUnitPrice)
        ? "Included"
        : formatPrice(selectedObj?.singleUnitPrice),
    SELECTION_DATE: formatDate(selectedObj?.[selectionConfigObj.datePar]),
    SELECTION_TIME: formatTime(
      `${selectedObj?.[selectionConfigObj.datePar]}T${
        selectedObj?.[selectionConfigObj.timePar]
      }`.slice(0, 16),
      "h:mm"
    ),
    SELECTION_QUANTITY: selectedObj?.Quantity,
    SELECTION_FULL_NAME: `${selectedObj?.FirstName} ${selectedObj?.LastName}`,
    
    RESERVATION_ADULTS: others?.resAdults,

    CHAINED_CONF_EXCEEDED_QUANTITY: others?.chainedExceededQty,

    BOOKING_HOLDER_NAME: cxlPolicy?.name,
    PENALTY_HOURS_PRIOR: cxlPolicy?.policyHours,
    PENALTY_PERCENTAGE: cxlPolicy ? `${cxlPolicy?.percentage * 100}` : "",
    SERVICE_AMOUNT: formatPrice(cxlPolicy?.activityPrice),
    PENALTY_DATE: formatDate(cxlPolicy?.date),
    PENALTY_SERVICE_NAME: cxlPolicy?.serviceName,
  };
  
  return obj;
};
export { replacePlaceholders, generateConflictReplacementObj };
