import React, { useContext, useEffect, useState } from "react";

import {
  COMPARE_RESULTS, SERVICES_TYPES,
} from "../../../../../../constants/constants";
import { useDataContext } from "../../../../../../context/DataContext";
import {
  getCurrentDate,
  getDatesBetween,
  getDayOfWeek,
} from "../../../../../../helpingFunctions/dateTimeUtilities";
import { datesCompare, isMobile } from "../../../../../../helpingFunctions/utilities";

import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import { EntertainmentsPageContext } from "../../../EntertainmentsPageContext";
import { useTheme } from "../../../../../../hooks/useTheme";

const DaysLogic = () => {
  const { typography } = useTheme();
  const {
    skippedDays,
    setSkippedDays,
    setSelectedDate,
    selectedDate,
    entertainmentBookingSelections,
    setEntertainmentBookingSelections,
    mobileDaySlider,
  } = useContext(EntertainmentsPageContext);

  const { reservationDetails } = useDataContext();

  const [displayedDateIndex, setDisplayedDateIndex] = useState(0);

  const datesList = getDatesBetween(
    reservationDetails.ArrivalDate,
    reservationDetails.DepartureDate,
    SERVICES_TYPES.ENTERTAINMENT
  );

  const entertainmentDates = datesList.map((date) => {
    return {
      date,
      id: date,
      content: (
        <BLParagraph txt={<>{getDayOfWeek(date)}</>} textVariant={typography.BODY_TEXT} />
      ),
    };
  });

  const shouldDisableDesktop = (date) => {
    //Always enable days button if entertainment has been booked before
    if (date < getCurrentDate()) {
      return true;
    }
    if (reservationDetails.Venue) {
      return false;
    }
    //Always enable the first day
    if (datesList[0] === date) {
      return false;
    }
    //If a previous day do not have a selection, disable next days
    const previousDays = datesList.filter((day) => day < date);

    let allPreviousDaysHaveSelection = true;
    for (const previousDay of previousDays) {
      const previousDayHasSelection =
        skippedDays.includes(previousDay) ||
        !!entertainmentBookingSelections.find(
          (booking) => booking.date === previousDay
        );
      if (!previousDayHasSelection) {
        allPreviousDaysHaveSelection = false;
        break;
      }
    }
    return !allPreviousDaysHaveSelection;
  };

  const handleDateChange = (slideVisibleContent) => {
    try {
      if (!slideVisibleContent) {
        return;
      }

      const { date } = slideVisibleContent;

      const dateIndex = entertainmentDates.findIndex(
        (dining) =>
          datesCompare(new Date(dining.date), new Date(date)) ===
          COMPARE_RESULTS.EQUAL
      );
      setDisplayedDateIndex(dateIndex);

      setSelectedDate(date);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleDateChange func in DaysLogic.js ${
          error.message
        }`
      );
    }
  };

  const handleNoBookingNeeded = () => {
    try {
      const isSkipped = skippedDays.includes(selectedDate);
      const skippedDaysCopy = [...skippedDays];
      const entertainmentBookingSelectionsCopy = JSON.parse(
        JSON.stringify(entertainmentBookingSelections)
      );
      if (!isSkipped) {
        skippedDaysCopy.push(selectedDate);

        const selectionsForRemoval = [];
        for (const selection of entertainmentBookingSelectionsCopy) {
          if (selection.action === "none" && selection.date === selectedDate) {
            selection.action = "remove";
          } else if (
            selection.action === "add" &&
            selection.date === selectedDate
          ) {
            selectionsForRemoval.push(selection);
          }
        }

        for (const selection of selectionsForRemoval) {
          entertainmentBookingSelectionsCopy.splice(
            entertainmentBookingSelectionsCopy.indexOf(selection),
            1
          );
        }
      } else {
        skippedDaysCopy.splice(skippedDaysCopy.indexOf(selectedDate), 1);

        if (entertainmentBookingSelectionsCopy.length > 0) {
          entertainmentBookingSelectionsCopy.forEach((sel) => {
            if (sel.action === "remove" && sel.date === selectedDate) {
              sel.action = "none";
            }
          });
        }
      }
      setEntertainmentBookingSelections(entertainmentBookingSelectionsCopy);
      setSkippedDays(skippedDaysCopy);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleNoBookingNeeded func in DaysLogic.js ${
          error.message
        }`
      );
    }
  };

  const handleSlideJumps = () => {
    try {
      let jumpToDateIndex = entertainmentDates.findIndex((dining) => {
        return dining.date === selectedDate;
      });
      if (
        displayedDateIndex === jumpToDateIndex ||
        Object.keys(mobileDaySlider).length === 0
      ) {
        return;
      }
      const isPrevDay = displayedDateIndex > jumpToDateIndex;
      if (isPrevDay) {
        while (jumpToDateIndex < displayedDateIndex) {
          mobileDaySlider?.slidePrev();
          jumpToDateIndex++;
        }
      } else {
        while (jumpToDateIndex > displayedDateIndex) {
          mobileDaySlider?.slideNext();
          jumpToDateIndex--;
        }
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in handleSlideJumps func in DaysLogic.js ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    if (selectedDate && isMobile()) {
      handleSlideJumps();
    }
  }, [selectedDate, mobileDaySlider]);

  return {
    entertainmentDates,
    handleDateChange,
    handleNoBookingNeeded,
    shouldDisableDesktop,
  };
};

export default DaysLogic;
