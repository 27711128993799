import { IonCard, IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useContext } from "react";
import { MaintenanceContext } from "../../../context/MaintenanceContext";
import BLLoadingContainer from "../../../shared/Ionic/BLLoadingContainer";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import { useTheme } from "../../../hooks/useTheme";

const MaintenancePage = () => {
  const { typography } = useTheme();
  const { maintenanceData } = useContext(MaintenanceContext);

  return (
    <IonPage style={{overflow: "auto"}}>
      {!maintenanceData ? (
        <BLLoadingContainer />
      ) : (
        <IonCard
          style={{
            background: `url(${maintenanceData?.Image?.formats?.large?.url || maintenanceData?.Image.url}) center / cover transparent fixed`,
            height: "100vh",
            display: "flex",
          }}
        >
          <IonCard
            className="bl-flex ion-justify-content-center ion-align-items-center"
            style={{
              background: "rgba(0, 0, 0, 0.2)",
              width: "100vw",
              minHeight: "63.241vh",
              borderRadius: "5px",
              flexDirection: "column",
              margin: "auto 16px",
              backdropFilter: "blur(25px)",
            }}
          >
            <IonGrid style={{ display: "inherit" }}>
              <IonRow className="bl-padding-l-xs bl-padding-r-xs">
                <IonCol className="ion-text-center">
                  <BLParagraph
                    color="white"
                    txt={maintenanceData?.Title}
                    //TODO: Unclear typography
                    //newClasses={`bold`}
                    textVariant={typography.h5}
                    newStyles={{ fontSize: "clamp(28px, 4vw, 40px)" }}
                  />
                </IonCol>
              </IonRow>
              <IonRow
                className="bl-margin-t-l ion-text-center bl-padding-l-xs bl-padding-r-xs"
              >
                <IonCol>
                  <BLParagraph
                    color="white"
                    txt={maintenanceData?.Description}
                    newStyles={{ fontSize: "clamp(18px, 4vw, 20px)" }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        </IonCard>
      )}
    </IonPage>
  );
};

export default MaintenancePage;
