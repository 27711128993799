import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import React, { useContext, useRef } from "react";

import { closeCircle } from "ionicons/icons";
import { useDataContext } from "../../../../../context/DataContext";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";
import CreateDimensions from "../../../../../helpingFunctions/DimensionsFactory";
import BLConfirmationPopupBox from "../../../../../shared/Ionic/Boxes/Popup/BLConfirmationPopupBox";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import ParticipantDisplayLogic from "./ParticipantDisplayLogic";
import { useTheme } from "../../../../../hooks/useTheme";

const ParticipantData = ({
  participantId,
  firstName,
  lastName,
  crsNumber,
  quantity,
  title,
  dimensions,
  prefix,
  isParticipantGroupCreator
}) => {
  const { typography } = useTheme();
  const { dimensions: participantDetailsDimensions } = CreateDimensions({
    padding: ["t-xxs"],
  });
  const {
    removeValidatedReservation,
    diningGrRemoveMsg,
    setDiningGrRemoveMsg,
    prompPopup,
  } = ParticipantDisplayLogic();
  const {
    reservationDetails
  } = useDataContext();
  const { isGroupActionLoading, isPostGrCreationPartAddedLoading, response } =
    useContext(DiningPartyContext);
  const modal = useRef(null);

  return (
    <BLContentCardWrapper background="light" dimensions={dimensions}>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <BLParagraph
              txt={title}
              textVariant={typography.h5}
              // newClasses={`x-small bold`}
              //TODO: Unclear typography
              color="text-grey"
            />
          </IonCol>
        </IonRow>
        <IonRow
          className={`${participantDetailsDimensions} ion-align-items-center`}
        >
          <IonCol size="6">
            <BLParagraph
              txt={`${firstName} ${lastName} ${prefix ? prefix : ""}`}
              color="text-grey"
            />
          </IonCol>
          <IonCol size="3.5">
            <BLParagraph txt={crsNumber} color="text-grey" />
          </IonCol>
          <IonCol size="1">
            <BLParagraph txt={`(${quantity})`} color="text-grey" />
          </IonCol>
          {!isParticipantGroupCreator && !isGroupActionLoading && reservationDetails.IsGroupCreator && !isPostGrCreationPartAddedLoading && !response?.isLoading && (
            <IonCol size="1" className="bl-padding-l-xs">
              <IonIcon
                onClick={() => {
                  prompPopup(participantId);
                }}
                className="bl-icon-sm"
                icon={closeCircle}
                color="secondary"
                id="open-modal"
                style={{ cursor: "pointer" }}
              />
            </IonCol>
          )}
        </IonRow>
      </IonGrid>

      <BLConfirmationPopupBox
        isOpen={!!diningGrRemoveMsg}
        txt1={diningGrRemoveMsg}
        confirmBtnLabel="Yes"
        dismissBtnType="outlined"
        confirmBtnType="primary"
        dismissBtnLabel="No"
        dismissHandler={() => {
          setDiningGrRemoveMsg(null);
          modal.current?.dismiss();
        }}
        confirmHandler={() => {
          removeValidatedReservation(participantId);
          modal.current?.dismiss();
        }}
        modal={modal}
      />
    </BLContentCardWrapper>
  );
};

export default ParticipantData;
