import { ACTION_TYPES, MAESTRO_BACKEND_NAMING_VIEWS } from "../../../constants/constants";
import { CONFLICT_TYPES } from "../conflictConstants";

/** 
    Method for checking if all the conflicts for a given conflict obj are resolved
  */
const areAllConflictsResolved = (conflictObjs) => {
  let totalConflicts = 0;
  let totalResolved = 0;

  conflictObjs?.forEach((obj) => {
    totalConflicts += obj?.conflicts.length;
    totalResolved += obj?.conflicts.filter(
      (conflict) => conflict.isResolved
    ).length;
  });

  return totalConflicts === totalResolved;
};

/** 
    Method for resolving SOFT conflict (button handler)
  */
const resolveSoftConflict = (
  setFoundConflicts,
  foundConflicts,
  spotlitConflict
) => {
  resolveConflict(foundConflicts, spotlitConflict, setFoundConflicts);
};

/** 
    Method for resolving AVERAGE conflict (DISMISS button handler)
  */
const negateAverageConflict = (
  setFoundConflicts,
  foundConflicts,
  setProhibitRQToMaestro
) => {
  // Forbid further processing execution
  setProhibitRQToMaestro(true);

  // Resolve all other conflicts to NOT show them
  resolveAllConflicts(foundConflicts, setFoundConflicts);
};

/** 
    Method for resolving AVERAGE conflict (CONFIRM button handler)
  */
const resolveAverageConflict = (
  setFoundConflicts,
  foundConflicts,
  spotlitConflict,
  setReplacedBookings,
  source
) => {
  if (source === CONFLICT_TYPES.CHAINED) {
    const cancellationObjs = [];
    spotlitConflict.confirmedBookingDetails.forEach((row) => {
      if (row.isSelected) {
        const serviceToCancel = prepareCancellationObjOfTheReplaced(
          foundConflicts,
          row
        );
        cancellationObjs.push(serviceToCancel);
      }
    });

    setReplacedBookings((prevState) => {
      return [...prevState, ...cancellationObjs];
    });
  } else {
    const serviceToCancel = prepareCancellationObjOfTheReplaced(
      foundConflicts,
      spotlitConflict.confirmedBookingDetails
    );

    setReplacedBookings((prevState) => {
      return [...prevState, serviceToCancel];
    });
  }

  resolveConflict(foundConflicts, spotlitConflict, setFoundConflicts);
};

/** 
    Method for resolving STRONG conflict (button handler)
  */
const resolveStrongConflict = (
  setFoundConflicts,
  foundConflicts,
  spotlitConflict,
  setProhibitRQToMaestro,
  canChainedConflictsBeResolved,
  disableReplaceInChainedSpaView
) => {
  const prohibitToMaestro = spotlitConflict.confirmedBookingDetails instanceof Array
    ? !canChainedConflictsBeResolved(spotlitConflict, foundConflicts, disableReplaceInChainedSpaView) 
    : true;

  setProhibitRQToMaestro(prohibitToMaestro);
  resolveConflict(foundConflicts, spotlitConflict, setFoundConflicts);
};

/** 
    Shared method for resolving conflicts
  */
const resolveConflict = (
  foundConflicts,
  spotlitConflict,
  setFoundConflicts
) => {
  const foundConflictsCopy = JSON.parse(JSON.stringify(foundConflicts));

  foundConflictsCopy.forEach((row) => {
    // Even if a confirmed booking is conflicting with multiple selections, the confirmed booking needs to be replaced only once
    const conflictIndex = row.conflicts.findIndex(
      (conflict) =>
        conflict.confirmedBookingDetails.BookingNumber ===
        spotlitConflict?.confirmedBookingDetails.BookingNumber
    );

    if (conflictIndex > -1) {
      row.conflicts[conflictIndex].isResolved = true;
    }
  });

  setFoundConflicts(foundConflictsCopy);
};

/** 
    Method for resolving ALL conflicts at once
  */
const resolveAllConflicts = (foundConflicts, setFoundConflicts) => {
  const foundConflictsCopy = JSON.parse(JSON.stringify(foundConflicts));

  foundConflictsCopy.forEach((row) => {
    row.conflicts.forEach((conf) => {
      conf.isResolved = true;
    });
  });

  setFoundConflicts(foundConflictsCopy);
};

/** 
    Method for preparing the cancellation object as expected from the backend 
  */
const prepareCancellationObjOfTheReplaced = (
  foundConflicts,
  spotlitConflict
) => {
  const {
    Id,
    BookingNumber,
    ReservationNumber,
    FirstName,
    LastName,
    ParticipantFirstName,
    ParticipantLastName,
    Type,
    Date,
    Time,
    ProviderCode,
    isParticipant,
    Quantity,
  } = spotlitConflict;

  let conflDetails = getConflictSelectionDetails(
    foundConflicts,
    spotlitConflict
  );

  const serviceToCancel = {
    id: Id,
    BookingNumber: BookingNumber,
    ReservationNumber: ReservationNumber,
    FirstName: FirstName,
    LastName: LastName,
    type: Type,
    action: ACTION_TYPES.REMOVE,
    Date: `${Date}T${Time}`,
    isReplacement: true,
    replacedWith: `${conflDetails.selectionDate}T${conflDetails.selectionTime}`,
  };

  if (Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
    serviceToCancel.ProviderCode = ProviderCode;
    serviceToCancel.isParticipant = isParticipant;

    if (isParticipant) {
      serviceToCancel.participantFirstName = ParticipantFirstName;
      serviceToCancel.participantLastName = ParticipantLastName;
    }
  } else {
    serviceToCancel.Quantity = Number(Quantity);
  }

  return serviceToCancel;
};

const getConflictSelectionDetails = (foundConflicts, spotlitConflict) => {
  let conflDetails = null;

  foundConflicts.forEach((row) => {
    let conflictIndex = -1;

    row.conflicts.forEach((conflict, index) => {
      if (conflict.confirmedBookingDetails instanceof Array) {
        conflict.confirmedBookingDetails.forEach((confBooks, confBookIndex) => {
          if (confBooks.BookingNumber === spotlitConflict?.BookingNumber) {
            conflictIndex = confBookIndex;
            return;
          }
        });
      } else {
        if (
          conflict.confirmedBookingDetails.BookingNumber ===
          spotlitConflict?.BookingNumber
        ) {
          conflictIndex = index;
          return;
        }
      }
    });

    if (conflictIndex > -1) {
      conflDetails = row;
      return;
    }
  });

  return conflDetails;
};

const getConflictResolverProgressText = (foundConflicts) => {
  let totalConflicts = 0;
  foundConflicts.forEach((row) => {
    totalConflicts += row.conflicts.length;
  });

  if (totalConflicts <= 1) {
    return "";
  }

  let totalConflictsShowned = 1;
  foundConflicts.forEach((row) => {
    if (totalConflictsShowned < totalConflicts)
      totalConflictsShowned += row.conflicts.filter(
        (conf) => conf.isResolved
      ).length;
  });

  return `**(${totalConflictsShowned}/${totalConflicts})**`;
};

export {
  areAllConflictsResolved,
  negateAverageConflict,
  resolveSoftConflict,
  resolveAverageConflict,
  resolveStrongConflict,
  getConflictSelectionDetails,
  getConflictResolverProgressText,
};
