import React from 'react';

import { useTheme } from '../../hooks/useTheme';

import { classFormatter } from '../../shared/Ionic/BLIonicHelpers';
import { TEXT_CSS_CLASS_IDENTIFIER } from '../../shared/Ionic/BLIonicConstants';

import { MAP_VARIANTS_TO_CLASSES } from '../../constants/constants';

const BLTypography = ({ variant, children, className, ...props }) => {
  const { typography } = useTheme();

  const Tag = [typography.h1, typography.h2, typography.h3, typography.h4, typography.h5].includes(variant) ? variant : 'span';

  const variantClassName = MAP_VARIANTS_TO_CLASSES[variant] || typography.BODY_TEXT;

  const paragraphSpecificClasses = classFormatter(
    variantClassName,
    TEXT_CSS_CLASS_IDENTIFIER
  );

  const combinedClassName = `${paragraphSpecificClasses} ${className || ''} bl-text-inline-block`.trim();

  return <Tag {...props} className={combinedClassName}>{children}</Tag>;
};

export default BLTypography;
