const areEqual = (arr1, arr2) => {
  let n = arr1.length;
  let m = arr2.length;

  if (n != m) return false;

  let map = new Map();
  let count = 0;
  for (let i = 0; i < n; i++) {
    if (map.get(arr1[i]) == null) map.set(arr1[i], 1);
    else {
      count = map.get(arr1[i]);
      count++;
      map.set(arr1[i], count);
    }
  }

  for (let i = 0; i < n; i++) {
    if (!map.has(arr2[i])) return false;

    if (map.get(arr2[i]) == 0) return false;

    count = map.get(arr2[i]);
    --count;
    map.set(arr2[i], count);
  }

  return true;
};

const groupBy = (items, keys) => {
  const groupedArr = [];

  items.map((item) => {
    const isExisting = groupedArr.some((grItem) => {
      const filteredKeyCheck = keys.filter(
        (key) => grItem[key] === item[key] && item[key]
      );
      if (filteredKeyCheck.length === keys.length) {
        return true;
      } else {
        return false;
      }
    });

    if (!isExisting) groupedArr.push(item);
  });

  return groupedArr;
};
const constructArrayOutOfGrouping = (
  allObjects,
  uniqueKeys,
  groupKeys,
  sortIndicator
) => {
  const structuredObjects = [];
  allObjects.map((item) => {
    const itemIndex = structuredObjects.findIndex((structuredObj) => {
      const filteredKeyCheck = uniqueKeys.filter(
        (key) => structuredObj[key] === item[key]
      );

      return filteredKeyCheck.length === uniqueKeys.length;
    });

    if (itemIndex >= 0) {
      structuredObjects[itemIndex].objects.push(item);
    } else {
      const groupData = {
        CategoryImage:
          "https://images.pexels.com/photos/2325446/pexels-photo-2325446.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260",
      };
      groupKeys.map((key) => {
        if (item[key]) groupData[key] = item[key];
      });

      structuredObjects.push({
        ...groupData,
        objects: [item],
      });
    }
  });
  const sorted = structuredObjects.sort(function (a, b) {
    return (
      (a[sortIndicator] === null) - (b[sortIndicator] === null) ||
      (a[sortIndicator] === undefined) - (b[sortIndicator] === undefined) ||
      +(a[sortIndicator] > b[sortIndicator]) ||
      -(a[sortIndicator] < b[sortIndicator])
    );
  });

  return sorted;
};
const compare = (a, b, type) => {
  const comparisonRes = {
    asc: {
      val1: -1,
      val2: 1,
    },
    desc: {
      val1: 1,
      val2: -1,
    },
  };

  if (!a) a = "";
  if (!b) b = "";

  if (a < b) {
    return comparisonRes[type].val1;
  }
  if (a > b) {
    return comparisonRes[type].val2;
  }

  return 0;
};

const orderByValue = (array, attr) =>
  array?.sort(
    (a, b) =>
      (a[attr] !== null ? a[attr] : Infinity) -
      (b[attr] !== null ? b[attr] : Infinity)
  );

const createObjectListOfAttrValues = (array, attr, newAttr) => {
  try {
    const newList = array
      .map((item) => {
        return { [newAttr]: item[attr] };
      })
      .filter((x) => x.hash);

    return newList;
  } catch (error) {
    console.error(
      `${new Date()} Error in createListOfAttrValues func in arrays.js ${
        error.message
      }`
    );
  }
};

export {
  areEqual,
  orderByValue,
  groupBy,
  compare,
  constructArrayOutOfGrouping,
  createObjectListOfAttrValues,
};
