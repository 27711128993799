import { menuController } from "@ionic/core/components";
import { IonCol, IonGrid, IonMenuToggle, IonRow } from "@ionic/react";
import { Collapse } from "@mui/material";
import { addCircle } from "ionicons/icons";
import React, { useContext, useState } from "react";
import { FEATURE_FLAGS } from "../../constants/constants";
import { useDataContext } from "../../context/DataContext";
import { FeatureFlagsContext } from "../../context/FeatureFlagsContext";
import { SideMenuContext } from "../../context/SideMenuContext";
import RequestHook from "../../hooks/RequestHook";
import BLAlertPopUp from "../../shared/Ionic/Boxes/Popup/BLAlertPopUp";
import BLParagraph from "../../shared/Ionic/Paragraph/BLParagraph";
import LinkReservationsPage from "../../views/children/LinkReservationsPage/LinkReservationsPage";
import LinkedReservationsBlockLogic from "./LinkedReservationsBlockLogic";
import LinkReservationCard from "./components/LinkReservationCard";
import LinkReservationCardButton from "./components/LinkReservationCardButton";
import { useTheme } from "../../hooks/useTheme";

const LinkedReservationsBlock = () => {
  const { typography } = useTheme();
  const { reservationDetails, dynamicTexts } = useDataContext();
  const { areLinkResCardsCollapsed, setFullScreenOverlayContent } =
    useContext(SideMenuContext);
  const { featureFlags } = useContext(FeatureFlagsContext);
  const [deleteReservationDialog, setDeleteReservationDialog] = useState(null);
  const {
    allReservations,
    invalidResDetails,
    setInvalidResDetails,
    changeReservation,
  } = LinkedReservationsBlockLogic();
  const { updateLinkedReservations, deleteLinkedReservations } = RequestHook();

  return (
    <>
      {reservationDetails && (
        <IonGrid>
          <IonRow
            style={{
              cursor:
                areLinkResCardsCollapsed &&
                reservationDetails.LinkedReservations?.length > 1
                  ? "pointer"
                  : "default",
            }}
          >
            <IonCol>
              <IonGrid
                className="bl-padding-s"
                style={{
                  height: "fit-content",
                  maxHeight: "305px",
                  overflow: "auto",
                }}
              >
                {allReservations.map((res, index) => (
                  <IonRow
                    key={res.id}
                    className={
                      !areLinkResCardsCollapsed && index !== 0
                        ? "bl-padding-t-s"
                        : ""
                    }
                  >
                    {res.CRSNumber !== reservationDetails.CRSNumber ? (
                      <Collapse
                        in={!areLinkResCardsCollapsed}
                        collapsedSize={"30px"}
                        sx={{
                          width: "100%",
                          borderRadius: "10px 10px 2px 2px",
                        }}
                      >
                        <LinkReservationCard
                          res={res}
                          areLinkResCardsCollapsed={areLinkResCardsCollapsed}
                          changeReservation={changeReservation}
                          setInvalidResDetails={setInvalidResDetails}
                          showRemoveIcon={true}
                          setDeleteReservationDialog={setDeleteReservationDialog}
                        />
                      </Collapse>
                    ) : (
                      <LinkReservationCard
                        res={res}
                        areLinkResCardsCollapsed={areLinkResCardsCollapsed}
                        changeReservation={changeReservation}
                        setInvalidResDetails={setInvalidResDetails}
                      />
                    )}
                  </IonRow>
                ))}
              </IonGrid>
            </IonCol>
          </IonRow>
          {featureFlags && featureFlags[FEATURE_FLAGS.LINKED_RESERVATIONS] &&
          <IonRow className="bl-padding-b-s bl-padding-l-s bl-padding-r-s">
            <IonMenuToggle style={{ width: "100%" }}>
              <LinkReservationCardButton
                icon={addCircle}
                label={
                  <BLParagraph
                    txt="Add new reservation"
                    color="black"
                    textVariant={typography.TOOLTIP_HIGHLIGHT_TEXT}
                  />
                }
                onClick={() => {
                  setFullScreenOverlayContent(<LinkReservationsPage />);
                }}
              />
            </IonMenuToggle>
          </IonRow>
          }
        </IonGrid>
      )}

      {invalidResDetails && (
        <BLAlertPopUp
          title={`${
            invalidResDetails.isRemoved
              ? "Removed"
              : invalidResDetails.isExpired ? "Expired" : "Cancelled"
          } reservation`}
          description={
            invalidResDetails.isRemoved
              ? dynamicTexts?.LinkedReservations?.Popup_Selected_RemovedReservation
              : invalidResDetails.isExpired
                ? dynamicTexts?.LinkedReservations?.Popup_Selected_ExpiredReservation
                : dynamicTexts?.LinkedReservations?.Popup_Selected_CancelledReservation
          }
          isOpen={!!invalidResDetails}
          confirmHandler={async () => {
            setInvalidResDetails(null);
            const updatedReservations = await updateLinkedReservations();

            //Close the burger menu if not additional reservations are left
            if (updatedReservations.remainingRes.length <= 1) {
              menuController.close();
            }
          }}
          confirmBtnLabel={"Dismiss"}
          confirmBtnType={"primary"}
        />
      )}

      {deleteReservationDialog &&
      <BLAlertPopUp
        title={"Remove reservation"}
        isOpen={!!deleteReservationDialog}
        confirmHandler={async () => {
          setDeleteReservationDialog(null);
          await deleteLinkedReservations(deleteReservationDialog.strapiResId);
          const updatedReservations = await updateLinkedReservations();

          //Close the burger menu if not additional reservations are left
          if (updatedReservations.remainingRes.length <= 1) {
            menuController.close();
          }
        }}
        confirmBtnLabel="Remove"
        confirmBtnType="primary"
        dismissBtnLabel="Cancel"
        dismissBtnType="underlined"
        dismissHandler={() => setDeleteReservationDialog(null)}
      >
        <BLParagraph
          txt={`You are about to remove a reservation for ${deleteReservationDialog.guestName} at ${deleteReservationDialog.HotelTitle}, `}
          textVariant={typography.BODY_TEXT}
        />
        {deleteReservationDialog.dateInterval}
        <BLParagraph
          txt={` - Ref ${deleteReservationDialog.CRSNumber}.`}
          textVariant={typography.BODY_TEXT}
        />
      </BLAlertPopUp>
      }
    </>
  );
};

export default LinkedReservationsBlock;
