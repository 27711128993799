import { IonCol, IonRow } from "@ionic/react";
import { format } from "date-fns";
import React, { useContext } from "react";
import { INITIAL_DATE } from "../../../../../../../constants/constants";
import { useDataContext } from "../../../../../../../context/DataContext";
import { SpaContext } from "../../../../../../../context/SpaContext";
import {
  getCurrentDate,
  getDayPeriod,
  isDateWithinRange,
} from "../../../../../../../helpingFunctions/dateTimeUtilities";
import { replacePlaceholders } from "../../../../../../../helpingFunctions/replacePlaceholders";
import BLLoadingContainer from "../../../../../../../shared/Ionic/BLLoadingContainer";
import BLInformationBox from "../../../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import BLButton from "../../../../../../../shared/Ionic/Buttons/BLButton";

const TimeRowTogetherSelectionLogic = (
  extractTimeSelections,
  serviceDetails
) => {
  const {
    spaSelections,
    setSpaSelections,
    handleProviderCode,
    spaAvailability,
    setSpaAvailability,
  } = useContext(SpaContext);
  const {
    reservationDetails,
    itineraryDetails,
    dynamicTexts,
    isLoadingItinerary,
  } = useDataContext();

  const handleTogetherSelection = (obj, indexToUpdate) => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    const spaAvailabilityCopy = JSON.parse(JSON.stringify(spaAvailability));

    spaSelectionsCopy.forEach((row) => {
      if (row.on[indexToUpdate]) {
        const providerCode = row.on[indexToUpdate].providerCode;
        const day = row.on[indexToUpdate].date;
        const selectedTime = row.on[indexToUpdate].time;
        if (row.on[indexToUpdate].time === obj.time) {
          const dayTime = getDayPeriod(
            `${INITIAL_DATE}T${row.on[indexToUpdate].time.substring(0, 5)}`
          );
          handleProviderCode(
            spaAvailabilityCopy,
            day,
            dayTime,
            obj.time,
            "increment",
            providerCode
          );
          row.on[indexToUpdate].time = "";
          row.on[indexToUpdate].providerCode = "";
        } else {
          const dayTime = getDayPeriod(
            `${INITIAL_DATE}T${obj.time.substring(0, 5)}`
          );

          const day = row.on[indexToUpdate].date;
          if (row.on[indexToUpdate].time !== "") {
            const dayTimePrev = getDayPeriod(
              `${INITIAL_DATE}T${row.on[indexToUpdate].time.substring(0, 5)}`
            );
            handleProviderCode(
              spaAvailabilityCopy,
              day,
              dayTimePrev,
              selectedTime,
              "increment",
              providerCode
            );
          }

          const newlySelProviderCode = handleProviderCode(
            spaAvailabilityCopy,
            day,
            dayTime,
            obj.time,
            "decrement",
            null
          );
          row.on[indexToUpdate].time = obj.time;
          row.on[indexToUpdate].providerCode = newlySelProviderCode;
        }
      }
    });

    setSpaAvailability(spaAvailabilityCopy);
    setSpaSelections(spaSelectionsCopy);
  };

  const isTimeSelected = (obj, selTimeIndex) => {
    return spaSelections[0]?.on[selTimeIndex].time === obj.time;
  };

  const notEnoughAvailability = (availabilityAM, availabilityPM) => {
    return (
      spaSelections.length > 1 &&
      !availabilityAM?.some((row) => row.availability >= spaSelections.length) &&
      !availabilityPM?.some((row) => row.availability >= spaSelections.length)
    );
  };

  const getNoAvailabilityForSelectionMsg = () => {
    if (!isDateWithinRange(reservationDetails.ArrivalDate, 90)) {
      return (
        <IonRow className={"bl-margin-t-s"}>
          <IonCol>
            <BLInformationBox
              content={replacePlaceholders(
                dynamicTexts?.Shared?.LackOfAvailability
                  ?.InfoBox_TimeSlot_NoAvailabilityOutside90DaysFromToday,
                {
                  PEOPLE_COUNT: spaSelections.length,
                }
              )}
              type={"warning"}
              markdown={
                !!dynamicTexts?.Shared?.LackOfAvailability
                  ?.InfoBox_TimeSlot_NoAvailabilityOutside90DaysFromToday
              }
            />
          </IonCol>
        </IonRow>
      );
    } else {
      return (
        <IonRow className={"bl-margin-t-s"}>
          <IonCol>
            <BLInformationBox
              content={replacePlaceholders(
                dynamicTexts?.Shared?.LackOfAvailability
                  ?.InfoBox_TimeSlot_NoAvailabilityWithin90DaysFromToday,
                {
                  PEOPLE_COUNT: spaSelections.length,
                }
              )}
              markdown={
                !!dynamicTexts?.Shared?.LackOfAvailability
                  ?.InfoBox_TimeSlot_NoAvailabilityWithin90DaysFromToday
              }
              type={"warning"}
            />
          </IonCol>
        </IonRow>
      );
    }
  };

  const retrieveContent = (date, selTimeIndex, action) => {
    const isDisabled =
      date === getCurrentDate() &&
      itineraryDetails.Dates[date].Spa.find(
        (activity) => activity.Id === serviceDetails.id
      ) &&
      spaSelections[0].on[selTimeIndex].time !== "" &&
      spaSelections[0].on[selTimeIndex].time.slice(0, 5).replace(":", "") <
        format(new Date(), "kkmm");

    let availabilityAM = extractTimeSelections(date, "AM");
    let availabilityPM = extractTimeSelections(date, "PM");

    if (
      !isDisabled &&
      date === getCurrentDate() &&
      availabilityAM &&
      availabilityPM &&
      typeof availabilityAM !== "string" &&
      typeof availabilityPM !== "string"
    ) {
      availabilityAM = availabilityAM.filter(
        (slot) =>
          slot.time.slice(0, 5).replace(":", "") > format(new Date(), "kkmm")
      );
      availabilityPM = availabilityPM.filter(
        (slot) =>
          slot.time.slice(0, 5).replace(":", "") > format(new Date(), "kkmm")
      );
    }

    if (date < getCurrentDate()) {
      return (
        <IonRow className="bl-margin-t-s">
          <IonCol>
            <BLInformationBox
              content={
                dynamicTexts?.Shared?.LackOfAvailability
                  ?.InfoBox_TimeSlot_PastTime
              }
              type="warning"
              markdown
            />
          </IonCol>
        </IonRow>
      );
    } else if (!availabilityAM && !availabilityPM) {
      return (
        <IonRow className="bl-margin-t-s">
          <IonCol>
            <BLInformationBox
              content={
                dynamicTexts?.Shared?.LackOfAvailability
                  ?.InfoBox_TimeSlot_AvailabilityRetrievalFailure
              }
              type="warning"
              markDown={
                !!dynamicTexts?.Shared?.LackOfAvailability
                  ?.InfoBox_TimeSlot_AvailabilityRetrievalFailure
              }
            />
          </IonCol>
        </IonRow>
      );
    } else if (
      availabilityAM === "loading" ||
      availabilityPM === "loading" ||
      isLoadingItinerary
    ) {
      return (
        <IonRow className="ion-text-center">
          <IonCol>
            <BLLoadingContainer />
          </IonCol>
        </IonRow>
      );
    } else if (availabilityAM.length === 0 && availabilityPM.length === 0) {
      return getNoAvailabilityForSelectionMsg();
    } else {
      return (
        <>
          {notEnoughAvailability(availabilityAM, availabilityPM) &&
            getNoAvailabilityForSelectionMsg()}
          {(availabilityAM.length > 0 || availabilityPM.length > 0) && (
            <>
              <IonRow className="bl-margin-t-m" style={{ gap: "16px" }}>
                {availabilityAM.map((obj) => (
                  <BLButton
                    key={obj.shortTime}
                    content={`${obj.shortTime} AM`}
                    disabled={
                      isDisabled ||
                      (obj.availability < spaSelections.length &&
                        !isTimeSelected(obj, selTimeIndex)) ||
                      action === "remove"
                    }
                    type={
                      isTimeSelected(obj, selTimeIndex)
                        ? "time"
                        : "time-outlined"
                    }
                    onClick={() => handleTogetherSelection(obj, selTimeIndex)}
                  />
                ))}
                {availabilityPM.map((obj) => (
                  <BLButton
                    key={obj.shortTime}
                    content={`${obj.shortTime} PM`}
                    disabled={
                      isDisabled ||
                      (obj.availability < spaSelections.length &&
                        !isTimeSelected(obj, selTimeIndex)) ||
                      action === "remove"
                    }
                    type={
                      isTimeSelected(obj, selTimeIndex)
                        ? "time"
                        : "time-outlined"
                    }
                    onClick={() => handleTogetherSelection(obj, selTimeIndex)}
                  />
                ))}
              </IonRow>
            </>
          )}
        </>
      );
    }
  };

  return {
    retrieveContent,
  };
};

export default TimeRowTogetherSelectionLogic;
