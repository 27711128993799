import { IonCol, IonRow } from "@ionic/react";
import React from "react";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";

const ActionButtons = ({
  actionTxt,
  cancelTxt,
  shouldDisable,
  cancelFunction,
  actionFunction,
  isActionInProgress,
  hideActionBtn,
}) => {
  return (
    <IonRow className="ion-justify-content-center bl-margin-t-m">
      <IonCol size="auto">
        <BLButton
          content={cancelTxt}
          type="underlined"
          onClick={cancelFunction}
          disabled={isActionInProgress}
        />
      </IonCol>
      {!hideActionBtn && (
        <IonCol size="auto" className="bl-margin-l-s">
          <BLButton
            content={actionTxt}
            type="primary"
            disabled={shouldDisable || isActionInProgress}
            onClick={actionFunction}
          />
        </IonCol>
      )}
    </IonRow>
  );
};

export default ActionButtons;
