import { useState, useContext } from "react";
import { SpaContext } from "../../../../../../../context/SpaContext";
import { INITIAL_DATE } from "../../../../../../../constants/constants";
import { getDayPeriod } from "../../../../../../../helpingFunctions/dateTimeUtilities";

const DaysRowSingleSelectionLogic = () => {
  const {
    spaSelections,
    setSpaSelections,
    handleProviderCode,
    spaAvailability,
    setSpaAvailability,
  } = useContext(SpaContext);
  const [isEditingMainDate, setIsEditingMainDate] = useState(false);

  const handleTogetherSelection = (indexToUpdate, val) => {
    setIsEditingMainDate(false);
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    const spaAvailabilityCopy = JSON.parse(JSON.stringify(spaAvailability));

    spaSelectionsCopy.forEach((row) => {
      const providerCode = row?.on[indexToUpdate]?.providerCode;
      if(row.on[indexToUpdate]) {
        if (providerCode !== "") {
          const day = row.on[indexToUpdate].date;
          const time = row.on[indexToUpdate].time;
          let dayString = INITIAL_DATE;
          if (time) {
            dayString += `T${time.substring(0, 5)}`;
          }
          const dayTime = getDayPeriod(dayString);
          handleProviderCode(
            spaAvailabilityCopy,
            day,
            dayTime,
            time,
            "increment",
            providerCode
          );
        }
        
        row.on[indexToUpdate].isEdited = false;
        row.on[indexToUpdate].date = val.target.value;
        row.on[indexToUpdate].time = "";
        row.on[indexToUpdate].providerCode = "";
      }
    });
    
    setSpaSelections(spaSelectionsCopy);
    setSpaAvailability(spaAvailabilityCopy);
  };

  const addTogetherDays = () => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    spaSelectionsCopy.forEach((row) => {
      row.on.push({
        date: "",
        time: "",
        bookedDate: "",
        bookedTime: "",
        action: "add",
        providerCode: "",
        bookedProviderCode: "",
      });
    });
    setSpaSelections(spaSelectionsCopy);
  };

  const removeTogetherSelection = (indexToRemove) => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    const spaAvailabilityCopy = JSON.parse(JSON.stringify(spaAvailability));

    spaSelectionsCopy.forEach((row, index) => {
      if(spaSelectionsCopy[index]?.on[indexToRemove]) {
        const providerCode =
          spaSelectionsCopy[index]?.on[indexToRemove]?.providerCode;
        const day = spaSelectionsCopy[index]?.on[indexToRemove]?.date;
        const time = spaSelectionsCopy[index]?.on[indexToRemove]?.time;
        let dayTimeString = INITIAL_DATE;
        if (time) {
          dayTimeString += `T${time.substring(0, 5)}`;
        }
        const dayTime = getDayPeriod(dayTimeString);
        if (providerCode !== "" && spaAvailabilityCopy) {
          handleProviderCode(
            spaAvailabilityCopy,
            day,
            dayTime,
            time,
            "increment",
            providerCode
          );
        }

        if (indexToRemove === 0 && index === 0) {
          setIsEditingMainDate(false);
          spaSelectionsCopy[index].on[indexToRemove].date = "";
          spaSelectionsCopy[index].on[indexToRemove].time = "";
          spaSelectionsCopy[index].on[indexToRemove].providerCode = "";
        } else {
          row.on = row.on.filter((row, dateIndex) => dateIndex !== indexToRemove);
        }
      }
    });

    setSpaSelections(spaSelectionsCopy);
    setSpaAvailability(spaAvailabilityCopy);
  };

  const updateStatus = (dateIndex) => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    spaSelectionsCopy[0].on[dateIndex].isEdited = true;
    setSpaSelections(spaSelectionsCopy);
  };

  return {
    isEditingMainDate,
    setIsEditingMainDate,
    handleTogetherSelection,
    addTogetherDays,
    removeTogetherSelection,
    updateStatus,
  };
};

export default DaysRowSingleSelectionLogic;
