import { useContext, useEffect } from "react";

import { DINING_PARTY_MODALS } from "./diningPartyConstants";

import { useDataContext } from "../../../context/DataContext";
import { DiningPartyContext } from "../../../context/DiningPartyContext";
import RequestHook from "../../../hooks/RequestHook";

const DiningPartyLogic = () => {
  const { setDiningPartyDisplay, groupFetched } =
    useContext(DiningPartyContext);
  const { reservationDetails } = useDataContext();
  const { getGroup } = RequestHook();

  useEffect(() => {
    const createRequest = async () => {
      if (reservationDetails) {
        if (!groupFetched) {
          await getGroup(reservationDetails);
        }
        getModalToDisplay();
      }
    };
    createRequest();
  }, [reservationDetails]);

  const getModalToDisplay = () => {
    try {
      if (
        reservationDetails.IsGroupCreator &&
        reservationDetails.GroupParticipants !== 0
      ) {
        setDiningPartyDisplay(DINING_PARTY_MODALS.DINING_PARTY_LEADER);
        return;
      }

      if (reservationDetails.PartOfGroup) {
        setDiningPartyDisplay(DINING_PARTY_MODALS.DINING_PARTY_PARTICIPANT);
        return;
      }

      setDiningPartyDisplay(DINING_PARTY_MODALS.INIT);
    } catch (error) {
      console.error(
        `${new Date()} Error in getModalToDisplay func in DiningPartyLogic.js ${
          error.message
        }`
      );
    }
  };

  return {};
};

export default DiningPartyLogic;
