import { IonPage, isPlatform } from "@ionic/react";
import queryString from "query-string";
import React, { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";

import DaysList from "../../../components/DaysList_OLD/DaysList";
import Information from "../../../components/InformationAlert_OLD/Information";
import GrayWrapper from "../../../components/UI/GrayWrapper/GrayWrapper";
import Heading from "../../../shared/Heading_OLD/Heading";
import InformationText from "../../../shared/InformationText_OLD/InformationText";
import LoadingContainer from "../../../shared/LoadingContainer_OLD/LoadingContainer";
import WhatsOnGuideLogic from "./WhatsOnGuideLogic";
import Filter from "./components/Filter/Filter";
import GuideItemDesktop from "./components/GuideItemDesktop/GuideItemDesktop";
import GuideItemMobile from "./components/GuideItemMobile/GuideItemMobile";
import GuideTvCarousel from "./components/TV/GuideTvCarousel/GuideTvCarousel";

import { useDataContext } from "../../../context/DataContext";

import { WhatsOnDataContext } from "../../../context/WhatsOnDataContext";
import { DESKTOP_TITLE, MOBILE_TITLE, TV_TITLE } from "./constants/constants";

import InformationPage from "../../../components/InformationPage_OLD/InformationPage";
import BLImageCarousel from "../../../shared/Ionic/Carousel/BLImageCarousel";
import styles from "./WhatsOnGuide.module.scss";

const WhatsOnGuide = () => {
  const { reservationDetails, reservationHotelDetails } = useDataContext();
  const {
    comingSoonData,
    ads,
    guide,
    whatsOnGuideAllDayActivities,
    dates,
    alert,
    isLoading,
    selectedDate
  } = useContext(WhatsOnDataContext);
  const {
    selectedItemId,
    internalActivityItinerary,
    setSelectedItemId,
    internalActivityItineraryHandler,
  } = WhatsOnGuideLogic();

  const { hotel } = useParams();
  const { search } = useLocation();
  const { screenView } = queryString.parse(search);

  const isInMobile = screenView === MOBILE_TITLE || isPlatform(MOBILE_TITLE);
  const isInDesktop = screenView === DESKTOP_TITLE || isPlatform(DESKTOP_TITLE);
  const isInTV = screenView === TV_TITLE;

  let GuideItem = null;
  if (isInMobile) {
    GuideItem = GuideItemMobile;
  } else if (isInDesktop) {
    GuideItem = GuideItemDesktop;
  }

  let AdsDisplay = null;
  
  if (isInDesktop && ads?.length > 0) {
    AdsDisplay = (
      <GrayWrapper newClass={styles.WhatsOnGuideAdsDisplay}>
        <BLImageCarousel
          onClick={() => {}}
          content={ads}
          autoplay
        />
      </GrayWrapper>
    );
  }

  let ErrorMessage = null;
  if (alert.isError) {
    ErrorMessage = (
      <Information
        className={styles.WhatsOnGuide__information}
        fullyColoredClass={"fullyColoredClass"}
        flexDirection={"row"}
        txt={alert.msg}
        marginLeft={"20px"}
        icon={"exclamation.svg"}
      />
    );
  }

  let PageMainSection = null;

  if (isInTV) {
    PageMainSection = <GuideTvCarousel />;
  } else if (isInMobile || isInDesktop) {
    let ManageableScreen = <></>;

    if (isInMobile || isInDesktop) {
      const hotelName = reservationHotelDetails?.Title || hotel;
      const informationText = `Here you can find out all events happening at the ${hotelName} during your stay.`;

      ManageableScreen = (
        <>
          <InformationText
            content={informationText}
            className={styles.WhatsOnGuide__informationText}
          />
          <div
            className={styles.WhatsOnGuide__Filter_wrapper}
            style={{ justifyContent: isInMobile ? "center" : "end" }}
          >
            <Filter isTimeOfDayFilterShown={Boolean(selectedDate)} />
          </div>
          <DaysList 
            data={dates}
            view="WhatsOnGuide"
            selectedItemId={selectedItemId} 
            setSelectedItemId={setSelectedItemId} 
          />
        </>
      );
    }

    PageMainSection = (
      <>
        <span className={styles.WhatsOnGuide__dynamicContent}>
          {ManageableScreen}
          <section className={styles.WhatsOnGuide__articles}>
            <GrayWrapper
              newClass={
                isInDesktop
                  ? "WhatsOnGuideDesktop"
                  : isInMobile
                    ? "WhatsOnGuideMobile"
                    : ""
              }
              newStyles={{
                minWidth: "80%",
                flex: 1,
              }}
            >
              {isLoading ? (
                <LoadingContainer
                  new_styles={{
                    width: "120px",
                    height: "120px",
                    marginTop: "120px",
                  }}
                />
              ) : (
                <>
                  {ErrorMessage}
                  {selectedDate &&
                    guide &&
                    guide.map((guideItem, index) => (
                      <GuideItem
                        key={guideItem.timeId}
                        guideItem={guideItem}
                        guide={guide}
                        itemIndex={index}
                        selectedItemId={selectedItemId}
                        setSelectedItemId={setSelectedItemId}
                        internalActivityItinerary={internalActivityItinerary}
                        internalActivityItineraryHandler={
                          internalActivityItineraryHandler
                        }
                      />
                    ))}
                  {selectedDate === null &&
                    whatsOnGuideAllDayActivities &&
                    whatsOnGuideAllDayActivities.map(
                      (allDayActivity, index) => (
                        <React.Fragment key={index}>
                          <GuideItem
                            key={1}
                            guideItem={allDayActivity}
                            guide={guide}
                            itemIndex={index}
                            selectedItemId={selectedItemId}
                            setSelectedItemId={setSelectedItemId}
                            internalActivityItinerary={
                              internalActivityItinerary
                            }
                            internalActivityItineraryHandler={
                              internalActivityItineraryHandler
                            }
                          />
                        </React.Fragment>
                      )
                    )}
                </>
              )}
            </GrayWrapper>
            {AdsDisplay}
          </section>
        </span>
      </>
    );
  }

  if (Object.keys(comingSoonData).length > 0) {
    return (
      <InformationPage
        image={comingSoonData.ComingSoonImage?.formats?.large?.url || comingSoonData.ComingSoonImage?.url}
        backdropFilter={"blur(25px)"}
        enableHomeButton={reservationDetails}
      >
        <Heading
          txt={comingSoonData.ComingSoonText}
          customHeadingClass={styles.WhatsOnGuide__comingSoonText}
        />
        <InformationText
          content={comingSoonData.ComingSoonDescription}
          className={styles.WhatsOnGuide__ComingSoonDescription}
        />
      </InformationPage>
    );
  }

  return (
    <IonPage style={{overflow: "auto"}}>
      <div className={styles.WhatsOnGuide}>
        <Heading
          txt={"What's on?"}
          customHeadingClass={
            isInTV
              ? styles.WhatsOnGuide__whatsOnHeadingInTV
              : styles.WhatsOnGuide__whatsOnHeading
          }
        />
        {PageMainSection}
      </div>
    </IonPage>
  );
};

export default WhatsOnGuide;
