import React from "react";

import styles from "./loading_container.module.css";

const LoadingContainer = ({ loaderWrapperStyle = {}, loaderSize = null}) => {
  return (
    <div className={styles.loadingStimulator} style={{...loaderWrapperStyle}}>
      <img
        src={"/img/info_icons/loading_.svg"}
        className={styles.loader}
        style={{ width: loaderSize, height: loaderSize }}
        alt="loader"
      />
    </div>
  );
};

export default LoadingContainer;
