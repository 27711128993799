import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import BLInformationBox from "../InformationBox/BLInformationBox";
import BLParagraph from "../../Paragraph/BLParagraph";
import { useTheme } from "../../../../hooks/useTheme";

const BLTipBox = ({ title, description, newClasses }) => {
  const { typography } = useTheme();

  const getTipBoxContent = () => {
    return (
      <IonGrid>
        <IonRow>
          <IonCol>
            <BLParagraph
              txt={title}
              textVariant={typography.NOTIFICATION_TITLE_TEXT}
            />
          </IonCol>
        </IonRow>
        <IonRow
          style={{
            margin: "5px 0",
            height: "1px",
            backgroundColor: "white",
          }}
        />
        <IonRow className="bl-padding-t-xs">
          <IonCol>
            <BLParagraph
              txt={description}
              //newClasses="xxx-small"
              //TODO: Unclear typography
              textVariant={typography.SMALL_PRINT_TEXT}
              markDown
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };
  return (
    <IonRow className={newClasses}>
      <IonCol>
        <BLInformationBox content={getTipBoxContent()} type="tip" />
      </IonCol>
    </IonRow>
  );
};

export default BLTipBox;
