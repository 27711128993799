import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { format } from "date-fns";
import { calendar, closeCircle, pencil, people } from "ionicons/icons";
import React, { Fragment, useEffect, useState } from "react";
import { MAESTRO_BACKEND_NAMING_VIEWS, INITIAL_DATE } from "../../../constants/constants";
import { formatTime, getCurrentDate } from "../../../helpingFunctions/dateTimeUtilities";
import { generateKey } from "../../../helpingFunctions/helpingFunctions";
import BLIcon from "../BLIcon";
import BLButton from "../Buttons/BLButton";
import BLParagraph from "../Paragraph/BLParagraph";
import BLContentCardWrapper from "../Wrappers/BLContentCardWrapper";
import { isBookingInThePast } from "../../../helpingFunctions/conflictManagement/globalConflictHelpersMethods";
import { isDesktop } from "../../../helpingFunctions/utilities";
import { useTheme } from "../../../hooks/useTheme";

const BookedSummaryScreenLogic = (
  source,
  selections,
  setSelections,
  handleBookingRQ,
  setIsEditMode,
  daySelections,
) => {
  const { typography } = useTheme();
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const getSpaGuestNameRow = (sel, margins) => {
    return (
      <IonRow key={generateKey(sel)}>
        <IonCol>
          {isDesktop() && <BLIcon color="primary" iconSource={people} />}
          <BLParagraph
            txt={sel.guestName}
            color="text-grey"
            textVariant={typography.h4}
            dimensions={{
              margin: margins,
            }}
          />
        </IonCol>
      </IonRow>
    );
  };

  const getDayDetailsRow = (daySel) => {
    return (
      <BLParagraph
        key={generateKey(daySel)}
        txt={
          <>
            {isDesktop() && (
              <BLIcon
                color="primary"
                iconSource={calendar}
                newClasses="bl-padding-r-s"
              />
            )}
            <BLParagraph
              txt={daySelections?.find((row) => row.id === daySel.date)?.Title}
              color="text-grey"
              textVariant={typography.h4}
            />
            <BLParagraph
              txt={`at ${formatTime(
                `${INITIAL_DATE}T${daySel.time.substring(0, 5)}`,
                true
              )}`}
              color="text-grey"
              textVariant={typography.h4}
              dimensions={{
                margin: ["l-xs"],
              }}
            />
          </>
        }
      />
    );
  };

  const getActivityQuantityRow = (daySel) => {
    return (
      <IonRow className={isDesktop() ? "" : "ion-justify-content-between"}>
        {isDesktop() && (
          <BLIcon
            color="primary"
            iconSource={people}
            newClasses="bl-padding-r-s"
          />
        )}
        <BLParagraph
          txt={`${daySel.quantity} guest${daySel.quantity === 1 ? "" : "s"}`}
          color="text-grey"
          textVariant={typography.h4}
          typographyStyles={daySel.action === "remove" ? { textDecoration: "line-through" } : {}}
        />
      </IonRow>
    );
  };

  const extractDaySelection = () => {
    const content = [];

    selections.forEach((sel, index) => {
      const guestContent = [];
      const margins = ["l-xs"];

      if (index !== 0) {
        margins.push("t-s");
      }

      if (source === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
        guestContent.push(getSpaGuestNameRow(sel, margins));
      }

      sel.on.forEach((daySel) => {
        if (source !== MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
          content.push(
            <BLContentCardWrapper
              key={generateKey(daySel)}
              background={isDesktop() ? "light" : "white"}
              dimensions={{ padding: ["t-s", "b-s", isDesktop() ? "s" : ""] }}
            >
              <IonGrid
                className="bl-padding-l-xs"
                style={{ gap: "8px", display: "grid" }}
              >
                {getDayDetailsRow(daySel)}

                {getActivityQuantityRow(daySel)}
              </IonGrid>
            </BLContentCardWrapper>
          );
        } else {
          guestContent.push(<Fragment key={generateKey(daySel)}>{getDayDetailsRow(daySel)}</Fragment>);
        }
      });

      if (source === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
        content.push(
          <BLContentCardWrapper
            background={isDesktop() ? "light" : "white"}
            dimensions={{ padding: ["t-s", "b-s", isDesktop() ? "s" : ""] }}
          >
            <IonGrid style={{ gap: "8px", display: "grid" }}>
              {guestContent}
            </IonGrid>
          </BLContentCardWrapper>
        );
      }
    });
    return content;
  };

  const cancelAllBookings = async () => {
    const selectionsCopy = JSON.parse(JSON.stringify(selections));
    const currentDay = getCurrentDate();
    const currentTime = format(new Date(), "kk:mm");
    selectionsCopy.forEach((sel) => {
      sel.action = "remove";
      sel.on.forEach((dayTime) => {
        if (!isBookingInThePast(dayTime.bookedDate, dayTime.bookedTime, currentDay, currentTime)) {
          dayTime.action = "remove";
        }
      });
    });
    setSelections(selectionsCopy);
    await handleBookingRQ(selectionsCopy, true);
  };

  const isCancelAllButtonDisabled = () => {
    const selectionsCopy = JSON.parse(JSON.stringify(selections));
    const currentDay = getCurrentDate();
    const currentTime = format(new Date(), "kk:mm");
    return selectionsCopy
      .every(sel => sel.on
        .every(booking => {
          return isBookingInThePast(booking.bookedDate, booking.bookedTime, currentDay, currentTime);
        })
      );
  };

  const getActionButtonsRow = () => {
    return (
      <IonRow
        className={`${isDesktop() ? "bl-margin-t-m" : "bl-margin-t-m"} ${isDesktop() ? "ion-justify-content-center" :
          "ion-justify-content-start"} ${isDesktop() ? "ion-align-items-center" : "ion-align-items-start"}`}
        style={{
          flexDirection: isDesktop() ? "row" : "column",
          gap: isDesktop() ? "8px" : "16px",
        }}
      >
        <BLButton
          size="small"
          newClasses="bl-margin-t-s medium"
          type="icon-text"
          content="Change"
          icon={pencil}
          iconSize="md"
          onClick={() => setIsEditMode(true)}
        />
        <BLButton
          size="small"
          newClasses="medium"
          disabled={isCancelAllButtonDisabled()}
          type="icon-text"
          content="Cancel Booking"
          icon={closeCircle}
          iconSize="md"
          onClick={() => setShowCancelDialog(true)}
        />
      </IonRow>
    );
  };

  useEffect(() => {
    setShowCancelDialog(false);
  }, [selections]);

  return {
    showCancelDialog,
    setShowCancelDialog,
    extractDaySelection,
    cancelAllBookings,
    getActionButtonsRow,
  };
};

export default BookedSummaryScreenLogic;
