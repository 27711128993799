import { useContext, useEffect, useState } from "react";

import {
  ACTION_TYPES,
  SERVICES_TYPES,
  SUCCESS_STATUS,
} from "../../../constants/constants";

import { useDataContext } from "../../../context/DataContext";
import { DiningPartyContext } from "../../../context/DiningPartyContext";
import { createObjectListOfAttrValues } from "../../../helpingFunctions/arrays";
import RequestHook from "../../../hooks/RequestHook";

import { DiningBookingContext } from "../../../context/DiningBookingContext";
import { isPast } from "date-fns";
import { DINING_PARTY_ACTION_TYPES, DINING_PARTY_MODALS, DINING_PARTY_NAME_ATTR, GLOBAL_DINING_PARTY_ATTRS, PARTICIPANT_CRS_NUMBER_ATTR, PARTICIPANT_SURNAME_ATTR } from "./diningPartyConstants";

const INITIAL_FORM_STATE = {
  [DINING_PARTY_NAME_ATTR]: "",
  [PARTICIPANT_SURNAME_ATTR]: "",
  [PARTICIPANT_CRS_NUMBER_ATTR]: "",
};

const useDiningPartyRequests = () => {
  const {
    postCreateGroup,
    getGroup,
    getActivitiesAvailability,
    validateDiningPartyReservation,
  } = RequestHook();
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isGroupNameInvalid, setIsGroupNameInvalid] = useState(false);

  const { reservationDetails, setReservationDetails } = useDataContext();
  const {
    diningPartySelections: { participants, id },
    setDiningPartySelections,
  } = useContext(DiningPartyContext);

  const {
    setResponse,
    diningPartyFormData,
    setDiningPartyFormData,
    setDiningPartyDisplay,
    setLinkResNewPopUp,
    setShowDiningPartyParticipantForm,
    linkNewResPopUp,
    setIsPostGrCreationPartAddedLoading,
    setIsGroupActionLoading,
  } = useContext(DiningPartyContext);

  const { prepareLeadGuestBookings, handleDiningBookingsForParticipants } =
    useContext(DiningBookingContext);

  const bookForNewReservation = async () => {
    try {
      setIsPostGrCreationPartAddedLoading(true);
      setIsGroupActionLoading(true);
      const selections = prepareLeadGuestBookings();

      const groupData = await createGroup();

      const participantBookingsToAdd = [];
      for (let diningBookings of selections) {
        if (diningBookings.action === ACTION_TYPES.NONE) {
          for (
            let participantsIndex = 0;
            participantsIndex < participants.length;
            participantsIndex++
          ) {
            const participant = participants[participantsIndex];
            if (
              (diningBookings.dinningType === SERVICES_TYPES.DINNER ||
              diningBookings.dinningType === SERVICES_TYPES.BREAKFAST) && !isPast(new Date(diningBookings.date))
            ) {
              participantBookingsToAdd.push({
                LastName: participant.LastName,
                FirstName: participant.FirstName,
                ReservationNumber: participant.ReservationNumber,
                Quantity: participant.Adults,
                action: diningBookings.isSkipped
                  ? ACTION_TYPES.NOT_REQ
                  : ACTION_TYPES.ADD,
                isPostGrCreationPartAdded: true,
                date: diningBookings.date,
                dinningType: diningBookings.dinningType,
                id: diningBookings.restaurantId,
                time: diningBookings.time,
                type: diningBookings.type,
                isSkipped: diningBookings.isSkipped,
                isSkippedBooked: diningBookings.isSkippedBooked,
                Date: diningBookings.isSkipped
                  ? diningBookings.date
                  : `${diningBookings.date}T${diningBookings.time}`,
              });
            }
          }
        }
      }

      await handleDiningBookingsForParticipants(participantBookingsToAdd, groupData?.groupId);

      setIsPostGrCreationPartAddedLoading(false);
      setLinkResNewPopUp(false);
      setIsGroupActionLoading(false);
    } catch (error) {
      setIsGroupActionLoading(false);
      setIsPostGrCreationPartAddedLoading(false);
      console.error(
        `${new Date()} Error in bookForNewReservation in DiningPartyInitLogic.js ${
          error.message
        }`
      );
    }
  };

  const validateReservation = async () => {
    try {
      const isSameAdded = participants.find(
        (part) =>
          part.CRSNumber === diningPartyFormData.CRSNum &&
          part.LastName === diningPartyFormData.Surname
      );

      if (isSameAdded) {
        setResponse({
          actionType: DINING_PARTY_ACTION_TYPES.VALIDATE,
          isLoading: false,
          status: "Finished",
          message: `Reservation with Holiday Reference Number: ${diningPartyFormData.CRSNum} and surname: ${diningPartyFormData.Surname} is already added to your dining party.`,
          infoBoxColor: "danger",
        });
        return;
      }

      setIsGroupActionLoading(true);
      setResponse({
        actionType: DINING_PARTY_ACTION_TYPES.VALIDATE,
        isLoading: true,
        status: "Pending",
        message: null,
        infoBoxColor: null,
      });

      const validatedRes = await validateDiningPartyReservation(
        diningPartyFormData[PARTICIPANT_SURNAME_ATTR],
        diningPartyFormData[PARTICIPANT_CRS_NUMBER_ATTR]
      );

      const isSuccess = validatedRes.status === SUCCESS_STATUS;
      const participantAttr = GLOBAL_DINING_PARTY_ATTRS.PARTICIPANTS;

      setResponse({
        actionType: DINING_PARTY_ACTION_TYPES.VALIDATE,
        isLoading: false,
        status: "Finished",
        message: validatedRes.msg,
        infoBoxColor: isSuccess ? "success" : "danger",
      });
      setDiningPartyFormData((prevState) => {
        return {
          ...INITIAL_FORM_STATE,
          [DINING_PARTY_NAME_ATTR]: prevState[DINING_PARTY_NAME_ATTR],
        };
      });

      if (isSuccess) {
        setDiningPartySelections((prevState) => {
          return {
            ...prevState,
            [participantAttr]: [
              ...prevState[participantAttr],
              validatedRes.validReservation,
            ],
          };
        });
        setReservationDetails((prevState) => {
          return { ...prevState, IsGroupCreator: true };
        });
        setShowDiningPartyParticipantForm(false);
      }
      setIsGroupActionLoading(false);
    } catch (error) {
      setIsGroupActionLoading(false);
      console.error(
        `${new Date()} Error in validateParticipant in DiningPartyInitLogic.js ${
          error.message
        }`
      );
    }
  };

  const createGroup = async () => {
    try {
      if (diningPartyFormData.diningPartyName === "") {
        setIsGroupNameInvalid(true);
        return;
      } else {
        setIsGroupNameInvalid(false);
      }

      if (
        (reservationDetails.Breakfast || reservationDetails.Dinner) &&
        !linkNewResPopUp 
      ) {
        setLinkResNewPopUp(true);
        return;
      }

      const actionType = id
        ? DINING_PARTY_ACTION_TYPES.MODIFY
        : DINING_PARTY_ACTION_TYPES.CREATE;

      const participantHashes = createObjectListOfAttrValues(
        participants,
        "LinkHash",
        "hash"
      );
      setResponse({
        actionType,
        isLoading: true,
        status: "Pending",
        message: null,
        infoBoxColor: null,
      });
      setIsGroupActionLoading(true);

      const createGroupRes = await postCreateGroup(
        participantHashes,
        diningPartyFormData.diningPartyName
      );

      const isSuccess = createGroupRes.status === SUCCESS_STATUS;

      if (!isSuccess) {
        setResponse({
          actionType,
          isLoading: false,
          status: "Finished",
          message: createGroupRes.msg,
          infoBoxColor: "danger",
        });
        setIsGroupActionLoading(false);
        return;
      }

      const groupData = await getGroup(reservationDetails);
      await getActivitiesAvailability(Number(reservationDetails.Adults));

      let totalAdultsInGroup = reservationDetails.Adults;
      participants.forEach((part) => {
        totalAdultsInGroup += part.Adults;
      });

      setReservationDetails((prevState) => {
        return {
          ...prevState,
          IsGroupCreator: true,
          PartOfGroup: true,
          GroupParticipants: totalAdultsInGroup,
        };
      });
      setResponse({
        actionType,
        isLoading: false,
        status: "Finished",
        message: createGroupRes.msg,
        infoBoxColor: "success",
      });
      setIsGroupActionLoading(false);
      setDiningPartyDisplay(DINING_PARTY_MODALS.DINING_PARTY_LEADER);
      return groupData;
    } catch (error) {
      setIsGroupActionLoading(false);
      console.error(
        `${new Date()} Error in createGroup func in CreateGroupLogic.js ${
          error.message
        }`
      );
    }
  };

  const formFillHandler = () => {
    try {
      const formDataKeys = Object.keys(diningPartyFormData).filter(
        (row) => row !== "diningPartyName"
      );
      const allKeysNum = formDataKeys.length;
      let filledKeysCount = 0;
      for (let index = 0; index < allKeysNum; index++) {
        const key = formDataKeys[index];

        if (
          diningPartyFormData[key] !== "" &&
          diningPartyFormData[key] !== null &&
          diningPartyFormData[key] !== undefined
        ) {
          filledKeysCount++;
        }
      }

      const isFilled = filledKeysCount === allKeysNum;
      setIsFormFilled(isFilled);
    } catch (error) {
      console.error(
        `${new Date()} Error in formFillHandler in DiningPartyInitLogic.js ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    formFillHandler();
  }, [diningPartyFormData]);

  return {
    isFormFilled,
    isGroupNameInvalid,
    setIsGroupNameInvalid,
    validateReservation,
    createGroup,
    bookForNewReservation,
  };
};

export default useDiningPartyRequests;
