import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { format } from "date-fns";
import React, { Fragment, useContext } from "react";
import { ActivityContext } from "../../../../../../context/ActivityContext";
import { useDataContext } from "../../../../../../context/DataContext";
import { getCurrentDate } from "../../../../../../helpingFunctions/dateTimeUtilities";
import BLLoadingContainer from "../../../../../../shared/Ionic/BLLoadingContainer";
import BLInformationBox from "../../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import AvailableTimesForDate from "./AvailableTimesForDate";
import { useTheme } from "../../../../../../hooks/useTheme";

const TimesRowContent = () => {
  const { typography } = useTheme();
  const { isLoadingItinerary, itineraryDetails, dynamicTexts } =
  useDataContext();
  const { activitySelections, selectedService, daySelections } = useContext(ActivityContext);

  return (
    <IonGrid className={"bl-margin-b-m ion-align-items-center"}>
      {isLoadingItinerary ? (
        <BLLoadingContainer />
      ) : (
        <IonGrid>
          {activitySelections[0]?.on
            .filter((item) => item.action !== "remove")
            .map((row, selTimeIndex) => (
              <Fragment key={row.uniqueKey || selTimeIndex}>
                <IonRow
                  style={{
                    borderBottom: "1px solid var(--ion-color-primary)",
                  }}
                >
                  <IonCol className={"ion-text-end bl-margin-t-xs"}>
                    <BLParagraph
                      txt={
                        daySelections?.find((daySel) => daySel.id === row.date)
                          ?.fullTitle
                      }
                      color={"primary"}
                      textVariant={typography.h4}
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonGrid>
                    {itineraryDetails.Dates[row.date]?.Activity.find(
                      (activity) => activity.Id === selectedService?.id
                    ) &&
                      row.date === getCurrentDate() &&
                      row.time !== "" &&
                      row.time.slice(0, 5).replace(":", "") <
                        format(new Date(), "kkmm") && (
                      <IonRow style={{ padding: "16px 0" }}>
                        <BLInformationBox
                          content={
                            dynamicTexts?.Shared?.LackOfAvailability
                              ?.InfoBox_TimeSlot_PastTime
                          }
                          type={"warning"}
                          markdown={true}
                        />
                      </IonRow>
                    )}
                    <IonRow>
                      <IonGrid>
                        <AvailableTimesForDate
                          date={row.date}
                          selIndex={0}
                          selTimeIndex={selTimeIndex}
                        />
                      </IonGrid>
                    </IonRow>
                  </IonGrid>
                </IonRow>
              </Fragment>
            ))}
        </IonGrid>
      )}
    </IonGrid>
  );
};

export default TimesRowContent;
