import React, { useContext, useState } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import { closeCircle, time, pricetag } from "ionicons/icons";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import { formatPrice } from "../../../../../helpingFunctions/formatPrice";
import { useTheme } from "../../../../../hooks/useTheme";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import { SpaContext } from "../../../../../context/SpaContext";
import { useDataContext } from "../../../../../context/DataContext";
import BLLink from "../../../../../shared/Ionic/Links/BLAnchor/BLLink";
import { formatDuration } from "../../../../../helpingFunctions/formatDuration";

const AdditionalSpaDetails = ({
  dismissAlert,
  images,
  header,
  duration,
  price,
  longDescription,
  contraindications,
  initialLoad,
  hasBooking,
  setSelectedService,
}) => {
  const { typography } = useTheme();
  const { isLoadingItinerary } = useDataContext();
  const { setShowBookingSection, setShowAdditionalDetails } =
    useContext(SpaContext);
  const [showContraindications, setShowContraindications] = useState(false);

  return (
    <BLContentCardWrapper dimensions={{ padding: ["s"] }}>
      <IonGrid className="bl-flex" style={{ height: "500px" }}>
        <IonRow className="bl-flex ion-align-items-center">
          <IonCol style={{ height: "100%", width: "275px" }}>
            <BLImageCarousel content={images} showArrows />
          </IonCol>
        </IonRow>
        <IonRow style={{ width: "100%" }}>
          <IonCol style={{ height: "100%" }}>
            <IonGrid
              className="bl-flex-column bl-padding-t-xs bl-margin-l-m"
              style={{ height: "100%" }}
            >
              <IonRow className="ion-margin-bottom bl-flex ion-align-items-center ion-justify-content-between">
                <IonCol>
                  <BLParagraph
                    txt={header}
                    color="text-grey"
                    textVariant={typography.h2}
                    // newClasses={`xx-large bold crimson-pro`}
                    //TODO: Unclear typography
                  />
                </IonCol>
                <IonCol size="auto" className="bl-margin-l-s">
                  <BLIcon
                    iconSource={closeCircle}
                    color="primary"
                    iconSize="md"
                    onClick={() => {
                      dismissAlert(false);
                      setSelectedService(null);
                    }}
                  />
                </IonCol>
              </IonRow>

              <IonRow className="bl-margin-b-s" style={{ gap: "15px" }}>
                <IonCol
                  className="bl-flex ion-align-items-center ion-justify-content-start"
                  style={{ gap: "5px" }}
                  size="auto"
                >
                  <BLIcon iconSource={pricetag} color="primary" />
                  <BLParagraph
                    txt={formatPrice(price)}
                    color="text-grey"
                    textVariant={typography.h4}
                  />
                </IonCol>
                <IonCol
                  className="bl-flex ion-align-items-center"
                  style={{ gap: "5px" }}
                >
                  <BLIcon iconSource={time} color="primary" />
                  <BLParagraph
                    txt={formatDuration(duration)}
                    color="text-grey"
                    textVariant={typography.h4}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <BLParagraph
                    txt="Description"
                    stylingType="sd"
                    color="text-grey"
                    textVariant={typography.h5}
                    // newClasses={`x-small bold open-sans`}
                    //TODO: Unclear typography
                  />
                </IonCol>
              </IonRow>

              <IonGrid style={{ overflow: "auto" }} className="bl-margin-t-s">
                <IonRow>
                  <BLParagraph
                    txt={longDescription}
                    color="text-grey"
                    textVariant={typography.BODY_TEXT}
                    // newClasses={`x-small`}
                    //TODO: Unclear typography
                    markDown={!!longDescription}
                  />
                </IonRow>
              </IonGrid>
              <IonRow className="bl-margin-t-s">
                <IonCol>
                  {showContraindications ? (
                    <IonGrid
                      className="bl-padding-xs"
                      style={{
                        background: "white",
                        maxHeight: "150px",
                        overflow: "auto",
                      }}
                    >
                      <IonRow className="bl-margin-b-xs">
                        <IonCol className="bl-flex ion-justify-content-between">
                          <BLParagraph
                            txt="Contraindications"
                            color="text-grey"
                            textVariant={typography.h4}
                          />
                          <BLLink
                            txt="Close"
                            onClick={() => setShowContraindications(false)}
                            color="secondary"
                          />
                        </IonCol>
                      </IonRow>
                      {contraindications.map((row) => {
                        return (
                          <IonRow
                            key={JSON.stringify(row)}
                            className="bl-margin-l-xs"
                          >
                            <IonCol>
                              <BLParagraph
                                txt={`- ${row}`}
                                color="text-grey"
                                textVariant={typography.h4}
                              />
                            </IonCol>
                          </IonRow>
                        );
                      })}
                    </IonGrid>
                  ) : (
                    <BLLink
                      txt="View contraindications"
                      color="secondary"
                      onClick={() => setShowContraindications(true)}
                    />
                  )}
                </IonCol>
              </IonRow>
              <IonRow className="bl-margin-t-s">
                {isLoadingItinerary ? (
                  <IonCol className="ion-text-center">
                    <BLLoadingContainer newClasses="bl-margin-xxxs" />
                  </IonCol>
                ) : (
                  <IonCol className="bl-flex ion-justify-content-center">
                    <BLButton
                      content={hasBooking ? "Change booking" : "Book now"}
                      type="primary"
                      onClick={() => {
                        initialLoad();
                        setShowBookingSection(true);
                        setShowAdditionalDetails(false);
                      }}
                    />
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </BLContentCardWrapper>
  );
};

export default AdditionalSpaDetails;
