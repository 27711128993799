import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import BLDateTimeFormatParagraph from "../Paragraph/BLDateTimeFormatParagraph";
const BLSelect = ({
  disable,
  placeholder,
  dropdownList = [],
  value,
  onChange,
  boxHeightClass,
  onBlur,
  classes,
  selectedText
}) => {
  const formattedData = dropdownList.map((item) => {
    return {
      label: item.Title,
      ...item,
    };
  });

  const existingOptionValue = (value) => {
    let valuePresent;
    if (value) {
      valuePresent = dropdownList.find((item) => item.id === value)?.id;
    }
    return valuePresent || "";
  };

  return (
    <FormControl
      fullWidth
      className={`${classes} no-ripple
      ${`bl-box-${boxHeightClass ? boxHeightClass : "height-default"}`}`}
    >
      <Select
        fullWidth
        className={`${`bl-box-${
          boxHeightClass ? boxHeightClass : "height-default"
        }`}`}
        value={existingOptionValue(value)}
        onChange={onChange}
        placeholder={placeholder}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return (
              <p
                style={{
                  fontFamily: "Open Sans",
                  color: "var(--ion-color-mid-grey)",
                  textAlign: "left",
                }}
              >
                {selectedText ? selectedText : placeholder}
              </p>
            );
          }
          const selectedOption = formattedData.find(
            (item) => item.id === selected
          )?.label;
          return selectedOption || "";
        }}
        disabled={disable || dropdownList?.length === 0}
        onBlur={onBlur}
        sx={{
          backgroundColor: "white",
          borderRadius: "7px",
          color: "var(--ion-color-text-grey)",
          "& .MuiSvgIcon-root.MuiSelect-icon": {
            height: 32,
            width: 32,
            top: "auto",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid var(--ion-color-secondary) !important",
          },
          "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
            paddingInlineStart: "calc(16px + var(--ion-safe-area-left, 0px))",
          },
        }}
        MenuProps={{ sx: { maxHeight: "45% !important" } }}
      >
        {formattedData.map((item) => (
          <MenuItem key={item.id} value={item.id} disabled={item.disableOption}>
            {typeof item.label === "object" ? (
              <BLDateTimeFormatParagraph date={item.id} view={item.view} />
            ) : (
              item.label
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BLSelect;
