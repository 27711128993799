import React from "react";
import { IonText } from "@ionic/react";

import BLTypography from "../../../components/BLTypography/BLTypography";
import CreateDimensions from "../../../helpingFunctions/DimensionsFactory";

import { classFormatter } from "../BLIonicHelpers";
import { TEXT_CSS_CLASS_IDENTIFIER } from "../BLIonicConstants";
import BLMarkdown from "../Markdown/BLMarkdown";

import styles from "./BLParagraph.module.scss";

const BLParagraph = ({
  txt = null,
  newClasses = "body",
  onClick,
  dimensions = {},
  newStyles = {},
  typographyStyles = {},
  color,
  markDown,
  center,
  ellipsisOn3rdRow,
  ionTextDirectClasses,
  children,
  textVariant,
}) => {
  const dimensionStyles = CreateDimensions(dimensions);

  const paragraphSpecificClasses = classFormatter(
    newClasses,
    TEXT_CSS_CLASS_IDENTIFIER
  );

  return (
    <IonText
      className={`
        ${paragraphSpecificClasses} ${dimensionStyles.dimensions} 
        ${center ? "ion-text-center" : ""} ${
      ellipsisOn3rdRow ? "ellipsis-3rd-row" : ""
    } 
        ${newClasses.includes("ellipsis") ? styles.ellipsis : ""} 
        ${newClasses.includes("text-ellipsis") ? styles["text-ellipsis"] : ""} 
        ${ionTextDirectClasses}
      `}
      color={color}
      style={{
        cursor: onClick ? "pointer" : "inherit",
        wordBreak: "break-word",
        ...newStyles,
      }}
      onClick={onClick ? onClick : null}
    >
      <BLTypography style={typographyStyles} variant={textVariant}>
        {markDown && txt ? <BLMarkdown>{txt}</BLMarkdown> : txt}
        {children}
      </BLTypography>
    </IonText>
  );
};

export default BLParagraph;
