import React from "react";

import { IonPage } from "@ionic/react";
import Navigator from "../../../components/NavigatorIonic/Navigator";
import WelcomeModal from "../../../components/NavigatorIonic/components/WelcomeModal/WelcomeModal";
import { isMobile } from "../../../helpingFunctions/utilities";

const LandingPage = () => {

  return (
    <IonPage style={{overflow: "auto"}}>
      {isMobile() ? (
        <Navigator />
      ) : (
        <WelcomeModal />
      )}
    </IonPage>
  );
};

export default LandingPage;
