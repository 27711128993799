import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize, {defaultSchema} from 'rehype-sanitize';
import remarkGfm from "remark-gfm";
import MarkdownBreakLine from "./components/MarkdownBreakLine";
import MarkdownLink from "./components/MarkdownLink";
import MarkdownCode from "./components/MarkdownCode";
import MarkdownQuote from "./components/MarkdownQuote";
import MarkdownList from "./components/MarkdownList";

const BLMarkdown = ({children}) => {

  return (
    <ReactMarkdown 
      rehypePlugins={[rehypeRaw, [rehypeSanitize, {
        ...defaultSchema,
        attributes: {
          ...defaultSchema.attributes,
          '*': [...defaultSchema.attributes['*'], 'style']
        }
      }]]}
      remarkPlugins={[remarkGfm]}
      linkTarget="_blank"
      components={{
        hr: (props) => <MarkdownBreakLine {...props} />,
        a: (props) => <MarkdownLink {...props} />,
        code: (props) => <MarkdownCode {...props} />,
        blockquote: (props) => <MarkdownQuote {...props} />,
        ul: (props) => <MarkdownList {...props} type="unordered"/>,
        ol: (props) => <MarkdownList {...props}/>
      }}
    >
      {children}
    </ReactMarkdown>
  );

};

export default BLMarkdown;
