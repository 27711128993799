import React from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import { addNew } from "../../../../../assets/SVGIcons";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import { useTheme } from "../../../../../hooks/useTheme";
import BLLink from "../../../../../shared/Ionic/Links/BLAnchor/BLLink";

const BookingPrompt = ({ title, subTitle, onClick }) => {
  const { typography } = useTheme();

  return (
    <IonGrid
      style={{ display: "flex", marginTop: 8, marginBottom: 8, marginLeft: 87 }}
    >
      <IonRow>
        <IonCol
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            marginRight: 8,
          }}
        >
          <BLIcon customIconSource={addNew(25, "var(--ion-color-primary)")} />
        </IonCol>
        <IonCol
          size="auto"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <BLParagraph 
            txt={title}
            textVariant={typography.TOOLTIP_HIGHLIGHT_TEXT}
          />
          <BLLink
            txt={subTitle}
            onClick={onClick}
            color="tertiary"
            smallLink
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default BookingPrompt;
