import React, { useEffect } from "react";

import { IonPage } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useDataContext } from "../../../context/DataContext";
import Button from "../../../shared/Button_OLD/Button";
import styles from "./Kiosk.module.css";
import KioskLogic from "./KioskLogic";

const Kiosk = () => {
  const history = useHistory();
  const { kioskData } = KioskLogic();
  const { setIsKioskRedirect } = useDataContext();

  useEffect(() => {
    let zendeskTryCount = 0;
    const checkForZendeskInterval = setInterval(() => {
      const zendesk = document.getElementById("launcher");
      if (zendesk) {
        zendesk.style.display = "none";
        clearInterval(checkForZendeskInterval);
      }

      if (zendeskTryCount >= 10) {
        clearInterval(checkForZendeskInterval);
      }
      zendeskTryCount++;
    }, 5000);
  }, []);

  return (
    <IonPage>
      <div
        className={styles.Kiosk}
        style={{ backgroundImage: `url(${kioskData?.KioskImage?.url})` }}
      >
        <div className={styles.Login__btn__container}>
          <Button
            txt={"Login Manually"}
            onClick={() => {
              setIsKioskRedirect(true);
              history.push("/");
            }}
            color={"var(--ion-color-primary)"}
            backgroundColor={"var(--ion-color-white)"}
            className={styles.Login__btn}
          />
        </div>
      </div>
    </IonPage>
  );
};

export default Kiosk;
