import { IonGrid, IonPage, IonRow } from "@ionic/react";
import React from "react";
import BLParagraph from "../shared/Ionic/Paragraph/BLParagraph";
import { ROUTE_VIEWS } from "./routeViews";
import { useHistory } from "react-router-dom";
import { useTheme } from "../hooks/useTheme";
import BLLink from "../shared/Ionic/Links/BLAnchor/BLLink";

const FallbackRoute = () => {
  const { typography } = useTheme();
  const history = useHistory();
  return (
    <IonPage>
      <IonGrid className="bl-flex-column ion-justify-content-center">
        <IonRow className="bl-flex ion-justify-content-center">
          <BLParagraph txt="Page not found" textVariant={typography.h1} />
        </IonRow>
        <IonRow className="bl-flex ion-justify-content-center bl-padding-t-s">
          <BLLink
            txt="Go back to home page"
            color="tertiary"
            onClick={() => history.push(ROUTE_VIEWS.LANDING_PAGE)}
          />
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default FallbackRoute;
