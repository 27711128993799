import React, { useContext, useEffect, useState } from "react";

import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { home, informationCircle } from "ionicons/icons";
import { useLocation } from "react-router-dom";
import { useDataContext } from "../../context/DataContext";
import { NavigatorContext } from "../../context/NavigatorContext";
import { PageLoaderContext } from "../../context/PageLoaderContext";
import { getCurrentDate } from "../../helpingFunctions/dateTimeUtilities";
import { replacePlaceholders } from "../../helpingFunctions/replacePlaceholders";
import { ROUTE_VIEWS } from "../../routes/routeViews";
import BLAlertPopUp from "../../shared/Ionic/Boxes/Popup/BLAlertPopUp";
import BLHomePageTile from "../../shared/Ionic/Cards/BLHomePageTile/BLHomePageTile";
import BlurryWrapper from "../../shared/Ionic/Wrappers/BlurryWrapper";
import styles from "./Navigator.module.scss";
import CountdownTimer from "./components/CountdownTimer/CountdownTimer";
import DesktopMenuItem from "./components/DesktopMenuItem/DesktopMenuItem";
import WelcomeTile from "./components/WelcomeTile/WelcomeTile";
import { isDesktop } from "../../helpingFunctions/utilities";
import BLLoadingContainer from "../../shared/Ionic/BLLoadingContainer";
import { SERVICES_TYPES } from "../../constants/constants";

const Navigator = () => {
  const { reservationDetails, reservationHotelDetails, isLoadingHotelData, pagesConfiguration } =
    useDataContext();
  const { warnerServices, redirectBetweenPages } = useContext(NavigatorContext);
  const { isLoaderOpened, setIsLoaderOpened, setLoaderMessage } =
    useContext(PageLoaderContext);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [bgImg, setBgImg] = useState(null);
  const [welcomeImg, setWelcomeImg] = useState(null);
  const [hasArrived, setHasArrived] = useState(null);
  const [disabledItemDialog, setDisabledItemDialog] = useState(null);

  const location = useLocation();

  const setImages = () => {
    const bgImage = new Image();
    const welcomeImage = new Image();
    bgImage.src = isDesktop()
      ? pagesConfiguration?.DesktopSidebar?.BackgroundImage?.url
      : reservationHotelDetails?.HomepageBackgroundImage?.url;
    welcomeImage.src =
      reservationHotelDetails?.Images[0]?.formats?.small?.url ||
      reservationHotelDetails?.Images[0]?.url;
    setBgImg(bgImage);
    setWelcomeImg(welcomeImage);
  };

  const onClickHandler = (
    isDisabled,
    title,
    disabledText,
    daysUntilEnabled,
    type
  ) => {
    if (isDisabled) {
      setDisabledItemDialog({
        title,
        description: disabledText,
        daysUntilEnabled,
      });
    } else {
      redirectBetweenPages(`/${type}`);
    }
  };
  const isLoadingNavigator = () => {
    return (
      warnerServices?.length === 0 ||
      !reservationDetails ||
      isLoadingHotelData ||
      isLoadingImages
    );
  };

  useEffect(() => {
    setIsLoadingImages(true);
    setImages();
    setIsLoadingImages(false);
    if (reservationDetails) {
      setHasArrived(
        getCurrentDate() >= reservationDetails.ArrivalDate?.slice(0, 10)
      );
    }
  }, [reservationHotelDetails, reservationDetails, pagesConfiguration]);

  // This checks (&& !isLoaderOpened) and (!isLoadingHotelData) is needed in order not to change loader
  // message when we add new reservation
  useEffect(() => {
    if (!isDesktop()) {
      if (isLoadingNavigator() && !isLoaderOpened) { 
        setIsLoaderOpened(true);                     
        setLoaderMessage("Loading Home Page");
      } else if (!isLoadingHotelData) {
        setIsLoaderOpened(false);
      }
    }
  }, [warnerServices, reservationDetails, isLoadingHotelData, isLoadingImages]);

  return isDesktop() && isLoadingNavigator() ? (
    <div className={styles.LoaderContainer}>
      <BLLoadingContainer newClasses="bl-icon-xl" color="tertiary" />
    </div>
  ) : (
    !isLoaderOpened && (
      <div
        className={styles.ScrollComponent}
        style={{
          backgroundImage: `url(${bgImg?.src})`,
        }}
      >
        {!isDesktop() && (
          <WelcomeTile hasArrived={hasArrived} img={welcomeImg} />
        )}
        <IonGrid
          className={
            !isDesktop()
              ? "ion-padding"
              : "bl-padding-t-l bl-padding-r-m bl-padding-m-l"
          }
        >
          {!hasArrived && reservationDetails && !isDesktop() && (
            <BlurryWrapper newClasses={styles.CountdownWrapper}>
              <CountdownTimer />
            </BlurryWrapper>
          )}
          <IonRow>
            {isDesktop() && (
              <>
                <DesktopMenuItem
                  title="Home"
                  icon={home}
                  isSelected={location?.pathname.includes(
                    ROUTE_VIEWS.LANDING_PAGE
                  )}
                  type={SERVICES_TYPES.LANDING_PAGE}
                  onClick={() => redirectBetweenPages(ROUTE_VIEWS.LANDING_PAGE)}
                />
                <DesktopMenuItem
                  title="Itinerary"
                  isSelected={location?.pathname.includes(
                    ROUTE_VIEWS.ITINERARY
                  )}
                  type={SERVICES_TYPES.ITINERARY}
                  onClick={() => redirectBetweenPages(ROUTE_VIEWS.ITINERARY)}
                />
                <DesktopMenuItem
                  title="Hotel information"
                  icon={informationCircle}
                  isSelected={location?.pathname.includes(
                    ROUTE_VIEWS.HOTEL_INFO
                  )}
                  type={SERVICES_TYPES.HOTEL_INFO}
                  onClick={() => redirectBetweenPages(ROUTE_VIEWS.HOTEL_INFO)}
                />
              </>
            )}
            {warnerServices.map(
              ({
                title,
                Icon,
                type,
                isDisabled,
                disabledText,
                id,
                isCompleted,
                daysUntilEnabled,
              }) => (
                <IonCol
                  size={isDesktop() ? "12" : ""}
                  key={id}
                  className={styles.Tile}
                >
                  {!isDesktop() ? (
                    <BLHomePageTile
                      icon={Icon}
                      title={title}
                      isDisabled={isDisabled}
                      isSelected={location?.pathname.includes(type)}
                      isCompleted={isCompleted}
                      onClick={() =>
                        onClickHandler(
                          isDisabled,
                          title,
                          disabledText,
                          daysUntilEnabled,
                          type
                        )
                      }
                    />
                  ) : (
                    <DesktopMenuItem
                      icon={Icon}
                      title={title}
                      type={type}
                      isDisabled={isDisabled}
                      isSelected={location?.pathname.includes(type)}
                      onClick={() =>
                        onClickHandler(
                          isDisabled,
                          title,
                          disabledText,
                          daysUntilEnabled,
                          type
                        )
                      }
                    />
                  )}
                </IonCol>
              )
            )}
          </IonRow>
        </IonGrid>
        {disabledItemDialog && disabledItemDialog.description && (
          <BLAlertPopUp
            title={disabledItemDialog.title}
            description={replacePlaceholders(disabledItemDialog.description, {
              DAYS: disabledItemDialog.daysUntilEnabled,
            })}
            isOpen={!!disabledItemDialog}
            dismissHandler={() => setDisabledItemDialog(null)}
            dismissBtnType={"primary"}
            dismissBtnLabel={"Dismiss"}
          />
        )}
      </div>
    )
  );
};

export default Navigator;
