import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { pencil } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ActivityContext } from "../../../../context/ActivityContext";
import { ConflictManagementContext } from "../../../../context/ConflictManagementContext";
import BLButton from "../../../../shared/Ionic/Buttons/BLButton";
import BLExpandableBox from "../../../../shared/Ionic/Cards/BLExpandableBox/BLExpandableBox";
import TermsAndConditions from "../../../../shared/Ionic/TermsAndConditions/TermsAndConditions";
import DaysRowCollapsedTitle from "./BookingSection/DaysRow/DaysRowCollapsedTitle";
import DaysRowContent from "./BookingSection/DaysRow/DaysRowContent";
import DaysRowExpandedTitle from "./BookingSection/DaysRow/DaysRowExpandedTitle";
import TimesRowContent from "./BookingSection/TimesRow/TimesRowContent";
import TimesRowTitle from "./BookingSection/TimesRow/TimesRowTitle";
import { expandCollapse } from "../../../../helpingFunctions/helpingFunctions";
import { constructInfoBoxPriceMsg } from "../../../../helpingFunctions/InfoBoxPriceMsg";
import { isDesktop } from "../../../../helpingFunctions/utilities";
import { ROUTE_VIEWS } from "../../../../routes/routeViews";
import { NavigatorContext } from "../../../../context/NavigatorContext";
import { useTheme } from "../../../../hooks/useTheme";

const ActivityExpandableRowsContent = ({ clickedRow, setClickedRow }) => {
  const { typography } = useTheme();
  const history = useHistory();
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const { findPageConflicts } = useContext(ConflictManagementContext);
  const [areAnyChanges, setAreAnyChanges] = useState(false);
  const [areSelectionsCompleted, setAreSelectionsCompleted] = useState(false);
  const {
    activitySelections,
    cancelChangesHandler,
    selectedService,
    setShowBookingSection,
    setPriceMsg,
    isEditMode,
  } = useContext(ActivityContext);

  useEffect(() => {
    validateSelections(activitySelections, selectedService);
  }, [activitySelections]);

  const validateSelections = (selections, serviceDetails) => {
    let selectionsOkay = 0;
    let selectionsDaysOkay = 0;
    let totalSelections = selections.length;
    let totalSelectionsDays = 0;
    let totalToRemove = 0;
    let totalToAdd = 0;
    let totalChanges = 0;

    const isPriceValid =
      !isNaN(serviceDetails?.Price) && serviceDetails?.Price !== "0.00";
    selections.forEach((selection) => {
      selectionsOkay++;
      totalSelectionsDays += selection.on.length;

      selection.on.forEach((day) => {
        const hasValidDayTimeSel = day.date !== "" && day.time !== "";

        if (hasValidDayTimeSel) {
          selectionsDaysOkay++;
        }

        if (hasValidDayTimeSel && day.action === "add") {
          if (isPriceValid) {
            totalToAdd += day.quantity
              ? day.quantity * Number(serviceDetails.Price)
              : Number(serviceDetails.Price);
          }
        } else if (day.action === "remove") {
          if (isPriceValid) {
            totalToRemove += day.quantity
              ? day.quantity * Number(serviceDetails.Price)
              : Number(serviceDetails.Price);
          }
        }

        if (day.action !== "none" && hasValidDayTimeSel) {
          totalChanges++;
        }
      });
    });

    if ((totalToAdd > 0 || totalToRemove > 0) && isPriceValid) {
      constructInfoBoxPriceMsg(totalToAdd, totalToRemove, setPriceMsg, typography);
    } else {
      setPriceMsg(null);
    }

    const areSelsCompleted =
      selectionsOkay === totalSelections &&
      selectionsDaysOkay === totalSelectionsDays;
    setAreSelectionsCompleted(areSelsCompleted);

    setAreAnyChanges(totalChanges > 0 && areSelsCompleted);
  };

  return (
    <IonGrid onMouseDown={(e) => e.preventDefault()}>
      <IonRow className={"bl-margin-b-s"}>
        <IonCol>
          <BLExpandableBox
            collapsedTitle={<DaysRowCollapsedTitle />}
            expandedTitle={<DaysRowExpandedTitle />}
            newClass={
              isDesktop() ? "ExpandableBox_main" : "ExpandableBox_second"
            }
            dimensions={{
              padding: [
                "l-s",
                "r-s",
                "t-s",
                clickedRow === "daysRow" ? "b-m" : "b-s",
              ],
            }}
            isClicked={clickedRow === "daysRow"}
            customIcon={isEditMode && pencil}
            onClick={() => expandCollapse("daysRow", clickedRow, setClickedRow)}
          >
            <DaysRowContent />
          </BLExpandableBox>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <BLExpandableBox
            collapsedTitle={<TimesRowTitle expanded={false} />}
            expandedTitle={<TimesRowTitle expanded />}
            newClass={
              isDesktop() ? "ExpandableBox_main" : "ExpandableBox_second"
            }
            dimensions={{ padding: ["l-s", "r-s", "t-s", "b-s"] }}
            isClicked={clickedRow === "timeRow"}
            customIcon={isEditMode && pencil}
            isDisabled={activitySelections?.find((row) => {
              return row.on.find((time) => time.date === "");
            })}
            onClick={() => expandCollapse("timeRow", clickedRow, setClickedRow)}
          >
            <TimesRowContent />
          </BLExpandableBox>
        </IonCol>
      </IonRow>
      <IonRow className="bl-margin-t-m">
        <IonCol className="ion-text-center">
          <TermsAndConditions />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol
          style={{ gap: "20px" }}
          className="bl-flex ion-justify-content-center ion-align-items-center bl-margin-t-m"
        >
          <BLButton
            content={isEditMode ? "Cancel" : "Go back"}
            type="underlined"
            onClick={() => {
              if (isEditMode) {
                if (history.location.state.isItineraryRedirect) {
                  redirectBetweenPages(ROUTE_VIEWS.ITINERARY);
                }
                cancelChangesHandler();
              } else {
                setShowBookingSection(false);
                setClickedRow(null);
                redirectBetweenPages(ROUTE_VIEWS.ACTIVITIES);
              }
            }}
          />
          <BLButton
            content={isEditMode ? "Update changes" : "Book now"}
            type="primary"
            onClick={() => {
              // When the user hits 'Confirm', first step would be to evaluate for conflicts
              // Whether there are conflicts or not, the 'handleActivityBookingRQ()' method will be called via useEffect in the ActivitiesProcessingMethods
              findPageConflicts(activitySelections);
              setClickedRow(null);
            }}
            disabled={isEditMode ? !areAnyChanges : !areSelectionsCompleted}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ActivityExpandableRowsContent;
