import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { addCircle, removeCircle } from "ionicons/icons";
import React, { Fragment, useContext } from "react";
import { SERVICES_TYPES, INITIAL_DATE } from "../../../../../../../constants/constants";
import { useDataContext } from "../../../../../../../context/DataContext";
import { NavigatorContext } from "../../../../../../../context/NavigatorContext";
import { TreatsContext } from "../../../../../../../context/TreatsContext";
import { formatTime } from "../../../../../../../helpingFunctions/dateTimeUtilities";
import { formatPrice } from "../../../../../../../helpingFunctions/formatPrice";
import { generateKey } from "../../../../../../../helpingFunctions/helpingFunctions";
import BLIcon from "../../../../../../../shared/Ionic/BLIcon";
import BLInformationBox from "../../../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import BLButton from "../../../../../../../shared/Ionic/Buttons/BLButton";
import BLNumberInput from "../../../../../../../shared/Ionic/Inputs/BLNumberInput";
import BLSelect from "../../../../../../../shared/Ionic/Inputs/BLSelect";
import BLParagraph from "../../../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import LocationsAndQuantitySelectionLogic from "./LocationsAndQuantitySelectionLogic";
import { isDesktop } from "../../../../../../../helpingFunctions/utilities";
import { ROUTE_VIEWS } from "../../../../../../../routes/routeViews";
import { useTheme } from "../../../../../../../hooks/useTheme";
import BLLink from "../../../../../../../shared/Ionic/Links/BLAnchor/BLLink";

const LocationsAndQuantitySelection = () => {
  const { typography } = useTheme();
  const {
    treatsSelections,
    selectedService,
    prepareLocationsDropdown,
    diningBookings,
    hasInRoomBookWithIn48hr,
    daySelections,
    getLocationLabel
  } = useContext(TreatsContext);
  const {
    dynamicTexts,
  } = useDataContext();
  const { redirectBetweenPages } = useContext(NavigatorContext);

  const {
    handleLocationSelection,
    addLocation,
    handleQuantitySelection,
    removeLocationSelection,
  } = LocationsAndQuantitySelectionLogic();

  const getFieldType = (selIndex, optIndex, sel, opt) => {
    const dropdownList = prepareLocationsDropdown(
      treatsSelections[selIndex]?.options,
      sel.date
    );

    const locationSel = (
      <IonRow className="bl-flex ion-align-items-center">
        <IonCol
          className={`bl-flex ion-align-items-center
              $"ion-justify-content-between"}`}
          style={{
            background: "light",
            minWidth: "230px",
          }}
        >
          <BLSelect
            placeholder="Select a location"
            dropdownList={dropdownList}
            value={opt?.serviceCode}
            selectedText={getLocationLabel(opt?.serviceCode)}
            disable={
              sel.action === "remove" ||
              opt.action === "remove" ||
              (hasInRoomBookWithIn48hr(sel) && opt?.bookedLocation === "In-Room")
            }
            onChange={(v) => handleLocationSelection(selIndex, optIndex, v)}
          />
        </IonCol>
        {((!!opt.serviceCode && optIndex > 0) ||
          (["none", "edit"].includes(sel.action) && opt.action !== "remove")) &&
          !isDesktop() && (
          <IonCol>
            <BLIcon
              iconSource={removeCircle}
              iconSize="md"
              color="secondary"
              onClick={() => removeLocationSelection(selIndex, optIndex)}
              newClasses="bl-margin-l-s"
              disable={
                hasInRoomBookWithIn48hr(sel) &&
                opt.bookedLocation === "In-Room"
              }
            />
          </IonCol>
        )}
      </IonRow>
    );

    const locationAndQtySelRowMobile = (
      <IonRow style={{ maxWidth: "270px" }}>
        <IonCol size="auto">
          <BLContentCardWrapper
            dimensions={{ padding: ["xs"] }}
            newStyles={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BLNumberInput
              itemBackground="light"
              plusFunc={() =>
                handleQuantitySelection("plus", selIndex, optIndex)
              }
              minFunc={() =>
                handleQuantitySelection("minus", selIndex, optIndex)
              }
              value={treatsSelections[selIndex]?.options[optIndex].quantity}
              min={1}
              max={selectedService.maxQtyAllowed}
              numberFieldWidth="35px"
              disableBtns={
                sel.action === "remove" ||
                opt.action === "remove" ||
                (hasInRoomBookWithIn48hr(sel) &&
                  opt.bookedLocation === "In-Room")
              }
              inputDimensions={{
                margin: [],
              }}
            />
            <BLParagraph
              txt={`Price: ${formatPrice(
                treatsSelections[selIndex]?.options[optIndex].quantity *
                selectedService.Price
              )}`}
              color="text-grey"
              textVariant={typography.BODY_TEXT}
            />
          </BLContentCardWrapper>
        </IonCol>
      </IonRow>
    );

    const locationAndQtySelDesktop = (
      <IonRow className="bl-flex ion-align-items-center">
        <BLContentCardWrapper
          dimensions={{ padding: ["xs"] }}
          newStyles={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <BLNumberInput
            itemBackground="light"
            plusFunc={() => handleQuantitySelection("plus", selIndex, optIndex)}
            minFunc={() => handleQuantitySelection("minus", selIndex, optIndex)}
            value={treatsSelections[selIndex]?.options[optIndex].quantity}
            min={1}
            max={selectedService.maxQtyAllowed}
            numberFieldWidth="35px"
            disableBtns={
              sel.action === "remove" ||
              opt.action === "remove" ||
              (hasInRoomBookWithIn48hr(sel) && opt.bookedLocation === "In-Room")
            }
            inputDimensions={{
              margin: [],
            }}
          />

          <BLParagraph
            txt={`Price: ${formatPrice(
              treatsSelections[selIndex]?.options[optIndex].quantity *
              selectedService.Price
            )}`}
            color="text-grey"
            textVariant={typography.BODY_TEXT}
            dimensions={{
              margin: ["l-s"],
              padding: [],
            }}
          />
          {((!!opt.serviceCode && optIndex > 0) ||
            (["none", "edit"].includes(sel.action) &&
              opt.action !== "remove")) && (
            <IonCol>
              <BLIcon
                iconSource={removeCircle}
                iconSize="md"
                color="secondary"
                onClick={() => removeLocationSelection(selIndex, optIndex)}
                newClasses="bl-margin-l-s"
                disable={
                  hasInRoomBookWithIn48hr(sel) &&
                  opt.bookedLocation === "In-Room"
                }
              />
            </IonCol>
          )}
        </BLContentCardWrapper>
      </IonRow>
    );

    return (
      <Fragment key={`${sel.date}_${opt.serviceCode}`}>
        {hasInRoomBookWithIn48hr(sel) && (
          <IonRow className="bl-margin-b-xs">
            {opt.bookedLocation === "In-Room" && (
              <IonCol size="12">
                <BLInformationBox
                  content={
                    <BLParagraph
                      markDown={!!dynamicTexts?.Treats?.Booking?.InfoBox_LocationsAndQuantities} 
                      txt={dynamicTexts?.Treats?.Booking?.InfoBox_LocationsAndQuantities} 
                    />
                  }
                  type="info"
                />
              </IonCol>
            )}
          </IonRow>
        )}

        <IonRow key={generateKey(sel)}>
          <IonCol>
            {isDesktop() ? (
              <IonGrid>
                <IonRow
                  className={
                    "ion-align-items-center ion-justify-content-start bl-margin-b-xs bl-margin-t-s"
                  }
                >
                  <IonCol className="bl-flex">
                    {locationSel}

                    {!!treatsSelections[selIndex]?.options[optIndex]
                      .serviceCode && locationAndQtySelDesktop}
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : (
              <IonGrid>
                <IonRow>{locationSel}</IonRow>
                {!!treatsSelections[selIndex]?.options[optIndex].serviceCode &&
                  locationAndQtySelRowMobile}
              </IonGrid>
            )}
          </IonCol>
        </IonRow>
        <IonRow className="bl-margin-b-s">
          {[SERVICES_TYPES.DINNER].includes(opt.location) && (
            <IonCol size="12">
              <BLInformationBox
                content={
                  <>
                    {diningBookings[opt.location][sel.date] ? (
                      <BLParagraph
                        txt={`${
                          opt.location
                        } treat will be delivered at ${formatTime(
                          `${INITIAL_DATE}T${diningBookings[opt.location][
                            sel.date
                          ].Time.substring(0, 5)}`,
                          true
                        )}`}
                      />
                    ) : (
                      <BLParagraph>
                        {`You haven't booked ${opt.location.toLowerCase()} yet. Please click `}
                        <BLLink
                          txt="here"
                          onClick={() => redirectBetweenPages(`${ROUTE_VIEWS.DINING}/${opt.location}`)} 
                        />
                        {" to make the booking."}
                      </BLParagraph>
                    )}
                  </>
                }
                type={
                  diningBookings[opt.location][sel.date] ? "info" : "warning"
                }
              />
            </IonCol>
          )}
        </IonRow>
      </Fragment>
    );
  };

  const locationAndQuantityRowContent = (
    <IonGrid
      className="bl-flex"
      style={{
        flexWrap: "wrap",
        flexDirection: "column",
        minWidth: "240px",
      }}
    >
      {treatsSelections?.map((sel, selIndex) => (
        <Fragment key={selIndex}>
          <IonRow
            className="bl-margin-b-s"
            style={{ borderBottom: "1px solid var(--ion-color-primary)" }}
          >
            <IonCol className="ion-text-end bl-margin-t-xs">
              <BLParagraph
                txt={
                  daySelections?.find((row) => row.id === sel.date)?.fullTitle
                }
                color="primary"
                textVariant={typography.h4}
              />
            </IonCol>
          </IonRow>

          {sel.options.map((opt, optIndex) => (
            <Fragment key={optIndex}>
              {getFieldType(selIndex, optIndex, sel, opt)}
            </Fragment>
          ))}

          <IonRow className="bl-margin-b-xs">
            <IonCol>
              <BLButton
                type="icon-text"
                icon={addCircle}
                iconSize="md"
                content="Add location"
                onClick={() => addLocation(selIndex)}
                disabled={
                  (sel.action !== "remove" &&
                    sel.options.find((row) => row.serviceCode === "")) ||
                  prepareLocationsDropdown(
                    treatsSelections[selIndex]?.options,
                    sel.date
                  ).length === 0 || sel.action === "remove"
                }
              />
            </IonCol>
          </IonRow>
        </Fragment>
      ))}
    </IonGrid>
  );

  return {
    locationAndQuantityRowContent,
  };
};

export default LocationsAndQuantitySelection;
