import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { pencil, repeat } from "ionicons/icons";
import React, { useContext } from "react";
import { useDataContext } from "../../../../../context/DataContext";
import { NavigatorContext } from "../../../../../context/NavigatorContext";
import { TreatsContext } from "../../../../../context/TreatsContext";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import BookedTreatsDetailsCardDesktop from "../cards/BookedTreatsDetailsCardDesktop";
import BookedTreatsDetailsCardMobile from "../cards/BookedTreatsDetailsCardMobile";
import { expandCollapse } from "../../../../../helpingFunctions/helpingFunctions";
import { isDesktop } from "../../../../../helpingFunctions/utilities";

const TreatsSummaryScreen = ({ isSummaryScreen, clickedRow, setClickedRow }) => {
  const { itineraryDetails } = useDataContext();
  const {
    setSelectedService,
    hasFailedTreatsBookings,
    handleTreatsBookingRQ, 
    checkIfSelectedTreatBookingsAreLeft, 
    confirmationScreenSelections, 
    setConfirmationScreenSelections, 
    setIsEditMode,
    isProcessingBookings } = useContext(TreatsContext);
  const { redirectToItinerary } = useContext(NavigatorContext);

  return (
    <>
      <IonRow className="bl-margin-t-s">
        <IonCol>
          <BLContentCardWrapper>
            <IonGrid>
              {!itineraryDetails ? (
                <IonRow className={"ion-text-center"}>
                  <IonCol>
                    <BLLoadingContainer />
                  </IonCol>
                </IonRow>
              ) : (
                <>
                  {isDesktop() ? (
                    <BookedTreatsDetailsCardDesktop
                      isSummaryScreen={isSummaryScreen}
                    />
                  ) : (
                    <BookedTreatsDetailsCardMobile
                      isSummaryScreen={isSummaryScreen}
                    />
                  )}
                </>
              )}
            </IonGrid>
          </BLContentCardWrapper>
        </IonCol>
      </IonRow>
      {!isProcessingBookings && (
        <>
          {confirmationScreenSelections ? (
            <>
              {isDesktop() ? (
                <IonRow className={` bl-margin-t-m ion-justify-content-center`}>
                  {hasFailedTreatsBookings ? (
                    <BLButton
                      type="underlined"
                      content={"Cancel"}
                      iconSize="md"
                      onClick={() => {
                        setIsEditMode(false);
                        setConfirmationScreenSelections(null);
                      }}
                    />
                  ) : (
                    <BLButton
                      type="icon-text"
                      content={"Change"}
                      icon={pencil}
                      iconSize="md"
                      onClick={() => {
                        setIsEditMode(checkIfSelectedTreatBookingsAreLeft());
                        expandCollapse("daysRow", clickedRow, setClickedRow);
                        setConfirmationScreenSelections(null);
                      }}
                    />
                  )}

                  {hasFailedTreatsBookings ? (
                    <BLButton
                      type="icon-text"
                      icon={repeat}
                      content={"Retry failed bookings"}
                      onClick={() => handleTreatsBookingRQ("retry")}
                    />
                  ) : (
                    <BLButton
                      type="primary"
                      content={"View in Itinerary"}
                      onClick={() => {
                        setConfirmationScreenSelections(null);
                        setSelectedService(null);
                        redirectToItinerary();
                      }}
                    />
                  )}
                </IonRow>
              ) : (
                <>
                  <IonRow
                    className={`bl-margin-t-m ion-justify-content-center ion-align-items-center`}
                  >
                    <IonCol size="auto">
                      {hasFailedTreatsBookings ? (
                        <BLButton
                          type="underlined"
                          content={"Cancel"}
                          iconSize="md"
                          onClick={() => {
                            setIsEditMode(false);
                            setConfirmationScreenSelections(null);
                          }}
                        />
                      ) : (
                        <BLButton
                          type="icon-text"
                          content={"Change"}
                          icon={pencil}
                          iconSize="md"
                          onClick={() => {
                            setIsEditMode(checkIfSelectedTreatBookingsAreLeft());
                            expandCollapse("daysRow", clickedRow, setClickedRow);
                            setConfirmationScreenSelections(null);
                          }}
                        />
                      )}
                    </IonCol>

                    <IonCol size="auto">
                      {hasFailedTreatsBookings ? (
                        <BLButton
                          type="icon-text"
                          icon={repeat}
                          content={"Retry failed bookings"}
                          onClick={() => handleTreatsBookingRQ("retry")}
                        />
                      ) : (
                        <BLButton
                          type="primary"
                          content={"View in Itinerary"}
                          onClick={() => {
                            setConfirmationScreenSelections(null);
                            setSelectedService(null);
                            redirectToItinerary();
                          }}
                        />
                      )}
                    </IonCol>
                  </IonRow>
                </>
              )}
            </>
          ) : (
            <>
              <IonRow
                className={`${isDesktop() ? "bl-margin-t-m" : "bl-margin-t-xs"} ion-justify-content-center`}
              >
                <BLButton
                  type="icon-text"
                  content={"Change"}
                  icon={pencil}
                  iconSize="md"
                  onClick={() => {
                    setIsEditMode(true);
                  }}
                />
              </IonRow>
            </>
          )}
        </>
      )}
    </>
  );
};

export default TreatsSummaryScreen;
