import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";

import { useDataContext } from "../../../../../context/DataContext";
import { getDatesBetween } from "../../../../../helpingFunctions/dateTimeUtilities";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";
import { EntertainmentsPageContext } from "../../EntertainmentsPageContext";
import BookedDayDetailsInitial from "./BookedDayDetails/BookedDayDetailsInitial";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { SERVICES_TYPES } from "../../../../../constants/constants";
import { useTheme } from "../../../../../hooks/useTheme";

const EntertainmentChange = () => {
  const { typography } = useTheme();
  const { isLoadingItinerary, reservationDetails } = useDataContext();

  const {
    entertainmentBookingSelections,
    skippedDays,
  } = useContext(EntertainmentsPageContext);

  const datesList = getDatesBetween(
    reservationDetails.ArrivalDate,
    reservationDetails.DepartureDate,
    SERVICES_TYPES.ENTERTAINMENT
  );

  const allSkipped = skippedDays.length === datesList.length && entertainmentBookingSelections.length === 0; 
  const summaryScreenText = "Below is your booking summary, you can change or cancel your bookings from here.";

  return (
    <IonGrid className={isDesktop() ? "" : "bl-padding-l-xs bl-padding-r-xs"}>
      <IonRow>
        <IonCol size="12">
          <BLParagraph
            newStyles={{ display: "flex", justifyContent: "center" }}
            txt={summaryScreenText}
            textVariant={typography.BODY_TEXT}
            color="text-grey"
          />
        </IonCol>
      </IonRow>
      {(!allSkipped && entertainmentBookingSelections?.length === 0) || 
        isLoadingItinerary 
        ? <IonRow className="ion-padding-top ion-padding-bottom ion-justify-content-center">
          <BLLoadingContainer />
        </IonRow>
        : <IonRow>
          <BLContentCardWrapper
            dimensions={{
              padding: ["b-s", "l-xs", "r-xs"],
              margin: ["t-s"],
            }}
            center={true}
          >
            <IonGrid style={{maxWidth: "400px"}}>
              {datesList?.map(
                (date) => 
                  <IonRow 
                    key={date}
                    className="ion-align-items-center ion-justify-content-center"
                  >
                    <BookedDayDetailsInitial
                      key={`${date}`}
                      date={date}
                    />
                  </IonRow>
              )}
            </IonGrid>
          </BLContentCardWrapper>
        </IonRow>
      }
    </IonGrid>
  );
};

export default EntertainmentChange;
