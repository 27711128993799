import React from "react";
import { IonBadge, IonGrid, IonRow } from "@ionic/react";
import BLParagraph from "../../Paragraph/BLParagraph";
import BLIcon from "../../BLIcon";
import * as ionicons from "ionicons/icons";

import styles from "./BLHomePageTile.module.scss";
import { hexToFilter } from "css-filter-converter";
import { useTheme } from "../../../../hooks/useTheme";

const BLHomePageTile = ({
  icon,
  title,
  isDisabled,
  isSelected,
  isCompleted,
  onClick,
  color = "tertiary",
}) => {
  const { typography } = useTheme();
  const whiteColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--ion-color-white`);
  const whiteColorFilter = hexToFilter(whiteColor).color;

  return (
    <IonGrid
      className={`
          ${styles.HomePageTile} 
        `}
      style={{
        cursor: isSelected ? "default" : "pointer",
        backgroundColor: isSelected ? `var(--ion-color-${color})` : "",
      }}
      onClick={onClick}
    >
      {isDisabled && (
        <IonBadge className={styles.TileBadge}>
          <BLIcon iconSource={ionicons.information} iconSize="xxsm" />
        </IonBadge>
      )}
      {isCompleted && (
        <IonBadge className={styles.TileBadge}>
          <BLIcon iconSource={ionicons.checkmark} iconSize="xxsm" />
        </IonBadge>
      )}

      <IonRow>
        {icon && icon.Ionic && (
          <BLIcon
            iconSource={icon?.Ionic ? ionicons[icon.Ionic.name] : null}
            color="white"
            iconSize="lg"
          />
        )}
        {icon && !icon.Ionic && icon.custom && icon.custom.source && (
          <img
            src={icon.custom.source.url}
            alt={icon.custom.source.alternativeText}
            className={"bl-icon-lg"}
            style={{
              filter: whiteColorFilter,
            }}
          />
        )}
      </IonRow>
      <IonRow className="bl-padding-t-xs">
        <BLParagraph
          txt={title}
          center={true}
          textVariant={typography.SMALL_PRINT_TEXT}
          color="white"
        />
      </IonRow>
    </IonGrid>
  );
};

export default BLHomePageTile;
