import {
  MAESTRO_BACKEND_NAMING_VIEWS,
  SERVICES_TYPES,
  UNCATEGORIZED,
} from "../constants/constants";


const ROUTE_VIEWS = {
  // Internal access pages (use redirectBetweenPages function)
  //Exception: LANLANDING_PAGE could be used with "history.push" too.

  // Standalone pages
  ITINERARY: "/Itinerary",
  LANDING_PAGE: "/Home",
  GOLF: "/Golf",
  REGISTER_CARD: "/RegisterCard",
  HOTEL_INFO: "/HotelInfo",
  MAP: "/Map",
  ROOM_ACCOUNT: "/RoomAccount",
  THINGS_TO_DO: "/ThingsToDo",

  // Dining
  DINING: "/Dining",
  DINING_BREAKFAST: "/Dining/Breakfast",
  DINING_DINNER: "/Dining/Dinner",
  DINING_DINING_PARTY: "/Dining/DiningParty",

  // Entertainment
  ENTERTAINMENT: "/Entertainment",

  // Treats
  TREATS: "/Treats",
  TREATS_CATEGORY: "/Treats/:categoryId",
  TREAT_BOOKING: "/Treats/:categoryId/:treatId",

  // Activities
  ACTIVITIES: "/Activities",
  ACTIVITY_BOOKING: "/Activities/:activityId",

  // Spa
  SPAS: "/Spas",
  SPAS_CATEGORY: "/Spas/:categoryId",
  SPA_BOOKING: "/Spas/:categoryId/:spaId",

  // Direct accsess (use history.push())

  // Login
  DEEP_LINK: "/:hotelId/:bookingId/:surname",
  LOGIN: "/Login",

  // public URLs for pages outside application
  MAINTENANCE: "/Maintenance",
  HELP: "/Help",
  SERVE: "/Serve",
  KIOSK: "/Kiosk",
  WHATS_ON_GUIDE_PUBLIC: "/:hotel/whatsonguidepublic",
  WHATS_ON_GUIDE_TEAM: "/:hotel/whatsonguideteam",

  // Page not found
  NOT_FOUND: "/PageNotFound",
};

const getActivityRoute = (itemId) => {
  return itemId
    ? ROUTE_VIEWS.ACTIVITY_BOOKING.replace(":activityId", itemId)
    : ROUTE_VIEWS.ACTIVITIES;
};
const getSpaRoute = (itemId, categoryId) => {
  if (itemId) {
    return ROUTE_VIEWS.SPA_BOOKING.replace(
      ":categoryId",
      categoryId ? categoryId : UNCATEGORIZED
    ).replace(":spaId", itemId);
  } else if (categoryId) {
    return ROUTE_VIEWS.SPAS_CATEGORY.replace(":categoryId", categoryId);
  } else {
    return ROUTE_VIEWS.SPAS;
  }
};
const getTreatRoute = (itemId, categoryId) => {
  if (itemId) {
    return ROUTE_VIEWS.TREAT_BOOKING.replace(
      ":categoryId",
      categoryId ? categoryId : UNCATEGORIZED
    ).replace(":treatId", itemId);
  } else if (categoryId) {
    return ROUTE_VIEWS.TREATS_CATEGORY.replace(":categoryId", categoryId);
  } else {
    return ROUTE_VIEWS.TREATS;
  }
};

const mapTypeToRoute = (type, itemId, categoryId) => {
  switch (type) {
    case MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY:
      return getActivityRoute(itemId);
    case MAESTRO_BACKEND_NAMING_VIEWS.SPA:
      return getSpaRoute(itemId, categoryId);
    case MAESTRO_BACKEND_NAMING_VIEWS.TREAT:
      return getTreatRoute(itemId, categoryId);
    case SERVICES_TYPES.BREAKFAST:
      return ROUTE_VIEWS.DINING_BREAKFAST;
    case SERVICES_TYPES.DINNER:
      return ROUTE_VIEWS.DINING_DINNER;
    case SERVICES_TYPES.DINING_PARTY:
      return ROUTE_VIEWS.DINING_DINING_PARTY;
    case MAESTRO_BACKEND_NAMING_VIEWS.VENUE:
      return ROUTE_VIEWS.ENTERTAINMENT;
    default:
      return type;
  }
};

export { ROUTE_VIEWS, mapTypeToRoute };
