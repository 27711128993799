import React, { createContext, useContext, useEffect, useState } from "react";
import {
  formatDateDetailed,
  getCurrentDate,
  getDatesBetween,
  getDayOfWeek,
} from "../../../helpingFunctions/dateTimeUtilities";
const { v4: uuidv4 } = require("uuid");

import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import {
  ITEM_POPULAR_STATUSES,
  MAESTRO_BACKEND_NAMING_VIEWS,
  SERVICES_TYPES,
} from "../../../constants/constants";
import { useDataContext } from "../../../context/DataContext";

import { calendar } from "ionicons/icons";
import { useHistory, useLocation } from "react-router-dom";
import { ConflictManagementContext } from "../../../context/ConflictManagementContext";
import RequestHook from "../../../hooks/RequestHook";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import axiosInstance from "../../../url/createAxios";
import { areAllConflictsResolved } from "../../../helpingFunctions/conflictManagement/resolveMethods/resolveMethods";
import { useTheme } from "../../../hooks/useTheme";

export const EntertainmentsPageContext = createContext({});

const EntertainmentsPageProvider = ({ children }) => {
  const { typography } = useTheme();
  const history = useHistory();
  const location = useLocation();
  const {
    setFoundConflicts,
    foundConflicts,
    loadNextConflict,
    setSpotlitConflict,
  } = useContext(ConflictManagementContext);
  const [entertainmentDetails, setEntertainmentDetails] = useState(null);
  const [entertainmentLocationDetails, setEntertainmentLocationDetails] =
    useState(null);
  const [selectedDateSelections, setSelectedDateSelections] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [entertainmentBookingSelections, setEntertainmentBookingSelections] =
    useState([]);
  const [entertainmentShowHours, setEntertainmentShowHours] = useState([]);
  const [selectedEntertainmentShow, setSelectedEntertainmentShow] = useState(
    {}
  );
  const [skippedDays, setSkippedDays] = useState([]);
  const [clickedRow, setClickedRow] = useState(null);
  const [mainStatus, setMainStatus] = useState(ITEM_POPULAR_STATUSES.INIT);
  const [entertainmentAvailability, setEntertainmentAvailability] = useState(null);

  const {
    reservationDetails,
    itineraryDetails,
    dynamicTexts,
    setReservationDetails,
    facilitiesData,
    availability,
  } = useDataContext();

  const [mobileDaySlider, setMobileDaySlider] = useState({});
  const [displayedDateIndex, setDisplayedDateIndex] = useState(0);

  useEffect(() => {
    if (facilitiesData) {
      setEntertainmentDetails(facilitiesData.Entertainment?.All);
      setEntertainmentLocationDetails(
        facilitiesData.Entertainment?.Categorized
      );
    }
  }, [facilitiesData]);


  useEffect(() => {
    if (location?.state?.isItineraryRedirect && reservationDetails) {
      prepareSelectionObjects();
      setSelectedDate(location?.state?.itineraryObj.Date);
      setMainStatus(ITEM_POPULAR_STATUSES.EDITING);
    }
  }, [location, reservationDetails]);

  // Forced trigger to try to process the bookings to maestro when the foundConflicts state is updated
  useEffect(() => {
    if (foundConflicts) {
      handleBooking();
    }
  }, [foundConflicts]);

  useEffect(() => {
    if (itineraryDetails && reservationDetails) {
      prepareSelectionObjects();
      if (!selectedDate) {
        if (location?.state?.isItineraryRedirect) {
          setSelectedDate(location?.state?.itineraryObj.Date);
        } else if (datesList[0] > getCurrentDate()) {
          setSelectedDate(datesList[0]);
        } else {
          setSelectedDate(getCurrentDate());
        }
      }
    }
  }, [itineraryDetails, reservationDetails, mobileDaySlider]);

  const datesList = getDatesBetween(
    reservationDetails?.ArrivalDate,
    reservationDetails?.DepartureDate,
    SERVICES_TYPES.ENTERTAINMENT
  );

  const instance = axiosInstance();

  const { getItinerary, refreshReservationState, getActivitiesAvailability } =
    RequestHook();

  const getDayParagraph = (date) => {
    const displayDate = date || reservationDetails?.ArrivalDate;
    return formatDateDetailed(datesList, displayDate);
  };

  const getFirstFutureDate = () => {
    if(datesList?.length) {
      const currentDate = getCurrentDate();
      return datesList[0] > currentDate
        ? datesList[0]
        : currentDate;
    }
    return null;
  };

  const daysRowExpandedTitle = (
    <IonGrid>
      <IonRow className="ion-align-items-center">
        <IonIcon
          style={{
            fill: "var(--ion-color-primary)",
            width: "32px",
            height: "32px",
          }}
          icon={calendar}
        />
        <IonCol
          className={"bl-flex"}
          style={{ flexDirection: "column", gap: "5px" }}
        >
          <BLParagraph
            txt={getDayParagraph(selectedDate || getFirstFutureDate())}
            color={"primary"}
            textVariant={typography.h3}
            // newClasses={`medium crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
            }}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const daysRowCollapsedTitle = (
    <IonGrid>
      <IonRow className="ion-align-items-center">
        <IonIcon
          style={{
            fill: "var(--ion-color-primary)",
            width: "32px",
            height: "32px",
          }}
          icon={calendar}
        />
        <IonCol
          className={"bl-flex"}
          style={{ flexDirection: "column", gap: "5px" }}
        >
          <IonRow>
            <BLParagraph
              txt={getDayParagraph(selectedDate || getFirstFutureDate())}
              color={"primary"}
              textVariant={typography.h3}
              // newClasses={`medium crimson-pro`}
              //TODO: Unclear typography
              dimensions={{
                margin: ["l-s"],
              }}
            />
          </IonRow>
          {skippedDays.includes(selectedDate) && (
            <IonRow>
              <BLParagraph
                txt={
                  dynamicTexts?.Shared?.DiningAndEntertainment
                    ?.Label_DaySelection_NoBookingNeededCheckBox
                }
                color={"primary"}
                textVariant={typography.h3}
                // newClasses={`medium crimson-pro`}
                //TODO: Unclear typography
                dimensions={{
                  margin: ["l-s"],
                }}
              />
            </IonRow>
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const handleSelectedDateSelections = (date) => {
    try {
      setSelectedDate(date);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleSelectedDateSelections func in EntertainmentsPageContext.js ${error.message}`
      );
    }
  };

  const handleBooking = async (skipConflictValidation) => {
    try {
      // If there are any unresolved conflicts - negate the method until all of them are resolved
      if (!areAllConflictsResolved(foundConflicts) && !skipConflictValidation) {
        loadNextConflict(foundConflicts);
        return;
      }

      setSpotlitConflict(null);
      setFoundConflicts(null);

      history.replace({
        pathname: location.pathname,
        state: { ...location.state, isItineraryRedirect: false },
      });

      const preparedBookingObject = {
        diningAddOns: [],
        isGroupRequest: false,
        reservationType: MAESTRO_BACKEND_NAMING_VIEWS.VENUE,
        request: [],
      };
      const filteredBookingSelections = entertainmentBookingSelections.filter(
        (selection) =>
          selection.action !== "none" &&
          selection.status !== ITEM_POPULAR_STATUSES.SUCCESS
      );
      bindRQItems(filteredBookingSelections);
      for (const selection of filteredBookingSelections) {
        const prepObj = {
          id: selection.venueId,
          ReservationNumber: selection.ReservationNumber,
          LastName: selection.LastName,
          FirstName: selection.FirstName,
          type: "activity",
          Date: selection.date + "T" + selection.time,
          Quantity: selection.Quantity,
          action: selection.action,
          BookingNumber: selection.bookingNumber,
        };

        if (selection.uniqueBinder) {
          prepObj.uniqueBinder = selection.uniqueBinder;
        }

        preparedBookingObject.request.push(prepObj);
      }

      const entertainmentBookingSelectionsCopy = JSON.parse(
        JSON.stringify(entertainmentBookingSelections)
      );
      entertainmentBookingSelectionsCopy.map((selection) => {
        if (
          selection.action !== "none" &&
          selection.status !== ITEM_POPULAR_STATUSES.SUCCESS
        ) {
          return (selection.status = ITEM_POPULAR_STATUSES.LOAD);
        }
      });

      setEntertainmentBookingSelections(entertainmentBookingSelectionsCopy);
      setMainStatus(ITEM_POPULAR_STATUSES.LOAD);

      const response = await instance.post(
        "/Hotel/Booking/Facility/Availability/Activity",
        preparedBookingObject,
        { withCredentials: true }
      );
      await getItinerary();
      if (response.data.bookings !== "skiped") {
        const bookings = JSON.parse(
          JSON.stringify(entertainmentBookingSelectionsCopy)
        );
        for (const bookingResponse of response.data.bookings) {
          const dateTime = bookingResponse.Date.split("T");
          const date = dateTime[0];
          const time = dateTime[1];
          const bookedItem = bookings.find(
            (item) =>
              item.venueId === bookingResponse.id &&
              item.time === time &&
              item.date === date
          );
          if (bookedItem) {
            bookedItem.status = bookingResponse.status;
          }
        }

        setEntertainmentBookingSelections(bookings);
      }

      setMainStatus(ITEM_POPULAR_STATUSES.SUCCESS);
      setReservationDetails((prevState) => {
        return {
          ...prevState,
          Venue: true,
        };
      });
      await refreshReservationState();
      getActivitiesAvailability(reservationDetails?.Adults);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleBooking func EntertainmentsPageContext.js ${error.message}`
      );
    }
  };

  const handleSelectShow = (show) => {
    let entertainmentBookingSelectionsCopy = JSON.parse(
      JSON.stringify(entertainmentBookingSelections)
    );
    const foundSelectedShow = entertainmentBookingSelectionsCopy.find(
      (el) =>
        el.venueId === show.venueId &&
        el.time === show.Time &&
        el.date === selectedDate
    );
    //if the same show is in the booking selections
    if (foundSelectedShow) {
      if (foundSelectedShow.action === "add") {
        //remove from selection because the show is not yet booked
        entertainmentBookingSelectionsCopy =
          entertainmentBookingSelectionsCopy.filter(
            (el) =>
              el.venueId !== show.venueId ||
              el.time !== show.Time ||
              el.date !== selectedDate
          );
      } else if (foundSelectedShow.action === "remove") {
        foundSelectedShow.action = "none";
      } else {
        //add remove action to cancel the show booking because the show is booked
        foundSelectedShow.action = "remove";
      }
    } else {
      //add to booking selections

      const preparedBookingObject = {
        location: show.location,
        title: show.title,
        FirstName: reservationDetails.FirstName,
        LastName: reservationDetails.LastName,
        Quantity: reservationDetails.Adults,
        ReservationNumber: reservationDetails.ReservationNumber,
        action: "add",
        date: selectedDate,
        time: show.Time,
        venueId: show.venueId,
        breakDescription: show.breakDescription,
        entertainmentId: show.entertainmentId,
        isFestival: show.isFestival,
      };
      entertainmentBookingSelectionsCopy.push(preparedBookingObject);
    }

    setEntertainmentBookingSelections(entertainmentBookingSelectionsCopy);
  };

  const prepareSelectionObjects = () => {
    try {
      const preparedBookingSelections = [];
      const newSkippedDays = [];

      for (let index = 0; index < datesList.length; index++) {
        const date = datesList[index];
        const hasBookedBefore =
          itineraryDetails?.Dates[date][SERVICES_TYPES.ENTERTAINMENT].length > 0;

        if (
          reservationDetails.Venue &&
          !hasBookedBefore &&
          entertainmentBookingSelections.find(
            (sel) => sel.action !== "none" && sel.date === selectedDate
          )
        ) {
          if (!skippedDays.includes(date)) {
            setSkippedDays((previousState) => [...previousState, date]);
          }
        } else if (reservationDetails?.Venue) {
          const bookedArray =
            itineraryDetails?.Dates[date][SERVICES_TYPES.ENTERTAINMENT];
          const bookedShows = [];

          for (const bookedItem of bookedArray) {
            const bookedItemData = entertainmentDetails?.find(
              (show) =>
                show.ShowDate === bookedItem.Date &&
                show.ShowTime.slice(0, 5) === bookedItem.Time.slice(0, 5) &&
                show.ActivityId === bookedItem.Id
            );

            bookedShows.push({
              location: bookedItemData?.Location || bookedItem.Location,
              title:
                bookedItemData?.ShowTitle ||
                dynamicTexts?.Entertainments?.Booking?.Paragraph_CardTitle,
              breakDescription: bookedItemData?.ShowBreakDescription || "",
              entertainmentId: bookedItemData?.ShowsGroupIdBL,
              isFestival: bookedItemData?.isFestival,
              ReservationNumber: reservationDetails.ReservationNumber,
              LastName: reservationDetails.LastName,
              FirstName: reservationDetails.FirstName,
              Quantity: reservationDetails.Adults,
              date: bookedItem.Date,
              venueId: bookedItem.Id,
              time: bookedItem.Time,
              bookingNumber: bookedItem.BookingNumber,
              action: "none",
            });
          }

          preparedBookingSelections.push(...bookedShows);
        }

        if (
          !preparedBookingSelections.find((booking) => booking.date === date) &&
          reservationDetails.Venue
        ) {
          newSkippedDays.push(date);
        }

        if (!reservationDetails.Venue && date < getCurrentDate()) {
          newSkippedDays.push(date);
        }
      }
      if (
        mainStatus !== ITEM_POPULAR_STATUSES.SUCCESS &&
        mainStatus !== ITEM_POPULAR_STATUSES.LOAD
      ) {
        setEntertainmentBookingSelections(preparedBookingSelections);

        setSkippedDays(newSkippedDays);
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in prepareSelectionObjects func EntertainmentsPageContext.js ${error.message}`
      );
    }
  };

  const bindRQItems = (items) => {
    const itemsCopy = [...items];

    for (const i in itemsCopy) {
      const item = itemsCopy[i];

      if (item.action === "remove") {
        const isStarShowCancellation =
          item.breakDescription?.startsWith("Headlining");
        if (isStarShowCancellation) {
          const starShowReplacementIndex = items.findIndex(
            (item) =>
              item.breakDescription?.startsWith("Headlining") &&
              item.action === "add"
          );

          if (starShowReplacementIndex > -1) {
            const uniqueBinder = uuidv4();
            items[i].uniqueBinder = uniqueBinder;
            items[starShowReplacementIndex].uniqueBinder = uniqueBinder;
            continue;
          }
        }

        const dayOfWeek = getDayOfWeek(item.date);
        const isThursdayOrSaturdayCancellation =
          dayOfWeek === "Thursday" || dayOfWeek === "Saturday";
        if (isThursdayOrSaturdayCancellation && !item.isFestival) {
          const replacementIndex = items.findIndex(
            (row) =>
              row.action === "add" &&
              row.venueId === item.venueId &&
              row.date === item.date
          );

          if (replacementIndex > -1) {
            const uniqueBinder = uuidv4();
            items[i].uniqueBinder = uniqueBinder;
            items[replacementIndex].uniqueBinder = uniqueBinder;
            continue;
          }
        }

        const sameDaySameTimeOppositeVenueAdditionIndex = items.findIndex(
          (row) =>
            row.venueId !== item.venueId &&
            row.date === item.date &&
            row.time === item.time &&
            row.action === "add"
        );
        if (sameDaySameTimeOppositeVenueAdditionIndex > -1) {
          const uniqueBinder = uuidv4();
          items[i].uniqueBinder = uniqueBinder;
          items[sameDaySameTimeOppositeVenueAdditionIndex].uniqueBinder =
            uniqueBinder;
        }
      }
    }
  };

  const getAdjustedAvailability = () => {
    const entertainmentAvailabilityCopy = JSON.parse(
      JSON.stringify(availability.Venue)
    );
    if (entertainmentBookingSelections?.length) {
      entertainmentBookingSelections.forEach((sel) => {
        let hasAvailabilityForBookedShow = false;
        const currentVenue = entertainmentAvailabilityCopy[
          sel.date
        ]?.AvailabilityDetails?.find((ent) => ent.FacilityGUID === sel.venueId);
        hasAvailabilityForBookedShow = !!currentVenue?.Times?.find(
          (entity) => entity.Time === sel.time
        );
        if (!hasAvailabilityForBookedShow) {
          currentVenue?.Times.push({
            Time: sel.time,
            Availability: "0",
            Price: "",
          });
        }
      });
    }
    return entertainmentAvailabilityCopy;
  };

  return (
    <EntertainmentsPageContext.Provider
      value={{
        selectedDateSelections,
        setSelectedDateSelections,
        entertainmentBookingSelections,
        setEntertainmentBookingSelections,
        handleSelectedDateSelections,
        daysRowExpandedTitle,
        daysRowCollapsedTitle,
        entertainmentShowHours,
        setEntertainmentShowHours,
        selectedEntertainmentShow,
        setSelectedEntertainmentShow,
        skippedDays,
        setSkippedDays,
        selectedDate,
        setSelectedDate,
        handleSelectShow,
        mainStatus,
        setMainStatus,
        prepareSelectionObjects,
        handleBooking,
        displayedDateIndex,
        setDisplayedDateIndex,
        mobileDaySlider,
        setMobileDaySlider,
        entertainmentDetails,
        entertainmentLocationDetails,
        setEntertainmentLocationDetails,
        entertainmentAvailability,
        setEntertainmentAvailability,
        getAdjustedAvailability,
        clickedRow, 
        setClickedRow,
      }}
    >
      {children}
    </EntertainmentsPageContext.Provider>
  );
};

export default EntertainmentsPageProvider;
