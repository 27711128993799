import React from "react";
import { IonCol, IonLabel } from "@ionic/react";
import CreateDimensions from "../../../helpingFunctions/DimensionsFactory";
import BLIcon from "../BLIcon";
import styles from "./BLLabel.module.scss";
import { TEXT_CSS_CLASS_IDENTIFIER } from "../BLIonicConstants";
import { classFormatter } from "../BLIonicHelpers";
import BLTooltip from "../Boxes/Popup/BLTooltip";

const BLLabel = ({
  label,
  center,
  labelDimensions = {},
  labelLeftMargin,
  beforeIconSource,
  beforeIconOnClick,
  beforeIconDisable,
  afterIconSource,
  afterIconTxt,
  afterIconColor = "secondary",
  newClasses,
  labelClasses,
  color
}) => {
  const labelDimensionStyles = CreateDimensions(labelDimensions);
  const labelSpecificClasses = classFormatter(
    newClasses,
    TEXT_CSS_CLASS_IDENTIFIER
  );

  return (
    <IonCol
      className={`${
        labelDimensionStyles.dimensions
      } ${labelSpecificClasses} ${"ion-text-wrap"} ${
        center ? "ion-text-center" : "ion-text-left"
      } ion-align-items-center bl-flex`}
    >
      {beforeIconSource && (
        <BLIcon
          color="primary"
          iconSource={beforeIconSource}
          onCLick={beforeIconOnClick}
          disable={beforeIconDisable}
          newClasses="bl-padding-r-xs"
        />
      )}

      <IonLabel
        color={color}
        className={`${styles["bl-input-label"]} ${labelClasses}`}
        style={{
          marginLeft: labelLeftMargin,
          marginRight: afterIconSource && "16px",
        }}
      >
        {label}
      </IonLabel>

      {afterIconSource && (
        <BLTooltip
          icon={afterIconSource}
          iconColor={afterIconColor}
          txt={afterIconTxt}
          iconClasses="bl-icon-sm"
        />
      )}
    </IonCol>
  );
};

export default BLLabel;
