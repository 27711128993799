import { useState } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';

const DropdownLogic = () => {
  const [isClicked, setIsClicked] = useState(false);

  const optionSelectionHandler = () => {
    setIsClicked(!isClicked);
  };

  const handleClickOutside = () => {
    setIsClicked(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  return {optionSelectionHandler, isClicked, ref};
};

export default DropdownLogic;