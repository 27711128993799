import React, { createContext, useContext, useEffect, useState } from "react";
import {
  ACTION_TYPES,
  ITEM_POPULAR_STATUSES,
  SERVICES_TYPES,
  INITIAL_DATE,
} from "../constants/constants.js";
import { getDayOfStay } from "../helpingFunctions/dateTimeUtilities.js";

import { useLocation } from "react-router-dom";
import { compare } from "../helpingFunctions/arrays.js";
import {
  formatDateDetailed,
  getCurrentDate,
  getDatesBetween,
  isChristmassEve,
  isDateInThePast,
  isDateWithinRange,
  isNewYearsEve,
} from "../helpingFunctions/dateTimeUtilities.js";
import { replacePlaceholders } from "../helpingFunctions/replacePlaceholders.js";
import RequestHook from "../hooks/RequestHook.js";
import {
  DINING_MODALS,
  DINING_SELECTIONS_ATTR,
  MAESTRO_BOOKING_TYPE,
} from "../views/children/DiningBookingPage/DiningBookingConstants.js";
import { ConflictManagementContext } from "./ConflictManagementContext.js";
import { useDataContext } from "./DataContext.js";
import { areAllConflictsResolved } from "../helpingFunctions/conflictManagement/resolveMethods/resolveMethods.js";
import { DiningPartyContext } from "./DiningPartyContext.js";

export const DiningBookingContext = createContext({});

const DiningBookingProvider = ({ children, viewType }) => {
  const location = useLocation();
  const {
    reservationDetails,
    itineraryDetails,
    setReservationDetails,
    dynamicTexts,
    facilitiesData,
    availability,
  } = useDataContext();
  const {
    setFoundConflicts,
    foundConflicts,
    loadNextConflict,
    setSpotlitConflict,
  } = useContext(ConflictManagementContext);

  const { diningPartyDetails } = useContext(DiningPartyContext);

  const [selectedType, setSelectedType] = useState(
    new Set([DINING_MODALS.DAY, DINING_MODALS.RESTAURANTS])
  );
  const [diningBookingSelections, setDiningBookingSelections] = useState([]);
  const [daySelections, setDaySelections] = useState(null);
  const [daySelectorOpts, setDaySelectorOpts] = useState(null);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [selectedDateSelections, setSelectedDateSelections] = useState({});
  const [diningBookingSelectionsPreview, setDiningBookingSelectionsPreview] =
    useState({ selections: [] });
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(null);
  const [clickedRestaurantId, setClickedRestaurantId] = useState(null);
  const [breakfastDetails, setBreakfastDetails] = useState(null);
  const [dinnerDetails, setDinnerDetails] = useState(null);
  const [diningAvailability, setDiningAvailability] = useState(null);
  const [restaurantDetails, setRestaurantDetails] = useState(null);
  const [showRestaurantDetails, setShowRestaurantDetails] = useState(false);
  const [viewAllTreats, setViewAllTreats] = useState(false);
  const [addOnDetails, setAddOnDetails] = useState(null);
  const [showAddOnDetails, setShowAddOnDetails] = useState(false);
  const [treatsByCurrentView, setTreatsByCurrentView] = useState({});
  const [groupDetachmentConfirmDialog, setGroupDetachmentConfirmDialog] = useState(false);

  useEffect(() => {
    if (facilitiesData) {
      setBreakfastDetails(facilitiesData.Breakfast);
      setDinnerDetails(facilitiesData.Dinner);
    }
  }, [facilitiesData]);

  useEffect(() => {
    if (availability) {
      setDiningAvailability({
        Breakfast: availability.Breakfast,
        Dinner: availability.Dinner,
      });
    }
  }, [availability]);

  useEffect(() => {
    if (reservationDetails) {
      extractDaySelections();
    }
  }, [viewType, reservationDetails]);

  useEffect(() => {
    setShowRestaurantDetails(false);
  }, []);

  // Forced trigger to try to process the bookings to maestro when the foundConflicts state is updated
  useEffect(() => {
    if (foundConflicts) {
      handleBooking();
    }
  }, [foundConflicts]);

  useEffect(() => {
    if (
      Object.keys(selectedDateSelections).length === 0 &&
      diningBookingSelections.length > 0
    ) {
      handleDateChange(diningBookingSelections[0].date);
    }
  }, [restaurantOptions]);

  const datesList = getDatesBetween(
    reservationDetails?.ArrivalDate,
    reservationDetails?.DepartureDate,
    viewType
  );

  const {
    sendDiningBookingRequest,
    getItinerary,
    refreshReservationState,
    getActivitiesAvailability,
  } = RequestHook();

  const extractDaySelections = () => {
    const stayingDates = getDatesBetween(
      reservationDetails.ArrivalDate,
      reservationDetails.DepartureDate,
      viewType
    );

    const daySels = [];
    stayingDates.forEach((date) => {
      daySels.push({
        Title: formatDateDetailed(stayingDates, date, true),
        fullTitle: formatDateDetailed(stayingDates, date),
        dayOfStay: getDayOfStay(stayingDates, date),
        view: location?.state,
        id: date,
        isCompleted: false,
        disable: date !== getCurrentDate() && isDateInThePast(date),
      });
    });

    setDaySelections(daySels);
  };

  const updateDaySelectionStatus = (bookings) => {
    const daySelectionsCopy = JSON.parse(JSON.stringify(daySelections));

    daySelectionsCopy.forEach((row) => {
      const selection = bookings.find((sel) => sel.date === row.id);

      row.isCompleted =
        !!(selection.restaurantId && selection.time) || !!selection.isSkipped;
    });

    setDaySelectorOpts(daySelectionsCopy);
  };

  const initialSelectionsPreparation = () => {
    const preparedBookingSelections = [];

    for (const dateObj of daySelections) {
      const confirmedBooking = itineraryDetails?.Dates[dateObj.id][viewType][0];

      const diningObj = {
        date: dateObj.id,
        time: null,
        restaurantId: null,
        action: ACTION_TYPES.NONE,
        treatAddOns: [],
        dinningType: viewType,
        FirstName: reservationDetails.FirstName,
        LastName: reservationDetails.LastName,
        Quantity: reservationDetails.Adults,
        ReservationNumber: reservationDetails.ReservationNumber,
        type: MAESTRO_BOOKING_TYPE,
        isSkipped: hasSkippedCurrentDay(dateObj.id),
      };

      if (confirmedBooking) {
        diningObj.BookingNumber = confirmedBooking.BookingNumber;
        diningObj.time = confirmedBooking.Time;
        diningObj.restaurantId = confirmedBooking.Id;
        diningObj.bookedTime = confirmedBooking.Time;
        diningObj.bookedRestaurantId = confirmedBooking.Id;
        diningObj.isSkippedBooked = hasSkippedCurrentDay(dateObj.id);
      }

      preparedBookingSelections.push(diningObj);
    }

    setDiningBookingSelections(preparedBookingSelections);
    updateDaySelectionStatus(preparedBookingSelections);
  };

  const handleNoBookingNeeded = () => {
    try {
      const selectedDateObjCopy = JSON.parse(
        JSON.stringify(selectedDateSelections)
      );
      const selectionsObjCopy = JSON.parse(
        JSON.stringify(diningBookingSelections)
      );

      const diningSelIndex = selectionsObjCopy.findIndex(
        (row) => row?.date === selectedDateObjCopy.date
      );

      if (
        selectedDateSelections.action === ACTION_TYPES.NOT_REQ ||
        selectedDateSelections.isSkipped
      ) {
        if (selectedDateSelections.bookedTime) {
          selectedDateObjCopy.action = ACTION_TYPES.NONE;
          selectedDateObjCopy.isSkipped =
            selectedDateSelections.isSkippedBooked;
          selectedDateObjCopy.time = selectedDateSelections.bookedTime;
          selectedDateObjCopy.restaurantId =
            selectedDateSelections.bookedRestaurantId;
        } else {
          // Revert no booking required when confirmed
          selectedDateObjCopy.action = ACTION_TYPES.NONE;
          selectedDateObjCopy.isSkipped = false;
        }
      } else {
        if (selectedDateObjCopy.isSkipped) {
          // Revert no booking required when not confirmed
          selectedDateObjCopy.action = ACTION_TYPES.NONE;
          selectedDateObjCopy.isSkipped = false;
        } else {
          selectedDateObjCopy.action = ACTION_TYPES.NOT_REQ;
          selectedDateObjCopy.time = null;
          selectedDateObjCopy.restaurantId = null;
          selectedDateObjCopy.isSkipped = true;
        }
      }

      selectionsObjCopy[diningSelIndex] = selectedDateObjCopy;
      setSelectedDateSelections(selectedDateObjCopy);
      setDiningBookingSelections(selectionsObjCopy);
      updateDaySelectionStatus(selectionsObjCopy);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleNoBookingNeeded func in DaysLogic.js ${error.message}`
      );
    }
  };

  const hasSkippedCurrentDay = (date, diningType) => {
    if (date) {
      const skippedDateObj = reservationDetails?.diningDatesSkipped?.find(
        (row) => row.date === date
      );

      if (!skippedDateObj) {
        return null;
      }

      if (
        (viewType === SERVICES_TYPES.BREAKFAST ||
          diningType === SERVICES_TYPES.BREAKFAST) &&
        typeof skippedDateObj.isBreakfastSkipped === "boolean"
      ) {
        return skippedDateObj.isBreakfastSkipped;
      } else if (
        (viewType === SERVICES_TYPES.DINNER ||
          diningType === SERVICES_TYPES.DINNER) &&
        typeof skippedDateObj.isDinnerSkipped === "boolean"
      ) {
        return skippedDateObj.isDinnerSkipped;
      }

      return null;
    }
  };

  const updateDaySelection = (property, value) => {
    const selectedDateObjCopy = JSON.parse(
      JSON.stringify(selectedDateSelections)
    );

    const selectionsObjCopy = JSON.parse(
      JSON.stringify(diningBookingSelections)
    );

    const diningSelIndex = selectionsObjCopy.findIndex(
      (row) => row?.date === selectedDateObjCopy.date
    );

    selectedDateObjCopy[property] = value;

    if (DINING_SELECTIONS_ATTR.RESTAURANT === property) {
      selectedDateObjCopy.time = null;
    }

    if (selectedDateObjCopy.bookedTime) {
      if (
        selectedDateObjCopy.bookedTime === selectedDateObjCopy.time &&
        selectedDateObjCopy.bookedRestaurantId ===
        selectedDateObjCopy.restaurantId &&
        selectedDateObjCopy.isSkippedBooked === selectedDateObjCopy.isSkipped
      ) {
        selectedDateObjCopy.action = ACTION_TYPES.NONE;
      } else {
        // If its booked and lacking restaurant or time then it means that the reservation is in incomplete state - show no changes made and ignore from processing
        if (!selectedDateObjCopy.restaurantId || !selectedDateObjCopy.time) {
          selectedDateObjCopy.action = ACTION_TYPES.NONE;
        } else {
          selectedDateObjCopy.action = ACTION_TYPES.EDIT;
        }
      }
    } else {
      if (selectedDateObjCopy.time === null) {
        selectedDateObjCopy.action = ACTION_TYPES.NONE;
      } else {
        selectedDateObjCopy.action = ACTION_TYPES.ADD;
      }
    }

    setSelectedDateSelections(selectedDateObjCopy);
    selectionsObjCopy[diningSelIndex] = selectedDateObjCopy;
    setDiningBookingSelections(selectionsObjCopy);
    updateDaySelectionStatus(selectionsObjCopy);
  };

  const prepareRestaurantAndTimeOptions = () => {
    try {
      if (viewType === SERVICES_TYPES.DINING_PARTY) return;

      const currentViewObjDetails =
        viewType === SERVICES_TYPES.BREAKFAST
          ? breakfastDetails
          : dinnerDetails;
      const { All } = currentViewObjDetails;
      const currentTypeAvailability = diningAvailability[viewType];

      const currentDateDetails = [];

      for (let index = 0; index < datesList.length; index++) {
        const date = datesList[index];

        const restaurantDetails = [];
        for (let index = 0; index < All.length; index++) {
          const restaurant = All[index];

          let Times;
          if (currentTypeAvailability && currentTypeAvailability[date]) {
            Times = getAvailableTimesPerRestaurant(
              currentTypeAvailability[date],
              restaurant.id,
              itineraryDetails.Dates[date][viewType][0]
            );
          }

          restaurantDetails.push({
            restaurantId: restaurant.id,
            details: restaurant,
            times: Times ? Times : [],
          });
        }

        currentDateDetails.push({
          date,
          restaurants: restaurantDetails,
        });
      }

      setRestaurantOptions(currentDateDetails);
    } catch (error) {
      console.error(
        `${new Date()} Error in prepareRestaurantAndTimeOptions func in DiningBookingContext.js file ${error.message}`
      );
    }
  };

  const getAvailableTimesPerRestaurant = (
    availableTimes,
    restaurantId,
    currentDateItinerary
  ) => {
    try {
      const { AvailabilityDetails } = availableTimes;
      const specificRestaurantAvailability = AvailabilityDetails.find(
        ({ FacilityGUID }) => FacilityGUID === restaurantId
      );

      const Times = specificRestaurantAvailability?.Times;
      if (currentDateItinerary && specificRestaurantAvailability) {
        const preSelectedTimeIsAvailable = Times.some(
          ({ Time }) => Time === currentDateItinerary.Time
        );

        if (!preSelectedTimeIsAvailable) {
          Times.push({
            Time: currentDateItinerary.Time,
            Availability: `${currentDateItinerary.Quantity}`,
          });

          const timesWithPreSelectedTime = Times;

          const sortedTimes = timesWithPreSelectedTime.sort((a, b) =>
            compare(
              new Date(`${INITIAL_DATE}T${a.Time}`).getTime(),
              new Date(`${INITIAL_DATE}T${b.Time}`).getTime(),
              "asc"
            )
          );
          return sortedTimes;
        }
      }

      return Times;
    } catch (error) {
      console.error(
        `${new Date()} Error in getAvailableTimesPerRestaurant func in DiningBookingContext.js ${error.message}`
      );
    }
  };

  const handleDateChange = (date) => {
    try {
      let finalDate;

      if (isDateInThePast(date)) {
        const today = getCurrentDate();
        finalDate = today;
      } else {
        finalDate = date;
      }

      setSelectedDateSelections(
        diningBookingSelections.find((row) => row.date === finalDate) || {}
      );
    } catch (error) {
      console.error(
        `${new Date()} Error in handleDateChange func in DaysLogic.js ${error.message}`
      );
    }
  };

  const handleBoxSelection = (boxType) => {
    try {
      const selectedTypesArr = Array.from(selectedType);
      if (selectedType.has(boxType)) {
        setSelectedType(
          new Set(selectedTypesArr.filter((row) => row !== boxType))
        );
        return;
      }

      selectedTypesArr.push(boxType);
      setSelectedType(new Set(selectedTypesArr));
    } catch (error) {
      console.error(
        `${new Date()} handleBoxSelection func DiningBookingContext.js ${error.message}`
      );
    }
  };

  const preparePreviewScreenData = (data, status) => {
    setDiningBookingSelectionsPreview({
      selections: data,
      mainStatus: status,
    });
  };

  const prepareRequestBodyData = () => {
    const preparedRQObj = [];

    for (const sel of diningBookingSelectionsPreview.selections) {
      if (sel.action === ACTION_TYPES.ADD && !sel.time) {
        continue;
      }

      if (sel.action !== ACTION_TYPES.NONE) {
        const prepObj = {
          id: sel.restaurantId,
          Date: `${sel.date}T${sel.action === ACTION_TYPES.NOT_REQ ? sel.bookedTime : sel.time}`,
          LastName: sel.LastName,
          ReservationNumber: sel.ReservationNumber,
          Quantity: sel.Quantity,

          isSkipped: sel.isSkipped,
          date: sel.date,
          time: sel.time,
          restaurantId: sel.restaurantId,
          action: sel.action,
          dinningType: viewType,
          BookingNumber: sel.BookingNumber,
          status: "loading",
        };

        if (ACTION_TYPES.EDIT === sel.action) {
          if (!sel.time) {
            prepObj.action = ACTION_TYPES.REMOVE;
            prepObj.Date = `${sel.date}T${sel.bookedTime}`;
          } else {
            prepObj.statusCancel = "loading";
          }
        }

        if (
          [
            ACTION_TYPES.EDIT,
            ACTION_TYPES.REMOVE,
            ACTION_TYPES.NOT_REQ,
          ].includes(sel.action)
        ) {
          prepObj.isSkippedBooked = sel.isSkippedBooked;
          prepObj.bookedTime = sel.bookedTime;
          prepObj.bookedRestaurantId = sel.bookedRestaurantId;
        }

        if (
          sel.action === ACTION_TYPES.ADD &&
          !sel.time &&
          !sel.restaurantId &&
          typeof sel.isSkipped === "boolean"
        ) {
          continue;
          // prepObj.action = "update-not-req";
        }

        preparedRQObj.push(prepObj);
      }
    }

    const shouldBookForGroup = reservationDetails.IsGroupCreator;

    const participantBookings = [];
    if (shouldBookForGroup) {
      for (const booking of preparedRQObj) {
        const participants = diningPartyDetails?.participants || [];
        for (const participant of participants) {
          const participantReqObj = {
            id: booking.id,
            Date: booking.Date,
            FirstName: participant.FirstName,
            LastName: participant.LastName,
            Quantity: participant.Adults,
            ReservationNumber: participant.ReservationNumber,
            action: booking.action,
            type: booking.type,
            dinningType: viewType,

            isSkipped: booking.isSkipped,
            status: "loading",
            date: booking.date,
            time: booking.time,
            restaurantId: booking.restaurantId,
            isParticipant: true,
          };

          if (ACTION_TYPES.EDIT === booking.action) {
            if (!booking.time) {
              participantReqObj.action = ACTION_TYPES.REMOVE;
              participantReqObj.Date = `${booking.date}T${booking.bookedTime}`;
            } else {
              participantReqObj.statusCancel = "loading";
            }
          }

          if (
            [
              ACTION_TYPES.EDIT,
              ACTION_TYPES.REMOVE,
              ACTION_TYPES.NOT_REQ,
            ].includes(booking.action)
          ) {
            participantReqObj.isSkippedBooked = booking.isSkippedBooked;
            participantReqObj.bookedTime = booking.bookedTime;
            participantReqObj.bookedRestaurantId = booking.bookedRestaurantId;
          }
          participantBookings.push(participantReqObj);
        }
      }
    }

    setDiningBookingSelectionsPreview({
      selections: preparedRQObj,
      mainStatus: ITEM_POPULAR_STATUSES.LOAD,
    });
    return {
      request: [...preparedRQObj, ...participantBookings],
      diningAddOns: [],
      isGroupRequest: shouldBookForGroup,
      GroupId: diningPartyDetails.id,
      reservationType: viewType,
    };
  };

  const syncAfterSave = async () => {
    setReservationDetails((prevState) => {
      return {
        ...prevState,
        [viewType]: true,
      };
    });

    await getItinerary();
  };

  const handleBooking = async () => {
    try {
      // If there are any unresolved conflicts - negate the method until all of them are resolved
      if (!areAllConflictsResolved(foundConflicts)) {
        loadNextConflict(foundConflicts);
        return;
      }

      setSpotlitConflict(null);
      setFoundConflicts(null);

      const rqItems = prepareRequestBodyData();
      const { data } = await sendDiningBookingRequest(rqItems);

      await getItinerary();
      await refreshReservationState();
      getActivitiesAvailability(reservationDetails?.Adults);
      data.status === ITEM_POPULAR_STATUSES.SUCCESS &&
        setDiningBookingSelectionsPreview({
          selections: data.selections.filter((row) => !row.isParticipant),
          mainStatus: rqItems.isGroupRequest
            ? ITEM_POPULAR_STATUSES.SUCCESS
            : data.selections.filter(
              ({ status }) => status === ITEM_POPULAR_STATUSES.SUCCESS
            ).length === data.selections.length
              ? ITEM_POPULAR_STATUSES.SUCCESS
              : ITEM_POPULAR_STATUSES.FAILURE,
        });
      const selectedTypesArr = Array.from(selectedType);
      setSelectedType(
        new Set(selectedTypesArr.filter((row) => row !== DINING_MODALS.TIME))
      );
      setSelectedDateSelections({});
      setDiningBookingSelections([]);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleBooking func in DiningBookingContext.js ${error.message}`
      );
    }
  };

  const handleDiningBookingsForParticipants = async (
    diningBookingSelections, GroupId
  ) => {
    try {
      await sendDiningBookingRequest({
        request: diningBookingSelections,
        diningAddOns: [],
        isGroupRequest: true,
        GroupId
      });

      await syncAfterSave();
    } catch (error) {
      console.error(
        `${new Date()} Error in handleBooking func in DiningBookingContext.js ${error.message} `
      );
    }
  };

  const getDayParagraph = (date) => {
    const displayDate = date || reservationDetails?.ArrivalDate;
    return formatDateDetailed(
      datesList,
      displayDate,
      false,
      viewType === SERVICES_TYPES.BREAKFAST
    );
  };

  const getUnavailRestBannerText = () => {
    const selectedDate = selectedDateSelections.date;

    if (viewType === SERVICES_TYPES.DINNER) {
      if (isChristmassEve(selectedDate)) {
        return dynamicTexts?.Shared?.Banners
          ?.Banner_Card_NoDinnerAvailabilityLeft25thDecemberText;
      } else if (isNewYearsEve(selectedDate)) {
        return dynamicTexts?.Shared?.Banners
          ?.Banner_Card_NoDinnerAvailabilityLeft31thDecemberText;
      }
    }

    if (isDateWithinRange(selectedDate, 90)) {
      return replacePlaceholders(
        dynamicTexts?.Shared?.Banners
          ?.Banner_Card_NoAvailabilityWithin90DaysFromToday,
        {
          PEOPLE_COUNT: reservationDetails.Adults,
        }
      );
    } else {
      return replacePlaceholders(
        dynamicTexts?.Shared?.Banners
          ?.Banner_Card_NoAvailabilityOutside90DaysFromToday,
        {
          PEOPLE_COUNT: reservationDetails.Adults,
        }
      );
    }
  };

  const autoSelectRestaurant = () => {
    // If the user hasn't made a selection for a restaurant for the given day
    if (
      selectedDateSelections.hasOwnProperty("restaurantId") &&
      !selectedDateSelections.restaurantId &&
      restaurantOptions.length > 0
    ) {
      const selectionCopy = JSON.parse(JSON.stringify(selectedDateSelections));
      const dayRestaurants = restaurantOptions.find(
        (row) => row.date === selectionCopy.date
      );

      // Check if there is only 1 restaurant available
      if (dayRestaurants.restaurants.length === 1) {
        // Validate if we are going to check availability for a group or single reservation
        const availabilityFilterCount =
          [SERVICES_TYPES.BREAKFAST, SERVICES_TYPES.DINNER].includes(
            viewType
          ) &&
            !reservationDetails[viewType] &&
            reservationDetails.IsGroupCreator
            ? reservationDetails.GroupParticipants
            : reservationDetails.Adults;

        const hasAtleastOneAvailSlot = dayRestaurants.restaurants[0].times.some(
          (row) => Number(row.Availability) >= availabilityFilterCount
        );

        // If only one restaurant is available, check if any of the given times in that restaurant can satisfy the booking needed quantity
        if (hasAtleastOneAvailSlot) {
          updateDaySelection(
            "restaurantId",
            dayRestaurants.restaurants[0].restaurantId
          );
        }
      }
    }
  };

  const prepareLeadGuestBookings = () => {
    const preparedBookingSelections = [];

    for (const dateObj of daySelections) {
      const confirmedBreakfastBooking =
        itineraryDetails.Dates[dateObj.id].Breakfast[0];
      const confirmedDinnerBooking =
        itineraryDetails.Dates[dateObj.id].Dinner[0];

      const diningObj = {
        date: dateObj.id,
        time: null,
        restaurantId: null,
        action: ACTION_TYPES.NONE,
        treatAddOns: [],
        FirstName: reservationDetails.FirstName,
        LastName: reservationDetails.LastName,
        Quantity: reservationDetails.Adults,
        ReservationNumber: reservationDetails.ReservationNumber,
        type: MAESTRO_BOOKING_TYPE,
      };

      const breakfastObj = {
        ...diningObj,
        isSkipped: hasSkippedCurrentDay(dateObj.id, SERVICES_TYPES.BREAKFAST),
      };

      const dinnerObj = {
        ...diningObj,
        isSkipped: hasSkippedCurrentDay(dateObj.id, SERVICES_TYPES.DINNER),
      };

      if (confirmedBreakfastBooking || breakfastObj.isSkipped) {
        if (confirmedBreakfastBooking) {
          breakfastObj.time = confirmedBreakfastBooking.Time;
          breakfastObj.restaurantId = confirmedBreakfastBooking.Id;
          breakfastObj.bookedTime = confirmedBreakfastBooking.Time;
          breakfastObj.bookedRestaurantId = confirmedBreakfastBooking.Id;
          breakfastObj.isSkippedBooked = hasSkippedCurrentDay(
            dateObj.id,
            SERVICES_TYPES.BREAKFAST
          );
        }
        breakfastObj.dinningType = confirmedBreakfastBooking?.Type || SERVICES_TYPES.BREAKFAST;
        preparedBookingSelections.push(breakfastObj);
      }

      if (confirmedDinnerBooking || dinnerObj.isSkipped) {
        if (confirmedDinnerBooking) {
          dinnerObj.time = confirmedDinnerBooking.Time;
          dinnerObj.restaurantId = confirmedDinnerBooking.Id;
          dinnerObj.bookedTime = confirmedDinnerBooking.Time;
          dinnerObj.bookedRestaurantId = confirmedDinnerBooking.Id;
          dinnerObj.isSkippedBooked = hasSkippedCurrentDay(
            dateObj.id,
            SERVICES_TYPES.DINNER
          );
        }
        dinnerObj.dinningType = confirmedDinnerBooking?.Type || SERVICES_TYPES.DINNER;
        preparedBookingSelections.push(dinnerObj);
      }
    }
    return preparedBookingSelections;
  };

  useEffect(() => {
    if (
      itineraryDetails &&
      !itineraryDetails.error &&
      reservationDetails &&
      daySelections &&
      viewType !== SERVICES_TYPES.DINING_PARTY
    ) {
      initialSelectionsPreparation();
    }
  }, [itineraryDetails, reservationDetails, daySelections]);

  useEffect(() => {
    const isValid =
      itineraryDetails &&
      diningAvailability &&
      diningBookingSelections.length > 0;

    if (isValid) {
      prepareRestaurantAndTimeOptions();
    }
  }, [itineraryDetails, diningAvailability, diningBookingSelections]);

  useEffect(() => {
    if (
      location?.state?.isItineraryRedirect &&
      diningBookingSelections.length > 0
    ) {
      handleDateChange(location.state.itineraryObj.Date);
    }
  }, [location, diningBookingSelections]);

  useEffect(() => {
    if (selectedDateSelections) {
      const isValidSelection =
        ![ACTION_TYPES.REMOVE, ACTION_TYPES.NOT_REQ].includes(
          selectedDateSelections.action
        ) && !selectedDateSelections.isSkipped;

      if (isValidSelection) {
        autoSelectRestaurant();
      }

      // Open times row if a restaurant is selected and close it if its not
      const selectedTypesArr = Array.from(selectedType);
      if (
        selectedDateSelections[DINING_SELECTIONS_ATTR.RESTAURANT] &&
        isValidSelection
      ) {
        if (!selectedTypesArr.includes(DINING_MODALS.TIME)) {
          selectedTypesArr.push(DINING_MODALS.TIME);
          setSelectedType(new Set(selectedTypesArr));
        }
      } else {
        setSelectedType(
          new Set(selectedTypesArr.filter((row) => row !== DINING_MODALS.TIME))
        );
      }
    }
  }, [selectedDateSelections, restaurantOptions]);

  return (
    <DiningBookingContext.Provider
      value={{
        viewType,
        selectedType,
        selectedDateSelections,
        restaurantOptions,
        diningBookingSelections,
        datesList,
        diningBookingSelectionsPreview,
        daySelections,
        daySelectorOpts,
        preparePreviewScreenData,
        setDiningBookingSelectionsPreview,
        getDayParagraph,
        setDiningBookingSelections,
        setSelectedDateSelections,
        prepareRestaurantAndTimeOptions,
        updateDaySelection,
        handleBooking,
        handleBoxSelection,
        handleDiningBookingsForParticipants,
        getUnavailRestBannerText,
        handleNoBookingNeeded,
        handleDateChange,
        prepareLeadGuestBookings,
        steps,
        setSteps,
        activeStep,
        setActiveStep,
        clickedRestaurantId,
        setClickedRestaurantId,
        breakfastDetails,
        dinnerDetails,
        restaurantDetails,
        setRestaurantDetails,
        showRestaurantDetails,
        setShowRestaurantDetails,
        viewAllTreats,
        setViewAllTreats,
        addOnDetails,
        setAddOnDetails,
        showAddOnDetails,
        setShowAddOnDetails,
        treatsByCurrentView,
        setTreatsByCurrentView,
        groupDetachmentConfirmDialog,
        setGroupDetachmentConfirmDialog,
      }}
    >
      {children}
    </DiningBookingContext.Provider>
  );
};

export default DiningBookingProvider;
