import { ACTIVITY_TYPES, MAESTRO_BACKEND_NAMING_VIEWS } from "../constants/constants";

const treatsSelected = (treatsBookings, date) => {
  const dayTreats = treatsBookings.filter((row) => row.Date === date);
  const bookedAddOns = dayTreats.filter((row) => row.action === "none");
  if (bookedAddOns.length > 0) {
    return "booked";
  } else if (dayTreats.length > 0) {
    return "selected";
  } else {
    return `not selected`;
  }
};

const multipleSelected = (
  date,
  width,
  selectedDate,
  bookingSelections,
  skipedDays,
  id,
) => {


  if (skipedDays.includes(date))
    return width <= 700 ? "not req." : "not required";
  const booked = id
    ? bookingSelections.filter(
      (row) =>
        row.day === date &&
          (row.action === "none" || row.action === "edit") &&
          row.facilityGUID.includes(id)
    )
    : bookingSelections.filter(
      (row) =>
        row.day === date && (row.action === "none" || row.action === "edit")
    );

  if (booked.length > 0) {
    return width <= 700 && date !== selectedDate
      ? ""
      : `${booked.length} booked`;
  } else {
    const bookSelections = bookingSelections.filter(
      (row) => row.day === date && row.action === "add"
    );

    const isSelected =
      [...ACTIVITY_TYPES, MAESTRO_BACKEND_NAMING_VIEWS.WELLNESS_ACTIVITY].includes(
        bookSelections[0]?.type
      ) && !bookSelections[0].hasOwnProperty("Quantity");

    return width <= 700 && date !== selectedDate
      ? ""
      : isSelected || bookSelections.length === 0
        ? `not selected`
        : `${bookSelections.length} selected`;
  }
};

const singleSelected = (
  date,
  width,
  selectedDate,
  bookingSelections,
  skipedDays
) => {
  if (skipedDays.includes(date))
    return width <= 700 ? "not req." : "not required";

  const booked = bookingSelections.filter(
    (row) =>
      row.day === date && (row.action === "none" || row.action === "edit")
  );
  if (booked.length > 0) {
    return width <= 700 && date !== selectedDate
      ? ""
      : booked.length === 0
        ? `no bookings`
        : `booked`;
  } else {
    const bookSelections = bookingSelections.filter(
      (row) => row.day === date && row.action === "add"
    );
    return width <= 700 && date !== selectedDate
      ? ""
      : bookSelections.length === 0 ||
        !bookSelections[0].hasOwnProperty("restaurant") ||
        !bookSelections[0].hasOwnProperty("time")
        ? `not selected`
        : `selected`;
  }
};


export {
  multipleSelected,
  singleSelected,
  treatsSelected,
};
