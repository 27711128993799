import add from "date-fns/add";

/** 
    Method for checking if the target day/time is in the past compared to another date/time
  */
const isBookingInThePast = (
  bookingDay,
  bookingTime,
  currentDay,
  currentTime
) => {
  return (
    bookingDay < currentDay ||
    (bookingDay === currentDay &&
      bookingTime?.substring(0, 5) <= currentTime?.substring(0, 5))
  );
};

/** 
    Method for checking if the target time is between two other times
  */
const isOverlapping = (time, startTime, endTime) => {
  return (
    time.substring(0, 5) >= startTime.substring(0, 5) &&
    time.substring(0, 5) <= endTime.substring(0, 5)
  );
};

/** 
    Method for checking if any of the overlaping cases are covered:
    case 1: selected start time is between booked duration -OR-
    case 2: selected end time is between booked duration -OR-
    case 3: booked start time is between selected duration -OR-
    case 4: booked end time is between selected duration -OR-
  */
const isOverallOverlaping = (
  selectionStartTime,
  selectionEndTime,
  bookedServStartTime,
  bookedServEndTime
) => {
  const isSelectedStartOverlapingBookedDuration = isOverlapping(
    selectionStartTime,
    bookedServStartTime,
    bookedServEndTime
  );
  const isSelectedEndOverlapingBookedDuration = isOverlapping(
    selectionEndTime,
    bookedServStartTime,
    bookedServEndTime
  );
  const isBookedStartOverlapingSelectionDuration = isOverlapping(
    bookedServStartTime,
    selectionStartTime,
    selectionEndTime
  );
  const isBookedEndOverlapingSelectionDuration = isOverlapping(
    bookedServEndTime,
    selectionStartTime,
    selectionEndTime
  );

  return (
    isSelectedStartOverlapingBookedDuration ||
    isSelectedEndOverlapingBookedDuration ||
    isBookedStartOverlapingSelectionDuration ||
    isBookedEndOverlapingSelectionDuration
  );
};

/** 
    Method for retrieving the end time for a given time and duration
  */
const getEndTime = (time, duration) => {
  if (duration === 0 || !duration) return time.substring(0, 5);

  const currentDate = new Date().toISOString().substring(0, 10);

  return add(new Date(`${currentDate}T${time.substring(0, 5)}`), {
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: duration,
    seconds: 0,
  })
    .toTimeString()
    .substring(0, 5);
};

/** 
    Method for retrieving a simplified version of the selection object.
    CAUTIONS: By default, if the selection object lacks a 'Quantity' fields, the quantity will be set to 1
  */
const getSimplifiedSelectionObj = (
  selection,
  serviceDetails,
  configObj
) => {
  const hasAValidPrice = serviceDetails.Price && !isNaN(serviceDetails.Price);

  return {
    date: selection[configObj.datePar],
    time: selection[configObj.timePar],
    action: selection.action,
    quantity: selection.Quantity
      ? Number(selection.Quantity)
      : selection.quantity
        ? Number(selection.quantity)
        : 1,
    singleUnitPrice: hasAValidPrice && serviceDetails.Price,
    title: serviceDetails.Title,
    id: serviceDetails.id,
    firstName: selection.FirstName,
    lastName: selection.LastName,
    type: serviceDetails.Type,
  };
};

export {
  isBookingInThePast,
  isOverlapping,
  isOverallOverlaping,
  getEndTime,
  getSimplifiedSelectionObj,
};
