import React, { useContext } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

import { useTheme } from "../../../../../hooks/useTheme";
import { useDataContext } from "../../../../../context/DataContext";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";
import CreateDimensions from "../../../../../helpingFunctions/DimensionsFactory";
import BLInformationBox from "../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import LocalLoader from "../../../../../shared/Ionic/Loader/LocalLoader";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import CreateDiningParty from "../CreateDiningParty/CreateDiningParty";
import DiningPartyForm from "../DiningPartyForm/DiningPartyForm";
import ParticipantDisplay from "../ParticipantDisplay/ParticipantDisplay";
import DiningPartyInitLogic from "./DiningPartyInitLogic";
import { PARTICIPANT_DISPLAY_TITLE } from "../../diningPartyConstants";

const DiningPartyInit = () => {
  DiningPartyInitLogic();
  const { typography } = useTheme();
  const { dynamicTexts } = useDataContext();
  const { response, isPostGrCreationPartAddedLoading, diningPartySelections: { participants, id }, } =
    useContext(DiningPartyContext);

  const hasUsedFormSuccessfully = participants.length > 0;

  const { dimensions: paragraphColDimensions } = CreateDimensions({
    padding: ["t-s"],
  });
  
  return (
    <BLContentCardWrapper dimensions={{ padding: ["s", "b-m"] }}>
      <IonGrid>
        <IonRow>
          <IonCol className={paragraphColDimensions} size="12">
            <BLParagraph
              txt={dynamicTexts?.Dining?.DiningParty?.Paragraph_FormIntro_DuringGroupCreation}
              textVariant={typography.BODY_TEXT}
              markDown
            />
          </IonCol>
        </IonRow>
        <DiningPartyForm />

        {hasUsedFormSuccessfully && (
          <>
            <IonRow>
              <IonCol size="12">
                <ParticipantDisplay title={PARTICIPANT_DISPLAY_TITLE} />
              </IonCol>
            </IonRow>
            <CreateDiningParty />
          </>
        )}

        {!id && (
          <IonRow
            className={`${
              response?.isLoading || isPostGrCreationPartAddedLoading
                ? "ion-justify-content-center"
                : ""
            } bl-padding-t-m`}
          >
            {(response?.isLoading || isPostGrCreationPartAddedLoading) && (
              <LocalLoader
                loaderSize={"40px"}
                dimensions={{ margin: ["t-l"] }}
              />
            )}

            {response?.message && !isPostGrCreationPartAddedLoading && (
              <BLInformationBox
                content={response.message}
                type={response.infoBoxColor}
              />
            )}
          </IonRow>
        )}
      </IonGrid>
    </BLContentCardWrapper>
  );
};

export default DiningPartyInit;
