import { useHistory } from "react-router-dom";
import { useDataContext } from "../../../../../context/DataContext";
import axiosInstance from "../../../../../url/createAxios";
import { MAESTRO_BACKEND_NAMING_VIEWS, SERVICES_TYPES } from "../../../../../constants/constants";
import { WhatsOnDataContext } from "../../../../../context/WhatsOnDataContext";
import { useContext } from "react";

const BookActivityLogic = (id, setView, internalActivityItineraryHandler) => {
  const history = useHistory();
  const instance = axiosInstance();
  const { selectedDate } = useContext(WhatsOnDataContext);
  const { reservationDetails, reservationHotelDetails, facilitiesDataDetails } = useDataContext();

  const findActivityType = () => {
    try {
      const [strapiId] = id.split("_");
      const parsedStrapiId = Number(strapiId);

      const selectedActivity = facilitiesDataDetails.find(
        (facility) => facility.id === parsedStrapiId
      );

      if (selectedActivity) {
        return selectedActivity.objType;
      } else {
        return null;
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in findActivityType func in BookActivityLogic.js ${
          error.message
        }`
      );
    }
  };

  const maestroActivityClicked = (guideItem) => {
    const [originalId] = id.split("_");
    history.push("/");

    let viewType = null;
    if (guideItem.ActivityType === MAESTRO_BACKEND_NAMING_VIEWS.VENUE) {
      viewType = SERVICES_TYPES.ENTERTAINMENT;
    } else if (!guideItem.ActivityType) {
      const selectedActivityType = findActivityType();

      if (!selectedActivityType) {
        return;
      }
      viewType = selectedActivityType;
    } else {
      viewType = guideItem.ActivityType;
    }

    setView({
      type: viewType,
      id: parseInt(originalId),
    });
  };

  const manipulateInternalActivity = async (timeRecord) => {
    try {
      const bookingDetails = {
        HotelId: reservationHotelDetails.HotelId,
        ReservationNumber: reservationDetails.ReservationNumber,
        CRSNumber: reservationDetails.CRSNumber,
        GuestName: `${reservationDetails.LastName}, ${reservationDetails.FirstName}`,
        LastName: reservationDetails.LastName,
        FirstName: reservationDetails.FirstName,
        Date: selectedDate,
        Time: `${timeRecord.time}:00`,
        ActivityId: timeRecord.activityId.split("_")[0],
        ActivityTitle: timeRecord.Title,
        InternalActivityTimeId: timeRecord.timeId,
        Type: "Internal Activity",
        Quantity: reservationDetails.Adults,
        RoomNumber: reservationDetails.RoomNumber,
        Comments: reservationDetails.Comments,
        IsInternalActivity: true,
      };

      await instance.post(
        "/activity-bookings/add",
        {
          bookingDetails,
        },
        {
          withCredentials: true,
        }
      );
      internalActivityItineraryHandler({ IsInternalActivity: true });
    } catch (error) {
      console.error(
        `${new Date()} Error in internalActivityClicked func inside BookActivityLogic.js file ${
          error.message
        }`
      );
    }
  };

  return {
    maestroActivityClicked,
    manipulateInternalActivity,
    selectedDate,
  };
};

export default BookActivityLogic;
