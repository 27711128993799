import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { location, time } from "ionicons/icons";
import React, { useState } from "react";
import { INITIAL_DATE } from "../../../../../constants/constants";
import { useDataContext } from "../../../../../context/DataContext";
import { formatTime } from "../../../../../helpingFunctions/dateTimeUtilities";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import BLCheckTriangle from "../../../../../shared/Ionic/Cards/BLCheckTriangle/BLCheckTriangle";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import { LAST_FEW_REMAINING_BREAKPOINT } from "../../constants";
import DisabledCardText from "./DisabledCardText";
import EntertainmentDetailsPopupBox from "./EntertainmentDetailsPopupBox";
import styles from "./ShowsCards.module.scss";
import { useTheme } from "../../../../../hooks/useTheme";
import BLLink from "../../../../../shared/Ionic/Links/BLAnchor/BLLink";

const btnProps = {
  deselect: {
    txt: "Deselect",
    type: "outlined",
  },
  select: {
    txt: "Select",
    type: "primary",
  },
};

const ShowsSummaryCardMobile = ({
  onClick,
  showDetails,
  selectedShow,
  handleSelection,
  isSelected,
  isDisabled,
  disabledText,
  isClicked,
  locationDetails,
  availability,
  isAlreadyBooked,
}) => {
  const { typography } = useTheme();
  const buttonType = isSelected ? "deselect" : "select";
  const btn = btnProps[buttonType];
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const { dynamicTexts, reservationDetails } = useDataContext();
  const isLastFew =
    availability > 0 && availability <= LAST_FEW_REMAINING_BREAKPOINT;
  const isNotAvailable =
    reservationDetails.Adults > availability && !isAlreadyBooked;

  return (
    <IonGrid
      style={{
        borderRadius: "8px",
        border: isSelected ? "2px solid var(--ion-color-secondary)" : "",
      }}
      onClick={(event) => onClick(event)}
    >
      {isDisabled() && (
        <DisabledCardText
          text={disabledText}
          newClasses={`${
            isClicked() && styles.ShowsExpandedCenterDisabledText
          }`}
        />
      )}
      <IonRow
        className={`${isDisabled() || isNotAvailable ? "bl-disable-fade" : ""}`}
      >
        <IonCol
          style={{ height: isClicked() ? "168px" : "224px", width: "200px" }}
        >
          {isSelected && <BLCheckTriangle size="sm" />}
          <BLImageCarousel
            content={
              showDetails.showImages && showDetails.showImages.length > 0
                ? showDetails.showImages
                : showDetails.locationImages
            }
            borderRadius="8px 8px 0 0"
          />
          {(isLastFew || isNotAvailable) && (
            <div className={styles.BLItem_lastfew_banner}>
              {isLastFew
                ? dynamicTexts?.Shared?.Banners?.Banner_Card_LimitedAvailability
                : dynamicTexts?.Shared?.Banners
                  ?.Banner_Card_NoAvailabilityWithin90DaysFromToday}
            </div>
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <BLContentCardWrapper
            background="white"
            dimensions={{ padding: ["b-xxs"] }}
          >
            {!isClicked() ? (
              <IonGrid className="bl-padding-s">
                <IonRow className="bl-margin-b-xs ion-text-center">
                  <IonCol>
                    <BLParagraph
                      color="primary"
                      txt={showDetails.title}
                      textVariant={typography.h4}
                      // newClasses={`small bold crimson-pro`}
                      //TODO: Unclear typography
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <BLIcon
                      color="primary"
                      iconSource={time}
                      newClasses="bl-padding-r-xs"
                    />
                    <BLParagraph
                      color="text-grey"
                      txt={formatTime(
                        `${INITIAL_DATE}T${showDetails.Time?.slice(0, 5)}`,
                        true
                      )}
                      textVariant={typography.ITINERARY_BODY_TEXT}
                    />
                  </IonCol>
                </IonRow>
                <IonRow className="bl-margin-t-xs">
                  <IonCol>
                    <BLIcon
                      color="primary"
                      iconSource={location}
                      newClasses="bl-padding-r-xs"
                    />
                    <BLParagraph
                      color="text-grey"
                      txt={showDetails.location}
                      textVariant={typography.ITINERARY_BODY_TEXT}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : (
              <IonGrid className="bl-padding-xs">
                <IonRow className="bl-margin-b-s">
                  <IonCol>
                    <BLParagraph
                      color="primary"
                      txt={showDetails.title}
                      textVariant={typography.h3}
                      // newClasses={`medium bold crimson-pro`}
                      //TODO: Unclear typography
                    />
                  </IonCol>
                </IonRow>
                <IonRow className="bl-margin-b-xs">
                  <IonCol>
                    <BLIcon
                      color="primary"
                      iconSource={time}
                      newClasses="bl-padding-r-xs"
                    />
                    <BLParagraph
                      color="text-grey"
                      txt={formatTime(
                        `${INITIAL_DATE}T${showDetails.Time?.slice(0, 5)}`,
                        true
                      )}
                      textVariant={typography.BODY_TEXT}
                    />
                  </IonCol>
                </IonRow>
                <IonRow className="bl-margin-b-s">
                  <IonCol>
                    <BLIcon
                      color="primary"
                      iconSource={location}
                      newClasses="bl-padding-r-xs"
                    />
                    <BLLink
                      txt={showDetails.location}
                      onClick={() => setShowLocationDetails(true)}
                    />
                  </IonCol>
                </IonRow>
                <IonRow
                  className="ellipsis-4rd-row bl-margin-b-xs"
                  style={{ maxHeight: "90px" }}
                >
                  <IonCol>
                    <BLParagraph
                      txt={`${showDetails.description || ""}`}
                      textVariant={typography.BODY_TEXT}
                      color="text-grey"
                    />
                  </IonCol>
                </IonRow>
                <IonRow className="bl-margin-b-s">
                  <IonCol>
                    <BLLink
                      txt="Read more"
                      onClick={() => setShowAdditionalDetails(true)}
                    />
                  </IonCol>
                </IonRow>

                <IonRow className="ion-justify-content-center">
                  <IonCol size="auto">
                    <BLButton
                      content={btn.txt}
                      type={btn.type}
                      dimensions={{ margin: ["t-xs", "b-xs"] }}
                      onClick={handleSelection}
                      disabled={isNotAvailable || isDisabled()}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}
          </BLContentCardWrapper>
        </IonCol>
      </IonRow>

      {showAdditionalDetails && (
        <EntertainmentDetailsPopupBox
          showAlert={showAdditionalDetails}
          dismissAlert={setShowAdditionalDetails}
          images={
            selectedShow.showImages
              ? selectedShow.showImages
              : selectedShow.locationImages
          }
          header={selectedShow.title}
          longDescription={selectedShow.description}
          location={selectedShow.location}
        />
      )}
      {showLocationDetails && (
        <EntertainmentDetailsPopupBox
          showAlert={showLocationDetails}
          dismissAlert={setShowLocationDetails}
          images={locationDetails?.LocationImages}
          header={locationDetails?.Location}
          longDescription={locationDetails?.LocationDescription}
        />
      )}
    </IonGrid>
  );
};

export default ShowsSummaryCardMobile;
