import React, { useContext } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import { pricetag, today, checkmarkCircle, closeCircle } from "ionicons/icons";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import { TreatsContext } from "../../../../../context/TreatsContext";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";
import { getTotalPriceSummaryText } from "../../../../../helpingFunctions/GetTotalPriceSummaryText";
import { SERVICES_TYPES } from "../../../../../constants/constants";
import { useTheme } from "../../../../../hooks/useTheme";

const BookedTreatsDetailsCardDesktop = ({ isSummaryScreen }) => {
  const { typography } = useTheme();
  const { treatsSelections, selectedService, confirmationScreenSelections, isProcessingBookings, daySelections } = useContext(TreatsContext);

  const selections = isSummaryScreen
    ? treatsSelections
    : confirmationScreenSelections;

  return (
    <>
      <IonRow className="bl-margin-t-m">
        <IonCol
          className="bl-flex"
          style={{ gap: "12px", flexDirection: "column" }}
        >
          {selections.map((item, index) => (
            <BLContentCardWrapper
              key={index}
              background={"white"}
              dimensions={{ padding: ["s"] }}
              newStyles={{
                gap: "15px",
                display: "flex",
                maxWidth: "400px",
                margin: "0 auto",
              }}
            >
              <BLIcon color={"primary"} iconSource={today} />
              <IonGrid
                style={{
                  gap: "10px",
                  display: "grid",
                }}
              >
                <IonRow>
                  <IonCol
                    size="12"
                    className={"bl-flex ion-align-items-center"}
                  >
                    <BLParagraph
                      txt={
                        daySelections.find((row) => row.id === item.date)
                          ?.fullTitle
                      }
                      textVariant={typography.h5}
                      // newClasses={`x-small bold`}
                      //TODO: Unclear typography
                    />
                  </IonCol>
                </IonRow>
                {item.options.map((opt, optIndex) => (
                  <IonRow key={optIndex}>
                    <IonCol
                      size="12"
                      className={`bl-flex ion-align-items-center ${
                        !isSummaryScreen && "ion-justify-content-between"
                      }`}
                    >
                      <BLParagraph
                        txt={`${opt.quantity} x ${opt.location}`}
                        textVariant={typography.BODY_TEXT}
                        typographyStyles={opt.action === "remove" ? {textDecoration: "line-through"} : {}}
                      />

                      {!isSummaryScreen && (
                        <>
                          {isProcessingBookings ? (
                            <BLLoadingContainer newClasses="bl-margin-xxs" />
                          ) : (
                            <>
                              {opt.Status === "success" ? (
                                <BLIcon
                                  color="success"
                                  iconSource={checkmarkCircle}
                                  newClasses="bl-padding-r-xs"
                                />
                              ) : (
                                <BLIcon
                                  color="danger"
                                  iconSource={closeCircle}
                                  newClasses="bl-padding-r-xs"
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </IonCol>
                  </IonRow>
                ))}
              </IonGrid>
            </BLContentCardWrapper>
          ))}
        </IonCol>
      </IonRow>
      <IonRow className={"bl-margin-t-m bl-padding-l-s bl-margin-b-s"}>
        <IonCol>
          <BLContentCardWrapper
            newStyles={{
              gap: "5px",
              display: "flex",
              maxWidth: "400px",
              margin: "0 auto",
              alignItems: "center",
            }}
          >
            <BLIcon color={"primary"} iconSource={pricetag} iconSize={"md"} />
            {!confirmationScreenSelections ? getTotalPriceSummaryText(
              "summaryScreen",
              selectedService.Price,
              treatsSelections,
              SERVICES_TYPES.TREATS,
              isProcessingBookings,
              typography
            ) : getTotalPriceSummaryText(
              "confirmationScreen",
              selectedService.Price,
              confirmationScreenSelections,
              SERVICES_TYPES.TREATS,
              isProcessingBookings,
              typography
            )}
          </BLContentCardWrapper>
        </IonCol>
      </IonRow>
    </>
  );
};

export default BookedTreatsDetailsCardDesktop;
