import React, { useContext, useEffect } from "react";
import { IonCol, IonRow } from "@ionic/react";
import BLButton from "../../../../shared/Ionic/Buttons/BLButton";
import { SideMenuContext } from "../../../../context/SideMenuContext";
import { validateForm } from "../../../../helpingFunctions/helpingFunctions";

const LinkReservationsPageActionButtons = ({
  formData,
  handleRequest,
  isWaitingToLogIn,
  setErrMsg,
  isFormButtonDisabled,
  setIsFormButtonDisabled
}) => {
  const { setFullScreenOverlayContent } = useContext(SideMenuContext);
 

  useEffect(() => {
    setErrMsg("");
    const undefinedValues = validateForm(formData);
    const undefinedKeys = Object.keys(undefinedValues);
    if (undefinedKeys.length === 0) {
      setIsFormButtonDisabled(false);
    } else {
      setIsFormButtonDisabled(true);
    }
  }, [formData]);

  return (
    <IonRow className="ion-text-center ion-margin-top">
      <IonCol>
        <BLButton
          content={"Cancel"}
          newClasses="uppercase"
          type={"outlined"}
          disabled={isWaitingToLogIn}
          onClick={() => setFullScreenOverlayContent(null)}
        />
      </IonCol>

      <IonCol>
        <BLButton
          content={"Continue"}
          newClasses="uppercase"
          type={"primary"}
          onClick={() => handleRequest(formData)}
          disabled={isWaitingToLogIn || isFormButtonDisabled}
        />
      </IonCol>
    </IonRow>
  );
};

export default LinkReservationsPageActionButtons;
