const CUSTOM_MADE_CLASS_PREFIX = "bl-";
const TEXT_CSS_CLASS_IDENTIFIER = "bl-text-";
const CHECKBOX_CLASS_IDENTIFIER = "bl-checkbox-";

const IONIC_CSS_CLASS_IDENTIFIER = "ion-";
const CLASS_NOT_FOUND_TEXT = "undefined";

export {
  CUSTOM_MADE_CLASS_PREFIX,
  IONIC_CSS_CLASS_IDENTIFIER,
  CLASS_NOT_FOUND_TEXT,
  TEXT_CSS_CLASS_IDENTIFIER,
  CHECKBOX_CLASS_IDENTIFIER
};