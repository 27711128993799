import { getPlatforms } from "@ionic/react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  ANDROID_PLATFORM,
  AUTHENTICATED_KEY,
  IOS_PLATFORM,
  TCLink,
} from "../constants/constants";
import { useDataContext } from "../context/DataContext";
import { SideMenuContext } from "../context/SideMenuContext";
import { ROUTE_VIEWS } from "../routes/routeViews";
import axiosInstance from "../url/createAxios";

const ValidateLoginHook = () => {
  const instance = axiosInstance();
  const history = useHistory();
  const { clearOnLogOut } = useDataContext();
  const { setFullScreenOverlayContent } = useContext(SideMenuContext);

  const handleLogin = async (reqBody, isLinkingReservation) => {
    try {
      const devicePlatforms = getPlatforms();
      const isMobile =
        !devicePlatforms.includes("mobileweb") &&
        (devicePlatforms.includes(IOS_PLATFORM) ||
          devicePlatforms.includes(ANDROID_PLATFORM));
     
      const { data } = await instance.post(
        !isLinkingReservation ? `/login` : "/Hotel/Booking/ConnectReservation",
        {
          isMobile: isMobile,
          ...reqBody,
          withCredentials: isLinkingReservation,
        }
      );
      if (data.success && !isLinkingReservation) {
        localStorage.setItem(AUTHENTICATED_KEY, "true");
        window.dispatchEvent(new Event("storage"));
        localStorage.removeItem(TCLink);
      }

      return {
        success: data.success,
        error: data.error,
      };
    } catch (e) {
      return {
        success: false,
        error: e.response
          ? e.response.data.error
          : "Network connection error. Please try again later!",
      };
    }
  };

  const logout = async () => {
    await instance.delete(`/login`, { withCredentials: true });
    setFullScreenOverlayContent(null);
    clearOnLogOut();
    history.replace(ROUTE_VIEWS.LOGIN);
  };

  return { handleLogin, logout };
};

export default ValidateLoginHook;
