import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { closeCircle, pricetag, time } from "ionicons/icons";
import React from "react";
import { formatPrice } from "../../../helpingFunctions/formatPrice";
import BLIcon from "../BLIcon";
import BLParagraph from "../Paragraph/BLParagraph";
import { useTheme } from "../../../hooks/useTheme";
import { formatDuration } from "../../../helpingFunctions/formatDuration";

const BookingSelectionHeaderDesktop = ({
  serviceDetails,
  onXSignClick,
  hideAddInfo,
}) => {
  const { typography } = useTheme();
  return (
    serviceDetails &&
    <IonGrid style={{ borderBottom: "2px solid var(--ion-color-primary)" }}>
      <IonRow className="bl-margin-b-xs">
        <IonCol
          className="bl-flex ion-justify-content-center ion-align-items-center"
        >
          <BLParagraph
            txt={serviceDetails.Title}
            color="text-grey"
            textVariant={typography.h2}
            // newClasses={`xx-large bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-lg"],
              padding: ["t-md", "b-md"],
            }}
          />
        </IonCol>
        <IonCol size="0.5" style={{ position: "absolute", top: 0, right: 0 }}>
          <BLIcon
            color="secondary"
            iconSize="md"
            iconSource={closeCircle}
            onClick={onXSignClick}
          />
        </IonCol>
      </IonRow>

      {hideAddInfo && (
        <IonRow
          className="bl-margin-t-xs bl-margin-b-xs ion-justify-content-center"
          style={{ gap: "15px" }}
        >
          <IonCol
            className="bl-flex ion-justify-content-end ion-align-items-center"
          >
            <BLIcon color="primary" iconSource={time} newClasses="bl-padding-r-xs" />
            <BLParagraph
              txt={formatDuration(serviceDetails.Duration)}
              textVariant={typography.BODY_TEXT}
            />
          </IonCol>
          <IonCol className="bl-flex ion-align-items-center">
            <BLIcon color="primary" iconSource={pricetag} newClasses="bl-padding-r-xs" />
            <BLParagraph
              txt={`${serviceDetails.Price === "Inclusive" ? "Inclusive" :
                formatPrice(serviceDetails.Price)}`}
              textVariant={typography.BODY_TEXT}
            />
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};

export default BookingSelectionHeaderDesktop;
