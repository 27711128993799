import { useContext, useEffect } from "react";
import { INITIAL_DATE } from "../../../../../constants/constants";
import { WhatsOnDataContext } from "../../../../../context/WhatsOnDataContext";

import { ERROR_TEXTS } from "../../../../../constants/errorConstants";

const FilterLogic = () => {
  const {
    updateWhatsOnGuideDisplay,
    manageFilters,
    whatsOnGuideDataStatic,
    selectedFilters,
    whatsOnGuideAllDayActivitiesStatic,
    setWhatsOnGuideAllDayActivities,
    setAlert,
    selectedDate
  } = useContext(WhatsOnDataContext);

  const manageFiltration = (selection, filterType) => {
    if (selection.value === "All") {
      const clonedFilter = JSON.parse(JSON.stringify(selectedFilters));
      delete clonedFilter[filterType];

      if (Object.keys(clonedFilter).length > 0) {
        manageFilters({ ...clonedFilter });
      } else {
        manageFilters({});
        if(selectedDate) {
          updateWhatsOnGuideDisplay([...whatsOnGuideDataStatic]);
        } else {
          setWhatsOnGuideAllDayActivities(whatsOnGuideAllDayActivitiesStatic);
          setAlert({
            isError: false,
          });
        }
      }
      return;
    }

    manageFilters((prevState) => {
      if (prevState[filterType]) {
        prevState[filterType] = selection;
        return { ...prevState };
      }
      return { ...prevState, [filterType]: selection };
    });
  };

  const filterAction = (selectedOption) => {
    let filteredData = null;
    if(!selectedDate) {
      if (!selectedOption["location"] || selectedOption["location"]?.value === "All") {
        setWhatsOnGuideAllDayActivities(whatsOnGuideAllDayActivitiesStatic);
      } else {
        filteredData = whatsOnGuideAllDayActivitiesStatic.filter(
          (allDayActivity) => 
            allDayActivity.Location === selectedOption["location"]?.value
        );
        if (filteredData.length === 0) {
          setAlert({
            isError: true,
            msg: selectedOption["location"]?.value ? ERROR_TEXTS.ACTIVITY_FILTER_ERROR : ERROR_TEXTS.ACTIVITY_NOT_CREATED_ERROR,
          });
        } else {
          setAlert({
            isError: false,
          });
        }
        setWhatsOnGuideAllDayActivities(filteredData);
      }
    } else {
      filteredData = applyFilter(whatsOnGuideDataStatic, selectedOption);
      updateWhatsOnGuideDisplay([...filteredData]);
    }
  };

  const applyFilter = (data, selectedOption) => data.map((guideItem) => {
    const AvailabletimesFilered = guideItem.Availabletimes.availability?.map(
      (availableTime) => {
        const availabilityFiltered = availableTime.availability.map(
          (availabilityItem) => {
            const filteredTimes = availabilityItem.times.filter((timeObj) => {
              const selectedKeys = Object.keys(selectedOption);
              const selectedKeysLn = selectedKeys.length;

              if (!timeObj.recordId) {
                return;
              }
              const matchItems = selectedKeys.filter((key) => {
                switch (key) {
                  case "location": {
                    return timeObj.Location.id === selectedOption[key].id;
                  }
                  case "time": {
                    const availableTime = new Date(
                      `${INITIAL_DATE}T${timeObj.time}:00`
                    ).getTime();
                    const startTime = new Date(
                      `${INITIAL_DATE}T${selectedOption[key].startHour}:00`
                    ).getTime();
                    const endTime = new Date(
                      `${INITIAL_DATE}T${selectedOption[key].endHour}:00`
                    ).getTime();
                    return (
                      availableTime >= startTime && availableTime <= endTime
                    );
                  }
                  default: {
                    return true;
                  }
                }
              });

              return matchItems.length === selectedKeysLn;
            });

            return {
              ...availabilityItem,
              times: filteredTimes,
            };
          }
        );
        return {
          ...availableTime,
          availability: availabilityFiltered,
        };
      }
    );
    return {
      ...guideItem,
      Availabletimes: { availability: AvailabletimesFilered },
    };
  });

  useEffect(() => {
    if (Object.keys(selectedFilters).length > 0) {
      filterAction(selectedFilters);
    }
  }, [selectedFilters]);

  return { manageFiltration };
};

export default FilterLogic;
