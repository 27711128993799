import { Keyboard } from "@capacitor/keyboard";
import React, { useEffect, useState } from "react";
import { useDataContext } from "../../../../../context/DataContext";
import ValidateLoginHook from "../../../../../hooks/ValidateLoginHook";
import {
  CRSNumberAttr,
  hotelAttr,
  surnameAttr,
} from "../../constants/constants";
import styles from "../../login.module.scss";
import { isMobile } from "../../../../../helpingFunctions/utilities";

const LoginFormLogic = (loginPageData) => {
  const [formData, setFormData] = useState({
    [hotelAttr]: "",
    [surnameAttr]: "",
    [CRSNumberAttr]: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const {
    isKioskRedirect,
    prepLoginPageErrMsg,
    hasReservationExpired,
    setHasReservationExpired,
    expiredSessionErrMsg,
  } = useDataContext();
  const [isWaitingToLogIn, setIsWaitingToLogin] = useState(false);
  const [alert, setAlert] = useState({});
  const [popupContent, setPopupContent] = useState(null);
  const { handleLogin } = ValidateLoginHook();

  const manageFormData = (key, value) => {
    if (key === CRSNumberAttr && value.startsWith("4")) {
      isMobile() && Keyboard.hide();
      setPopupContent(
        <>
          <p>{loginPageData?.CRSInfoText}</p>
          {loginPageData?.CRSInfoImage ? (
            <img
              className={styles.LoginForm__AlertImg}
              src={loginPageData?.CRSInfoImage?.formats?.small?.url || loginPageData?.CRSInfoImage?.url}
            />
          ) : null}
        </>
      );
      setAlert({
        isError: false,
        isAlert: true,
      });
      return;
    }

    if (key === surnameAttr) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }

    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleRequest = async (e) => {
    e.preventDefault();
    try {
      setHasReservationExpired(false);
      setErrMsg("");
      setAlert({});
      setIsWaitingToLogin(true);

      const res = await handleLogin(formData);

      if (res.error) {
        setIsWaitingToLogin(false);
        const message = prepLoginPageErrMsg(res.error);
        setErrMsg(message);
        setAlert({
          isError: true,
          isAlert: false,
        });
      }
    } catch (err) {
      console.error(
        `${new Date()} Error in handleRequest function in LoginLogic.js file ${
          err.message
        }`
      );
    }
  };

  const handleKioskRedirect = () => {
    if (isKioskRedirect) {
      const heythropDetails = loginPageData.Hotels.find((row) =>
        row.Title.includes("Heythrop")
      );
      if (heythropDetails) {
        manageFormData("hotelId", heythropDetails.id);
      }
    }
  };

  useEffect(() => {
    if (hasReservationExpired && expiredSessionErrMsg) {
      setAlert({
        isAlert: true,
        isError: false,
        func: () => setAlert({}),
      });
      setErrMsg(expiredSessionErrMsg);
    }
  }, [hasReservationExpired, expiredSessionErrMsg]);

  useEffect(() => {
    handleKioskRedirect();
  }, [isKioskRedirect]);

  return {
    manageFormData,
    handleRequest,
    errMsg,
    alert,
    setAlert,
    formData,
    isWaitingToLogIn,
    setErrMsg,
    popupContent,
    setPopupContent,
  };
};

export default LoginFormLogic;
