import { IonFooter, IonRow, IonToolbar } from "@ionic/react";
import React, { useState } from "react";
import { AUTHENTICATED_KEY, TCLink } from "../../constants/constants";
import { useDataContext } from "../../context/DataContext";
import LegalDocsLinks from "../../views/children/LoginPage/LegalDocsLinks";
import { useEffect } from "react";

const DesktopFooter = () => {
  const { pagesConfiguration } = useDataContext();
  const isLoggedIn = localStorage.getItem(AUTHENTICATED_KEY);
  const [storageTCLink, setStorageTCLink] = useState(
    localStorage.getItem(TCLink)
  );

  useEffect(() => {
    const getTCLink = () => {
      if (localStorage.getItem(TCLink)) {
        setStorageTCLink(localStorage.getItem(TCLink));
      }
    };

    window.addEventListener("storage", getTCLink);

    return () => {
      window.removeEventListener("storage", getTCLink);
    };
  }, [storageTCLink]);

  return (
    <IonFooter>
      <IonToolbar
        className="ion-no-padding"
        color="primary"
        style={{ "--min-height": "30px" }}
      >
        <IonRow className="ion-justify-content-center">
          <LegalDocsLinks
            tcLink={
              isLoggedIn
                ? pagesConfiguration?.TermsAndConditions?.Link
                : storageTCLink
            }
          />
        </IonRow>
      </IonToolbar>
    </IonFooter>
  );
};

export default DesktopFooter;
