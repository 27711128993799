import React, { useContext } from "react";
import { IonCol, IonRow } from "@ionic/react";

import { ACTION_TYPES, SERVICES_TYPES } from "../../../../../constants/constants";
import {
  formatTime,
  getCurrentDate,
  isDateWithinRange,
} from "../../../../../helpingFunctions/dateTimeUtilities";
import { DiningBookingContext } from "../../../../../context/DiningBookingContext";
import { DINING_SELECTIONS_ATTR } from "../../DiningBookingConstants";
import { useDataContext } from "../../../../../context/DataContext";
import { INITIAL_DATE } from "../../../../../constants/constants";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import TimesLogic from "./TimesLogic";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";

import styles from "./Times.module.scss";
import BLDateTimeFormatParagraph from "../../../../../shared/Ionic/Paragraph/BLDateTimeFormatParagraph";
import { format } from "date-fns";
import BLInformationBox from "../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import { replacePlaceholders } from "../../../../../helpingFunctions/replacePlaceholders";

const Times = ({ viewType }) => {
  const { selectedDateSelections, updateDaySelection } =
    useContext(DiningBookingContext);
  const isRemove =
    selectedDateSelections[DINING_SELECTIONS_ATTR.ACTION] ===
    ACTION_TYPES.REMOVE;
  const { selectedRestaurantTimes } = TimesLogic(viewType);
  const {
    reservationDetails,
    dynamicTexts,
    isLoadingItinerary,
    isLoadingAvailability,
  } = useDataContext();
  const retrieveGuestNum = () => {
    if (
      viewType === SERVICES_TYPES.BREAKFAST &&
      reservationDetails.IsGroupCreator
    ) {
      return reservationDetails.GroupParticipants;
    } else if (
      viewType === SERVICES_TYPES.DINNER &&
      reservationDetails.IsGroupCreator
    ) {
      return reservationDetails.GroupParticipants;
    } else {
      return reservationDetails.Adults;
    }
  };

  const getNoAvailabilityForSelectionMsg = () => {
    return (
      <IonRow style={{ marginBottom: "16px", marginTop: "16px" }}>
        {!isDateWithinRange(reservationDetails.ArrivalDate, 90) ? (
          <BLInformationBox
            content={replacePlaceholders(
              dynamicTexts?.Shared?.LackOfAvailability
                ?.InfoBox_TimeSlot_NoAvailabilityOutside90DaysFromToday,
              {
                PEOPLE_COUNT: retrieveGuestNum(),
              }
            )}
            type="warning"
            markdown
          />
        ) : (
          <BLInformationBox
            content={replacePlaceholders(
              dynamicTexts?.Shared?.LackOfAvailability
                ?.InfoBox_TimeSlot_NoAvailabilityWithin90DaysFromToday,
              {
                PEOPLE_COUNT: retrieveGuestNum(),
              }
            )}
            type="warning"
            markdown
          />
        )}
      </IonRow>
    );
  };

  const notEnoughAvailability = (availabilityAM, availabilityPM) => {
    return (
      (viewType === SERVICES_TYPES.BREAKFAST && !availabilityAM.some((row) => row.Availability >= retrieveGuestNum())) ||
      (viewType === SERVICES_TYPES.DINNER && !availabilityPM.some((row) => row.Availability >= retrieveGuestNum()))
    );
  };

  return (
    <>
      <BLContentCardWrapper dimensions={{ margin: ["t-m", "b-m"] }}>
        {isLoadingItinerary || isLoadingAvailability ? (
          <IonRow className="ion-text-center">
            <IonCol>
              <BLLoadingContainer />
            </IonCol>
          </IonRow>
        ) : (
          <>
            {selectedDateSelections.time &&
              reservationDetails[viewType] &&
              format(new Date(), "kkmm") >
                selectedDateSelections.time.slice(0, 5).replace(":", "") &&
              getCurrentDate() === selectedDateSelections.date && (
              <IonRow style={{ marginBottom: "16px" }}>
                <BLInformationBox
                  content={
                    dynamicTexts?.Shared?.LackOfAvailability
                      ?.InfoBox_TimeSlot_PastTime
                  }
                  type="warning"
                  markdown
                />
              </IonRow>
            )}
            <IonRow className="ion-justify-content-center">
              <IonCol size="11.8" className={`${styles.Times_Date}`}>
                <BLDateTimeFormatParagraph
                  date={selectedDateSelections.date}
                  view={viewType}
                />
              </IonCol>
            </IonRow>
            <>
              {notEnoughAvailability(selectedRestaurantTimes.AM, selectedRestaurantTimes.PM) &&
                getNoAvailabilityForSelectionMsg()}
              <IonRow className={styles.Times_slots}>
                {selectedRestaurantTimes?.AM?.map(({ Time, Availability }) => (
                  <BLButton
                    key={`${Time}_${Availability}`}
                    content={formatTime(
                      `${INITIAL_DATE}T${Time.substr(0, 5)}`,
                      false,
                      "p"
                    )}
                    type={`${
                      selectedDateSelections.time === Time && !isRemove
                        ? "time"
                        : "time-outlined"
                    }`}
                    disabled={
                      Number(Availability) < Number(retrieveGuestNum()) ||
                      (format(new Date(), "kkmm") >
                        Time.slice(0, 5).replace(":", "") &&
                        getCurrentDate() === selectedDateSelections.date) ||
                      (selectedDateSelections.time &&
                        reservationDetails[viewType] &&
                        format(new Date(), "kkmm") >
                          selectedDateSelections.time
                            .slice(0, 5)
                            .replace(":", "") &&
                        getCurrentDate() === selectedDateSelections.date)
                    }
                    onClick={() =>
                      updateDaySelection(DINING_SELECTIONS_ATTR.TIME, Time)
                    }
                  />
                ))}
                {selectedRestaurantTimes?.PM?.map(({ Time, Availability }) => (
                  <BLButton
                    key={`${Time}_${Availability}`}
                    content={formatTime(
                      `${INITIAL_DATE}T${Time.substr(0, 5)}`,
                      false,
                      "p"
                    )}
                    type={`${
                      selectedDateSelections.time === Time && !isRemove
                        ? "time"
                        : "time-outlined"
                    }`}
                    disabled={
                      Number(Availability) < Number(retrieveGuestNum()) ||
                      (format(new Date(), "kkmm") >
                        Time.slice(0, 5).replace(":", "") &&
                        getCurrentDate() === selectedDateSelections.date) ||
                      (selectedDateSelections.time &&
                        reservationDetails[viewType] &&
                        format(new Date(), "kkmm") >
                          selectedDateSelections.time
                            .slice(0, 5)
                            .replace(":", "") &&
                        getCurrentDate() === selectedDateSelections.date)
                    }
                    onClick={() =>
                      updateDaySelection(DINING_SELECTIONS_ATTR.TIME, Time)
                    }
                  />
                ))}
              </IonRow>
            </>
          </>
        )}
      </BLContentCardWrapper>
    </>
  );
};

export default Times;
