import { useContext, useEffect } from "react";

import { useDataContext } from "../../../../../context/DataContext";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";

const DiningPartyInitLogic = () => {
  const {
    reservationDetails: { PartOfGroup },
  } = useDataContext();
  const { addMoreHandler } = useContext(DiningPartyContext);

  useEffect(() => {
    addMoreHandler(PartOfGroup);
  }, []);

  return {};
};

export default DiningPartyInitLogic;
