import moment from "moment";
import { ARRIVAL_DATE_START_TIME } from "../constants/constants";

const checkIfHasArrived = (reservationDetails) => {
  try {
    const { ArrivalDate } = reservationDetails;
    const [ArrivalDateOnly] = ArrivalDate.split("T");
    const earliestCheckInPerArrivalDate = new Date(
      `${ArrivalDateOnly}T${ARRIVAL_DATE_START_TIME}`
    );
    const currentMoment = new Date();

    const hasArrived = currentMoment >= earliestCheckInPerArrivalDate;

    return hasArrived;
  } catch (error) {
    console.error(
      `${new Date()} Error in checkIfHasArrived func in bookingHelp.js ${error.message}`
    );
  }
};

const isPastDate = (date, time) => {
  try {
    const currentMoment = new Date();

    if (!time) {
      const currentDateStr = moment(currentMoment).format("YYYY-MM-DD");
      if (currentDateStr === date) {
        return false;
      } else {
        const dateObj = new Date(date);
        return dateObj.getTime() < currentMoment.getTime();
      }
    }

    const compareDate = new Date(`${date}T${time}`);

    return compareDate.getTime() < currentMoment.getTime();
  } catch (error) {
    console.error(
      `${new Date()} Error in isPastDate func in bookingHelp.js ${error.message}`
    );
  }

};

const hasAtleastOneBooking = (serviceId, itineraryDetails) => {
  let hasBooking = false;
  for (const day of Object.values(itineraryDetails?.Dates)) {
    const booking = day?.All.find((row) => row.Id === serviceId);

    if (booking) {
      hasBooking = true;
      return hasBooking;
    }
  }

  return hasBooking;
};

const hasBooking = (serviceId, itineraryDetails) => {
  return (
    itineraryDetails &&
    itineraryDetails.hasOwnProperty("Dates") &&
    hasAtleastOneBooking(serviceId, itineraryDetails)
  );
};

const hasCategoryItemsAtleastOneBooking = (cateogryId, validatedType, itineraryDetails) => {
  let hasBooking = false;
  if (itineraryDetails && itineraryDetails.hasOwnProperty("Dates")) {
    for (const day of Object.values(itineraryDetails?.Dates)) {
      const booking = day?.All.find((row) => row.CategoryId === cateogryId && row.Type === validatedType);

      if (booking) {
        hasBooking = true;
        return hasBooking;
      }
    }

    return hasBooking;
  }
};

export { isPastDate, checkIfHasArrived, hasAtleastOneBooking, hasBooking, hasCategoryItemsAtleastOneBooking };
