import { useContext, useEffect } from "react";
import { useDataContext } from "../../../../context/DataContext";
import { TreatsContext } from "../../../../context/TreatsContext";
import { getDatesBetween } from "../../../../helpingFunctions/dateTimeUtilities";

const TreatYourselfProcessingMethods = (setIsFirstTimeBooker) => {
  const { selectedService, setTreatsSelections, hasFailedTreatsBookings, confirmationScreenSelections, isProcessingBookings } = useContext(TreatsContext);

  const {
    reservationDetails,
    itineraryDetails,
    isLoadingItinerary,
    setReservationDetails,
  } = useDataContext();

  const initialLoad = () => {
    if (itineraryDetails && selectedService) {
      const selections = [];
      const stayingDates = getDatesBetween(
        reservationDetails.ArrivalDate,
        reservationDetails.DepartureDate
      );

      let itinerary = [];
      stayingDates.forEach((date) => {
        itinerary = [...itinerary, ...itineraryDetails.Dates[date].Treats];
      });

      const selectedServiceBookings = itinerary.filter(
        (row) => row.Id === selectedService.id
      );

      if (selectedServiceBookings.length > 0) {
        setIsFirstTimeBooker(false);

        selectedServiceBookings.forEach((row) => {
          const isAdded = selections.findIndex(
            (selRow) => selRow.date === row.Date
          );

          if (isAdded === -1) {
            selections.push({
              fName: reservationDetails.FirstName,
              lName: reservationDetails.LastName,
              guestName: `${reservationDetails.FirstName} ${reservationDetails.LastName}`,
              date: row.Date,
              bookedDate: row.Date,
              options: [
                {
                  action: "none",
                  location: row.Location,
                  bookedLocation: row.Location,
                  serviceCode: row.ServiceCode,
                  bookedServiceCode: row.ServiceCode,
                  quantity: row.Quantity,
                  bookedQuantity: row.Quantity,
                },
              ],
              action: "none",
              isEdited: false,
            });
          } else {
            selections[isAdded].options.push({
              action: "none",
              location: row.Location,
              bookedLocation: row.Location,
              serviceCode: row.ServiceCode,
              bookedServiceCode: row.ServiceCode,
              quantity: row.Quantity,
              bookedQuantity: row.Quantity,
            });
          }
        });

        setTreatsSelections(selections);
      } else if (hasFailedTreatsBookings && confirmationScreenSelections) {
        setTreatsSelections(confirmationScreenSelections);
      } else {
        setIsFirstTimeBooker(true);
        selections.push({
          fName: reservationDetails.FirstName,
          lName: reservationDetails.LastName,
          guestName: `${reservationDetails.FirstName} ${reservationDetails.LastName}`,
          date: "",
          bookedDate: "",
          options: [
            {
              action: "add",
              location: "",
              bookedLocation: "",
              serviceCode: "",
              bookedServiceCode: "",
              quantity: 1,
              bookedQuantity: "",
            },
          ],
          action: "add",
          isEdited: false,
        });

        setTreatsSelections(selections);
      }
    }
  };

  useEffect(() => {
    initialLoad();
  }, [isLoadingItinerary, selectedService, itineraryDetails]);

  useEffect(() => {
    if (!isProcessingBookings && itineraryDetails) {
      const hasTreatsBookings =
        Object.values(itineraryDetails.Dates).findIndex(
          (booking) => booking.Treats.length > 0
        ) !== -1;

      setReservationDetails((prevState) => {
        return {
          ...prevState,
          Treat: hasTreatsBookings,
        };
      });
    }
  }, [isProcessingBookings]);

  return {};
};

export default TreatYourselfProcessingMethods;
