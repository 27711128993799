import { CONFLICT_TYPES } from "../conflictConstants";
import { retrieveConflictAlertMessage } from "./namingHelperMethods";

/** 
    Method for extracting the SOFT conflict type message and constructing the conflict object 
  */
const prepareSoftConflictObj = (
  confirmed,
  selection,
  conflictedSelection,
  conflictManagementTexts,
  reservationDetails,
  configObj
) => {
  const msg = retrieveConflictAlertMessage(
    confirmed,
    selection,
    CONFLICT_TYPES.SOFT,
    reservationDetails,
    null,
    0,
    conflictManagementTexts,
    configObj
  );

  conflictedSelection.conflicts.push({
    conflictMsg: msg,
    conflictType: CONFLICT_TYPES.SOFT,
    conflictPriority: 3,
    isResolved: false,

    confirmedBookingDetails: confirmed,
  });
};

/** 
    Method for extracting the AVERAGE conflict type message and constructing the conflict object 
  */
const prepareAverageConflictObj = (
  confirmed,
  selection,
  conflictedSelection,
  conflictManagementTexts,
  reservationDetails,
  configObj
) => {
  const msg = retrieveConflictAlertMessage(
    confirmed,
    selection,
    CONFLICT_TYPES.AVERAGE,
    reservationDetails,
    null,
    0,
    conflictManagementTexts,
    configObj
  );

  conflictedSelection.conflicts.push({
    conflictMsg: msg,
    conflictType: CONFLICT_TYPES.AVERAGE,
    conflictPriority: 2,
    isResolved: false,

    confirmedBookingDetails: confirmed,
  });
};

const prepareStrongConflictObj = (
  confirmed,
  selection,
  conflictedSelection,
  conflictManagementTexts,
  reservationDetails,
  configObj
) => {
  const msg = retrieveConflictAlertMessage(
    confirmed,
    selection,
    CONFLICT_TYPES.STRONG,
    reservationDetails,
    null,
    0,
    conflictManagementTexts,
    configObj
  );

  conflictedSelection.conflicts.push({
    conflictMsg: msg,
    conflictType: CONFLICT_TYPES.STRONG,
    conflictPriority: 1,
    isResolved: false,

    confirmedBookingDetails: confirmed,
  });
};

export {
  prepareSoftConflictObj,
  prepareAverageConflictObj,
  prepareStrongConflictObj,
};
