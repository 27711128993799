import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { closeCircle, pencil } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { ActivityContext } from "../../../../../../../context/ActivityContext";
import { useDataContext } from "../../../../../../../context/DataContext";
import {
  formatTime,
  getCurrentDate,
} from "../../../../../../../helpingFunctions/dateTimeUtilities";
import BLIcon from "../../../../../../../shared/Ionic/BLIcon";
import BLNumberInput from "../../../../../../../shared/Ionic/Inputs/BLNumberInput";
import BLSelect from "../../../../../../../shared/Ionic/Inputs/BLSelect";
import BLDateTimeFormatParagraph from "../../../../../../../shared/Ionic/Paragraph/BLDateTimeFormatParagraph";
import BLParagraph from "../../../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import DaysRowSeparateSelectionLogic from "./DaysRowSeparateSelectionLogic";
import { prepareDaysDropdownList } from "../../../../../../../helpingFunctions/prepareDaysDropdownList";
import { isDesktop } from "../../../../../../../helpingFunctions/utilities";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../../../../../../../constants/constants";
import { useTheme } from "../../../../../../../hooks/useTheme";

const DaysRowSeparateSelection = ({selIndex, dateIndex, when}) => {
  const { typography } = useTheme();
  const { activitySelections, setActivitySelections, daySelections, isEditMode } = useContext(ActivityContext);
  const {
    reservationDetails,
    isLoadingAvailability,
  } = useDataContext();
  
  const [isNotSelecting, setIsNotSelecting] = useState(!when.isEdited && when.date !== "");
  const [showXSign, setShowXSign] = useState(isEditMode && when.isEdited && when.action !== "remove");

  const {
    hasDateSelected,
    handleSeparateSelection,
    removeSeparateSelection,
    updateStatus,
    updateGuestCount,
  } = DaysRowSeparateSelectionLogic();
  
  const isValidDate = when.bookedDate !== "";
  const isPastDate = isValidDate && when.bookedDate < getCurrentDate();
  const isPastTimeToday =
    isValidDate &&
    formatTime(new Date().toString()) > when.bookedTime.slice(0, 5) &&
    getCurrentDate() === when.bookedDate;

  useEffect(() => {
    setIsNotSelecting(!when.isEdited && when.date !== "");
    setShowXSign(isEditMode && when.isEdited && when.action !== "remove");
  }, [activitySelections]);

  const dateSelRow = (
    <>
      <IonCol
        className={`bl-flex ion-align-items-center
            ${"ion-justify-content-between"}`}
        style={{
          background:
            isDesktop() && isNotSelecting
              ? "var(--ion-color-light)"
              : hasDateSelected(selIndex, dateIndex) && !isDesktop() && "white",
          height: isNotSelecting && "48px",
          borderRadius: isDesktop() && "5px",
          width: isDesktop() ? "270px" : "100%",
          maxWidth: isDesktop() ? "270px" : "400px",
        }}
      >
        {isNotSelecting ? (
          <BLDateTimeFormatParagraph
            date={activitySelections[selIndex].on[dateIndex].date}
            view={MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY}
          />
        ) : (
          <BLSelect
            placeholder="Select a day"
            dropdownList={prepareDaysDropdownList(
              when.date,
              activitySelections,
              selIndex,
              daySelections
            ).filter((el) => el.id >= getCurrentDate())}
            value={when.date}
            disable={when.action === "remove"}
            onChange={(v) => handleSeparateSelection(selIndex, dateIndex, v)}
            onBlur={(v) => {
              if (when.date === v.target.value) {
                const selectionsCopy = JSON.parse(JSON.stringify(activitySelections));
                selectionsCopy[selIndex].on[dateIndex].isEdited = false;
                setActivitySelections(selectionsCopy);
              }
            }}
          />
        )}

        {!hasDateSelected(selIndex, dateIndex) ||
        activitySelections[selIndex].on[dateIndex].isEdited 
          ? null 
          : showXSign
            ? <BLIcon
              iconSource={closeCircle}
              color={"secondary"}
              onClick={() => removeSeparateSelection(selIndex, dateIndex)}
            /> 
            : <>
              {!isLoadingAvailability &&
              !isPastDate &&
              !isPastTimeToday && (
                <BLIcon
                  iconSource={pencil}
                  color={"secondary"}
                  onClick={() => updateStatus(selIndex, dateIndex)}
                  newClasses="bl-padding-r-xs"
                />
              )}
            </>
        }
      </IonCol>
      {!isNotSelecting && activitySelections[selIndex].on.length > 0 && (
        <IonCol
          size="auto"
          className="ion-justify-content-end ion-align-items-center bl-flex"
          style={{
            background: activitySelections[selIndex].on[dateIndex].date
              ? "#fff"
              : "",
          }}
        >
          <BLIcon
            newClasses="bl-padding-l-xs bl-padding-r-xs"
            iconSource={closeCircle}
            color={"secondary"}
            onClick={() => removeSeparateSelection(selIndex, dateIndex)}
          />
        </IonCol>
      )}
    </>
  );

  const lineRow = (
    <IonRow
      style={{
        height: "1px",
        margin: "0 10px",
        background: "var(--ion-color-primary)",
        maxWidth: "380px",
      }}
    />
  );

  const guestNumSelRowMobile = (
    <IonRow style={{ maxWidth: "400px" }}>
      <IonCol>
        <BLContentCardWrapper
          background={"white"}
          dimensions={{ padding: ["xs", "l-m", "r-m"] }}
          newStyles={{
            display: "flex",
            flexDirection: "column",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            paddingLeft: 16,
          }}
        >
          <BLParagraph
            txt={"Guests"}
            color={"black"}
            textVariant={typography.h4}
            //newClasses="small bold"
            //TODO: Unclear typography
            dimensions={{
              margin: [],
              padding: [],
            }}
          />
          <BLNumberInput
            plusFunc={() => updateGuestCount("plus", selIndex, dateIndex)}
            minFunc={() => updateGuestCount("minus", selIndex, dateIndex)}
            value={activitySelections[selIndex]?.on[dateIndex].quantity}
            min={1}
            max={reservationDetails.Adults}
            numberFieldWidth={"35px"}
            disableBtns={
              isLoadingAvailability ||
              when.action === "remove" ||
              isPastDate ||
              isPastTimeToday
            }
          />
        </BLContentCardWrapper>
      </IonCol>
    </IonRow>
  );

  const guestNumSelRowDesktop = (
    <IonCol>
      <BLContentCardWrapper
        background={"white"}
        dimensions={{ padding: ["xs", "l-m", "r-m"] }}
        newStyles={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <BLParagraph
          txt={"Guests"}
          color={"black"}
          textVariant={typography.h4}
          //newClasses="small bold"
          //TODO: Unclear typography
          dimensions={{
            margin: ["r-s"],
            padding: [],
          }}
        />
        <BLNumberInput
          plusFunc={() => updateGuestCount("plus", selIndex, dateIndex)}
          minFunc={() => updateGuestCount("minus", selIndex, dateIndex)}
          value={activitySelections[selIndex]?.on[dateIndex].quantity}
          min={1}
          max={reservationDetails.Adults}
          numberFieldWidth={"35px"}
          disableBtns={
            isLoadingAvailability ||
            when.action === "remove" ||
            isPastDate ||
            isPastTimeToday
          }
        />
      </BLContentCardWrapper>
    </IonCol>
  );

  return (
    <IonRow>
      <IonCol>
        {isDesktop() ? (
          <IonGrid>
            <IonRow
              className={"ion-align-items-center ion-justify-content-start"}
            >
              {dateSelRow}

              {activitySelections[selIndex]?.on[dateIndex].date !== "" &&
                guestNumSelRowDesktop}
            </IonRow>
          </IonGrid>
        ) : (
          <IonGrid>
            <IonRow>{dateSelRow}</IonRow>
            {activitySelections[selIndex]?.on[dateIndex].date !== "" && (
              <>
                {isNotSelecting && lineRow}
                {guestNumSelRowMobile}
              </>
            )}
          </IonGrid>
        )}
      </IonCol>
    </IonRow>
  );
};

export default DaysRowSeparateSelection;
