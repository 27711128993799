import sub from "date-fns/sub";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  formatDateDetailed,
  getDatesBetween,
  getDayOfStay,
  isDateWithinRange,
} from "../helpingFunctions/dateTimeUtilities";
import RequestHook from "../hooks/RequestHook";
import axiosInstance from "../url/createAxios";
import { useDataContext } from "./DataContext";
import { constructInfoBoxPriceMsg } from "../helpingFunctions/InfoBoxPriceMsg";
import { SERVICES_TYPES } from "../constants/constants";
import { useTheme } from "../hooks/useTheme";
export const TreatsContext = createContext({});

export const TreatsProvider = ({ children }) => {
  const { typography } = useTheme();
  const instance = axiosInstance();
  const { getItinerary } = RequestHook();
  const { reservationDetails, itineraryDetails, facilitiesData } =
    useDataContext();
  const [confirmationScreenSelections, setConfirmationScreenSelections] =
    useState(null);
  const [isProcessingBookings, setIsProcessingBookings] = useState(false);
  const [daySelections, setDaySelections] = useState(null);
  const [priceMsg, setPriceMsg] = useState(null);
  const [treatsSelections, setTreatsSelections] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [treatsDaysStatuses, setTreatsDaysStatuses] = useState([]);
  const [areSelectionsCompleted, setAreSelectionsCompleted] = useState(false);
  const [areAnyChanges, setAreAnyChanges] = useState(false);
  const [hasFailedTreatsBookings, setHasFailedTreatsBookings] = useState(false);
  const [treatsDetails, setTreatsDetails] = useState(null);
  const [viewAllTreats, setViewAllTreats] = useState(false);
  const [treatsByCurrentView, setTreatsByCurrentView] = useState({});
  const [diningBookings, setDiningBookings] = useState({
    Dinner: {},
  });
  const [isEditMode, setIsEditMode] = useState(false);

  const extractDaySelections = () => {
    const stayingDates = getDatesBetween(
      reservationDetails?.ArrivalDate,
      reservationDetails?.DepartureDate
    );

    const daySels = [];
    stayingDates.forEach((date) => {
      daySels.push({
        Title: formatDateDetailed(stayingDates, date, true),
        fullTitle: formatDateDetailed(stayingDates, date),
        dayOfStay: getDayOfStay(stayingDates, date),
        view: "Activity",
        id: date,
      });
    });

    setDaySelections(daySels);
  };

  useEffect(() => {
    if (reservationDetails) extractDaySelections();
  }, [reservationDetails]);

  useEffect(() => {
    validateSelections();
  }, [treatsSelections]);

  useEffect(() => {
    if (itineraryDetails) {
      prepDiningBookings();
    }
  }, [itineraryDetails]);

  useEffect(() => {
    if (facilitiesData) {
      setTreatsDetails(facilitiesData.Treats);
    }
  }, [facilitiesData]);

  const prepareLocationsDropdown = (locationSelections, selectionDate) => {
    const dropdownList = [];
    const arrivalDate = reservationDetails.ArrivalDate.substring(0, 10);
    const departureDate = reservationDetails.DepartureDate.substring(0, 10);

    const hasInDinnerBooked = locationSelections.find(
      (row) => row.bookedLocation === SERVICES_TYPES.DINNER
    );

    if (selectionDate === arrivalDate) {
      const today = new Date();
      const twoDaysBeforeArr = sub(new Date(reservationDetails.ArrivalDate), {
        years: 0,
        months: 0,
        weeks: 0,
        days: 2,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      dropdownList.push({
        id: selectedService.ServiceCode.toLowerCase(),
        Title: "In-room",
        disableOption: today >= twoDaysBeforeArr,
      });
    }

    if (
      selectionDate !== departureDate &&
      !hasInDinnerBooked &&
      selectedService.DinnerServiceCode
    ) {
      dropdownList.push({
        id: selectedService.DinnerServiceCode.toLowerCase(),
        Title: "Dinner at restaurant",
      });
    }

    return dropdownList;
  };

  const getLocationLabel = (serviceCode) => {
    if (!serviceCode) return "";

    if (serviceCode.toLowerCase().startsWith("d")) {
      return "Dinner at restaurant";
    } else {
      return "In-Room";
    }
  };

  const prepDiningBookings = () => {
    const prepObj = { Dinner: {} };
    Object.keys(itineraryDetails.Dates).forEach((date) => {
      prepObj.Dinner[date] =
        itineraryDetails.Dates[date].Dinner.length === 0
          ? null
          : itineraryDetails.Dates[date].Dinner[0];
    });
    setDiningBookings(prepObj);
  };

  const validateSelections = () => {
    let selectionsOkay = treatsSelections.length;
    let selectionsDaysOkay = 0;
    let totalSelectionsLocations = 0;
    let totalSelectionsLocationsOkay = 0;
    let totalToRemove = 0;
    let totalToAdd = 0;
    let totalChanges = 0;

    const isPriceValid =
      !isNaN(selectedService?.Price) && selectedService?.Price !== "0.00";

    treatsSelections.forEach((selection) => {
      totalSelectionsLocations += selection.options.length;

      if (selection.date !== "" && selection.date) {
        selectionsDaysOkay++;
      }

      if (
        selection.action !== "none" &&
        selection.date !== selection.bookedDate
      ) {
        totalChanges++;
      }

      selection.options.forEach((opt) => {
        let hasValidLocSel = opt.serviceCode && opt.serviceCode !== "";

        if ([SERVICES_TYPES.DINNER].includes(opt.location)) {
          hasValidLocSel =
            opt.serviceCode &&
            opt.serviceCode !== "" &&
            diningBookings[opt.location][selection.date];
        }

        if (hasValidLocSel) {
          totalSelectionsLocationsOkay++;
        }

        if (hasValidLocSel && opt.action === "add") {
          if (isPriceValid) {
            totalToAdd += opt.quantity
              ? opt.quantity * Number(selectedService.Price)
              : Number(selectedService.Price);
          }
        } else if (opt.action === "remove") {
          if (isPriceValid) {
            totalToRemove += opt.bookedQuantity
              ? opt.bookedQuantity * Number(selectedService.Price)
              : Number(selectedService.Price);
          }
        } else if (hasValidLocSel && opt.action === "edit") {
          if (isPriceValid) {
            if (opt.quantity > opt.bookedQuantity) {
              totalToAdd +=
                (opt.quantity - opt.bookedQuantity) *
                Number(selectedService.Price);
            } else if (opt.quantity < opt.bookedQuantity) {
              totalToRemove +=
                (opt.bookedQuantity - opt.quantity) *
                Number(selectedService.Price);
            }
          }
        }

        if (opt.action !== "none" && hasValidLocSel) {
          totalChanges++;
        }
      });
    });

    if ((totalToAdd > 0 || totalToRemove > 0) && isPriceValid) {
      constructInfoBoxPriceMsg(totalToAdd, totalToRemove, setPriceMsg, typography);
    } else {
      setPriceMsg(null);
    }

    const areSelsCompleted =
      selectionsOkay === selectionsDaysOkay &&
      totalSelectionsLocationsOkay === totalSelectionsLocations;
    setAreSelectionsCompleted(areSelsCompleted);

    setAreAnyChanges(totalChanges > 0 && areSelsCompleted);
  };

  const handleTreatsBookingRQ = async (action) => {
    setIsProcessingBookings(true);
    setHasFailedTreatsBookings(false);

    try {
      const rqItems = contructRQItems(action);

      const rsItems = processRS(rqItems);
      setConfirmationScreenSelections(rsItems);

      const response = await instance.post(
        `/Hotel/Booking/Facility/Availability/Treats`,
        { request: rqItems },
        { withCredentials: true }
      );
      const correlationID = response.data;

      if (correlationID) {
        let taskStatus = "processing";
        let result = {};

        while (
          !["success", "failed", "error", "warning"].includes(
            taskStatus.toLowerCase()
          )
        ) {
          result = await instance.post(
            `/Hotel/Booking/TreatsStatus`,
            {
              request: rqItems,
              correlationID,
            },
            { withCredentials: true }
          );

          taskStatus = result.data.overallStatus;
          delay(5000);
        }

        const rsItems = processRS(result.data.bookings);
        setConfirmationScreenSelections(rsItems);
      } else {
        rqItems.map((row) => {
          return { ...row, Status: "failed" };
        });

        const rsItems = processRS(rqItems);
        setConfirmationScreenSelections(rsItems);
      }

      await getItinerary();
      setIsProcessingBookings(false);
    } catch (e) {
      console.error(`Failed to process treats bookings. Error: ${e.message}`);
      setIsProcessingBookings(false);
    }
  };

  const contructRQItems = (action) => {
    const preparedRQItems = [];

    const selectionsSource =
      action === "retry" ? confirmationScreenSelections : treatsSelections;
    const now = new Date().toISOString().split("T");

    selectionsSource.forEach((selection) => {
      let constructedObj = {};
      constructedObj.Price = selectedService.Price;

      selection.options.forEach((whenSel) => {
        if (
          (action === "retry" && whenSel.Status !== "success") ||
          action !== "retry"
        ) {
          if (whenSel.action === "edit") {
            const cxlObj = { ...constructedObj };
            cxlObj.action = "remove";
            cxlObj.Quantity = whenSel.bookedQuantity;
            cxlObj.ServiceCode = whenSel.bookedServiceCode;
            cxlObj.Date = selection.bookedDate;
            cxlObj.Comment = constructComment(
              false,
              selection,
              whenSel,
              now,
              true
            );

            const bookObj = { ...constructedObj };
            bookObj.action = "add";
            bookObj.Quantity = whenSel.quantity;
            bookObj.ServiceCode = whenSel.serviceCode;
            bookObj.Date = selection.date;
            bookObj.Comment = constructComment(true, selection, whenSel, now);

            preparedRQItems.push(cxlObj);
            preparedRQItems.push(bookObj);
          } else if (whenSel.action === "remove") {
            const cxlObj = { ...constructedObj };
            cxlObj.action = whenSel.action;
            cxlObj.Quantity = whenSel.bookedQuantity;
            cxlObj.ServiceCode = whenSel.bookedServiceCode;
            cxlObj.Date = selection.bookedDate;
            cxlObj.Comment = constructComment(false, selection, whenSel, now);

            preparedRQItems.push(cxlObj);
          } else {
            const bookObj = { ...constructedObj };
            bookObj.action = whenSel.action;
            bookObj.Quantity = whenSel.quantity;
            bookObj.ServiceCode = whenSel.serviceCode;
            bookObj.Date = selection.date;
            bookObj.Comment = constructComment(true, selection, whenSel, now);

            preparedRQItems.push(bookObj);
          }
        }
      });
    });

    const treatBookings = extractAllOtherTreatBookings();
    treatBookings.forEach((book) => {
      if (
        book.Id !== selectedService.id ||
        (book.Id === selectedService.id && action === "retry")
      ) {
        preparedRQItems.push({
          Date: book.Date,
          Price: book.UnitPrice,
          action: "none",
          Quantity: book.Quantity,
          ServiceCode: book.ServiceCode,
          Comment: book.Comment,
        });
      }
    });

    return preparedRQItems;
  };

  const processRS = (responseData) => {
    const treatsObjs = [];
    setHasFailedTreatsBookings(
      responseData.find((row) => !["no-change", "success"].includes(row.Status))
    );

    responseData.forEach((sel) => {
      if (sel.action !== "none") {
        const isExistingIndex = treatsObjs.findIndex(
          (row) => row.date === sel.Date
        );

        const location = getLocationLabel(sel.ServiceCode);
        if (isExistingIndex > -1) {
          treatsObjs[isExistingIndex].options.push({
            action: sel.action,
            location: location,
            bookedLocation: location,
            serviceCode: sel.ServiceCode,
            bookedServiceCode: sel.ServiceCode,
            quantity: sel.Quantity,
            bookedQuantity: sel.Quantity,
            Status: sel.Status,
          });
        } else {
          treatsObjs.push({
            fName: reservationDetails.FirstName,
            lName: reservationDetails.LastName,
            guestName: `${reservationDetails.FirstName} ${reservationDetails.LastName}`,
            date: sel.Date,
            bookedDate: sel.Date,
            options: [
              {
                action: sel.action,
                location: location,
                bookedLocation: location,
                serviceCode: sel.ServiceCode,
                bookedServiceCode: sel.ServiceCode,
                quantity: sel.Quantity,
                bookedQuantity: sel.Quantity,
                Status: sel.Status,
              },
            ],
            action: sel.action,
            isEdited: false,
          });
        }
      }
    });

    return treatsObjs;
  };

  const constructComment = (
    isBookSource,
    selection,
    whenSel,
    now,
    useBookedLocation
  ) => {
    const location = useBookedLocation
      ? whenSel.bookedLocation
      : whenSel.location;
    const date = useBookedLocation ? selection.bookedDate : selection.date;
    const quantity = useBookedLocation
      ? whenSel.bookedQuantity
      : whenSel.quantity;

    return `^Add-on request for ${quantity}x ${
      selectedService.Title
    } on ${date} ${
      [SERVICES_TYPES.DINNER].includes(location) && diningBookings[location][date]
        ? `- ${diningBookings[location][date].Time.substring(0, 5)} `
        : ""
    } at ${location}. Last change done on ${now[0]} - ${now[1].substring(
      0,
      5
    )}. Last status: ${isBookSource ? "Booked" : "Cancelled"}$`;
  };

  const extractAllOtherTreatBookings = () => {
    let treatBooks = [];
    for (const vals of Object.values(itineraryDetails.Dates)) {
      treatBooks = [...treatBooks, ...vals.Treats];
    }
    return treatBooks;
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const cancelChangesHandler = () => {
    let selectionsCopy = JSON.parse(JSON.stringify(treatsSelections));

    selectionsCopy = selectionsCopy.filter((row) => row.action !== "add");

    selectionsCopy.forEach((sel) => {
      sel.options = sel.options.filter((opt) => opt.action !== "add");

      sel.date = sel.bookedDate;
      sel.isEdited = false;
      sel.action = "none";

      sel.options.forEach((opt) => {
        opt.location = opt.bookedLocation;
        opt.serviceCode = opt.bookedServiceCode;
        opt.quantity = opt.bookedQuantity;
        opt.isEdited = false;
        opt.action = "none";
      });
    });

    setTreatsSelections(selectionsCopy);
    setIsEditMode(false);
  };

  const hasInRoomBookWithIn48hr = (sel) => {
    return (
      isDateWithinRange(reservationDetails.ArrivalDate.substring(0, 10), 2) &&
      reservationDetails.ArrivalDate.substring(0, 10) === sel.date &&
      sel.options.find((opt) => opt.bookedLocation === "In-Room")
    );
  };

  const checkIfSelectedTreatBookingsAreLeft = () => {
    const stayingDates = getDatesBetween(
      reservationDetails.ArrivalDate,
      reservationDetails.DepartureDate
    );

    let itinerary = [];
    stayingDates.forEach((date) => {
      itinerary = [...itinerary, ...itineraryDetails.Dates[date].Treats];
    });

    const selectedServiceBookings = itinerary.filter(
      (row) => row.Id === selectedService.id
    );

    return selectedServiceBookings.length > 0;
  };

  return (
    <TreatsContext.Provider
      value={{
        selectedService,
        treatsSelections,
        treatsDaysStatuses,
        areAnyChanges,
        areSelectionsCompleted,
        diningBookings,
        hasFailedTreatsBookings,
        treatsDetails,
        setTreatsDetails,
        checkIfSelectedTreatBookingsAreLeft,
        setDiningBookings,
        setAreSelectionsCompleted,
        setAreAnyChanges,
        setTreatsDaysStatuses,
        setTreatsSelections,
        setSelectedService,
        prepareLocationsDropdown,
        getLocationLabel,
        handleTreatsBookingRQ,
        cancelChangesHandler,
        hasInRoomBookWithIn48hr,
        confirmationScreenSelections,
        setConfirmationScreenSelections,
        isProcessingBookings,
        setIsProcessingBookings,
        daySelections,
        setDaySelections,
        priceMsg,
        setPriceMsg,
        isEditMode,
        setIsEditMode,
        viewAllTreats,
        setViewAllTreats,
        treatsByCurrentView,
        setTreatsByCurrentView
      }}
    >
      {children}
    </TreatsContext.Provider>
  );
};
export const useTreatsContext = () => {
  const {
    selectedService,
    treatsSelections,
    treatsDaysStatuses,
    areAnyChanges,
    areSelectionsCompleted,
    diningBookings,
    hasFailedTreatsBookings,
    treatsDetails,
    setTreatsDetails,
    checkIfSelectedTreatBookingsAreLeft,
    setDiningBookings,
    setAreSelectionsCompleted,
    setAreAnyChanges,
    setTreatsDaysStatuses,
    setTreatsSelections,
    setSelectedService,
    prepareLocationsDropdown,
    getLocationLabel,
    handleTreatsBookingRQ,
    cancelChangesHandler,
    hasInRoomBookWithIn48hr,
    confirmationScreenSelections,
    setConfirmationScreenSelections,
    isProcessingBookings,
    setIsProcessingBookings,
    daySelections,
    setDaySelections,
    priceMsg,
    setPriceMsg,
    isEditMode,
    setIsEditMode,
    viewAllTreats,
    setViewAllTreats,
    treatsByCurrentView,
    setTreatsByCurrentView
  } = useContext(TreatsContext);
  return {
    selectedService,
    treatsSelections,
    treatsDaysStatuses,
    areAnyChanges,
    areSelectionsCompleted,
    diningBookings,
    hasFailedTreatsBookings,
    treatsDetails,
    setTreatsDetails,
    checkIfSelectedTreatBookingsAreLeft,
    setDiningBookings,
    setAreSelectionsCompleted,
    setAreAnyChanges,
    setTreatsDaysStatuses,
    setTreatsSelections,
    setSelectedService,
    prepareLocationsDropdown,
    getLocationLabel,
    handleTreatsBookingRQ,
    cancelChangesHandler,
    hasInRoomBookWithIn48hr,
    confirmationScreenSelections,
    setConfirmationScreenSelections,
    isProcessingBookings,
    setIsProcessingBookings,
    daySelections,
    setDaySelections,
    priceMsg,
    setPriceMsg,
    isEditMode,
    setIsEditMode,
    viewAllTreats,
    setViewAllTreats,
    treatsByCurrentView,
    setTreatsByCurrentView
  };
};
