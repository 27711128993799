import {
  generateConflictReplacementObj,
  replacePlaceholders,
} from "../../../helpingFunctions/replacePlaceholders";
import { CONFLICT_TYPES } from "../conflictConstants";
import { SERVICES_TYPES } from "../../../constants/constants";

/** 
    Method for handling distribution of data for extracting proper conflict message based on the conflict type 
  */
const retrieveConflictAlertMessage = (
  confirmedObj,
  selectionObj,
  conflictType,
  reservationDetails,
  canConflcitsBeResolved,
  exceededQuantity,
  conflictManagementTexts,
  configObj
) => {
  // TODO check after full implementation
  const placeHolders = generateConflictReplacementObj(
    confirmedObj,
    selectionObj,
    confirmedObj?.cancellationPolicyMessage,
    { resAdults: reservationDetails.Adults, chainedExceededQty: exceededQuantity },
    configObj
  );

  switch (conflictType) {
    case CONFLICT_TYPES.SOFT:
      return getSoftConflictMsg(
        confirmedObj,
        configObj.isInItinerary
          ? conflictManagementTexts?.Itinerary
          : conflictManagementTexts?.Shared,
        placeHolders
      );
    case CONFLICT_TYPES.AVERAGE:
      return getAverageConflictMsg(
        confirmedObj,
        selectionObj,
        configObj.isInItinerary
          ? conflictManagementTexts?.Itinerary
          : conflictManagementTexts?.Shared,
        placeHolders
      );
    case CONFLICT_TYPES.STRONG:
      return getStrongConflictMsg(
        confirmedObj,
        configObj.isInItinerary
          ? conflictManagementTexts?.Itinerary
          : conflictManagementTexts?.Shared,
        placeHolders
      );
    case CONFLICT_TYPES.CHAINED:
      return getChainedConflictMsg(
        configObj.isInItinerary
          ? conflictManagementTexts?.Itinerary
          : conflictManagementTexts?.Shared,
        placeHolders,
        canConflcitsBeResolved,
        configObj.hasMatchingNameSpaSel
      );

    default:
      break;
  }
};

/** 
    Method for extracting conflict message based on a SOFT conflict type 
  */
const getSoftConflictMsg = (
  confirmedObj,
  conflictManagementTexts,
  placeHolders
) => {
  if (
    ![
      SERVICES_TYPES.BREAKFAST,
      SERVICES_TYPES.DINNER,
      SERVICES_TYPES.ENTERTAINMENT,
    ].includes(confirmedObj.Type) &&
    confirmedObj.Duration > 0
  ) {
    return replacePlaceholders(
      conflictManagementTexts?.Warnings?.Popup_Regular_WithDuration,
      placeHolders
    );
  } else {
    return replacePlaceholders(
      conflictManagementTexts?.Warnings?.Popup_Regular,
      placeHolders
    );
  }
};

/** 
    Method for extracting conflict message based on a AVERAGE conflict type 
  */
const getAverageConflictMsg = (
  confirmedObj,
  selectionObj,
  conflictManagementTexts,
  placeHolders
) => {
  let hasPrice = false;
  let hasDuration = false;

  if (
    ![
      SERVICES_TYPES.BREAKFAST,
      SERVICES_TYPES.DINNER,
      SERVICES_TYPES.ENTERTAINMENT,
    ].includes(confirmedObj.Type) &&
    confirmedObj.Duration > 0
  ) {
    hasDuration = true;
  }

  if (
    selectionObj.singleUnitPrice &&
    Number(selectionObj.singleUnitPrice) > 0
  ) {
    hasPrice = true;
  }

  if (hasDuration && hasPrice) {
    return replacePlaceholders(
      conflictManagementTexts?.Replacements
        ?.Popup_Regular_WithDurationAndPrice,
      placeHolders
    );
  }

  if (hasDuration) {
    return replacePlaceholders(
      conflictManagementTexts?.Replacements?.Popup_Regular_WithDuration,
      placeHolders
    );
  }

  if (hasPrice) {
    return replacePlaceholders(
      conflictManagementTexts?.Replacements?.Popup_Regular_WithPrice,
      placeHolders
    );
  }

  return replacePlaceholders(
    conflictManagementTexts?.Replacements?.Popup_Regular,
    placeHolders
  );
};

const getStrongConflictMsg = (
  confirmedObj,
  conflictManagementTexts,
  placeHolders
) => {
  if (confirmedObj.cancellationPolicyMessage) {
    if (confirmedObj.Duration > 0) {
      return replacePlaceholders(
        conflictManagementTexts?.Forbids
          ?.Popup_Regular_WithDurationAndWithinCancellationPolicyWindow,
        placeHolders
      );
    } else {
      return replacePlaceholders(
        conflictManagementTexts?.Forbids
          ?.Popup_Regular_WithinCancellationPolicyWindow,
        placeHolders
      );
    }
  }

  if (confirmedObj.Duration > 0) {
    return replacePlaceholders(
      conflictManagementTexts?.Forbids?.Popup_Regular_WithDuration,
      placeHolders
    );
  } else {
    return replacePlaceholders(
      conflictManagementTexts?.Forbids?.Popup_Regular,
      placeHolders
    );
  }
};

const getChainedConflictMsg = (
  conflictManagementTexts,
  placeHolders,
  canConflcitsBeResolved,
  hasMatchingNameSpaSel
) => {
  if (canConflcitsBeResolved) {
    if (hasMatchingNameSpaSel) {
      return replacePlaceholders(
        conflictManagementTexts?.Special
          ?.Popup_Chained_ReplaceSpaSelectionOverlapsSpaBookingSameName,
        placeHolders
      );
    } else {
      return replacePlaceholders(
        conflictManagementTexts?.Special?.Popup_Chained_WithOptToReplace,
        placeHolders
      );
    }
  } else {
    if (hasMatchingNameSpaSel) {
      return replacePlaceholders(
        conflictManagementTexts?.Special?.Popup_Chained_ForbidSpaSelectionOverlapsSpaBookingSameName,
        placeHolders
      );
    } else {
      return replacePlaceholders(
        conflictManagementTexts?.Special?.Popup_Chained_WithoutOptToReplace,
        placeHolders
      );
    }
  }
};

export { retrieveConflictAlertMessage };
