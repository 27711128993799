import moment from "moment";
import { pickBy } from "lodash";
import levenshtein from "fast-levenshtein";

const validateForm = (originalObject) =>
  pickBy(originalObject, (v) => v === undefined || v === "");

const checkForMatch = (storedTxt, inputTxt, maxDiff, minDiff) => {
  return (
    levenshtein.get(storedTxt.toLowerCase(), inputTxt.toLowerCase()) <=
      maxDiff &&
    levenshtein.get(storedTxt.toLowerCase(), inputTxt.toLowerCase()) >= minDiff
  );
};

const inBetween = (compareValue, startValue, endValue) =>
  startValue <= compareValue && endValue >= compareValue;

const handleNullDisplay = (compareVal, valueAdditionalTxt, secondText) => {
  try {
    return compareVal ? `${valueAdditionalTxt}${compareVal}` : `${secondText}`;
  } catch (error) {
    console.error(
      `${new Date()} Error in handleNullDisplay func inside helpingFunctions.js file ${
        error.message
      }`
    );
  }
};

const checkCurrentTime = (arrivalDate) => {
  const startTime = moment(new Date(arrivalDate));
  const endTime = moment(new Date());

  const hourInterval = startTime.diff(endTime, "hours");

  return hourInterval <= 0;
};

const getHashCode = (string) => {
  var hash = 0;
  for (var i = 0; i < string.length; i++) {
    var code = string.charCodeAt(i);
    hash = (hash << 5) - hash + code;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

const generateKey = (obj) => {
  const hashCode = getHashCode(JSON.stringify(obj));
  return Math.abs(hashCode); // We need Math.abs as hashCode could be negative value
};

const expandCollapse = (row, clickedRow, setClickedRow) => {
  if (clickedRow === row) {
    setClickedRow(null);
  } else {
    setClickedRow(row);
  }
};

export {
  validateForm,
  checkForMatch,
  inBetween,
  handleNullDisplay,
  checkCurrentTime,
  generateKey,
  expandCollapse
};
