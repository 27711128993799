import React from "react";

import BLButton from "../../Buttons/BLButton";
import BLParagraph from "../../Paragraph/BLParagraph";
import { IonGrid, IonModal, IonRow, IonCol } from "@ionic/react";
import styles from "./BLAlertPopUp.module.scss";
import BLIcon from "../../BLIcon";
import { xSign } from "../../../../assets/SVGIcons";
import { useTheme } from "../../../../hooks/useTheme";

const BLAlertPopUp = ({
  title,
  description,
  children,
  isOpen,
  confirmHandler,
  dismissHandler,
  confirmBtnLabel,
  dismissBtnLabel,
  confirmBtnType,
  dismissBtnType,
  confirmBtnClasses,
  dismissBtnClasses,
  backdropEffect = true,
  confirmBtnDisabled = false,
  dismissBtnDisabled = false,
  enableBackdropDismiss = false,
  enableHeaderLevelCloseBtn,
  question,
}) => {
  const { typography } = useTheme();
  return (
    <IonModal
      className={`${styles.BLAlertPopUp} ion-disable-focus-trap `}
      isOpen={isOpen}
      backdropDismiss={enableBackdropDismiss}
      showBackdrop={backdropEffect}
      onWillDismiss={() => {
        if (enableBackdropDismiss) {
          dismissHandler();
        }
      }}
    >
      <IonGrid
        className={enableHeaderLevelCloseBtn ? "" : "bl-padding-xs"}
      >
        {title && (
          <IonRow
            className={`${
              enableHeaderLevelCloseBtn && "ion-justify-content-between"
            } bl-padding-b-s`}
          >
            <IonCol>
              <BLParagraph
                txt={title}
                color="black"
                textVariant={typography.h3}
                dimensions={{ padding: ["b-s"] }}
                markDown={!!title}
              />
            </IonCol>

            {enableHeaderLevelCloseBtn && (
              <IonCol size="auto">
                <BLIcon customIconSource={xSign()} onClick={dismissHandler} />
              </IonCol>
            )}
          </IonRow>
        )}

        {description && (
          <IonRow className="bl-padding-b-s">
            <IonCol>
              <BLParagraph
                txt={description}
                color="text-grey"
                textVariant={typography.BODY_TEXT}
                dimensions={{ padding: ["b-s"] }}
                markDown={!!description}
              />
            </IonCol>
          </IonRow>
        )}

        {question && (
          <IonRow className="bl-padding-b-s">
            <IonCol>
              <BLParagraph
                txt={question}
                color="text-grey"
                textVariant={typography.BODY_TEXT}
                // newClasses={`x-small bold`}
                //TODO: Unclear typography
                dimensions={{ padding: ["b-s"] }}
              />
            </IonCol>
          </IonRow>
        )}

        {children && (
          <IonRow>
            <IonCol>{children}</IonCol>
          </IonRow>
        )}

        <IonRow>
          <IonCol
            className="bl-flex ion-justify-content-center"
            style={{ gap: "16px" }}
          >
            {dismissBtnLabel && (
              <BLButton
                newClasses={dismissBtnClasses}
                content={dismissBtnLabel}
                type={dismissBtnType}
                dimensions={{
                  margin: ["t-s"],
                }}
                onClick={dismissHandler}
                disabled={dismissBtnDisabled}
              />
            )}

            {confirmBtnLabel && (
              <BLButton
                newClasses={confirmBtnClasses}
                content={confirmBtnLabel}
                type={confirmBtnType}
                dimensions={{
                  margin: ["t-s l-s"],
                }}
                onClick={confirmHandler}
                disabled={confirmBtnDisabled}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default BLAlertPopUp;
