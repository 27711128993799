import { IonCard, IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { useDataContext } from "../../../../context/DataContext";

import { NavigatorContext } from "../../../../context/NavigatorContext";
import { getCurrentDate } from "../../../../helpingFunctions/dateTimeUtilities";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import { PageLoaderContext } from "../../../../context/PageLoaderContext";
import { useTheme } from "../../../../hooks/useTheme";

const WelcomeModal = () => {
  const { typography } = useTheme();
  const { reservationDetails, isLoadingHotelData } = useDataContext();
  const { warnerServices } = useContext(NavigatorContext);
  const { isLoaderOpened, setIsLoaderOpened, setLoaderMessage } =
    useContext(PageLoaderContext);

  // This check (!isLoadingHotelData) is needed in order not to change loader
  // message when we add new reservation

  useEffect(() => {
    if (!reservationDetails || !warnerServices) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Home Page");
    } else if (!isLoadingHotelData) {
      setIsLoaderOpened(false);
    }
  }, [warnerServices, reservationDetails, isLoadingHotelData]);

  return (
    !isLoaderOpened && (
      <IonCard
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "40px 20px",
        }}
      >
        <BLParagraph
          newClasses="ion-margin-top ion-margin-bottom"
          color="text-grey"
          txt={`Welcome ${reservationDetails?.FirstName ?? ''},`}
          textVariant={typography.h1}
        />
        <BLParagraph
          newClasses="ion-margin-top"
          color="text-grey"
          txt={`Ref: ${reservationDetails?.CRSNumber ?? ''}`}
          textVariant={typography.h1}
        />
        {getCurrentDate() < reservationDetails?.ArrivalDate?.slice(0, 10) && (
          <IonGrid
            className="bl-margin-t-s"
            style={{
              background: "var(--ion-color-primary)",
              maxHeight: "150px",
              width: "400px",
              borderRadius: "5px",
              padding: "16px",
              border: " 1px solid var(--ion-color-warner-teal)",
              flex: 0,
            }}
          >
            <IonRow>
              <IonCol>
                <CountdownTimer />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonCard>
    )
  );
};

export default WelcomeModal;
