import { useEffect, useContext } from "react";
import { SpaContext } from "../../../../context/SpaContext";
import GuestsRow from "./BookingSection/GuestsRow/GuestsRow";
import DaysRow from "./BookingSection/DaysRow/DaysRow";
import TimesRow from "./BookingSection/TimesRow/TimesRow";
import { constructInfoBoxPriceMsg } from "../../../../helpingFunctions/InfoBoxPriceMsg";
import { typography } from "@mui/system";

const SpaExpandableRowsContentLogic = (serviceDetails, setAreAnyChanges, setAreSelectionsCompleted) => {
  const { spaSelections, setPriceMsg } = useContext(SpaContext);

  const { guestsRowExpanded, guestsRowCollapsed, guestsRowContent } =
    GuestsRow(serviceDetails);
  const { daysRowCollapsed, daysRowExpanded, daysRowContent } = DaysRow();
  const { timeRowCollapsed, timeRowExpanded, timeRowContent } = TimesRow(serviceDetails);

  const validateSelections = () => {
    let selectionsOkay = 0;
    let selectionsDaysOkay = 0;
    let totalSelections = spaSelections.length;
    let totalSelectionsDays = 0;
    let totalToRemove = 0;
    let totalToAdd = 0;
    let isFailure = false;
    let totalChanges = 0;

    for (const selection of spaSelections) {
      if (selection.fName.trim() !== "" && selection.lName.trim() !== "") {
        selectionsOkay++;
        totalSelectionsDays += selection.on.length;

        for (const day of selection.on) {
          const hasValidDayTimeSel = day.date !== "" && day.time !== "";

          if (hasValidDayTimeSel) {
            selectionsDaysOkay++;
          }

          if (hasValidDayTimeSel && day.action === "add") {
            totalToAdd += Number(serviceDetails.Price);
          } else if (day.action === "remove") {
            totalToRemove += Number(serviceDetails.Price);
          }

          if (
            (day.action !== "none" || selection.action !== "none") &&
            hasValidDayTimeSel
          ) {
            totalChanges++;
          }
        }
      } else {
        isFailure = true;
        setAreSelectionsCompleted(false);
        setAreAnyChanges(false);
        break;
      }
    }

    if (!isFailure) {
      const areSelsCompleted =
        selectionsOkay === totalSelections &&
        selectionsDaysOkay === totalSelectionsDays;

      setAreSelectionsCompleted(areSelsCompleted);

      setAreAnyChanges(totalChanges > 0 && areSelsCompleted);

      if (totalToAdd > 0 || totalToRemove > 0) {
        constructInfoBoxPriceMsg(totalToAdd, totalToRemove, setPriceMsg, typography);
      } else {
        setPriceMsg(null);
      }
    }
  };

  useEffect(() => {
    validateSelections();
  }, [spaSelections]);

  return {
    guestsRowExpanded,
    guestsRowCollapsed,
    guestsRowContent,
    daysRowCollapsed,
    daysRowExpanded,
    daysRowContent,
    timeRowCollapsed,
    timeRowExpanded,
    timeRowContent,
  };
};

export default SpaExpandableRowsContentLogic;
