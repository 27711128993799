import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";
import { useDataContext } from "../../../../../context/DataContext";
import { NavigatorContext } from "../../../../../context/NavigatorContext";
import { ROUTE_VIEWS } from "../../../../../routes/routeViews";
import CategoryCard from "../../../../../shared/Ionic/ServicesComponents/ServiceCards/CategoryCard";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import { isDesktop } from "../../../../../helpingFunctions/utilities";

const TreatYourselfCategories = ({ categories }) => {
  const { itineraryDetails } = useDataContext();
  const { redirectBetweenPages } = useContext(NavigatorContext);

  const hasBookedCategory = (categoryId, items) => {
    if (itineraryDetails) {
      for (const day of Object.values(itineraryDetails?.Dates)) {
        return day?.All.find(
          (row) =>
            row.categoryId === categoryId ||
            items.find((item) => item.Title === row.title)
        );
      }
    }
  };

  return (
    <BLContentCardWrapper
      width="100%"
      background="white"
      dimensions={{ margin: ["b-s"] }}
    >
      <IonGrid>
        <IonRow style={{ gap: "16px" }}>
          {categories?.map((category, index) => (
            <IonCol
              size={isDesktop() ? 3 : 12}
              key={index}
              style={
                isDesktop()
                  ? {
                    minWidth: "260px",
                    maxWidth: "260px",
                    minHeight: "170px",
                  }
                  : { minHeight: "150px" }
              }
              onClick={() => {
                redirectBetweenPages(
                  ROUTE_VIEWS.TREATS_CATEGORY.replace(
                    ":categoryId",
                    category.categoryId
                  ),
                  {
                    categoryId: category.categoryId,
                    description: category.description,
                  }
                );
              }}
            >
              <CategoryCard
                categoryImg={category.categoryPortraitImg}
                title={category.title}
                hasBooking={hasBookedCategory(
                  category.categoryId,
                  category.items
                )}
              />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </BLContentCardWrapper>
  );
};

export default TreatYourselfCategories;
