import { IonCol, IonGrid, IonRow } from "@ionic/react";
import {
  bicycle,
  calendar,
  checkmarkCircle,
  closeCircle,
  pencil,
  people,
  repeat,
} from "ionicons/icons";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { spaFilled } from "../../../assets/SVGIcons";
import { MAESTRO_BACKEND_NAMING_VIEWS, INITIAL_DATE } from "../../../constants/constants";
import { useDataContext } from "../../../context/DataContext";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { formatTime } from "../../../helpingFunctions/dateTimeUtilities";
import { generateKey } from "../../../helpingFunctions/helpingFunctions";
import BLIcon from "../BLIcon";
import BLLoadingContainer from "../BLLoadingContainer";
import BLButton from "../Buttons/BLButton";
import BLParagraph from "../Paragraph/BLParagraph";
import BLContentCardWrapper from "../Wrappers/BLContentCardWrapper";
import { isDesktop } from "../../../helpingFunctions/utilities";
import { useTheme } from "../../../hooks/useTheme";

const ConfirmationScreenLogic = (
  source,
  retryFailsFunc,
  confirmationScreenSelections,
  setConfirmationScreenSelections,
  isProcessingBookings,
  daySelections,
  setPriceMsg,
  setIsEditMode
) => {
  const { typography } = useTheme();
  const { facilitiesDataDetails } = useDataContext();
  const [areAllCancellations, setAreAllCancellations] = useState(false);
  const [areAllFailures, setAreAllFailures] = useState(false);
  const [hasFailures, setHasFailures] = useState(false);
  const [processedRSResults, setProcessedRSResults] = useState([]);
  const { redirectToItinerary } = useContext(NavigatorContext);

  const getSpaGuestNameRow = (sel, addLine) => {
    return (
      <IonRow key={`${generateKey(sel)}spaguestnamerow`}>
        <IonCol>
          {isDesktop() && (
            <BLIcon
              color="primary"
              iconSource={people}
              newClasses="bl-padding-r-s"
            />
          )}
          <BLParagraph
            txt={sel.guestName}
            color="text-grey"
            textVariant={typography.h4}
            typographyStyles={addLine ? { textDecoration: "line-through" } : {}}
          />
        </IonCol>
      </IonRow>
    );
  };

  const getActivityHeadRow = () => {
    return (
      <IonRow>
        <IonCol>
          <BLParagraph
            txt="Bookings"
            color="primary"
            textVariant={typography.h4}
          //newClasses="small semibold"
          //TODO: Unclear typography
          />
        </IonCol>
      </IonRow>
    );
  };

  const getDayTimeRow = (daySel) => {
    return (
      <IonRow
        className="bl-flex ion-align-items-start"
        style={{ flexWrap: "nowrap" }}
        key={`${generateKey(daySel)}daytimerow`}
      >
        {isDesktop() && (
          <BLIcon
            color="primary"
            iconSource={calendar}
            newClasses="bl-padding-r-s"
          />
        )}

        <IonCol>
          <IonRow className="bl-flex ion-justify-content-between">
            <IonCol size="auto">
              <BLParagraph
                txt={
                  daySelections?.find((row) => row.id === daySel.date)?.Title
                }
                color="text-grey"
                textVariant={typography.BODY_TEXT}
                typographyStyles={daySel.action === "remove" ? { textDecoration: "line-through" } : {}}
              />
              <BLParagraph
                txt={`at ${formatTime(
                  `${INITIAL_DATE}T${daySel.time.substring(0, 5)}`,
                  true
                )}`}
                dimensions={{ margin: ["r-s", "l-xs"] }}
                color="text-grey"
                textVariant={typography.BODY_TEXT}
                typographyStyles={daySel.action === "remove" ? { textDecoration: "line-through" } : {}}
              />
            </IonCol>
          </IonRow>
        </IonCol>

        {daySel.Status === "success" ? (
          <IonCol size="auto" className="bl-flex ion-justify-content-end">
            <BLIcon
              color="success"
              iconSource={checkmarkCircle}
              newClasses="bl-padding-r-xs"
            />
          </IonCol>
        ) : (
          <IonCol className="bl-flex ion-justify-content-end">
            <BLIcon
              color="danger"
              iconSource={closeCircle}
              newClasses="bl-padding-r-xs"
            />
          </IonCol>
        )}
      </IonRow>
    );
  };

  const getActivityQuantityRow = (daySel) => {
    return (
      <IonRow
        key={`${generateKey(daySel)}guest`}
        className={isDesktop() ? "" : "ion-justify-content-between"}
      >
        {isDesktop() && (
          <BLIcon
            color="primary"
            iconSource={people}
            newClasses="bl-padding-r-s"
          />
        )}
        <BLParagraph
          txt={`${daySel.quantity} guest${daySel.quantity === 1 ? "" : "s"}`}
          color="text-grey"
          textVariant={typography.h4}
          typographyStyles={daySel.action === "remove" ? { textDecoration: "line-through" } : {}}
        />
      </IonRow>
    );
  };

  const getSelectionReplacementRow = (hasForeignItems, sel) => {
    let selectionReplacement = null;
    if (hasForeignItems) {
      selectionReplacement = sel.on.filter(
        (row) => row.isReplacement || row.isEditReplacement
      );
    }

    let replacementSummary = null;
    if (selectionReplacement?.length > 0) {
      const data = [];
      selectionReplacement.forEach((replacementSel, index) => {
        const serviceTitleRow = (
          <IonRow key={`${generateKey(replacementSel)}servicetitle`}>
            <IonCol
              className={isDesktop() ? "bl-flex ion-align-items-center" : ""}
            >
              {isDesktop() && (
                <>
                  {replacementSel.type === MAESTRO_BACKEND_NAMING_VIEWS.SPA ? (
                    <BLIcon
                      customIconSource={spaFilled()}
                      newClasses="bl-padding-r-s"
                    />
                  ) : (
                    <BLIcon
                      color="primary"
                      iconSource={bicycle}
                      newClasses="bl-padding-r-s"
                    />
                  )}
                </>
              )}

              <BLParagraph
                txt={
                  facilitiesDataDetails?.find(
                    (row) =>
                      row.id === replacementSel.id &&
                      replacementSel.type === row.ObjType
                  )?.Title
                }
                color="text-grey"
                textVariant={typography.BODY_TEXT}
                typographyStyles={{ textDecoration: "line-through" }}
              />
            </IonCol>
          </IonRow>
        );

        const prepRow = (
          <Fragment key={`${generateKey(replacementSel)}preprow`}>
            {serviceTitleRow}

            {getDayTimeRow(replacementSel)}

            {replacementSel.type === MAESTRO_BACKEND_NAMING_VIEWS.SPA &&
              getSpaGuestNameRow(sel, true)}

            {replacementSel.type !== MAESTRO_BACKEND_NAMING_VIEWS.SPA &&
              getActivityQuantityRow(replacementSel)}
          </Fragment>
        );

        data.push(prepRow);

        if (
          selectionReplacement.length > 1 &&
          index !== selectionReplacement.length - 1
        ) {
          data.push(
            <IonRow
              key="2pxdachedline"
              style={{ borderBottom: "2px dashed gray" }}
            />
          );
        }
      });

      data.push(
        <IonRow
          key="3pxdachedline"
          style={{ borderBottom: "3px solid gray" }}
        />
      );

      replacementSummary = data;
    }

    return replacementSummary;
  };

  const prepareConfScreenActivityContent = () => {
    const content = [];
    let totalItemsRequested = 0;
    let totalRemovedItems = 0;
    let totalFailed = 0;

    confirmationScreenSelections.forEach((sel) => {
      if (isDesktop()) {
        content.push(getActivityHeadRow());
      }

      const hasForeignItems =
        sel.on.filter((row) => row.isReplacement || row.isEdited).length > 0;

      const cleanSelections = sel.on.filter(
        (row) => !row.isReplacement && !row.isEditReplacement
      );
      totalItemsRequested += sel.on.length;

      cleanSelections.forEach((daySel) => {
        let replacementSummary = null;

        // if (daySel.isEdited || daySel.isReplacement) {
        let items = sel.on.filter(
          (row) => row.replacedWith === `${daySel.date}T${daySel.time}`
        );
        replacementSummary = getSelectionReplacementRow(hasForeignItems, {
          guestName: sel.guestName,
          on: items,
        });
        // }

        if (daySel.action === "remove" && daySel.Status === "success") {
          totalRemovedItems++;
        }

        if (daySel.Status !== "success" && !hasFailures) {
          setHasFailures(true);
        }

        if (daySel.Status !== "success") {
          totalFailed++;
        }

        content.push(
          <BLContentCardWrapper
            background={isDesktop() ? "light" : "white"}
            dimensions={{ padding: ["t-s", "b-s", isDesktop() ? "s" : ""] }}
          >
            <IonGrid
              className={"bl-padding-l-xs"}
              style={{ gap: "8px", display: "grid" }}
            >
              {replacementSummary && replacementSummary}

              {getDayTimeRow(daySel)}

              {getActivityQuantityRow(daySel)}
            </IonGrid>
          </BLContentCardWrapper>
        );
      });
    });

    if (totalFailed === totalItemsRequested) {
      setAreAllFailures(true);
    }

    if (totalItemsRequested === totalRemovedItems) {
      setAreAllCancellations(true);
      setIsEditMode(false);
    } else {
      setProcessedRSResults(content);
    }
  };

  const prepareConfScreenSpaContent = () => {
    const content = [];
    let totalItemsRequested = 0;
    let totalRemovedItems = 0;
    let totalFailed = 0;

    confirmationScreenSelections.forEach((sel) => {
      const guestContent = [];

      const hasForeignItems =
        sel.on.filter((row) => row.isReplacement || row.isEdited).length > 0;

      const cleanSelections = sel.on.filter(
        (row) => !row.isReplacement && !row.isEditReplacement
      );
      totalItemsRequested += sel.on.length;

      // If there are only replacements - show them directly and as standalone rows
      if (cleanSelections.length === 0) {
        sel.on.forEach((daySel) => {
          if (daySel.action === "remove" && daySel.Status === "success") {
            totalRemovedItems++;
          }

          if (daySel.Status !== "success" && !hasFailures) {
            setHasFailures(true);
          }

          if (daySel.Status !== "success") {
            totalFailed++;
          }

          guestContent.push(
            <Fragment key={`${generateKey(daySel)}spaguestrowfrag`}>
              {getSpaGuestNameRow(sel)}
            </Fragment>
          );

          guestContent.push(
            <Fragment key={`${generateKey(daySel)}daytimefragcleanzero`}>
              {getDayTimeRow(daySel)}
            </Fragment>
          );
        });
      } else {
        // else, combine everything and display with a rule
        let replacementSummary = getSelectionReplacementRow(
          hasForeignItems,
          sel
        );
        cleanSelections.forEach((daySel, index) => {
          if (daySel.action === "remove" && daySel.Status === "success") {
            totalRemovedItems++;
          }

          if (daySel.Status !== "success" && !hasFailures) {
            setHasFailures(true);
          }

          if (daySel.Status !== "success") {
            totalFailed++;
          }

          guestContent.push(
            <Fragment key={`${generateKey(daySel)}replacementsummary`}>
              {replacementSummary && index === 0 && replacementSummary}
            </Fragment>
          );

          index === 0 && guestContent.push(getSpaGuestNameRow(sel));

          guestContent.push(
            <Fragment key={`${generateKey(daySel)}daytimefrag`}>
              {getDayTimeRow(daySel)}
            </Fragment>
          );
        });
      }

      content.push(
        <BLContentCardWrapper
          background={isDesktop() ? "light" : "white"}
          dimensions={{ padding: ["s"] }}
        >
          <IonGrid style={{ gap: "8px", display: "grid" }}>
            {guestContent}
          </IonGrid>
        </BLContentCardWrapper>
      );
    });

    if (totalFailed === totalItemsRequested) {
      setAreAllFailures(true);
    }

    if (totalItemsRequested === totalRemovedItems) {
      setAreAllCancellations(true);
    } else {
      setProcessedRSResults(content);
    }
  };

  const getActionButtonsRow = () => {
    return (
      <IonRow
        className={` bl-margin-t-m ${isDesktop() ? "ion-justify-content-center" :
          "ion-justify-content-start"} ${isDesktop() ? "ion-align-items-center" : "ion-align-items-start"}`}
        style={{
          flexDirection: isDesktop() ? "row" : "column",
          gap: "16px",
        }}
      >
        <BLButton
          type="icon-text"
          content={hasFailures ? "Retry failed bookings" : "Change"}
          icon={hasFailures ? repeat : pencil}
          iconSize="md"
          onClick={() => {
            if (hasFailures) {
              retryFailsFunc();
            } else {
              setIsEditMode(true);
              setConfirmationScreenSelections(null);
              setPriceMsg(null);
            }
          }}
        />

        {!areAllFailures && (
          <BLButton
            newClasses="primary"
            content="View in Itinerary"
            onClick={() => redirectToItinerary()}
          />
        )}
      </IonRow>
    );
  };

  const getProcessingScreenContent = () => {
    return (
      <>
        <IonRow className="ion-text-center" style={{ height: "85px" }}>
          <IonCol>
            <BLLoadingContainer />
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-center">
          <IonCol>
            <BLParagraph
              txt={"Confirming your bookings. Please wait..."}
              color="text-grey"
              textVariant={typography.h4}
            />
          </IonCol>
        </IonRow>
      </>
    );
  };

  const getAreAllCancellationsScreenContent = (serviceDetails) => {
    return (
      <>
        <IonRow className="bl-margin-b-s bl-margin-t-l">
          <IonCol className="bl-flex ion-justify-content-center">
            <BLParagraph
              txt={`You have successfully cancelled your ${serviceDetails.Title}`}
              color="text-grey"
              textVariant={typography.h4}
              dimensions={{
                margin: ["l-s", "r-s"],
                padding: ["t-md", "b-md"],
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="bl-flex ion-justify-content-center">
            <BLButton
              content="Go back"
              type="underlined"
              onClick={() => {
                setIsEditMode(false);
                setConfirmationScreenSelections(null);
                setPriceMsg(null);
              }}
            />
          </IonCol>
        </IonRow>
      </>
    );
  };

  useEffect(() => {
    if (!isProcessingBookings && confirmationScreenSelections) {
      if (source === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
        prepareConfScreenSpaContent();
      } else {
        prepareConfScreenActivityContent();
      }
    }
  }, [isProcessingBookings, confirmationScreenSelections]);

  return {
    areAllCancellations,
    processedRSResults,
    getActionButtonsRow,
    getProcessingScreenContent,
    getAreAllCancellationsScreenContent,
  };
};

export default ConfirmationScreenLogic;
