import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { checkmarkCircle } from "ionicons/icons";
import React from "react";
import { chevronDownOutlined } from "../../../assets/SVGIcons";
import { useDataContext } from "../../../context/DataContext";
import { formatDateDetailed, getDatesBetween } from "../../../helpingFunctions/dateTimeUtilities";
import BLIcon from "../BLIcon";

const BLMUISelect = ({
  placeholder,
  dropdownList,
  value,
  onChange,
  viewType,
  fieldType,
  enableSuccessIcon,
}) => {
  const { reservationDetails } = useDataContext();

  return (
    <Select
      placeholder={placeholder}
      value={value ? value : ""}
      onChange={onChange}
      IconComponent={() => chevronDownOutlined(24, "#4D8298")}
      sx={{
        height: 50,
        width: "100%",
        background: "white",
        boxShadow: "none",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.MuiOutlinedInput-root": { paddingRight: "16px" },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
      }}
    >
      {dropdownList?.map((opt) => {
        return (
          <MenuItem key={opt.id} value={opt.id} disabled={opt.disable}>
            {fieldType === "dateSelector" ? (
              <>
                {enableSuccessIcon && (
                  <>
                    {opt.isCompleted ? (
                      <BLIcon
                        iconSource={checkmarkCircle}
                        color={"success"}
                        newClasses={"bl-padding-r-xs"}
                      />
                    ) : (
                      <div style={{ width: "32px" }} />
                    )}
                  </>
                )}

                {formatDateDetailed(
                  getDatesBetween(
                    reservationDetails.ArrivalDate,
                    reservationDetails.DepartureDate,
                    viewType
                  ),
                  opt.id,
                  true
                )}
              </>
            ) : (
              opt.Title
            )}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default BLMUISelect;
