import { IonCol, IonGrid, IonModal, IonRow } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import React, { useContext } from "react";
import { SpaContext } from "../../../../../context/SpaContext";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import BLInlineModal from "../../../../../shared/Ionic/Boxes/Popup/BLInlineModal";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../../hooks/useTheme";

const ContraindicationsPopup = ({ serviceDetails }) => {
  const { typography } = useTheme();
  const { showContraindications, setShowContraindications } =
    useContext(SpaContext);

  const content = (
    <IonGrid>
      {isDesktop() && (
        <IonRow className="bl-margin-b-xs">
          <IonCol
            className={
              "bl-flex ion-justify-content-between ion-align-items-center"
            }
          >
            <BLParagraph
              txt={serviceDetails?.Title}
              color="primary"
              textVariant={typography.h2}
              //newClasses={`x-large bold`}
              //TODO: Unclear typography
              dimensions={{
                margin: ["l-lg"],
                padding: ["t-md", "b-md"],
              }}
            />
            <BLIcon
              iconSource={closeCircle}
              iconSize="md"
              color="secondary"
              onClick={() => setShowContraindications(false)}
            />
          </IonCol>
        </IonRow>
      )}

      <IonRow className="bl-margin-b-xs">
        <IonCol>
          <BLParagraph
            txt="Contraindications"
            color="text-grey"
            textVariant={typography.h4}
            // newClasses={`small bold crimson-pro`}
            //TODO: Unclear typography
          />
        </IonCol>
      </IonRow>
      {serviceDetails?.Contraindications.map((row) => {
        return (
          <IonRow key={row} className="bl-margin-l-xs">
            <IonCol>
              <BLParagraph
                txt={`- ${row}`}
                color="text-grey"
                textVariant={typography.h4}
              />
            </IonCol>
          </IonRow>
        );
      })}
    </IonGrid>
  );

  return (
    <>
      {isDesktop() ? (
        <IonModal
          className="bl-popup"
          isOpen={showContraindications}
          backdropDismiss={false}
          showBackdrop={true}
        >
          {content}
        </IonModal>
      ) : (
        <BLInlineModal
          showAlert={showContraindications}
          dismissAlert={setShowContraindications}
          header={serviceDetails?.Title}
          modalClasses="bl-inline-modal-55percH"
          content={content}
        />
      )}
    </>
  );
};

export default ContraindicationsPopup;
