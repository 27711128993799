import { useEffect, useState } from "react";
import axiosInstance from "../../../url/createAxios";

const BookingFolioLogic = () => {
  const instance = axiosInstance();
  const [folioDetails, setFolioDetails] = useState(null);
  const [isLoadingFolio, setIsLoadingFolio] = useState(true);

  const fetchData = async () => {
    setIsLoadingFolio(true); 
    try {
      const { data } = await instance.get("/Hotel/Booking/Folio", {
        withCredentials: true,
      });

      setFolioDetails(data);
      setIsLoadingFolio(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { folioDetails, isLoadingFolio };
};

export default BookingFolioLogic;
