import React, { useContext, useEffect, useState } from "react";

import { SERVICES_TYPES } from "../../../../../constants/constants";
import { DEFAULT_CONTENT, DINING_MODALS } from "../../DiningBookingConstants";

import { DiningBookingContext } from "../../../../../context/DiningBookingContext";

import BLExpandableBox from "../../../../../shared/Ionic/Cards/BLExpandableBox/BLExpandableBox";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import DiningBookingPreview from "../DiningBookingPreview/DiningBookingPreview";
import DefaultContentDisplayLogic from "./DefaultContentDisplayLogic";
import { useTheme } from "../../../../../hooks/useTheme";

const DefaultContentDisplay = ({ viewType}) => {
  const { typography } = useTheme();
  const {
    selectedType,
    handleBoxSelection,
    diningBookingSelectionsPreview,
    selectedDateSelections,
    getDayParagraph,
    steps,
    setSteps,
    setActiveStep
  } = useContext(DiningBookingContext);

  const {
    displayByType,
    handleDisability,
    collapsedTitle,
    getExpandableIcon: getExpandableIcon,
    getSteps,
  } = DefaultContentDisplayLogic(viewType);

  const [filteredDefaultContent, setFilteredDefaultContent] = useState([]);

  const getIsClicked = (type) => {
    if ([DINING_MODALS.DAY, DINING_MODALS.RESTAURANTS].includes(type)) {
      return true;
    }
    return selectedType.has(type);
  };

  const getStepIndex = (stepType) => {
    return steps.findIndex(step => step.type === stepType);
  };

  const getActiveStep = () => {
    const isDateSelected = selectedDateSelections.date;
    const isRestaurantSelected = selectedDateSelections.restaurantId;
    const isTimeSelected = selectedDateSelections.time;
    const noBookingReq = selectedDateSelections.isSkipped;
    if (!isDateSelected) {
      return;
    } else if (noBookingReq) {
      setActiveStep(steps.length - 1);
    }else if (!isRestaurantSelected && !isTimeSelected) {
      setActiveStep(getStepIndex(DINING_MODALS.DAY));
    } else if (isRestaurantSelected && !isTimeSelected) {
      setActiveStep(getStepIndex(DINING_MODALS.RESTAURANTS));
    } else if (isRestaurantSelected && isTimeSelected) {
      setActiveStep(getStepIndex(DINING_MODALS.TIME));
    }
    
  };

  useEffect(() => {
    getActiveStep();
  }, [selectedDateSelections, steps]);

  useEffect(() => {
    switch (viewType) {
      case SERVICES_TYPES.BREAKFAST:
        setFilteredDefaultContent(DEFAULT_CONTENT);
        setSteps(getSteps(DEFAULT_CONTENT));
        // TODO #6599: Hiding treats in dinner page
        // filteredDefaultContent = DEFAULT_CONTENT.filter(
        //   ({ type }) => type !== DINING_MODALS.TREATS
        // );
        break;
      default:
        setFilteredDefaultContent(DEFAULT_CONTENT);
        setSteps(getSteps(DEFAULT_CONTENT));

        // TODO #6599: Hiding treats in dinner page
        // if (treatsByCurrentView.treatsCount === 0) {
        //   filteredDefaultContent = filteredDefaultContent.filter(
        //     ({ type }) => type !== DINING_MODALS.TREATS
        //   );
        // }
        break;
    }
  }, []);

  return (
    <>
      {diningBookingSelectionsPreview?.selections.length > 0 ? (
        <DiningBookingPreview view={viewType} />
      ) : (
        filteredDefaultContent?.map(({ title, type }) => (
          <BLExpandableBox
            value={type}
            key={type}
            colorClass={"ion-color-light"}
            newClass={"ExpandableBox_second"}
            dimensions={{ padding: ["t-xs", "b-xs", "l-s", "r-s"] }}
            collapsedTitle={
              <BLParagraph
                textVariant={typography.h3}
                newClasses={`force-center`}
                // newClasses={`medium crimson-pro bold force-center`}
                //TODO: Unclear typography
                txt={
                  <>
                    {getExpandableIcon(type)}
                    {collapsedTitle(title, type, selectedDateSelections.date)}
                  </>
                }
              />
            }
            expandedTitle={
              <BLParagraph
                textVariant={typography.h3}
                newClasses={`force-center`}
                // newClasses={`medium crimson-pro bold force-center`}
                //TODO: Unclear typography
                txt={
                  <>
                    {getExpandableIcon(type)}
                    <BLParagraph
                      txt={
                        type === DINING_MODALS.DAY
                          ? getDayParagraph(selectedDateSelections.date)
                          : title
                      }
                      dimensions={{ padding: ["l-s"], margin: ["r-s"] }}
                    />
                  </>
                }
                color="primary"
              />
            }
            onClick={(e) => {
              if (e.target.ariaLabel) {
                e.preventDefault();
              } else if (
                ![DINING_MODALS.DAY, DINING_MODALS.RESTAURANTS].includes(type)
              ) {
                handleBoxSelection(type);
              }
            }}
            isClicked={getIsClicked(type)}
            isDisabled={handleDisability(type)}
            isReadOnly={[DINING_MODALS.DAY, DINING_MODALS.RESTAURANTS].includes(
              type
            )}
          >
            {displayByType(type)}
          </BLExpandableBox>
        ))
      )}
    </>
  );
};

export default DefaultContentDisplay;
