import React from "react";
import { IonCol } from "@ionic/react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLCheckTriangle from "../../../../../shared/Ionic/Cards/BLCheckTriangle/BLCheckTriangle";
import { useContext } from "react";
import { DiningBookingContext } from "../../../../../context/DiningBookingContext";
import styles from "./Restaurants.module.scss";
import { useTheme } from "../../../../../hooks/useTheme";

const RestaurantCardMobile = ({
  restDetails,
  isSelected,
  onClick,
  isUnavailable,
  isInThePast,
}) => {
  const { typography } = useTheme();
  const { getUnavailRestBannerText } = useContext(DiningBookingContext);

  const unavailableBanner = (
    <div className={styles.restaurant_unavail_banner}>
      <BLParagraph txt={getUnavailRestBannerText()} textVariant={typography.BODY_TEXT} />
    </div>
  );

  return (
    <>
      <IonCol
        size="12"
        onClick={onClick}
        className={`bl-flex ion-justify-content-center`}
        style={{
          height: "90px",
          pointerEvents: isInThePast ? "none" : "auto",
          opacity: isInThePast ? "0.6" : "1",
        }}
      >
        {isSelected && (
          <>
            <BLCheckTriangle
              size="sm"
              backgroundColor="var(--ion-color-white)"
              checkColor="var(--ion-color-secondary)"
            />
            <div className={styles.RestaurantCardMobile_isSelected} />
          </>
        )}
        {isUnavailable && unavailableBanner}
        <img
          src={`${restDetails.Image[0]?.url}`}
          alt=""
          style={{
            width: "100%",
            height: "90px",
            objectFit: "cover",
            filter: "brightness(0.6)",
            borderRadius: "5px",
          }}
        />
        <BLParagraph
          txt={restDetails.Title}
          textVariant={typography.BODY_TEXT}
          //newClasses="x-small"
          //Unclear typography
          color="white"
          newStyles={{
            position: "absolute",
            bottom: "8px",
            textAlign: "center",
            zIndex: 2,
          }}
        />
      </IonCol>
    </>
  );
};

export default RestaurantCardMobile;
