import { IonCol, IonGrid, IonInput, IonItem, IonRow } from "@ionic/react";
import { format } from "date-fns";
import {
  addCircle,
  arrowUndoCircleOutline,
  checkmarkCircle,
  closeCircle,
  pencil,
  people,
} from "ionicons/icons";
import React, { useContext } from "react";
import { useDataContext } from "../../../../../../context/DataContext";
import { SpaContext } from "../../../../../../context/SpaContext";
import { getCurrentDate } from "../../../../../../helpingFunctions/dateTimeUtilities";
import BLIcon from "../../../../../../shared/Ionic/BLIcon";
import BLButton from "../../../../../../shared/Ionic/Buttons/BLButton";
import BLLabel from "../../../../../../shared/Ionic/Inputs/BLLabel";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import GuestsRowLogic from "./GuestsRowLogic";
import { isDesktop } from "../../../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../../../hooks/useTheme";

const GuestsRow = (serviceDetails) => {
  const { typography } = useTheme();
  const { spaSelections, isEditMode } = useContext(SpaContext);
  const { reservationDetails } = useDataContext();
  const {
    addSelection,
    removeSelection,
    handleForm,
    isFullName,
    edit,
    isNameRepeated,
  } = GuestsRowLogic(serviceDetails);

  const guestCount = spaSelections?.length;

  const guestsRowExpanded = (
    <IonGrid>
      <IonRow>
        <IonCol className="bl-flex ion-align-items-center">
          <BLIcon iconSource={people} color="primary" iconSize="md" />
          <BLParagraph
            txt="Guests"
            color="primary"
            textVariant={typography.h3}
            // newClasses={`medium bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
            }}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const guestsRowCollapsed = (
    <IonGrid>
      <IonRow>
        <IonCol className="bl-flex ion-align-items-center">
          <BLIcon iconSource={people} color="primary" iconSize="md" />
          <BLParagraph
            txt={`${guestCount} ${guestCount === 1 ? "Guest" : "Guests"}`}
            color="primary"
            textVariant={typography.h3}
            // newClasses={`medium bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
            }}
          />

          {spaSelections?.filter(
            (row) =>
              (row.fName !== row.bookedFName ||
                row.lName !== row.bookedLName ||
                !["none"].includes(row.action)) &&
              isEditMode
          ).length > 0 && (
            <BLParagraph
              txt="*Updated*"
              color="success"
              textVariant={typography.h3}
              // newClasses={`medium bold crimson-pro`}
              //TODO: Unclear typography
              dimensions={{
                margin: ["l-s"],
              }}
            />
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const isNotInThePast = (selection) => {
    return (
      selection.on.findIndex(
        (sel) =>
          (sel.bookedDate && sel.bookedDate < getCurrentDate()) ||
          (sel.bookedTime &&
            sel.bookedDate === getCurrentDate() &&
            sel.bookedTime.slice(0, 5).replace(":", "") <
              format(new Date(), "kkmm"))
      ) === -1
    );
  };

  const getField = (value, action, valueType, placeholder, index) => {
    return (
      <IonItem
        style={{
          background: !isDesktop()
            ? "var(--ion-color-light)"
            : "var(--ion-color-white)",
          "--background": !isDesktop()
            ? "var(--ion-color-light)"
            : "var(--ion-color-white)",

          "--color": "var(--ion-color-text-grey)",
          height: "50px",
        }}
        className="bl-box-height-default"
        lines="none"
      >
        <IonInput
          className="bl-box-height-default"
          placeholder={placeholder}
          value={value}
          disabled={action === "remove"}
          name={valueType}
          onIonInput={(v) => handleForm(index, valueType, v)}
        />
      </IonItem>
    );
  };

  const getParticipantFieldsDesktop = (selection, index) => {
    return (
      <IonGrid className="bl-margin-l-s">
        <IonRow className="ion-justify-content-between">
          <IonCol size="5.7" style={{ maxWidth: "350px" }}>
            <BLLabel
              label="Name"
              labelDimensions={{ margin: ["b-xxs", "t-xs"] }}
            />
            {getField(
              selection.fName,
              selection.action,
              "fName",
              "Enter your name",
              index
            )}
          </IonCol>
          <IonCol size="5.7" style={{ maxWidth: "350px" }}>
            <BLLabel
              label="Surname"
              labelDimensions={{ margin: ["b-xxs", "t-xs"] }}
            />
            {getField(
              selection.lName,
              selection.action,
              "lName",
              "Enter your surname",
              index
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  const getParticipantFieldsMobile = (selection, index) => {
    return (
      <IonGrid className="bl-margin-l-xs bl-margin-r-xs">
        <IonRow>
          <IonCol style={{ maxWidth: "350px" }}>
            <BLLabel
              label="Name"
              labelDimensions={{ margin: ["b-xxs", "t-xs"] }}
            />
            {getField(
              selection.fName,
              selection.action,
              "fName",
              "Enter your name",
              index
            )}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ maxWidth: "350px" }}>
            <BLLabel
              label="Surname"
              labelDimensions={{ margin: ["b-xxs", "t-xs"] }}
            />
            {getField(
              selection.lName,
              selection.action,
              "lName",
              "Enter your surname",
              index
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  const guestsRowContent = (
    <IonGrid className="ion-align-items-center">
      {spaSelections?.map((selection, index) => (
        <IonRow
          key={selection.uniqueKey || index}
          className={`ion-justify-content-between bl-padding-t-xs bl-padding-b-xs  ${
            selection.isFullName && !selection.isEditingName && isDesktop()
              ? "bl-flex ion-align-items-center"
              : ""
          }`}
          style={{
            maxWidth: isDesktop() ? "700px" : "600px",
          }}
        >
          <IonCol
            size={
              !isDesktop()
                ? 12
                : selection.isFullName && !selection.isEditingName
                  ? 10
                  : 12
            }
            className="bl-flex ion-align-items-center"
          >
            <BLContentCardWrapper
              background={isDesktop() ? "light" : "white"}
              newClasses={`bl-flex ${
                (isDesktop() ||
                  (!isDesktop() &&
                    selection.isFullName &&
                    !selection.isEditingName)) &&
                "ion-align-items-center"
              }`}
              dimensions={{
                padding: [
                  "l-xs",
                  "r-xs",
                  "t-xs",
                  selection.isFullName && !selection.isEditingName
                    ? "b-xs"
                    : "b-s",
                ],
              }}
            >
              {selection.isFullName && !selection.isEditingName ? (
                <IonGrid>
                  <IonRow className="ion-nowrap ion-align-items-center">
                    <IonCol size="auto">
                      <div
                        className="bl-flex ion-justify-content-center ion-align-items-center"
                        style={{
                          backgroundColor: "var(--ion-color-primary)",
                          borderRadius: "50%",
                          width: "32px",
                          height: "32px",
                          marginTop: "2px",
                        }}
                      >
                        <p
                          style={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          {index + 1}
                        </p>
                      </div>
                    </IonCol>
                    <IonCol
                      className={
                        !isDesktop()
                          ? "bl-flex ion-align-items-center ion-justify-content-between bl-margin-l-s"
                          : "bl-margin-l-s"
                      }
                    >
                      <BLParagraph
                        txt={selection.guestName}
                        textVariant={typography.BODY_TEXT}
                        color="text-grey"
                      />
                    </IonCol>
                    {!isDesktop() && isNotInThePast(selection) && (
                      <IonCol size="auto">
                        <BLIcon
                          iconSource={pencil}
                          iconSize="md"
                          color="secondary"
                          onClick={() => edit(index)}
                          newClasses="ion-no-padding"
                        />
                      </IonCol>
                    )}
                  </IonRow>
                </IonGrid>
              ) : (
                <>
                  {isDesktop()
                    ? getParticipantFieldsDesktop(selection, index)
                    : getParticipantFieldsMobile(selection, index)}

                  <div
                    style={
                      !isDesktop() ? { alignItems: "end", display: "flex" } : {}
                    }
                  >
                    <BLIcon
                      iconSource={checkmarkCircle}
                      color="secondary"
                      iconSize="md"
                      onClick={() => isFullName(index)}
                      newClasses="bl-margin-l-xs"
                      disable={isNameRepeated()}
                    />
                    <BLIcon
                      iconSource={
                        selection.action === "edit" ||
                        (index === 0 &&
                          selection.action === "add" &&
                          (selection.fName !== reservationDetails.FirstName ||
                            selection.lName !== reservationDetails.LastName))
                          ? arrowUndoCircleOutline
                          : closeCircle
                      }
                      color="secondary"
                      iconSize="md"
                      onClick={() => removeSelection(index)}
                    />
                  </div>
                </>
              )}
            </BLContentCardWrapper>

            {isDesktop() &&
              selection.isFullName &&
              !selection.isEditingName && 
              isNotInThePast(selection) && (
              <BLIcon
                iconSource={pencil}
                iconSize="md"
                color="secondary"
                onClick={() => edit(index)}
                newClasses="bl-margin-l-s"
                newStyles={{
                  cursor: "pointer",
                }}
              />
            )}
          </IonCol>
        </IonRow>
      ))}

      <IonRow>
        <IonCol>
          <BLButton
            type="icon-text"
            icon={addCircle}
            iconSize="md"
            content="Add more guests"
            onClick={() => addSelection()}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
  return { guestsRowExpanded, guestsRowCollapsed, guestsRowContent };
};

export default GuestsRow;
