import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { pricetag } from "ionicons/icons";
import React from "react";
import { formatPrice } from "../../../../../helpingFunctions/formatPrice";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import BLCheckTriangle from "../../../../../shared/Ionic/Cards/BLCheckTriangle/BLCheckTriangle";
import BLItem from "../../../../../shared/Ionic/Cards/BLItem/BLItem";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../../hooks/useTheme";

const TreatYourselfSummaryCard = ({
  images,
  title,
  price,
  onClick,
  newWrapperStyles,
  hasBooking,
}) => {
  const { typography } = useTheme();

  return (
    <BLItem
      onClick={onClick ? onClick : null}
      newWrapperStyles={newWrapperStyles}
      newStylesMainContentRow={{display: "flex", flexDirection: "column", background: !isDesktop() && "white" }}
      containerType="category"
      contentType="category"
      imageType="summary"
      imageContent={
        <>
          {hasBooking && <BLCheckTriangle />}
          <BLImageCarousel content={images} hideNavigation hidePagination />
        </>
      }
      mainContent={
        <IonGrid>
          <IonRow
            className="ion-justify-content-between ion-align-items-baseline bl-margin-xs"
            style={{ gap: "8px" }}
          >
            <IonCol className="ion-text-left">
              <BLParagraph
                color="text-grey"
                txt={title}
                textVariant={isDesktop() ? typography.h2 : typography.h4}
                // newClasses={`${
                //   isDesktop() ? "x-large" : "small"
                // } bold crimson-pro`}
                //TODO: Unclear typography
              />
            </IonCol>
            <IonCol
              size="auto"
              className="bl-flex ion-align-items-center ion-justify-content-center"
              style={{ gap: "5px" }}
            >
              <BLIcon
                color="primary"
                iconSource={pricetag}
                iconSize="xxsm"
              />
              <BLParagraph
                txt={`${price === "Inclusive" ? "Inclusive" : formatPrice(price)}`}
                color="text-grey"
                textVariant={typography.BODY_TEXT}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      }
      contentDimensions={{ padding: [] }}
    />
  );
};

export default TreatYourselfSummaryCard;
