import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SideMenuContext } from "../context/SideMenuContext";
import { ROUTE_VIEWS } from "../routes/routeViews";

const ZENDESK_INDEX = {
  SHOW_INDEX: 333335,
  HIDE_INDEX: -1,
};

const useZendesk = () => {
  const { pathname } = useLocation();
  const [launcher, setLauncher] = useState();
  const isHelpPage = pathname.includes(ROUTE_VIEWS.HELP);
  const { isSideMenuOpened } = useContext(SideMenuContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setLauncher(document.getElementById("launcher"));
      if (launcher) {
        clearInterval(interval);
      }
    });
  }, []);

  useEffect(() => {
    if (launcher) {
      // This is needed property for iOS
      launcher.style.display = isHelpPage && !isSideMenuOpened ? "" : "none";

      // Create an observer instance linked to a function to execute when mutations are observed
      const observer = new MutationObserver(() => {
        launcher.style.bottom = "70px";
      });

      // Start observing the target node for attribute changes
      const config = { attributes: true };
      observer.observe(launcher, config);
      launcher.style.zIndex = isHelpPage
        ? ZENDESK_INDEX.SHOW_INDEX
        : ZENDESK_INDEX.HIDE_INDEX;
    }
  }, [pathname, isSideMenuOpened, isHelpPage, launcher]);

  return {};
};

export default useZendesk;
