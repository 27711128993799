import React from "react";
import BLParagraph from "../shared/Ionic/Paragraph/BLParagraph";
import { formatPrice } from "./formatPrice";
import { generateKey } from "./helpingFunctions";

export const constructInfoBoxPriceMsg = (
  totalToAdd,
  totalToRemove,
  setPriceMsg,
  typography
) => {
  let msg = [
    <BLParagraph
      key={generateKey(totalToAdd)}
      txt="A total of"
      textVariant={typography.BODY_TEXT}
    />,
  ];

  if (totalToAdd > 0) {
    const txt = `${formatPrice(totalToAdd)} will be added`;
    msg.push(
      <BLParagraph
        key={generateKey(txt)}
        txt={txt}
        textVariant={typography.BODY_TEXT}
        // newClasses={`x-small bold`}
        //TODO: Unclear typography
        newStyles={{ marginLeft: "5px", marginRight: "5px" }}
      />
    );
    msg.push(
      <BLParagraph
        key={generateKey(`to your account`)}
        txt="to your account"
        textVariant={typography.BODY_TEXT}
      />
    );

    if (totalToRemove > 0) {
      msg.push(
        <BLParagraph
          key={generateKey(` and a total of`)}
          txt=" and a total of"
          textVariant={typography.BODY_TEXT}
        />
      );
    }
  }

  if (totalToRemove > 0) {
    const txt = `${formatPrice(totalToAdd)} will be added`;
    msg.push(
      <BLParagraph
        key={generateKey(txt)}
        txt={txt}
        textVariant={typography.BODY_TEXT}
        // newClasses={`x-small bold`}
        //TODO: Unclear typography
        newStyles={{ marginLeft: "5px", marginRight: "5px" }}
      />
    );
    msg.push(
      <BLParagraph
        key={generateKey(`from your account`)}
        txt="from your account"
        textVariant={typography.BODY_TEXT}
      />
    );
  }

  msg.push(
    <BLParagraph
      key={generateKey(`.`)}
      txt={`.`}
      textVariant={typography.BODY_TEXT}
    />
  );

  if (totalToAdd === 0 && totalToRemove === 0) {
    setPriceMsg(null);
  } else {
    setPriceMsg(msg);
  }
};
