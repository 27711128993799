import { IonCard, IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import { informationCircle } from "ionicons/icons";
import { useDataContext } from "../../../../../context/DataContext";
import { DiningBookingContext } from "../../../../../context/DiningBookingContext";
import {
  formatTime,
  getCurrentDate,
} from "../../../../../helpingFunctions/dateTimeUtilities";
import WindowWidth from "../../../../../hooks/WindowWidth";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import boxSizingStyles from "../../../../../theme/parentPageBoxSizing.module.scss";
import AdditionalRestaurantDetailsPopUp from "./AdditionalRestaurantDetailsPopUp";
import RestaurantCardDesktop from "./RestaurantCardDesktop";
import RestaurantCardMobile from "./RestaurantCardMobile";
import RestaurantsLogic from "./RestaurantsLogic";

const RestaurantsNew = ({ viewType }) => {
  const { width } = WindowWidth();
  const {
    currentRestaurants,
    handleResSelection,
    moreInfoRestaurantDetails,
    setMoreInfoRestaurantDetails,
    getRestTileSize,
  } = RestaurantsLogic(viewType);
  const { isLoadingItinerary, isLoadingAvailability, reservationDetails } =
  useDataContext();
  const { clickedRestaurantId, setClickedRestaurantId } =
    useContext(DiningBookingContext);

  const {
    selectedDateSelections: { restaurantId, time, date },
  } = useContext(DiningBookingContext);

  const isInThePast =
    time &&
    reservationDetails[viewType] &&
    formatTime(new Date().toString()) > time.slice(0, 5) &&
    getCurrentDate() === date;

  return (
    <BLContentCardWrapper dimensions={{ margin: ["t-xs", "b-m"] }}>
      {isLoadingItinerary || isLoadingAvailability ? (
        <IonRow>
          <IonCol size="12">
            <IonRow className={"ion-text-center"}>
              <IonCol>
                <BLLoadingContainer />
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      ) : (
        <>
          {width >= 821 ? (
            <IonGrid>
              <IonRow style={{ gap: "15px" }}>
                <RestaurantCardDesktop
                  currentRestaurants={currentRestaurants}
                  clickedRestaurantId={clickedRestaurantId}
                  setClickedRestaurantId={setClickedRestaurantId}
                  getRestTileSize={getRestTileSize}
                  handleResSelection={handleResSelection}
                  isInThePast={isInThePast}
                />
              </IonRow>
            </IonGrid>
          ) : (
            <IonGrid>
              <AdditionalRestaurantDetailsPopUp
                showAlert={moreInfoRestaurantDetails}
                dismissAlert={setMoreInfoRestaurantDetails}
                restDetails={moreInfoRestaurantDetails}
                onSelectClick={(restId) => {
                  if (currentRestaurants.length > 1 && !isInThePast) {
                    handleResSelection(restId);
                  }
                }}
                isSelected={moreInfoRestaurantDetails?.id === restaurantId}
              />

              <IonRow
                style={{ gap: "13px" }}
                className={boxSizingStyles["box-size-wrapper"]}
              >
                {currentRestaurants?.map((rest) => {
                  return (
                    <IonCol
                      key={rest.restaurantId}
                      className={`${boxSizingStyles["box-size"]} bl-flex ion-justify-content-center`}
                    >
                      <IonCard style={{ width: "100%" }}>
                        <IonGrid>
                          <IonRow className="ion-text-center">
                            <RestaurantCardMobile
                              restDetails={rest.details}
                              isSelected={rest.restaurantId === restaurantId}
                              onClick={() => {
                                if (
                                  currentRestaurants.length > 1 &&
                                  !isInThePast
                                ) {
                                  handleResSelection(rest.restaurantId);
                                }
                              }}
                              isUnavailable={rest.times.length === 0}
                              isInThePast={isInThePast}
                            />
                            <IonCol>
                              <BLButton
                                type="icon-text"
                                icon={informationCircle}
                                iconSize="md"
                                content={"More info"}
                                onClick={() =>
                                  setMoreInfoRestaurantDetails(rest.details)
                                }
                              />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </IonCol>
                  );
                })}
              </IonRow>
            </IonGrid>
          )}
        </>
      )}
    </BLContentCardWrapper>
  );
};

export default RestaurantsNew;
