import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { useDataContext } from "../../../context/DataContext";
import BLContentCardWrapper from "../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import DefaultContentDisplay from "./components/DefaultContentDisplay/DefaultContentDisplay";
import AdditionalRestaurantDetails from "./components/Restaurants/AdditionalRestaurantDetails";
import StayNavigator from "./components/StayNavigator/StayNavigator";
import AdditionalTreatsDetails from "./components/TreatYourself/TreatYourselfPresentation/AdditionalTreatsDetails";
import { FOOTER_SIZE, SERVICES_TYPES } from "../../../constants/constants";
import { getCurrentDate } from "../../../helpingFunctions/dateTimeUtilities";
import BLInformationBox from "../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import { DiningBookingContext } from "../../../context/DiningBookingContext";
import BLStepper from "../../../shared/Ionic/Stepper/BLStepper";
import styles from "./DiningBooking.module.scss";
import ConflictPopupBoxes from "../../../helpingFunctions/conflictManagement/ConflictPopupBoxes";
import { isDesktop } from "../../../helpingFunctions/utilities";
import RequestHook from "../../../hooks/RequestHook";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import BLAlertPopUp from "../../../shared/Ionic/Boxes/Popup/BLAlertPopUp";
import { ConflictManagementContext } from "../../../context/ConflictManagementContext";
import ServicePageHeader from "../../../shared/Ionic/Wrappers/ServicePageHeader";

const DiningBooking = () => {
  const location = useLocation();
  const { warnerServices } = useContext(NavigatorContext);
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const {
    reservationDetails,
    dynamicTexts,
  } = useDataContext();
  const {
    steps,
    activeStep,
    diningBookingSelectionsPreview: { selections },
    restaurantDetails,
    setRestaurantDetails,
    showRestaurantDetails,
    setShowRestaurantDetails,
    // viewAllTreats,
    addOnDetails,
    setAddOnDetails,
    showAddOnDetails,
    setShowAddOnDetails,
    groupDetachmentConfirmDialog,
    setGroupDetachmentConfirmDialog,
  } = useContext(DiningBookingContext);
  const { findPageConflicts } = useContext(ConflictManagementContext);
  const { getGroup } = RequestHook();
  const Type = location.pathname.includes(SERVICES_TYPES.BREAKFAST) ? SERVICES_TYPES.BREAKFAST : SERVICES_TYPES.DINNER;

  useEffect(() => {
    if (reservationDetails) {
      getGroup(reservationDetails);
    }
  }, [reservationDetails]);

  useEffect(() => {
    if (warnerServices.length === 0) {
      setIsLoaderOpened(true);
      setLoaderMessage(`Loading ${Type} Page`);
    } else {
      setIsLoaderOpened(false);
    }
  }, [warnerServices]);


  return (
    reservationDetails && (
      <IonPage style={{ overflow: "auto" }}>
        <ConflictPopupBoxes />
        <IonGrid
          style={{
            maxHeight: `calc(100vh - 80px - ${FOOTER_SIZE} - env(safe-area-inset-top) - env(safe-area-inset-bottom))`,
            overflow: "auto",
          }}
        >
          <ServicePageHeader txt={`${Type} Booking`} />
          {/* TODO #6599: Hiding treats in dinner page */}
          {/* {viewAllTreats && (
            <IonCol className={"ion-text-center bl-padding-b-s"} size="12">
              <BLParagraph
                txt={
                  "Select from our wine packages and add them to your dinner dining bookings below."
                }
                color={"text-grey"}
              />
            </IonCol>
          )} */}
          <IonRow className="bl-flex">
            {selections?.length === 0 && (
              <IonCol
                size="auto"
                className="bl-padding-l-xs"
                style={{
                  position: "absolute",
                  top: "20%",
                  bottom: "30%",
                  height: "70%",
                }}
              >
                <BLStepper
                  newClasses={styles.DiningBooking_Stepper}
                  orientation="vertical"
                  steps={steps}
                  activeStep={activeStep}
                />
              </IonCol>
            )}
            <IonCol
              className="bl-padding-r-s"
              style={{
                paddingLeft:
                  selections?.length > 0 || steps?.length === 0
                    ? "16px"
                    : "56px",
              }}
            >
              {Type === SERVICES_TYPES.DINNER &&
              reservationDetails?.DepartureDate.slice(0, 10) ===
                getCurrentDate() ? (
                  <IonGrid>
                    <IonRow className="bl-margin-t-m bl-padding-m">
                      <BLInformationBox
                        content={
                          dynamicTexts?.Shared?.DiningAndEntertainment
                            ?.InfoBox_Intro_DepartureDateRestriction
                        }
                        type="info"
                        markdown={true}
                      />
                    </IonRow>
                  </IonGrid>
                ) : (
                  <BLContentCardWrapper
                    dimensions={{ padding: ["b-s"] }}
                    width={"100%"}
                    background="white"
                  >
                    {isDesktop() && showRestaurantDetails ? (
                      <AdditionalRestaurantDetails
                        dismissAlert={() => {
                          setShowRestaurantDetails(false);
                          setRestaurantDetails(null);
                        }}
                        images={restaurantDetails.Image}
                        header={restaurantDetails.Title}
                        longDescription={restaurantDetails.LongDescription}
                      />
                    ) : isDesktop() && showAddOnDetails ? (
                      <AdditionalTreatsDetails
                        dismissAlert={() => {
                          setShowAddOnDetails(false);
                          setAddOnDetails(null);
                        }}
                        images={[addOnDetails.Image]}
                        header={addOnDetails.Title}
                        longDescription={addOnDetails.LongDescription}
                        price={addOnDetails.Price}
                      />
                    ) : (
                    // TODO #6599: Hiding treats in dinner page
                    //  viewAllTreats ? (
                    //   <>
                    //     <AllTreatsPage view={Type} isDesktop={isDesktop} />
                    //   </>
                    // ) :
                      <DefaultContentDisplay viewType={Type} />
                    )}
                  </BLContentCardWrapper>
                )}
            </IonCol>
          </IonRow>

          {/* TODO #6599: Hiding treats in dinner page */}
          {/* {!showRestaurantDetails && !showAddOnDetails && !viewAllTreats && (
          <StayNavigator />
        )} */}

          {!showRestaurantDetails && !showAddOnDetails && <StayNavigator />}
          {groupDetachmentConfirmDialog && (
            <BLAlertPopUp
              title={`Leaving dining party`}
              description={
                dynamicTexts?.Dining?.DiningParty?.Popup_GroupMemberDetachment
              }
              question="Do you confirm leaving the dining party?"
              isOpen={groupDetachmentConfirmDialog}
              confirmHandler={() => {
                findPageConflicts(selections);
                setGroupDetachmentConfirmDialog(false);
              }}
              dismissHandler={() => {
                setGroupDetachmentConfirmDialog(false);
              }}
              confirmBtnLabel={"Confirm"}
              dismissBtnLabel={"Cancel"}
              confirmBtnType={"primary"}
              dismissBtnType={"underlined"}
            />
          )}
        </IonGrid>
      </IonPage>
    )
  );
};

export default DiningBooking;
