import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import { bicycle, colorPalette, golf, search } from "ionicons/icons";
import React, { useContext, useEffect } from "react";
import { shows, spaFilled } from "../../../assets/SVGIcons";
import { useDataContext } from "../../../context/DataContext";
import { NavigatorContext } from "../../../context/NavigatorContext";
import BLIcon from "../../../shared/Ionic/BLIcon";
import CategoryCard from "../../../shared/Ionic/ServicesComponents/ServiceCards/CategoryCard";
import BLContentCardWrapper from "../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import styles from "../../../theme/parentPageBoxSizing.module.scss";
import { isDesktop } from "../../../helpingFunctions/utilities";
import { SERVICES_TYPES } from "../../../constants/constants";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import ServicePageHeader from "../../../shared/Ionic/Wrappers/ServicePageHeader";
import { ROUTE_VIEWS } from "../../../routes/routeViews";
import { useHistory } from "react-router-dom";

const ThingsToDoPage = () => {
  const history = useHistory();
  const { redirectBetweenPages, warnerServices, getWarnerService } =
    useContext(NavigatorContext);
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const { reservationDetails } = useDataContext();

  useEffect(() => {
    if (warnerServices.length === 0) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Things To Do Page");
    } else {
      setIsLoaderOpened(false);
    }
  }, [warnerServices]);

  const ionIconsOptions = {
    "Activities": bicycle,
    "Golf": golf,
    "Experiences": colorPalette,
    "Explore": search,
  };

  const getIconType = (type) => {
    if (type === SERVICES_TYPES.ENTERTAINMENT) {
      return <BLIcon customIconSource={shows()} />;
    } else if (type === SERVICES_TYPES.SPAS) {
      return <BLIcon customIconSource={spaFilled()} />;
    } else {
      return <BLIcon iconSource={ionIconsOptions[type]} color={"primary"} />;
    }
  };

  const hasBooking = (type) => {
    return (
      (type === SERVICES_TYPES.ACTIVITIES && reservationDetails.Activities) ||
      (type === SERVICES_TYPES.SPAS && reservationDetails.Spas) ||
      (type === SERVICES_TYPES.TREATS && reservationDetails.Treat) ||
      (type === SERVICES_TYPES.ENTERTAINMENT && reservationDetails.Venue) ||
      (type === SERVICES_TYPES.REGISTER_CARD && reservationDetails.CreditCard)
    );
  };

  return (
    reservationDetails && (
      <IonPage>
        <BLContentCardWrapper
          newStyles={{ height: "100%", overflow: "auto" }}
          background={isDesktop() ? "white" : "light"}
        >
          <IonGrid>
            <ServicePageHeader
              service={SERVICES_TYPES.THINGS_TO_DO}
              serviceType="service"
              onClick={() => history.push(ROUTE_VIEWS.LANDING_PAGE)}
            />

            <IonRow
              className={`${styles["box-size-wrapper"]} bl-margin-t-s bl-padding-s`}
              style={{ gap: "15px" }}
            >
              {getWarnerService(SERVICES_TYPES.THINGS_TO_DO)?.options?.map(
                (opt) => (
                  <IonCol
                    className={styles["box-size"]}
                    key={`${opt.Type}_${opt.Title}`}
                    onClick={async (e) => {
                      e.stopPropagation();
                      await redirectBetweenPages(`/${opt.Type}`);
                    }}
                  >
                    <CategoryCard
                      title={opt.Title}
                      categoryImg={opt.Image?.formats?.small?.url || opt.Image?.url}
                      hasBooking={hasBooking(opt.Type)}
                      icon={getIconType(opt.Type)}
                      titleContentStyle={{
                        background: isDesktop()
                          ? "var(--ion-color-light)"
                          : "var(--ion-color-white)",
                      }}
                    />
                  </IonCol>
                )
              )}
            </IonRow>
          </IonGrid>
        </BLContentCardWrapper>
      </IonPage>
    )
  );
};

export default ThingsToDoPage;
