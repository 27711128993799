import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App } from "@capacitor/app";

const AppUrlListener = () => {
  let history = useHistory();

  const getPath = (url) => {
    if (!url) return;

    const urlParts = url.split("//");
    if (urlParts[0] === "http:" || urlParts[0] === "https:") {
      const protocol = urlParts[0] + "//";
      const host = urlParts[1].split("/")[0];
      const path = url.split(protocol + host)[1];

      return path;
    }
  };

  App.addListener("appUrlOpen", (data) => {
    history.push(getPath(data?.url));
  });

  const checkAppLaunchUrl = async () => {
    const { url } = await App.getLaunchUrl();
    const path = getPath(url);
    if (path) {
      history.push(path);
    }
  };

  useEffect(() => {
    checkAppLaunchUrl();
  }, []);

  return <></>;
};

export default AppUrlListener;
