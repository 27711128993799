import { StepConnector, stepConnectorClasses } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/styles";
import React from "react";
import { DESKTOP_PLATFORM } from "../../../constants/constants";
import BLIcon from "../BLIcon";
import { isDesktop } from "../../../helpingFunctions/utilities";

const stepLabelProps = {
  "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
    fontFamily: "Open Sans",
    fontSize: 16,
    fontWeight: 600,
    color: "var(--ion-color-primary)",
  },
  "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel.Mui-disabled": {
    opacity: 0.5,
  },
};

const HorizontalConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
    padding: "0px 13px",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderTop: "1px dashed var(--ion-color-primary)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderTop: "1px dashed var(--ion-color-primary)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTop: "1px dashed var(--ion-color-primary);",
    opacity: 0.5,
  },
}));

const VerticalConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
    padding: "0px 13px",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderLeft: "1px dashed var(--ion-color-primary)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderLeft: "1px dashed var(--ion-color-primary)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderLeft: "1px dashed var(--ion-color-primary);",
    opacity: 0.5,
    height: "100%",
    marginLeft: "8px",
  },
}));

const CustomStepIconRoot = styled("div")(({ state }) => ({
  backgroundColor: "var(--ion-color-primary)",
  opacity: 0.5,
  zIndex: 1,
  color: "#fff",
  width: isDesktop() === DESKTOP_PLATFORM && state.stepperOrientation === "horizontal" ? "56px" : "40px",
  height: isDesktop() && state.stepperOrientation === "horizontal" ? "56px" : "40px",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  cursor: state.clickable ? "pointer" : "default",
  ...(state.active && {
    backgroundColor: "var(--ion-color-primary)",
    opacity: 1,
  }),
  ...(state.completed && {
    backgroundColor: "var(--ion-color-primary)",
    opacity: 1,
  }),
}));

const CustomStepIcon = (props) => {
  const { active, completed, clickable, stepperOrientation } = props;

  return (
    <CustomStepIconRoot state={{ completed, active, clickable, stepperOrientation }}>
      {<BLIcon iconSource={props.icon} />}
    </CustomStepIconRoot>
  );
};

/**
 * Steps should be passed as array with objects in format:
 *
 * {label: string, icon: iconSource}
 *
 * For custom icons the component will need extention.
 * 
 * 
 * If clickable property is set to true the steps can be changed by clicking on them.
 * 
 * 
 **/

const BLStepper = ({ steps, activeStep, setActiveStep, clickable, orientation="horizontal", newClasses }) => {
  const isVertical = orientation === "vertical";

  return (
    <Stepper
      className={newClasses}
      alternativeLabel={isVertical ? false : true}
      activeStep={activeStep || 0}
      connector={isVertical ? <VerticalConnector /> :<HorizontalConnector />}
      orientation={orientation}
    >
      {steps?.map((step, index) => (
        <Step key={`${step.label}-${index}`}>
          <StepLabel
            onClick={() => clickable && setActiveStep(index)}
            StepIconComponent={CustomStepIcon}
            StepIconProps={{ icon: step.icon, clickable, orientation }}
            sx={stepLabelProps}
          >
            {step.label ? step.label : null}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default BLStepper;
