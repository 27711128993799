import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { DiningBookingContext } from "../../../../../context/DiningBookingContext";
import { NavigatorContext } from "../../../../../context/NavigatorContext";

import { arrowForwardOutline } from "ionicons/icons";
import {
  ACTION_TYPES,
  ITEM_POPULAR_STATUSES,
  SERVICES_TYPES
} from "../../../../../constants/constants";
import { ConflictManagementContext } from "../../../../../context/ConflictManagementContext";
import { useDataContext } from "../../../../../context/DataContext";
import { getDayOfWeek } from "../../../../../helpingFunctions/dateTimeUtilities";
import { ROUTE_VIEWS } from "../../../../../routes/routeViews";
import {
  BOOKING_SELECTIONS_CANCEL,
  BOOKING_SELECTIONS_CONFIRM,
  BOOKING_SELECTIONS_GO_BACK,
  BOOKING_SELECTIONS_PREVIEW,
  DINING_SELECTIONS_ATTR,
} from "../../DiningBookingConstants";

const StayNavigatorLogic = () => {
  const {
    selectedDateSelections,
    setSelectedDateSelections,
    diningBookingSelectionsPreview: { selections, mainStatus },
    handleDateChange,
    diningBookingSelections,
    setDiningBookingSelections,
    datesList,
    viewType,
    setDiningBookingSelectionsPreview,
    prepareRestaurantAndTimeOptions,
    preparePreviewScreenData,
    // treatsByCurrentView,
    setGroupDetachmentConfirmDialog,
  } = useContext(DiningBookingContext);
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const {
    reservationDetails,
    isLoadingHotelData,
    isLoadingItinerary,
    isLoadingAvailability,
  } = useDataContext();
  const history = useHistory();
  const [showAddOnPrompDialog, setShowAddOnPrompDialog] = useState(false);
  const [navButtons, setNavButtons] = useState(null);
  const { findPageConflicts } = useContext(ConflictManagementContext);

  const getContinueMessage = () => {
    try {
      if (mainStatus === ITEM_POPULAR_STATUSES.FAILURE) {
        const firstFailedSelection = selections.find(
          (selection) => selection.status === ITEM_POPULAR_STATUSES.FAILURE
        );
        const firstFailedSelectionDate =
          firstFailedSelection.Date.split("T")[0];

        return {
          icon: arrowForwardOutline,
          type: "primary-icon-text",
          txt: `${getDayOfWeek(firstFailedSelectionDate)}`,
          showDay: async () => {
            setDiningBookingSelectionsPreview({ selections: [] });
            prepareRestaurantAndTimeOptions();
            handleDateChange(firstFailedSelectionDate);
          },
        };
      }

      const nextView =
        viewType === SERVICES_TYPES.BREAKFAST
          ? SERVICES_TYPES.DINNER
          : SERVICES_TYPES.BREAKFAST;
      const isBookedView = reservationDetails[nextView];

      return !isBookedView
        ? {
          icon: arrowForwardOutline,
          txt: nextView,
          route: nextView,
          type: "primary-icon-text",
        }
        : {
          icon: arrowForwardOutline,
          txt: SERVICES_TYPES.ITINERARY,
          route: ROUTE_VIEWS.ITINERARY,
          type: "primary-icon-text",
        };
    } catch (error) {
      console.error(
        `${new Date()} Error in getContinueMessage func in StayNavigatorLogic.js ${error.message}`
      );
    }
  };

  const clearAllSelections = () => {
    try {
      for (let index = 0; index < diningBookingSelections.length; index++) {
        const element = diningBookingSelections[index];
        element[DINING_SELECTIONS_ATTR.RESTAURANT] = null;
        element[DINING_SELECTIONS_ATTR.TIME] = null;
      }

      setDiningBookingSelectionsPreview({ selections: [] });
      setDiningBookingSelections([]);
      handleDateChange(datesList[0]);
    } catch (error) {
      console.error(
        `${new Date()} Error in clearAllSelections func in StayNavigatorLogic.js ${error.message}`
      );
    }
  };

  // const prompAddOnMessage = () => {
  //   let promp = 0;

  //   selections.forEach((row) => {
  //     if (
  //       row.ReservationNumber === reservationDetails.ReservationNumber &&
  //       row.treatAddOns.length > 0
  //     ) {
  //       promp++;
  //     }
  //   });

  //   return promp;
  // };

  const syncSelections = () => {
    try {

      // TODO #6599: Hiding treats in dinner page
      // If this is uncommented the function must accept parameter : skipAddOnsCheck
      // if (
      //   [SERVICES_TYPES.DINNER].includes(viewType) &&
      //   mainStatus &&
      //   treatsByCurrentView.treatsCount > 0 &&
      //   !skipAddOnsCheck
      // ) {
      //   const prompForAddOns = prompAddOnMessage();
      //   const areAllCxlations = selections.filter(
      //     (row) => row.action === "remove"
      //   );
      //   if (
      //     prompForAddOns === 0 &&
      //     areAllCxlations.length !== selections.length
      //   ) {
      //     setShowAddOnPrompDialog(true);
      //     return;
      //   }
      // }

      // When the user hits 'Confirm' on the preview screen, first step would be to evaluate for conflicts
      // Whether there are conflicts or not, the 'handleBooking()' method will be called via useEffect in the DiningBookingContext

      //If the reservation is dining party participant, show the detachment confirmation dialog
      if (!reservationDetails.IsGroupCreator && reservationDetails.PartOfGroup) {
        setGroupDetachmentConfirmDialog(true);
      } else {
        findPageConflicts(selections);
      }
      // handleBooking();
    } catch (error) {
      console.error(
        `${new Date()} Error in syncSelections func in StayNavigatorLogic.js ${error.message}`
      );
    }
  };

  const getActionBtnDisabilityStatus = () => {
    const changedItems = diningBookingSelections.filter(
      (sel) => sel.action !== ACTION_TYPES.NONE
    );

    return changedItems.length === 0;
  };

  const getPreviewBtnDisabilityStatus = () => {
    // If Hotel details, Itinerary or Availability is loading - disable
    if (
      isLoadingHotelData ||
      isLoadingItinerary ||
      isLoadingAvailability
    ) {
      return true;
    }

    // Disable until 1 full selection or 1 full change (edit mode) is present
    if (
      diningBookingSelections.filter((sel) => sel.action !== ACTION_TYPES.NONE)
        .length === 0
    ) {
      return true;
    }
  };

  const diningButtons = () => {
    try {
      switch (mainStatus) {
        case ITEM_POPULAR_STATUSES.SUCCESS:
          {
            const { icon, txt, route, type } = getContinueMessage();
            setNavButtons([
              {
                icon: icon,
                txt: txt,
                type: type,
                onClick: () => {
                  setDiningBookingSelectionsPreview({ selections: [] });
                  if (route.includes(SERVICES_TYPES.BREAKFAST) || route.includes(SERVICES_TYPES.DINNER)) {
                    setSelectedDateSelections({});
                  }
                  redirectBetweenPages(route);
                },
                disabled: false,
                dimensions: { margin: ["l-s"] },
              },
            ]);
          }
          return;
        case ITEM_POPULAR_STATUSES.LOAD:
        case ITEM_POPULAR_STATUSES.INIT:
          setNavButtons([
            {
              txt: BOOKING_SELECTIONS_GO_BACK,
              type: "underlined",
              onClick: () => {
                setDiningBookingSelectionsPreview({ selections: [] });
              },
              disabled:
                ITEM_POPULAR_STATUSES.LOAD === mainStatus ? true : false,
              dimensions: {},
            },

            {
              txt: BOOKING_SELECTIONS_CONFIRM,
              type: "primary",
              onClick: () => syncSelections(),
              disabled:
                ITEM_POPULAR_STATUSES.LOAD === mainStatus
                  ? true
                  : getActionBtnDisabilityStatus(),
              dimensions: { margin: ["l-s"] },
            },
          ]);
          return;
        case ITEM_POPULAR_STATUSES.FAILURE:
          const { icon, txt, showDay, type } = getContinueMessage();
          setNavButtons([
            {
              icon: icon,
              txt: txt,
              type: type,
              onClick: () => showDay(),
              disabled: false,
              dimensions: { margin: ["l-s"] },
            },
          ]);
          return;
        default:
          break;
      }

      const defaultBtns = [
        {
          txt: BOOKING_SELECTIONS_CANCEL,
          onClick: () => redirectBetweenPages(history.location.state.isItineraryRedirect ? ROUTE_VIEWS.ITINERARY
            : ROUTE_VIEWS.DINING),
          type: "underlined",
          disabled: false,
        }
      ];

      if (selectedDateSelections && Object.keys(selectedDateSelections).length > 0) {
        defaultBtns.push({
          txt: BOOKING_SELECTIONS_PREVIEW,
          onClick: () => preparePreviewScreenData(
            diningBookingSelections,
            ITEM_POPULAR_STATUSES.INIT
          ),
          type: "primary",
          disabled: getPreviewBtnDisabilityStatus(),
          dimensions: { margin: ["l-s"] },
        });
      }

      setNavButtons(defaultBtns);
    } catch (error) {
      console.error(
        `${new Date()} Error in diningButtons func in StayNavigatorLogic.js file ${error.message}`
      );
    }
  };

  useEffect(() => {
    diningButtons();
  }, [selectedDateSelections, diningBookingSelections, mainStatus]);

  return {
    showAddOnPrompDialog,
    navButtons,
    syncSelections,
    setShowAddOnPrompDialog,
    clearAllSelections,
  };
};

export default StayNavigatorLogic;
