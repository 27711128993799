import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { pricetag, time } from "ionicons/icons";
import React from "react";
import BLIcon from "../../BLIcon";
import BLParagraph from "../../Paragraph/BLParagraph";
import BLContentCardWrapper from "../../Wrappers/BLContentCardWrapper";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../../../../constants/constants";
import { useTheme } from "../../../../hooks/useTheme";
import { formatDuration } from "../../../../helpingFunctions/formatDuration";

const BookedDetailsCardDesktop = ({
  serviceDetails,
  daySelections,
  priceTxt,
  headerTxt,
  source,
}) => {
  const { typography } = useTheme();
  return (
    <IonGrid>
      {headerTxt && (
        <IonRow className="ion-text-center bl-margin-t-m">
          <IonCol>
            <BLParagraph
              txt={headerTxt}
              textVariant={typography.h4}
              //newClasses="small bold"
              //TODO: Unclear typography
              color="primary"
            />
          </IonCol>
        </IonRow>
      )}

      <IonRow>
        <IonCol>
          <BLContentCardWrapper
            background="white"
            dimensions={{ margin: ["t-m"], padding: ["s"] }}
            newStyles={{
              flexDirection: "column",
              gap: "15px",
              display: "flex",
            }}
          >
            <IonGrid
              style={{
                gap: source === MAESTRO_BACKEND_NAMING_VIEWS.SPA ? "8px" : "10px",
                display: "grid",
              }}
            >
              {daySelections.map((item, index) => (
                <IonRow key={index}>
                  <IonCol className="bl-flex ion-align-items-center">
                    {item}
                  </IonCol>
                </IonRow>
              ))}

              <IonRow className="ion-align-items-center bl-padding-l-s">
                <BLIcon color="primary" iconSource={time} iconSize="md" />
                <IonCol
                  className="bl-flex"
                  style={{ flexDirection: "column", gap: "5px" }}
                >
                  <BLParagraph
                    txt={formatDuration(serviceDetails.Duration)}
                    color="text-grey"
                    textVariant={typography.h4}
                    dimensions={{
                      margin: ["l-s"],
                      padding: ["t-md", "b-md"],
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow className="ion-align-items-center bl-padding-l-s">
                <BLIcon
                  color="primary"
                  iconSource={pricetag}
                  iconSize="md"
                />
                <IonCol
                  className="bl-flex"
                  style={{ flexDirection: "column", gap: "5px" }}
                >
                  {priceTxt}
                </IonCol>
              </IonRow>
            </IonGrid>
          </BLContentCardWrapper>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default BookedDetailsCardDesktop;
