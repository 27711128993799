import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import React from "react";

import { ACTION_TYPES } from "../../../../../../constants/constants";
import { formatTime } from "../../../../../../helpingFunctions/dateTimeUtilities";

import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";

import { duplicate } from "ionicons/icons";
import { useDataContext } from "../../../../../../context/DataContext";
import { getRestaurantNameById } from "../../../../../../helpingFunctions/facilityAndServices";
import BLDateTimeFormatParagraph from "../../../../../../shared/Ionic/Paragraph/BLDateTimeFormatParagraph";
import styles from "./BookedDayDetails.module.scss";
import { isDesktop } from "../../../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../../../hooks/useTheme";

const BookedDayDetails = ({ selDetails, view }) => {
  const { typography } = useTheme();
  const { facilitiesDataDetails, notifyIconDisplay, dynamicTexts } =
  useDataContext();

  const getContentSummary = (action) => {
    if (action === ACTION_TYPES.NONE) {
      return (
        <IonRow className={styles.BookedDayDetails_time_venue_details}>
          <IonCol size="auto">
            <BLParagraph
              txt={dynamicTexts?.Shared?.PreviewScreen?.Paragraph_PreviewScreen_NoChangesMadeInfo
              }
              textVariant={typography.BODY_TEXT}
              color="text-grey"
              markDown={
                !!dynamicTexts?.Shared?.PreviewScreen?.Paragraph_PreviewScreen_NoChangesMadeInfo
              }
            />
          </IonCol>
        </IonRow>
      );
    } else if (action === ACTION_TYPES.ADD) {
      const dateTime = `${selDetails.date}T${selDetails.time}`;

      return (
        <IonRow className={styles.BookedDayDetails_time_venue_details}>
          <IonCol size="auto">
            <BLParagraph
              txt={formatTime(dateTime.substring(0, 16), false, "p")}
              textVariant={typography.BODY_TEXT}
              color="text-grey"
            />
          </IonCol>
          <IonCol className="bl-padding-l-s">
            <BLParagraph
              txt={` ${getRestaurantNameById(
                facilitiesDataDetails,
                selDetails.restaurantId
              )}`}
              textVariant={typography.BODY_TEXT}
              color="text-grey"
            />
          </IonCol>
        </IonRow>
      );
    } else if (action === ACTION_TYPES.EDIT) {
      const dateTimeToBook = `${selDetails.date}T${selDetails.time}`;
      const dateTimeToCancell = `${selDetails.date}T${selDetails.bookedTime}`;

      return (
        <>
          <IonRow className={styles.BookedDayDetails_time_venue_details}>
            <IonCol size="auto">
              <BLParagraph
                txt={formatTime(dateTimeToCancell.substring(0, 16), false, "p")}
                textVariant={typography.BODY_TEXT}
                typographyStyles={{ textDecoration: "line-through" }}
                color="text-grey"
              />
            </IonCol>
            <IonCol className="bl-padding-l-s">
              <BLParagraph
                txt={` ${getRestaurantNameById(
                  facilitiesDataDetails,
                  selDetails.bookedRestaurantId
                )}`}
                textVariant={typography.BODY_TEXT}
                typographyStyles={{ textDecoration: "line-through" }}
                color="text-grey"
              />
            </IonCol>
          </IonRow>

          <IonRow className={styles.BookedDayDetails_time_venue_details}>
            <IonCol size="auto">
              <BLParagraph
                txt={formatTime(dateTimeToBook.substring(0, 16), false, "p")}
                textVariant={typography.BODY_TEXT}
                color="text-grey"
              />
            </IonCol>
            <IonCol className="bl-padding-l-s">
              <BLParagraph
                txt={` ${getRestaurantNameById(
                  facilitiesDataDetails,
                  selDetails.restaurantId
                )}`}
                textVariant={typography.BODY_TEXT}
                color="text-grey"
              />
            </IonCol>
          </IonRow>
        </>
      );
    } else if (action === ACTION_TYPES.NOT_REQ) {
      const hasBooking = selDetails.bookedTime;
      const dateTimeToCancell = `${selDetails.date}T${selDetails.bookedTime}`;

      return (
        <>
          {hasBooking && (
            <IonRow className={styles.BookedDayDetails_time_venue_details}>
              <IonCol size="auto">
                <BLParagraph
                  txt={formatTime(
                    dateTimeToCancell.substring(0, 16),
                    false,
                    "p"
                  )}
                  textVariant={typography.BODY_TEXT}
                  typographyStyles={{ textDecoration: "line-through" }}
                  color="text-grey"
                />
              </IonCol>
              <IonCol className="bl-padding-l-s">
                <BLParagraph
                  txt={` ${getRestaurantNameById(
                    facilitiesDataDetails,
                    selDetails.bookedRestaurantId
                  )}`}
                  textVariant={typography.BODY_TEXT}
                  typographyStyles={{ textDecoration: "line-through" }}
                  color="text-grey"
                />
              </IonCol>
            </IonRow>
          )}

          <IonRow className={styles.BookedDayDetails_time_venue_details}>
            <IonCol size="auto">
              <BLParagraph
                txt={
                  dynamicTexts?.Shared?.PreviewScreen?.Paragraph_PreviewScreen_NoBookingNeededInfo
                }
                textVariant={typography.BODY_TEXT}
                color="text-grey"
                markDown={
                  !!dynamicTexts?.Shared?.PreviewScreen?.Paragraph_PreviewScreen_NoBookingNeededInfo
                }
              />
            </IonCol>
          </IonRow>
        </>
      );
    } else if (action === ACTION_TYPES.REMOVE) {
      const dateTime = `${selDetails.date}T${selDetails.time}`;

      return (
        <IonRow className={styles.BookedDayDetails_time_venue_details}>
          <IonCol size="auto">
            <BLParagraph
              txt={formatTime(dateTime.substring(0, 16), false, "p")}
              textVariant={typography.BODY_TEXT}
              typographyStyles={{ textDecoration: "line-through" }}
              color="text-grey"
            />
          </IonCol>
          <IonCol className="bl-padding-l-s">
            <BLParagraph
              txt={` ${getRestaurantNameById(
                facilitiesDataDetails,
                selDetails.restaurantId
              )}`}
              textVariant={typography.BODY_TEXT}
              typographyStyles={{ textDecoration: "line-through" }}
              color="text-grey"
            />
          </IonCol>
        </IonRow>
      );
    } else {
      return (
        <IonRow className={styles.BookedDayDetails_time_venue_details}>
          <IonCol size="auto">
            <BLParagraph
              txt={"Something when wrong " + action}
              textVariant={typography.BODY_TEXT}
              typographyStyles={{ textDecoration: "line-through" }}
              color="text-grey"
            />
          </IonCol>
        </IonRow>
      );
    }
  };

  return (
    <>
      <BLContentCardWrapper
        background="white"
        dimensions={{
          padding: ["t-s", "b-s", "l-s", "r-s"],
          margin: ["t-s"],
        }}
      >
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol>
              <BLDateTimeFormatParagraph
                date={selDetails.date}
                view={view}
                dimensions={{}}
              />
            </IonCol>

            {selDetails.status && !isDesktop() && (
              <IonCol size="auto">
                {notifyIconDisplay(
                  selDetails.status || selDetails.statusCancel
                )}
              </IonCol>
            )}
          </IonRow>
          {getContentSummary(selDetails.action)}

          {selDetails.treatAddOns?.map((addOn, index) => (
            <IonRow
              className={`${
                index === 0 ? "bl-margin-t-xs" : ""
              } bl-padding-t-xs ion-align-items-center`}
              style={{
                borderTop:
                  index === 0 ? "1px solid var(--ion-color-primary)" : "",
              }}
              key={`bookedDay_${index}`}
            >
              <IonIcon
                style={{ width: "18px", height: "18px" }}
                icon={duplicate}
                color="primary"
              />

              <BLParagraph
                txt={
                  <>
                    <BLParagraph
                      txt="Add on: "
                      textVariant={typography.BODY_TEXT}
                      typographyStyles={addOn.status.toLowerCase() === ACTION_TYPES.REMOVE ? { textDecoration: "line-trough" } : {}}
                      // newClasses={`${
                      //   addOn.status.toLowerCase() === ACTION_TYPES.REMOVE &&
                      //   "line"
                      // } bold x-small`}
                      //TODO: Unclear typography
                      color="text-grey"
                    />
                    <BLParagraph
                      txt={`${addOn.Quantity} x ${addOn.Title}`}
                      textVariant={typography.BODY_TEXT}
                      typographyStyles={addOn.status.toLowerCase() === ACTION_TYPES.REMOVE ? { textDecoration: "line-trough" } : {}}
                      color="text-grey"
                    />
                  </>
                }
                dimensions={{ padding: ["l-xs"] }}
              />
            </IonRow>
          ))}
        </IonGrid>
      </BLContentCardWrapper>
    </>
  );
};

export default BookedDayDetails;
