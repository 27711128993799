import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import {
  SERVICES_TYPES,
  INITIAL_DATE,
  NO_BOOKING_REQUIRED,
} from "../../../../../../constants/constants";
import {
  formatDateDetailed,
  formatTime,
  getDatesBetween,
} from "../../../../../../helpingFunctions/dateTimeUtilities";

import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";

import { shows } from "../../../../../../assets/SVGIcons";
import { useDataContext } from "../../../../../../context/DataContext";
import { EntertainmentsPageContext } from "../../../EntertainmentsPageContext";
import styles from "./BookedDayDetails.module.scss";
import { useTheme } from "../../../../../../hooks/useTheme";

const BookedDayDetailsInitial = ({ date }) => {
  const { typography } = useTheme();
  const { reservationDetails } = useDataContext();
  const { entertainmentBookingSelections } = useContext(
    EntertainmentsPageContext
  );

  const dateBookingSelections = entertainmentBookingSelections.filter(
    (sel) => sel.date === date
  );

  const datesList = getDatesBetween(
    reservationDetails.ArrivalDate,
    reservationDetails.DepartureDate,
    SERVICES_TYPES.ENTERTAINMENT
  );

  return (
    <>
      <BLContentCardWrapper
        background="white"
        dimensions={{
          padding: ["t-s", "b-s", "l-s", "r-s"],
          margin: ["t-s"],
        }}
      >
        <IonGrid>
          <IonRow>
            <IonCol>
              <BLParagraph
                txt={formatDateDetailed(datesList, date)}
                color="primary"
                textVariant={typography.BODY_TEXT}
              />
            </IonCol>
          </IonRow>
          {dateBookingSelections.map((booking) => (
            <IonRow key={booking.time} className="ion-justify-content-between">
              <IonCol size="12">
                <IonGrid>
                  <IonRow
                    className={styles.BookedDayDetails_time_venue_details}
                  >
                    <IonCol size="auto" style={{ paddingRight: "16px" }}>
                      <BLParagraph
                        txt={formatTime(
                          `${INITIAL_DATE}T${booking.time.slice(0, -9)}`,
                          true
                        )}
                        textVariant={typography.BODY_TEXT}
                        // newClasses={`x-small bold`}
                        //TODO: Unclear typography
                        color="text-grey"
                      />
                    </IonCol>
                    <IonCol className="ion-no-padding">
                      <BLParagraph
                        txt={booking.location}
                        textVariant={typography.BODY_TEXT}
                        // newClasses={`x-small bold`}
                        //TODO: Unclear typography
                        color="text-grey"
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow
                    className={styles.BookedDayDetails_time_venue_details}
                  >
                    <IonCol style={{ flex: "0 0 90px", paddingLeft: "25px" }}>
                      <BLParagraph
                        dimensions={{ padding: ["l-s", "r-s"] }}
                        txt={shows(32, "var(--ion-color-primary)")}
                        textVariant={typography.BODY_TEXT}
                        color="text-grey"
                      />
                    </IonCol>
                    <IonCol className="ion-no-padding ion-align-self-center">
                      <BLParagraph
                        txt={booking.title}
                        textVariant={typography.BODY_TEXT}
                        color="text-grey"
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          ))}
          {dateBookingSelections.length === 0 && (
            <IonRow>
              <BLParagraph
                txt={NO_BOOKING_REQUIRED}
                textVariant={typography.BODY_TEXT}
                // newClasses={`x-small bold`}
                //TODO: Unclear typography
                color="text-grey"
              />
            </IonRow>
          )}
        </IonGrid>
      </BLContentCardWrapper>
    </>
  );
};

export default BookedDayDetailsInitial;
