import { IonBackdrop, IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";
import BLLoadingContainer from "../BLLoadingContainer";
import BLParagraph from "../Paragraph/BLParagraph";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import styles from "./BLLoader.module.scss";
import { useTheme } from "../../../hooks/useTheme";

/**
 * @returns  Backdrop with absolute positined loader.
 *
 * Called on global level.
 *
 * This custom loader is implemented and should be used troughout the application,
 *  because the Ionic one (IonLoading) has state issues and produces bugs.
 * It is controlled by it's own context: PageLoaderContext
 *
 */

const BLLoader = () => {
  const { typography } = useTheme();
  const { isLoaderOpened, loaderMessage } = useContext(PageLoaderContext);
  return (
    isLoaderOpened && (
      <>
        <IonBackdrop visible className={styles.BLLoader_backdrop} />
        <IonGrid className={styles.BLLoader_content}>
          <IonRow className="bl-flex ion-align-items-center bl-padding-m">
            <IonCol size="auto" className="bl-margin-r-s">
              <BLLoadingContainer type="lines" newClasses="" />
            </IonCol>
            <IonCol>
              <BLParagraph 
                txt={loaderMessage} 
                //newClasses="bold" 
                //TODO: Unclear typography
                textVariant={typography.h5}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
    )
  );
};

export default BLLoader;
