import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";

import { ACTION_TYPES, SERVICES_TYPES } from "../../../../../../constants/constants";
import { INITIAL_DATE } from "../../../../../../constants/constants";
import { formatDateDetailed, formatTime, getDatesBetween } from "../../../../../../helpingFunctions/dateTimeUtilities";

import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";

import { shows } from "../../../../../../assets/SVGIcons";
import { useTheme } from "../../../../../../hooks/useTheme";
import { useDataContext } from "../../../../../../context/DataContext";
import styles from "./BookedDayDetails.module.scss";
import { isDesktop } from "../../../../../../helpingFunctions/utilities";

const BookedDayDetails = ({ entertainmentBookingSelection }) => {
  const { typography } = useTheme();
  const { notifyIconDisplay, reservationDetails } = useDataContext();

  const datesList = getDatesBetween(
    reservationDetails.ArrivalDate,
    reservationDetails.DepartureDate,
    SERVICES_TYPES.ENTERTAINMENT
  );

  return (
    <>
      <BLContentCardWrapper
        background="white"
        dimensions={{
          padding: ["t-s", "b-s", "l-s", "r-s"],
          margin: ["t-s"],
        }}
      >
        <IonGrid>
          <IonRow>
            <IonCol>
              <BLParagraph
                txt={formatDateDetailed(datesList, entertainmentBookingSelection?.date)}
                color="primary"
                textVariant={typography.BODY_TEXT}
              />
            </IonCol>
            {!isDesktop() && entertainmentBookingSelection?.status && (
              <IonCol size="auto">
                {notifyIconDisplay(entertainmentBookingSelection?.status)}
              </IonCol>
            )}
          </IonRow>
          <IonRow className="ion-justify-content-between">
            <IonCol size="12">
              <IonGrid>
                <IonRow className={styles.BookedDayDetails_time_venue_details}>
                  <IonCol size="auto" style={{ paddingRight: "16px" }}>
                    <BLParagraph
                      txt={formatTime(`${INITIAL_DATE}T${entertainmentBookingSelection?.time.slice(0, -9)}`, true)}
                      textVariant={typography.BODY_TEXT}
                      typographyStyles={entertainmentBookingSelection?.action ===
                          ACTION_TYPES.REMOVE ? { textDecoration: "line-through" } : {}}
                      // newClasses={`${
                      //   entertainmentBookingSelection?.action ===
                      //     ACTION_TYPES.REMOVE && "line"
                      // } x-small bold open-sans`}
                      //TODO: Unclear typography
                      color="text-grey"
                    />
                  </IonCol>
                  <IonCol className="ion-no-padding">
                    <BLParagraph
                      txt={entertainmentBookingSelection?.location}
                      textVariant={typography.BODY_TEXT}
                      typographyStyles={entertainmentBookingSelection?.action ===
                          ACTION_TYPES.REMOVE ? { textDecoration: "line-through" } : {}}
                      // newClasses={`${
                      //   entertainmentBookingSelection?.action ===
                      //     ACTION_TYPES.REMOVE && "line"
                      // } x-small bold open-sans`}
                      //TODO: Unclear typography
                      color="text-grey"
                    />
                  </IonCol>
                </IonRow>
                <IonRow className={styles.BookedDayDetails_time_venue_details}>
                  <IonCol style={{ flex: "0 0 90px", paddingLeft: "25px" }}>
                    <BLParagraph
                      dimensions={{ padding: ["l-s", "r-s"] }}
                      txt={shows(32, "var(--ion-color-primary)")}
                      textVariant={typography.BODY_TEXT}
                      color="text-grey"
                    />
                  </IonCol>
                  <IonCol className="ion-no-padding ion-align-self-center">
                    <BLParagraph
                      txt={entertainmentBookingSelection?.title}
                      textVariant={typography.BODY_TEXT}
                      typographyStyles={entertainmentBookingSelection?.action ===
                          ACTION_TYPES.REMOVE ? { textDecoration: "line-through" } : {}}
                      color="text-grey"
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </BLContentCardWrapper>
    </>
  );
};

export default BookedDayDetails;
