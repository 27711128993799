import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDataContext } from "../../../context/DataContext";
import { ROUTE_VIEWS } from "../../../routes/routeViews";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import ZoomImageContainer from "../../../shared/Ionic/ZoomImageContainer/ZoomImageContainer";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { SERVICES_TYPES } from "../../../constants/constants";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import { useTheme } from "../../../hooks/useTheme";
import ServicePageHeader from "../../../shared/Ionic/Wrappers/ServicePageHeader";

const MapPage = () => {
  const { typography } = useTheme();
  const { reservationHotelDetails } = useDataContext();
  const { warnerServices} = useContext(NavigatorContext);
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const location = useLocation();
  const headerRef = useRef(null);
  const gridRef = useRef(null);
  const descriptionRef = useRef(null);
  const [gridHeight, setGridHeight] = useState(null);
  const [descriptionHeight, setDescriptionHeight] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(null);

  useEffect(() => {
    if (warnerServices.length === 0) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Map Page");
    } else {
      setIsLoaderOpened(false);
    }
  }, [warnerServices]);

  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      switch (entry.target.id) {
        case "gridContainer":
          setGridHeight(entry.target.clientHeight);
          break;
        case "descriptionContainer":
          setDescriptionHeight(entry.target.clientHeight);
          break;
        case "headerContainer":
          setHeaderHeight(entry.target.clientHeight);
          break;
      }
    });
  });

  useEffect(() => {
    if (location.pathname.includes(ROUTE_VIEWS.MAP)) {
      resizeObserver.observe(gridRef.current);
      resizeObserver.observe(headerRef.current);
      resizeObserver.observe(descriptionRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <IonPage style={{ overflow: "auto" }}>
      <IonGrid id="gridContainer" ref={gridRef} style={{ height: "100%" }}>
        <IonGrid id="headerContainer" ref={headerRef}>
          <ServicePageHeader service={SERVICES_TYPES.MAP} />
        </IonGrid>
        <IonRow
          id="descriptionContainer"
          ref={descriptionRef}
          className="bl-padding-t-s bl-padding-r-s bl-padding-l-s"
        >
          <IonCol>
            <BLParagraph
              txt={reservationHotelDetails?.HotelMapDescription}
              markDown
              textVariant={typography.BODY_TEXT}
              color="text-grey"
            />
          </IonCol>
        </IonRow>
        {reservationHotelDetails &&
          reservationHotelDetails.HotelMapImage &&
          reservationHotelDetails.HotelMapImage.url && (
          <IonRow className="ion-padding">
            <IonCol>
              <ZoomImageContainer
                image={reservationHotelDetails?.HotelMapImage.url}
                containerHeight={
                  gridHeight - headerHeight - descriptionHeight - 32
                }
                // Here we have to substract the padding (sum of top and bottom) of the container as well
              />
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </IonPage>
  );
};

export default MapPage;
