import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import { useDataContext } from "../../../../../context/DataContext";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import ActionButtons from "../ActionButtons/ActionButtons";
import DiningPartyFormLogic from "./DiningPartyFormLogic";

import useDiningPartyRequests from "../../useDiningPartyRequests";

import { informationCircle } from "ionicons/icons";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";
import { NavigatorContext } from "../../../../../context/NavigatorContext";
import { ROUTE_VIEWS } from "../../../../../routes/routeViews";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import BLTooltip from "../../../../../shared/Ionic/Boxes/Popup/BLTooltip";
import BLInput from "../../../../../shared/Ionic/Inputs/BLInput";
import BLLabel from "../../../../../shared/Ionic/Inputs/BLLabel";
import AddMoreGuests from "./AddMoreGuests";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../../hooks/useTheme";
import { DINING_PARTY_NAME_INPUT, DINING_PARTY_VALIDATE_TEXT, VALIDATE_INPUTS } from "../../diningPartyConstants";

const DiningPartyForm = () => {
  const { typography } = useTheme();
  const { resetForm } = DiningPartyFormLogic();
  const { redirectBetweenPages } = useContext(NavigatorContext);

  const {
    dynamicTexts,
  } = useDataContext();
  const { showDiningPartyParticipantForm, isGroupActionLoading, diningPartyDetails, diningPartySelections } =
    useContext(DiningPartyContext);
  const { isFormFilled, validateReservation } = useDiningPartyRequests();

  const { dataFillHandler, diningPartyFormData } =
    useContext(DiningPartyContext);

  const surnameInputField = (
    <IonCol size={isDesktop() ? "6" : "12"} className="bl-margin-t-xs">
      <IonGrid>
        <IonRow>
          <BLLabel
            label={VALIDATE_INPUTS.inputs[0].label}
            newClasses={VALIDATE_INPUTS.inputs[0].labelNewClasses}
            labelDimensions={VALIDATE_INPUTS.inputs[0].labelDimensions}
            afterIconSource={informationCircle}
            afterIconTxt={dynamicTexts?.Dining?.DiningParty?.Tooltip_Surname}
            labelColor={VALIDATE_INPUTS.inputs[0].labelColor}
            labelLeftMargin="35px"
            labelClasses="bl-text-semibold bl-text-x-small"
          />
        </IonRow>
        <IonRow className="ion-align-items-center bl-margin-t-xs">
          <IonCol size="auto" className="bl-margin-r-xs">
            <BLIcon
              iconSource={VALIDATE_INPUTS.inputs[0].icon}
              color="primary"
            />
          </IonCol>
          <IonCol size="10">
            <BLInput
              placeholder={VALIDATE_INPUTS.inputs[0].placeHolder}
              type={VALIDATE_INPUTS.inputs[0].type}
              value={
                diningPartyFormData[VALIDATE_INPUTS.inputs[0].dataAttrType]
              }
              onChange={(e) =>
                dataFillHandler(
                  VALIDATE_INPUTS.inputs[0].dataAttrType,
                  e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                )
              }
              boxHeightClass={isDesktop() ? "height-md" : "height-sm"}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCol>
  );

  const bookingIdInputField = (
    <IonCol size={isDesktop() ? "6" : "12"} className="bl-margin-t-xs">
      <IonGrid>
        <IonRow>
          <BLLabel
            label={VALIDATE_INPUTS.inputs[1].label}
            newClasses={VALIDATE_INPUTS.inputs[1].labelNewClasses}
            labelDimensions={VALIDATE_INPUTS.inputs[1].labelDimensions}
            afterIconSource={informationCircle}
            afterIconTxt={dynamicTexts?.Dining?.DiningParty?.Tooltip_ReferenceNumber}
            labelColor={VALIDATE_INPUTS.inputs[1].labelColor}
            labelLeftMargin="35px"
            labelClasses="bl-text-semibold bl-text-x-small"
          />
        </IonRow>
        <IonRow className="ion-align-items-center bl-margin-t-xs">
          <IonCol size="auto" className="bl-padding-r-xs">
            <BLIcon
              iconSource={VALIDATE_INPUTS.inputs[1].icon}
              color="primary"
            />
          </IonCol>
          <IonCol size="10">
            <BLInput
              placeholder={VALIDATE_INPUTS.inputs[1].placeHolder}
              type={VALIDATE_INPUTS.inputs[1].type}
              value={
                diningPartyFormData[VALIDATE_INPUTS.inputs[1].dataAttrType]
              }
              onChange={(e) =>
                dataFillHandler(
                  VALIDATE_INPUTS.inputs[1].dataAttrType,
                  e.target.value
                )
              }
              boxHeightClass={isDesktop() ? "height-md" : "height-sm"}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCol>
  );

  return (
    <>
      {!diningPartyDetails.id && (
        <>
          {/* Group name */}

          <IonRow>
            <IonCol
              className="bl-margin-t-s"
              size={isDesktop() ? "7" : "12"}
            >
              <BLParagraph
                textVariant={typography.h4}
                //newClasses="small bold"
                //TODO: Unclear typography
                dimensions={{ margin: ["r-s"] }}
                txt={<>{DINING_PARTY_NAME_INPUT.mainLabel}</>}
                color="text-grey"
              />

              <BLTooltip
                icon={DINING_PARTY_NAME_INPUT.mainIcon}
                txt={dynamicTexts?.Dining?.DiningParty?.Tooltip_GroupName}
                iconClasses="bl-icon-sm"
              />
              <BLInput
                classes="bl-margin-t-xs"
                placeholder={DINING_PARTY_NAME_INPUT.inputs[0].placeHolder}
                type={DINING_PARTY_NAME_INPUT.inputs[0].type}
                value={
                  diningPartyFormData[DINING_PARTY_NAME_INPUT.inputs[0].dataAttrType]
                }
                onChange={(e) =>
                  dataFillHandler(
                    DINING_PARTY_NAME_INPUT.inputs[0].dataAttrType,
                    e.target.value
                  )
                }
                boxHeightClass={isDesktop() ? "height-md" : "height-sm"}
              />
            </IonCol>
          </IonRow>

          {!showDiningPartyParticipantForm && (
            <IonRow>
              <IonCol size="12">
                <AddMoreGuests />
              </IonCol>
            </IonRow>
          )}
        </>
      )}

      {showDiningPartyParticipantForm && (
        <>
          <IonRow className="bl-margin-t-s">
            <IonCol size="12">
              <BLParagraph
                textVariant={typography.h4}
                //newClasses="small bold"
                //TODO: Unclear typography
                dimensions={{ margin: ["r-s"] }}
                txt={<>{VALIDATE_INPUTS.mainLabel}</>}
                color="text-grey"
              />
            </IonCol>
          </IonRow>

          {isDesktop() ? (
            <>
              <IonRow>
                {surnameInputField}
                {bookingIdInputField}
              </IonRow>
            </>
          ) : (
            <>
              {/* Surname */}
              <IonRow>{surnameInputField}</IonRow>

              {/* Booking id */}
              <IonRow>{bookingIdInputField}</IonRow>
            </>
          )}

          <ActionButtons
            actionTxt={DINING_PARTY_VALIDATE_TEXT}
            shouldDisable={!isFormFilled}
            isActionInProgress={isGroupActionLoading}
            cancelFunction={() => diningPartySelections.participants.length > 0 ? resetForm() : redirectBetweenPages(ROUTE_VIEWS.DINING)}
            actionFunction={validateReservation}
            cancelTxt={diningPartySelections.participants.length > 0 ? "Cancel" : "Go back"}
          />
        </>
      )}
    </>
  );
};

export default DiningPartyForm;
