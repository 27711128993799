import React, { createContext, useEffect, useState } from "react";
import {  INITIAL_DATE } from "../constants/constants";
import {
  formatDateDetailed,
  formatTime,
  getDatesBetween,
  getDayOfStay,
  getDayPeriod,
} from "../helpingFunctions/dateTimeUtilities";
import { useDataContext } from "./DataContext";
export const SpaContext = createContext({});

export const SpaProvider = ({ children }) => {
  const {
    itineraryDetails,
    facilitiesData,
    reservationDetails,
  } = useDataContext();
  const [isEditMode, setIsEditMode] = useState(false); 
  const [confirmationScreenSelections, setConfirmationScreenSelections] =
    useState(null);
  const [isProcessingBookings, setIsProcessingBookings] = useState(false);
  const [daySelections, setDaySelections] = useState(null);
  const [priceMsg, setPriceMsg] = useState(null);
  const [option, setOption] = useState(null);
  const [showBookingSection, setShowBookingSection] = useState(false);
  const [spaAvailability, setSpaAvailability] = useState(null);
  const [spaSelections, setSpaSelections] = useState([]);
  const [showContraindications, setShowContraindications] = useState(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [spaDetails, setSpaDetails] = useState(null);

  const extractDaySelections = () => {
    const stayingDates = getDatesBetween(
      reservationDetails?.ArrivalDate,
      reservationDetails?.DepartureDate
    );

    const daySels = [];
    stayingDates.forEach((date) => {
      daySels.push({
        Title: formatDateDetailed(stayingDates, date, true),
        fullTitle: formatDateDetailed(stayingDates, date),
        dayOfStay: getDayOfStay(stayingDates, date),
        view: "Spa",
        id: date,
      });
    });

    setDaySelections(daySels);
  };

  useEffect(() => {
    if (reservationDetails) extractDaySelections();
  }, [reservationDetails]);


  useEffect(() => { 
    if (facilitiesData) {
      setSpaDetails(facilitiesData.Spa);
    }
  }, [facilitiesData]);

  const handleProviderCode = (
    spaAvailabilityCopy,
    day,
    dayTime,
    time,
    action,
    providerCode
  ) => {
    const dayTimeArr =  spaAvailabilityCopy[day][dayTime];
    const availIndex = dayTimeArr.findIndex(
      (row) => row.time === time
    );

    if (availIndex !== -1) {
      if (action === "increment") {
        dayTimeArr[availIndex].availability++;
        dayTimeArr[availIndex].providerCodes.push(
          providerCode
        );
      } else if (action === "decrement") {
        dayTimeArr[availIndex].availability--;
        const newlySelProvCode =
        dayTimeArr[availIndex].providerCodes.pop();
        return newlySelProvCode;
      } else if (action === "decrement-spec") {
        dayTimeArr[availIndex].availability--;
        const index = dayTimeArr[
          availIndex
        ].providerCodes.findIndex((row) => row === providerCode);
        if (index !== -1) {
          dayTimeArr[availIndex].providerCodes.splice(
            index,
            1
          );
        }
      }
    } else if (action === "increment" && availIndex === -1) {
     
      const shortTime = formatTime(
        `${INITIAL_DATE}T${time.substring(0, 5)}`,
        "h:mm"
      );
      const availabilityObj = {
        availability: 1,
        providerCodes: [providerCode],
        time: time,
        shortTime: shortTime.replace("PM", "")
      };
     
      dayTimeArr.push(availabilityObj);
    }
    
  };

  const adjustSpaAvailability = (date, data, serviceId) => {
    const daySpaItinerary = itineraryDetails.Dates[date]?.Spa.filter(
      (row) => row.Id === serviceId
    );

    daySpaItinerary.forEach((item) => {
      const time = item.Time;
      const shortTime = formatTime(
        `${INITIAL_DATE}T${time.substring(0, 5)}`,
        "h:mm"
      );
      const dayTime = getDayPeriod(`${INITIAL_DATE}T${time.substring(0, 5)}`);

      const hasTimeObj = data[dayTime]?.find((row) => row.time === time);

      if (!hasTimeObj) {
        data[dayTime].push({
          availability: 0,
          providerCodes: [],
          shortTime: shortTime.split(" ")[0],
          time: time,
        });
      }
    });

    data.AM?.sort((a, b) => {
      return a.time.localeCompare(b.time);
    });

    data.PM?.sort((a, b) => {
      return a.time.localeCompare(b.time);
    });
  };

  const cancelChangesHandler = () => {
    let spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    const spaAvailabilityCopy = JSON.parse(JSON.stringify(spaAvailability));

    spaSelectionsCopy = spaSelectionsCopy.filter((row) => row.action !== "add");
    spaSelectionsCopy.forEach((row) => {
      const providerCode = row.providerCode;
      if (providerCode !== "" && row.action === "add") {
        const day = row.date;
        const time = row.time;
        const dayTime = time.substring(0, 2) >= "12" ? "PM" : "AM";
        handleProviderCode(
          spaAvailabilityCopy,
          day,
          dayTime,
          time,
          "increment",
          providerCode
        );
      }
      row.on = row.on.filter((dayTimeRow) => dayTimeRow.action !== "add");
    });

    spaSelectionsCopy.forEach((sel) => {
      sel.fName = sel.bookedFName;
      sel.lName = sel.bookedLName;
      sel.isFullName = true;
      sel.guestName = `${sel.fName} ${sel.lName}`;
      sel.action = "none";
      sel.on.forEach((dayTimeSel) => {
        const providerCode = dayTimeSel.providerCode;
        if (providerCode !== dayTimeSel.bookedProviderCode) {
          const day = dayTimeSel.date;
          const time = dayTimeSel.time;
          const dayTime = time.substring(0, 2) >= "12" ? "PM" : "AM";

          if (providerCode !== "") {
            handleProviderCode(
              spaAvailabilityCopy,
              day,
              dayTime,
              time,
              "increment",
              providerCode
            );
          }

          const dayTimeBooked =
            dayTimeSel.bookedTime.substring(0, 2) >= "12" ? "PM" : "AM";
          handleProviderCode(
            spaAvailabilityCopy,
            dayTimeSel.bookedDate,
            dayTimeBooked,
            dayTimeSel.bookedTime,
            "decrement-spec",
            dayTimeSel.bookedProviderCode
          );
        }

        dayTimeSel.date = dayTimeSel.bookedDate;
        dayTimeSel.time = dayTimeSel.bookedTime;
        dayTimeSel.providerCode = dayTimeSel.bookedProviderCode;
        dayTimeSel.action = "none";
      });
    });

    setSpaAvailability(spaAvailabilityCopy);
    setSpaSelections(spaSelectionsCopy);
    setIsEditMode(false);
  };

  return (
    <SpaContext.Provider
      value={{
        spaDetails,
        spaAvailability,
        spaSelections,
        showContraindications,
        setShowContraindications,
        setSpaSelections,
        setSpaAvailability,
        handleProviderCode,
        adjustSpaAvailability,
        showAdditionalDetails,
        setShowAdditionalDetails,
        cancelChangesHandler,
        option,
        setOption,
        showBookingSection,
        setShowBookingSection,
        confirmationScreenSelections,
        setConfirmationScreenSelections,
        isProcessingBookings,
        setIsProcessingBookings,
        daySelections,
        setDaySelections,
        priceMsg,
        setPriceMsg,
        isEditMode,
        setIsEditMode,
      }}
    >
      {children}
    </SpaContext.Provider>
  );
};
