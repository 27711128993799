import { ITEM_POPULAR_STATUSES } from "../../../constants/constants";

const RESTAURANT_MENU_LINK_TEXT = "Sample Menu";

const TREATS_ALERT_MESSAGE =
  "Please note that by clicking add on the dining booking screen you are adding this treat to that particular day.";

const GROUP_DINING_CHANGES_MESSAGE =
  "These changes will apply to all dining party participants.";

const BOOKING_SELECTIONS_CONFIRM = "Confirm";
const BOOKING_SELECTIONS_CONTINUE = "Continue";
const BOOKING_SELECTIONS_BACK = "Back";
const BOOKING_SELECTIONS_CANCEL = "Cancel";
const BOOKING_SELECTIONS_CLOSE = "Close";
const BOOKING_SELECTIONS_ITINERARY = "View in Itinerary";
const BOOKING_SELECTIONS_GO_BACK = "Go back";
const BOOKING_SELECTIONS_CHANGE = "Change";
const BOOKING_SELECTIONS_PREVIEW = "Preview";

const MAESTRO_BOOKING_TYPE = "activity";

const PREVIEW_INFO_MESSAGES = {
  [ITEM_POPULAR_STATUSES.INIT]:
    "Below is the summary of your booking. Please review and confirm.",
  [ITEM_POPULAR_STATUSES.LOAD]: "We are verifying your booking.",
  [ITEM_POPULAR_STATUSES.SUCCESS]:
    "You have successfully made your breakfast booking",
  [ITEM_POPULAR_STATUSES.FAILURE]: "Something went wrong",
};

const TREATS_TYPE_FOR_DISPLAY = {
  MAIN_TREATS: "mainTreats",
  OTHER_TREATS: "otherTreats",
};
const TREATS_VIEW_MODES = {
  VIEW_ALL: "View All",
  VIEW_LESS: "View Less",
};

const DINING_SELECTIONS_ATTR = {
  RESTAURANT: "restaurantId",
  TIME: "time",
  ACTION: "action",
  TREATS: "treatAddOns",
};

const QUANTITY_CHOICE_FORM = {
  INCREMENT: {
    type: "increment",
    symbol: "+",
  },
  DECREMENT: {
    type: "decrement",
    symbol: "-",
  },
};

const TREAT_ADD_ONS_STATUS = {
  INITIAL: "initial",
  ADD: "Add",
  CANCEL: "Cancel",
};

const DINING_MODALS = {
  DAY: "Day",
  RESTAURANTS: "Restaurants",
  TIME: "TimeSlots",
  TREATS: "Treats",
};

const DEFAULT_CONTENT = [
  {
    title: "Day",
    additionalInfo: "",
    type: DINING_MODALS.DAY,
  },
  {
    title: "Restaurants",
    additionalInfo: "",
    type: DINING_MODALS.RESTAURANTS,
  },
  {
    title: "Time",
    additionalInfo: "",
    type: DINING_MODALS.TIME,
  },
  // TODO #6599: Hiding treats in dinner page
  // {
  //   title: "Treat yourself",
  //   additionalInfo: "",
  //   type: DINING_MODALS.TREATS,
  // },
];

export {
  DEFAULT_CONTENT,
  TREAT_ADD_ONS_STATUS,
  MAESTRO_BOOKING_TYPE,
  BOOKING_SELECTIONS_BACK,
  BOOKING_SELECTIONS_CONFIRM,
  BOOKING_SELECTIONS_CONTINUE,
  BOOKING_SELECTIONS_GO_BACK,
  BOOKING_SELECTIONS_ITINERARY,
  BOOKING_SELECTIONS_CHANGE,
  DINING_MODALS,
  RESTAURANT_MENU_LINK_TEXT,
  BOOKING_SELECTIONS_PREVIEW,
  QUANTITY_CHOICE_FORM,
  TREATS_ALERT_MESSAGE,
  TREATS_TYPE_FOR_DISPLAY,
  TREATS_VIEW_MODES,
  DINING_SELECTIONS_ATTR,
  BOOKING_SELECTIONS_CANCEL,
  GROUP_DINING_CHANGES_MESSAGE,
  PREVIEW_INFO_MESSAGES,
  BOOKING_SELECTIONS_CLOSE,
};
