import React from "react";
import { IonModal, IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../Paragraph/BLParagraph";
import BLButton from "../../Buttons/BLButton";
import { useTheme } from "../../../../hooks/useTheme";

const BLConfirmationPopupBox = ({
  isOpen,
  header,
  txt1,
  txt2,
  txtBelowBtns,
  confirmHandler,
  dismissHandler,
  confirmBtnLabel,
  dismissBtnLabel,
  confirmBtnType,
  dismissBtnType,
  confirmBtnClasses,
  dismissBtnClasses,
  modal,
}) => {
  const { typography } = useTheme();
  return (
    <IonModal
      ref={modal}
      className={"bl-popup"}
      isOpen={isOpen}
      backdropDismiss={false}
      showBackdrop={true}
    >
      {header && (
        <BLParagraph
          txt={header}
          color={"bright"}
          textVariant={typography.h4}
          //newClasses="small bold"
          //TODO: Unclear typography
          dimensions={{ padding: ["t-s", "b-xs"] }}
          center
        />
      )}

      {txt1 && (
        <BLParagraph
          txt={txt1}
          color="black"
          textVariant={typography.BODY_TEXT}
          dimensions={{ padding: ["t-s", "b-xs"] }}
          center
        />
      )}

      {txt2 && (
        <BLParagraph
          txt={txt2}
          color={"black"}
          textVariant={typography.BODY_TEXT}
          dimensions={{ padding: ["t-s", "b-xs"] }}
          center
        />
      )}

      <IonGrid>
        <IonRow
          className={`ion-text-center`}
        >
          <IonCol
            className={"bl-flex ion-align-items-center ion-justify-content-center"}
            style={{ gap: "8px" }}
          >
            <BLButton
              newClasses={dismissBtnClasses}
              content={dismissBtnLabel}
              type={dismissBtnType}
              dimensions={{
                margin: ["b-s", "t-s"],
              }}
              onClick={dismissHandler}
            />
            <BLButton
              newClasses={confirmBtnClasses}
              content={confirmBtnLabel}
              type={confirmBtnType}
              dimensions={{
                margin: ["b-s", "t-s"],
              }}
              onClick={confirmHandler}
            />
          </IonCol>
        </IonRow>
      </IonGrid>

      {txtBelowBtns && (
        <BLParagraph
          txt={txtBelowBtns}
          color={"black"}
          textVariant={typography.BODY_TEXT}
          typographyStyles={{fontWeight: 300}}
          // newClasses={`x-small light`}
          //TODO: Unclear typography
          dimensions={{ margin: ["xs"], padding: ["t-xs", "b-xs"] }}
          center
        />
      )}
    </IonModal>
  );
};

export default BLConfirmationPopupBox;
