import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDataContext } from "../context/DataContext";
import { ROUTE_VIEWS } from "../routes/routeViews";
import AxiosInstance from "../url/createAxios";

const useReservationValidator = () => {
  const instance = AxiosInstance();
  const { setHasReservationExpired, prepLoginPageErrMsg, setExpiredSessionErrMsg, clearOnLogOut } =
  useDataContext();
  const location = useLocation();
  const history = useHistory();

  let sse = null;
  const checkReservationValidity = async () => {
    try {
      sse = new EventSource(
        `${process.env.REACT_APP_BACK_ENDPOINT}/check-reservation-validity`,
        { withCredentials: true }
      );
      sse.onerror = function (error) {
        console.error(
          `${new Date()} Error in the EventSource ${JSON.stringify(error)}`
        );
      };
      sse.addEventListener("reservationValidation", async (e) => {
        const parsedData = JSON.parse(e.data);

        if (!parsedData.isResValid) {

          await instance.delete(`/login`, { withCredentials: true });

          setHasReservationExpired(!parsedData.isResValid);
          const expMsg = prepLoginPageErrMsg(parsedData.errMsg);
          setExpiredSessionErrMsg(expMsg);
          
          clearOnLogOut();
          history.push(ROUTE_VIEWS.LOGIN);
        }
      });
    } catch (error) {
      console.error(
        `${new Date()} Error in checkReservationValidity func in useReservationValidation.js file ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    checkReservationValidity();
    return () => {
      sse.close();
    };
  }, [location]);

  return {};
};

export default useReservationValidator;
