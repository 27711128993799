import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { SpaContext } from "../../../context/SpaContext";
import { ROUTE_VIEWS } from "../../../routes/routeViews";
import BLHorizontalLineTitle from "../../../shared/Ionic/BLHorizontalLineTitle";
import SpaCategories from "./Views/Categories/SpaCategories";
import SpaServices from "./Views/Single/SpaServices";
import ConflictPopupBoxes from "../../../helpingFunctions/conflictManagement/ConflictPopupBoxes";
import { SERVICES_TYPES, UNCATEGORIZED } from "../../../constants/constants";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import ServicePageHeader from "../../../shared/Ionic/Wrappers/ServicePageHeader";
import { useHistory, useLocation } from "react-router-dom";
import { isDesktop } from "../../../helpingFunctions/utilities";

const SpaPage = () => {
  const history = useHistory();
  const { categoryId, spaId } = useParams();
  const location = useLocation();
  const { redirectBetweenPages, warnerServices } = useContext(NavigatorContext);
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const {
    setShowAdditionalDetails,
    spaDetails,
    showBookingSection,
    showAdditionalDetails,
  } = useContext(SpaContext);
  const [formatedSpaServices, setFormatedSpaServices] = useState(null);

  useEffect(() => {
    if (!categoryId) {
      setShowAdditionalDetails(false);
    }

    if (categoryId && categoryId !== UNCATEGORIZED) {
      setFormatedSpaServices(
        spaDetails?.Categorized.find(
          (category) => category.categoryId === Number(categoryId)
        )
      );
    } else {
      setFormatedSpaServices(null);
    }
  }, [location, spaDetails]);

  useEffect(() => {
    if (warnerServices.length === 0 || !spaDetails) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Spa Page");
    } else {
      setIsLoaderOpened(false);
    }
  }, [warnerServices, spaDetails]);

  return (
    <IonPage style={{ overflow: "auto" }}>
      <ConflictPopupBoxes />
      <ServicePageHeader
        service={SERVICES_TYPES.SPAS}
        onClick={() => history.push(history.location.state.isItineraryRedirect ? ROUTE_VIEWS.ITINERARY : ROUTE_VIEWS.THINGS_TO_DO)}
      />

      <IonGrid className="bl-padding-s bl-padding-t-xl">
        {isDesktop() && !showBookingSection && !showAdditionalDetails && (
          <BLHorizontalLineTitle
            txt={formatedSpaServices ? formatedSpaServices.title : "All"}
            color="primary"
            enableBackButton={formatedSpaServices}
            backButtonHandler={() => {
              setFormatedSpaServices(null);
              redirectBetweenPages(ROUTE_VIEWS.SPAS);
            }}
          />
        )}
        {spaDetails && !categoryId && !spaId && (
          <IonRow>
            <IonCol>
              <SpaCategories categories={spaDetails.Categorized} />
            </IonCol>
          </IonRow>
        )}

        {categoryId && (
          <IonRow>
            <IonCol>
              <SpaServices formatedSpaServices={formatedSpaServices} />
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </IonPage>
  );
};

export default SpaPage;
