import { IonModal } from "@ionic/react";
import React, { useContext } from "react";
import { HEADER_SIZE } from "../../../../constants/constants";
import { SideMenuContext } from "../../../../context/SideMenuContext";
import styles from "./FullScreenWrapper.module.scss";
import { isDesktop } from "../../../../helpingFunctions/utilities";

const FullScreenWrapper = () => {
  const { fullScreenOverlayContent } = useContext(SideMenuContext);

  return (
    <IonModal
      style={{
        top: isDesktop() ? HEADER_SIZE : `calc(${HEADER_SIZE} + env(safe-area-inset-top))`,
        "--backdrop-opacity": 0.1,
      }}
      isOpen={!!fullScreenOverlayContent}
      backdropDismiss={false}
      className={`${styles.Modal} ion-disable-focus-trap`}
    >
      {fullScreenOverlayContent}
    </IonModal>
  );
};

export default FullScreenWrapper;