import React from "react";

import RedirectToLandingPageLogic from "./RedirectToLandingPageLogic";

import styles from "./redirect.module.scss";
import LocalLoader from "../../../shared/Ionic/Loader/LocalLoader";

const RedirectToLandingPage = ({setIsLoadingDeeplink}) => {
  RedirectToLandingPageLogic(setIsLoadingDeeplink);

  return (
    <div className={styles.redirectPageWrapper}>
      <LocalLoader loaderSize={"40px"} dimensions={{ margin: ["b-l"] }} />
      <p>We are redirecting you to another page</p>
    </div>
  );
};

export default RedirectToLandingPage;
