const getRestaurantNameById = (facilitiesDataDetails, restaurantId) => {
  try {
    const restaurant = facilitiesDataDetails.find(({ id }) => id === restaurantId);

    if (!restaurant) {
      return "no restaurant found";
    }

    return restaurant.Title;
  } catch (error) {
    console.error(
      `${new Date()} Error in getRestaurantNameById func in facilityAndServices.js ${
        error.message
      }`
    );
  }
};

export { getRestaurantNameById };
