import React from "react";
import styles from "../BLMarkdown.module.scss";

const MarkdownList = ({type, children}) => {

  return (
    <>
      {type=="unordered"
        ? <ul className={styles.MarkdownUList}>
          {children}
        </ul>
        : <ol className={styles.MarkdownOList}>
          {children}
        </ol>
      }
    </>
  );

};

export default MarkdownList;