import React, { Fragment } from "react";
import ReactPlayer from "react-player";

import HelpItem from "../../../components/HelpItem_OLD/HelpItem";
import Heading from "../../../shared/Heading_OLD/Heading";
import InformationText from "../../../shared/InformationText_OLD/InformationText";
import HelpPageLogic from "./HelpPageLogic";
import LoadingContainer from "../../../shared/LoadingContainer_OLD/LoadingContainer";
import FAQSearch from "../../../components/FAQSearch_OLD/FAQSearch";
import Information from "../../../components/InformationAlert_OLD/Information";
import Button from "../../../shared/Button_OLD/Button";
import useZendesk from "../../../hooks/useZendesk";

import styles from "./help_page.module.scss";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { IonPage } from "@ionic/react";

const HelpPage = () => {
  useZendesk();
  const { filteredData, videoSectionData, isLoading, manageSearch } =
    HelpPageLogic();
  const { Title, Description, MediaLink, Image } = videoSectionData;
  const history = useHistory();

  return (
    <IonPage>
      <div className={styles.helpPage}>
        <div className={styles.helpPage__headline}>
          <div className={styles.helpPage__title}>
            <Heading
              txt={`We’re here to help`}
              paddingBottom={"35px"}
              paddingTop={"35px"}
              fontSize={"24px"}
            />
          </div>
          <div className={styles.helpPage__desc}>
            <InformationText
              content={
                <>
                  <p>
                    Your go-to place for any questions on how to make your
                    breakfast and dinner bookings.
                  </p>
                </>
              }
            />
          </div>
        </div>
        <div className={styles.helpPage__video_section}>
          {isLoading ? (
            <LoadingContainer />
          ) : (
            <>
              {Image || MediaLink ? (
                <div className={styles.helpPage__video_title__box}>
                  <div className={styles.helpPage__video_title_desc}>
                    <Heading
                      txt={Title}
                      paddingBottom={"20px"}
                      fontSize={"20px"}
                    />
                    <InformationText content={Description} />
                  </div>
                  <div className={styles.helpPage__media}>
                    {MediaLink ? (
                      <ReactPlayer
                        width={"100%"}
                        height={"100%"}
                        url={MediaLink}
                      />
                    ) : (
                      <img
                        src={
                          Image?.formats?.small.url || Image.url
                        }
                        width="100%"
                        height="100%"
                      />
                    )}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
        <div className={styles.helpPage__highlitedTitle}>
          <Heading txt={"Frequently asked questions"} fontSize={"24px"} />
        </div>
        <div className={styles.helpPage__questions_terms}>
          <div className={styles.helpPage__questions}>
            <FAQSearch manageSearch={manageSearch} />
            {filteredData.length > 0 ? (
              <>
                {filteredData.map(({ Category, CategoryImage, objects }) => (
                  <Fragment key={Category || "Misc"}>
                    <div
                      className={styles.CategoryWrapper}
                      style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url(${CategoryImage})`,
                      }}
                    >
                      <Heading
                        txt={Category ? Category : "Misc"}
                        customHeadingClass={styles.helpPage__categoryHeading}
                      />
                    </div>
                    {objects.map(({ id, Question, Answer }) => (
                      <HelpItem key={id} question={Question} answer={Answer} />
                    ))}
                  </Fragment>
                ))}
              </>
            ) : (
              <>
                {isLoading ? (
                  <LoadingContainer />
                ) : (
                  <Information
                    className={styles.helpPage__information}
                    fullyColoredClass={"fullyColoredClass"}
                    flexDirection={"column"}
                    txt={"Sorry we could not find any results!"}
                    icon={"exclamation.svg"}
                  />
                )}
              </>
            )}
            <HelpItem
              question={"Need more help?"}
              answer={
                <>
                  {"Our support team would love to help! Click "}
                  <a
                    style={{
                      color: "#559CB6",
                      textDecoration: "underline",
                      fontSize: "1.1em",
                    }}
                    target={"_blank"}
                    rel="noreferrer"
                    href={"https://support.warnerleisurehotels.co.uk/hc/en-gb"}
                  >
                    here
                  </a>
                  {" to view our contact information. "}
                </>
              }
              backgroundColor={"primary"}
            />
          </div>

          <div className={styles.helpPage__terms}>
            <InformationText
              content={
                <p>
                  Copyright {moment().format("YYYY")} Warner Leisure Hotels.
                  Bourne Holidays Limited (trading as Warner Leisure Hotels) is
                  a company registered in England and Wales with company number
                  01854900 whose registered office is 1 Park Lane, Hemel
                  Hempstead, Hertfordshire HP2 4YL.
                </p>
              }
            />
            <InformationText
              content={
                <p>
                  †Cost of calling Warner: Calls cost no more than calls to
                  geographic numbers (01 or 02) and must be included in
                  inclusive minutes and discount schemes in the same way. Calls
                  from landlines are typically charged up to 9p per minute;
                  calls from mobiles typically cost between 8p and 40p per
                  minute. Calls from landlines and mobiles are included in many
                  free call packages. Please check with your supplier.Check out
                  our step-by-step video of how to make your dining bookings.
                </p>
              }
            />
            <div className={styles.HelpPage__goBack}>
              <Button
                txt={"Back"}
                color={"#4b5781"}
                backgroundColor={"white"}
                onClick={() => history.goBack()}
              />
            </div>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default HelpPage;
