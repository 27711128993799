import {
  bookedEndOverlapsSelectedDuration,
  bookedStartOverlapsSelectedDuration,
  selectedEndOverlapsBookedDuration,
  selectedStartOverlapsBookedDuration,
} from "./evaluationHelperMethods";

import {
  getEndTime,
  isOverallOverlaping,
} from "../globalConflictHelpersMethods";
import { CONFLICT_DINING_TYPES, CONFLICT_SERVICES_TYPES } from "../conflictConstants";
import { MAESTRO_BACKEND_NAMING_VIEWS, SERVICES_TYPES } from "../../../constants/constants";

/** 
    Method for retrieving all booked items that match the newly made selection by its start time
  */
const conflictSameDaySameTimeValidation = (
  selectionDetails,
  bookedDayRecords,
  conflictOccuringView
) => {
  return bookedDayRecords.filter(
    (row) =>
      row.Time.substring(0, 5) === selectionDetails.time.substring(0, 5) &&
      selectionDetails.id !== row.Id &&
      (!CONFLICT_DINING_TYPES.includes(selectionDetails.type) ||
      (CONFLICT_DINING_TYPES.includes(selectionDetails.type) && selectionDetails.type !== row.Type)) &&
      //There is no conflict for the same date and time for Entertainments
      (conflictOccuringView === SERVICES_TYPES.ENTERTAINMENT && row.Type !== MAESTRO_BACKEND_NAMING_VIEWS.VENUE)
  );
};

/** 
    Method for retrieving all booked items that are conflicting either by:
      case 1: the selection start/end time overlaps the booked item duration 
      -OR-
      case 2: the booked start.end time overlaps the selection item duration
  */
const conflictOverlapingPeriodValidation = (
  selectedServiceDetails,
  selectionDetails,
  bookedDayRecords,
  facilitiesDataDetails,
  conflictOccuringView
) => {
  let hasConflict = false;
  let conflictedObjs = [];

  const isConfOccuringInSpa = MAESTRO_BACKEND_NAMING_VIEWS.SPA === conflictOccuringView;

  for (const row of bookedDayRecords) {
    if (
      (isConfOccuringInSpa &&
        selectionDetails.id === row.Id &&
        selectionDetails.lastName === row.LastName &&
        selectionDetails.firstName === row.FirstName) ||
      (row.Id === selectionDetails.id && !isConfOccuringInSpa) ||
      (CONFLICT_DINING_TYPES.includes(selectionDetails.type) &&
        selectionDetails.type === row.Type) ||
      //There is no conflict for the same date and time for Entertainments
      (conflictOccuringView === SERVICES_TYPES.ENTERTAINMENT &&
        row.Type === MAESTRO_BACKEND_NAMING_VIEWS.VENUE)
    ) {
      continue;
    }

    hasConflict = selectedStartOverlapsBookedDuration(
      selectionDetails,
      row,
      facilitiesDataDetails
    );
    if (hasConflict) {
      conflictedObjs.push(row);
      continue;
    }

    hasConflict = selectedEndOverlapsBookedDuration(
      selectionDetails,
      row,
      selectedServiceDetails,
      facilitiesDataDetails
    );
    if (hasConflict) {
      conflictedObjs.push(row);
      continue;
    }

    hasConflict = bookedStartOverlapsSelectedDuration(
      selectionDetails,
      row,
      selectedServiceDetails
    );
    if (hasConflict) {
      conflictedObjs.push(row);
      continue;
    }

    hasConflict = bookedEndOverlapsSelectedDuration(
      selectionDetails,
      row,
      selectedServiceDetails,
      facilitiesDataDetails
    );
    if (hasConflict) {
      conflictedObjs.push(row);
      continue;
    }
  }

  return conflictedObjs;
};

/** 
    Method for retrieving all booked items that are overlaping by time AND BY quantity.
    At any given period of time, the user is not allowed to have bookings, which quantity, together with the newly made selection quantity EXCEED the quantity in the reservation
  */
const conflictExceededQuantityValidation = (
  conflictedBookedRecords,
  selectionDetails,
  selectedServiceDetails,
  facilitiesDataDetails,
  reservationDetails,
  isConflictOccuringInServicesView
) => {
  const selectionStartTime = selectionDetails.time.substring(0, 5);
  const selectionEndTime = getEndTime(
    selectionDetails.time.substring(0, 5),
    selectedServiceDetails.Duration
  );

  const confirmedConflicts = [];

  // Check to skip case like
  // Res for 2 adults, confirmed bookings for Breakfast for 9Am and Activity for 1 perison, 9Am. Trying to book another activity for 1 person, for 9Am - soft conflict
  let ignoreSameTimeIncludedTypesQty = false;
  if (isConflictOccuringInServicesView) {
    let confirmedQty = 0;
    conflictedBookedRecords.forEach((row) => {
      if (CONFLICT_SERVICES_TYPES.includes(row.Type)) {
        confirmedQty += Number(row.Quantity);
      }
    });

    if (!(confirmedQty + selectionDetails.quantity > reservationDetails.Adults)) {
      ignoreSameTimeIncludedTypesQty = true;
    }
  }

  // Find the total booked quantity and all overlapping bookings in the duration of the selection
  let bookedQuantity = 0;
  for (const conflictedEntity of conflictedBookedRecords) {
    const bookedServiceDetails = facilitiesDataDetails.find(
      (facilityDetail) => facilityDetail.id === conflictedEntity.Id
    ) || { Duration: 0 };

    const bookedServStartTime = conflictedEntity.Time.substring(0, 5);
    const bookedServEndTime = getEndTime(
      conflictedEntity.Time.substring(0, 5),
      bookedServiceDetails.Duration
    );

    const isOverlaping = isOverallOverlaping(
      selectionStartTime,
      selectionEndTime,
      bookedServStartTime,
      bookedServEndTime
    );

    if (isOverlaping) {
      if (
        conflictedEntity.Time.substring(0, 5) ===
        selectionDetails.time.substring(0, 5)
      ) {
        if (
          ignoreSameTimeIncludedTypesQty &&
          CONFLICT_SERVICES_TYPES.includes(conflictedEntity.Type)
        )
          continue;
      }

      bookedQuantity += Number(conflictedEntity.Quantity);
      confirmedConflicts.push(conflictedEntity);
    }
  }
  
  // If we have the circumstances of a conflict
  if (bookedQuantity + selectionDetails.quantity > reservationDetails.Adults) {
    // Split the conflicted objects to included (Dining, Ent) and services (Activities, Spa)
    const serviceConflicts = confirmedConflicts.filter((row) =>
      CONFLICT_SERVICES_TYPES.includes(row.Type)
    );
    const includedConflicts = confirmedConflicts.filter(
      (row) => !CONFLICT_SERVICES_TYPES.includes(row.Type)
    );

    // By default, the included conflcits (Dining, Ent) oppose a conflict and must be showned. (Qty doesnt play a row here)
    const verifiedQtyConflicts = includedConflicts;

    // If the rest of the conflicts are atleast 2
    if (serviceConflicts.length > 1) {
      // Check if each confirmed booking is overllaping with each other
      serviceConflicts.forEach((serv) => {
        const currentConflictStartTime = serv.Time.substring(0, 5);
        const currentConflictEndTime = getEndTime(
          serv.Time.substring(0, 5),
          serv.Duration
        );

        serviceConflicts.forEach((row) => {
          if (row.BookingNumber !== serv.BookingNumber) {
            const otherConflictStartTime = row.Time.substring(0, 5);
            const otherConflictEndTime = getEndTime(
              row.Time.substring(0, 5),
              row.Duration
            );

            const isOverlaping = isOverallOverlaping(
              currentConflictStartTime,
              currentConflictEndTime,
              otherConflictStartTime,
              otherConflictEndTime
            );

            if (isOverlaping) {
              const totalOverllapingQty =
                Number(serv.Quantity) + Number(row.Quantity);
              if (
                totalOverllapingQty + selectionDetails.quantity >
                reservationDetails.Adults
              ) {
                verifiedQtyConflicts.push(serv);
              }
            } else {
              if (
                Number(serv.Quantity) + selectionDetails.quantity >
                reservationDetails.Adults
              ) {
                verifiedQtyConflicts.push(serv);
              }
            }
          }
        });
      });
    } else if (serviceConflicts.length === 1) {
      if (
        Number(serviceConflicts[0].Quantity) + selectionDetails.quantity >
        reservationDetails.Adults
      ) {
        verifiedQtyConflicts.push(serviceConflicts[0]);
      }
    }

    return verifiedQtyConflicts;
  }

  return [];
};

export {
  conflictOverlapingPeriodValidation,
  conflictSameDaySameTimeValidation,
  conflictExceededQuantityValidation,
};
