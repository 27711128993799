import { format } from "date-fns";
import { useContext } from "react";
import { useDataContext } from "../../../context/DataContext";
import { getCurrentDate, getDayOfWeek } from "../../../helpingFunctions/dateTimeUtilities";
import { EntertainmentsPageContext } from "./EntertainmentsPageContext";

const EntertainmentsPageLogic = () => {
  const {
    entertainmentBookingSelections,
    selectedDate,
    entertainmentLocationDetails,
    entertainmentDetails,
  } = useContext(
    EntertainmentsPageContext
  );
  const { dynamicTexts, itineraryDetails } = useDataContext();

  const dayOfWeek = getDayOfWeek(selectedDate);
  const isThursdayOrSaturday =
    dayOfWeek === "Thursday" || dayOfWeek === "Saturday";

  const prepareShows = (entertainmentAvailability) => {
    const entAvailability = entertainmentAvailability[
      selectedDate
    ]?.AvailabilityDetails?.map((availabilityDetail) => {
      let entertainmentLocation = (entertainmentLocationDetails ?? []).find(
        (ent) => ent.ActivityId === availabilityDetail.FacilityGUID
      );
      return availabilityDetail.Times.map((time) => {
        let entertainment = (entertainmentDetails ?? []).find(
          (ent) =>
            ent.ActivityId === availabilityDetail.FacilityGUID &&
            ent.ShowTime.slice(0, 5) === time.Time.slice(0, 5) &&
            ent.ShowDate === selectedDate
        );
        return {
          venueId:
            entertainment?.ActivityId || availabilityDetail.FacilityGUID,
          title: entertainment
            ? entertainment.ShowTitle
            : dynamicTexts?.Entertainments?.Booking?.Paragraph_CardTitle,
          location: entertainment
            ? entertainment.Location
            : entertainmentLocation
              ? entertainmentLocation.Location
              : "",
          description: entertainment
            ? entertainment.ShowDescription
            : entertainmentLocation
              ? entertainmentLocation.Priority <= 1
                ? dynamicTexts?.Entertainments?.Booking
                  ?.Paragraph_Card_PriorityOneDescription
                : dynamicTexts?.Entertainments?.Booking
                  ?.Paragraph_Card_PriorityTwoDescription
              : dynamicTexts?.Entertainments?.Booking
                ?.Paragraph_Card_PriorityOneDescription,
          showImages: entertainment?.ShowImages,
          locationImages: entertainmentLocation?.LocationImages,
          breakDescription: entertainment?.ShowBreakDescription,
          entertainmentId: entertainment?.ShowsGroupIdBL,
          isSelected: false,
          isFestival: entertainment?.isFestival,
          ...time,
        };
      });
    });
    const filteredEntAvailability = entAvailability?.filter(
      (el) => el !== undefined
    );
    if (filteredEntAvailability && filteredEntAvailability.length > 0) {
      const flattenedEntAvailability = filteredEntAvailability.flat();

      const showsWithNoMaestroAvailability = (entertainmentDetails ?? []).filter(
        (show) => {
          return (
            flattenedEntAvailability.findIndex(
              (flattenedShow) =>
                flattenedShow?.Time?.slice(0, 5) === show.ShowTime.slice(0, 5)
            ) === -1 && show.ShowDate === selectedDate
          );
        }
      );

      const convertedShowsWithNoAvailability =
        showsWithNoMaestroAvailability.map((show) => {
          return {
            venueId: show.ActivityId,
            Availability: "0",
            Time: show.ShowTime,
            title: show.ShowTitle,
            location: show.Location,
            showImages: show.ShowImages,
            description: show.ShowDescription,
            isFestival: show.isFestival,
          };
        });
      const allShows = [
        ...flattenedEntAvailability,
        ...convertedShowsWithNoAvailability,
      ];

      const filteredShows = filterShows(allShows);
      const sortedShows = (filteredShows ?? []).sort(
        (showA, showB) =>
          Number(showA.Time.slice(0, 5).replace(":", "")) -
          Number(showB.Time.slice(0, 5).replace(":", "")) ||
          showB.location.indexOf("Theatre") -
          showA.location.indexOf("Theatre")
      );
      return sortedShows;
    }
    return [];
  };

  const filterShows = (shows) => {
    try {
      shows = shows.filter((show) =>
        selectedDate === getCurrentDate()
          ? show.Time.slice(0, 5).replace(":", "") >=
          format(new Date(), "kkmm") ||
          !!itineraryDetails.Dates[selectedDate]?.Entertainment.find(
            (booking) =>
              booking.Id === show.venueId && booking.Time === show.Time
          )
          : show
      );

      if (isThursdayOrSaturday && !shows.find((show) => show.isFestival)) {
        const venue1 = shows[0]?.location;
        const venue1Shows = shows.filter((show) => show.location === venue1);
        const venue2Shows = shows.filter((show) => show.location !== venue1);
        const bookedShows = shows.filter((show) => isShowAlreadyBooked(show));
        if (venue1) {
          if (
            venue1Shows.filter((show) => show.Availability > 0).length >
            venue2Shows.filter((show) => show.Availability > 0).length
          ) {
            return [...venue1Shows, ...bookedShows.filter((show) => show.location !== venue1)];
          } else if (
            venue1Shows.filter((show) => show.Availability > 0).length <
            venue2Shows.filter((show) => show.Availability > 0).length
          ) {
            return [...venue2Shows, ...bookedShows.filter((show) => show.location === venue1)];
          }
        }
      }
      return shows;
    } catch (error) {
      console.error(
        `${new Date()} Error in filterShows func in EntertainmentsPageLogic.js ${error.message}`
      );
    }
  };

  const isSummaryCardDisabled = (show) => {
    try {
      let isPassed = false;
      let isThursdayOrSaturdayDisabled = false;
      let isStarBreakDisabled = false;
      let isSameTimeDisabled = false;
      let reason = "";

      if (
        show.Time.slice(0, 5).replace(":", "") < format(new Date(), "kkmm") &&
        selectedDate === getCurrentDate()
      ) {
        isPassed = true;
        reason = dynamicTexts?.Entertainments?.Booking?.Tooltip_Card_DisabledPassedShow;
      }

      if (
        isThursdayOrSaturday &&
        !entertainmentBookingSelections.find((show) => show.isFestival)
      ) {
        isThursdayOrSaturdayDisabled =
          entertainmentBookingSelections.findIndex(
            (el) =>
              ((el.time !== show.Time && el.venueId === show.venueId) ||
                (el.time === show.Time && el.venueId !== show.venueId)) &&
              el.date === selectedDate &&
              (el.action === "add" || el.action === "none")
          ) !== -1;
        if (isThursdayOrSaturdayDisabled) {
          reason =
            dynamicTexts?.Entertainments?.Booking
              ?.Tooltip_Card_DisabledThuOrSat;
        }
      }

      if (show.breakDescription?.startsWith("Headlining")) {
        isStarBreakDisabled =
          entertainmentBookingSelections.findIndex(
            (el) =>
              el.breakDescription?.startsWith("Headlining") &&
              el.entertainmentId === show.entertainmentId &&
              el.action !== "remove" &&
              (selectedDate !== el.date ||
                (selectedDate === el.date && el.time !== show.Time))
          ) !== -1;
        if (isStarBreakDisabled) {
          reason = dynamicTexts?.Entertainments?.Booking?.Tooltip_Card_DisabledStarBreakShow;
        }
      }

      isSameTimeDisabled =
        entertainmentBookingSelections.findIndex(
          (el) =>
            el.time === show.Time &&
            el.venueId !== show.venueId &&
            el.date === selectedDate &&
            (el.action === "add" || el.action === "none")
        ) !== -1;
      if (isSameTimeDisabled) {
        reason =
          dynamicTexts?.Entertainments?.Booking?.Tooltip_Card_DisabledMultipleShowsAtSameTime;
      }

      return {
        isDisabled:
          isThursdayOrSaturdayDisabled ||
          isStarBreakDisabled ||
          isSameTimeDisabled ||
          isPassed,
        reason: reason,
      };
    } catch (error) {
      console.error(
        `${new Date()} Error in isSummaryCardDisabled func in EntertainmentsPageLogic.js ${error.message}`
      );
    }
  };
  const isShowAlreadyBooked = (showHours) => {
    const isBooked = !!entertainmentBookingSelections.find(
      (el) =>
        el.time ===
        showHours.Time &&
        el.venueId ===
        showHours.venueId &&
        el.bookingNumber && el.date === selectedDate
    );
    return isBooked;
  };

  return {
    filterShows,
    isSummaryCardDisabled,
    isThursdayOrSaturday,
    isShowAlreadyBooked,
    prepareShows,
  };
};

export default EntertainmentsPageLogic;
