import React, { useState, createContext, useContext } from "react";

import { IonGrid, IonRow } from "@ionic/react";
import {
  ITEM_POPULAR_STATUSES,
  AUTHENTICATED_KEY,
} from "../constants/constants";
import LocalLoader from "../shared/Ionic/Loader/LocalLoader";
import { checkmarkCircle, closeCircle } from "ionicons/icons";
import BLIcon from "../shared/Ionic/BLIcon";
import {
  ERROR_TEXTS,
  ERROR_TYPES
} from "../constants/errorConstants";
import { menuController } from "@ionic/core/components";

export const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  //Texts
  const [dynamicTexts, setDynamicTexts] = useState({});
  const [expiredSessionErrMsg, setExpiredSessionErrMsg] = useState(null);
  const [conflictManagementTexts, setConflictManagementTexts] = useState({});
  // Data Objects
  const [hasReservationExpired, setHasReservationExpired] = useState(false);
  const [facilitiesData, setFacilitiesData] = useState({});
  const [facilitiesDataDetails, setFacilitiesDataDetails] = useState([]);
  const [availability, setAvailability] = useState(null);
  const [cancellationReasons, setCancellationReasons] = useState({
    Activity: [],
    Spa: [],
  });

  const [pagesConfiguration, setPagesConfiguration] = useState(null);
  const [itineraryDetails, setItineraryDetails] = useState(null);
  const [reservationDetails, setReservationDetails] = useState(null);
  const [reservationHotelDetails, setReservationHotelDetails] = useState(null);

  // Other
  const [isKioskRedirect, setIsKioskRedirect] = useState(false);
  const [isLoadingHotelData, setIsLoadingHotelData] = useState(false);
  const [isLoadingItinerary, setIsLoadingItinerary] = useState(true);
  const [isLoadingAvailability, setIsLoadingAvailability] =
    useState(false);

  const [loginPageData, setLoginPageData] = useState(null);

  const clearOnLogOut = () => {
    localStorage.removeItem(AUTHENTICATED_KEY);
    window.dispatchEvent(new Event('storage'));
    menuController.close();
    setReservationDetails(null);
    setItineraryDetails(null);
  };

  const notifyIconDisplay = (status) => {
    try {
      let IconEl = null;

      switch (status) {
        case ITEM_POPULAR_STATUSES.LOAD:
          IconEl = <LocalLoader loaderSize={"32px"} />;
          break;
        case ITEM_POPULAR_STATUSES.SUCCESS:
          IconEl = (
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <BLIcon
                  iconSource={checkmarkCircle}
                  color={"success"}
                  iconSize={"md"}
                />
              </IonRow>
            </IonGrid>
          );
          break;
        case ITEM_POPULAR_STATUSES.FAILURE:
        case ITEM_POPULAR_STATUSES.FAILED:
          IconEl = (
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <BLIcon
                  iconSource={closeCircle}
                  color={"danger"}
                  iconSize={"md"}
                />
              </IonRow>
            </IonGrid>
          );
          break;
        default:
          break;
      }

      return IconEl;
    } catch (error) {
      console.error(
        `${new Date()} Error in notifyIconDisplay func in DataContext.js ${error.message}`
      );
    }
  };

  const prepLoginPageErrMsg = (errType) => {
    switch (errType) {
      case ERROR_TYPES.ERR_CANCELED_RESERVATION:
        return ERROR_TEXTS.INVALID_RESERVATION;
      case ERROR_TYPES.ERR_OLD_RESERVATION:
        return ERROR_TEXTS.EXPIRED_RESERVATION;
      case ERROR_TYPES.ERR_DEFAULT:
        return ERROR_TEXTS.SESSION_EXPIRED;
      case ERROR_TYPES.ERR_LOGIN_RES_NOT_FOUND:
        return ERROR_TEXTS.NOT_FOUND_RESERVATION;

      default:
        return Object.keys(errType).length !== 0 ? errType : "Unknown error";
    }
  };

  return (
    <DataContext.Provider
      value={{
        itineraryDetails,
        reservationDetails,
        reservationHotelDetails,
        isLoadingItinerary,
        isLoadingHotelData,
        setIsLoadingHotelData,
        setIsLoadingItinerary,
        setReservationHotelDetails,
        setReservationDetails,
        setItineraryDetails,
        conflictManagementTexts,
        setConflictManagementTexts,
        isKioskRedirect,
        isLoadingAvailability,
        dynamicTexts,
        pagesConfiguration,
        setPagesConfiguration,
        setDynamicTexts,
        setIsLoadingAvailability,
        notifyIconDisplay,
        setIsKioskRedirect,
        clearOnLogOut,
        cancellationReasons,
        setCancellationReasons,
        facilitiesDataDetails,
        setFacilitiesDataDetails,
        hasReservationExpired,
        setHasReservationExpired,
        prepLoginPageErrMsg,
        expiredSessionErrMsg,
        setExpiredSessionErrMsg,
        facilitiesData,
        setFacilitiesData,
        availability,
        setAvailability,
        loginPageData,
        setLoginPageData
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  const {
    itineraryDetails,
    reservationDetails,
    reservationHotelDetails,
    isLoadingItinerary,
    isLoadingHotelData,
    setIsLoadingHotelData,
    setIsLoadingItinerary,
    setReservationHotelDetails,
    setReservationDetails,
    setItineraryDetails,
    conflictManagementTexts,
    setConflictManagementTexts,
    isKioskRedirect,
    isLoadingAvailability,
    dynamicTexts,
    pagesConfiguration,
    setPagesConfiguration,
    setDynamicTexts,
    setIsLoadingAvailability,
    notifyIconDisplay,
    setIsKioskRedirect,
    clearOnLogOut,
    cancellationReasons,
    setCancellationReasons,
    facilitiesDataDetails,
    setFacilitiesDataDetails,
    hasReservationExpired,
    setHasReservationExpired,
    prepLoginPageErrMsg,
    expiredSessionErrMsg,
    setExpiredSessionErrMsg,
    facilitiesData,
    setFacilitiesData,
    availability,
    setAvailability,
    loginPageData,
    setLoginPageData
  } = useContext(DataContext);
  return {
    itineraryDetails,
    reservationDetails,
    reservationHotelDetails,
    isLoadingItinerary,
    isLoadingHotelData,
    setIsLoadingHotelData,
    setIsLoadingItinerary,
    setReservationHotelDetails,
    setReservationDetails,
    setItineraryDetails,
    conflictManagementTexts,
    setConflictManagementTexts,
    isKioskRedirect,
    isLoadingAvailability,
    dynamicTexts,
    pagesConfiguration,
    setPagesConfiguration,
    setDynamicTexts,
    setIsLoadingAvailability,
    notifyIconDisplay,
    setIsKioskRedirect,
    clearOnLogOut,
    cancellationReasons,
    setCancellationReasons,
    facilitiesDataDetails,
    setFacilitiesDataDetails,
    hasReservationExpired,
    setHasReservationExpired,
    prepLoginPageErrMsg,
    expiredSessionErrMsg,
    setExpiredSessionErrMsg,
    facilitiesData,
    setFacilitiesData,
    availability,
    setAvailability,
    loginPageData,
    setLoginPageData
  };
};
