import React from "react";
import { useDataContext } from "../../../context/DataContext";
import { formatDateDetailed, getDatesBetween } from "../../../helpingFunctions/dateTimeUtilities";
import BLParagraph from "./BLParagraph";
import { SERVICES_TYPES } from "../../../constants/constants";
import { useTheme } from "../../../hooks/useTheme";

const BLDateTimeFormatParagraph = ({ date, view, dimensions = {padding: ["l-s", "r-s"] } }) => {
  const { typography } = useTheme();
  const { reservationDetails } = useDataContext();

  if (!date) return null;
  const stayingDates = getDatesBetween(
    reservationDetails.ArrivalDate,
    reservationDetails.DepartureDate,
    view
  );

  return (
    <BLParagraph
      dimensions={dimensions}
      txt={formatDateDetailed(stayingDates, date, false, view.includes(SERVICES_TYPES.BREAKFAST))}
      textVariant={typography.BODY_TEXT}
      // newClasses={`x-small bold`}
      //TODO: Unclear typography
      color="primary"
    />
  );
};

export default BLDateTimeFormatParagraph;
