import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { useLocation } from "react-router-dom";
import Navigator from "../components/NavigatorIonic/Navigator";
import { ROUTE_VIEWS } from "../routes/routeViews";
import styles from "./MainWrapper.module.scss";

const MainWrapper = ({ children }) => {
  const location = useLocation();

  return (
    // FIXME: This is a workaround allowing direct access to URLs, the fallback needs to be moved to the Switch, but before that we need to
    // finish implementation of the nested routes story (https://dev.azure.com/Sciant/BourneLeisure/_workitems/edit/8415);
    [
      ROUTE_VIEWS.COOKIE_POLICY,
      ROUTE_VIEWS.PRIVACY_POLICY,
      ROUTE_VIEWS.HELP,
      ROUTE_VIEWS.MAINTENANCE,
      ROUTE_VIEWS.SERVE,
      ROUTE_VIEWS.KIOSK,
      "whatsonguideteam",
      "whatsonguidepublic"
    ].includes(location.pathname) ? (
        children
      ) : (
        <IonGrid className={styles.MainWrapper}>
          <IonRow className={`${styles.MainContentWrapper}`}>
            <IonCol className={styles.SideNav}>
              <div className={styles.SideNavWrapper}>
                <Navigator />
              </div>
            </IonCol>

            <IonCol
              className={styles.ContentWrapper}
              style={{
                background:
                  (location?.pathname.includes(
                    ROUTE_VIEWS.LANDING_PAGE) &&
                    window.matchMedia("(min-width: 821px)").matches) ||
                    !location?.pathname.includes(ROUTE_VIEWS.LANDING_PAGE)
                    ? "var(--ion-color-white)"
                    : "var(--ion-color-light)",
              }}
            >
              <div className={styles.ScrollWrapper}>{children}</div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )
  );
};

export default MainWrapper;
