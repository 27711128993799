import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect } from "react";

import { useDataContext } from "../../../../../context/DataContext";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";

import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import ModifyDiningParty from "../CreateDiningParty/ModifyDiningParty";
import DiningPartyForm from "../DiningPartyForm/DiningPartyForm";
import ParticipantDisplay from "../ParticipantDisplay/ParticipantDisplay";
import { useTheme } from "../../../../../hooks/useTheme";

const DiningPartyLeader = () => {
  const { typography } = useTheme();
  const {
    reservationDetails,
  } = useDataContext();
  const { addMoreHandler, diningPartyDetails: { name, id } } = useContext(DiningPartyContext);

  useEffect(() => {
    addMoreHandler(null);
  }, []);

  const GroupTitle = (
    <IonGrid>
      <IonRow className="ion-justify-content-between ion-align-items-end">
        <IonCol size="auto">
          <BLParagraph
            txt={name}
            textVariant={typography.h3}
            // newClasses={"medium bold"}
            //TODO: Unclear typography
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  return (
    reservationDetails && 
    <BLContentCardWrapper dimensions={{ padding: ["s"] }}>
      <ParticipantDisplay leader title={GroupTitle} />
      <BLContentCardWrapper>
        <DiningPartyForm />
      </BLContentCardWrapper>
      {reservationDetails.IsGroupCreator && id && <ModifyDiningParty />}
    </BLContentCardWrapper>
  );
};

export default DiningPartyLeader;
