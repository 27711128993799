import React from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

import { Box } from "@mui/material";

import DayBox from "../DayBox/DayBox";
import GeneralActions from "../GeneralActions/GeneralActions";



const TabContent = ({ itinerary, currentTab }) => {
  //Add filter logic depending on the current tab
  return (
    <IonGrid style={{ display: "flex", minHeight: "100%", flexDirection: "column" }}>
      <IonRow style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <IonCol style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          {itinerary.map((dayItinerary, index) => (
            <DayBox
              itinerary={itinerary}
              dayItinerary={dayItinerary}
              key={dayItinerary.Data}
              isLastDay={itinerary.length - 1 === index}
              currentTab={currentTab}
            />
          ))}
          {currentTab === "All" && (
            <Box className="bl-padding-l-s">
              <GeneralActions itineraryData={itinerary} />
            </Box>
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default TabContent;
