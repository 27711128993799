import React, { useContext } from "react";
import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import RegisterCardPageLogic from "./RegisterCardPageLogic";
import BLStepper from "../../../shared/Ionic/Stepper/BLStepper";
import { card, fingerPrintOutline, reorderFour } from "ionicons/icons";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { SERVICES_TYPES } from "../../../constants/constants";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import ServicePageHeader from "../../../shared/Ionic/Wrappers/ServicePageHeader";
import { useHistory } from "react-router-dom";


const RegisterCardPage = () => {
  const history = useHistory();
  const { warnerServices } = useContext(NavigatorContext);
  const [activeStep, setActiveStep] = useState(0);
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);

  const { currentPage, pageData } = RegisterCardPageLogic();

  const stepperStepsMap = {
    CardRegistration: 0,
    CardAuthorisation: 1,
    CardSummary: 2,
  };

  const stepperOptions = {
    showStepper: 
      (pageData?.page === "CardSummary" && pageData?.addedNow) ||
      pageData?.page === "CardRegistration" ||
      pageData?.page === "CardAuthorisation",
    steps: [
      { label: "Card details", icon: reorderFour },
      { label: "Authorisation", icon: fingerPrintOutline },
      { label: "Add Card", icon: card },
    ],
  };

  useEffect(() => {
    setActiveStep(stepperStepsMap[pageData?.page]);
  }, [pageData]);

  useEffect(() => {
    if (!currentPage || warnerServices?.length === 0) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Register Card Page");
    } else {
      setIsLoaderOpened(false);
    }
  }, [currentPage, warnerServices]);

  return (
    <IonPage style={{ overflow: "auto" }}>
      <ServicePageHeader
        service={SERVICES_TYPES.REGISTER_CARD}
        onClick={() => history.goBack()}
      />
      <IonGrid>
        {stepperOptions.showStepper && (
          <IonRow
            style={{
              width: "100%",
              maxWidth: "535px",
              margin: "24px auto 4px auto",
            }}
          >
            <IonCol size="12">
              <BLStepper steps={stepperOptions.steps} activeStep={activeStep} />
            </IonCol>
          </IonRow>
        )}
        <IonRow className="ion-justify-content-center">{currentPage}</IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default RegisterCardPage;