import React, { useContext, useEffect } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { openOutline } from "ionicons/icons";
import { useDataContext } from "../../../context/DataContext";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import BLButton from "../../../shared/Ionic/Buttons/BLButton";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import { useTheme } from "../../../hooks/useTheme";
import ServicePageHeader from "../../../shared/Ionic/Wrappers/ServicePageHeader";
import { useHistory } from "react-router-dom";

const Golf = () => {
  const { typography } = useTheme();
  const history = useHistory();
  const { pagesConfiguration } = useDataContext();
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);

  useEffect(() => {
    if (!pagesConfiguration) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Golf Page");
    } else {
      setIsLoaderOpened(false);
    }
  }, [pagesConfiguration]);

  return (
    pagesConfiguration && (
      <IonGrid>
        <ServicePageHeader
          txt={pagesConfiguration.GolfPage?.Title}
          onClick={() => history.goBack()}
        />
        <IonGrid className="bl-flex-column bl-padding-s bl-padding-t-m">
          <IonRow>
            <IonCol>
              <BLParagraph
                txt={pagesConfiguration.GolfPage?.Description}
                color="text-grey"
                textVariant={typography.BODY_TEXT}
                markDown={!!pagesConfiguration.GolfPage?.Description}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-text-center bl-padding-t-xl">
            <IonCol className="bl-flex ion-justify-content-center">
              {pagesConfiguration.GolfPage?.Link && (
                <BLButton
                  icon={openOutline}
                  type="primary-icon-text"
                  content="Go to golf events"
                  dimensions={{ margin: ["l-s"] }}
                  slot="end"
                  onClick={() =>
                    window.open(pagesConfiguration.GolfPage?.Link, "_blank")
                  }
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonGrid>
    )
  );
};

export default Golf;
