import { useContext } from "react";
import { getDatesBetween } from "../helpingFunctions/dateTimeUtilities";

import { useDataContext } from "../context/DataContext";
import { FeatureFlagsContext } from "../context/FeatureFlagsContext";
import { SpaContext } from "../context/SpaContext";
import axiosInstance from "../url/createAxios";
import { DiningPartyContext } from "../context/DiningPartyContext";
import {
  DINING_PARTY_DETAILS_INITIAL,
  GLOBAL_DINING_PARTY_ATTRS,
} from "../views/children/DiningPartyPage/diningPartyConstants";

const RequestHook = () => {
  const instance = axiosInstance();
  const {
    reservationDetails,
    setItineraryDetails,
    setIsLoadingItinerary,
    setIsLoadingAvailability,
    setReservationDetails,
    setFacilitiesDataDetails,
    setReservationHotelDetails,
    setIsLoadingHotelData,
    setDynamicTexts,
    setPagesConfiguration,
    setConflictManagementTexts,
    setCancellationReasons,
    setFacilitiesData,
    setAvailability,
  } = useDataContext();
  const {
    setSpaAvailability,
    adjustSpaAvailability,
  } = useContext(SpaContext);
  const { setDiningPartyDetails, setGroupFetched } = useContext(DiningPartyContext);
  const { setFeatureFlags } = useContext(FeatureFlagsContext);

  const getInternalActivityItinerary = async (query) => {
    try {
      const { data } = await instance.get(`/activity-bookings`, {
        params: query,
      });

      return data.data;
    } catch (error) {
      console.error(
        `${new Date()} Error in getInternalActivityItinerary func in RequestHook.js file ${
          error.message
        }`
      );
    }
  };

  const getItinerary = async () => {
    try {
      setIsLoadingItinerary(true);
      const { data } = await instance.get(`/Hotel/Booking/Itinerary`);

      setItineraryDetails(data);
      setIsLoadingItinerary(false);
    } catch (error) {
      setIsLoadingItinerary(false);
      console.error(
        `${new Date()} Error in getItinerary in RequestHook.js file ${
          error.message
        }`
      );
    }
  };

  const getActivitiesAvailability = async (quantity) => {
    try {
      setIsLoadingAvailability(true);
      const { data } = await instance.get(
        `/Hotel/Booking/Facility/Availability?quantity=${quantity}`
      );

      // If a given hour is with availability of 0 in maestro, maestro doesnt return that time.
      // If there is a booking for such time, add it to the flow
      setAvailability(data);
      setIsLoadingAvailability(false);
    } catch (e) {
      setIsLoadingAvailability(false);
      console.error(
        `${new Date()} Error in getActivitiesAvailability in RequestHook.js file ${
          e.message
        }`
      );
    }
  };

  const getSpaAvailability = async (serviceId) => {
    const stayingDates = getDatesBetween(
      reservationDetails.ArrivalDate,
      reservationDetails.DepartureDate
    );

    for (const date of stayingDates) {
      try {
        const { data } = await instance.get(
          `/Hotel/Booking/Spa/Availability?searched_date=${date}&&service=${serviceId}`,
          {
            withCredentials: true,
          }
        );

        // If a given hour is with availability of 0 in maestro, maestro doesnt return that time.
        // If there is a booking for such time, add it to the flow
        adjustSpaAvailability(date, data, serviceId);

        setSpaAvailability((prevState) => {
          return { ...prevState, [date]: data };
        });
      } catch (e) {
        setSpaAvailability((prevState) => {
          return { ...prevState, [date]: { AM: null, PM: null } };
        });
        console.error(
          `${new Date()} Error in getSpaAvailability in RequestHook.js file for date ${date}:  ${
            e.message
          }`
        );
        continue;
      }
    }
  };

  const getGroup = async (reservationDetails) => {
    try {
      const { data } = await instance.get(`/group`);

      const itemsLength = data.items.length;
      for (let i = 0; i < itemsLength; i++) {
        const item = data.items[i];
        item.IsInGroup = true;
      }

      data.items.unshift(
        data.items.splice(
          data.items.findIndex((item) => item.IsGroupCreator),
          1
        )[0]
      );

      const filteredItems = data.items.filter(
        ({ CRSNumber }) => CRSNumber !== reservationDetails?.CRSNumber
      );

      const formattedItems = filteredItems.map((item) => {
        return {
          id: item.id,
          CRSNumber: item.CRSNumber,
          FirstName: item.GuestFirstName,
          LastName: item.GuestLastName,
          IsGroupCreator: item.IsGroupCreator,
          IsInGroup: item.IsInGroup,
          Adults: item.NumberOfGuests,
          ReservationNumber: item.ReservationNumber,
          changes: item.changes,
          NumberOfGuests: item.NumberOfGuests,
        };
      });
      if (data.groupId && data.groupName && formattedItems.length > 0) {
        setDiningPartyDetails({
          [GLOBAL_DINING_PARTY_ATTRS.ID]: data.groupId,
          [GLOBAL_DINING_PARTY_ATTRS.NAME]: data.groupName,
          [GLOBAL_DINING_PARTY_ATTRS.PARTICIPANTS]: formattedItems,
        });
      } else {
        setDiningPartyDetails(DINING_PARTY_DETAILS_INITIAL);
      }
      setGroupFetched(true);
      return data;
    } catch (e) {
      console.error(
        `${new Date()} Error in getGroup func at RequestHook.js file: ${
          e.message
        }`
      );
    }
  };

  const getWarnerServiceOptionByType = async (type) => {
    try {
      const { data } = await instance.get(
        `/warner-service-options/type/${type}`
      );

      return data;
    } catch (error) {
      console.error(
        `${new Date()} Error in getWarnerServiceOptionByType func in RequestHook ${
          error.message
        }`
      );
    }
  };

  const getWarnerServices = async () => {
    try {
      const { data } = await instance.get("/warner-services");

      return data.WarnerServices;
    } catch (error) {
      console.error(
        `${new Date()} Error in getWarnerServices func in RequestHook.js ${
          error.message
        }`
      );
    }
  };

  const validateDiningPartyReservation = async (surname, crsNumber) => {
    try {
      const { data } = await instance.post(`/group/validate`, {
        GuestLastName: surname,
        CRSNumber: crsNumber,
      });

      return data;
    } catch (error) {
      console.error(
        `${new Date()} Error in validateDiningPartyParticipant func in RequestHook.js ${
          error.message
        }`
      );
    }
  };

  const postCreateGroup = async (participantHashes, groupName) => {
    try {
      const { data } = await instance.post(`/group`, {
        participantHashes,
        GroupName: groupName,
      });

      return data;
    } catch (error) {
      console.error(
        `${new Date()} Error in postCreateGroup func in RequestHook.js ${
          error.message
        }`
      );
    }
  };

  const sendDiningBookingRequest = async (requestBody) => {
    try {
      return await instance.post(
        `/Hotel/Booking/Facility/Availability/Dining`,
        requestBody,
        { withCredentials: true }
      );
    } catch (error) {
      console.error(
        `${new Date()} Error in sendDiningBookingRequest func in RequestHook.js ${
          error.message
        }`
      );
    }
  };

  const getReservation = async () => {
    setIsLoadingHotelData(true);
    const { data } = await instance.get(`/Hotel`);

    const {
      Hotel,
      Reservation,
      Group,
      DynamicTexts,
      PagesConfiguration,
      ConflictManagementTexts,
      CancellationReasons,
    } = data;

    setDynamicTexts(DynamicTexts);
    setPagesConfiguration(PagesConfiguration);
    setConflictManagementTexts(ConflictManagementTexts);
    setCancellationReasons(CancellationReasons);

    setReservationDetails({
      ...Reservation,
      ...Group,
    });
    setReservationHotelDetails(Hotel);
    getActivitiesAvailability(Reservation?.Adults);

    const res = await instance.get(`/Hotel/Facilities`);
    const Facilities = res?.data?.Facilities || {};
    setFacilitiesData(Facilities);

    setIsLoadingHotelData(false);
    let allData = [];

    if (Facilities?.Activities?.All) {
      allData = [...allData, ...Facilities.Activities.All];
    }

    if (Facilities?.WellnessActivities?.All) {
      allData = [...allData, ...Facilities.WellnessActivities.All];
    }

    if (Facilities?.Spa?.All) {
      allData = [...allData, ...Facilities.Spa.All];
    }

    if (Facilities?.Entertainment?.Categorized) {
      allData = [...allData, ...Facilities.Entertainment.Categorized];
    }

    if (Facilities?.Breakfast?.All) {
      allData = [...allData, ...Facilities.Breakfast.All];
    }

    if (Facilities?.Dinner?.All) {
      allData = [...allData, ...Facilities.Dinner.All];
    }

    setFacilitiesDataDetails(allData);
  };

  const refreshReservationState = async () => {
    const { data } = await instance.get(`/Hotel/Booking/BookingsState`);

    setReservationDetails({ ...data.Group, ...data.Reservation });
  };

  const applyCancellationPolicy = async (bookings) => {
    try {
      const appliedPolicyBookings = await instance.post(
        `/Hotel/Booking/Facility/Availability/CancellationPolicyCheck`,
        {
          request: bookings,
        },
        { withCredentials: true }
      );
      return appliedPolicyBookings.data;
    } catch (error) {
      console.error(
        `${new Date()} Error in applyCancellationPolicy func in RequestHook.js ${
          error.message
        }`
      );
    }
  };

  const getFeatureFlags = async () => {
    try {
      const { data } = await instance.get("/feature-flags");

      setFeatureFlags(data);
    } catch (error) {
      console.error(
        `${new Date()} Error in getFeatureFlags func in RequestHook.js ${
          error.message
        }`
      );
    }
  };

  const updateLinkedReservations = async () => {
    try {
      const { data } = await instance.get(
        "/Hotel/Booking/RetrieveLinkedReservations",
        { withCredentials: true }
      );

      if (data.status === "success") {
        setReservationDetails({
          ...reservationDetails,
          LinkedReservations: data.remainingRes,
        });
      }

      return data;
    } catch (error) {
      console.error(
        `${new Date()} Error in updateLinkedReservations func in RequestHook.js ${
          error.message
        }`
      );
      return "failed";
    }
  };

  const deleteLinkedReservations = async (strapiResId) => {
    try {
      await instance.post(
        `/Hotel/Booking/DeleteLinkConnection/${strapiResId}`,
        { withCredentials: true }
      );
    } catch (error) {
      console.error(
        `${new Date()} Error in deleteLinkedReservations func in RequestHook.js ${
          error.message
        }`
      );
      return "failed";
    }
  };

  const checkReservationValidity = async (resId) => {
    try {
      const { data } = await instance.post(
        "/Hotel/Booking/CheckReservationValidity",
        { resId },
        { withCredentials: true }
      );

      return data;
    } catch (error) {
      console.error(
        `${new Date()} Error in checkReservationValidity func in RequestHook.js ${
          error.message
        }`
      );
      return { status: "failed" };
    }
  };

  return {
    getItinerary,
    getGroup,
    getInternalActivityItinerary,
    getWarnerServiceOptionByType,
    getWarnerServices,
    getSpaAvailability,
    getActivitiesAvailability,
    validateDiningPartyReservation,
    sendDiningBookingRequest,
    postCreateGroup,
    getReservation,
    refreshReservationState,
    applyCancellationPolicy,
    getFeatureFlags,
    updateLinkedReservations,
    checkReservationValidity,
    deleteLinkedReservations,
  };
};

export default RequestHook;
