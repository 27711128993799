import { useEffect, useState } from "react";
import { AUTHENTICATED_KEY } from "../constants/constants";
import RequestHook from "../hooks/RequestHook";

const RouteLogic = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem(AUTHENTICATED_KEY));

  const { getItinerary, getReservation, getFeatureFlags } =
    RequestHook();

  useEffect(() => {
    if (isAuthenticated) {
      getReservation();
      getItinerary();
      getFeatureFlags();
      return () => {
        null;
      };
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const checkUserData = () => {
      if (localStorage.getItem(AUTHENTICATED_KEY)) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };
  
    window.addEventListener('storage', checkUserData);
  
    return () => {
      window.removeEventListener('storage', checkUserData);
    };
  }, [isAuthenticated]);

  return {
    isAuthenticated
  };
};

export default RouteLogic;
