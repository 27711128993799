import React, { useState, createContext } from "react";

export const FeatureFlagsContext = createContext({});

export const FeatureFlagsProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState({});

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags,
        setFeatureFlags,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};