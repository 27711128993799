import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { DataProvider } from "./context/DataContext";
import { MaintenanceProvider } from "./context/MaintenanceContext";
import { FeatureFlagsProvider } from "./context/FeatureFlagsContext";

ReactDOM.render(
  <React.StrictMode>
    <MaintenanceProvider>
      <DataProvider>
        <FeatureFlagsProvider>
          <App />
        </FeatureFlagsProvider>
      </DataProvider>
    </MaintenanceProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
