import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { format } from "date-fns";
import { addCircle, closeCircle, pencil } from "ionicons/icons";
import React, { Fragment, useContext } from "react";
import { useDataContext } from "../../../../../../../context/DataContext";
import { SpaContext } from "../../../../../../../context/SpaContext";
import { getCurrentDate, getDiffTweenTodayTo } from "../../../../../../../helpingFunctions/dateTimeUtilities";
import BLIcon from "../../../../../../../shared/Ionic/BLIcon";
import BLButton from "../../../../../../../shared/Ionic/Buttons/BLButton";
import BLSelect from "../../../../../../../shared/Ionic/Inputs/BLSelect";
import BLDateTimeFormatParagraph from "../../../../../../../shared/Ionic/Paragraph/BLDateTimeFormatParagraph";
import BLParagraph from "../../../../../../../shared/Ionic/Paragraph/BLParagraph";
import DaysRowSeparateSelectionLogic from "./DaysRowSeparateSelectionLogic";
import { prepareDaysDropdownList } from "../../../../../../../helpingFunctions/prepareDaysDropdownList";
import { isDesktop } from "../../../../../../../helpingFunctions/utilities";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../../../../../../../constants/constants";
import { useTheme } from "../../../../../../../hooks/useTheme";

const DaysRowSeparateSelection = () => {
  const { typography } = useTheme();
  const { reservationDetails } = useDataContext();
  const { spaSelections, spaAvailability, daySelections, isEditMode } = useContext(SpaContext);
  const {
    hasDateSelected,
    handleSeparateSelection,
    addSeparateDays,
    removeSeparateSelection,
    updateStatus,
  } = DaysRowSeparateSelectionLogic();

  const getFieldType = (selIndex, dateIndex, when) => {
    if (hasDateSelected(selIndex, dateIndex) && !when.isEdited) {
      const isDisabled =  
        when.date !== "" &&
        (when.date < getCurrentDate() ||
        (when.time !== "" &&
        when.time.slice(0, 5).replace(":", "") < format(new Date(), "kkmm") &&
        when.date === getCurrentDate()));

      return (
        <IonRow
          className="bl-margin-b-s ion-align-items-center"
          style={{
            maxWidth: "350px",
            minWidth: "270px",
          }}
        >
          <IonCol
            className="bl-flex ion-align-items-center bl-margin-r-s "
            style={{
              background: isDesktop()
                ? "var(--ion-color-light)"
                : hasDateSelected(selIndex, dateIndex) &&
                  !isDesktop() &&
                  "var(--ion-color-white)",
              height: "48px",
              borderRadius: isDesktop() && "5px",
            }}
          >
            <BLDateTimeFormatParagraph
              date={spaSelections[selIndex].on[dateIndex].date}
              view={MAESTRO_BACKEND_NAMING_VIEWS.SPA}
            />
          </IonCol>

          <IonCol size="1">
            {spaSelections[selIndex].on.length <= reservationDetails.Nights ? (
              <>
                {!isDisabled && (!isEditMode ||
                  (isEditMode &&
                    spaAvailability &&
                    spaAvailability.hasOwnProperty(when.date))) && (
                  <BLIcon
                    iconSource={pencil}
                    color="secondary"
                    onClick={() => updateStatus(selIndex, dateIndex)}
                  />
                )}
              </>
            ) : (
              <>
                {dateIndex !== 0 && (
                  <BLIcon
                    iconSource={closeCircle}
                    color="secondary"
                    onClick={() => removeSeparateSelection(selIndex, dateIndex)}
                  />
                )}
              </>
            )}
          </IonCol>
        </IonRow>
      );
    } else {
      const showXSign =
        (dateIndex !== 0 || isEditMode) && when.action !== "remove";

      return (
        <IonRow
          className="bl-margin-b-s ion-align-items-center"
          style={{
            maxWidth: "350px",
            minWidth: "270px",
          }}
        >
          <IonCol className="bl-flex bl-margin-r-s ">
            <BLSelect
              placeholder="Select a day"
              dropdownList={prepareDaysDropdownList(
                when.date,
                spaSelections,
                selIndex,
                daySelections
              ).filter((day) => day.id >= getCurrentDate())}
              value={when.date}
              disable={when.action === "remove"}
              onChange={(v) => handleSeparateSelection(selIndex, dateIndex, v)}
            />
          </IonCol>

          {showXSign && (
            <IonCol size="1">
              <BLIcon
                iconSource={closeCircle}
                color="secondary"
                onClick={() => removeSeparateSelection(selIndex, dateIndex)}
              />
            </IonCol>
          )}
        </IonRow>
      );
    }
  };
  
  const passedDaysNumber = reservationDetails.ArrivalDate < getCurrentDate()
    ? Math.abs(getDiffTweenTodayTo(reservationDetails.ArrivalDate))
    : 0;
  
  const daysRowSeparatedContent = (
    <IonRow>
      <IonCol>
        {spaSelections.map((sel, selIndex) => (
          <IonGrid key={sel.uniqueKey || selIndex}>
            <IonRow
              className={`bl-margin-b-xs ${selIndex > 0 && "bl-margin-t-s"}`}
            >
              <IonCol>
                <BLParagraph
                  txt={`${
                    sel.guestName ===
                    reservationDetails.FirstName + " " + reservationDetails.LastName
                      ? sel.guestName + " (you)"
                      : sel.guestName
                  } `}
                  color="text-grey"
                  textVariant={typography.h4}
                  //newClasses="small bold"
                  //TODO: Unclear typography
                />
              </IonCol>
            </IonRow>
            {sel.on.map((when, dateIndex) =>
              <Fragment key={when.uniqueKey || dateIndex}>
                {getFieldType(selIndex, dateIndex, when)}
              </Fragment>
            )}

            {hasDateSelected(selIndex, 0) &&
              sel.action !== "remove" &&
              sel.on.length <= reservationDetails.Nights - passedDaysNumber + sel.on.filter(s => s.date !== "" && s.date < getCurrentDate()).length && (
              <IonRow className="bl-margin-t-s bl-margin-b-xs">
                <IonCol>
                  <BLButton
                    type="icon-text"
                    icon={addCircle}
                    iconSize="md"
                    content="Add more days"
                    onClick={() => addSeparateDays(selIndex)}
                  />
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        ))}
      </IonCol>
    </IonRow>
  );

  return {
    daysRowSeparatedContent,
  };
};

export default DaysRowSeparateSelection;
