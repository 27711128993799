import React from 'react';
import { IonGrid, IonRow } from "@ionic/react";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import BLIcon from "../../../../shared/Ionic/BLIcon";
import { CheckCircle } from "../../../../assets/SVGIcons";
import BLButton from "../../../../shared/Ionic/Buttons/BLButton";
import { closeCircle } from "ionicons/icons";
import { useHistory } from 'react-router-dom';

const CardSummary = ({ details, addedNow, staticTexts }) => {
  const history = useHistory();
  const topText = addedNow
    ? staticTexts?.Paragraph_NewlyAddedCardSummary
    : staticTexts?.Paragraph_ConfirmedCardSummary;

  return (
    <IonGrid
      className="bl-padding-b-m bl-padding-l-m bl-padding-r-m"
      style={{
        width: "100%",
        maxWidth: "535px",
      }}
    >
      {addedNow &&
        <IonRow className="ion-justify-content-center ion-padding">
          <BLIcon
            customIconSource={CheckCircle(56, 2.334)}
          />
        </IonRow>
      }
      <IonRow className={`ion-justify-content-center ${addedNow ? "" : "ion-padding-top"}`}>
        <BLParagraph
          txt={topText}
          color="text-grey"
          center={true}
          markDown={!!topText}
        />
      </IonRow>
      <IonRow className="bl-margin-t-m">
        <BLContentCardWrapper
          newClasses="bl-padding-t-s bl-padding-b-s bl-padding-l-m bl-padding-r-m"
          newStyles={{ borderRadius: "0px" }}
        >
          <IonGrid>
            <IonRow className="bl-padding-t-xxs bl-padding-b-xxs">
              <BLParagraph
                txt="Date of registration"
                color="black"
              //newClasses="semibold"
              //TODO: Unclear typography
              />
            </IonRow>
            <IonRow className="bl-padding-l-xs bl-padding-t-xxs bl-padding-b-xxs">
              <BLParagraph
                txt={details.transactionDate.split("-").reverse().join(".")}
                color="black"
              />
            </IonRow>
            <IonRow className="bl-padding-t-xxs bl-padding-b-xxs">
              <BLParagraph
                txt="Description"
                color="black"
              //newClasses="semibold"
              //TODO: Unclear typography
              />
            </IonRow>
            <IonRow className="bl-padding-l-xs bl-padding-t-xxs bl-padding-b-xxs">
              <BLParagraph
                txt="Registration of Credit Card"
                color="black"
              />
            </IonRow>
            <IonRow className="bl-padding-t-xxs bl-padding-b-xxs">
              <BLParagraph
                txt="Card type"
                color="black"
              //newClasses="semibold"
              //TODO: Unclear typography
              />
            </IonRow>
            <IonRow className="bl-padding-l-xs bl-padding-t-xxs bl-padding-b-xxs">
              <BLParagraph
                txt={details.cardType}
                color="black"
              />
            </IonRow>
            <IonRow className="bl-padding-t-xxs bl-padding-b-xxs">
              <BLParagraph
                txt="Last 4 digits of your card"
                color="black"
              //newClasses="semibold"
              //TODO: Unclear typography
              />
            </IonRow>
            <IonRow className="bl-padding-l-xs bl-padding-t-xxs bl-padding-b-xxs">
              <BLParagraph
                txt={`**** **** ${details.cardNums}`}
                color="black"
              />
            </IonRow>
          </IonGrid>
        </BLContentCardWrapper>
      </IonRow>
      <IonRow
        className="ion-justify-content-center bl-margin-t-m"
        style={{ gap: "16px" }}
      >
        {addedNow
          ? <BLButton
            content="Close"
            type="icon-text"
            icon={closeCircle}
            iconSize="md"
            color="primary"
            onClick={() => history.goBack()}
          />
          : <BLButton
            content="Go back"
            type="underlined"
            onClick={() => history.goBack()}
          />
        }
      </IonRow>
    </IonGrid>
  );
};

export default CardSummary;