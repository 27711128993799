import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { useDataContext } from "../../../../context/DataContext";
import { NavigatorContext } from "../../../../context/NavigatorContext";
import { SpaContext } from "../../../../context/SpaContext";
import {
  formatDate,
  formatTime,
} from "../../../../helpingFunctions/dateTimeUtilities";
import { formatPrice } from "../../../../helpingFunctions/formatPrice";
import { replacePlaceholders } from "../../../../helpingFunctions/replacePlaceholders";
import { ROUTE_VIEWS, mapTypeToRoute } from "../../../../routes/routeViews";
import BLAlertPopUp from "../../../../shared/Ionic/Boxes/Popup/BLAlertPopUp";
import BLSelect from "../../../../shared/Ionic/Inputs/BLSelect";
import BookedSummaryScreen from "../../../../shared/Ionic/ServicesComponents/BookedSummaryScreen";
import BookingSelectionHeaderDesktop from "../../../../shared/Ionic/ServicesComponents/BookingSelectionHeaderDesktop";
import BookingSelectionHeaderMobile from "../../../../shared/Ionic/ServicesComponents/BookingSelectionHeaderMobile";
import ConfirmationScreen from "../../../../shared/Ionic/ServicesComponents/ConfirmationScreen";
import BLContentCardWrapper from "../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import SpaExpandableRowsContent from "./SpaExpandableRowsContent";
import { isDesktop } from "../../../../helpingFunctions/utilities";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../../../../constants/constants";

const SpaBookingFlow = ({
  serviceDetails,
  handleSpaBookingRQ,
  retryFailsFunc,
  isFirstTimeBooker,
  isEditActionOnlyCancelled,
  hasCancellationPolicy,
  setHasCancellationPolicy,
  cancellationPolicyAlertsLength,
  cancellationPolicyAlerts,
  setCancellationPolicyAlerts,
}) => {
  const {
    spaSelections,
    setSpaSelections,
    cancelChangesHandler,
    spaDetails,
    setShowBookingSection,
    confirmationScreenSelections,
    setConfirmationScreenSelections,
    isProcessingBookings,
    daySelections,
    setPriceMsg,
    isEditMode,
    setIsEditMode,
  } = useContext(SpaContext);
  const { spaId } = useParams();
  const history = useHistory();
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const { dynamicTexts, cancellationReasons } = useDataContext();
  const location = useLocation();
  const [showCancellationPolicyAlert, setShowCancellationPolicyAlert] =
    useState(false);
  const [selectedCancellationReason, setSelectedCancellationReason] =
    useState(null);
  useEffect(() => {
    if (cancellationPolicyAlerts.length > 0) {
      setShowCancellationPolicyAlert(true);
      setSelectedCancellationReason(null);
    } else {
      setShowCancellationPolicyAlert(false);
    }
  }, [cancellationPolicyAlerts]);

  const replaceMsgPlaceholders = (params) => {
    if (!params) {
      return "";
    }

    const activityDate = formatDate(params.date);
    const time = formatTime(params.date.slice(0, 16), "h:mm");
    const price = params.activityPrice * params.percentage;

    const text =
      dynamicTexts?.Spa?.CancellationDetails
        ?.Popup_InsideSpaCancellationWindow || "";
    const result = replacePlaceholders(text, {
      SERVICE_NAME: params.serviceName,
      DATE: activityDate,
      TIME: time,
      PRICE: formatPrice(price),
      PERCENTAGE: params.percentage * 100 + "%",
      POLICY_HOURS: params.policyHours,
      NAME: params.name,
    });

    return result;
  };

  const getDropdownReasons = () => {
    const options = [];
    for (const reason of cancellationReasons.Spa) {
      options.push({
        id: reason.ReasonCode,
        Title: reason.ReasonDescription,
      });
    }
    return options;
  };

  return (
    <BLContentCardWrapper
      background={isDesktop() ? "light" : "white"}
      newStyles={{ height: "100%" }}
      dimensions={{ padding: ["t-s", "b-s", "l-s"] }}
    >
      <IonGrid
        className="bl-margin-b-s"
        style={{
          height: "100%",
          overflow: "auto",
          overflowX: "hidden",
          paddingRight: "25px",
          width: "100%",
        }}
      >
        <IonRow>
          <IonCol>
            {isDesktop() ? (
              <BookingSelectionHeaderDesktop
                serviceDetails={serviceDetails}
                onXSignClick={() => {
                  const isUserComesFromItinerary = isEditMode && history.location.state.isItineraryRedirect;
                  if (isEditMode) {
                    cancelChangesHandler();
                  }
                  setShowBookingSection(false);
                  setConfirmationScreenSelections(null);
                  setIsEditMode(false);

                  const redirectTo = spaDetails.All.find(
                    (row) => row.id === Number(spaId)
                  );

                  if (redirectTo?.CategoryId) {
                    redirectBetweenPages(
                      isUserComesFromItinerary ? ROUTE_VIEWS.ITINERARY :
                        mapTypeToRoute(
                          redirectTo.ObjType,
                          null,
                          redirectTo.CategoryId
                        )
                    );
                  } else {
                    redirectBetweenPages(isUserComesFromItinerary ? ROUTE_VIEWS.ITINERARY : ROUTE_VIEWS.SPAS);
                  }
                }}
                hideAddInfo={isFirstTimeBooker || isEditMode}
              />
            ) : (
              <BookingSelectionHeaderMobile
                onXSignClick={() => {
                  const isUserComesFromItinerary = isEditMode && history.location.state.isItineraryRedirect;
                  if (isEditMode) {
                    cancelChangesHandler();
                  }
                  setShowBookingSection(false);
                  setConfirmationScreenSelections(null);
                  setIsEditMode(false);

                  const redirectTo = spaDetails.All.find(
                    (row) => row.id === Number(spaId)
                  );

                  if (redirectTo?.CategoryId) {
                    redirectBetweenPages(
                      isUserComesFromItinerary ? ROUTE_VIEWS.ITINERARY :
                        mapTypeToRoute(
                          redirectTo.ObjType,
                          null,
                          redirectTo.CategoryId
                        ),
                      {
                        ...location?.state?.pageDetails,
                        categoryId: redirectTo.CategoryId,
                      }
                    );
                  } else {
                    redirectBetweenPages(ROUTE_VIEWS.SPAS);
                  }
                }}
                serviceDetails={serviceDetails}
                hideAddInfo={isFirstTimeBooker || isEditMode}
              />
            )}
          </IonCol>
        </IonRow>
        {(isProcessingBookings && !hasCancellationPolicy) ||
          confirmationScreenSelections ?
          (
            <IonRow>
              <IonCol>
                <ConfirmationScreen
                  serviceDetails={serviceDetails}
                  retryFailsFunc={retryFailsFunc}
                  isEditActionOnlyCancelled={isEditActionOnlyCancelled}
                  source={MAESTRO_BACKEND_NAMING_VIEWS.SPA}
                  confirmationScreenSelections={confirmationScreenSelections}
                  setConfirmationScreenSelections={
                    setConfirmationScreenSelections
                  }
                  isProcessingBookings={isProcessingBookings}
                  daySelections={daySelections}
                  setPriceMsg={setPriceMsg}
                  setIsEditMode={setIsEditMode}
                />
              </IonCol>
            </IonRow>
          ) : (
            <>
              {isFirstTimeBooker || isEditMode ? (
                <SpaExpandableRowsContent serviceDetails={serviceDetails} />
              ) : (
                <BookedSummaryScreen
                  serviceDetails={serviceDetails}
                  handleBookingRQ={handleSpaBookingRQ}
                  source={MAESTRO_BACKEND_NAMING_VIEWS.SPA}
                  selections={spaSelections}
                  setSelections={setSpaSelections}
                  cancellationPolicyAlertsLenght={cancellationPolicyAlertsLength}
                  hasCancellationPolicy={hasCancellationPolicy}
                  setHasCancellationPolicy={setHasCancellationPolicy}
                  setIsEditMode={setIsEditMode}
                  daySelections={daySelections}
                />
              )}
            </>
          )}
      </IonGrid>

      {showCancellationPolicyAlert && (
        <BLAlertPopUp
          title={
            cancellationPolicyAlertsLength > 1
              ? `Late cancellation (${cancellationPolicyAlertsLength -
              cancellationPolicyAlerts.length +
              1
              }/${cancellationPolicyAlertsLength})`
              : "Late cancellation"
          }
          description={replaceMsgPlaceholders(
            cancellationPolicyAlerts[0]?.cancellationPolicyMessage
          )}
          isOpen={showCancellationPolicyAlert}
          confirmBtnLabel="Confirm"
          dismissBtnType="underlined"
          confirmBtnType="primary"
          dismissBtnLabel="Skip"
          dismissHandler={() => {
            setHasCancellationPolicy(false);
            const cancellationPolicyAlertsCopy = JSON.parse(
              JSON.stringify(cancellationPolicyAlerts)
            );
            const spaService = cancellationPolicyAlertsCopy.shift();
            if (spaService) {
              const date = spaService.Date.slice(0, 10);
              const time = spaService.Date.split("T")[1].slice(0, 5);
              const spaSelectionsCopy = JSON.parse(
                JSON.stringify(spaSelections)
              );
              const selectionIndex = spaSelectionsCopy.findIndex(
                (item) =>
                  item.bookedFName === spaService.participantFirstName &&
                  item.bookedLName === spaService.participantLastName
              );
              const spaServiceToCancel = spaSelectionsCopy[
                selectionIndex
              ].on.find(
                (item) =>
                  item.bookedDate === date &&
                  item.bookedTime.slice(0, 5) === time
              );
              spaServiceToCancel.action = "none";
              const spaServiceBookedPerson = spaSelectionsCopy[selectionIndex];

              //When editing, return the changed time, date and name to the initial values
              if (spaServiceToCancel.bookedDate !== spaServiceToCancel.date) {
                spaServiceToCancel.date = spaServiceToCancel.bookedDate;
              }
              if (spaServiceToCancel.bookedTime !== spaServiceToCancel.time) {
                spaServiceToCancel.time = spaServiceToCancel.bookedTime;
              }
              if (
                spaServiceBookedPerson.bookedFName !==
                spaServiceBookedPerson.fName
              ) {
                spaServiceBookedPerson.fName =
                  spaServiceBookedPerson.bookedFName;
                spaServiceBookedPerson.guestName = `${spaServiceBookedPerson.fName} ${spaServiceBookedPerson.lName}`;
              }
              if (
                spaServiceBookedPerson.bookedLName !==
                spaServiceBookedPerson.lName
              ) {
                spaServiceBookedPerson.lName =
                  spaServiceBookedPerson.bookedLName;
                spaServiceBookedPerson.guestName = `${spaServiceBookedPerson.fName} ${spaServiceBookedPerson.lName}`;
              }

              setSpaSelections(spaSelectionsCopy);
              if (cancellationPolicyAlertsCopy.length === 0) {
                if (
                  spaSelectionsCopy.some((person) =>
                    person.on.some((item) => item.action !== "none")
                  )
                ) {
                  handleSpaBookingRQ(spaSelectionsCopy);
                }
                setCancellationPolicyAlerts([]);
              } else {
                setCancellationPolicyAlerts(cancellationPolicyAlertsCopy);
              }
              setShowCancellationPolicyAlert(false);
            }
          }}
          confirmHandler={async () => {
            setHasCancellationPolicy(false);
            const cancellationPolicyAlertsCopy = JSON.parse(
              JSON.stringify(cancellationPolicyAlerts)
            );
            const spaService = cancellationPolicyAlertsCopy[0];
            const date = spaService.Date.slice(0, 10);
            const time = spaService.Date.split("T")[1].slice(0, 5);
            const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
            const selectionIndex = spaSelectionsCopy.findIndex(
              (item) =>
                item.bookedFName === spaService.participantFirstName &&
                item.bookedLName === spaService.participantLastName
            );
            const spaServiceToCancel = spaSelectionsCopy[
              selectionIndex
            ].on.find(
              (item) =>
                item.bookedDate === date && item.bookedTime.slice(0, 5) === time
            );
            spaServiceToCancel.ReasonCode = selectedCancellationReason;
            setSpaSelections(spaSelectionsCopy);
            if (cancellationPolicyAlertsCopy.length === 1) {
              setCancellationPolicyAlerts([]);
              await handleSpaBookingRQ(spaSelectionsCopy);
            } else if (cancellationPolicyAlertsCopy.length > 1) {
              cancellationPolicyAlertsCopy.shift();
              setCancellationPolicyAlerts(cancellationPolicyAlertsCopy);
            }

            setShowCancellationPolicyAlert(false);
          }}
          confirmBtnDisabled={!selectedCancellationReason}
        >
          <IonGrid>
            <IonRow>
              <BLSelect
                placeholder="Select cancellation reason"
                dropdownList={getDropdownReasons()}
                value={selectedCancellationReason}
                onChange={(e) => setSelectedCancellationReason(e.target.value)}
              />
            </IonRow>
          </IonGrid>
        </BLAlertPopUp>
      )}
    </BLContentCardWrapper>
  );
};

export default SpaBookingFlow;
