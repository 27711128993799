import { DURATIONS } from "../../../../../assets/StaticObjects";

const durationLabel = (durationMins) => {
  const { display } = DURATIONS.find(({ mins }) => mins === durationMins) || {};
  if (display) {
    return display;
  }

  if (!/^[0-9]+$/.test(durationMins)) {
    if (durationMins === "blank") {
      return "";
    } else {
      return durationMins;
    }
  }

  return `${durationMins} mins`;
};

const expand_collapse = (setSelectedItemId, id) => {
  setSelectedItemId((prevState) => (prevState === id ? null : id));
};

export { durationLabel, expand_collapse };
