import moment from "moment";
import { COMPARE_RESULTS, DESKTOP_PLATFORM, MOBILE_PLATFORM, SERVICES_TYPES } from "../constants/constants";

const getPlatform = () => {
  return window.innerWidth < 821 ? MOBILE_PLATFORM : DESKTOP_PLATFORM;
};

const isMobile = () => {
  return getPlatform() === MOBILE_PLATFORM;
};

const isDesktop = () => {
  return getPlatform() === DESKTOP_PLATFORM;
};

const formatDate = (date) => {
  if (!date) return;

  return moment(date).format("Do MMMM YYYY");
};

const getDayOfWeek = (date) => {
  try {
    return moment(date).format("dddd");
  } catch (error) {
    console.error(
      `${new Date()} error in getDayOfWeek func inside CheckBookingConflicts.js file ${
        error.message
      }`
    );
  }
};

const isBookingInThePast = (
  bookingDay,
  bookingTime,
  currentDay,
  currentTime,
  type,
  arrivalDate
) => {
  if (type === SERVICES_TYPES.TREATS) {
    return currentDay >= arrivalDate;
  }
  return (
    bookingDay < currentDay ||
    (bookingDay === currentDay &&
      bookingTime?.substring(0, 5) <= currentTime?.substring(0, 5))
  );
};

const datesCompare = (date1, date2) => {
  try {
    const areEqual = date1.getTime() === date2.getTime();
    if (areEqual) {
      return COMPARE_RESULTS.EQUAL;
    }

    return date1.getTime() > date2.getTime()
      ? COMPARE_RESULTS.UP
      : COMPARE_RESULTS.DOWN;
  } catch (error) {
    console.error(
      `${new Date()} Error in datesCompare func in utilities.js ${
        error.message
      }`
    );
  }
};

export { formatDate, getDayOfWeek, isBookingInThePast, datesCompare, getPlatform, isMobile, isDesktop };
