import React, { useContext } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import { pricetag, today, checkmarkCircle, closeCircle } from "ionicons/icons";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import { TreatsContext } from "../../../../../context/TreatsContext";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";
import { getTotalPriceSummaryText } from "../../../../../helpingFunctions/GetTotalPriceSummaryText";
import { SERVICES_TYPES } from "../../../../../constants/constants";
import { useTheme } from "../../../../../hooks/useTheme";

const BookedTreatsDetailsCardMobile = ({ isSummaryScreen }) => {
  const { typography } = useTheme();
  const { treatsSelections, selectedService, confirmationScreenSelections, isProcessingBookings, daySelections } = useContext(TreatsContext);

  const selections = isSummaryScreen
    ? treatsSelections
    : confirmationScreenSelections;

  return (
    <>
      <IonRow className="bl-margin-t-m">
        <IonCol className="bl-padding-l-s">
          {selections.map((item, index) => (
            <IonGrid
              key={index}
              className="bl-flex bl-margin-b-s"
              style={{ gap: "20px" }}
            >
              <BLIcon color={"primary"} iconSource={today} iconSize="md" />
              <BLContentCardWrapper
                background={"white"}
                dimensions={{ padding: ["xs"] }}
                newStyles={{
                  width: "80%",
                }}
              >
                <IonRow>
                  <IonCol className="bl-flex ion-align-items-center">
                    <BLParagraph
                      txt={
                        daySelections.find((row) => row.id === item.date)
                          ?.fullTitle
                      }
                      textVariant={typography.BODY_TEXT}
                      // newClasses={`x-small bold`}
                      //TODO: Unclear typography
                    />
                  </IonCol>
                </IonRow>
                {item.options.map((opt, optIndex) => (
                  <IonRow
                    key={optIndex}
                    className="bl-margin-t-xxs bl-margin-b-xxs"
                  >
                    <IonCol
                      className={`bl-flex ion-align-items-center ${
                        !isSummaryScreen && "ion-justify-content-between"
                      }`}
                    >
                      <BLParagraph
                        txt={`${opt.bookedQuantity} x ${opt.bookedLocation}`}
                        textVariant={typography.BODY_TEXT}
                        typographyStyles={opt.action === "remove" ? { textDecoration: "line-through" } : {}}
                      />
                      
                      {!isSummaryScreen && (
                        <>
                          {isProcessingBookings ? (
                            <BLLoadingContainer newClasses="bl-margin-xxs" />
                          ) : (
                            <>
                              {opt.Status === "success" ? (
                                <BLIcon
                                  color="success"
                                  iconSource={checkmarkCircle}
                                  newClasses="bl-padding-r-xs"
                                />
                              ) : (
                                <BLIcon
                                  color="danger"
                                  iconSource={closeCircle}
                                  newClasses="bl-padding-r-xs"
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </IonCol>
                  </IonRow>
                ))}
              </BLContentCardWrapper>
            </IonGrid>
          ))}
        </IonCol>
      </IonRow>
      <IonRow className={"bl-margin-t-s bl-padding-l-s bl-padding-b-s"}>
        <IonCol>
          <BLContentCardWrapper
            newStyles={{
              gap: "5px",
              display: "flex",
              maxWidth: "400px",
              margin: "0 auto",
              alignItems: "center",
            }}
          >
            <BLIcon color={"primary"} iconSource={pricetag} iconSize={"md"} />
            {!confirmationScreenSelections ? getTotalPriceSummaryText(
              "summaryScreen",
              selectedService.Price,
              treatsSelections,
              SERVICES_TYPES.TREATS,
              typography
            ) : getTotalPriceSummaryText(
              "confirmationScreen",
              selectedService.Price,
              confirmationScreenSelections,
              SERVICES_TYPES.TREATS,
              typography
            )}
          </BLContentCardWrapper>
        </IonCol>
      </IonRow>
    </>
  );
};

export default BookedTreatsDetailsCardMobile;
