import { useEffect, useState } from "react";
import axiosInstance from "../../../url/createAxios";

const KioskLogic = () => {
  const instance = axiosInstance();

  const [kioskData, setKioskData] = useState({});

  const getKioskData = async () => {
    const { data } = await instance.get("/pages-configuration/kiosk-page");
    setKioskData(data);
  };

  useEffect(() => {
    getKioskData();
  }, []);
  
  return { kioskData };
};

export default KioskLogic;
