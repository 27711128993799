import { IonCol, IonGrid, IonModal, IonRow } from "@ionic/react";
import { chevronBack, chevronUp, close } from "ionicons/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { HEADER_SIZE, LEGAL_DOCS } from "../../constants/constants";
import { SideMenuContext } from "../../context/SideMenuContext";
import BLButton from "../../shared/Ionic/Buttons/BLButton";
import styles from "./LegalDocDialog.module.scss";
import { isDesktop } from "../../helpingFunctions/utilities";

const LegalDocDialog = () => {
  const { legalDocDialogType, setLegalDocDialogType } = useContext(SideMenuContext);
  const [showButton, setShowButton] = useState(false);
  const divRef = useRef(null);

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop > 55) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    if (legalDocDialogType) {
      window.OneTrust?.NoticeApi?.Initialized?.then(function () {
        window.OneTrust.NoticeApi.LoadNotices(
          [
            legalDocDialogType === LEGAL_DOCS.PRIVACY
              ? "https://privacyportal-eu-cdn.onetrust.com/75f6ad4c-3666-4142-a5d5-a8a3cdabae93/privacy-notices/draft/c13cf6e9-d917-4dc9-ab7f-340d1ffda550.json"
              : "https://privacyportal-eu-cdn.onetrust.com/75f6ad4c-3666-4142-a5d5-a8a3cdabae93/privacy-notices/draft/bdafa367-cd11-4ffe-8f3d-575f9541aed8.json"
          ],
          false
        );
        
      });
    }
  }, [legalDocDialogType]);

  return (
    <IonModal
      className={styles.Modal}
      style={{
        top: isDesktop() ? HEADER_SIZE : `calc(${HEADER_SIZE} + env(safe-area-inset-top))`,
      }}
      isOpen={!!legalDocDialogType}
      backdropDismiss={false}
    >
      <IonGrid
        className={styles.policies} 
        onScroll={handleScroll}
        ref={divRef}
      >
        <IonRow>
          <IonCol className="ion-padding bl-padding-b-l">
            <BLButton
              type="ui-small" 
              content={isDesktop() ? "Close" : "Back"} 
              icon={isDesktop() ? close : chevronBack}
              onClick={() => {
                setLegalDocDialogType(null);
              }} 
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{paddingBottom: "60px"}}>
            <div
              id={
                legalDocDialogType === LEGAL_DOCS.PRIVACY
                  ? "otnotice-c13cf6e9-d917-4dc9-ab7f-340d1ffda550"
                  : "otnotice-bdafa367-cd11-4ffe-8f3d-575f9541aed8"
              }
              className={`otnotice ${styles[legalDocDialogType === LEGAL_DOCS.PRIVACY ? "privacy" : "cookie"]}`}
            ></div>
            
          </IonCol>
        </IonRow>
        
      </IonGrid>
      {showButton &&
        <div className={styles.backToTop}>
          <BLButton 
            content="Back to top"
            type="ui"
            icon={chevronUp}
            onClick={
              () => divRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
          />
        </div>
      }
    </IonModal>
  );
};

export default LegalDocDialog;