import React from "react";
import WindowWidth from "../../hooks/WindowWidth";
import BlurryWrapper from "../UI/BulurryWrapper_OLD/BlurryWrapper";

import styles from "./InformationPage.module.css";

const InformationPage = ({
  children,
  image,
  className,
  new_styles,
  backdropFilter,
}) => {
  const {width} = WindowWidth();
  return (
    <>
      <div
        className={`${styles.InformationPage} ${className}`}
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          ...new_styles,
        }}
      >
        <BlurryWrapper
          imgUrl={image}
          new_styles={{
            background: "rgba(0, 0, 0, 0.2)",
            width: "66.198vw",
            minHeight: "63.241vh",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter,
          }}
          new_class={"BlurryWrapper__helper_maintenance"}
          whiteShadowStyle={{
            padding: width > 1324 ? "20px 50px" : "20px 15px",
          }}
        >
          {children}
        </BlurryWrapper>
      </div>
    </>
  );
};

export default InformationPage;
