import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import { formatPrice } from "../../../../helpingFunctions/formatPrice";
import { useTheme } from "../../../../hooks/useTheme";

const FolioSummaryBox = ({ folioDetails }) => {
  const { typography } = useTheme();
  return (
    <BLContentCardWrapper>
      <IonGrid className="bl-padding-xs bl-padding-r-s">
        <IonRow className="ion-text-end bl-padding-b-xs" style={{gap: "4px"}}>
          <IonCol>
            <BLParagraph
              color="text-grey"
              txt="Total charges: "
              textVariant={typography.h3}
              // newClasses={`medium bold`}
              //TODO: Unclear typography
              dimensions={{ padding: ["xxs"] }}
            />
          </IonCol>
          <IonCol size="auto" style={{ minWidth: "80px" }}>
            <BLParagraph
              color="text-grey"
              textVariant={typography.h3}
              txt={formatPrice(folioDetails.totalCharges)}
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-end bl-padding-b-xs" style={{gap: "4px"}}>
          <IonCol>
            <BLParagraph
              color="text-grey"
              txt="Total payments: "
              textVariant={typography.h3}
              // newClasses={`medium bold`}
              //TODO: Unclear typography
              dimensions={{ padding: ["xxs"] }}
            />
          </IonCol>
          <IonCol size="auto" style={{ minWidth: "80px" }}>
            <BLParagraph
              color="text-grey"
              textVariant={typography.h3}
              txt={formatPrice(folioDetails.totalPayments)}
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-text-end bl-padding-b-xs" style={{gap: "4px"}}>
          <IonCol>
            <BLParagraph
              color="primary"
              txt="Balance due: "
              textVariant={typography.h3}
              // newClasses={`medium bold`}
              //TODO: Unclear typography
              dimensions={{ padding: ["xxs"] }}
            />
          </IonCol>
          <IonCol size="auto" style={{ minWidth: "80px" }}>
            <BLParagraph
              color="primary"
              textVariant={typography.h3}
              txt={formatPrice(folioDetails.totalCharges - folioDetails.totalPayments)}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </BLContentCardWrapper>
  );
};

export default FolioSummaryBox;
