import { IonButton, IonCol, IonItem, IonMenuToggle, IonRow } from "@ionic/react";
import React from "react";
import BLParagraph from "../Paragraph/BLParagraph";
import styles from "./SideMenu.module.scss";
import { useTheme } from "../../../hooks/useTheme";

const SideMenuButton = ({
  btnClasses,
  onClick,
  disabled,
  btnText,
  noLines,
  isSelected,
}) => {
  const { typography } = useTheme();

  return (
    <IonMenuToggle>
      <IonItem
        lines={noLines && "none"}
        className="ion-no-padding"
        style={{
          "--background": isSelected
            ? "var(--ion-color-secondary)"
            : "transparent",
        }}
        onClick={onClick}
      >
        <IonButton
          className={`${btnClasses} ${styles.SideMenu_button}`}
          disabled={disabled}
        >
          <IonRow >
            <IonCol>
              <BLParagraph
                txt={btnText}
                textVariant={typography.BODY_TEXT}
                color={isSelected ? "white" : "text-grey"}
              />
            </IonCol>
          </IonRow>
        </IonButton>
      </IonItem>
    </IonMenuToggle>
  );
};

export default SideMenuButton;
