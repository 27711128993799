import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import BLIcon from "../BLIcon";

import "swiper/swiper.min.css";
import styles from "./BLImageCarousel.module.scss";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";

// sliderContent should be an array of object containing {id, content} as the required attributes
const BLGeneralSlider = ({
  sliderOptions = [],
  slidesRef,
  setSlidesRef,
  getActiveObject,
  disableNext,
  disablePrev,
}) => {
  if (sliderOptions.length === 0) {
    return null;
  }

  const handleNext = () => {
    slidesRef?.slideNext();
    activeObjectProvider(slidesRef.activeIndex);
  };
  const handlePrev = () => {
    slidesRef?.slideTo(slidesRef.activeIndex);
    activeObjectProvider(slidesRef.activeIndex);
  };

  const activeObjectProvider = async (activeIndex) => {
    try {
      const obj = sliderOptions[activeIndex];
      getActiveObject(obj);
    } catch (error) {
      console.error(
        `${new Date()} Error in activeObjectProvider func in BLGeneralSlider.js ${
          error.message
        }`
      );
    }
  };

  const prevBtn = (
    <BLIcon
      newClasses={`general-slider-prev`}
      iconSource={chevronBackOutline}
      disable={disablePrev}
      onClick={disablePrev ? null : handlePrev}
    />
  );

  const nextBtn = (
    <BLIcon
      newClasses={`general-slider-next`}
      iconSource={chevronForwardOutline}
      disable={disableNext}
      onClick={disableNext ? null : handleNext}
    />
  );

  return (
    <>
      {prevBtn}
      <Swiper
        onInit={(ev) => {
          setSlidesRef(ev);
        }}
        className={styles.swiperContainer}
        centeredSlides={true}
        navigation={{
          nextEl: `.general-slider-next`,
          prevEl: ".general-slider-prev",
        }}
        modules={[Navigation]}
        allowSlideNext={!disableNext}
        allowSlidePrev={!disablePrev}
        onSlideChange={(e) => {
          const newActiveIndex = e.activeIndex > e.previousIndex 
            ? e.activeIndex 
            : e.previousIndex - 1;
          activeObjectProvider(newActiveIndex);
          e.activeIndex = newActiveIndex;
        }}
      >
        {sliderOptions.map(({ id, content }) => {
          return (
            <SwiperSlide
              key={id}
              className={`${styles.swiperSlide} ion-text-center`}
            >
              {content}
            </SwiperSlide>
          );
        })}
      </Swiper>
      {nextBtn}
    </>
  );
};

export default BLGeneralSlider;
