import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { calendar } from "ionicons/icons";
import React, { useContext } from "react";
import { SpaContext } from "../../../../../../context/SpaContext";
import BLIcon from "../../../../../../shared/Ionic/BLIcon";
import BLButton from "../../../../../../shared/Ionic/Buttons/BLButton";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import DaysRowLogic from "./DaysRowLogic";
import DaysRowSeparateSelection from "./Separate/DaysRowSeparateSelection";
import DaysRowTogetherSelection from "./Together/DaysRowTogetherSelection";
import { isDesktop } from "../../../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../../../hooks/useTheme";

const DaysRow = () => {
  const { typography } = useTheme();
  const { spaSelections, option, setOption, isEditMode } = useContext(SpaContext);
  const { collapsedRowContent } = DaysRowLogic();
  const { daysRowTogetherContent } = DaysRowTogetherSelection();
  const { daysRowSeparatedContent } = DaysRowSeparateSelection();

  const daysRowCollapsed = (
    <IonGrid>
      <IonRow className={option !== "separate" && "ion-align-items-center"}>
        <BLIcon iconSource={calendar} color="primary" iconSize="md" />
        <IonCol
          className="bl-flex"
          style={{ flexDirection: "column", gap: "5px" }}
        >
          {collapsedRowContent}
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const daysRowExpanded = (
    <IonGrid>
      <IonRow>
        <IonCol className="bl-flex ion-align-items-center">
          <BLIcon iconSource={calendar} color="primary" iconSize="md" />
          <BLParagraph
            txt="Day(s)"
            color="primary"
            textVariant={typography.h3}
            // newClasses={`medium bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
            }}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const daysRowContent = (
    <IonGrid className="ion-align-items-center">
      {(option === "together" || spaSelections.length === 1) && !isEditMode ? (
        daysRowTogetherContent
      ) : option === "separate" || isEditMode ? (
        daysRowSeparatedContent
      ) : (
        <>
          <IonRow>
            <IonCol>
              <BLParagraph
                txt="Would you like to go together or separate days or times?"
                color="text-grey"
                textVariant={typography.BODY_TEXT}
              />
            </IonCol>
          </IonRow>
          <IonRow
            className={`bl-flex bl-margin-t-m ion-align-items-center ion-justify-content-${
              isDesktop() ? "start" : "center"
            }`}
            style={{ gap: "20px" }}
          >
            <BLButton
              content="Together"
              type="outlined"
              onClick={() => setOption("together")}
            />

            <BLButton
              content="Separate"
              type="outlined"
              onClick={() => setOption("separate")}
            />
          </IonRow>
        </>
      )}
    </IonGrid>
  );

  return {
    daysRowCollapsed,
    daysRowExpanded,
    daysRowContent,
  };
};

export default DaysRow;
