import React, { useContext } from "react";
import { SpaContext } from "../../../../../context/SpaContext";
import { IonModal, IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import { closeCircle, time, pricetag } from "ionicons/icons";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import { formatPrice } from "../../../../../helpingFunctions/formatPrice";
import { useTheme } from "../../../../../hooks/useTheme";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import { useDataContext } from "../../../../../context/DataContext";
import BLLink from "../../../../../shared/Ionic/Links/BLAnchor/BLLink";
import { formatDuration } from "../../../../../helpingFunctions/formatDuration";

const BLAdditionalSpaDetailsPopupBox = ({
  showAlert,
  dismissAlert,
  images,
  header,
  duration,
  price,
  longDescription,
  initialLoad,
  hasBooking,
  setSelectedService,
}) => {
  const { typography } = useTheme();
  const { isLoadingItinerary } = useDataContext();
  const {
    setShowContraindications,
    setShowBookingSection,
    setShowAdditionalDetails,
  } = useContext(SpaContext);
  return (
    <IonModal
      className="bl-popup-details"
      isOpen={showAlert}
      backdropDismiss={false}
      showBackdrop
    >
      <IonGrid className="bl-flex-column" style={{ height: "inherit" }}>
        <IonRow className="bl-flex ion-align-items-center bl-padding-s">
          <IonCol className="ion-text-center">
            <BLParagraph
              txt={header}
              color="text-grey"
              textVariant={typography.h2}
              // newClasses={`xx-large bold crimson-pro`}
              //TODO: Unclear typography
            />
          </IonCol>
          <IonCol size="auto">
            <BLIcon
              iconSource={closeCircle}
              color="secondary"
              iconSize="md"
              onClick={() => {
                dismissAlert(false);
                setSelectedService(null);
              }}
            />
          </IonCol>
        </IonRow>

        <IonRow style={{ minHeight: "30%" }}>
          <IonCol>
            <BLImageCarousel content={images} />
          </IonCol>
        </IonRow>

        <IonRow
          className="ion-justify-content-between bl-padding-s"
          style={{ gap: "15px" }}
        >
          <IonCol
            className="bl-flex ion-align-items-center ion-justify-content-start"
            style={{ gap: "5px" }}
          >
            <BLIcon iconSource={pricetag} color="primary" />
            <BLParagraph
              txt={formatPrice(price)}
              color="text-grey"
              textVariant={typography.h4}
            />
          </IonCol>
          <IonCol
            className="bl-flex ion-align-items-center ion-justify-content-end"
            style={{ gap: "5px" }}
          >
            <BLIcon iconSource={time} color="primary" />
            <BLParagraph
              txt={formatDuration(duration)}
              color="text-grey"
              textVariant={typography.h4}
            />
          </IonCol>
        </IonRow>

        <IonRow className="bl-margin-xs bl-margin-l-m">
          <IonCol>
            <BLParagraph
              txt="Description"
              color="text-grey"
              textVariant={typography.h5}
              // newClasses={`x-small bold open-sans`}
              //TODO: Unclear typography
            />
          </IonCol>
        </IonRow>
        <IonGrid
          className="bl-margin-xs bl-margin-l-m"
          style={{ overflow: "auto" }}
        >
          <IonRow className="bl-margin-xs">
            <BLParagraph
              txt={longDescription}
              color="text-grey"
              textVariant={typography.BODY_TEXT}
              markDown={!!longDescription}
            />
          </IonRow>
        </IonGrid>
        <IonRow className="bl-margin-xs bl-margin-l-m bl-margin-t-s">
          <IonCol>
            <BLLink
              txt="View contraindications"
              color="secondary"
              onClick={() => setShowContraindications(true)}
            />
          </IonCol>
        </IonRow>
        <IonRow className="bl-margin-t-s bl-margin-b-s">
          {isLoadingItinerary ? (
            <IonCol className="ion-text-center">
              <BLLoadingContainer newClasses="bl-margin-xxxs" />
            </IonCol>
          ) : (
            <IonCol className="bl-flex ion-justify-content-center">
              <BLButton
                content={hasBooking ? "Change booking" : "Book now"}
                type="primary"
                onClick={() => {
                  initialLoad();
                  setShowBookingSection(true);
                  setShowAdditionalDetails(false);
                }}
              />
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default BLAdditionalSpaDetailsPopupBox;
