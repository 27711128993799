import { MAESTRO_BACKEND_NAMING_VIEWS } from "../../../constants/constants";


/** 
    Method for verifying if a spa selection for a given name is already booked and overllaping in the terms of a chained conflict 
  */
const verifySpaSelectionReplaceCondition = (
  restConflicts,
  selection,
  setDisableReplaceInChainedSpaView
) => {
  let hasMatchingNameSpaSel = false;

  hasMatchingNameSpaSel = restConflicts.find(
    (row) =>
      ((row.confirmedBookingDetails.ParticipantFirstName ===
        selection.selectionFirstName &&
        row.confirmedBookingDetails.ParticipantLastName ===
          selection.selectionLastName) ||
        (row.confirmedBookingDetails.FirstName ===
          selection.selectionFirstName &&
          row.confirmedBookingDetails.LastName ===
            selection.selectionLastName)) &&
      row.confirmedBookingDetails.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA
  );

  setDisableReplaceInChainedSpaView(!!hasMatchingNameSpaSel);

  return !!hasMatchingNameSpaSel;
};

export { verifySpaSelectionReplaceCondition };
