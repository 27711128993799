import { IonGrid, IonRow } from "@ionic/react";
import React from "react";
import BLButton from "../../../../shared/Ionic/Buttons/BLButton";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import { isDesktop } from "../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../hooks/useTheme";

const RegisterCardInitial = ({ details, changePage}) => {
  const { typography } = useTheme();
  return (
    <IonGrid>
      <IonRow 
        className="ion-justify-content-center"
        style={{marginTop: "40px"}}
      >
        <BLParagraph
          txt="You have not registered a card" //Get text from strapi
          color="text-grey"
          textVariant={typography.BODY_TEXT}
        />
      </IonRow>
      <IonRow 
        className="ion-justify-content-center"
        style={{marginTop: isDesktop() ? "60px" : "32px"}}
      >
        <BLButton 
          content="Add card now"
          type="primary"
          onClick={() => changePage({ page: "CardRegistration", details })}
        />
      </IonRow>
    </IonGrid>
  );
};

export default RegisterCardInitial;