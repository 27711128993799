import { useContext, useEffect, useState } from "react";
import { DiningBookingContext } from "../../../../../context/DiningBookingContext";
import { DINING_SELECTIONS_ATTR } from "../../DiningBookingConstants";
import WindowWidth from "../../../../../hooks/WindowWidth";

const RestaurantsLogic = () => {
  const { width } = WindowWidth();
  const isDesktop = width >= 821;
  const {
    restaurantOptions,
    selectedDateSelections,
    updateDaySelection,
    clickedRestaurantId,
    setClickedRestaurantId,
  } = useContext(DiningBookingContext);
  const [currentRestaurants, setCurrentRestaurants] = useState([]);
  const [moreInfoRestaurantDetails, setMoreInfoRestaurantDetails] =
    useState(null);
  const [selectedResId, setSelectedResId] = useState(null);

  const filterNecessaryItems = () => {
    try {
      const items = restaurantOptions.find(
        (row) => row.date === selectedDateSelections.date
      );

      setCurrentRestaurants(
        items.restaurants.sort(
          (a, b) =>
            (a.details.OrderPriority === null) -
              (b.details.OrderPriority === null) ||
            a.details.OrderPriority - b.details.OrderPriority ||
            a.details.id - b.details.id
        )
      );
    } catch (error) {
      console.error(
        `${new Date()} Error in filterNecessaryItems func in RestaurantsLogic.js ${
          error.message
        }`
      );
    }
  };

  const getRestTileSize = (restaurant) => {
    let size = 0;

    if (isDesktop) {
      const totalRestaurants = currentRestaurants.length;
      // If desktop and a restaurant has been selected
      if (clickedRestaurantId) {
        // If its the selected restaurant
        if (clickedRestaurantId === restaurant.id) {
          if (totalRestaurants === 3) {
            size = "7";
          } else {
            size = "6.7";
          }
        } else {
          // If its the other restaurant
          if (totalRestaurants === 3) {
            size = "12";
          } else {
            size = "4";
          }
        }
      } else {
        if (totalRestaurants === 3) {
          size = "4";
        } else {
          if (totalRestaurants === 1) {
            size = "7";
          } else {
            size = "5.5";
          }
        }
      }
    }

    return size;
  };

  const handleResSelection = (resId) => {
    try {

      if (selectedDateSelections.isSkipped) {
        return;
      }

      const isSelected =
        selectedDateSelections[DINING_SELECTIONS_ATTR.RESTAURANT] === resId;

      isSelected
        ? updateDaySelection(DINING_SELECTIONS_ATTR.RESTAURANT, null)
        : updateDaySelection(DINING_SELECTIONS_ATTR.RESTAURANT, resId);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleResSelection func RestaurantExpanded.js ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    if (restaurantOptions.length > 0 && selectedDateSelections.date) {
      filterNecessaryItems();
    }
  }, [restaurantOptions, selectedDateSelections]);

  return {
    currentRestaurants,
    clickedRestaurantId,
    selectedResId,
    moreInfoRestaurantDetails,
    setMoreInfoRestaurantDetails,
    setClickedRestaurantId,
    setSelectedResId,
    getRestTileSize,
    handleResSelection,
  };
};

export default RestaurantsLogic;
