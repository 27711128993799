import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import { NavigatorContext } from "../../../../../context/NavigatorContext";
import { ROUTE_VIEWS } from "../../../../../routes/routeViews";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import ParticipantDisplay from "../ParticipantDisplay/ParticipantDisplay";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";

const DiningPartyParticipant = () => {
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const {
    diningPartyDetails: { name },
  } = useContext(DiningPartyContext);

  return (
    <BLContentCardWrapper
      dimensions={{ margin: ["t-m"], padding: ["l-xs", "r-xs", "t-s", "b-s"] }}
    >
      <ParticipantDisplay title={`You are part of ${name}`} />
      <IonGrid>
        <IonRow className={"ion-text-center"}>
          <IonCol>
            <BLButton
              content={"Go back"}
              type={"underlined"}
              onClick={() => redirectBetweenPages(ROUTE_VIEWS.DINING)}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </BLContentCardWrapper>
  );
};

export default DiningPartyParticipant;
