import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { chevronForward, location, time } from "ionicons/icons";
import React from "react";
import { INITIAL_DATE } from "../../../../../constants/constants";
import { useDataContext } from "../../../../../context/DataContext";
import { formatTime } from "../../../../../helpingFunctions/dateTimeUtilities";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import BLItem from "../../../../../shared/Ionic/Cards/BLItem/BLItem";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import { LAST_FEW_REMAINING_BREAKPOINT } from "../../constants";
import { useTheme } from "../../../../../hooks/useTheme";

const ShowsSummaryCard = ({
  onClick,
  title,
  timeText,
  locationText,
  isSelected,
  isDisabled,
  isClicked,
  availability,
  isAlreadyBooked
}) => {
  const { typography } = useTheme();
  const { reservationDetails } = useDataContext();
  const isLastFew = reservationDetails.Adults <= availability && availability <= LAST_FEW_REMAINING_BREAKPOINT;
  const isNotAvailable = reservationDetails.Adults > availability && !isAlreadyBooked;
  
  return (
    <BLItem
      onClick={onClick}
      newWrapperStyles={{
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: `0px 3px 6px var(--ion-color-box-shadow)${
          isSelected || isClicked()
            ? ", inset 0px 0px 0px 2px var(--ion-color-secondary)"
            : ""
        }`,
        borderRadius: 5,
        cursor: isDisabled() ? "not-allowed" : "pointer",
      }}
      isSelected={{
        status: isSelected,
        showBorder: false,
        position: "left",
      }}
      isLastFew={isLastFew}
      isNotAvailable={isNotAvailable}
      mainContent={
        <IonGrid
          className={`${
            isDisabled() || isNotAvailable ? "bl-disable-fade" : ""
          }${
            isLastFew || isNotAvailable ? "" : " ion-padding-top"
          } ion-padding-bottom`}
        >
          <IonRow
            className="ion-justify-content-center"
            style={{ padding: `0 30px 0 25px` }}
          >
            <BLParagraph
              color={"primary"}
              txt={title}
              textVariant={typography.h2}
              // newClasses={`large bold crimson-pro`}
              //TODO: Unclear typography
            />
            {isClicked() && (
              <BLIcon
                newStyles={{ paddingTop: 4, position: "absolute", right: "0" }}
                color={"secondary"}
                iconSource={chevronForward}
              />
            )}
          </IonRow>
          <IonRow
            className={"bl-margin-t-xs ion-padding-start ion-padding-end"}
          >
            <IonCol
              style={{ flex: "0 0 105px" }}
              className={
                "bl-flex ion-align-items-center ion-justify-content-start"
              }
            >
              <BLIcon
                color="primary"
                iconSource={time}
                newClasses="bl-padding-r-xs"
              />
              <BLParagraph
                txt={formatTime(`${INITIAL_DATE}T${timeText.slice(0, 5)}`, true)}
                textVariant={typography.BODY_TEXT}
                newStyles={{ textAlign: "left" }}
              />
            </IonCol>
          </IonRow>
          <IonRow
            className="bl-margin-t-xs ion-padding-start ion-padding-end"
          >
            <IonCol
              size="auto"
              className="bl-flex ion-align-items-center ion-justify-content-start"
              style={{
                flex: "0 0 calc(100% - 121px)",
                display: "grid",
                gridAutoFlow: "column",
              }}
            >
              <BLIcon
                color={"primary"}
                iconSource={location}
                newClasses="bl-padding-r-xs"
              />
              <BLParagraph
                txt={locationText}
                newStyles={{ textAlign: "left" }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      }
      contentDimensions={{ padding: [] }}
    />
  );
};

export default ShowsSummaryCard;
