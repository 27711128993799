import { useContext, useEffect } from "react";

import { DiningPartyContext } from "../../../../../context/DiningPartyContext";
import {
  DINING_PARTY_NAME_ATTR,
  DINING_PARTY_INIT_FORM,
  DINING_PARTY_INITIAL_FORM_STATE,
  DINING_PARTY_MODALS,
  INIT_RESPONSE_DATA
} from "../../diningPartyConstants";

const DiningPartyFormLogic = () => {
  const {
    setFormContent,
    diningPartyFormData,
    setDiningPartyFormData,
    setResponse,
    setShowDiningPartyParticipantForm,
    setDiningPartyDisplay,
    diningPartyDetails,
  } = useContext(DiningPartyContext);

  const { id, name, participants } = diningPartyDetails;

  const formDataSetup = () => {
    try {
      const hasUsedFormSuccessfully = participants.length > 0;

      if (hasUsedFormSuccessfully) {
        const [diningPartySection] = DINING_PARTY_INIT_FORM;

        if (id) {
          setDiningPartyFormData((prevState) => {
            return {
              ...prevState,
              [DINING_PARTY_NAME_ATTR]: name,
            };
          });
          return;
        }

        setFormContent({
          data: [diningPartySection],
          isEntireForm: false,
        });
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in formDataSetup func in DiningPartyFormLogic.js ${
          error.message
        }`
      );
    }
  };

  const resetForm = () => {
    try {
      if (id) {
        setDiningPartyFormData({
          ...DINING_PARTY_INITIAL_FORM_STATE,
          [DINING_PARTY_NAME_ATTR]: name,
        });
      } else {
        setDiningPartyFormData({
          CRSNum: "",
          Surname: "",
          diningPartyName: diningPartyFormData.diningPartyName,
        });
      }

      setResponse(INIT_RESPONSE_DATA);
      setShowDiningPartyParticipantForm(false);

      if (diningPartyDetails.participants.length === 0) {
        setDiningPartyDisplay(DINING_PARTY_MODALS.INIT);
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in diningForm func in DiningPartyFormLogic.js ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    formDataSetup();
  }, [diningPartyDetails]);

  return { resetForm };
};

export default DiningPartyFormLogic;
