import React from "react";
import { IonCol, IonRow } from "@ionic/react";
import { useDataContext } from "../../../context/DataContext";
import { useTheme } from "../../../hooks/useTheme";
import BLParagraph from "../Paragraph/BLParagraph";
import BLLink from "../Links/BLAnchor/BLLink";

const TermsAndConditions = () => {
  const { typography } = useTheme();
  const { pagesConfiguration } = useDataContext();

  return (
    <IonRow>
      <IonCol className="ion-text-center">
        <BLParagraph
          txt="Review our "
          textVariant={typography.BODY_TEXT}
          typographyStyles={{ display: "inline" }}
          color="text-grey"
        />
        <BLLink
          txt="Terms & Conditions and Cancellation Policy"
          color="secondary"
          href={pagesConfiguration?.TermsAndConditions.Link}
        />
      </IonCol>
    </IonRow>
  );
};

export default TermsAndConditions;
