import { useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MaintenanceContext } from "../context/MaintenanceContext";
import { ROUTE_VIEWS } from "../routes/routeViews";

const useMaintenance = (redirectBetweenPages) => {
  const { setMaintenanceData } = useContext(MaintenanceContext);
  const location = useLocation();
  const history = useHistory();
  const isInMaintenance = location.pathname.includes(ROUTE_VIEWS.MAINTENANCE);

  let sse = null;
  const checkMaintenanceStatus = async () => {
    try {
      sse = new EventSource(
        `${process.env.REACT_APP_BACK_ENDPOINT}/pages-configuration/maintenance-page`,
        { withCredentials: true }
      );
      sse.onerror = function (error) {
        console.error(
          `${new Date()} Error in the EventSource ${JSON.stringify(error)}`
        );
      };
      sse.addEventListener("underMaintenance", (e) => {
        const parsedData = JSON.parse(e.data);

        if (parsedData.Enabled) {
          setMaintenanceData(parsedData);

          if (isInMaintenance) {
            return;
          }

          history.push(ROUTE_VIEWS.MAINTENANCE);
        } else {
          if (!isInMaintenance) {
            return;
          }
          setMaintenanceData(null);
          redirectBetweenPages(ROUTE_VIEWS.LANDING_PAGE);
        }
      });
    } catch (error) {
      console.error(
        `${new Date()} Error in checkMaintenanceStatus func in RouteLogic.js file ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    checkMaintenanceStatus();
    return () => {
      sse.close();
    };
  }, [location.pathname]);

  return {  };
};

export default useMaintenance;
