import { IonCol, IonIcon } from "@ionic/react";
import { Tooltip } from "@mui/material";
import React from "react";
import { isDesktop } from "../../../../helpingFunctions/utilities";

const BLTooltip = ({
  txt,
  icon,
  iconColor = "secondary",
  iconClasses = "bl-icon-xxsm",
  disableHover = false,
  open,
  handleOpen,
  handleClose,
  children = <></>
}) => {
  return (
    <>
      <Tooltip
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        placement="bottom"
        enterDelay={isDesktop() ? 300 : 0}
        enterTouchDelay={0}
        leaveTouchDelay={900000}
        arrow
        title={txt}
        disableHoverListener={disableHover}
        PopperProps={{
          sx: {
            "& .MuiTooltip-tooltip": {
              fontSize: "14px",
              letterSpacing: 0.5,
              backgroundColor: "var(--ion-color-primary)"
            },
            "& .MuiTooltip-arrow": {
              color: "var(--ion-color-primary)"
            },
            "& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom": {
              margin: "8px !important",
            },
          },
        }}
      >
        {txt && icon ? (
          // Wrapping the icon in IonCol is a workaround to avoid the browser tooltip of the element.
          <IonCol size="auto">
            <IonIcon
              className={iconClasses}
              style={{ pointerEvents: "none" }}
              icon={icon}
              color={iconColor}
            />
          </IonCol>
        ) : (
          children
        )}
      </Tooltip>
    </>
  );
};

export default BLTooltip;
