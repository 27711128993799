const INTERNAL_NON_BOOKED_TXT = "Add reminder to itinerary";
const INTERNAL_REMOVE_BOOKED_TXT = "Remove from itinerary";

const DEFAULT_FILTER_PLACEHOLDER = "Please select an option";

const TV_TITLE = "tv";
const DESKTOP_TITLE = "desktop";
const MOBILE_TITLE = "mobile";
const VERTICAL_ORDER = "vertical";
const HORIZONTAL_ORDER = "horizontal";

const ALL_DAY_ACTIVITY = "all_day_activity";

export {
  INTERNAL_NON_BOOKED_TXT,
  DEFAULT_FILTER_PLACEHOLDER,
  INTERNAL_REMOVE_BOOKED_TXT,
  TV_TITLE,
  DESKTOP_TITLE,
  MOBILE_TITLE,
  VERTICAL_ORDER,
  HORIZONTAL_ORDER,
  ALL_DAY_ACTIVITY,
};
