import React from "react";

import Heading from "../../../../../../shared/Heading_OLD/Heading";
import InformationText from "../../../../../../shared/InformationText_OLD/InformationText";

import { durationLabel } from "../../shared/helpingFunctions";

import styles from "./GuideItemTV.module.scss";
import { ALL_DAY_ACTIVITY } from "../../../constants/constants";
import { formatTime, getCurrentDate } from "../../../../../../helpingFunctions/dateTimeUtilities";
import { formatPrice } from "../../../../../../helpingFunctions/formatPrice";

const GuideItemTV = ({
  Description,
  Location,
  Price,
  Title,
  Images,
  duration,
  time,
  timeId,
  Type,
  StartTime,
  EndTime
}) => {
  const imageUrl = Images[0]?.formats.medium
    ? Images[0].formats.medium.url
    : Images[0]?.formats.small
      ? Images[0].formats.small.url
      : Images[0]?.url;
  const isAllDayActivity = ALL_DAY_ACTIVITY === Type;

  return (
    <article className={styles.GuideItemTV} id={isAllDayActivity ? "All Day Activities" : timeId}>
      <div
        className={styles.GuideItemTV__slider}
        style={{
          background: `url(${imageUrl}) center / cover transparent`,
        }}
      ></div>
      <div className={styles.GuideItemTV__details}>
        <Heading
          txt={Title}
          customHeadingClass={styles.GuideItemTV__heading}
        />
        <ul className={styles.GuideItemTV__details_txt}>
          <li>
            <strong>Price:</strong>{" "}
            {`${Price ? formatPrice(Price) : `Inclusive`}`}
          </li>
          <li>
            <strong>Start:</strong> {isAllDayActivity ? formatTime(`${getCurrentDate()} ${StartTime}`) : time}
          </li>
          <li>
            <strong>Location:</strong> {isAllDayActivity ? Location : Location?.locationName}
          </li>
          <li>
            <strong>{isAllDayActivity ? "End:" : "Duration:"}</strong> {isAllDayActivity
              ? formatTime(`${getCurrentDate()} ${EndTime}`)
              : durationLabel(duration)}
          </li>
        </ul>
        <InformationText
          content={Description}
          className={styles.GuideItemTV__informationText}
        />
      </div>
    </article>
  );
};

export default GuideItemTV;
