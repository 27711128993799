import React, { useContext, useEffect, useRef } from "react";
import { IonRow, IonCol, IonPage, IonContent, IonGrid } from "@ionic/react";

import { DiningPartyContext } from "../../../context/DiningPartyContext";

import DiningPartyInit from "../DiningPartyPage/components/DiningPartyInit/DiningPartyInit";
import DiningPartyLeader from "../DiningPartyPage/components/DiningPartyLeader/DiningPartyLeader";
import BLConfirmationPopupBox from "../../../shared/Ionic/Boxes/Popup/BLConfirmationPopupBox";

import DiningPartyLogic from "./DiningPartyLogic";
import DiningPartyParticipant from "../DiningPartyPage/components/DiningPartyParticipant/DiningPartyParticipant";
import useDiningPartyRequests from "./useDiningPartyRequests";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import { SERVICES_TYPES } from "../../../constants/constants";
import { useDataContext } from "../../../context/DataContext";
import {
  DINING_PARTY_MODALS,
  LINK_AND_BOOK_ALERT_MESSAGE,
} from "./diningPartyConstants";
import ServicePageHeader from "../../../shared/Ionic/Wrappers/ServicePageHeader";

const DiningParty = () => {
  DiningPartyLogic();
  const { bookForNewReservation } = useDiningPartyRequests();
  const modal = useRef(null);
  const { reservationDetails } = useDataContext();
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);

  const { diningPartyDisplay, linkNewResPopUp, setLinkResNewPopUp } =
    useContext(DiningPartyContext);

  useEffect(() => {
    if (!diningPartyDisplay) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Social Party");
    } else {
      setIsLoaderOpened(false);
    }
  }, [diningPartyDisplay]);

  let DiningPartyEl = null;

  switch (diningPartyDisplay) {
    case DINING_PARTY_MODALS.INIT: {
      DiningPartyEl = <DiningPartyInit />;
      break;
    }
    case DINING_PARTY_MODALS.DINING_PARTY_LEADER: {
      DiningPartyEl = <DiningPartyLeader />;
      break;
    }
    case DINING_PARTY_MODALS.DINING_PARTY_PARTICIPANT: {
      DiningPartyEl = <DiningPartyParticipant />;
      break;
    }
    default: {
      DiningPartyEl = null;
    }
  }

  return (
    <IonPage>
      <IonContent scroll-y="false">
        <IonGrid style={{ height: "100%", overflow: "auto" }}>
          <BLConfirmationPopupBox
            modal={modal}
            isOpen={linkNewResPopUp}
            txt1={LINK_AND_BOOK_ALERT_MESSAGE}
            confirmBtnLabel={"Continue"}
            dismissBtnType={"outlined"}
            confirmBtnType={"primary"}
            dismissBtnLabel={"Cancel"}
            dismissHandler={() => {
              setLinkResNewPopUp(false);
              modal.current?.dismiss();
            }}
            confirmHandler={() => {
              bookForNewReservation();
              modal.current?.dismiss();
            }}
          />
          <ServicePageHeader service={SERVICES_TYPES.DINING_PARTY} />
          <IonRow className="bl-padding-s">
            <IonCol>{reservationDetails && DiningPartyEl}</IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default DiningParty;
