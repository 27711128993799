import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useDataContext } from "../../../../../context/DataContext";
import { TreatsContext } from "../../../../../context/TreatsContext";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import TreatYourselfBookingFlow from "../../components/TreatYourselfBookingFlow";
import TreatYourselfSummaryCard from "../../components/cards/TreatYourselfSummaryCard";
import { hasBooking } from "../../../../../helpingFunctions/bookingHelp";
import TreatYourselfProcessingMethods from "../../logic/TreatYourselfProcessingMethods";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { UNCATEGORIZED } from "../../../../../constants/constants";
import { ROUTE_VIEWS } from "../../../../../routes/routeViews";
import { NavigatorContext } from "../../../../../context/NavigatorContext";
import { useLocation, useParams } from "react-router-dom/";

const TreatYourselfServices = ({
  formatedTreats,
}) => {
  const location = useLocation();
  const { categoryId, treatId } = useParams();
  const { itineraryDetails } = useDataContext();
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const {
    selectedService,
    treatsDetails,
    setSelectedService,
    setAreAnyChanges,
    setAreSelectionsCompleted,
    setIsEditMode,
  } = useContext(TreatsContext);
  const [isFirstTimeBooker, setIsFirstTimeBooker] = useState(false);
  TreatYourselfProcessingMethods(setIsFirstTimeBooker);

  useEffect(() => {
    setSelectedService(null);
    if (treatsDetails) {
      let categoryExists;
      let redirectTo;

      treatsDetails.All?.forEach((item) => {
        if (treatId) {
          if (item.id === Number(treatId)) {
            redirectTo = item;
            categoryExists = item.categoryId === Number(categoryId);
          }
        } else {
          if (item.categoryId === Number(categoryId)) {
            categoryExists = true;
          }
        }
      });
      redirect(redirectTo, categoryExists);
    }
  }, [treatId, treatsDetails?.All, selectedService]);

  useEffect(() => {
    if (selectedService && !location?.state?.isItineraryRedirect && !treatId) {
      redirectBetweenPages(
        `${ROUTE_VIEWS.TREAT_BOOKING.replace(
          ":categoryId",
          selectedService.categoryId
            ? selectedService.categoryId
            : UNCATEGORIZED
        ).replace(":treatId", selectedService.id)}`
      );
    }
  }, [selectedService]);
  
  const redirect = (redirectedTo, categoryExists) => {
    if (
      redirectedTo &&
      (redirectedTo.categoryId === Number(categoryId) || categoryId === UNCATEGORIZED)
    ) {
      setSelectedService(redirectedTo);
      if (location?.state?.isItineraryRedirect && !isFirstTimeBooker) {
        setIsEditMode(true);
      }
    } else if (
      categoryExists &&
      !categoryId &&
      categoryId !== UNCATEGORIZED
    ) {
      redirectBetweenPages(
        ROUTE_VIEWS.TREATS_CATEGORY.replace(":categoryId", categoryId)
      );
    } else if (!categoryExists) {
      redirectBetweenPages(ROUTE_VIEWS.NOT_FOUND);
    }
  };
  
  const categoryServBoxes = (
    <>
      {formatedTreats?.items.map((service, index) => (
        <IonCol
          size={isDesktop() ? 3 : 12}
          key={index}
          style={
            isDesktop()
              ? {
                minWidth: "260px",
                maxWidth: "260px",
                minHeight: "170px",
              }
              : { minHeight: "150px" }
          }
        >
          <TreatYourselfSummaryCard
            newWrapperStyles={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
            images={service.Image}
            title={service.Title}
            duration={service.Duration}
            price={service.Price}
            onClick={() => {
              setSelectedService(service);
              setIsEditMode(false);
              setAreSelectionsCompleted(false);
              setAreAnyChanges(false);
            }}
            hasBooking={hasBooking(service.id, itineraryDetails)}
          />
        </IonCol>
      ))}
    </>
  );

  return (
    <>
      {isDesktop() ? (
        <BLContentCardWrapper
          width="100%"
          background={"white"}
          dimensions={{
            margin: ["b-s"],
            padding: selectedService ? [] : ["s"],
          }}
        >
          <IonGrid>
            <IonRow style={{ gap: "16px" }}>
              {selectedService ? (
                <TreatYourselfBookingFlow
                  isFirstTimeBooker={isFirstTimeBooker}
                />
              ) : (
                categoryServBoxes
              )}
            </IonRow>
          </IonGrid>
        </BLContentCardWrapper>
      ) : (
        <>
          {selectedService ? (
            <TreatYourselfBookingFlow
              isFirstTimeBooker={isFirstTimeBooker}
            />
          ) : (
            <BLContentCardWrapper
              width="100%"
              background={"light"}
              dimensions={{
                margin: ["b-s"],
                padding: selectedService ? [] : ["s"],
              }}
            >
              <IonGrid>
                <IonRow style={{ gap: "16px" }}>{categoryServBoxes}</IonRow>
              </IonGrid>
            </BLContentCardWrapper>
          )}
        </>
      )}
    </>
  );
};

export default TreatYourselfServices;
