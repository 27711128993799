import React from "react";
import Heading from "../../shared/Heading_OLD/Heading";
import DropdownLogic from "./DropdownLogic";

import styles from "./Dropdown.module.css";

const Dropdown = ({
  list,
  selectedOption,
  setSelectedOption,
  title,
  filterType,
  view,
}) => {
  const { optionSelectionHandler, isClicked, ref } = DropdownLogic();

  return (
    <div className={styles.Dropdown}>
      <Heading
        txt={title}
        customHeadingClass={styles.Dropdown_heading}
      />
      <div className={styles.Dropdown_action} onClick={optionSelectionHandler} ref={ref}>
        <div className={styles.Dropdown__selectionHolder}>
          {view === "WhatsOnGuide" ? selectedOption : selectedOption.value}
        </div>
        <div className={styles.Dropdown__indicatorHolder}>
          <img
            className={styles.Dropdown__icon}
            src={"/img/arrow.svg"}
            alt={"no img"}
          />
        </div>
        {isClicked ? (
          <ul className={styles.Dropdown__list}>
            {view === "WhatsOnGuide" ? (
              <li
                onClick={() => setSelectedOption({ value: "All" }, filterType)}
              >
                All
              </li>
            ) : null}

            {list.map((item) => (
              <li
                key={item.value}
                onClick={() => setSelectedOption(item, filterType)}
                style={{
                  backgroundColor:
                    item.value === selectedOption.value ? "gray" : null,
                  color: item.value === selectedOption.value ? "#fff" : null,
                }}
              >
                {item.value}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default Dropdown;
