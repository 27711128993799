import { useContext } from "react";
import { TreatsContext } from "../../../../../../../context/TreatsContext";
import { SERVICES_TYPES } from "../../../../../../../constants/constants";

const LocationsAndQuantitySelectionLogic = () => {
  const { treatsSelections, setTreatsSelections, selectedService } =
    useContext(TreatsContext);

  const handleLocationSelection = (selIndex, optIndex, val) => {
    const treatsSelCopy = JSON.parse(JSON.stringify(treatsSelections));

    treatsSelCopy[selIndex].options[optIndex].isEdited = false;
    treatsSelCopy[selIndex].options[optIndex].serviceCode = val.target.value;

    if (!val.target.value) {
      treatsSelCopy[selIndex].options[optIndex].location = "";
    } else if (val.target.value.toLowerCase().startsWith("d")) {
      treatsSelCopy[selIndex].options[optIndex].location = SERVICES_TYPES.DINNER;
    } else {
      treatsSelCopy[selIndex].options[optIndex].location = "In-Room";
    }
    
    if (treatsSelCopy[selIndex].options[optIndex].action !== "add") {
      if (treatsSelCopy[selIndex].options[optIndex].bookedServiceCode === val.target.value) {
        treatsSelCopy[selIndex].options[optIndex].action = "none";
      } else {
        treatsSelCopy[selIndex].options[optIndex].action = "edit";
      }
    }

    setTreatsSelections(treatsSelCopy);
  };

  const addLocation = (selIndex) => {
    const treatsSelCopy = JSON.parse(JSON.stringify(treatsSelections));
    treatsSelCopy[selIndex].options.push({
      action: "add",
      location: "",
      bookedLocation: "",
      serviceCode: "",
      bookedServiceCode: "",
      quantity: 1,
      bookedQuantity: "",
    });
    setTreatsSelections(treatsSelCopy);
  };

  const removeLocationSelection = (selIndex, optIndex) => {
    const treatsSelCopy = JSON.parse(JSON.stringify(treatsSelections));

    if (treatsSelCopy[selIndex].options[optIndex].action === "add") {
      if (optIndex === 0) {
        treatsSelCopy[selIndex].options[optIndex].isEdited = false;
        treatsSelCopy[selIndex].options[optIndex] = [
          {
            action: "add",
            location: "",
            bookedLocation: "",
            serviceCode: "",
            bookedServiceCode: "",
            quantity: 1,
            bookedQuantity: "",
          },
        ];
      } else {
        treatsSelCopy[selIndex].options = treatsSelCopy[
          selIndex
        ].options.filter((_, index) => index !== optIndex);
      }
    } else {
      treatsSelCopy[selIndex].options[optIndex].action = "remove";
    }

    setTreatsSelections(treatsSelCopy);
  };

  const handleQuantitySelection = (type, selIndex, dateIndex) => {
    const selectionsCopy = JSON.parse(JSON.stringify(treatsSelections));
    let count = selectionsCopy[selIndex].options[dateIndex].quantity;

    if (count < 1 || count > selectedService.maxQtyAllowed) return;

    if (type === "minus") {
      count = count - 1;
    } else {
      count = count + 1;
    }

    selectionsCopy[selIndex].options[dateIndex].quantity = count;

    if (selectionsCopy[selIndex].options[dateIndex].action !== "add") {
      if (
        selectionsCopy[selIndex].options[dateIndex].bookedQuantity === count
      ) {
        selectionsCopy[selIndex].options[dateIndex].action = "none";
      } else {
        selectionsCopy[selIndex].options[dateIndex].action = "edit";
      }
    }
    setTreatsSelections(selectionsCopy);
  };

  return {
    handleLocationSelection,
    addLocation,
    handleQuantitySelection,
    removeLocationSelection,
  };
};

export default LocationsAndQuantitySelectionLogic;
