import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useDataContext } from "../../../context/DataContext";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import BLLoadingContainer from "../../../shared/Ionic/BLLoadingContainer";
import BLInformationBox from "../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import BLTipBox from "../../../shared/Ionic/Boxes/TipBox/BLTipBox";
import LegalDocsLinks from "../LoginPage/LegalDocsLinks";
import LoginPageTitle from "../LoginPage/LoginPageTitle";
import {
  CRSNumberAttr,
  hotelAttr,
  surnameAttr,
} from "../LoginPage/constants/constants";
import LinkReservationsPageLogic from "./LinkReservationsPageLogic";
import LinkReservationsPageActionButtons from "./components/LinkReservationsPageActionButtons";
import LinkReservationsPageForm from "./components/LinkReservationsPageForm";
import { isMobile } from "../../../helpingFunctions/utilities";

const LinkReservationsPage = () => {
  const {
    hotelsDropdownOpts,
    isWaitingToLogIn,
    errMsg,
    setErrMsg,
    handleRequest,
  } = LinkReservationsPageLogic();
  const { isLoadingHotelData, pagesConfiguration } =
    useDataContext();
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const [isFormButtonDisabled, setIsFormButtonDisabled] = useState(true);

  const [formData, setFormData] = useState({
    [hotelAttr]: "",
    [surnameAttr]: "",
    [CRSNumberAttr]: "",
  });

  useEffect(() => {
    if (isLoadingHotelData) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Hotels");
    } else {
      setIsLoaderOpened(false);
    }
  }, [isLoadingHotelData]);

  const backgroundImage = pagesConfiguration?.LoginPage?.BackgroundImage;

  return (
    <IonContent scroll-y="false">
      {!isLoadingHotelData && (
        <IonGrid
          style={{
            background: `url(${backgroundImage?.formats?.medium?.url || backgroundImage?.url})  center bottom / cover `,
            overflow: "auto",
            minHeight: isMobile() ? "100%" : "",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            height: "100%"
          }}
        >
          <IonGrid className="bl-padding-b-s" style={{ flex: 1 }}>
            <LoginPageTitle
              title={pagesConfiguration?.LinkReservationsPage?.Title}
            />


            <LinkReservationsPageForm
              hotelsDropdownOpts={hotelsDropdownOpts}
              handleRequest={handleRequest}
              formData={formData}
              setFormData={setFormData}
              isFormButtonDisabled={isFormButtonDisabled}
            />

            <LinkReservationsPageActionButtons
              formData={formData}
              handleRequest={handleRequest}
              isWaitingToLogIn={isWaitingToLogIn}
              setErrMsg={setErrMsg}
              isFormButtonDisabled={isFormButtonDisabled}
              setIsFormButtonDisabled={setIsFormButtonDisabled}
            />
            {!isWaitingToLogIn && !errMsg &&
            <BLTipBox
              title={pagesConfiguration?.LinkReservationsPage?.Tip?.Title}
              description={
                pagesConfiguration?.LinkReservationsPage?.Tip?.Description
              }
              newClasses="bl-margin-t-m"
            />}


            {isWaitingToLogIn && (
              <IonRow className={"ion-text-center"}>
                <IonCol>
                  <BLLoadingContainer color="white" />
                </IonCol>
              </IonRow>
            )}

            {!isWaitingToLogIn && errMsg && (
              <IonRow className="bl-padding-t-s">
                <IonCol>
                  <BLInformationBox
                    content={errMsg}
                    type={"login_info"}
                    markdown
                  />
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
          {isMobile() && (
            <IonRow className="bl-flex ion-justify-content-center bl-padding-b-s">
              <LegalDocsLinks
                tcLink={pagesConfiguration?.TermsAndConditions?.Link}
              />
            </IonRow>
          )}
        </IonGrid>
      )}
    </IonContent>
  );
};

export default LinkReservationsPage;
