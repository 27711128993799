import React, { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RequestHook from "../hooks/RequestHook";
import useMaintenance from "../hooks/useMaintenance";
import useReservationValidator from "../hooks/useReservationValidator";
import { ROUTE_VIEWS } from "../routes/routeViews";
import { useDataContext } from "./DataContext";

export const NavigatorContext = createContext({});

const NavigatorProvider = ({ children }) => {
  const history = useHistory();
  const { getWarnerServices } = RequestHook();
  const { reservationDetails, itineraryDetails } = useDataContext();

  const [warnerServices, setWarnerServices] = useState([]);

  const formatWarnerServices = async () => {
    try {
      const warnerServices = await getWarnerServices();

      if (!warnerServices) {
        return;
      }

      setWarnerServices(warnerServices);
    } catch (error) {
      console.error(
        `${new Date()} Error in formatWarnerServices func in NavigatorContext.js file ${
          error.message
        }`
      );
    }
  };

  const redirectFromItinerary = (redirectTo, details) => {
    let referralService = {};

    warnerServices.forEach((serv) => {
      if (redirectTo.includes(serv.type)) {
        referralService = serv;
      } else {
        serv.options.forEach((subServ) => {
          if (redirectTo.includes(subServ.Type)) {
            referralService = subServ;
          }
        });
      }
    });

    const itineraryObj = itineraryDetails.Dates[details.bookedDate]?.All.find(
      (row) => row.Type === redirectTo
    );

    history.push({
      pathname: redirectTo,
      state: {
        isItineraryRedirect: true,
        pageDetails: { ...referralService, ...details },
        itineraryObj: itineraryObj
          ? itineraryObj
          : { Date: details.bookedDate },
      },
    });
  };

  const redirectToItinerary = (details) => {
    redirectBetweenPages(ROUTE_VIEWS.ITINERARY, details);
  };

  const redirectBetweenPages = (redirectTo, details) => {
    let referralService = {};

    warnerServices.forEach((serv) => {
      if (redirectTo.includes(serv.type)) {
        referralService = serv;
      } else {
        serv.options.forEach((subServ) => {
          if (redirectTo.includes(subServ.Type)) {
            referralService = subServ;
          }
        });
      }
    });

    if (referralService?.options?.length === 1) {
      history.push({
        pathname: redirectTo,
        state: {
          pageDetails: {
            ...referralService,
            ...referralService.options[0],
            ...details,
          },
        },
      });
    } else {
      history.push({
        pathname: redirectTo,
        state: {
          pageDetails: { ...referralService, ...details },
        },
      });
    }
  };

  useMaintenance(redirectBetweenPages);
  useReservationValidator();

  useEffect(() => {
    if (reservationDetails) {
      formatWarnerServices(reservationDetails);
    }
  }, [reservationDetails]);

  const getWarnerService = (type) => {
    const filteredServices = warnerServices.filter(service => service.type === type);
    if(filteredServices && filteredServices.length > 0) {
      return filteredServices[0];
    }
    
    return null;
  };

  const getWarnerServiceOption = (type) => {
    for(let i = 0; i < warnerServices.length; i++) {
      const filteredOptions = warnerServices[i].options.filter(option => option.Type === type);
      if(filteredOptions && filteredOptions.length > 0) {
        return filteredOptions[0];
      }
    }

    return null;
  };

  return (
    <NavigatorContext.Provider
      value={{
        warnerServices,
        setWarnerServices,
        redirectFromItinerary,
        redirectBetweenPages,
        redirectToItinerary,
        getWarnerService,
        getWarnerServiceOption
      }}
    >
      {children}
    </NavigatorContext.Provider>
  );
};

export default NavigatorProvider;
