import { IonCard, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { useDataContext } from "../../../../context/DataContext";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import styles from "./WelcomeTile.module.scss";
import { useTheme } from "../../../../hooks/useTheme";

const WelcomeTile = ({ hasArrived, img }) => {
  const { typography } = useTheme();
  const { reservationDetails } = useDataContext();

  const { Breakfast, Dinner, Activities, Spas, Venue, Treat, Entertainments } =
  reservationDetails ? reservationDetails : {} ;

  const welcomeConditionalText = hasArrived
    ? "enjoy your stay"
    : Breakfast ||
      Dinner ||
      Activities ||
      Spas ||
      Venue ||
      Treat ||
      Entertainments
      ? "welcome back"
      : "welcome";
  const welcomeText = `Hi ${reservationDetails?.FirstName}, ${welcomeConditionalText}!`;

  return (
    <IonCard style={{ borderRadius: "0px" }}>
      <IonGrid
        className={styles.WelcomeTile}
        style={{
          backgroundImage: `url(${img?.src})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      />
      <IonGrid>
        <IonRow
          style={{
            backgroundColor: "var(--ion-color-secondary)",
            padding: "8px 16px",
          }}
        >
          <BLParagraph
            txt={welcomeText}
            //newClasses="small bold"
            //TODO: Unclear typography
            textVariant={typography.h4}
            color="light"
          />
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default WelcomeTile;
