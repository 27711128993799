import { format } from "date-fns";

const AUTHENTICATED_KEY = "authenticated";

const TCLink = "TCLink";

const ARRIVAL_DATE_START_TIME = "15:00";

const SUCCESS_STATUS = 200;

const POUND_SYMBOL = "£";

const NO_BOOKING_REQUIRED = "No booking required";

// Default route parameter for services without category
const UNCATEGORIZED = "uncategorized";

const HEADER_SIZE = "80px";
const FOOTER_SIZE = "70px";

const BOOKING_CONFIRMATION_MSG = "Please find your booking summary as below";

const CHECKIN_TYPE = "Check in";
const CHECKOUT_TYPE = "Check out";
const CHECKINCHECKOUT_TYPES = [CHECKIN_TYPE, CHECKOUT_TYPE];

const MOBILE_PLATFORM = "mobile"; // a mobile device
const ANDROID_PLATFORM = "android"; // a device running Android
const IOS_PLATFORM = "ios"; // a device running iOS
const DESKTOP_PLATFORM = "desktop"; // a desktop device
const MOBILEWEB_PLATFORM = "mobileweb"; // a web browser running in a mobile device

const IMAGE_FORMATS = Object.freeze({
  XXLARGE: "xxlarge", //2000px
  XLARGE: "xlarge", //1600px
  LARGE: "large", //1000px
  MEDIUM: "medium", //750px
  SMALL: "small", //500px
});

const INITIAL_DATE = format(new Date(), "yyy-MM-dd");

const SERVICES_TYPES = Object.freeze({
  DINING: "Dining",
  THINGS_TO_DO: "ThingsToDo",
  REGISTER_CARD: "RegisterCard",
  TREATS: "Treats",
  MAP: "Map",
  WHATS_ON: "WhatsOn",
  ROOM_ACCOUNT: "RoomAccount",
  BREAKFAST: "Breakfast",
  DINNER: "Dinner",
  DINING_PARTY: "DiningParty",
  ENTERTAINMENT: "Entertainment",
  ACTIVITIES: "Activities",
  GOLF: "Golf",
  SPAS: "Spas",
  WHATS_ON_GUIDE: "WhatsOnGuide",
  ITINERARY: "Itinerary",
});

const MAESTRO_BACKEND_NAMING_VIEWS = Object.freeze({
  VENUE: "Venue",
  ACTIVITY: "Activity",
  SPA: "Spa",
  TREAT: "Treat",
  WELLNESS_ACTIVITY: "WellnessActivity",
});
const DINING_TYPES = [SERVICES_TYPES.BREAKFAST, SERVICES_TYPES.DINNER];

const ACTIVITY_TYPES = [
  MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY,
  MAESTRO_BACKEND_NAMING_VIEWS.SPA,
  MAESTRO_BACKEND_NAMING_VIEWS.TREAT,
];
const ACTIVITIES_TYPES = [
  MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY,
  MAESTRO_BACKEND_NAMING_VIEWS.WELLNESS_ACTIVITY,
  "Internal Activity",
];
const ENTERTAINMENT_TYPES = [
  SERVICES_TYPES.ENTERTAINMENT,
  MAESTRO_BACKEND_NAMING_VIEWS.VENUE,
];

const COMPARE_RESULTS = Object.freeze({
  EQUAL: 0,
  UP: 1,
  DOWN: -1,
});

const ITEM_POPULAR_STATUSES = Object.freeze({
  INIT: "initial",
  LOAD: "loading",
  SUCCESS: "success",
  FAILURE: "failure",
  FAILED: "failed",
  PENDING: "pending",
  EDITING: "editing",
});

const ACTION_TYPES = Object.freeze({
  NONE: "none",
  ADD: "add",
  EDIT: "edit",
  REMOVE: "remove",
  NOT_REQ: "not-required",
});

const SORT_BY_LABELS = [
  { id: "none", label: "No sorting", simpleLabel: "No sorting" },
  {
    id: "most popular",
    label: "Sort by Most Popular",
    simpleLabel: "Most Popular",
  },
  {
    id: "price low to high",
    label: "Sort by Price Low to High",
    simpleLabel: "Price Low to High",
  },
  {
    id: "price high to low",
    label: "Sort by Price High to Low",
    simpleLabel: "Price High to Low",
  },
  {
    id: "duration short to long",
    label: "Sort by Duration Short to Long",
    simpleLabel: "Duration Short to Long",
  },
  {
    id: "duration long to short",
    label: "Sort by Duration Long to Short",
    simpleLabel: "Duration Long to Short",
  },
  {
    id: "new treatment",
    label: "Sort by NEW Treatments!",
    simpleLabel: "NEW Treatments",
  },
];

const FEATURE_FLAGS = Object.freeze({
  SPA_AVAILABILITY_FILTER: "SpaAvailabilityFilter",
  LINKED_RESERVATIONS: "LinkedReservations",
});

const LEGAL_DOCS = Object.freeze({
  COOKIE: "cookie",
  PRIVACY: "privacy",
});

const TYPOGRAPHY_OPTIONS = Object.freeze({
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  NOTIFICATION_TITLE_TEXT: "notification",
  BODY_TEXT: "body",
  TEXT_LINK: "link",
  ITINERARY_BODY_TEXT: "itinerary",
  SMALL_PRINT_TEXT: "printText",
  SMALL_PRINT_LINK: "printLink",
  TOOLTIP_HIGHLIGHT_TEXT: "tooltipHighlightText",
});

const MAP_VARIANTS_TO_CLASSES = Object.freeze({
  [TYPOGRAPHY_OPTIONS.h1]: "xxx-large crimson-pro light",
  [TYPOGRAPHY_OPTIONS.h2]: "x-large crimson-pro light",
  [TYPOGRAPHY_OPTIONS.h3]: "medium open-sans regular",
  [TYPOGRAPHY_OPTIONS.h4]: "small open-sans regular",
  [TYPOGRAPHY_OPTIONS.h5]: "body open-sans semibold",
  [TYPOGRAPHY_OPTIONS.NOTIFICATION_TITLE_TEXT]: "xx-small open-sans bold",
  [TYPOGRAPHY_OPTIONS.BODY_TEXT]: "body open-sans regular",
  [TYPOGRAPHY_OPTIONS.TEXT_LINK]: "body open-sans regular underline",
  [TYPOGRAPHY_OPTIONS.ITINERARY_BODY_TEXT]: "xx-small open-sans regular",
  [TYPOGRAPHY_OPTIONS.SMALL_PRINT_TEXT]: "xxx-small open-sans semibold",
  [TYPOGRAPHY_OPTIONS.SMALL_PRINT_LINK]: "xxx-small open-sans semibold underline",
  [TYPOGRAPHY_OPTIONS.TOOLTIP_HIGHLIGHT_TEXT]: "xx-small open-sans semibold",
});

export {
  AUTHENTICATED_KEY,
  TCLink,
  ARRIVAL_DATE_START_TIME,
  SERVICES_TYPES,
  MAESTRO_BACKEND_NAMING_VIEWS,
  ACTIVITY_TYPES,
  ACTIVITIES_TYPES,
  ENTERTAINMENT_TYPES,
  DINING_TYPES,
  ITEM_POPULAR_STATUSES,
  SUCCESS_STATUS,
  BOOKING_CONFIRMATION_MSG,
  CHECKINCHECKOUT_TYPES,
  CHECKIN_TYPE,
  CHECKOUT_TYPE,
  MOBILE_PLATFORM,
  MOBILEWEB_PLATFORM,
  ANDROID_PLATFORM,
  IOS_PLATFORM,
  DESKTOP_PLATFORM,
  COMPARE_RESULTS,
  INITIAL_DATE,
  POUND_SYMBOL,
  ACTION_TYPES,
  SORT_BY_LABELS,
  HEADER_SIZE,
  FOOTER_SIZE,
  FEATURE_FLAGS,
  LEGAL_DOCS,
  UNCATEGORIZED,
  TYPOGRAPHY_OPTIONS,
  MAP_VARIANTS_TO_CLASSES,
  NO_BOOKING_REQUIRED,
  IMAGE_FORMATS,
};
