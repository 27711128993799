import { IonGrid } from "@ionic/react";
import { addCircle } from "ionicons/icons";
import React, { useContext } from "react";

import { DiningPartyContext } from "../../../../../context/DiningPartyContext";

import { useDataContext } from "../../../../../context/DataContext";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import { ADD_MORE_GUESTS } from "../../diningPartyConstants";

const AddMoreGuests = () => {
  const { addMoreHandler } = useContext(DiningPartyContext);
  const {
    reservationDetails: { PartOfGroup },
  } = useDataContext();

  return (
    <IonGrid
      className="bl-padding-t-s bl-padding-b-s"
      onClick={addMoreHandler.bind(null, PartOfGroup)}
    >
      <BLButton type="icon-text" icon={addCircle} content={ADD_MORE_GUESTS} />
    </IonGrid>
  );
};

export default AddMoreGuests;
