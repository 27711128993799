import React, { useState, useContext, useEffect, Fragment } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { useDataContext } from "../../../../../../context/DataContext";
import { SpaContext } from "../../../../../../context/SpaContext";
import BLLoadingContainer from "../../../../../../shared/Ionic/BLLoadingContainer";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import { INITIAL_DATE } from "../../../../../../constants/constants";
import {
  getDayPeriod,
  formatTimeDetailed,
  getDatesBetween,
  getCurrentDate,
} from "../../../../../../helpingFunctions/dateTimeUtilities";
import { generateKey } from "../../../../../../helpingFunctions/helpingFunctions";
import { useTheme } from "../../../../../../hooks/useTheme";

const TimesRowLogic = () => {
  const { typography } = useTheme();
  const [collapsedRowContent, setCollapsedRowContent] = useState(null);
  const { reservationDetails } = useDataContext();
  const { spaAvailability, spaSelections, option, isEditMode } = useContext(SpaContext);

  const extractTimeSelections = (date, type) => {
    if (date >= getCurrentDate() && (!spaAvailability || date === "" || !spaAvailability[date])) return "loading";
    if (spaAvailability && spaAvailability[date] && !spaAvailability[date].AM && !spaAvailability[date].PM) return "passedDay";
    return spaAvailability?.[date]?.[type];
  };

  const handleCollapsedRowSummary = () => {
    if (option === "separate" || isEditMode) {
      const content = [];

      spaSelections.forEach((sel, index) => {
        const subContent = [];
        const margins = [];
        if (index !== 0) {
          margins.push("t-s");
        }

        subContent.push(
          <IonRow key={generateKey(sel)} className="bl-margin-b-xs bl-margin-l-s">
            <IonCol>
              <BLParagraph
                txt={sel.guestName}
                color="text-grey"
                textVariant={typography.h3}
                // newClasses={`medium bold crimson-pro`}
                //TODO: Unclear typography
                dimensions={{
                  margin: margins,
                }}
              />
            </IonCol>
          </IonRow>
        );

        sel.on.forEach((row) => {
          if (row.time !== "") {
            subContent.push(
              <IonRow key={generateKey(row)} className="bl-margin-l-s">
                <IonCol>
                  {getTime(row) ? (
                    <BLParagraph
                      txt={getTime(row)}
                      color="primary"
                      textVariant={typography.h3}
                      typographyStyles={
                        row.action === "remove"
                          ? { textDecoration: "line-through" }
                          : {}
                      }
                    // newClasses={`medium bold crimson-pro ${
                    //   row.action === "remove" ? "line" : ""
                    // }`}
                    //TODO: Unclear typography
                    />
                  ) : (
                    <IonRow
                      className="ion-text-center"
                      style={{
                        margin: 0,
                        marginLeft: "20px",
                      }}
                    >
                      <IonCol>
                        <BLLoadingContainer newClasses="bl-margin-xxxs" />
                      </IonCol>
                    </IonRow>
                  )}

                  {!["none"].includes(row.action) &&
                    isEditMode &&
                    (row.time !== row.bookedTime
                      || row.action === "remove")
                    && (
                      <BLParagraph
                        txt="*Updated*"
                        color="success"
                        textVariant={typography.h3}
                        // newClasses={`medium bold crimson-pro`}
                        //TODO: Unclear typography
                        dimensions={{
                          margin: ["l-s"],
                        }}
                      />
                    )}
                </IonCol>
              </IonRow>
            );
          }
        });

        content.push(
          <IonGrid key={generateKey(sel)} className="bl-margin-b-xs">{subContent}</IonGrid>
        );
      });
      setCollapsedRowContent(content);
    } else {
      const content = [];

      const hasUnselectedDays = spaSelections[0]?.on.find(
        (sel) => sel.time === ""
      );

      if (hasUnselectedDays) {
        content.push(
          <BLParagraph
            key="timeslot"
            txt="Time slot(s)"
            color="primary"
            textVariant={typography.h3}
            // newClasses={`medium bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
            }}
          />
        );
      } else {
        spaSelections[0]?.on.forEach((row, index) => {
          if (row.date !== "") {
            content.push(
              <Fragment key={generateKey(row)}>
                {getTime(row) ? (
                  <BLParagraph
                    key={index}
                    txt={getTime(row)}
                    color="primary"
                    textVariant={typography.h3}
                    // newClasses={`medium bold crimson-pro`}
                    //TODO: Unclear typography
                    dimensions={{
                      margin: ["l-s"],
                    }}
                  />
                ) : (
                  <IonRow
                    key={index}
                    className="ion-text-center"
                    style={{
                      margin: 0,
                      marginLeft: "20px",
                    }}
                  >
                    <IonCol>
                      <BLLoadingContainer />
                    </IonCol>
                  </IonRow>
                )}
              </Fragment>
            );
          }
        });
      }

      setCollapsedRowContent(content);
    }
  };

  const getTime = (row) => {
    let dayString = INITIAL_DATE;
    if (row.time) {
      dayString += `T${row.time.substring(0, 5)}`;
    }
    const dayTime = getDayPeriod(dayString);
    const times = extractTimeSelections(row.date, dayTime);
    if ((typeof times === "string" && times !== "passedDay") || !times) return;

    return formatTimeDetailed(
      getDatesBetween(reservationDetails.ArrivalDate, reservationDetails.DepartureDate),
      `${row.date}T${row.time.substring(0, 5)}`
    );
  };

  useEffect(() => {
    handleCollapsedRowSummary();
  }, [spaSelections, spaAvailability]);

  return {
    collapsedRowContent,
    extractTimeSelections,
  };
};

export default TimesRowLogic;
