import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { calendar } from "ionicons/icons";
import React, { useContext } from "react";
import { ActivityContext } from "../../../../../../context/ActivityContext";
import { useDataContext } from "../../../../../../context/DataContext";
import BLIcon from "../../../../../../shared/Ionic/BLIcon";
import BLLoadingContainer from "../../../../../../shared/Ionic/BLLoadingContainer";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import { useTheme } from "../../../../../../hooks/useTheme";

const DaysRowCollapsedTitle = () => {
  const { typography } = useTheme();
  const { isLoadingItinerary } = useDataContext();
  const { activitySelections, daySelections, isEditMode } = useContext(ActivityContext);

  return (
    <IonGrid>
      <IonRow className={"ion-align-items-center"}>
        <BLIcon iconSource={calendar} color={"primary"} iconSize={"md"} />
        <IonCol
          className={"bl-flex"}
          style={{ flexDirection: "column", gap: "5px" }}
        >
          {isLoadingItinerary 
            ? <BLLoadingContainer /> 
            : isEditMode
              ? <IonGrid className={"bl-padding-b-xs bl-padding-l-s"}>
                {activitySelections[0]?.on.map((sel, i) => (
                  <IonRow key={sel.uniqueKey || i}>
                    <IonCol>
                      <BLParagraph
                        txt={
                          daySelections?.find((daySel) => daySel.id === sel.date)
                            ?.fullTitle
                        }
                        color={"primary"}
                        textVariant={typography.h3}
                        typographyStyles={sel.action === "remove" ? {textDecoration: "line-through"} : {}}
                        // newClasses={`medium bold ${
                        //   sel.action === "remove" ? "line" : ""
                        // } crimson-pro`}
                        //TODO: Unclear typography
                        dimensions={{
                          padding: ["t-md", "b-md"],
                        }}
                      />
                      {!["none"].includes(sel.action) && isEditMode && (sel.date !== sel.bookedDate || sel.action === "remove" || sel.quantity !== sel.bookedQuantity) &&
                        (<BLParagraph
                          txt={"*Updated*"}
                          color={"success"}
                          textVariant={typography.h3}
                          // newClasses={`medium bold crimson-pro`}
                          //TODO: Unclear typography
                          dimensions={{
                            margin: ["l-s"],
                            padding: ["t-md", "b-md"],
                          }}
                        />)
                      }
                    </IonCol>
                  </IonRow>
                ))}
              </IonGrid>
              : activitySelections[0]?.on.some((sel) => sel.date === "")
                ? <BLParagraph
                  txt={"Days and no. of guests"}
                  color={"primary"}
                  textVariant={typography.h3}
                  // newClasses={`medium bold crimson-pro`}
                  //TODO: Unclear typography
                  dimensions={{
                    margin: ["l-s"],
                    padding: ["t-md", "b-md"],
                  }}
                />
                : <>
                  {activitySelections[0]?.on.map((sel, i) => (
                    <BLParagraph
                      key={sel.date || i}
                      txt={
                        <>
                          {daySelections?.find((daySel) => daySel.id === sel.date)?.fullTitle} {", "}
                          {`${sel.quantity} guest${sel.quantity === 1 ? "" : "s"}`}
                        </>
                      }
                      color={"primary"}
                      textVariant={typography.h3}
                      // newClasses={`medium crimson-pro`}
                      //TODO: Unclear typography
                      dimensions={{
                        margin: ["l-s"],
                      }}
                    />
                  ))}
                </>
          }
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default DaysRowCollapsedTitle;