import React, { useContext, useEffect } from "react";

import { leftArrow, rightArrow } from "../../assets/SVGIcons";
import {
  multipleSelected,
  singleSelected,
  treatsSelected,
} from "../../assets/requests";
import { DINING_TYPES } from "../../constants/constants";
import { useDataContext } from "../../context/DataContext";
import WindowWidth from "../../hooks/WindowWidth";
import DayButton from "../../shared/DayButton_OLD/DayButton";
import DaysListLogic from "./DaysListLogic";

import { WhatsOnDataContext } from "../../context/WhatsOnDataContext";
import "../../css/regularCss/action_button.scss";
import AllDayButton from "../../shared/DayButton_OLD/AllDayButton";
import BLIcon from "../../shared/Ionic/BLIcon";
import { ALL_DAY_ACTIVITY } from "../../views/children/WhatsOnGuidePage/constants/constants";
import styles from "./days_list.module.css";

const DaysList = ({
  data,
  view,
  id,
  treatsBookings = [],
  selectedItemId,
  setSelectedItemId,
}) => {
  const {
    reservationDetails,
  } = useDataContext();
  const { width } = WindowWidth();
  const { handleClick, slideLeft, slideRight, leftPosition } = DaysListLogic(
    data.length,
    view,
    width
  );
  const { setAlert,selectedDate, setSelectedDate } = useContext(WhatsOnDataContext);

  useEffect(() => {
    if (view === "WhatsOnGuide") {
      setAlert({ isError: false, });
    }
  }, [selectedDate]);

  const allDayActivitiesTab = () => (
    <AllDayButton
      key={`${ALL_DAY_ACTIVITY}Button`}
      handleClick={() => {
        setSelectedItemId(ALL_DAY_ACTIVITY);
        setSelectedDate(null);
      }}
      isSelected={selectedItemId === ALL_DAY_ACTIVITY && selectedDate === null}
      length={1}
      view={view}
    />
  );

  return (
    <>
      {(data.length >= 3 && width < 750) ||
      (data.length >= 6 && width < 1065) ||
      (data.length >= 7 && width < 1121) ? (
          <div className={styles.dayList_mobile}>
            <div
              className={`${styles.arrow} ${styles.dayList_mobile__leftArrow}`}
              onClick={slideRight} 
            >
              <BLIcon customIconSource={leftArrow} />
            </div>
            <div className={styles.dayList_mobile__slider_container}>
              <div
                className={styles.slider_container_show}
                style={{ left: leftPosition }}
              >
                {data.map((date, index) => (
                  <DayButton
                    key={date}
                    date={date}
                    timeSelected={
                      DINING_TYPES.includes(view)
                        ? singleSelected(
                          date,
                          width,
                          selectedDate,
                          [],
                          []
                        )
                        : view === "Treats"
                          ? treatsSelected(treatsBookings, date)
                          : multipleSelected(
                            date,
                            width,
                            selectedDate,
                            [],
                            [],
                            id,
                            reservationDetails.ArrivalDate,
                          )
                    }
                    handleClick={() => handleClick(date)}
                    isSelected={selectedDate === date}
                    beforeSelected={
                      index < data.length && selectedDate === data[index + 1]
                    }
                    length={data.length}
                    view={view}
                  />
                ))}
                {view === "WhatsOnGuide" && allDayActivitiesTab()}
              </div>
            </div>
            <div
              className={`${styles.arrow} ${styles.dayList_mobile__rightArrow}`}
              onClick={slideLeft} 
            >
              <BLIcon customIconSource={rightArrow} />
            </div>
          </div>
        ) : (
          <div className={styles.daysContainer}>
            <div className={styles.daysWrapper}>
              {data.map((date, index) => (
                <DayButton
                  key={date}
                  date={date}
                  timeSelected={
                    DINING_TYPES.includes(view)
                      ? singleSelected(
                        date,
                        width,
                        selectedDate,
                        [],
                        []
                      )
                      : view === "Treats"
                        ? treatsSelected(treatsBookings, date)
                        : multipleSelected(
                          date,
                          width,
                          selectedDate,
                          [],
                          [],
                          id,
                          reservationDetails?.ArrivalDate,
                        )
                  }
                  handleClick={() => handleClick(date)}
                  isSelected={selectedDate === date}
                  beforeSelected={
                    index < data.length && selectedDate === data[index + 1]
                  }
                  length={data.length}
                  view={view}
                />
              ))}
              {view === "WhatsOnGuide" && allDayActivitiesTab()}
            </div>
          </div>
        )}
    </>
  );
};

export default DaysList;
