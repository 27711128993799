import React, { useEffect, useState } from "react";
import LoginFormLogic from "./LoginFormLogic";
import {
  CRSNumberAttr,
  hotelAttr,
  surnameAttr,
} from "../../constants/constants";

import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";
import BLInput from "../../../../../shared/Ionic/Inputs/BLInput";
import BLSelect from "../../../../../shared/Ionic/Inputs/BLSelect";
import BLLabel from "../../../../../shared/Ionic/Inputs/BLLabel";
import BLInformationBox from "../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import BLAlertPopUp from "../../../../../shared/Ionic/Boxes/Popup/BLAlertPopUp";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import { validateForm } from "../../../../../helpingFunctions/helpingFunctions";
import { informationCircle } from "ionicons/icons";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../../hooks/useTheme";

const LoginForm = ({ loginPageData }) => {
  const { typography } = useTheme();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [errorBox, setErrorBox] = useState(null);
  const {
    manageFormData,
    handleRequest,
    errMsg,
    setErrMsg,
    alert,
    setAlert,
    formData,
    isWaitingToLogIn,
    popupContent,
    setPopupContent,
  } = LoginFormLogic(loginPageData);

  useEffect(() => {
    const undefinedValues = validateForm(formData);
    const undefinedKeys = Object.keys(undefinedValues);
    if (undefinedKeys.length === 0) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  }, [formData]);

  useEffect(() => {
    if (alert.isError) {
      let errorMsg = (
        <BLInformationBox content={errMsg} type="login_info" markdown />
      );
      setErrorBox(errorMsg);
    }
  }, [alert]);

  const AlertPopup = (
    <BLAlertPopUp
      title={!popupContent && "Logout"}
      enableHeaderLevelCloseBtn
      dismissHandler={() => {
        setAlert({}), setPopupContent(null);
      }}
      dismissBtnLabel="OK"
      dismissBtnType=""
      isOpen={!!alert.isAlert}
      enableBackdropDismiss
    >
      <IonRow className="bl-padding-b-s bl-padding-t-s">
        <IonCol>
          {popupContent ? (
            popupContent
          ) : (
            <BLParagraph
              txt={errMsg}
              color="text-grey"
              textVariant={typography.BODY_TEXT}
              dimensions={{ padding: ["b-s"] }}
            />
          )}
        </IonCol>
      </IonRow>
    </BLAlertPopUp>
  );

  return (
    <>
      <IonGrid>
        <form
          onKeyDown={(e) => {
            if (e.key === "Enter" && !submitButtonDisabled) {
              handleRequest(e);
            }
          }}
        >
          <IonRow
            className="ion-justify-content-center"
            style={{ minWidth: "100px" }}
          >
            <IonCol>
              <IonGrid>
                <IonRow>
                  <BLLabel
                    color="accent-pale-grey"
                    label="Hotel"
                    labelDimensions={{
                      margin: ["b-xs"],
                    }}
                    labelClasses="bl-text-regular"
                  />
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <BLSelect
                      dropdownList={loginPageData?.Hotels?.map((option) => ({
                        id: option.id,
                        label: option.Title,
                      }))}
                      name={hotelAttr}
                      value={formData[hotelAttr]}
                      placeholder="Select the hotel on your reservation"
                      onChange={(event) => {
                        manageFormData(hotelAttr, event.target.value);
                      }}
                      boxHeightClass={isDesktop() ? "height-md" : "height-sm"}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
          <IonRow className="ion-text-center ion-justify-content-center">
            <IonCol>
              <IonGrid>
                <IonRow>
                  <BLLabel
                    color="accent-pale-grey"
                    label="Surname"
                    labelDimensions={{
                      margin: isDesktop() ? ["b-xs", "t-s"] : ["b-xs", "t-s"],
                    }}
                    labelClasses="bl-text-regular"
                  />
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <BLInput
                      placeholder="Enter your last name"
                      name={surnameAttr}
                      type="text"
                      value={formData[surnameAttr]}
                      onChange={(e) => {
                        manageFormData(surnameAttr, e.target.value);
                        setErrorBox(null);
                        setErrMsg("");
                      }}
                      boxHeightClass={isDesktop() ? "height-md" : "height-sm"}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
          <IonRow className="ion-text-center ion-justify-content-center">
            <IonCol>
              <IonGrid>
                <IonRow>
                  <BLLabel
                    color="accent-pale-grey"
                    label="Holiday reference number"
                    labelDimensions={{
                      margin: isDesktop() ? ["b-xs", "t-s"] : ["b-xs", "t-s"],
                    }}
                    labelClasses="bl-text-regular"
                    afterIconSource={
                      loginPageData?.ReferenceNumberTooltip
                        ? informationCircle
                        : ""
                    }
                    afterIconTxt={loginPageData?.ReferenceNumberTooltip}
                    afterIconColor="white"
                  />
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <BLInput
                      placeholder="Number begins with a 1"
                      name={CRSNumberAttr}
                      type="text"
                      value={formData[CRSNumberAttr]}
                      onChange={(e) => {
                        manageFormData(CRSNumberAttr, e.target.value);
                        setErrorBox(null);
                        setErrMsg("");
                      }}
                      labelClasses="bl-text-regular"
                      boxHeightClass={isDesktop() ? "height-md" : "height-sm"}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </form>
        {errorBox && (
          <IonRow className="bl-margin-t-s">
            <IonCol>{errorBox}</IonCol>
          </IonRow>
        )}
        <IonRow
          className={`ion-text-center ${
            errorBox ? "bl-padding-t-s" : "bl-padding-t-xl"
          } bl-padding-b-s`}
        >
          <IonCol>
            <BLButton
              content="Log in"
              newClasses="uppercase"
              type="primary"
              onClick={handleRequest}
              disabled={submitButtonDisabled}
            />
          </IonCol>
        </IonRow>
        {isWaitingToLogIn && (
          <IonRow className="ion-text-center">
            <IonCol>
              <BLLoadingContainer color="accent-pale-grey" />
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
      {!!alert.isAlert && AlertPopup}
    </>
  );
};

export default LoginForm;
