import { IonPage, IonRouterOutlet } from "@ionic/react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { ConflictManagementProvider } from "../context/ConflictManagementContext";
import { SpaProvider } from "../context/SpaContext";
import SpaPage from "../views/children/SpaPage/SpaPage";
import FallbackRoute from "./FallbackRoute";
import { ROUTE_VIEWS } from "./routeViews";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../constants/constants";

const spaPage = () => {
  return (
    <ConflictManagementProvider conflictOccuringView={MAESTRO_BACKEND_NAMING_VIEWS.SPA}>
      <SpaProvider>
        <SpaPage />
      </SpaProvider>
    </ConflictManagementProvider>
  );
};

const SpaRoutes = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Switch>
          <Route
            path={ROUTE_VIEWS.SPAS}
            exact={true}
            component={spaPage}
          />
          <Route
            path={ROUTE_VIEWS.SPAS_CATEGORY}
            exact={true}
            component={spaPage}
          />
          <Route
            path={ROUTE_VIEWS.SPA_BOOKING}
            exact={true}
            component={spaPage}
          />
          <Route component={FallbackRoute} />
        </Switch>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default SpaRoutes;
