import { useEffect, useState } from "react";

import axiosInstance from "../../../../../../../url/createAxios";

const UserFiltersLogic = () => {
  const instance = axiosInstance();
  const [storedLocations, setStoredLocations] = useState([]);

  const getWhatsOnLocations = async () => {
    const { data } = await instance.get("/activity-locations?display=some");
    const formatedData = data.map(({ id, LocationName }) => {
      return {
        id,
        value: LocationName,
      };
    });
    setStoredLocations(formatedData);
  };

  useEffect(() => {
    getWhatsOnLocations();
  }, []);

  return { storedLocations };
};

export default UserFiltersLogic;
