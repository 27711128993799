import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { ActivityContext } from "../../../context/ActivityContext";
import ActivityServices from "./Views/ActivityServices";
import ConflictPopupBoxes from "../../../helpingFunctions/conflictManagement/ConflictPopupBoxes";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { SERVICES_TYPES } from "../../../constants/constants";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import ServicePageHeader from "../../../shared/Ionic/Wrappers/ServicePageHeader";
import { useHistory } from "react-router-dom";
import { ROUTE_VIEWS } from "../../../routes/routeViews";

const ActivitiesPage = () => {
  const history = useHistory();
  const {
    activitiesDetails,
  } = useContext(ActivityContext);
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const { warnerServices } = useContext(NavigatorContext);

  useEffect(() => {
    if (warnerServices.length === 0 || !activitiesDetails) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Activities Page");
    } else {
      setIsLoaderOpened(false);
    }
  }, [warnerServices, activitiesDetails]);

  return (
    <IonPage style={{ overflow: "auto" }}>
      <ConflictPopupBoxes />
      <ServicePageHeader
        service={SERVICES_TYPES.ACTIVITIES}
        onClick={() => history.push(history.location.state.isItineraryRedirect ? ROUTE_VIEWS.ITINERARY : ROUTE_VIEWS.THINGS_TO_DO)}
      />
      <IonGrid className="bl-padding-s bl-padding-t-xl">
        <IonRow>
          <IonCol>
            <ActivityServices dataSource={activitiesDetails?.All} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default ActivitiesPage;
