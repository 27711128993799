import { IonCard, IonCol, IonGrid, IonRow } from "@ionic/react";
import { Box } from "@mui/material";
import React from "react";
import BLContentCardWrapper from "../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import BLIcon from "../../../shared/Ionic/BLIcon";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import { trash } from "ionicons/icons";
import { useTheme } from "../../../hooks/useTheme";

const LinkReservationCardButton = ({
  onClick,
  label,
  iconBackground = "secondary",
  icon,
  nights,
  dateInterval,
  border = "none",
  showRemoveIcon = false,
  areLinkResCardsCollapsed,
  setDeleteReservationDialog,
  guestName,
  HotelTitle,
  strapiResId,
  CRSNumber,
}) => {
  const { typography } = useTheme();
  return (
    <Box
      sx={{
        boxShadow: 5,
        borderRadius: "10px",
        width: "100%",
      }}
      onClick={onClick}
    >
      <IonCard
        button={onClick ? true : false}
        class="bl-padding-xs"
        style={{ border }}
      >
        <IonGrid>
          <IonRow>
            {icon && (
              <IonCol size="auto">
                <BLContentCardWrapper
                  background={iconBackground}
                  newStyles={{
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  dimensions={{ padding: ["xs"] }}
                >
                  <BLIcon iconSource={icon} iconSize="sm" color="white" />
                </BLContentCardWrapper>
              </IonCol>
            )}
            <IonCol className="bl-flex bl-padding-l-s ion-align-items-center">
              {label}
            </IonCol>
            {showRemoveIcon && !areLinkResCardsCollapsed &&
            <IonCol size="auto" className="ion-justify-content-end">
              <BLIcon 
                iconSource={trash} 
                color="danger" 
                iconSize="xsm"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteReservationDialog({
                    guestName,
                    dateInterval,
                    HotelTitle,
                    strapiResId,
                    CRSNumber,
                  });
                }}
              />
            </IonCol>
            }
          </IonRow>
          {(nights || dateInterval) && (
            <IonRow className="bl-padding-t-xs">
              {nights && (
                <IonCol size="auto">
                  <BLParagraph
                    txt={`${nights} ${nights === 1 ? "night" : "nights"}`}
                    //newClasses="xxx-small"
                    //TODO: Unclear typography
                    textVariant={typography.SMALL_PRINT_TEXT}
                    color="text-grey"
                  />
                </IonCol>
              )}
              {dateInterval && (
                <IonCol className="bl-flex ion-justify-content-end">
                  <BLParagraph
                    txt={dateInterval}
                    //newClasses="xxx-small"
                    //TODO: Unclear typography
                    textVariant={typography.SMALL_PRINT_TEXT}
                    color="text-grey"
                  />
                </IonCol>
              )}
            </IonRow>
          )}
        </IonGrid>
      </IonCard>
    </Box>
  );
};

export default LinkReservationCardButton;
