import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { calendar } from "ionicons/icons";
import React from "react";
import { useDataContext } from "../../../../../../context/DataContext";
import BLIcon from "../../../../../../shared/Ionic/BLIcon";
import BLLoadingContainer from "../../../../../../shared/Ionic/BLLoadingContainer";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import DaysRowLogic from "./DaysRowLogic";
import DaysRowSelection from "./Separate/DaysRowSelection";
import { useTheme } from "../../../../../../hooks/useTheme";

const DaysRow = () => {
  const { typography } = useTheme();
  const { isLoadingItinerary } = useDataContext();
  const { collapsedRowContent, hasMultipleSel } = DaysRowLogic();
  const { daysRowSelectionContent } = DaysRowSelection();

  const daysRowCollapsed = (
    <IonGrid>
      <IonRow className={`${!hasMultipleSel ? "ion-align-items-center" : ""}`}>
        <BLIcon iconSource={calendar} color={"primary"} iconSize={"md"} />
        <IonCol
          className={"bl-flex"}
          style={{ flexDirection: "column", gap: "5px" }}
        >
          {isLoadingItinerary ? <BLLoadingContainer /> : collapsedRowContent}
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const daysRowExpanded = (
    <IonGrid>
      <IonRow className={`${!hasMultipleSel ? "ion-align-items-center" : ""}`}>
        <IonCol className={"bl-flex ion-align-items-center"}>
          <BLIcon iconSource={calendar} color={"primary"} iconSize={"md"} />
          <BLParagraph
            txt={"Upon Arrival"}
            color={"primary"}
            textVariant={typography.h3}
            // newClasses={`medium bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
            }}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const daysRowContent = (
    <IonCol className={"ion-align-items-center"}>
      {isLoadingItinerary ? <BLLoadingContainer /> : daysRowSelectionContent}
    </IonCol>
  );

  return {
    daysRowCollapsed,
    daysRowExpanded,
    daysRowContent,
  };
};

export default DaysRow;
