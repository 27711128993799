import React from "react";
import { IonGrid, IonRow } from "@ionic/react";
import { useState } from "react";
import BLLoadingContainer from "../../../../shared/Ionic/BLLoadingContainer";
import CardAuthorisation from "./CardAuthorisation";
import { isDesktop } from "../../../../helpingFunctions/utilities";

const CardRegistration = ({ details }) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const changeLoadingComponent = async () => {
    setIsInitialLoading(false);
  };

  return (
    <IonGrid>
      <IonRow
        className="ion-justify-content-center"
        style={{
          marginTop: "40px",
          width: "100%"
        }}
      >
        {isInitialLoading
          ? <div
            style={{
              zIndex: -1,
              position: "absolute",
              top: "300px",
              width: "100%",
              textAlign: "center"
            }}
          >
            <BLLoadingContainer />
          </div>
          : <div
            style={{
              zIndex: -1,
              position: "absolute",
              top: "200px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              maxWidth: "555px",
              padding: "16px",
            }}  
          >
            <CardAuthorisation authorizationStatus="loading" />
          </div>
        }
        <iframe
          style={{
            border: "none",
            width: "100%",
            maxWidth: "535px",
            height: isDesktop() ? "850px" : "400px",
            overflow: "hidden",
            padding: "16px"
          }}
          src={details.redirectUrl}
          title="Register credit card"
          onLoad={changeLoadingComponent}
        >
        </iframe>
      </IonRow>
    </IonGrid>
  );
};

export default CardRegistration;