import { useContext } from "react";
import { Calendar } from "@awesome-cordova-plugins/calendar";
import add from "date-fns/add";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing";
import {
  IOS_PLATFORM,
  ANDROID_PLATFORM,
  INITIAL_DATE,
  MOBILEWEB_PLATFORM,
  MAESTRO_BACKEND_NAMING_VIEWS,
} from "../../../../../constants/constants";
import { ACTIVITIES_TYPES } from "../../../../../constants/constants";
import { DINING_TYPES } from "../../../../../constants/constants";
import { ENTERTAINMENT_TYPES } from "../../../../../constants/constants";
import { calcTotalGuests } from "../../../../../helpingFunctions/groups";
import { useDataContext } from "../../../../../context/DataContext";
import { isPlatform } from "@ionic/core";
import {
  calendarItinerary,
  seeOnMap,
  shareItinerary,
  viewOnMenu,
} from "../../../../../assets/SVGIcons";
import { MapImagePopupContext } from "../../../../../context/MapImagePopupContext";
import { ItineraryContext } from "../../context";
import { getBookedItemDescription, getBookedItemName } from "../../helpers";
import { formatTime } from "../../../../../helpingFunctions/dateTimeUtilities";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";
import { formatPrice } from "../../../../../helpingFunctions/formatPrice";
import { SideMenuContext } from "../../../../../context/SideMenuContext";
import { formatDuration } from "../../../../../helpingFunctions/formatDuration";

const DayItemLogic = (bookedItem) => {
  const { setCalendarLinksData } = useContext(ItineraryContext);
  const { setMapImage } = useContext(MapImagePopupContext);
  const { setFullScreenMap } = useContext(SideMenuContext);

  const {
    reservationDetails: { PartOfGroup, CRSNumber },
    facilitiesDataDetails,
    reservationHotelDetails,
    dynamicTexts,
    facilitiesData,
  } = useDataContext();
  const {
    diningPartyDetails: { name, participants },
  } = useContext(DiningPartyContext);

  const facilityData = facilitiesDataDetails?.find(
    (item) => item.id === bookedItem.Id
  );

  const entertainments = facilitiesData?.Entertainment?.All;

  const SINGLE_BOOKING_ACTIONS = [
    {
      id: `location`,
      icon: seeOnMap(25, "#153246"),
      onClick: (type) => {
        setFullScreenMap(true);
        setMapImage((prev) => ({
          ...prev,
          type: bookedItem?.MapImage ? type : "Hotel",
          url: bookedItem?.MapImage
            ? bookedItem.MapImage.url
            : reservationHotelDetails?.HotelMapImage?.url
        }));
      },
      label: "see on map",
    },
    {
      id: `calendar`,
      icon: calendarItinerary(25, "#153246"),
      onClick: () => {
        syncCalendar();
      },
      label: "add to calendar",
    },
    {
      id: `share`,
      icon: shareItinerary(25, "#153246"),
      onClick: () => {
        shareActivity();
      },
      label: "share",
    },
  ];

  if (DINING_TYPES.includes(bookedItem.Type) && facilityData?.Menu) {
    SINGLE_BOOKING_ACTIONS.splice(1, 0, {
      id: `menu`,
      icon: viewOnMenu(25, "#153246"),
      onClick: () => {
        window.open(facilityData.Menu);
      },
      label: "view menu",
    });
  }

  const bookedItemDetails = () => {
    try {
      let title = bookedItem?.Title;
      let duration = getDuration();
      let location = bookedItem.Location;
      let guestsInfo = getGuestsInfo();

      if (DINING_TYPES.includes(bookedItem.Type)) {
        title = bookedItem.Type;
        location = bookedItem.Title;
      } else if (ENTERTAINMENT_TYPES.includes(bookedItem.Type)) {
        title = getBookedItemName(bookedItem, entertainments, dynamicTexts);
        location = bookedItem.Location;
      } else if (bookedItem.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
        location = reservationHotelDetails?.SpaLocation;
        title += ` ${formatPrice(
          Number(bookedItem.ConfirmedPrice) *
            (bookedItem.totalParticipants || 1)
        )}`;
      } else if (
        bookedItem.Type === "Check in" ||
        bookedItem.Type.includes("NotRequired")
      ) {
        duration = bookedItem.Info;
      } else if (bookedItem.Type === "Treat") {
        title = `Treat: ${bookedItem.title}`;
        duration = `${bookedItem.Quantity}x ${bookedItem.Location}`;
        location = "";
      }

      return {
        title,
        location,
        duration,
        guestsInfo,
      };
    } catch (error) {
      console.error(
        `${new Date()} Error in bookedItemDetails func in BookedDayItemLogic.js ${
          error.message
        }`
      );
    }
  };

  const getGuestsInfo = () => {
    if (PartOfGroup && DINING_TYPES.includes(bookedItem.Type)) {
      const totalGuests = calcTotalGuests(participants);
      return {
        text: `You are part of "${name}" Dining party (${totalGuests} guests)`,
      };
    } else if (ACTIVITIES_TYPES.includes(bookedItem.Type)) {
      return {
        text: `${bookedItem.Quantity} ${
          Number(bookedItem.Quantity) === 1 ? "guest" : "guests"
        }`,
      };
    } else if (bookedItem.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
      let text = "1 guest:";
      const names = new Set();
      names.add(
        bookedItem.ParticipantFirstName && bookedItem.ParticipantLastName
          ? `${bookedItem.ParticipantFirstName} ${bookedItem.ParticipantLastName}`
          : `${bookedItem.FirstName} ${bookedItem.LastName}`
      );

      if (bookedItem.totalParticipants) {
        text = `${bookedItem.totalParticipants} guests:`;
      }

      if (bookedItem.others && bookedItem.others.length > 0) {
        bookedItem.others.forEach((guest) => {
          names.add(
            `${guest.ParticipantFirstName} ${guest.ParticipantLastName}`
          );
        });
      }

      return {
        text,
        names: Array.from(names),
      };
    }
  };

  const getDuration = () => {
    try {
      let durationInMinutes = 0;

      durationInMinutes += bookedItem.Duration || 0;
      durationInMinutes += bookedItem.DurationHour * 60 || 0;
      durationInMinutes += bookedItem.DurationMinutes || 0;
      if (
        durationInMinutes &&
        ![MAESTRO_BACKEND_NAMING_VIEWS.VENUE, ...DINING_TYPES].includes(
          bookedItem.Type
        )
      ) {
        const startDate = new Date(
          `${bookedItem.Date}T${bookedItem.Time.substr(0, 5)}`
        );
        const endDate = add(startDate, {
          years: 0,
          months: 0,
          weeks: 0,
          days: 0,
          hours: 0,
          minutes: durationInMinutes,
          seconds: 0,
        }).toString();

        const formattedTime = formatTime(
          `${INITIAL_DATE}T${bookedItem.Time.substr(0, 5)}`,
          false,
          "p"
        );

        if (endDate) {
          return `${formattedTime} - ${formatTime(
            endDate,
            false,
            "p"
          )} (${formatDuration(durationInMinutes)})`;
        }
      }
      return "";
    } catch (error) {
      console.error(
        `${new Date()} Error in bookingTimeDisplay func in ${error.message}`
      );
    }
  };

  const getItemLocation = (bookedItem) => {
    if (bookedItem.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA && reservationHotelDetails?.SpaLocation) {
      return "\nLocation: " + reservationHotelDetails.SpaLocation;
    } else if (bookedItem.Location) {
      return "\nLocation: " + bookedItem.Location;
    }

    return "";
  };

  const syncCalendar = () => {
    if (
      (isPlatform(IOS_PLATFORM) || isPlatform(ANDROID_PLATFORM)) &&
      !isPlatform(MOBILEWEB_PLATFORM)
    ) {
      const checkCalendars = () => {
        const success = (data) => {
          const calendarObj = data.find((calendar) => calendar.name === "MWS");
          if (calendarObj) {
            return deleteEvent(calendarObj);
          }
          return createCalendar();
        };

        const error = (err) => {
          console.error(
            `Error in syncCalendar function in BookedDayItemLogic.js ${err}`
          );
        };

        Calendar.listCalendars(success, error);
      };

      const checkForPermission = () => {
        Calendar.hasReadWritePermission((result) => {
          if (!result) {
            alert("Please allow MWS to use your calendar and try again.");
          } else {
            checkCalendars();
          }
        });
      };

      if (isPlatform(ANDROID_PLATFORM)) {
        checkCalendars();
      } else if (isPlatform(IOS_PLATFORM)) {
        checkForPermission();
      }
    } else {
      const title = getBookedItemName(bookedItem, entertainments, dynamicTexts);
      const location = encodeURIComponent(bookedItem.location || "");
      let duration = bookedItem.Duration || 0;
      duration += bookedItem.DurationHour * 60 || 0;
      duration += bookedItem.DurationMinutes || 0;
      let description = encodeURIComponent(
        getBookedItemDescription(
          bookedItem,
          CRSNumber,
          entertainments,
          dynamicTexts
        )
      );

      const startTime = new Date(
        bookedItem.Date + "T" + bookedItem.Time.slice(0, -6)
      ).toISOString();
      const endTime = add(new Date(startTime), {
        years: 0,
        months: 0,
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: duration,
        seconds: 0,
      }).toISOString();

      const startTimeGoogleAndYahoo = encodeURIComponent(
        startTime.replaceAll("-", "").replaceAll(":", "").slice(0, -5) + "Z"
      );
      const endTimeGoogleAndYahoo = encodeURIComponent(
        endTime.replaceAll("-", "").replaceAll(":", "").slice(0, -5) + "Z"
      );

      const startTimeMicrosoft = encodeURIComponent(
        startTime.slice(0, -5) + "+00:00"
      );
      const endTimeMicrosoft = encodeURIComponent(
        endTime.slice(0, -5) + "+00:00"
      );

      setCalendarLinksData({
        title,
        description,
        startTimeGoogleAndYahoo,
        endTimeGoogleAndYahoo,
        startTimeMicrosoft,
        endTimeMicrosoft,
        location,
      });
    }
  };

  const deleteEvent = (calendar) => {
    let addMinutes = 0;
    if (isPlatform(IOS_PLATFORM)) {
      addMinutes = 60;
    }
    let duration = bookedItem.Duration || 0;
    duration += bookedItem.DurationHour * 60 || 0;
    duration += bookedItem.DurationMinutes || 0;

    const startDate = new Date(
      bookedItem.Date + "T" + bookedItem.Time.slice(0, -6)
    );
    const endDate = add(new Date(startDate), {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: duration ? duration : addMinutes,
      seconds: 0,
    });
    const title = getBookedItemName(bookedItem, entertainments, dynamicTexts);
    const success = (data) => {
      if (data.length > 0) {
        const delSuccess = () => {
          addEventToCalendar(calendar.id);
        };

        const delError = (err) => {
          console.error(
            `Error in deleteEvent function in BookedDayItemLogic.js ${err}`
          );
        };

        Calendar.deleteEventById(data[0].id, null, delSuccess, delError);
      } else {
        addEventToCalendar(calendar.id);
      }
    };

    const error = (err) => {
      console.error(
        `Error in deleteEvent function in BookedDayItemLogic.js ${err}`
      );
    };

    Calendar.findEvent(title, null, null, startDate, endDate, success, error);
  };

  const createCalendar = () => {
    const createCalOptions = Calendar.getCreateCalendarOptions();
    createCalOptions.calendarName = "MWS";
    createCalOptions.calendarColor = "var(--ion-color-primary)";

    const success = (calendarId) => {
      addEventToCalendar(calendarId);
    };

    const error = () => {
      console.error(
        `Error in createCalendar function in BookedDayItemLogic.js ${error}`
      );
    };

    Calendar.createCalendar(createCalOptions, success, error);
  };

  const addEventToCalendar = (calendarId) => {
    const calOptions = Calendar.getCalendarOptions();
    calOptions.calendarId = calendarId;
    calOptions.calendarName = "MWS";

    const success = () => {
      alert("Activity added to calendar.");
    };
    const error = (error) => {
      alert(error);
    };
    let addMinutes = 0;
    if (isPlatform(IOS_PLATFORM)) {
      addMinutes = 60;
    }
    let duration = bookedItem.Duration || 0;
    duration += bookedItem.DurationHour * 60 || 0;
    duration += bookedItem.DurationMinutes || 0;

    const startDate = new Date(
      bookedItem.Date + "T" + bookedItem.Time.slice(0, -6)
    );
    const endDate = add(new Date(startDate), {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: duration ? duration : addMinutes,
      seconds: 0,
    });
    const title = getBookedItemName(bookedItem, entertainments, dynamicTexts);
    let notes = getBookedItemDescription(
      bookedItem,
      CRSNumber,
      entertainments,
      dynamicTexts
    );

    // Replace null with location when we have it
    Calendar.createEventWithOptions(
      title,
      null,
      notes,
      startDate,
      endDate,
      calOptions,
      success,
      error
    );
  };

  const shareActivity = () => {
    try {
      const activityName = getBookedItemName(
        bookedItem,
        entertainments,
        dynamicTexts
      );
      const facility = facilitiesDataDetails?.find(
        (facility) => facility.Title === bookedItem.Title
      );
      const description = facility.ShortDescription;
      const time = `${bookedItem.Date.split("-")
        .reverse()
        .join(".")} ${bookedItem.Time.slice(0, -9)}`;
      let duration = bookedItem.Duration || 0;
      duration += bookedItem.DurationHour * 60 || 0;
      duration += bookedItem.DurationMinutes || 0;
      const message = `${activityName}${
        description ? "\n" + description : ""
      }\nTime: ${time} ${getItemLocation(bookedItem)} ${duration ? "\nDuration: " + duration + " minutes" : ""}`;

      if (
        (isPlatform(IOS_PLATFORM) || isPlatform(ANDROID_PLATFORM)) &&
        !isPlatform(MOBILEWEB_PLATFORM)
      ) {
        const options = {
          message,
          subject: activityName,
        };

        const onSuccess = () => null;
        const onError = (err) => {
          console.error(
            `Error in shareActivity function in BookedDayItemLogic.js ${err}`
          );
        };

        SocialSharing.shareWithOptions(options, onSuccess, onError);
      } else {
        if (navigator.share) {
          navigator
            .share({
              title: activityName,
              text: message,
            })
            .then(() => {
              //confirmation message
            })
            .catch(console.error);
        } else {
          window.open(
            encodeURI(`mailto:?subject=${activityName}&body=${message}`)
          );
        }
      }
    } catch (err) {
      console.error(
        `Error in shareActivity function in BookedDayItemLogic.js ${err}`
      );
    }
  };

  return { SINGLE_BOOKING_ACTIONS, bookedItemDetails: bookedItemDetails() };
};

export default DayItemLogic;
