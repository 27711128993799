const ERROR_TEXTS = Object.freeze({
  SESSION_EXPIRED: "Your session has expired. Please log in again.",
  INVALID_RESERVATION:
    "This reservation has been cancelled or marked as invalid.",
  EXPIRED_RESERVATION: "This reservation has expired.",
  ALREADY_LINKED_RESERVATION: "This reservation is already linked.",
  NOT_FOUND_RESERVATION:
    "Sorry, we couldn't match the details provided, please check the surname and hotel reference number for spaces or spelling and try again.",
  ACTIVITY_FILTER_ERROR: "There is no activity for the selected filter.",
  ACTIVITY_NOT_CREATED_ERROR: "There is no activity created for the day.",
});

const ERROR_TYPES = Object.freeze({
  ERR_OLD_RESERVATION: "ERR_OLD_RESERVATION",
  ERR_CANCELED_RESERVATION: "ERR_CANCELED_RESERVATION",
  ERR_DEFAULT: "ERR_DEFAULT",
  ERR_LOGIN_RES_NOT_FOUND: "ERR_LOGIN_RES_NOT_FOUND",
});

export { ERROR_TEXTS, ERROR_TYPES };
