import React from "react";
import { IonButton, IonCol, IonRow, IonText } from "@ionic/react";
import CreateDimensions from "../../../helpingFunctions/DimensionsFactory";

import styles from "./BLButton.module.scss";
import { classFormatter, cssModuleClassConvert } from "../BLIonicHelpers";
import BLIcon from "../BLIcon";

const BUTTON_CSS_CLASS_IDENTIFIER = "bl-button-";

const BLButton = ({
  content,
  onClick = () => {
    console.error("No function applied for");
  },
  dimensions = {},
  disabled,
  newClasses = "",
  size = "small",
  color,
  type = "primary",
  icon,
  customIcon,
  iconSize = "sm",
  slot = "start",
  strong,
  noMargin,
  textColor = " ",
  textSize = "small",
  defaultCursor,
}) => {
  const dimensionStyles = CreateDimensions(dimensions);
  const buttonSpecificClasses = classFormatter(
    newClasses,
    BUTTON_CSS_CLASS_IDENTIFIER
  );
  const convertedClasses = cssModuleClassConvert(styles, buttonSpecificClasses);

  const getClassByType = (type) => {
    switch (type) {
      case "primary":
        return styles["bl-button-primary"];
      case "outlined":
        return styles["bl-button-primary-outlined"];
      case "underlined":
        return styles["bl-button-primary-underlined"];
      case "time":
        return styles["bl-button-time-primary"];
      case "time-outlined":
        return styles["bl-button-time-outlined"];
      case "icon-text":
        return styles["bl-button-icon-text"];
      case "transparent":
        return styles["bl-button-transparent"];
      case "outlined-icon-text":
        return styles["bl-button-outlined-icon-text"];
      case "primary-icon-text":
        return styles["bl-button-primary"];
      case "secondary":
        return styles["bl-button-secondary"];
      case "ui":
        return styles["bl-button-ui"];
      case "ui-small":
        return styles["bl-button-ui-small"];
      default:
        return "";
    }
  };

  const btnClasses = `${styles.BLButton} ${convertedClasses} ${
    styles["bl-button-common"]
  } ${
    color === "secondary" ? styles["bl-button-secondary"] : ""
  }  ${getClassByType(type)} ${dimensionStyles.dimensions} ${
    defaultCursor && "bl-default-pointer"
  }`;

  return [
    "transparent",
    "icon-text",
    "primary-icon-text",
    "outlined-icon-text",
    "ui",
    "ui-small",
  ].includes(type) && slot ? (
      <IonButton
        strong={strong}
        size={size}
        className={`${btnClasses}`}
        onClick={(e) => onClick(e)}
        disabled={disabled}
      >
        <IonRow className="bl-flex ion-align-items-center ion-nowrap">
          {slot === "start" && (
            <IonCol>
              <BLIcon
                id="icon"
                newClasses={noMargin ? "" : "bl-margin-r-xs"}
                iconSource={icon}
                customIconSource={customIcon}
                iconSize={iconSize}
              />
            </IonCol>
          )}
          <IonCol>
            <IonText
              id="text"
              color={textColor}
              className={`bl-text-${textSize} bl-text-semibold`}
            >
              {content}
            </IonText>
          </IonCol>
          {slot === "end" && (
            <IonCol>
              <BLIcon
                newClasses={noMargin ? "" : "bl-margin-l-xs"}
                iconSource={icon}
                customIconSource={customIcon}
                iconSize={iconSize}
              />
            </IonCol>
          )}
        </IonRow>
      </IonButton>
    ) : (
      <IonButton
        strong={strong}
        size={size}
        className={btnClasses}
        onClick={(e) => onClick(e)}
        disabled={disabled}
      >
        {content}
      </IonButton>
    );
};

export default BLButton;
