import React from "react";

import styles from "./BlurryWrapper.module.scss";
import { IonGrid } from "@ionic/react";

const BlurryWrapper = ({
  children,
  onClick = () => {},
  newClasses,
  newStyles,
}) => {
  return (
    <IonGrid
      className={`${styles.BlurryWrapper} ${newClasses}`}
      style={{
        ...newStyles,
      }}
      onClick={onClick}
    >
      {children}
    </IonGrid>
  );
};

export default BlurryWrapper;
