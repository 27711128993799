import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import { useDataContext } from "../../../../../context/DataContext";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";

import { calcTotalGuests } from "../../../../../helpingFunctions/groups";

import CreateDimensions from "../../../../../helpingFunctions/DimensionsFactory";
import BLInformationBox from "../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import LocalLoader from "../../../../../shared/Ionic/Loader/LocalLoader";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import ParticipantData from "./ParticipantData";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../../hooks/useTheme";
import { DINING_PARTY_LEADER_TITLE, DINING_PARTY_PARTICIPANT_TITLE } from "../../diningPartyConstants";

const ParticipantDisplay = ({ title, leader }) => {
  const { typography } = useTheme();
  const {
    reservationDetails: { Adults, FirstName, LastName, CRSNumber, IsGroupCreator },
  } = useDataContext();
  const { response, isPostGrCreationPartAddedLoading, diningPartySelections, diningPartyDetails } =
    useContext(DiningPartyContext);

  const prepareGroupRecordsView = () => {
    const participants = [];
    const leader = [];
    try {
      leader.push(
        <BLParagraph
          key="party_leader_title_text"
          txt={DINING_PARTY_LEADER_TITLE}
          textVariant={typography.h5}
          // newClasses={`x-small bold`}
          //TODO: Unclear typography
          color="text-grey"
          dimensions={{ padding: ["t-xs"] }}
        />
      );
      participants.push(
        <BLParagraph
          key="party_participant_title_text"
          txt={DINING_PARTY_PARTICIPANT_TITLE}
          textVariant={typography.h5}
          // newClasses={`x-small bold`}
          //TODO: Unclear typography
          color="text-grey"
          dimensions={{ padding: ["t-xs"] }}
        />
      );

      if (IsGroupCreator) {
        leader.push(
          <IonCol
            size="12"
            className={lastRowParticipantDimensions}
            key={`col_${FirstName}_${LastName}`}
          >
            <ParticipantData
              key={`${FirstName}_${LastName}`}
              firstName={FirstName}
              lastName={LastName}
              quantity={Adults}
              crsNumber={CRSNumber}
              prefix="(you)"
              isParticipantGroupCreator={IsGroupCreator}
            />
          </IonCol>
        );
      } else {
        participants.push(
          <IonCol
            size="12"
            key={`col_${FirstName}_${LastName}_${CRSNumber}`}
            className={
              diningPartyDetails.participants.length > 2
                ? lastRowParticipantDimensions
                : ""
            }
          >
            <ParticipantData
              key={`${FirstName}_${LastName}`}
              firstName={FirstName}
              lastName={LastName}
              quantity={Adults}
              crsNumber={CRSNumber}
              prefix="(you)"
              isParticipantGroupCreator={IsGroupCreator}
            />
          </IonCol>
        );
      }

      diningPartySelections.participants.forEach((row, index) => {
        if (!row.IsGroupCreator) {
          participants.push(
            <IonCol size="12" key={`col_${row.FirstName}_${row.LastName}_${index}`}>
              <ParticipantData
                key={`${row.FirstName}_${row.LastName}`}
                participantId={row.id}
                firstName={row.FirstName}
                lastName={row.LastName}
                quantity={row.Adults}
                crsNumber={row.CRSNumber}
                isParticipantGroupCreator={row.IsGroupCreator}
              />
            </IonCol>
          );
        } else {
          leader.push(
            <IonCol size="12" key={`col_${row.FirstName}_${row.LastName}_${index}`}>
              <ParticipantData
                key={`${row.FirstName}_${row.LastName}`}
                firstName={row.FirstName}
                lastName={row.LastName}
                quantity={row.Adults}
                crsNumber={row.CRSNumber}
                isParticipantGroupCreator={row.IsGroupCreator}
              />
            </IonCol>
          );
        }
      });

      if (IsGroupCreator) {
        return [...leader, ...participants];
      } else {
        return [...participants, ...leader];
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in isCurrentReservationLabel func in ParticipantDisplayLogic.js ${
          error.message
        }`
      );
    }
  };


  const { dimensions: participantListWrapperDimensions } = CreateDimensions({
    padding: ["t-xs"],
  });

  const { dimensions: totalParticipantDimensions } = CreateDimensions({
    padding: ["t-s"],
  });

  const { dimensions: lastRowParticipantDimensions } = CreateDimensions({
    padding: ["b-s"],
  });

  const totalParticipants = calcTotalGuests(
    diningPartySelections.participants,
    "Adults"
  );
  const total = totalParticipants + Number(Adults);

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="12">
          {leader ? (
            title
          ) : (
            <BLParagraph
              textVariant={typography.h4}
              //newClasses="small bold"
              //TODO: Unclear typography
              dimensions={{ margin: ["t-m"] }}
              txt={title}
              color="text-grey"
            />
          )}
        </IonCol>
      </IonRow>
      <IonRow className={participantListWrapperDimensions}>
        {totalParticipants > 0 && prepareGroupRecordsView()}
      </IonRow>

      <IonRow className={totalParticipantDimensions}>
        <IonCol
          size="10.3"
          className={isDesktop() ? "bl-padding-t-s" : ""}
          style={{
            borderTop: isDesktop() ? "1px solid var(--ion-color-primary)" : "",
          }}
        >
          <BLParagraph
            txt={`Total number of guests: ${total}`}
            textVariant={typography.h5}
            // newClasses={`x-small bold`}
            //TODO: Unclear typography
            color="text-grey"
          />
        </IonCol>
      </IonRow>

      {diningPartyDetails.id && (
        <IonRow
          className={`${
            response?.isLoading || isPostGrCreationPartAddedLoading
              ? "ion-justify-content-center"
              : ""
          } ${response?.message && !isPostGrCreationPartAddedLoading && "bl-padding-t-s bl-padding-b-xs"}`}
        >
          {(response?.isLoading || isPostGrCreationPartAddedLoading) && (
            <LocalLoader loaderSize="40px" dimensions={{ margin: ["t-l"] }} />
          )}

          {response?.message && !isPostGrCreationPartAddedLoading && (
            <BLInformationBox
              content={response.message}
              type={response.infoBoxColor}
            />
          )}
        </IonRow>
      )}
    </IonGrid>
  );
};

export default ParticipantDisplay;
