import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MAESTRO_BACKEND_NAMING_VIEWS, SERVICES_TYPES, INITIAL_DATE } from "../constants/constants";
import {
  formatDateDetailed,
  formatTime,
  getDatesBetween,
  getDayOfStay,
  getDayPeriod,
} from "../helpingFunctions/dateTimeUtilities";
import { useDataContext } from "./DataContext";
export const ActivityContext = createContext({});

export const ActivityProvider = ({ children }) => {
  const location = useLocation();
  const {
    itineraryDetails,
    facilitiesData,
    availability,
    reservationDetails,
  } = useDataContext();
  const [confirmationScreenSelections, setConfirmationScreenSelections] =
    useState(null);
  const [isProcessingBookings, setIsProcessingBookings] = useState(false);
  const [daySelections, setDaySelections] = useState(null);
  const [priceMsg, setPriceMsg] = useState(null);
  const [showBookingSection, setShowBookingSection] = useState(false);
  const [activityAvailability, setActivityAvailability] = useState(null);
  const [activitySelections, setActivitySelections] = useState([]);
  const [activityViewType, setActivityViewType] = useState(null);
 
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [activitiesDetails, setActivitiesDetails] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const extractDaySelections = () => {
    const stayingDates = getDatesBetween(
      reservationDetails?.ArrivalDate,
      reservationDetails?.DepartureDate
    );

    const daySels = [];
    stayingDates.forEach((date) => {
      daySels.push({
        Title: formatDateDetailed(stayingDates, date, true),
        fullTitle: formatDateDetailed(stayingDates, date),
        dayOfStay: getDayOfStay(stayingDates, date),
        view: "Activity",
        id: date,
      });
    });

    setDaySelections(daySels);
  };

  const isActivityAvailable = (activity, availability) => {
    return !!Object.values(availability)
      .map(a => a.AvailabilityDetails)
      .flat()
      .find(facility => facility.FacilityGUID === activity.id);
  };

  const isActivityBooked = (activity, itineraryDetails) => {
    return !!Object.values(itineraryDetails.Dates)
      .map(date => date.Activity)
      .flat()
      .find(bookedActivity => bookedActivity.Id === activity.id);
  };

  useEffect(() => {
    if (reservationDetails) extractDaySelections();
  }, [reservationDetails]);

  useEffect(() => {
    if (facilitiesData && availability && itineraryDetails) {
      setActivityAvailability({ Activity: availability.Activity });

      const facilitiesDataCopy = JSON.parse(JSON.stringify(facilitiesData.Activities));
      const filteredActivities = facilitiesDataCopy?.All?.filter(activity => 
        isActivityAvailable(activity, availability.Activity) || 
        isActivityBooked(activity, itineraryDetails)
      );
      facilitiesDataCopy.All = filteredActivities;
      setActivitiesDetails(facilitiesDataCopy);
    }
  }, [facilitiesData, availability, itineraryDetails]);

  useEffect(() => {
    if (location?.pathname) {
      if (location.pathname.includes(SERVICES_TYPES.ACTIVITIES)) {
        setActivityViewType(MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY);
      }
    }
  }, [location]);

  const adjustActivityAvailability = (data) => {

    Object.entries(itineraryDetails.Dates).forEach(([key, value]) => {
      value[MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY].forEach((item) => {
        const availabilityDetails = data[MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY][key].AvailabilityDetails;
        const time = item.Time;
        const shortTime = formatTime(
          `${INITIAL_DATE}T${time.substring(0, 5)}`,
          "h:mm"
        );
        const dayTime = getDayPeriod(`${INITIAL_DATE}T${time.substring(0, 5)}`);

        const foundIndex = availabilityDetails.findIndex(
          (row) => row.FacilityGUID === item.id
        );

        const hasTimeObj = availabilityDetails[
          foundIndex
        ].Times[dayTime].find((row) => row.time === time);

        if (!hasTimeObj) {
          availabilityDetails.Times[dayTime].push({
            availability: 0,
            shortTime: shortTime.split(" ")[0],
            time: time,
          });

          availabilityDetails[foundIndex].Times[
            dayTime
          ].sort((a, b) => {
            return a.time.localeCompare(b.time);
          });
        }
      });
    });
  };

  const handleAvailabilityOnSelection = (
    activityAvailabilityCopy,
    day,
    dayTime,
    time,
    action,
    activityId,
    quantity
  ) => {
    const selectedActivityAvailIndex = activityAvailabilityCopy[
      activityViewType
    ][day].AvailabilityDetails.findIndex(
      (activityAvail) => activityAvail.FacilityGUID === activityId
    );

    if (selectedActivityAvailIndex > -1) {
      const availIndex = activityAvailabilityCopy[activityViewType][
        day
      ].AvailabilityDetails[selectedActivityAvailIndex].Times[
        dayTime
      ].findIndex((avail) => avail.time === time);

      if (action === "increment") {
        activityAvailabilityCopy[activityViewType][day].AvailabilityDetails[
          selectedActivityAvailIndex
        ].Times[dayTime][availIndex].availability += quantity;
      } else if (action === "decrement") {
        activityAvailabilityCopy[activityViewType][day].AvailabilityDetails[
          selectedActivityAvailIndex
        ].Times[dayTime][availIndex].availability -= quantity;
      }
    }
  };

  const cancelChangesHandler = () => {
    let activitySelectionsCopy = JSON.parse(JSON.stringify(activitySelections));
    const activityAvailabilityCopy = JSON.parse(
      JSON.stringify(activityAvailability)
    );

    activitySelectionsCopy = activitySelectionsCopy.filter(
      (row) => row.action !== "add"
    );
    activitySelectionsCopy.forEach((row) => {
      row.on = row.on.filter((dayTimeRow) => dayTimeRow.action !== "add");
    });

    activitySelectionsCopy.forEach((sel) => {
      sel.action = "none";
      sel.on.forEach((dayTimeSel) => {
        dayTimeSel.date = dayTimeSel.bookedDate;
        dayTimeSel.time = dayTimeSel.bookedTime;
        dayTimeSel.quantity = dayTimeSel.bookedQuantity;
        dayTimeSel.action = "none";
      });
    });

    setActivityAvailability(activityAvailabilityCopy);
    setActivitySelections(activitySelectionsCopy);
    setIsEditMode(false);
  };

  return (
    <ActivityContext.Provider
      value={{
        activitiesDetails,
        activityAvailability,
        activitySelections,
        activityViewType,
        showAdditionalDetails,
        setShowAdditionalDetails,
        setActivityViewType,
        setActivitySelections,
        setActivityAvailability,
        adjustActivityAvailability,
        handleAvailabilityOnSelection,
        cancelChangesHandler,
        selectedService,
        setSelectedService,
        showBookingSection,
        setShowBookingSection,
        confirmationScreenSelections,
        setConfirmationScreenSelections,
        isProcessingBookings,
        setIsProcessingBookings,
        daySelections,
        setDaySelections,
        priceMsg,
        setPriceMsg,
        isEditMode,
        setIsEditMode,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
