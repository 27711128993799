import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import BookedDayDetails from "./BookedDayDetails/BookedDayDetails";

import { ITEM_POPULAR_STATUSES, SERVICES_TYPES } from "../../../../../constants/constants";
import { useDataContext } from "../../../../../context/DataContext";
import { getDatesBetween } from "../../../../../helpingFunctions/dateTimeUtilities";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";
import { EntertainmentsPageContext } from "../../EntertainmentsPageContext";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../../hooks/useTheme";

const EntertainmentBookingPreview = () => {
  const { typography } = useTheme();
  const { notifyIconDisplay, reservationDetails } = useDataContext();

  const {
    entertainmentBookingSelections,
    skippedDays,
    mainStatus,
  } = useContext(EntertainmentsPageContext);

  const datesList = getDatesBetween(
    reservationDetails.ArrivalDate,
    reservationDetails.DepartureDate,
    SERVICES_TYPES.ENTERTAINMENT
  );

  const allSkipped = skippedDays.length === datesList.length && entertainmentBookingSelections.length === 0; 
  const sortedSelections = entertainmentBookingSelections.sort((selectionA, selectionB) => (selectionA.date+" "+selectionA.time).localeCompare(selectionB.date+" "+selectionB.time));
  const summaryScreenText = allSkipped 
    ? mainStatus === ITEM_POPULAR_STATUSES.PENDING 
      ? "By clicking on 'Confirm' you confirm that you want to skip your entertainment booking. You can amend anytime if you change your mind."
      : mainStatus === ITEM_POPULAR_STATUSES.LOAD
        ? "We are skipping your entertainment."
        : "We skipped your entertainment. You can amend anytime if you change your mind."
    : "Below is the summary of your booking. Please review and confirm.";

  return (
    <IonGrid className={isDesktop() ? "" : "bl-padding-l-xs bl-padding-r-xs"}>
      <IonRow>
        <IonCol size="12">
          <BLParagraph
            newStyles={{ display: "flex", justifyContent: "center" }}
            txt={summaryScreenText}
            textVariant={typography.BODY_TEXT}
            color="text-grey"
          />
        </IonCol>
      </IonRow>
      {((allSkipped && mainStatus === ITEM_POPULAR_STATUSES.LOAD) || (!allSkipped && entertainmentBookingSelections?.length === 0)) && (
        <IonRow className="ion-justify-content-center ion-padding-top ion-padding-bottom"> 
          <BLLoadingContainer />
        </IonRow>
      )}
      {!allSkipped && entertainmentBookingSelections?.length > 0 ? (
        <IonRow>
          <IonCol size="12">
            <BLContentCardWrapper
              dimensions={{
                padding: ["t-s", "b-s", "l-xs", "r-xs"],
                margin: ["t-s"],
              }}
            >
              <IonGrid style={{maxWidth: "525px", margin: "0 auto"}}>
                {isDesktop() ? (
                  <>
                    <IonRow>
                      <IonCol size="9">
                        <BLParagraph
                          txt="Summary"
                          textVariant={typography.h3}
                          // newClasses="medium bold"
                          //TODO: Unclear typography
                          color="primary"
                        />
                      </IonCol>
                      <IonCol size="3" className="bl-padding-l-m">
                        <BLParagraph
                          txt="Status"
                          textVariant={typography.h3}
                          // newClasses="medium bold"
                          //TODO: Unclear typography
                          color="primary"
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                      {sortedSelections?.map(
                        (entertainmentBookingSelection, index) => 
                          (entertainmentBookingSelection.action !== "none" || entertainmentBookingSelection.status)  &&
                          <React.Fragment key={`frag_${index}`}>
                            <IonCol size="9">
                              <BookedDayDetails
                                key={`${entertainmentBookingSelection}`}
                                entertainmentBookingSelection={
                                  entertainmentBookingSelection
                                }
                              />
                            </IonCol>
                            <IonCol
                              size="3"
                              className="bl-padding-l-m bl-margin-t-s"
                            >
                              {notifyIconDisplay(
                                entertainmentBookingSelection?.status
                              )}
                            </IonCol>
                          </React.Fragment>
                      )}
                    </IonRow>
                  </>
                ) : (
                  <IonRow>
                    <IonCol size="12">
                      {sortedSelections?.map(
                        (entertainmentBookingSelection, index) => 
                          entertainmentBookingSelection.action !== "none" &&
                        (
                          <BookedDayDetails
                            key={`mob_${index}`}
                            entertainmentBookingSelection={
                              entertainmentBookingSelection
                            }
                          />
                        )
                      )}
                    </IonCol>
                  </IonRow>
                )}
              </IonGrid>
            </BLContentCardWrapper>
          </IonCol>
        </IonRow>
      ) : null}
    </IonGrid>
  );
};

export default EntertainmentBookingPreview;
