import { useContext, useEffect } from "react";
import { useDataContext } from "../../../../../../../context/DataContext";
import { TreatsContext } from "../../../../../../../context/TreatsContext";
import { getCurrentDate } from "../../../../../../../helpingFunctions/dateTimeUtilities";

const DaysRowSelectionLogic = () => {
  const {
    treatsSelections,
    setTreatsSelections,
    setTreatsDaysStatuses,
    prepareLocationsDropdown,
    daySelections,
  } = useContext(TreatsContext);
  const { reservationDetails } = useDataContext();

  const handleDaySelection = (selIndex, val) => {
    let treatsSelCopy = JSON.parse(JSON.stringify(treatsSelections));

    treatsSelCopy[selIndex].isEdited = false;
    treatsSelCopy[selIndex].date = val.target.value;

    if (treatsSelCopy[selIndex].action !== "add") {
      if (treatsSelCopy[selIndex].bookedDate === val.target.value) {
        treatsSelCopy[selIndex].action = "none";

        treatsSelCopy[selIndex].options.forEach((_, optInd) => {
          treatsSelCopy[selIndex].options[optInd].action = "none";
          treatsSelCopy[selIndex].options[optInd].location = treatsSelCopy[selIndex].options[optInd].bookedLocation;
          treatsSelCopy[selIndex].options[optInd].quantity = treatsSelCopy[selIndex].options[optInd].bookedQuantity;
          treatsSelCopy[selIndex].options[optInd].serviceCode = treatsSelCopy[selIndex].options[optInd].bookedServiceCode;
        });
      } else {
        treatsSelCopy[selIndex].action = "edit";

        treatsSelCopy[selIndex].options.forEach((_, optInd) => {
          if (treatsSelCopy[selIndex].options[optInd].location === "In-Room") {
            treatsSelCopy[selIndex].options[optInd].action = "remove";
          } else {
            treatsSelCopy[selIndex].options[optInd].location = "";
            treatsSelCopy[selIndex].options[optInd].quantity = 1;
            treatsSelCopy[selIndex].options[optInd].serviceCode = "";
            treatsSelCopy[selIndex].options[optInd].action = "edit";
          }
        });
      }
    } else {
      treatsSelCopy[selIndex].options = [
        {
          action: "add",
          serviceCode: "",
          bookedServiceCode: "",
          location: "",
          bookedLocation: "",
          quantity: 1,
          bookedQuantity: "",
        },
      ];
    }

    setTreatsSelections(treatsSelCopy);
  };

  const addSeparateDays = () => {
    const treatsSelCopy = JSON.parse(JSON.stringify(treatsSelections));
    treatsSelCopy.push({
      fName: reservationDetails.FirstName,
      lName: reservationDetails.LastName,
      guestName: `${reservationDetails.FirstName} ${reservationDetails.LastName}`,
      date: "",
      bookedDate: "",
      options: [
        {
          action: "add",
          serviceCode: "",
          bookedServiceCode: "",
          location: "",
          bookedLocation: "",
          quantity: 1,
          bookedQuantity: "",
        },
      ],
      action: "add",
      isEdited: false,
    });
    setTreatsSelections(treatsSelCopy);
  };

  const removeSeparateSelection = (selIndex) => {
    let treatSelectionsCopy = JSON.parse(JSON.stringify(treatsSelections));

    if (treatSelectionsCopy[selIndex].action === "add") {
      if (selIndex === 0 && treatSelectionsCopy.length === 1) {
        treatSelectionsCopy[selIndex].isEdited = false;
        treatSelectionsCopy[selIndex].options = [
          {
            action: "add",
            location: "",
            bookedLocation: "",
            serviceCode: "",
            bookedServiceCode: "",
            quantity: 1,
            bookedQuantity: "",
          },
        ];
        treatSelectionsCopy[selIndex].date = "";
        prepDropdownOptions();
      } else {
        treatSelectionsCopy = treatSelectionsCopy.filter(
          (row, index) => index !== selIndex
        );
      }
    } else {
      treatSelectionsCopy[selIndex].action = "remove";
      for (let i = 0; i < treatSelectionsCopy[selIndex].options.length; i++) {
        treatSelectionsCopy[selIndex].options[i].action = "remove";
      }
    }

    setTreatsSelections(treatSelectionsCopy);
  };

  const updateStatus = (selIndex) => {
    const treatsSelectionsCopy = JSON.parse(JSON.stringify(treatsSelections));
    treatsSelectionsCopy[selIndex].isEdited = true;
    setTreatsSelections(treatsSelectionsCopy);
  };

  const prepDropdownOptions = () => {
    const remainingDays = {};
    daySelections.forEach((date) => {
      if (!remainingDays.hasOwnProperty(date.id)) {
        const locationOpt = prepareLocationsDropdown([], date.id);

        remainingDays[date.id] = {
          ...date,
          hasSelection: !!treatsSelections.find((sel) => sel.date === date.id),
          disableOption: locationOpt.length === 0 || date.id < getCurrentDate(),
        };
      }
    });

    setTreatsDaysStatuses(Object.values(remainingDays));
  };

  useEffect(() => {
    prepDropdownOptions();
  }, [treatsSelections]);

  return {
    handleDaySelection,
    addSeparateDays,
    removeSeparateSelection,
    updateStatus,
  };
};

export default DaysRowSelectionLogic;
