import { IonPage, IonRouterOutlet } from "@ionic/react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { TreatsProvider } from "../context/TreatsContext";
import TreatYourselfPage from "../views/children/TreatYourselfPage/TreatYourselfPage";
import FallbackRoute from "./FallbackRoute";
import { ROUTE_VIEWS } from "./routeViews";

const treatsPage = () => {
  return (
    <TreatsProvider>
      <TreatYourselfPage />
    </TreatsProvider>
  );
};

const TreatsRoutes = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Switch>
          <Route
            path={ROUTE_VIEWS.TREATS}
            exact={true}
            component={treatsPage}
          />
          <Route
            path={ROUTE_VIEWS.TREATS_CATEGORY}
            exact={true}
            component={treatsPage}
          />
          <Route
            path={ROUTE_VIEWS.TREAT_BOOKING}
            exact={true}
            component={treatsPage}
          />
          <Route component={FallbackRoute} />
        </Switch>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default TreatsRoutes;
