import React from "react";
import { IonBadge, IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import * as ionicons from "ionicons/icons";
import BLIcon from "../../../../shared/Ionic/BLIcon";
import { hexToFilter } from "css-filter-converter";
import { itineraryOutlined } from "../../../../assets/SVGIcons";
import styles from "./DesktopMenuItem.module.scss";
import { SERVICES_TYPES } from "../../../../constants/constants";
import { useTheme } from "../../../../hooks/useTheme";

const DesktopMenuItem = ({ title, icon, isSelected, isDisabled, type, onClick }) => {
  const { typography } = useTheme();
  const whiteColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue(`--ion-color-white`);
  const whiteColorFilter = hexToFilter(whiteColor).color;
  return (
    <IonButton
      fill="clear"
      color="white"
      style={{ 
        width: "100%", 
        borderRight: isSelected ? "1px solid white" : "none",
        borderTop: isSelected ? "1px solid white" : "none",
        borderBottom: isSelected ? "1px solid white" : "none",
        borderTopRightRadius: "4px", 
        borderBottomRightRadius: "4px",
        "--padding-top": "var(--bl-padding-xxs)",
        "--padding-bottom": "var(--bl-padding-xxs)",
        backgroundColor: isSelected ? `var(--ion-color-tertiary)` : ""
      }}
      onClick={onClick}
    >
      <IonGrid>
        <IonRow className="bl-flex ion-align-items-center ion-justify-content-start ion-nowrap">
          <IonCol size="auto">
            {icon && icon.Ionic && (
              <BLIcon
                iconSource={icon?.Ionic ? ionicons[icon.Ionic.name] : null}
                color="white"
                iconSize="sm"
              />
            )}
            {icon && !icon.Ionic && icon.custom && icon.custom.source && (
              <img
                src={icon.custom.source.url}
                alt={icon.custom.source.alternativeText}
                className="bl-icon-sm"
                style={{filter: whiteColorFilter}}
              />
            )}
            {icon && typeof icon === "string" &&
            <BLIcon
              iconSource={icon}
              color="white"
              iconSize="sm"
            />
            }
            {SERVICES_TYPES.ITINERARY.includes(type) &&
            <BLIcon
              customIconSource={itineraryOutlined(
                24,
                24,
                "var(--ion-color-white)"
              )}
              color="white"
              iconSize="sm"
            />
            }
          </IonCol>
          <IonCol
            size="auto" 
            className="bl-padding-l-xxs bl-flex ion-justify-content-start" 
            style={{maxWidth: isDisabled ? "160px" : "190px"}}
          >
            <BLParagraph 
              txt={title} 
              typographyStyles={{display: "inline"}} 
              textVariant={typography.TEXT_LINK} 
              newClasses="text-ellipsis"
              //newClasses={"x-small semibold underline text-ellipsis"}
              //TODO: Unclear typography
            />
          </IonCol>
          {isDisabled &&
          <IonCol className="ion-justify-content-start bl-padding-l-xs">
            <IonBadge className={styles.MenuItemBadge}>
              <BLIcon iconSource={ionicons.information} iconSize="xxsm" />
            </IonBadge>
          </IonCol>
          }
        </IonRow>
      </IonGrid>
    </IonButton>
  );
};

export default DesktopMenuItem;