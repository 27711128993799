import React from "react";

import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";

import Divider from '@mui/material/Divider';

import { useTheme } from "../../hooks/useTheme";

import styles from "./BLHorizontalLineTitle.module.scss";
import BLParagraph from "./Paragraph/BLParagraph";
import BLButton from "./Buttons/BLButton";

const BLHorizontalLineTitle = ({
  txt,
  color,
  enableBackButton,
  backButtonHandler,
  transperentBackground
}) => {
  const { typography } = useTheme();

  return (
    <IonRow className="ion-text-center bl-margin-b-m">
      {enableBackButton && (
        <IonCol className="bl-flex ion-align-items-center bl-margin-r-xs" size="auto">
          <BLButton
            type="icon-text"
            newClasses="small ion-no-padding"
            noMargin
            size="small"
            icon={chevronBackOutline}
            content="Back"
            onClick={backButtonHandler}
          />
        </IonCol>
      )}

      <IonCol className="bl-flex">
        <IonGrid className="bl-flex ion-align-items-center">
          <IonCol>
            <Divider className={styles.divider} />
          </IonCol>
          <IonCol size="auto" className="bl-padding-l-xs bl-padding-r-xs">
            <BLParagraph
              txt={txt}
              color={color}
              textVariant={typography.h2}
              newClasses="capitalize"
              // newClasses={"large bold crimson-pro capitalize"}
              //TODO: Unclear typography
              center
              newStyles={{
                background: transperentBackground
                  ? ""
                  : "var(--ion-color-white)",
                padding: "0 10px",
              }}
            />
          </IonCol>
          <IonCol>
            <Divider className={styles.divider} />
          </IonCol>
        </IonGrid>
      </IonCol>
    </IonRow>
  );
};

export default BLHorizontalLineTitle;
