import { useContext } from "react";
import { useDataContext } from "../../../../../context/DataContext";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";
import { DINING_PARTY_MODALS, INIT_RESPONSE_DATA } from "../../diningPartyConstants";

const CreateDiningPartyLogic = () => {
  const { setReservationDetails } =
  useDataContext();
  const {
    addMoreHandler,
    setResponse,
    setShowDiningPartyParticipantForm,
    setDiningPartyDisplay,
    setDiningPartySelections,
    diningPartyDetails
  } = useContext(DiningPartyContext);

  const cancelAddedParticipants = () => {
    try {
      setDiningPartySelections(diningPartyDetails);

      if (diningPartyDetails.participants.length === 0) {
        addMoreHandler(false);
      }

      if (!diningPartyDetails.id) {
        setDiningPartyDisplay(DINING_PARTY_MODALS.INIT);
        setReservationDetails((prevState) => {
          return { ...prevState, isGroupCreator: false };
        });
      }

      setResponse(INIT_RESPONSE_DATA);
      setShowDiningPartyParticipantForm(false);
    } catch (error) {
      console.error(
        `${new Date()} Error in cancelAddedParticipants func CreateDiningPartyLogic.js ${
          error.message
        }`
      );
    }
  };

  return { cancelAddedParticipants };
};

export default CreateDiningPartyLogic;
