import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import BLIcon from "../../../../../../shared/Ionic/BLIcon";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import { calendar } from "ionicons/icons";
import { useTheme } from "../../../../../../hooks/useTheme";

const DaysRowExpandedTitle = () => {
  const { typography } = useTheme();

  return (
    <IonGrid>
      <IonRow>
        <IonCol className={"bl-flex ion-align-items-center"}>
          <BLIcon iconSource={calendar} color={"primary"} iconSize={"md"} />
          <BLParagraph
            txt={"Days and no. of guests"}
            color={"primary"}
            textVariant={typography.h3}
            // newClasses={`medium bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
            }}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default DaysRowExpandedTitle;