import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import RequestHook from "../../../hooks/RequestHook";

import { INITIAL_DATE } from "../../../constants/constants";
import { useDataContext } from "../../../context/DataContext";
import { WhatsOnDataContext } from "../../../context/WhatsOnDataContext";
import { getDatesBetween } from "../../../helpingFunctions/dateTimeUtilities";

const INTERVAL_15_MIN_PERIOD = 900000;

const WhatsOnGuideLogic = () => {
  const { getGuide } = useContext(WhatsOnDataContext);
  const { getInternalActivityItinerary } = RequestHook();
  const { reservationDetails, reservationHotelDetails } = useDataContext();

  const { pathname } = useLocation();
  const isInPublicPage = pathname.includes("public");
  const isNotPrivate = isInPublicPage || pathname.includes("team");

  const { hotel } = useParams();

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [internalActivityItinerary, setInternalActivityItinerary] = useState(
    []
  );

  const constructParams = async () => {
    try {
      if (isNotPrivate) {
        return {
          showDates: [INITIAL_DATE],
          hotel,
        };
      }

      const dates = getDatesBetween(
        reservationDetails.ArrivalDate,
        reservationDetails.DepartureDate
      );
      const formatedDates = dates.map((date) =>
        moment(new Date(date)).format("YYYY-MM-DD")
      );
      return {
        showDates: formatedDates,
        hotel: reservationHotelDetails.Title,
      };
    } catch (error) {
      return {
        showDates: [INITIAL_DATE],
        hotel,
      };
    }
  };

  const internalActivityItineraryHandler = async (params) => {
    try {
      const data = await getInternalActivityItinerary({
        ReservationNumber: reservationDetails.ReservationNumber,
        CRSNumber: reservationDetails.CRSNumber,
        LastName: reservationDetails.LastName,
        ...params,
      });

      setInternalActivityItinerary(data);
    } catch (error) {
      console.error(
        `${new Date()} Error in internalActivityItineraryHandler func in WhatsOnGuideLogic file ${
          error.message
        }`
      );
    }
  };

  const scheduleDataFetch = async () => {
    try {
      const params = await constructParams();
      await getGuide(params);

      if (isInPublicPage) {
        setInterval(async () => {
          const params = await constructParams();
          await getGuide(params);
        }, INTERVAL_15_MIN_PERIOD);
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in scheduleDataFetch func ${error.message}`
      );
    }
  };

  useEffect(() => {
    scheduleDataFetch();
    if (!isNotPrivate) {
      internalActivityItineraryHandler({
        IsInternalActivity: true,
      });
    }
  }, []);

  return {
    selectedItemId,
    internalActivityItinerary,
    setSelectedItemId,
    internalActivityItineraryHandler,
  };
};

export default WhatsOnGuideLogic;
