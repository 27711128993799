import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ActivityContext } from "../../../../context/ActivityContext";
import { useDataContext } from "../../../../context/DataContext";
import ServiceSummaryCard from "../../../../shared/Ionic/ServicesComponents/ServiceCards/ServiceSummaryCard";
import BLBottomPopupWrapper from "../../../../shared/Ionic/Wrappers/BLBottomPopupWrapper";
import BLContentCardWrapper from "../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import ActivityBookingFlow from "../components/ActivityBookingFlow";
import AdditionalActivitiesDetails from "../components/Cards/AdditionalActivitiesDetails";
import BLAdditionalActivityDetailsPopupBox from "../components/Cards/BLAdditionalActivityDetailsPopupBox";
import ActivitiesProcessingMethods from "../components/Logic/ActivitiesProcessingMethods";
import { hasBooking } from "../../../../helpingFunctions/bookingHelp";
import { isDesktop } from "../../../../helpingFunctions/utilities";
import { useParams, useLocation } from "react-router-dom";
import { ROUTE_VIEWS } from "../../../../routes/routeViews";
import { NavigatorContext } from "../../../../context/NavigatorContext";

const ActivityServices = ({ dataSource }) => {
  const modal = useRef(null);
  const { activityId } = useParams();

  const location = useLocation();
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const [modalRef, setModalRef] = useState();
  const [isFirstTimeBooker, setIsFirstTimeBooker] = useState(false);
  const [isEditActionOnlyCancelled, setIsEditActionOnlyCancelled] =
    useState(false);
  const [hasCancellationPolicy, setHasCancellationPolicy] = useState(false);
  const [cancellationPolicyAlertsLength, setCancellationPolicyAlertsLength] =
    useState(0);
  const [cancellationPolicyAlerts, setCancellationPolicyAlerts] = useState([]);
  const [activityIdExists, setActivityIdExists] = useState(false);
  const { itineraryDetails } = useDataContext();

  const {
    showAdditionalDetails,
    setShowAdditionalDetails,
    selectedService,
    setSelectedService,
    showBookingSection,
    setShowBookingSection,
    setIsEditMode,
    activitiesDetails,
    setConfirmationScreenSelections,
  } = useContext(ActivityContext);
  const { handleActivityBookingRQ, retryFailsFunc, initialLoad } =
    ActivitiesProcessingMethods(
      setIsFirstTimeBooker,
      setIsEditActionOnlyCancelled,
      setHasCancellationPolicy,
      setCancellationPolicyAlertsLength,
      setCancellationPolicyAlerts
    );

  useEffect(() => {
    setSelectedService(null);
    if (activitiesDetails) {
      const redirectedTo = activitiesDetails?.All?.find(
        (row) => row.id === Number(activityId)
      );

      if (redirectedTo) {
        setActivityIdExists(true);
        setSelectedService(redirectedTo);
        setShowBookingSection(true);
        if (location?.state?.isItineraryRedirect && !isFirstTimeBooker)
          setIsEditMode(true);
      } else {
        setActivityIdExists(false);
        setSelectedService(null);
        setShowBookingSection(false);
        setConfirmationScreenSelections(null);
        setIsEditMode(false);
        if (activityId) {
          redirectBetweenPages(ROUTE_VIEWS.NOT_FOUND);
        }
      }
    }
  }, [activityId, activitiesDetails?.All]);

  useEffect(() => {
    if (
      showBookingSection &&
      !location?.state?.isItineraryRedirect &&
      !activityId
    ) {
      redirectBetweenPages(
        `${ROUTE_VIEWS.ACTIVITY_BOOKING.replace(
          ":activityId",
          selectedService.id
        )}`
      );
    }
  }, [showBookingSection]);

  const servBoxes = dataSource?.map(
    (service) =>
      (!selectedService || selectedService.id !== service.id) && (
        <IonCol
          key={service.id}
          style={
            isDesktop()
              ? {
                minWidth: "260px",
                maxWidth: "260px",
                minHeight: "220px",
              }
              : {
                minWidth: "168px",
                maxWidth: "168px",
                width: "168px",
                minHeight: "200px",
              }
          }
        >
          <ServiceSummaryCard
            images={service.Image}
            title={service.Title}
            duration={service.Duration}
            price={service.Price}
            onClick={() => {
              setSelectedService(service);
              setShowAdditionalDetails(true);
            }}
            hasBooking={hasBooking(service.id, itineraryDetails)}
            newWrapperStyles={{ height: "100%" }}
          />
        </IonCol>
      )
  );

  return (
    <>
      <BLContentCardWrapper
        width="100%"
        background="white"
        dimensions={{ padding: ["b-s"] }}
      >
        <IonGrid>
          <IonRow style={{ gap: isDesktop() ? "16px" : "22px" }}>
            {isDesktop() ? (
              <>
                {showAdditionalDetails ? (
                  <AdditionalActivitiesDetails
                    dismissAlert={setShowAdditionalDetails}
                    images={selectedService?.Image}
                    header={selectedService?.Title}
                    price={selectedService?.Price}
                    duration={selectedService?.Duration}
                    longDescription={selectedService?.LongDescription}
                    initialLoad={initialLoad}
                    hasBooking={hasBooking(
                      selectedService?.id,
                      itineraryDetails
                    )}
                  />
                ) : showBookingSection ? (
                  <ActivityBookingFlow
                    handleActivityBookingRQ={handleActivityBookingRQ}
                    retryFailsFunc={retryFailsFunc}
                    isFirstTimeBooker={isFirstTimeBooker}
                    isEditActionOnlyCancelled={isEditActionOnlyCancelled}
                    hasCancellationPolicy={hasCancellationPolicy}
                    setHasCancellationPolicy={setHasCancellationPolicy}
                    cancellationPolicyAlertsLength={
                      cancellationPolicyAlertsLength
                    }
                    cancellationPolicyAlerts={cancellationPolicyAlerts}
                    setCancellationPolicyAlerts={setCancellationPolicyAlerts}
                  />
                ) : (
                  servBoxes
                )}
              </>
            ) : (
              <>
                <BLBottomPopupWrapper
                  showAlert={activityIdExists}
                  modal={modal}
                  modalRef={modalRef}
                  setModalRef={setModalRef}
                >
                  <ActivityBookingFlow
                    handleActivityBookingRQ={handleActivityBookingRQ}
                    retryFailsFunc={retryFailsFunc}
                    isFirstTimeBooker={isFirstTimeBooker}
                    isEditActionOnlyCancelled={isEditActionOnlyCancelled}
                    hasCancellationPolicy={hasCancellationPolicy}
                    setHasCancellationPolicy={setHasCancellationPolicy}
                    cancellationPolicyAlertsLength={
                      cancellationPolicyAlertsLength
                    }
                    cancellationPolicyAlerts={cancellationPolicyAlerts}
                    setCancellationPolicyAlerts={setCancellationPolicyAlerts}
                  />
                </BLBottomPopupWrapper>

                {servBoxes}

                <BLAdditionalActivityDetailsPopupBox
                  showAlert={showAdditionalDetails}
                  dismissAlert={setShowAdditionalDetails}
                  images={selectedService?.Image}
                  header={selectedService?.Title}
                  price={selectedService?.Price}
                  duration={selectedService?.Duration}
                  longDescription={selectedService?.LongDescription}
                  initialLoad={initialLoad}
                  hasBooking={hasBooking(selectedService?.id, itineraryDetails)}
                />
              </>
            )}
          </IonRow>
        </IonGrid>
      </BLContentCardWrapper>
    </>
  );
};

export default ActivityServices;
