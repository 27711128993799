import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDataContext } from "../../../../../context/DataContext";
import { NavigatorContext } from "../../../../../context/NavigatorContext";
import { SpaContext } from "../../../../../context/SpaContext";
import { hasBooking } from "../../../../../helpingFunctions/bookingHelp";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { ROUTE_VIEWS } from "../../../../../routes/routeViews";
import ServiceSummaryCard from "../../../../../shared/Ionic/ServicesComponents/ServiceCards/ServiceSummaryCard";
import BLBottomPopupWrapper from "../../../../../shared/Ionic/Wrappers/BLBottomPopupWrapper";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import AdditionalSpaDetails from "../../components/Cards/AdditionalSpaDetails";
import BLAdditionalSpaDetailsPopupBox from "../../components/Cards/BLAdditionalSpaDetailsPopupBox";
import ContraindicationsPopup from "../../components/Cards/ContraindicationsPopup";
import SpaProcessingMethods from "../../components/Logic/SpaProcessingMethods";
import SpaBookingFlow from "../../components/SpaBookingFlow";
import SpaServicesLogic from "./SpaServicesLogic";
import { useLocation, useParams } from "react-router-dom";
import { UNCATEGORIZED } from "../../../../../constants/constants";

const SpaServices = ({ formatedSpaServices }) => {
  const modal = useRef(null);
  const location = useLocation();
  const { spaId } = useParams();
  const [modalRef, setModalRef] = useState();
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const [isFirstTimeBooker, setIsFirstTimeBooker] = useState(false);
  const [isEditActionOnlyCancelled, setIsEditActionOnlyCancelled] =
    useState(false);
  const [hasCancellationPolicy, setHasCancellationPolicy] = useState(false);
  const [cancellationPolicyAlertsLength, setCancellationPolicyAlertsLength] =
    useState(0);
  const [cancellationPolicyAlerts, setCancellationPolicyAlerts] = useState([]);
  const [spaIdExists, setSpaIdExists] = useState(false);
  const { servicesToDisplay, selectedService, setSelectedService } =
    SpaServicesLogic(formatedSpaServices, setSpaIdExists, isFirstTimeBooker);

  const { itineraryDetails } = useDataContext();

  const {
    showAdditionalDetails,
    setShowAdditionalDetails,
    showBookingSection,
  } = useContext(SpaContext);

  const { handleSpaBookingRQ, retryFailsFunc, initialLoad } =
    SpaProcessingMethods(
      selectedService,
      setIsFirstTimeBooker,
      setIsEditActionOnlyCancelled,
      setHasCancellationPolicy,
      setCancellationPolicyAlertsLength,
      setCancellationPolicyAlerts
    );

  useEffect(() => {
    if (showBookingSection && !location?.state?.isItineraryRedirect && !spaId) {
      redirectBetweenPages(
        `${ROUTE_VIEWS.SPA_BOOKING.replace(
          ":categoryId",
          selectedService?.CategoryId
            ? selectedService.CategoryId
            : UNCATEGORIZED
        ).replace(":spaId", selectedService?.id)}`
      );
    }
  }, [showBookingSection]);

  const categoryServBoxes = servicesToDisplay?.items.map(
    (service) =>
      (!selectedService || selectedService?.id !== service.id) && (
        <IonCol
          key={service.id}
          style={
            isDesktop()
              ? {
                minWidth: "260px",
                maxWidth: "260px",
                minHeight: "220px",
              }
              : {
                minWidth: "168px",
                maxWidth: "168px",
                width: "168px",
                minHeight: "200px",
              }
          }
        >
          <ServiceSummaryCard
            images={service.Image}
            title={service.Title}
            duration={service.Duration}
            price={service.Price}
            onClick={() => {
              setSelectedService(service);
              setShowAdditionalDetails(true);
            }}
            hasBooking={hasBooking(service.id, itineraryDetails)}
            newWrapperStyles={{ height: "100%" }}
          />
        </IonCol>
      )
  );

  return (
    <>
      <BLContentCardWrapper
        width="100%"
        background="white"
        dimensions={{ margin: ["b-s"] }}
      >
        <IonGrid>
          <IonRow style={{ gap: isDesktop() ? "16px" : "22px" }}>
            {isDesktop() ? (
              <>
                {showAdditionalDetails ? (
                  <AdditionalSpaDetails
                    dismissAlert={setShowAdditionalDetails}
                    images={selectedService?.Image}
                    header={selectedService?.Title}
                    price={selectedService?.Price}
                    duration={selectedService?.Duration}
                    longDescription={selectedService?.LongDescription}
                    contraindications={selectedService?.Contraindications}
                    initialLoad={initialLoad}
                    hasBooking={hasBooking(
                      selectedService?.id,
                      itineraryDetails
                    )}
                    setSelectedService={setSelectedService}
                  />
                ) : showBookingSection ? (
                  <SpaBookingFlow
                    serviceDetails={selectedService}
                    handleSpaBookingRQ={handleSpaBookingRQ}
                    retryFailsFunc={retryFailsFunc}
                    isFirstTimeBooker={isFirstTimeBooker}
                    isEditActionOnlyCancelled={isEditActionOnlyCancelled}
                    hasCancellationPolicy={hasCancellationPolicy}
                    setHasCancellationPolicy={setHasCancellationPolicy}
                    cancellationPolicyAlertsLength={
                      cancellationPolicyAlertsLength
                    }
                    cancellationPolicyAlerts={cancellationPolicyAlerts}
                    setCancellationPolicyAlerts={setCancellationPolicyAlerts}
                  />
                ) : (
                  categoryServBoxes
                )}
                <ContraindicationsPopup serviceDetails={selectedService} />
              </>
            ) : (
              <>
                <BLBottomPopupWrapper
                  showAlert={spaIdExists}
                  modal={modal}
                  modalRef={modalRef}
                  setModalRef={setModalRef}
                >
                  <SpaBookingFlow
                    serviceDetails={selectedService}
                    handleSpaBookingRQ={handleSpaBookingRQ}
                    retryFailsFunc={retryFailsFunc}
                    isFirstTimeBooker={isFirstTimeBooker}
                    isEditActionOnlyCancelled={isEditActionOnlyCancelled}
                    hasCancellationPolicy={hasCancellationPolicy}
                    setHasCancellationPolicy={setHasCancellationPolicy}
                    cancellationPolicyAlertsLength={
                      cancellationPolicyAlertsLength
                    }
                    cancellationPolicyAlerts={cancellationPolicyAlerts}
                    setCancellationPolicyAlerts={setCancellationPolicyAlerts}
                  />
                </BLBottomPopupWrapper>

                {categoryServBoxes}

                <BLAdditionalSpaDetailsPopupBox
                  showAlert={showAdditionalDetails}
                  dismissAlert={setShowAdditionalDetails}
                  images={selectedService?.Image}
                  header={selectedService?.Title}
                  price={selectedService?.Price}
                  duration={selectedService?.Duration}
                  longDescription={selectedService?.LongDescription}
                  initialLoad={initialLoad}
                  hasBooking={hasBooking(selectedService?.id, itineraryDetails)}
                  setSelectedService={setSelectedService}
                />
                <ContraindicationsPopup serviceDetails={selectedService} />
              </>
            )}
          </IonRow>
        </IonGrid>
      </BLContentCardWrapper>
    </>
  );
};

export default SpaServices;
