import { useContext } from "react";
import { INITIAL_DATE } from "../../../../../../../constants/constants";
import { ActivityContext } from "../../../../../../../context/ActivityContext";
import { getDayPeriod } from "../../../../../../../helpingFunctions/dateTimeUtilities";

const TimeRowSeparateSelectionLogic = () => {
  const {
    activityAvailability,
    activitySelections,
    setActivitySelections,
    setActivityAvailability,
    handleAvailabilityOnSelection,
    activityViewType,
    selectedService,
  } = useContext(ActivityContext);

  const handleSeparateSelection = (obj, selIndex, selTimeIndex, dayTime) => {
    const activitySelectionsCopy = JSON.parse(
      JSON.stringify(activitySelections)
    );
    const activityAvailabilityCopy = JSON.parse(
      JSON.stringify(activityAvailability)
    );

    // If the same time is clicked - deselect
    if (activitySelectionsCopy[selIndex].on[selTimeIndex].time === obj.time) {
      if (activitySelectionsCopy[selIndex].on[selTimeIndex].action !== "add")
        return;
      // Adjust availability
      const day = activitySelectionsCopy[selIndex].on[selTimeIndex].date;
      const time = activitySelectionsCopy[selIndex].on[selTimeIndex].time;
      const quantity =
        activitySelectionsCopy[selIndex].on[selTimeIndex].quantity;

      handleAvailabilityOnSelection(
        activityAvailabilityCopy,
        day,
        dayTime,
        time,
        "increment",
        selectedService?.id,
        quantity
      );

      // Update time
      activitySelectionsCopy[selIndex].on[selTimeIndex].time = "";

      if (activitySelectionsCopy[selIndex].on[selTimeIndex].action !== "add") {
        activitySelectionsCopy[selIndex].on[selTimeIndex].action = "remove";
      }
    } else {
      const selectedTime = obj.time;

      const when = getDayPeriod(
        `${INITIAL_DATE}T${selectedTime.substring(0, 5)}`
      );
      const day = activitySelectionsCopy[selIndex].on[selTimeIndex].date;
      const quantity =
        activitySelectionsCopy[selIndex].on[selTimeIndex].quantity;

      const selectedActivityAvail = activityAvailabilityCopy[activityViewType][
        day
      ].AvailabilityDetails.find(
        (activityAvail) => activityAvail.FacilityGUID === selectedService?.id
      );
      const availIndexToIncrement = when
        ? selectedActivityAvail.Times[when].findIndex(
          (avail) => avail.time === selectedTime
        )
        : -1;

      if (availIndexToIncrement > -1) {
        handleAvailabilityOnSelection(
          activityAvailabilityCopy,
          day,
          when,
          selectedTime,
          "increment",
          selectedService?.id,
          quantity
        );
      }

      handleAvailabilityOnSelection(
        activityAvailabilityCopy,
        day,
        dayTime,
        obj.time,
        "decrement",
        selectedService?.id,
        quantity
      );

      activitySelectionsCopy[selIndex].on[selTimeIndex].time = obj.time;

      if (
        (["edit", "remove"].includes(activitySelectionsCopy[selIndex].action) ||
          ["edit", "remove"].includes(
            activitySelectionsCopy[selIndex].on[selTimeIndex].action
          )) &&
        activitySelectionsCopy[selIndex].on[selTimeIndex].date ===
          activitySelectionsCopy[selIndex].on[selTimeIndex].bookedDate &&
        activitySelectionsCopy[selIndex].on[selTimeIndex].time ===
          activitySelectionsCopy[selIndex].on[selTimeIndex].bookedTime &&
        activitySelectionsCopy[selIndex].on[selTimeIndex].quantity ===
          activitySelectionsCopy[selIndex].on[selTimeIndex].bookedQuantity
      ) {
        activitySelectionsCopy[selIndex].action = "none";
        activitySelectionsCopy[selIndex].on[selTimeIndex].action = "none";
      } else if (
        activitySelectionsCopy[selIndex].on[selTimeIndex].action !== "add" &&
        activitySelectionsCopy[selIndex].action !== "add"
      ) {
        activitySelectionsCopy[selIndex].on[selTimeIndex].action = "edit";
      }
    }

    setActivityAvailability(activityAvailabilityCopy);
    setActivitySelections(activitySelectionsCopy);
  };

  const isTimeSelected = (obj, selIndex, selTimeIndex) => {
    return activitySelections[selIndex].on[selTimeIndex].time === obj.time;
  };

  const getDayQtySelected = (selIndex, selTimeIndex) => {
    return activitySelections[selIndex].on[selTimeIndex].quantity;
  };

  return {
    handleSeparateSelection,
    isTimeSelected,
    getDayQtySelected,
  };
};

export default TimeRowSeparateSelectionLogic;
