import React, { useEffect } from "react";
import { IonModal } from "@ionic/react";

const BLBottomPopupWrapper = ({ showAlert, modal, modalRef, setModalRef, children }) => {

  useEffect(function callback() {
    if(modal?.current) {
      setModalRef(modal.current);
    }
    return function () {
      modalRef?.dismiss();
    };
  }, [modal, modalRef]);

  return (
    <IonModal
      ref={modal}
      className={`bl-inline-modal bl-inline-modal-85percH ion-disable-focus-trap`}
      isOpen={showAlert}
      backdropDismiss={false}
      showBackdrop={true}
    >
      {children}
    </IonModal>
  );
};

export default BLBottomPopupWrapper;
