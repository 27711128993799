import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import BLInput from "../../../../shared/Ionic/Inputs/BLInput";
import BLLabel from "../../../../shared/Ionic/Inputs/BLLabel";
import {
  CRSNumberAttr,
  hotelAttr,
  surnameAttr,
} from "../../LoginPage/constants/constants";
import { isDesktop } from "../../../../helpingFunctions/utilities";
import BLSelect from "../../../../shared/Ionic/Inputs/BLSelect";

const LinkReservationsPageForm = ({
  formData,
  setFormData,
  hotelsDropdownOpts,
  handleRequest,
  isFormButtonDisabled
}) => {

  const prepareHotelSelectionBlock = () => {
    return (
      <IonGrid>
        <IonRow>
          <BLLabel
            label="Hotel"
            color="accent-pale-grey"
            labelDimensions={{
              margin: ["b-xs", "t-s"],
            }}
            labelClasses="bl-text-regular"
          />
        </IonRow>
        <IonRow>
          <IonCol>
            <BLSelect
              dropdownList={hotelsDropdownOpts}
              name={hotelAttr}
              value={formData[hotelAttr]}
              placeholder="Select the hotel on your reservation"
              boxHeightClass={isDesktop() ? "height-md" : "height-sm"}
              onChange={(event) => {
                setFormData((prev) => {
                  return { ...prev, [hotelAttr]: event.target.value };
                });
              }}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  const prepareSurnameInputBlock = () => {
    return (
      <IonGrid>
        <IonRow>
          <BLLabel
            label="Surname"
            color="accent-pale-grey"
            labelDimensions={{
              margin: ["b-xs", "t-s"],
            }}
            labelClasses="bl-text-regular"
          />
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <BLInput
              placeholder="Enter your last name"
              name={surnameAttr}
              type="text"
              value={formData[surnameAttr]}
              onChange={(e) =>
                setFormData((prev) => {
                  return {
                    ...prev,
                    [surnameAttr]:
                      e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1),
                  };
                })
              }
              boxHeightClass={isDesktop() ? "height-md" : "height-sm"}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  const prepareHolidayRefInputBlock = () => {
    return (
      <IonGrid>
        <IonRow>
          <BLLabel
            label="Holiday reference number"
            color="accent-pale-grey"
            labelDimensions={{
              margin:["b-xs", "t-s"],
            }}
            labelClasses="bl-text-regular"
          />
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <BLInput
              placeholder="Number begins with a 1" 
              name={CRSNumberAttr}
              type="text"
              value={formData[CRSNumberAttr]}
              onChange={(e) =>
                setFormData((prev) => {
                  return { ...prev, [CRSNumberAttr]: e.target.value };
                })
              }
              boxHeightClass="height-sm"
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  return (
    <form
      onKeyDown={(e) => {
        if (e.key === "Enter" && !isFormButtonDisabled) {
          handleRequest(formData);
        }
      }}
    >
      <IonRow
        className={`ion-justify-content-center`}
        style={{ minWidth: "100px" }}
      >
        <IonCol>{prepareHotelSelectionBlock()}</IonCol>
      </IonRow>
      <IonRow className={`ion-justify-content-center`}>
        <IonCol>{prepareSurnameInputBlock()}</IonCol>
      </IonRow>
      <IonRow className={`ion-justify-content-center`}>
        <IonCol>{prepareHolidayRefInputBlock()}</IonCol>
      </IonRow>
    </form>
  );
};

export default LinkReservationsPageForm;
