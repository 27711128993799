import { useContext } from "react";
import { useDataContext } from "../../../../../../context/DataContext";
import { SpaContext } from "../../../../../../context/SpaContext";

const GuestsRowLogic = (serviceDetails) => {
  const { reservationDetails } = useDataContext();
  const {
    spaSelections,
    setSpaSelections,
    handleProviderCode,
    spaAvailability,
    setSpaAvailability,
    isEditMode
  } = useContext(SpaContext);
  const { setOption } = useContext(SpaContext);

  const removeSelection = (indexToRemove) => {
    const spaSelectionsCopy = revertSelections();
    if (indexToRemove !== 0 || isEditMode) {
      const action = spaSelectionsCopy[indexToRemove].action;
      const spaAvailabilityCopy = JSON.parse(JSON.stringify(spaAvailability));
      if (action === "add") {
        setSpaSelections(
          spaSelectionsCopy.filter((row, index) => index !== indexToRemove)
        );
        return;
      } else if (action === "none") {
        spaSelectionsCopy[indexToRemove].action = "remove";
        spaSelectionsCopy[indexToRemove].isFullName = true;
        spaSelectionsCopy[indexToRemove].on.forEach((row) => {
          const providerCode = row.providerCode;
          if (providerCode !== "") {
            const selDate = row.date;
            const time = row.time;
            const dayTime = time.substring(0, 2) >= "12" ? "PM" : "AM";
            handleProviderCode(
              spaAvailabilityCopy,
              selDate,
              dayTime,
              time,
              "increment",
              providerCode
            );
          }
          row.action = "remove";
        });
        setSpaSelections(spaSelectionsCopy);
      } else if (action === "edit") {
        spaSelectionsCopy[indexToRemove].action = "none";
        spaSelectionsCopy[indexToRemove].fName =
          spaSelectionsCopy[indexToRemove].bookedFName;
        spaSelectionsCopy[indexToRemove].lName =
          spaSelectionsCopy[indexToRemove].bookedLName;
        spaSelectionsCopy[
          indexToRemove
        ].guestName = `${spaSelectionsCopy[indexToRemove].bookedFName} ${spaSelectionsCopy[indexToRemove].bookedLName}`;
        spaSelectionsCopy[indexToRemove].isEditingName = false;
        spaSelectionsCopy[indexToRemove].on.forEach((row) => {
          row.action = "none";
          row.date = row.bookedDate;
          row.time = row.bookedTime;
        });
      }
      setSpaAvailability(spaAvailabilityCopy);
      setSpaSelections(spaSelectionsCopy);
    } else {
      if (
        indexToRemove === 0 &&
        spaSelectionsCopy[0].action === "add" &&
        (spaSelectionsCopy[0].fName !== reservationDetails.FirstName ||
          spaSelectionsCopy[0].lName !== reservationDetails.LastName)
      ) {
        spaSelectionsCopy[0].fName = reservationDetails.FirstName;
        spaSelectionsCopy[0].lName = reservationDetails.LastName;
        spaSelectionsCopy[0].guestName = `${reservationDetails.FirstName} ${reservationDetails.LastName}`;
        spaSelectionsCopy[0].isEditingName = false;
        spaSelectionsCopy[0].isFullName = true;
        setOption(null);
      } else if (indexToRemove === 0 && spaSelectionsCopy[0].action === "add") {
        isFullName(indexToRemove);
      }

      setSpaSelections(spaSelectionsCopy);
    }
  };

  const addSelection = () => {
    const spaSelectionsCopy = revertSelections();
    const spaObj = {
      fName: "",
      lName: "",
      isFullName: false,
      bookedFName: "",
      bookedLName: "",
      guestName: "",
      spaId: serviceDetails.id,
      on: [
        {
          date: "",
          time: "",
          bookedTime: "",
          bookedDate: "",
          action: "add",
          providerCode: "",
          bookedProviderCode: "",
        },
      ],
      action: "add",
      isEdited: false,
      isEditingName: false,
    };

    spaSelectionsCopy.push(spaObj);
    setSpaSelections(spaSelectionsCopy);
  };

  const handleForm = (indexToUpdate, fieldName, val) => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    spaSelectionsCopy[indexToUpdate][fieldName] = val.target.value;
    spaSelectionsCopy[
      indexToUpdate
    ].guestName = `${spaSelectionsCopy[indexToUpdate].fName} ${spaSelectionsCopy[indexToUpdate].lName}`;
    if (spaSelectionsCopy[indexToUpdate].action !== "add") {
      spaSelectionsCopy[indexToUpdate].action = "edit";
    }
    setSpaSelections(spaSelectionsCopy);
  };

  const revertSelections = () => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));

    const spaAvailabilityCopy = JSON.parse(JSON.stringify(spaAvailability));
    spaSelectionsCopy.forEach((row) => {
      if (row.action === "add") {
        setOption(null);
        row.on.forEach((day) => {
          const providerCode = day.providerCode;
          if (providerCode !== "") {
            const selDate = day.date;
            const time = day.time;
            const dayTime = time.substring(0, 2) >= "12" ? "PM" : "AM";
            handleProviderCode(
              spaAvailabilityCopy,
              selDate,
              dayTime,
              time,
              "increment",
              providerCode
            );
          }

          day.date = "";
          day.time = "";
          day.providerCode = "";
        });
        setSpaAvailability(spaAvailabilityCopy);
      }
    });

    return spaSelectionsCopy;
  };

  const isFullName = (index) => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    const isFullName =
      spaSelectionsCopy[index].fName.trim().length > 0 &&
      spaSelectionsCopy[index].lName.trim().length > 0;

    spaSelectionsCopy[index].isFullName = isFullName;

    if (isFullName) {
      spaSelectionsCopy[index].isEditingName = false;
    }

    setSpaSelections(spaSelectionsCopy);
  };

  const isNameRepeated = () => {
    const map = spaSelections.reduce(
      (acc, e) =>
        acc.set(
          `${e.fName} ${e.lName}`,
          (acc.get(`${e.fName} ${e.lName}`) || 0) + 1
        ),
      new Map()
    );

    return [...map.values()].filter((y) => y > 1).length === 1;
  };

  const edit = (index) => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    spaSelectionsCopy[index].isEditingName = true;

    setSpaSelections(spaSelectionsCopy);
  };

  return {
    addSelection,
    removeSelection,
    handleForm,
    isFullName,
    edit,
    isNameRepeated
  };
};

export default GuestsRowLogic;
