import React from "react";

import { getDayOfWeek } from "../../../../../../helpingFunctions/utilities";

const DateDisplay = ({ date }) => {
  return (
    <p
      style={{
        color: "#3b3b3b",
        fontSize: "30px",
        fontWeight: "bold",
        textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "20px",
      }}
    >
      {getDayOfWeek(date)} - {date.replace(/-/g, ".")}
    </p>
  );
};

export default DateDisplay;
