import React from "react";
import { IonCard } from "@ionic/react";
import CreateDimensions from "../../../helpingFunctions/DimensionsFactory";
import styles from "./BLContentCardWrapper.module.scss";

const BLContentCardWrapper = ({
  children,
  background = "light",
  dimensions = {},
  noMargin,
  center,
  newStyles,
  color,
  newClasses,
}) => {
  const dimensionStyles = CreateDimensions(dimensions);

  return (
    <IonCard
      className={`${dimensionStyles.dimensions} ${
        styles["bl-content-wrapper"]
      } ${noMargin ? "ion-no-margin" : ""} ${
        center ? "ion-justify-content-center bl-flex ion-align-items-center" : ""
      } ${newClasses}`}
      style={{
        background: background.startsWith("#")
          ? background
          : `var(--ion-color-${background})`,
        boxShadow: "none",
        ...newStyles,
      }}
      color={color}
    >
      {children}
    </IonCard>
  );
};

export default BLContentCardWrapper;
