import React, { useContext } from "react";
import { IonGrid, IonCol, IonRow } from "@ionic/react";

import {
  ACTION_TYPES,
} from "../../../../../constants/constants";
import { DiningBookingContext } from "../../../../../context/DiningBookingContext";
import BLItem from "../../../../../shared/Ionic/Cards/BLItem/BLItem";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import RestaurantExpandedDesktop from "./RestaurantExpandedDesktop";

import styles from "./Restaurants.module.scss";
import { useTheme } from "../../../../../hooks/useTheme";

const RestaurantCardDesktop = ({
  currentRestaurants,
  clickedRestaurantId,
  setClickedRestaurantId,
  getRestTileSize,
  handleResSelection,
  isInThePast
}) => {
  const { typography } = useTheme();
  const {
    selectedDateSelections: { restaurantId, action, isSkipped },
    getUnavailRestBannerText,
  } = useContext(DiningBookingContext);
  const isRemove = action === ACTION_TYPES.REMOVE;

  let selectedRestaurant = null;
  const restOfRestaurants = [];

  const isOneRestaurant = currentRestaurants.length === 1;
  const AllRestaurants = currentRestaurants.map(
    ({ details: restaurant, times }) => {
      const shouldOpen =
        clickedRestaurantId === restaurant.id || isOneRestaurant;
      const isSelected = restaurantId === restaurant.id || isOneRestaurant;
      const isUnavailable = times.length === 0;
      let ImageContent = null;
      let MainContent = null;

      if (shouldOpen) {
        ImageContent = (
          <>
            <BLImageCarousel content={restaurant.Image} autoplay={false} />
          </>
        );
        MainContent = (
          <RestaurantExpandedDesktop
            restaurant={restaurant}
            isSelected={isSelected}
            isUnavailable={isUnavailable}
            isOneRestaurant={isOneRestaurant}
            isInThePast={isInThePast}
            handleResSelection={handleResSelection}
          />
        );
      } else {
        ImageContent = (
          <img
            src={restaurant.Image[0]?.formats?.small?.url || restaurant.Image[0]?.url}
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            alt="restaurant"
            width="100%"
            height="100%"
          />
        );
        MainContent = (
          <BLParagraph
            color="primary"
            txt={restaurant.Title}
            textVariant={typography.BODY_TEXT}
            // newClasses={`small crimson-pro bold`}
            //Unclear typography
          />
        );
      }

      const prepRestaurant = (
        <BLItem
          onClick={() => setClickedRestaurantId(restaurant.id)}
          imageContent={ImageContent}
          mainContent={MainContent}
          isSelected={{
            status: isSelected && !isRemove && !isSkipped,
            showBorder: isSelected && !isRemove && !isSkipped,
            position: "right",
          }}
          newClasses={`restaurant-card white`}
          isUnavailable={isUnavailable}
          unavailableBannerText={isUnavailable && getUnavailRestBannerText()}
          newStylesMainContentRow={{ alignItems: "center" }}
        />
      );

      if (shouldOpen) {
        selectedRestaurant = (
          <IonCol
            key={restaurant.id}
            size={getRestTileSize(restaurant)}
            className={shouldOpen ? styles.restaurant_selected_desktop_tile_size : styles.restaurant_unselected_desktop_tile_size}
          >
            {prepRestaurant}
          </IonCol>
        );
      } else {
        restOfRestaurants.push(
          <IonCol key={restaurant.id} size={getRestTileSize(restaurant)}>
            {prepRestaurant}
          </IonCol>
        );
      }

      return (
        <IonCol
          key={restaurant.id}
          size={getRestTileSize(restaurant)}
          style={clickedRestaurantId ? {} : { display: "flex" }}
          className={shouldOpen ? styles.restaurant_selected_desktop_tile_size : styles.restaurant_unselected_desktop_tile_size}
        >
          {prepRestaurant}
        </IonCol>
      );
    }
  );

  return (
    <>
      {AllRestaurants.length > 2 && clickedRestaurantId ? (
        <>
          {selectedRestaurant}
          <IonCol size="4" style={{ height: "max-content" }}>
            <IonGrid>
              <IonRow
                className="bl-flex"
                style={{ flexDirection: "column", gap: "8px", minWidth: "130px" }}
              >
                {restOfRestaurants}
              </IonRow>
            </IonGrid>
          </IonCol>
        </>
      ) : (
        AllRestaurants
      )}
    </>
  );
};

export default RestaurantCardDesktop;
