import React from "react";

import { Link } from "@mui/material";
import { classFormatter } from "../../BLIonicHelpers";
import { TEXT_CSS_CLASS_IDENTIFIER } from "../../BLIonicConstants";

const BLLink = ({
  txt,
  color = "text-grey",
  newClasses = "body",
  smallLink,
  href,
  onClick = () => {},
}) => {
  const smallLinkClasses = "xxx-small semibold";

  const textSpecificClasses = classFormatter(
    smallLink ? smallLinkClasses : newClasses,
    TEXT_CSS_CLASS_IDENTIFIER
  );

  return (
    <Link
      className={`bl-text-pointer ${textSpecificClasses}`}
      href={href}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
      sx={{
        color: `var(--ion-color-${color}) !important`,
        textDecorationColor: `var(--ion-color-${color}) !important`,
      }}
    >
      {txt}
    </Link>
  );
};

export default BLLink;
