import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import UserFilters from "./components/UserFilters/UserFilters";
import FilterLogic from "./FilterLogic";
import AdminFilters from "./components/AdminFilters/AdminFilters";

import { WhatsOnDataContext } from "../../../../../context/WhatsOnDataContext";

import styles from "./Filter.module.scss";

const Filter = (isTimeOfDayFilterShown) => {
  const { selectedFilters } = useContext(WhatsOnDataContext);
  const { pathname } = useLocation();
  const isAdminPage = pathname.includes("team");
  const { manageFiltration } = FilterLogic();
  
  let PageFilters = null;

  if (isAdminPage) {
    PageFilters = <AdminFilters />;
  } else {
    PageFilters = (
      <UserFilters
        isTimeOfDayFilterShown={isTimeOfDayFilterShown.isTimeOfDayFilterShown}
        selectedFilters={selectedFilters}
        manageFiltration={manageFiltration}
      />
    );
  }

  return <div className={styles.Filter}>{PageFilters}</div>;
};

export default Filter;
