import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import { xSign } from "../../../../../assets/SVGIcons";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import { useTheme } from "../../../../../hooks/useTheme";

const AdditionalRestaurantDetails = ({
  dismissAlert,
  images,
  header,
  longDescription,
}) => {
  const { typography } = useTheme();
  return (
    <IonCol>
      <BLContentCardWrapper dimensions={{ padding: ["s"] }}>
        <IonGrid className={"bl-flex ion-justify-content-center"}>
          <IonRow
            className={`ion-text-center bl-flex ion-align-items-center ion-justify-content-center`}
            style={{ height: "50px" }}
          >
            <IonCol style={{ height: "500px", width: "350px" }}>
              <BLImageCarousel content={images} />
            </IonCol>
          </IonRow>
          <IonRow style={{ overflow: "auto", height: "500px" }}>
            <IonCol>
              <IonGrid className="bl-margin-xs">
                <IonRow className="ion-margin-bottom bl-flex ion-align-items-start">
                  <IonCol>
                    <BLParagraph
                      txt={header}
                      color="black"
                      textVariant={typography.h2}
                      // newClasses={`xx-large bold crimson-pro`}
                      //TODO: Unclear typography
                      dimensions={{
                        margin: ["l-lg"],
                        padding: ["t-md", "b-md"],
                      }}
                    />
                  </IonCol>
                  <IonCol size="auto">
                    <div style={{ marginLeft: "8px", display: "flex" }}>
                      <BLIcon
                        customIconSource={xSign(
                          "24px",
                          "var(--ion-color-secondary)",
                          "var(--ion-color-white)"
                        )}
                        onClick={() => dismissAlert()}
                      />
                    </div>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <BLParagraph
                      txt="Description"
                      stylingType="sd"
                      color="text-grey"
                      textVariant={typography.BODY_TEXT}
                      // newClasses={`x-small bold`}
                      //TODO: Unclear typography
                      dimensions={{
                        margin: ["l-lg"],
                        padding: ["t-md", "b-md"],
                      }}
                    />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <BLParagraph
                      txt={longDescription}
                      color="text-grey"
                      textVariant={typography.BODY_TEXT}
                      markDown
                      dimensions={{
                        margin: ["l-lg"],
                        padding: ["t-md", "b-md"],
                      }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </BLContentCardWrapper>
    </IonCol>
  );
};

export default AdditionalRestaurantDetails;
