import { useContext, useState } from "react";

import { DiningPartyContext } from "../../../../../context/DiningPartyContext";
import { GLOBAL_DINING_PARTY_ATTRS, INIT_RESPONSE_DATA } from "../../diningPartyConstants";

const ParticipantDisplayLogic = () => {
  const {
    addMoreHandler,
    deleteGroupParticipant,
    removeDiningParty,
    setResponse,
    diningPartySelections,
    setDiningPartySelections,
    diningPartyDetails
  } = useContext(DiningPartyContext);
  const [diningGrRemoveMsg, setDiningGrRemoveMsg] = useState(null);

  const prompPopup = (id) => {
    const diningPartyParticipants =
      diningPartySelections[GLOBAL_DINING_PARTY_ATTRS.PARTICIPANTS];

    const otherParticipants = diningPartyParticipants.filter(
      (participant) => participant.id !== id
    );

    let alertMsg =
      "Are you sure to remove this reservation from the dining party?";
    if (otherParticipants.length === 0 && diningPartyDetails.id) {
      alertMsg =
        "By deleting this reservation you will delete the dining party. Are you sure to proceed?";
    }

    setResponse(INIT_RESPONSE_DATA);
    setDiningGrRemoveMsg(alertMsg);
  };

  const removeValidatedReservation = async (id) => {
    setDiningGrRemoveMsg(null);
    try {
      const diningPartyParticipants =
        diningPartySelections[GLOBAL_DINING_PARTY_ATTRS.PARTICIPANTS];
      const resToBeRemoved = diningPartyParticipants.find(
        (row) => row.id === id
      );

      const otherParticipants = diningPartyParticipants.filter(
        (participant) => participant.id !== id
      );

      if (diningPartySelections.id && resToBeRemoved.IsInGroup) {
        if (otherParticipants.length === 0) {
          await removeDiningParty();
        } else {
          await deleteGroupParticipant(id);
        }
      }

      if (otherParticipants.length === 0 && !diningPartyDetails.id) {
        addMoreHandler(false);
      }

      setDiningPartySelections((prevState) => {
        return {
          ...prevState,
          [GLOBAL_DINING_PARTY_ATTRS.PARTICIPANTS]: otherParticipants,
        };
      });
    } catch (error) {
      console.error(
        `${new Date()} Error in removeValidatedReservation func in ParticipantDisplayLogic.js ${
          error.message
        }`
      );
    }
  };

  return {
    removeValidatedReservation,
    prompPopup,
    diningGrRemoveMsg,
    setDiningGrRemoveMsg,
  };
};

export default ParticipantDisplayLogic;
