import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { addCircle, closeCircle, pencil } from "ionicons/icons";
import React, { Fragment, useContext } from "react";
import { useDataContext } from "../../../../../../../context/DataContext";
import { TreatsContext } from "../../../../../../../context/TreatsContext";
import BLIcon from "../../../../../../../shared/Ionic/BLIcon";
import BLInformationBox from "../../../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import BLButton from "../../../../../../../shared/Ionic/Buttons/BLButton";
import BLSelect from "../../../../../../../shared/Ionic/Inputs/BLSelect";
import BLDateTimeFormatParagraph from "../../../../../../../shared/Ionic/Paragraph/BLDateTimeFormatParagraph";
import BLParagraph from "../../../../../../../shared/Ionic/Paragraph/BLParagraph";
import DaysRowSelectionLogic from "./DaysRowSelectionLogic";
import { isDesktop } from "../../../../../../../helpingFunctions/utilities";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../../../../../../../constants/constants";
 
const DaysRowSelection = () => {
  const {
    treatsSelections,
    setTreatsSelections,
    treatsDaysStatuses,
    hasInRoomBookWithIn48hr,
  } = useContext(TreatsContext);
  const {
    reservationDetails,
    dynamicTexts,
  } = useDataContext();
 
  const {
    handleDaySelection,
    addSeparateDays,
    removeSeparateSelection,
    updateStatus,
  } = DaysRowSelectionLogic();
 
  const getFieldType = (sel, selIndex) => {
    const showXSign = sel.isEdited && sel.action !== "remove";
 
    let dropdownListPlusCurrentElement = treatsDaysStatuses.filter(
      (row) => !row.hasSelection
    );
    const selectedDate = treatsDaysStatuses.find(
      (row) => row.id === treatsSelections[selIndex].date
    );
    if (selectedDate) dropdownListPlusCurrentElement.push(selectedDate);
 
    const sortedDropdownListPlusCurrentElement =
      dropdownListPlusCurrentElement.sort((a, b) => a.id.localeCompare(b.id));
 
    const isNotSelecting = !sel.isEdited && sel.date !== "";

    const dateSelRow = (
      <IonCol
        key={`${sel.date}`}
        class="ion-justify-content-start"
        style={{
          width: isDesktop() ? "310px" : "100%",
          maxWidth: isDesktop() ? "310px" : "290px",
        }}
      >
        <IonGrid>
          <IonRow
            className={
              "bl-flex ion-align-items-center ion-justify-content-between"
            }
            style={{ position: "relative" }}
          >
            <IonCol
              className={`bl-flex ion-align-items-center`}
              style={{
                background: isNotSelecting && "white",
                height: isNotSelecting && "48px",
                borderRadius: isDesktop() && "5px",
                marginRight: "16px",
              }}
            >
              {isNotSelecting ? (
                <BLDateTimeFormatParagraph date={sel.date} view={MAESTRO_BACKEND_NAMING_VIEWS.TREAT} />
              ) : (
                <BLSelect
                  placeholder="Choose"
                  dropdownList={sortedDropdownListPlusCurrentElement.filter(item => !item.disableOption)}
                  value={sel.date}
                  disable={sel.action === "remove"}
                  onChange={(v) => handleDaySelection(selIndex, v)}
                  onClose={(v) => {
                    if (treatsSelections[selIndex].date === v.target.value) {
                      const selectionsCopy = JSON.parse(
                        JSON.stringify(treatsSelections)
                      );
                      selectionsCopy[selIndex].isEdited = false;
                      setTreatsSelections(selectionsCopy);
                    }
                  }}
                />
              )}
            </IonCol>
            <IonCol
              size="auto"
            >
              {sel.date !== "" ? (
                <>
                  {showXSign ? (
                    <BLIcon
                      iconSource={closeCircle}
                      iconSize="md"
                      color={"secondary"}
                      onClick={() => removeSeparateSelection(selIndex)}
                      disable={hasInRoomBookWithIn48hr(sel)}
                    />
                  ) : (
                    <BLIcon
                      iconSource={pencil}
                      iconSize="md"
                      color={"secondary"}
                      onClick={() => updateStatus(selIndex)}
                      disable={hasInRoomBookWithIn48hr(sel)}
                    />
                  )}
                </>
              ) : selIndex > 0 ? (
                <BLIcon
                  iconSource={closeCircle}
                  iconSize="md"
                  color={"secondary"}
                  onClick={() => removeSeparateSelection(selIndex)}
                  disable={hasInRoomBookWithIn48hr(sel)}
                />
              ) : null}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCol>
    );
 
    return (
      <>
        {hasInRoomBookWithIn48hr(sel) && (
          <IonRow>
            {sel.date ===
              reservationDetails.ArrivalDate.substring(0, 10) &&
              sel.options.filter((row) => row.bookedLocation === "In-Room").length > 0
              && (
                <IonCol size="12" className={"bl-margin-b-s"}>
                  <BLInformationBox
                    content={
                      <BLParagraph
                        markDown={!!dynamicTexts?.Treats?.Booking?.InfoBox_LocationsAndQuantities}
                        txt={dynamicTexts?.Treats?.Booking?.InfoBox_LocationsAndQuantities}
                      />
                    }
                    type={"info"}
                  />
                </IonCol>
              )}
          </IonRow>
        )}
 
        <IonRow>{dateSelRow}</IonRow>
      </>
    );
  };
 
  const daysRowSelectionContent = (
    <IonGrid
      className={"bl-flex"}
      style={{
        flexWrap: "wrap",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {treatsSelections?.map((sel, selIndex) => (
        <Fragment key={selIndex}>{getFieldType(sel, selIndex)}</Fragment>
      ))}

      {treatsDaysStatuses?.filter((day) => !day.disableOption).length > 1 &&
        <IonRow>
          <IonCol>
            <BLButton
              type="icon-text"
              icon={addCircle}
              iconSize="md"
              content={"Add more days"}
              onClick={() => addSeparateDays()}
              disabled={
                treatsSelections?.find((sel) => sel.date === "") ||
                treatsDaysStatuses?.filter((row) => row.hasSelection || row.disableOption).length ===
                treatsDaysStatuses?.length
              }
            />
          </IonCol>
        </IonRow>
      }
    </IonGrid>
  );
 
  return {
    daysRowSelectionContent,
  };
};
 
export default DaysRowSelection;
 