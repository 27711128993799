import { MAESTRO_BACKEND_NAMING_VIEWS, SERVICES_TYPES } from "../../constants/constants";
import { ENTERTAINMENT_TYPES } from "../../constants/constants";

const CONFLICT_TYPES = Object.freeze({
  SOFT: "soft",
  AVERAGE: "average",
  STRONG: "strong",
  CHAINED: "chained"
});

const FLOW_EVALUATION_PLANS = Object.freeze({
  NESTED: "nested",
  SINGLE: "single",
});

const ID_TYPES = Object.freeze({
  DINING: "restaurantId",
  SPA: "spaId",
  ENTERTAINMENT: "venueId",
  ACTIVITY: "activityId",
  ITINERARY: "Id",
});

const DATE_TYPES = Object.freeze({
  ITINERARY: "Date",
  NON_ITINERARY: "date",
});

const TIME_TYPES = Object.freeze({
  ITINERARY: "Time",
  NON_ITINERARY: "time",
});

const CONFLICT_DINING_TYPES = [SERVICES_TYPES.BREAKFAST, SERVICES_TYPES.DINNER];
const CONFLICT_ENTERTAINMENT_TYPES = ENTERTAINMENT_TYPES; //TODO Check entertainment types differance
const CONFLICT_ACTIVITIES_TYPES = [
  MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY,
];
const CONFLICT_SPAS_TYPES = [MAESTRO_BACKEND_NAMING_VIEWS.SPA];

const CONFLICT_INCLUDED_TYPES = [
  ...CONFLICT_DINING_TYPES,
  ...CONFLICT_ENTERTAINMENT_TYPES,
];

const CONFLICT_SERVICES_TYPES = [
  ...CONFLICT_SPAS_TYPES,
  ...CONFLICT_ACTIVITIES_TYPES,
];

const CONFLICT_ALL_TYPES = [...CONFLICT_INCLUDED_TYPES, ...CONFLICT_SERVICES_TYPES];

export {
  CONFLICT_TYPES,
  CONFLICT_DINING_TYPES,
  CONFLICT_ENTERTAINMENT_TYPES,
  CONFLICT_ACTIVITIES_TYPES,
  CONFLICT_SPAS_TYPES,
  CONFLICT_SERVICES_TYPES,
  CONFLICT_INCLUDED_TYPES,
  CONFLICT_ALL_TYPES,
  FLOW_EVALUATION_PLANS,
  ID_TYPES,
  DATE_TYPES,
  TIME_TYPES,
};
