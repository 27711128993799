import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { pricetag, time } from "ionicons/icons";
import React from "react";
import { formatPrice } from "../../../../helpingFunctions/formatPrice";
import BLIcon from "../../BLIcon";
import BLCheckTriangle from "../../Cards/BLCheckTriangle/BLCheckTriangle";
import BLItem from "../../Cards/BLItem/BLItem";
import BLImageCarousel from "../../Carousel/BLImageCarousel";
import BLParagraph from "../../Paragraph/BLParagraph";
import { isDesktop } from "../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../hooks/useTheme";
import { formatDuration } from "../../../../helpingFunctions/formatDuration";

const ServiceSummaryCard = ({
  images,
  title,
  duration,
  price,
  onClick,
  newWrapperStyles,
  hasBooking,
}) => {
  const { typography } = useTheme();

  return (
    <BLItem
      onClick={onClick ? onClick : null}
      newWrapperStyles={newWrapperStyles}
      containerType="category"
      contentType="category"
      imageType="summary"
      imageContent={
        <>
          {hasBooking && <BLCheckTriangle size="sm" />}
          <BLImageCarousel content={images} hideNavigation hidePagination />
        </>
      }
      mainContent={
        <IonGrid>
          <IonRow>
            <IonCol>
              <BLParagraph
                color="text-grey"
                txt={title}
                textVariant={isDesktop() ? typography.h2 : typography.h5}
                //newClasses={`${isDesktop() ? "x-large" : "x-small"} bold crimson-pro`}
                //TODO: Unclear typography
              />
            </IonCol>
          </IonRow>
          <IonRow className="bl-margin-t-xs bl-margin-b-xs">
            <IonCol
              className="bl-flex ion-align-items-center ion-justify-content-center"
              style={{ gap: "5px" }}
            >
              <BLIcon color="primary" iconSource={time} iconSize="xxsm" />
              <BLParagraph
                txt={formatDuration(duration)}
                textVariant={
                  isDesktop()
                    ? typography.BODY_TEXT
                    : typography.ITINERARY_BODY_TEXT
                }
              />
            </IonCol>
            <IonCol
              className={
                "bl-flex ion-align-items-center ion-justify-content-center"
              }
              style={{ gap: "5px" }}
            >
              <BLIcon
                color="primary"
                iconSource={pricetag}
                iconSize="xxsm"
              />
              <BLParagraph
                txt={`${
                  price === "Inclusive" ? "Inclusive" : formatPrice(price)
                }`}
                textVariant={
                  isDesktop()
                    ? typography.BODY_TEXT
                    : typography.ITINERARY_BODY_TEXT
                }
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      }
      contentDimensions={{ padding: [] }}
    />
  );
};

export default ServiceSummaryCard;
