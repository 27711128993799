import { IonCol, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import { useDataContext } from "../../../../../context/DataContext";
import BLCheckBox from "../../../../../shared/Ionic/Boxes/CheckBox/BLCheckBox";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import DaysLogic from "./DaysLogic";

import format from "date-fns/format";
import { ACTION_TYPES } from "../../../../../constants/constants";
import { DiningBookingContext } from "../../../../../context/DiningBookingContext";
import { getCurrentDate } from "../../../../../helpingFunctions/dateTimeUtilities";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";
import BLMUISelect from "../../../../../shared/Ionic/Inputs/BLMUISelect";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import styles from "./Days.module.scss";

const Days = ({ viewType }) => {
  const { noBookReqTexts } = DaysLogic(viewType);

  const {
    selectedDateSelections,
    handleNoBookingNeeded,
    handleDateChange,
    daySelectorOpts,
  } = useContext(DiningBookingContext);

  const { isLoadingItinerary, isLoadingAvailability, reservationDetails } =
  useDataContext();

  return (
    <BLContentCardWrapper>
      {isLoadingItinerary || isLoadingAvailability ? (
        <IonRow className="ion-text-center">
          <IonCol>
            <BLLoadingContainer />
          </IonCol>
        </IonRow>
      ) : (
        <>
          <IonRow>
            <IonCol size="auto" className={styles.daySelector}>
              <BLMUISelect
                placeholder="Select a day"
                dropdownList={daySelectorOpts}
                value={selectedDateSelections.date}
                disable={[ACTION_TYPES.REMOVE].includes(
                  selectedDateSelections.action
                )}
                onChange={(v) => {
                  if (v.target.value !== selectedDateSelections.date)
                    handleDateChange(v.target.value);
                }}
                enableSuccessIcon
                viewType={viewType}
                fieldType="dateSelector"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <BLContentCardWrapper
                dimensions={{ margin: ["t-s"], padding: ["b-xs"] }}
              >
                <>
                  <IonRow>
                    <IonCol size="auto">
                      <BLCheckBox
                        txt={noBookReqTexts.checkBoxTxt}
                        labelColor="text-grey"
                        newClasses="dining-party"
                        labelNewClasses="light"
                        checked={selectedDateSelections.isSkipped}
                        onClick={() => handleNoBookingNeeded()}
                        checkSlot="start"
                        disabled={
                          // If the selected time is in the past
                          selectedDateSelections.time &&
                          reservationDetails[viewType] &&
                          format(new Date(), "kkmm") >
                            selectedDateSelections.time
                              .slice(0, 5)
                              .replace(":", "") &&
                          getCurrentDate() === selectedDateSelections.date
                        }
                      />
                    </IonCol>
                  </IonRow>
                  {selectedDateSelections.isSkipped && (
                    <IonRow>
                      <IonCol>
                        <BLParagraph txt={noBookReqTexts.amendTxt} markDown />
                      </IonCol>
                    </IonRow>
                  )}
                </>
              </BLContentCardWrapper>
            </IonCol>
          </IonRow>
        </>
      )}
    </BLContentCardWrapper>
  );
};

export default Days;
