import { isOverlapping, getEndTime } from "../globalConflictHelpersMethods";

/** 
    Method for validating if the selection start time is between the booked start and end time
  */
const selectedStartOverlapsBookedDuration = (
  selectionDetails,
  bookedDetails,
  facilitiesDataDetails
) => {
  let isConflicting = false;

  const bookedServiceDetails = facilitiesDataDetails.find(
    (facilityDetail) => facilityDetail.id === bookedDetails.Id
  ) || { Duration: 0 };

  if (
    isOverlapping(
      selectionDetails.time,
      bookedDetails.Time,
      getEndTime(bookedDetails.Time, Number(bookedServiceDetails.Duration))
    )
  ) {
    isConflicting = true;
  }

  return isConflicting;
};

/** 
    Method for validating if the selection end time is between the booked start and end time
  */
const selectedEndOverlapsBookedDuration = (
  selectionDetails,
  bookedDetails,
  selectedServiceDetails,
  facilitiesDataDetails
) => {
  let isConflicting = false;

  const bookedServiceDetails = facilitiesDataDetails.find(
    (facilityDetail) => facilityDetail.id === bookedDetails.Id
  ) || { Duration: 0 };

  if (
    isOverlapping(
      getEndTime(
        selectionDetails.time,
        Number(selectedServiceDetails.Duration)
      ),
      bookedDetails.Time,
      getEndTime(bookedDetails.Time, Number(bookedServiceDetails.Duration))
    )
  ) {
    isConflicting = true;
  }

  return isConflicting;
};

/** 
    Method for validating if the booked start time is between the selection start and end time
  */
const bookedStartOverlapsSelectedDuration = (
  selectionDetails,
  bookedDetails,
  selectedServiceDetails
) => {
  let isConflicting = false;

  if (
    isOverlapping(
      bookedDetails.Time,
      selectionDetails.time,
      getEndTime(selectionDetails.time, Number(selectedServiceDetails.Duration))
    )
  ) {
    isConflicting = true;
  }
  return isConflicting;
};

/** 
    Method for validating if the booked end time is between the selection start and end time
  */
const bookedEndOverlapsSelectedDuration = (
  selectionDetails,
  bookedDetails,
  selectedServiceDetails,
  facilitiesDataDetails
) => {
  let isConflicting = false;

  const bookedServiceDetails = facilitiesDataDetails.find(
    (facilityDetail) => facilityDetail.id === bookedDetails.Id
  ) || { Duration: 0 };

  if (
    isOverlapping(
      getEndTime(bookedDetails.Time, Number(bookedServiceDetails.Duration)),
      selectionDetails.time,
      getEndTime(selectionDetails.time, Number(selectedServiceDetails.Duration))
    )
  ) {
    isConflicting = true;
  }

  return isConflicting;
};

export {
  selectedStartOverlapsBookedDuration,
  selectedEndOverlapsBookedDuration,
  bookedStartOverlapsSelectedDuration,
  bookedEndOverlapsSelectedDuration,
};
