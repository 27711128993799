import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { time } from "ionicons/icons";
import React, { useContext } from "react";
import BLIcon from "../../../../../../shared/Ionic/BLIcon";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import TimeRowSeparateSelection from "./Separate/TimeRowSeparateSelection";
import TimesRowLogic from "./TimesRowLogic";
import TimeRowTogetherSelection from "./Together/TimeRowTogetherSelection";
import { SpaContext } from "../../../../../../context/SpaContext";
import { useTheme } from "../../../../../../hooks/useTheme";

const TimesRow = (serviceDetails) => {
  const { typography } = useTheme();
  const { option, isEditMode } = useContext(SpaContext);
  const { collapsedRowContent, extractTimeSelections } = TimesRowLogic();

  const { timeRowTogetherContent } = TimeRowTogetherSelection(
    extractTimeSelections,
    serviceDetails
  );
  const { timeRowSeparatedContent } = TimeRowSeparateSelection(
    extractTimeSelections,
    serviceDetails,
  );

  const timeRowCollapsed = (
    <IonGrid>
      <IonRow className={option !== "separate" && "ion-align-items-center"}>
        <BLIcon iconSource={time} color={"primary"} iconSize={"md"} />
        <IonCol
          className={"bl-flex"}
          style={{ flexDirection: "column", gap: "5px" }}
        >
          {collapsedRowContent}
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const timeRowExpanded = (
    <IonGrid>
      <IonRow>
        <IonCol className={"bl-flex ion-align-items-center"}>
          <BLIcon iconSource={time} color={"primary"} iconSize={"md"} />
          <BLParagraph
            txt={"Time slot(s)"}
            color={"primary"}
            textVariant={typography.h3}
            // newClasses={`medium bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
            }}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const timeRowContent = (
    <IonGrid className={"bl-margin-b-m ion-align-items-center"}>
      {option === "separate" || isEditMode
        ? timeRowSeparatedContent
        : timeRowTogetherContent}
    </IonGrid>
  );

  return { timeRowCollapsed, timeRowExpanded, timeRowContent };
};

export default TimesRow;
