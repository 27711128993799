import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { gift } from "ionicons/icons";
import React from 'react';
import { useDataContext } from "../../../../../../context/DataContext";
import BLIcon from "../../../../../../shared/Ionic/BLIcon";
import BLLoadingContainer from "../../../../../../shared/Ionic/BLLoadingContainer";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import LocationsAndQuantityRowLogic from "./LocationsAndQuantityRowLogic";
import LocationsAndQuantitySelection from "./Separate/LocationsAndQuantitySelection";
import { useTheme } from "../../../../../../hooks/useTheme";

const LocationsAndQuantityRow = () => {
  const { typography } = useTheme();
  const { isLoadingItinerary } = useDataContext();
  const { collapsedRowContent, hasMultipleSel } =
    LocationsAndQuantityRowLogic();
  const { locationAndQuantityRowContent } = LocationsAndQuantitySelection();

  const locationsAndQtyRowCollapsed = (
    <IonGrid>
      <IonRow className={`${!hasMultipleSel ? "ion-align-items-center" : ""}`}>
        <BLIcon iconSource={gift} color={"primary"} iconSize={"md"} />
        <IonCol
          className={"bl-flex"}
          style={{ flexDirection: "column", gap: "5px" }}
        >
          {isLoadingItinerary ? <BLLoadingContainer /> : collapsedRowContent}
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const locationsAndQtyRowExpanded = (
    <IonGrid>
      <IonRow className={`${!hasMultipleSel ? "ion-align-items-center" : ""}`}>
        <IonCol className={"bl-flex ion-align-items-center"}>
          <BLIcon iconSource={gift} color={"primary"} iconSize={"md"} />
          <BLParagraph
            txt={"Locations & Quantities"}
            color={"primary"}
            textVariant={typography.h3}
            // newClasses={`medium bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
            }}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  const locationAndQtyRowContent = (
    <IonCol className={"ion-align-items-center"}>
      {isLoadingItinerary ? <BLLoadingContainer /> : locationAndQuantityRowContent}
    </IonCol>
  );

  return {
    locationsAndQtyRowCollapsed,
    locationsAndQtyRowExpanded,
    locationAndQtyRowContent,
  };
};

export default LocationsAndQuantityRow;
