import React, { useState } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import BLIcon from "../BLIcon";
import { arrowBackCircle, arrowForwardCircle } from "ionicons/icons";

import "swiper/swiper.min.css";
import styles from "./BLImageCarousel.module.scss";
import BLParagraph from "../Paragraph/BLParagraph";
import { useTheme } from "../../../hooks/useTheme";
import { getImageFormatURL } from "../../../helpingFunctions/imageFormat";

const autoplayConfig = {
  delay: 5000,
  disableOnInteraction: false,
};

const paginationStyles = {
  default: {
    "--swiper-pagination-color": "#FFF",
    "--swiper-pagination-bullet-inactive-color": "#FFFFFF",
    "--swiper-pagination-bullet-inactive-opacity": "0.7",
    "--swiper-pagination-bullet-size": "10px",
    "--swiper-pagination-bullet-horizontal-gap": "6px",
  },
};

const navgivationConfig = {
  nextEl: `.slider-next-arrow`,
  prevEl: ".slider-prev-arrow",
};

const BLImageJointCarousel = ({
  content = [],
  autoplay,
  hideNavigation,
  borderRadius = "0px 0px 0px 0px",
  paginationType = "default",
  format = "small",
}) => {
  const { typography } = useTheme();
  const [sliderRef, setSliderRef] = useState({});
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const hideNav = hideNavigation || content.length <= 1;

  const handleNext = () => {
    sliderRef?.slideNext();
  };

  const handlePrev = () => {
    sliderRef?.slidePrev();
  };
  const isFirst = currentSlideIndex === 0;
  const isLast = currentSlideIndex === content.length - 1;

  const prevBtn = (
    <BLIcon
      newClasses={styles["slider-prev-arrow"]}
      color={"white"}
      iconSource={arrowBackCircle}
      disable={isFirst}
      onClick={isFirst ? null : handlePrev}
    />
  );

  const nextBtn = (
    <BLIcon
      newClasses={styles["slider-next-arrow"]}
      color={"white"}
      iconSource={arrowForwardCircle}
      disable={isLast}
      onClick={isLast ? null : handleNext}
    />
  );

  const extraContentPagination = [];
  content.forEach((row, index) => {
    extraContentPagination.push(
      <div
        key={`auto_nav-${index}`}
        className={styles.dotsWrapper_auto_nav}
        onClick={() => sliderRef.slideTo(index)}
        style={{
          opacity: currentSlideIndex === index ? "1" : "0.6",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            backgroundColor:
              currentSlideIndex === index ? "#646464" : "#6E6E6E",
            height: "7px",
            width: "100px",
            borderRadius: "1px",
          }}
        ></div>
        <p
          className={styles.dotsWrapper_auto_nav_index}
          style={{
            color: currentSlideIndex === index ? "#646464" : "#6E6E6E",
            borderRadius: "1px",
          }}
        >
          0{index + 1}
        </p>
      </div>
    );
  });

  return (
    <>
      {!hideNav && prevBtn}
      <Swiper
        className={styles.swiperContainer}
        style={{
          borderRadius,
          ...paginationStyles[paginationType],
        }}
        onInit={(ev) => {
          setSliderRef(ev);
          ev.on("slideChange", function () {
            setCurrentSlideIndex(ev.realIndex);
          });
        }}
        centeredSlides={true}
        autoplay={autoplay ? autoplayConfig : false}
        pagination={false}
        navigation={hideNav ? false : navgivationConfig}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {content.map((obj, index) => {
          return (
            <SwiperSlide
              key={obj.image?.formats?.url ? obj.image?.formats?.url : index}
              className={styles.swiperSlide}
              style={{
                backgroundImage: `url(${getImageFormatURL(obj.image, format)})`,
              }}
            >
              <div
                style={{
                  transition: "1s all ease-in-out",
                  width: "600px",
                  minHeight: "200px",
                  position: "absolute",
                  bottom: "0",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: "5px 5px 0 0",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <IonGrid>
                  <IonRow
                    className={
                      "bl-margin-b-s bl-margin-t-l bl-padding-l-xs bl-padding-r-xs"
                    }
                  >
                    <IonCol className={"ion-text-center"}>
                      <BLParagraph
                        txt={obj.title}
                        color={"primary"}
                        textVariant={typography.h1}
                        //newClasses={"xxx-large crimson-pro bold"}
                        //TODO: Unclear typography
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow
                    className={"bl-margin-b-s  bl-padding-l-xs bl-padding-r-xs"}
                  >
                    <IonCol className={"ion-text-center"}>
                      <BLParagraph markDown={true} txt={obj.dsc} />
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ height: "60px" }}>
                    <IonCol
                      className={
                        "bl-flex ion-justify-content-center bl-margin-t-s bl-margin-b-xs"
                      }
                      style={{
                        gap: "12px",
                        position: "absolute",
                        bottom: 0,
                      }}
                    >
                      {extraContentPagination}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {!hideNav && nextBtn}
    </>
  );
};
export default BLImageJointCarousel;
