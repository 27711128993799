import React, { useContext } from "react";
import { IonCol, IonRow } from "@ionic/react";
import BLParagraph from "../Paragraph/BLParagraph";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { useTheme } from "../../../hooks/useTheme";
import { isMobile } from "../../../helpingFunctions/utilities";
import { chevronBack } from "ionicons/icons";
import BLButton from "../Buttons/BLButton";

const ServicePageHeader = ({ service, serviceType, txt, onClick }) => {
  const { typography } = useTheme();
  const { getWarnerService, getWarnerServiceOption } =
    useContext(NavigatorContext);

  const getText = () => {
    if (serviceType === "service") {
      return getWarnerService(service)?.title;
    } else {
      return getWarnerServiceOption(service)?.Title;
    }
  };

  return (
    <IonRow className="bl-flex bl-margin-t-l bl-padding-l-s bl-padding-r-s">
      <IonCol size="3" style={{ display: 'flex' }}>
        {isMobile() && onClick && (
          <BLButton
            type="ui-small"
            content="Back"
            icon={chevronBack}
            onClick={onClick}
          />
        )}
      </IonCol>
      <IonCol
        size="6"
        className="bl-flex ion-justify-content-center ion-text-center"
      >
        <BLParagraph
          txt={txt || getText()}
          color="primary"
          textVariant={typography.h2}
        // newClasses={`xx-large bold crimson-pro`}
        //TODO: Unclear typography
        />
      </IonCol>
      <IonCol size="3" />
    </IonRow >
  );
};

export default ServicePageHeader;
