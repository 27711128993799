import { Calendar } from "@awesome-cordova-plugins/calendar";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import { isPlatform } from "@ionic/core";
import format from "date-fns-tz/format";
import add from "date-fns/add";
import {
  getDayOfWeek,
  formatTime,
} from "../../../../../helpingFunctions/dateTimeUtilities";
import { jsPDF } from "jspdf";
import RemoveMarkdown from "remove-markdown";
import {
  ANDROID_PLATFORM,
  IOS_PLATFORM, INITIAL_DATE, MOBILEWEB_PLATFORM, MAESTRO_BACKEND_NAMING_VIEWS
} from "../../../../../constants/constants";
import { DINING_TYPES } from "../../../../../constants/constants";
import { useDataContext } from "../../../../../context/DataContext";
import { isPastDate } from "../../../../../helpingFunctions/bookingHelp";
import {
  ENTERTAINMENT_INFORMATION_MSG,
  SPA_INFORMATION_MSG,
} from "../../constants";
import {
  convertItineraryObject,
  getBookedItemDescription,
} from "../../helpers";

const GeneralActionsLogic = (itineraryDates) => {
  const { 
    itineraryDetails, 
    reservationDetails, 
    reservationHotelDetails, 
    dynamicTexts,
    facilitiesData,
  } = useDataContext();
  const itineraryData = convertItineraryObject(itineraryDetails);
  const pdfDate = itineraryData.ArrivalDate.slice(0, 10);
  const emailSubjectDate = pdfDate.split("-").reverse().join(".");
  const itinerary = JSON.parse(JSON.stringify(itineraryDates));

  const PDF_FILENAME = `My Warner Stay itinerary for ${reservationHotelDetails?.Title} on ${pdfDate}`;
  const EMAIL_SUBJECT = `My Warner Stay itinerary for ${reservationHotelDetails?.Title} on ${emailSubjectDate}`;

  const addToCalendar = (calendarId) => {
    const calOptions = Calendar.getCalendarOptions();
    calOptions.calendarId = calendarId;
    calOptions.calendarName = "MWS";

    const dates = itineraryData.itineraryDates;
    const events = [];

    dates.map(({ Facilities, Spa, Entertainment }) => {
      for (const calendarEvent of [...Facilities, ...Spa, ...Entertainment]) {
        if (
          calendarEvent.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA &&
          calendarEvent.ParticipantFirstName &&
          calendarEvent.ParticipantFirstName !== calendarEvent.FirstName &&
          calendarEvent.ParticipantLastName &&
          calendarEvent.ParticipantLastName !== calendarEvent.LastName &&
          calendarEvent.isParticipant &&
          !calendarEvent.others
        ) {
          continue;
        }
        events.push(calendarEvent);
      }
    });

    const error = (err) => {
      alert(err);
    };

    const createSingleEvent = (allEvents, i) => {
      let addMinutes = 0;
      if (isPlatform(IOS_PLATFORM)) {
        addMinutes = 60;
      }

      const entertainments = facilitiesData?.Entertainment?.All;

      let duration = allEvents[i].Duration || 0;
      duration += allEvents[i].DurationHour * 60 || 0;
      duration += allEvents[i].DurationMinutes || 0;

      const startDate = new Date(
        allEvents[i].Date + "T" + allEvents[i].Time.slice(0, -6)
      );

      const endDate = add(new Date(startDate), {
        years: 0,
        months: 0,
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: duration ? duration : addMinutes,
        seconds: 0,
      });
      const title = DINING_TYPES.includes(allEvents[i].Type)
        ? `${allEvents[i].Type} at ${allEvents[i].Title}`
        : allEvents[i].Title;
      const notes = getBookedItemDescription(
        allEvents[i],
        reservationDetails.CRSNumber,
        entertainments, 
        dynamicTexts
      );

      const success = () => {
        if (i === allEvents.length - 1) {
          alert("Itinerary synced to calendar.");
        } else {
          createSingleEvent(allEvents, i + 1);
        }
      };

      Calendar.createEventWithOptions(
        title,
        null,
        notes,
        startDate,
        endDate,
        calOptions,
        success,
        error
      );
    };

    createSingleEvent(events, 0);
  };

  const createCalendar = () => {
    const createCalOptions = Calendar.getCreateCalendarOptions();
    createCalOptions.calendarName = "MWS";
    createCalOptions.calendarColor = "#545F8D";

    const success = (calendarId) => {
      addToCalendar(calendarId);
    };

    const error = (err) => {
      console.error(
        `Error in createCalendar function in GenerealActionsLogic.js ${err}`
      );
    };

    Calendar.createCalendar(createCalOptions, success, error);
  };

  const deleteAllEvenets = (calendar) => {
    const deleteOneEvent = (events, i) => {
      const success = () => {
        deleteOneEvent(events, i + 1);
      };

      const error = (err) => {
        console.error("Error deleting an event", err);
      };

      if (i < events.length) {
        Calendar.deleteEventById(events[i].id, null, success, error);
      } else {
        addToCalendar(calendar.id);
      }
    };

    const startDate = new Date(
      format(new Date(itineraryData.ArrivalDate.slice(0, 10)), "yyyy-MM-dd")
    );

    const endDate = add(
      new Date(
        format(new Date(itineraryData.DepartureDate.slice(0, 10)), "yyyy-MM-dd")
      ),
      {
        years: 0,
        months: 0,
        weeks: 0,
        days: 1,
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
    );

    const success = (events) => {
      deleteOneEvent(events, 0);
    };

    const error = (err) => {
      console.error(
        `Error in findEvent in deleteAllEvenets func in GeneralActionsLogic.js ${err}`
      );
    };

    const calOptions = Calendar.getCalendarOptions();
    calOptions.calendarName = "MWS";
    calOptions.calendarId = calendar.id;

    Calendar.findEventWithOptions(
      null,
      null,
      null,
      startDate,
      endDate,
      calOptions,
      success,
      error
    );
  };

  const syncCalendar = () => {
    const checkCalendars = () => {
      const success = (calendars) => {
        const calendarObj = calendars.find(
          (calendar) => calendar.name === "MWS"
        );
        if (calendarObj) {
          return deleteAllEvenets(calendarObj);
        }
        return createCalendar();
      };

      const error = (err) => {
        alert(err);
        console.error(
          `${new Date()} Error in syncCalendar func in GeneralActionsLogic.js ${err}`
        );
      };

      Calendar.listCalendars(success, error);
    };

    const checkForPermission = () => {
      Calendar.hasReadWritePermission((result) => {
        if (!result) {
          alert("Please allow MWS to use your calendar and try again.");
        } else {
          checkCalendars();
        }
      });
    };

    if (isPlatform(ANDROID_PLATFORM)) {
      checkCalendars();
    } else if (isPlatform(IOS_PLATFORM)) {
      checkForPermission();
    }
  };

  const createPdf = () => {
    try {
      const doc = new jsPDF();

      doc.setFillColor("#545F8D");
      doc.rect(0, 0, doc.internal.pageSize.getWidth(), 15, "F");
      doc.setTextColor("#FFFFFF");
      let textX =
        (doc.internal.pageSize.getWidth() -
          doc.getTextWidth("My Warner Stay")) /
        2;
      doc.text("My Warner Stay", textX, 10);
      doc.setTextColor("#545F8D");
      doc.setFont(undefined, "bold");
      doc.text(
        "Itinerary",
        (doc.internal.pageSize.getWidth() - doc.getTextWidth("Itinerary")) / 2,
        25
      );
      doc.setFillColor("#F2F2F4");
      doc.roundedRect(
        10,
        30,
        doc.internal.pageSize.getWidth() - 20,
        doc.internal.pageSize.getHeight() - 40,
        1,
        1,
        "F"
      );

      const dates = itinerary.sort((a, b) => a.Data.localeCompare(b.Data));

      let line = 30;
      let dayNumber = 1;
      for (const date of dates) {
        doc.setFontSize(14);
        doc.setTextColor("#545F8D");
        doc.setFont("Courier", "bold");
        if (line > 240) {
          line = 10;
          doc.addPage("a4", "p");
          doc.setFillColor("#F2F2F4");
          doc.roundedRect(
            10,
            10,
            doc.internal.pageSize.getWidth() - 20,
            doc.internal.pageSize.getHeight() - 20,
            1,
            1,
            "F"
          );
        }
        line += 10;
        doc.text(20, line, `Day ${dayNumber}`);
        doc.setFont("Courier", "normal");

        doc.text(
          20 + doc.getTextWidth(`Day ${dayNumber}`),
          line,
          ` - ${getDayOfWeek(date.Data, "E")} ${format(
            new Date(date.Data),
            "do MMM"
          )}`
        );

        const pdfEvents = date.bookings
          .filter((booking) => booking.Type !== MAESTRO_BACKEND_NAMING_VIEWS.TREAT);

        pdfEvents.sort((a, b) => {
          return (
            Number(a.Time.slice(0, -9).replace(":", "")) -
            Number(b.Time.slice(0, -9).replace(":", ""))
          );
        });

        for (const event of pdfEvents) {
          if (line > 250) {
            line = 10;
            doc.addPage("a4", "p");
            doc.setFillColor("#F2F2F4");
            doc.roundedRect(
              10,
              10,
              doc.internal.pageSize.getWidth() - 20,
              doc.internal.pageSize.getHeight() - 20,
              1,
              1,
              "F"
            );
          }
          line += 10;
          doc.setFillColor("#FFFFFF");
          doc.roundedRect(
            11,
            line - 7,
            doc.internal.pageSize.getWidth() - 22,
            10,
            0,
            0,
            "F"
          );
          doc.setFontSize(12);
          doc.setFont("Helvetica", "bold");
          doc.setTextColor("#333333");

          let timeText = formatTime(
            `${INITIAL_DATE}T${event.Time.slice(0, -9)}`,
            true
          );
          timeText = timeText.length > 7 ? timeText : `  ${timeText}`;

          if (event.Type === "Check in") {
            timeText = `${timeText} onwards`;
          }

          doc.text(16, line, timeText);
          doc.setFont("Helvetica", "normal");
          const col = 16 + doc.getTextWidth(timeText) + 4;

          if (DINING_TYPES.includes(event.Type)) {
            doc.text(
              col,
              line,
              ` ${event.Type} at ${event.Title || event.Location}`
            );
          } else {
            if (event.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
              doc.text(col, line, ` SPA Treatment - ${event.Title || event.Location}`);
            } else {
              doc.text(col, line, ` ${event.Title || event.Location}`);
            }
          }

          if (
            event.conflicts &&
            !isPastDate(date.Data, event.Time.substring(0, 5))
          ) {
            event.conflicts.forEach((conflict) => {
              const conflictText = RemoveMarkdown(conflict);
              const CONFLICT_TEXT_WIDTH = 150;
              const textLines = Math.ceil(
                doc.getTextWidth(conflictText) / CONFLICT_TEXT_WIDTH
              );

              doc.setFillColor("#FFFFFF");
              doc.roundedRect(
                11,
                line + 3,
                doc.internal.pageSize.getWidth() - 22,
                11 + 5 * textLines,
                0,
                0,
                "F"
              );
              doc.setFillColor("#e8d862");
              doc.roundedRect(
                16,
                line + 5,
                doc.internal.pageSize.getWidth() - 32,
                6 + 5 * textLines,
                1,
                1,
                "F"
              );
              doc.addImage(
                "iVBORw0KGgoAAAANSUhEUgAAABwAAAAbCAYAAABvCO8sAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAANeSURBVEhLpZZfSFNRHMe/+6tuuj9X56YhaaalpP2TsKiIogcjSIki8CHoqXrpQSIiCOqhnpKgyHqop/48lRGRRSj0x9ISDaX/JTqt/L9NN6fbdeucszPddNu96gfu7v39xu5n99zzO+en+PftWFCBxASDwEwgCL8viDGnHw5yKMiP0gUN0s1aKJSAWpX4LuQWSNKtQUJhgEhGRv14+9GFjs4JtHdN8G+i2VpmREmRnpxNyBDUUCoX3pEKaTau0O8Por5hCM+bxjA47OPZxGRmaFGxR0BVhQUaDXnsSLgxprC3fwrXbvfh649JnlkcJcWpuHA6D0naCCkXzvsbIBIPTp75vmQZpeuLG9UnPmPaF+AZAn+qKOGow4dzl7t5tDy8UwFcqbPDRyZaJLNCUQzixp0/0f8qDinJCwYmJs0fXLhfPwBxZk46+8umZgda2sd5FJ91a1Nx/VIhHtQVwWzS8Gx8GhrHYO+b4hEX+v0BPGscZQkp8nOTYSGzUafXwJohLXR7RDx9OcJGkMKEre0T+Plb3iTxegOk6BWs8H2kdOTwusWJCc8Mu2bCtk/SQxnGTYQUuvrQkZEDnUAfuYMJu+1eFsjB4xZJSREbFfJhkkOPPfQemdA5LrJADu5JIqQ+8rEYocPlZ2cmdC1C6PWGno7ik1FCYca5gwmTZdYVxT0Zevl06Zhf1IkIO9inYFKzQA4eMtuoiB6LGVKzMVRCTLjClswCOdB98XHDMJ68GGLbl1yybVp2ZsKy9QYWyOXeowHcfTjII3lsKEljZyakm6dcdClKtvXUHM/hGWkK8/XIyUpi10yYZdWSTTOTJaTItiWhtNiA8s0CrJbQMEmxf68AtZqpQkLaEhw6YMGqlSksmYj+v9OovWlH7a1eWZ3A7u1m7Cw3saWQEtISjGlqVO2z8Cg+U9MBvGl14H2bk2fiYzaqcfSwLardmLsi7NpmRvVBG4+Wz9lTuRDM0TtKlFBJoiOVVpyvyYWBPPFSoc3U1YsFKCrQLejgYjZRtL5+9XhZvb16Jz10kdBRqqzIwOo83ex7iyRhX0o3zdYOF5PSPVMUY6+dqXoVNpUYsKPciC0bDWRGxrujhDAM7UnoUtZJujG66jvJQTdh+n4E0maUFqVCRd6AKkYDHA3wH3K8THDGI1yyAAAAAElFTkSuQmCC",
                "PNG",
                22,
                line + 6 + Math.round((5 * textLines) / 2),
                5,
                5
              );
              doc.setTextColor("#345867");
              doc.text(32, line + 12, conflictText, {
                maxWidth: CONFLICT_TEXT_WIDTH,
              });
              line += 11 + 5 * textLines;
            });
          }

          if (
            (event.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA || event.Type === MAESTRO_BACKEND_NAMING_VIEWS.VENUE) &&
            !isPastDate(date.Data, event.Time.substring(0, 5))
          ) {
            doc.setFillColor("#FFFFFF");
            doc.roundedRect(
              11,
              line + 3,
              doc.internal.pageSize.getWidth() - 22,
              16,
              0,
              0,
              "F"
            );
            doc.setFillColor("#D5ECEF");
            doc.roundedRect(
              16,
              line + 5,
              doc.internal.pageSize.getWidth() - 32,
              11,
              1,
              1,
              "F"
            );
            doc.addImage(
              "iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAICSURBVDhPjZXNSxtBGMafXVPIKbG2qcREkvYvKKWXgngRBMWD0PoBpUToxXNvnoqn3jwXRLAqIrYFD6IgeBGhl1J68eoHGq0kaOPFbIxZ53ldV2N2Zv3BS3YnOz9mdvI+sbaKpy5CqLnqERaxLNiqTGiltVoNpbMS8vkjHB4XsLu3L+PZTDvaWhNIpZKIx+KwbVvG79IgddWKqpeXWPi5hI3NX95oMJ0dbzD8th+Rpia1gdvV10m5zd9//mJ24QfK5bI3aiYajeLD8Du8fvXSfy3+2rnC7Z09TE7PPVhI+CzncC4dxJdyy1+nvnl3jTx5lpDSwbl0EJHyUFbW1tXBnMlgIxZGBgeQU8XrIDiXDrpEevq/hOXVNfkyGBeZbApZVbzWQQddIt0/yMugCee8IhUGXTZP/ODwyBvSU7moSIVBl81O4Q88DMdxpMKgS7ZvBXRFPS7KFUfK9E4JXTZ7ma1nxsK/46KqglyboMtmF7CXw5iZX1T13bvTQ5fsm+EQRu79kFQYdImUacNwMJFOJqVM0EGXHygX1So+jX3W9n1L4ql8nhSK8nkfBsvEl3E8ikSuV0oYX0wbHZTphIRz6SAN0ce0YTjoc6CeeCyG0Y85vHie8aNPG9IMB3MeAH093ejt7jKH9F2YNgwH9jJb76breLrptiTa0yk8bg76OwGuAMqxAB4FLw/qAAAAAElFTkSuQmCC",
              "PNG",
              19,
              line + 8,
              5,
              5
            );
            doc.setTextColor("#345867");
            doc.text(
              27,
              line + 12,
              event.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA
                ? SPA_INFORMATION_MSG
                : ENTERTAINMENT_INFORMATION_MSG
            );
            line += 16;
          }
        }

        dayNumber++;
      }
      return doc;
    } catch (error) {
      console.error(
        `${new Date()} Error in createPdf func in GeneralActionsLogic.js ${
          error.message
        }`
      );
    }
  };

  const downloadPdf = () => {
    try {
      const doc = createPdf();

      if (isPlatform(IOS_PLATFORM) || isPlatform(ANDROID_PLATFORM)) {
        Filesystem.writeFile({
          path: `${PDF_FILENAME}.pdf`,
          data: doc.output("datauristring"),
          directory: isPlatform(IOS_PLATFORM)
            ? Directory.Documents
            : Directory.External,
        })
          .then(() => alert("PDF downloaded successfully!"))
          .catch((err) => alert(err));
      } else {
        const blobPDF = new Blob([doc.output("blob")], {
          type: "application/pdf",
        });
        const blobUrl = URL.createObjectURL(blobPDF);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `${PDF_FILENAME}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in downloadPdf func in GeneralActionsLogic.js ${
          error.message
        }`
      );
    }
  };

  const shareItinerary = async () => {
    try {
      const doc = createPdf();
      let base64Pdf = doc
        .output("datauristring")
        .replace("filename=generated.pdf;", "");

      if (
        (isPlatform(IOS_PLATFORM) || isPlatform(ANDROID_PLATFORM)) &&
        !isPlatform(MOBILEWEB_PLATFORM)
      ) {
        let fileExtension = "";
        if (isPlatform(ANDROID_PLATFORM)) {
          fileExtension = ".pdf";
        }
        base64Pdf = base64Pdf.replace(
          "data:application/pdf",
          `df:${PDF_FILENAME}${fileExtension};data:application/pdf`
        );
        const fileConfig = {
          path: `${PDF_FILENAME}.pdf`,
          data: doc.output("datauristring"),
          directory: Directory.Cache,
        };

        const writeFileResult = await Filesystem.writeFile(fileConfig);

        await Share.share({ url: writeFileResult.uri });
      } else {
        const dates = itineraryData.itineraryDates.sort((a, b) =>
          a.Data.localeCompare(b.Data)
        );
        let text = "";

        for (const [index, date] of dates.entries()) {
          text += `\n${date.Data.split("-").reverse().join(".")}\n`;

          if (index === 0) {
            text += "15:00 Check-in from 3pm to 12am\n";
          }

          const shareEvents = [
            ...date.Facilities,
            ...date.Spa,
            ...date.Entertainment,
          ].sort((a, b) => {
            return (
              Number(a.Time.slice(0, -9).replace(":", "")) -
              Number(b.Time.slice(0, -9).replace(":", ""))
            );
          });

          for (const event of shareEvents) {
            if (event.others) {
              continue;
            }
            text +=
              event.Time.slice(0, -9) +
              (DINING_TYPES.includes(event.Type) ? ` ${event.Type} at ` : " ") +
              event.Title +
              "\n";
          }

          if (index === dates.length - 1) {
            text += "12:00 Check out";
          }
        }

        const mailtoLink = encodeURI(
          `mailto:?subject=${EMAIL_SUBJECT}&body=${text}`
        );

        if (navigator.share) {
          fetch(base64Pdf)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], `${PDF_FILENAME}.pdf`, {
                type: "application/pdf",
              });
              if (navigator.canShare({ files: [file] })) {
                navigator
                  .share({
                    title: EMAIL_SUBJECT,
                    text: "",
                    files: [file],
                  })
                  .then(() => {
                    //confirmation message?
                  })
                  .catch(console.error);
              } else {
                window.open(mailtoLink);
              }
            });
        } else {
          window.open(mailtoLink);
        }
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in shareItinerary func in GeneralActionsLogic.js ${
          error.message
        }`
      );
    }
  };

  const actionClickHandler = (actionName) => {
    try {
      if (actionName === "addToCalendar") {
        if (isPlatform(ANDROID_PLATFORM) || isPlatform(IOS_PLATFORM)) {
          syncCalendar();
        }
      } else if (actionName === "downloadPdf") {
        downloadPdf();
      } else if (actionName === "shareItinerary") {
        shareItinerary();
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in actionClickHandler func in GeneralActionsLogic.js ${
          error.message
        }`
      );
    }
  };

  return {
    actionClickHandler,
  };
};

export default GeneralActionsLogic;
