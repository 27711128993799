import { IonCol, IonContent, IonGrid, IonPage, IonRow, isPlatform } from "@ionic/react";
import React, { useContext, useEffect } from "react";
import {
  ANDROID_PLATFORM,
  IMAGE_FORMATS,
  IOS_PLATFORM,
  MOBILEWEB_PLATFORM,
} from "../../../constants/constants";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import BLImageJointCarousel from "../../../shared/Ionic/Carousel/BLImageJointCarousel";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import LegalDocsLinks from "./LegalDocsLinks";
import LoginLogic from "./LoginLogic";
import LoginForm from "./components/LoginForm/LoginForm";
import styles from "./login.module.scss";
import { isDesktop } from "../../../helpingFunctions/utilities";

const Login = () => {
  const { loginPageData, isLoading } = LoginLogic();
  const [splashScreen, setSplashScreen] = React.useState(true);
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const isNativeApp =
    ((isPlatform(ANDROID_PLATFORM) || isPlatform(IOS_PLATFORM)) &&
      (!isPlatform(MOBILEWEB_PLATFORM) ||
        process.env.REACT_APP_ENVIRONMENT === "dev")) ||
    (process.env.REACT_APP_ENVIRONMENT === "local" && !isDesktop());

  React.useEffect(() => {
    if (!loginPageData?.SplashScreen) return;
    setTimeout(() => {
      setSplashScreen(false);
    }, loginPageData?.SplashScreen?.Delay * 1000);
  }, [loginPageData?.SplashScreen]);

  const backgroundImage = loginPageData?.BackgroundImage;

  const DESKTOP_CONTENT = (
    <>
      <IonGrid
        className={styles.Login}
        style={{
          background: `url(${backgroundImage?.formats?.medium?.url || backgroundImage?.url})  center bottom / cover `,
        }}
      >
        <IonRow
          className="bl-padding-t-l"
          style={{ flex: 1, overflow: "auto", width: "100%" }}
        >
          <LoginForm loginPageData={loginPageData} />
        </IonRow>
        {!isDesktop() && (
          <IonRow className="bl-flex ion-justify-content-center bl-padding-b-s">
            <LegalDocsLinks tcLink={loginPageData?.TermsAndConditions?.Link} />
          </IonRow>
        )}
      </IonGrid>
      {isDesktop() && (
        <BLImageJointCarousel
          hideNavigation
          autoplay
          content={loginPageData?.SliderContent}
          format={IMAGE_FORMATS.XXLARGE}
        />
      )}
    </>
  );

  useEffect(() => {
    if (isLoading) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Login Page");
    } else {
      setIsLoaderOpened(false);
    }
  }, [isLoading]);

  return (
    <IonPage>
      <IonContent scroll-y="false">
        {!isLoading && (

          <IonGrid
            style={{
              display: "flex",
              height: "100%",
              overflow: "auto"
            }}
          >
            {isNativeApp &&
              loginPageData?.SplashScreen?.BackgroundImage &&
              splashScreen ?
              (
                <IonGrid
                  className={styles.Login}
                  style={{
                    background: `url(${loginPageData?.SplashScreen?.BackgroundImage?.url})  center center / cover `,
                  }}
                >
                  <IonGrid>
                    <IonRow className={styles.Separator} />
                    <IonRow className={styles.SplashContent}>
                      <IonCol style={{ display: "flex", justifyContent: "center" }}>
                        <BLParagraph
                          color="white"
                          txt={loginPageData?.SplashScreen?.Description}
                          markDown={true}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonGrid>
              ) : (
                DESKTOP_CONTENT
              )}
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Login;
