import { IonCol, IonRow } from "@ionic/react";
import { pencil } from "ionicons/icons";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TreatsContext } from "../../../../context/TreatsContext";
import BLButton from "../../../../shared/Ionic/Buttons/BLButton";
import BLExpandableBox from "../../../../shared/Ionic/Cards/BLExpandableBox/BLExpandableBox";
import TermsAndConditions from "../../../../shared/Ionic/TermsAndConditions/TermsAndConditions";
import TreatYourselfExpandableRowsContentLogic from "./TreatYourselfExpandableRowsContentLogic";
import { expandCollapse } from "../../../../helpingFunctions/helpingFunctions";
import { isDesktop } from "../../../../helpingFunctions/utilities";
import { useParams } from "react-router-dom";
import { UNCATEGORIZED } from "../../../../constants/constants";
import { NavigatorContext } from "../../../../context/NavigatorContext";
import { ROUTE_VIEWS } from "../../../../routes/routeViews";

const TreatYourselfExpandableRowsContent = ({ clickedRow, setClickedRow }) => {
  const { categoryId } = useParams();
  const history = useHistory();
  const {
    treatsSelections,
    cancelChangesHandler,
    handleTreatsBookingRQ,
    areSelectionsCompleted,
    areAnyChanges,
    setSelectedService,
    isEditMode,
  } = useContext(TreatsContext);
  const { redirectBetweenPages } = useContext(NavigatorContext);

  const [isDayClicked, setIsDayClicked] = useState(true);

  const {
    daysRowCollapsed,
    daysRowExpanded,
    daysRowContent,
    locationsAndQtyRowCollapsed,
    locationsAndQtyRowExpanded,
    locationAndQtyRowContent,
  } = TreatYourselfExpandableRowsContentLogic();

  useEffect(() => {
    setClickedRow("daysRow");
  }, []);

  useEffect(() => {
    if (clickedRow === "daysRow") {
      setIsDayClicked(true);
    } else {
      setIsDayClicked(false);
    }
  }, [clickedRow]);

  return (
    <Fragment>
      <IonRow key="daysRow1" className={isDesktop() ? "bl-margin-b-xs" : ""}>
        <IonCol>
          <BLExpandableBox
            value="daysRow"
            collapsedTitle={daysRowCollapsed}
            expandedTitle={daysRowExpanded}
            newClass={"ExpandableBox_second"}
            dimensions={{
              padding: [
                "l-s",
                "r-s",
                "t-s",
                isDayClicked === "daysRow" ? "b-m" : "b-s",
              ],
            }}
            isClicked={isDayClicked}
            customIcon={isEditMode && pencil}
            onClick={() => expandCollapse("daysRow", clickedRow, setClickedRow)}
          >
            {daysRowContent}
          </BLExpandableBox>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <BLExpandableBox
            value="locationAndQtyRow"
            collapsedTitle={locationsAndQtyRowCollapsed}
            expandedTitle={locationsAndQtyRowExpanded}
            newClass={"ExpandableBox_second"}
            dimensions={{
              padding: [
                "l-s",
                "r-s",
                "t-s",
                clickedRow === "locationAndQtyRow" ? "b-m" : "b-s",
              ],
            }}
            isClicked={clickedRow === "locationAndQtyRow"}
            customIcon={isEditMode && pencil}
            isDisabled={treatsSelections.find((row) => row.date === "")}
            onClick={() =>
              expandCollapse("locationAndQtyRow", clickedRow, setClickedRow)
            }
          >
            {locationAndQtyRowContent}
          </BLExpandableBox>
        </IonCol>
      </IonRow>
      <IonRow className="bl-margin-t-s">
        <IonCol>
          <TermsAndConditions />
        </IonCol>
      </IonRow>
      <IonRow key="buttonsRow">
        <IonCol
          style={{ gap: "20px" }}
          className="bl-flex ion-justify-content-center ion-align-items-center bl-margin-t-m"
        >
          <BLButton
            content={isEditMode ? "Cancel" : "Go back"}
            type="underlined"
            onClick={() => {
              if (isEditMode) {
                if (history.location.state.isItineraryRedirect) {
                  redirectBetweenPages(ROUTE_VIEWS.ITINERARY);
                }
                cancelChangesHandler();
              } else {
                setSelectedService(null);
                if (categoryId && categoryId !== UNCATEGORIZED) {
                  redirectBetweenPages(
                    ROUTE_VIEWS.TREATS_CATEGORY.replace(
                      ":categoryId",
                      categoryId
                    )
                  );
                } else {
                  redirectBetweenPages(ROUTE_VIEWS.TREATS);
                }
              }
            }}
          />
          <BLButton
            content={isEditMode ? "Update changes" : "Add"}
            type="primary"
            onClick={() =>
              handleTreatsBookingRQ(isEditMode ? "change" : "book")
            }
            disabled={isEditMode ? !areAnyChanges : !areSelectionsCompleted}
          />
        </IonCol>
      </IonRow>
    </Fragment>
  );
};

export default TreatYourselfExpandableRowsContent;
