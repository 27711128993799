import React, { useState } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import BLExpandableBox from "../../../../shared/Ionic/Cards/BLExpandableBox/BLExpandableBox";
import { formatDateDetailed } from "../../../../helpingFunctions/dateTimeUtilities";
import FolioPostingRow from "./FolioPostingRow";
import { formatPrice } from "../../../../helpingFunctions/formatPrice";
import { expandCollapse, generateKey } from "../../../../helpingFunctions/helpingFunctions";
import { useTheme } from "../../../../hooks/useTheme";

const FolioExpandableBox = ({ keyVal, value }) => {
  const { typography } = useTheme();
  const [clickedRow, setClickedRow] = useState(null);

  return (
    <BLExpandableBox
      collapsedTitle={
        <IonGrid>
          <IonRow className="ion-align-items-center" style={{ gap: "4px" }}>
            <IonCol>
              <BLParagraph
                txt={formatDateDetailed([], keyVal, true)}
                color="primary"
                textVariant={typography.h3}
                // newClasses={`medium crimson-pro`}
                //TODO: Unclear typography
              />
            </IonCol>
            <IonCol size="auto" style={{ minWidth: "80px", textAlign: "right" }}>
              <BLParagraph
                txt={formatPrice(value.totalAmount)}
                color="primary"
                textVariant={typography.h3}
                // newClasses={`medium crimson-pro`}
                //TODO: Unclear typography
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      }
      expandedTitle={
        <BLParagraph
          txt={formatDateDetailed([], keyVal, true)}
          color="primary"
          textVariant={typography.h3}
          // newClasses={`medium crimson-pro`}
          //TODO: Unclear typography
        />
      }
      newClass="ExpandableBox_main"
      dimensions={{
        padding: ["xs"],
      }}
      isClicked={clickedRow === keyVal}
      onClick={() => expandCollapse(keyVal, clickedRow, setClickedRow)}
    >
      {
        <IonCol>
          <IonGrid style={{ gap: "8px", display: "grid", overflowY: "hidden" }}>
            <FolioPostingRow
              isHeader
              description="DESCRIPTION"
              refId="REF ID"
              price="PRICE"
            />
            {value.postings.map((posting) => (
              <FolioPostingRow
                key={generateKey(posting)}
                description={posting.PostingCodeDescription}
                price={formatPrice(posting.Amount)}
                refId={posting.Voucher}
              />
            ))}
            <IonRow className="ion-text-end">
              <IonCol className="bl-padding-r-xs">
                <BLParagraph
                  txt={
                    <>
                      <BLParagraph
                        txt="Total: "
                        //TODO: Unclear typography
                        //newClasses="bold"
                        dimensions={{
                          padding: ["xxs"],
                        }}
                      />
                      <BLParagraph txt={formatPrice(value.totalAmount)} />
                    </>
                  }
                  color="text-grey"
                  textVariant={typography.BODY_TEXT}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCol>
      }
    </BLExpandableBox>
  );
};

export default FolioExpandableBox;
