import { useContext, useState } from "react";
import { WhatsOnDataContext } from "../../context/WhatsOnDataContext";

const DaysListLogic = (dataLength, view, width) => {
  const { setSelectedDate } = useContext(WhatsOnDataContext);
  const [leftPosition, setLeftPosition] = useState(0);
  const handleClick = (date) => {
    try {
      setSelectedDate(date);
    } catch (e) {
      console.error("handleClick Error: ", e);
    }
  };

  const slideLeft = () => {
    let leftPositionThreshold = view === "WhatsOnGuide" ? -420 : -210;
    let slideLeftPositionThreshold = 70;
    const isDataLengthBiggerThanFive = dataLength >= 6;

    if (["Entertainment", "Treats"].includes(view)) {
      if (width >= 685) {
        leftPositionThreshold = isDataLengthBiggerThanFive ? -280 : 0;
      } else if (width >= 480) {
        slideLeftPositionThreshold = isDataLengthBiggerThanFive ? 70 : 50;
        leftPositionThreshold = isDataLengthBiggerThanFive ? -420 : -250;
      } else {
        leftPositionThreshold = isDataLengthBiggerThanFive ? dataLength * -100 : -280;
      }
    } else if (["Breakfast", "Dinner", "Spa"].includes(view)) {
      if (width >= 685) {
        leftPositionThreshold = isDataLengthBiggerThanFive ? dataLength * -55 : 0;
      } else if (width >= 480) {
        slideLeftPositionThreshold = isDataLengthBiggerThanFive ? 70 : 50;
        leftPositionThreshold = isDataLengthBiggerThanFive ? dataLength * -70 : -250;
      } else {
        leftPositionThreshold = isDataLengthBiggerThanFive ? dataLength * -80 : -210;
      }
    }

    if (leftPosition < leftPositionThreshold) return;

    setLeftPosition((prevState) => prevState - slideLeftPositionThreshold);
  };

  const slideRight = () => {
    let slideRightPositionThreshold = width >= 480 && width <= 685 && dataLength <= 5 ? 50 : 70;

    if (leftPosition >= 0) return;

    setLeftPosition((prevState) => prevState + slideRightPositionThreshold);
  };

  
  return { handleClick, slideLeft, slideRight, leftPosition };
};

export default DaysListLogic;
