import React from "react";
import Markdown from "markdown-to-jsx";

import styles from "./information-text.module.css";

const InformationText = ({ lineHeight, content, className, markdown }) => {
  return (
    <div
      className={`${styles.infoWrapper} ${className}`}
      style={{
        lineHeight,
      }}
    >
      {markdown && content ? <Markdown>{content}</Markdown> : content}
    </div>
  );
};

export default InformationText;
