import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import { closeCircle } from "ionicons/icons";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import { useTheme } from "../../../../../hooks/useTheme";

const ShowsDetails = ({
  dismissAlert,
  images,
  header,
  longDescription,
  location
}) => {
  const { typography } = useTheme();
  return (
    <IonCol>
      <BLContentCardWrapper dimensions={{ padding: ["s"] }}>
        <IonGrid className="bl-flex ion-justify-content-center">
          <IonRow
            className="ion-text-center bl-flex ion-align-items-center ion-justify-content-center"
            style={{ height: "50px" }}
          >
            <IonCol style={{ height: "500px", width: "275px" }}>
              {images && <BLImageCarousel content={images} />}
            </IonCol>
          </IonRow>
          <IonRow style={{ overflow: "auto", height: "500px", width: "100%" }}>
            <IonCol>
              <IonGrid className="bl-margin-xs bl-margin-l-m">
                <IonRow className="ion-margin-bottom">
                  <IonCol
                    className="bl-flex ion-align-items-center ion-justify-content-between"
                  >
                    <BLParagraph
                      txt={header}
                      color="text-grey"
                      textVariant={typography.h2}
                      // newClasses={`xx-large bold crimson-pro`}
                      //TODO: Unclear typography
                    />
                    <BLIcon
                      iconSource={closeCircle}
                      color="primary"
                      iconSize="md"
                      onClick={() => dismissAlert()}
                      newStyles={{ flex: "0 0 32px" }}
                    />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <BLParagraph
                      txt={longDescription}
                      color="text-grey"
                      textVariant={typography.BODY_TEXT}
                      markDown={!!longDescription}
                    />
                  </IonCol>
                </IonRow>
                {location && (
                  <>
                    <IonRow>
                      <IonCol>
                        <BLParagraph
                          txt={
                            <>
                              <BLParagraph
                                txt="Show is being played at "
                                color="text-grey"
                                textVariant={typography.BODY_TEXT}
                              />
                              <BLParagraph
                                txt={location}
                                color="text-grey"
                                textVariant={typography.TEXT_LINK}
                                //newClasses={`x-small bold underline `}
                                //TODO: Unclear typography
                              />
                            </>
                          }
                          color="text-grey"
                          textVariant={typography.BODY_TEXT}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <BLParagraph
                          txt="Check in the booking flow for available dates and times."
                          color="text-grey"
                          textVariant={typography.BODY_TEXT}
                        />
                      </IonCol>
                    </IonRow>
                  </>
                )}
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </BLContentCardWrapper>
    </IonCol>
  );
};

export default ShowsDetails;
