import React, { useContext, useEffect, useState } from "react";
import { TreatsContext } from "../../../../../../context/TreatsContext";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import { useTheme } from "../../../../../../hooks/useTheme";

const DaysRowLogic = () => {
  const { typography } = useTheme();
  const { treatsSelections, daySelections, isEditMode } = useContext(TreatsContext);
  const [collapsedRowContent, setCollapsedRowContent] = useState(null);
  const [hasMultipleSel, setHasMultipleSel] = useState(false);
 

  const handleCollapsedRowSummary = () => {
    const content = [];

    const hasUnselectedDays = treatsSelections?.find((sel) => sel.date === "");
    
    if (hasUnselectedDays) {
      content.push(
        <BLParagraph
          key={"Days"}
          txt={"Upon Arrival"}
          color={"primary"}
          textVariant={typography.h3}
          // newClasses={`medium bold crimson-pro`}
          //TODO: Unclear typography
          dimensions={{
            margin: ["l-s"],
            padding: ["t-md", "b-md"],
          }}
        />
      );
    } else {
      const treatsSelCopy = JSON.parse(JSON.stringify(treatsSelections));
      treatsSelCopy?.forEach((row, rowIndex) => {
        if (row.date !== "") {
          content.push(
            <BLParagraph
              key={rowIndex}
              textVariant={typography.h3}
              txt={
                <>
                  <BLParagraph
                    key={daySelections?.find((daySel) => daySel.id === row.date)
                      ?.fullTitle}
                    txt={
                      daySelections?.find((daySel) => daySel.id === row.date)
                        ?.fullTitle
                    }
                    color={"primary"}
                    textVariant={typography.h3}
                    // newClasses={`medium crimson-pro`}
                    //TODO: Unclear typography
                    dimensions={{
                      margin: ["l-s"],
                    }}
                  />

                  {isEditMode &&
                    (!["none"].includes(row.action) ||
                      row.date !== row.bookedDate ||
                      row.action === "remove") && (
                    <BLParagraph
                      txt={" *Updated* "}
                      color={"success"}
                      textVariant={typography.h3}
                      // newClasses={`medium bold crimson-pro`}
                      //TODO: Unclear typography
                    />
                  )}
                </>
              }
            />
          );
        }
      });
    }
    setHasMultipleSel(content.length > 1);
    setCollapsedRowContent(content);
  };

  useEffect(() => {
    if (treatsSelections) {
      handleCollapsedRowSummary();
    }
  }, [treatsSelections]);

  return { collapsedRowContent, hasMultipleSel };
};

export default DaysRowLogic;
