import { useEffect, useState } from "react";
import { useDataContext } from "../../../../../context/DataContext";

const DaysLogic = (viewType) => {
  const { dynamicTexts } = useDataContext();
  const [noBookReqTexts, setNoBookReqTexts] = useState({});

  useEffect(() => {
    setNoBookReqTexts({
      checkBoxTxt:
        dynamicTexts?.Shared?.DiningAndEntertainment
          ?.Label_DaySelection_NoBookingNeededCheckBox,
      amendTxt:
        dynamicTexts?.Shared?.DiningAndEntertainment
          ?.Paragraph_DaySelection_NoBookingNeededExplicitInfo,
    });
  }, [viewType, dynamicTexts]);

  return {
    noBookReqTexts,
  };
};

export default DaysLogic;
