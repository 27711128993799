import React, { useState, createContext } from "react";

export const SideMenuContext = createContext({});

export const SideMenuProvider = ({ children }) => {
  const [isSideMenuOpened, setIsSideMenuOpened] = useState(false);
  const [areLinkResCardsCollapsed, setAreLinkResCardsCollapsed] =
    useState(true);
  const [fullScreenOverlayContent, setFullScreenOverlayContent] =
    useState(null);
  const [legalDocDialogType, setLegalDocDialogType] =
    useState(null);
  const [fullScreenMap, setFullScreenMap] = 
    useState(null);
   
  return (
    <SideMenuContext.Provider
      value={{
        isSideMenuOpened,
        setIsSideMenuOpened,
        setFullScreenMap,
        fullScreenMap,
        areLinkResCardsCollapsed,
        setAreLinkResCardsCollapsed,
        fullScreenOverlayContent,
        setFullScreenOverlayContent,
        legalDocDialogType,
        setLegalDocDialogType,
      }}
    >
      {children}
    </SideMenuContext.Provider>
  );
};
