import React, { createContext } from 'react';
import { TYPOGRAPHY_OPTIONS } from '../constants/constants';

/* Basic Theme implementation; Colors and Spacing are just for example and future improvements */
const theme = {
  colors: {
    primary: '#007bff',
    secondary: '#6c757d',
  },
  typography: {
    ...TYPOGRAPHY_OPTIONS,
  },
  spacing: {
    margin: '8px',
    padding: '8px',
  }
};

export const ThemeContext = createContext(theme);

export const ThemeContextProvider = ({ children }) => {
  return (
    <ThemeContext.Provider
      value={
        theme
      }
    >
      {children}
    </ThemeContext.Provider>
  );
};