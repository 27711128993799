import React from "react";
import { search_icon } from "../../assets/SVGIcons";
import BLIcon from "../../shared/Ionic/BLIcon";

import FAQSearchLogic from "./FAQSearchLogic";

import styles from "./faq_search.module.css";

const FAQSearch = ({ manageSearch }) => {
  const {
    handleChange,
    removePlaceHolder,
    displayPlaceHolder,
    searchValue,
    isPlaceHolder,
  } = FAQSearchLogic(manageSearch);

  return (
    <form
      className={styles.FAQSearch}
      onSubmit={(e) => manageSearch(e, searchValue)}
    >
      <div className={styles.FAQSearch__search}>
        <input
          className={styles.FAQSearch__submit}
          type={"submit"}
          value={""}
        />
        <BLIcon customIconSource={search_icon} />
      </div>
      {isPlaceHolder ? (
        <p className={styles.FAQSearch__input_para} onClick={removePlaceHolder}>
          How can we help you? Start typing keywords, questions etc.
        </p>
      ) : (
        <input
          className={styles.FAQSearch__input}
          autoFocus={true}
          onBlur={displayPlaceHolder}
          type="text"
          onChange={handleChange}
          value={searchValue}
        />
      )}
    </form>
  );
};

export default FAQSearch;
