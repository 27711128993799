import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";
import { useDataContext } from "../../../../../context/DataContext";
import { NavigatorContext } from "../../../../../context/NavigatorContext";
import { hasCategoryItemsAtleastOneBooking } from "../../../../../helpingFunctions/bookingHelp";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { ROUTE_VIEWS } from "../../../../../routes/routeViews";
import CategoryCard from "../../../../../shared/Ionic/ServicesComponents/ServiceCards/CategoryCard";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../../../../../constants/constants";

const SpaCategories = ({ categories }) => {
  const { itineraryDetails } = useDataContext();
  const { redirectBetweenPages } = useContext(NavigatorContext);

  return (
    <BLContentCardWrapper
      width="100%"
      background="white"
      dimensions={{ margin: ["b-s"] }}
    >
      <IonGrid>
        <IonRow style={{ gap: isDesktop() ? "16px" : "22px" }}>
          {categories.map(
            ({ title, categoryPortraitImg, categoryId }, index) => (
              <IonCol
                key={index}
                style={
                  isDesktop()
                    ? {
                      minWidth: "260px",
                      maxWidth: "260px",
                      minHeight: "220px",
                    }
                    : {
                      minWidth: "168px",
                      maxWidth: "168px",
                      width: "168px",
                      minHeight: "200px",
                    }
                }
                onClick={() =>
                  redirectBetweenPages(
                    ROUTE_VIEWS.SPAS_CATEGORY.replace(":categoryId", categoryId)
                  )
                }
              >
                <CategoryCard
                  categoryImg={categoryPortraitImg}
                  title={title}
                  hasBooking={hasCategoryItemsAtleastOneBooking(
                    categoryId,
                    MAESTRO_BACKEND_NAMING_VIEWS.SPA,
                    itineraryDetails
                  )}
                />
              </IonCol>
            )
          )}
        </IonRow>
      </IonGrid>
    </BLContentCardWrapper>
  );
};

export default SpaCategories;
