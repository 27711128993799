import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { SpaContext } from "../../../../../../context/SpaContext";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import { useTheme } from "../../../../../../hooks/useTheme";

const DaysRowLogic = () => {
  const { typography } = useTheme();
  const { spaSelections, option, daySelections, isEditMode } = useContext(SpaContext);
  const [collapsedRowContent, setCollapsedRowContent] = useState(null);
  const handleCollapsedRowSummary = () => {
    if (option === "separate" || isEditMode) {
      const content = [];
      spaSelections.forEach((sel, index) => {
        const subContent = [];
        const margins = [];
        if (index !== 0) {
          margins.push("t-s");
        }

        subContent.push(
          <IonRow key={sel.guestName} className="bl-margin-b-xs bl-margin-l-s">
            <IonCol>
              <BLParagraph
                txt={sel.guestName}
                color="text-grey"
                textVariant={typography.h3}
                // newClasses={`medium bold crimson-pro`}
                //TODO: Unclear typography
                dimensions={{
                  margin: margins,
                }}
              />
            </IonCol>
          </IonRow>
        );

        sel.on.forEach((row, index) => {
          if (row.date !== "") {
            subContent.push(
              <IonRow key={row.uniqueKey || index} className="bl-margin-l-s">
                <IonCol>
                  <BLParagraph
                    txt={
                      daySelections?.find((daySel) => daySel.id === row.date)
                        ?.fullTitle
                    }
                    color="primary"
                    textVariant={typography.h3}
                    typographyStyles={
                      row.action === "remove"
                        ? { textDecoration: "line-through" }
                        : {}
                    }
                    // newClasses={`medium bold crimson-pro ${
                    //   row.action === "remove" ? "line" : ""
                    // } `}
                    //TODO: Unclear typography
                  />
                  {!["none"].includes(row.action) && isEditMode && (row.date !== row.bookedDate || row.action === "remove") && (
                    <BLParagraph
                      txt="*Updated*"
                      color="success"
                      textVariant={typography.h3}
                      // newClasses={`medium bold crimson-pro`}
                      //TODO: Unclear typography
                      dimensions={{
                        margin: ["l-s"],
                      }}
                    />
                  )}
                </IonCol>
              </IonRow>
            );
          }
        });

        content.push(
          <IonGrid key={sel.uniqueKey || index} className="bl-margin-b-xs">{subContent}</IonGrid>
        );
      });
      setCollapsedRowContent(content);
    } else {
      const content = [];

      const hasUnselectedDays = spaSelections[0]?.on.find(
        (sel) => sel.date === ""
      );

      if (hasUnselectedDays) {
        content.push(
          <BLParagraph
            key="daysrow"
            txt="Day(s)"
            color="primary"
            textVariant={typography.h3}
            // newClasses={`medium bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
            }}
          />
        );
      } else {
        spaSelections[0]?.on.forEach((row, index) => {
          if (row.date !== "") {
            content.push(
              <BLParagraph
                key={index}
                txt={
                  daySelections?.find((daySel) => daySel.id === row.date)?.fullTitle
                }
                color="primary"
                textVariant={typography.h3}
                // newClasses={`medium bold crimson-pro`}
                //TODO: Unclear typography
                dimensions={{
                  margin: ["l-s"],
                }}
              />
            );
          }
        });
      }

      setCollapsedRowContent(content);
    }
  };

  useEffect(() => {
    handleCollapsedRowSummary();
  }, [spaSelections]);

  return { collapsedRowContent };
};

export default DaysRowLogic;
