import { IonPage, IonRouterOutlet } from "@ionic/react";
import React from "react";
import { ROUTE_VIEWS } from "./routeViews";
import { Route, Switch } from "react-router-dom";
import { ConflictManagementProvider } from "../context/ConflictManagementContext";
import { ActivityProvider } from "../context/ActivityContext";
import ActivitiesPage from "../views/children/ActivitiesPage/ActivitiesPage";
import FallbackRoute from "./FallbackRoute";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../constants/constants";

const activitiesPage = () => {
  return (
    <ConflictManagementProvider
      conflictOccuringView={MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY}
    >
      <ActivityProvider>
        <ActivitiesPage />
      </ActivityProvider>
    </ConflictManagementProvider>
  );
};

const ActivitiesRoutes = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Switch>
          <Route
            path={ROUTE_VIEWS.ACTIVITIES}
            exact={true}
            component={activitiesPage}
          />
          <Route
            path={ROUTE_VIEWS.ACTIVITY_BOOKING}
            exact={true}
            component={activitiesPage}
          />
          <Route component={FallbackRoute} />
        </Switch>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ActivitiesRoutes;
