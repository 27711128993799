import React from "react";
import { IonCol, IonRow } from "@ionic/react";

import CreateDimensions from "../../../../../helpingFunctions/DimensionsFactory";
import StayNavigatorLogic from "./StayNavigatorLogic";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";

const StayNavigator = () => {
  const {
    // showAddOnPrompDialog,
    // setViewAllTreats,
    navButtons,
    // setShowAddOnPrompDialog,
    // syncSelections,
  } = StayNavigatorLogic();
  // const modal = useRef(null);

  const { dimensions: lastRowDimensionStyles } = CreateDimensions({
    margin: ["t-xl", "b-m"],
  });

  return (
    <>
      {/* TODO #6599: Hiding treats in dinner page */}
      {/* <BLConfirmationPopupBox
        modal={modal}
        isOpen={showAddOnPrompDialog}
        header={"Can we interest you in treating yourself?"}
        confirmHandler={() => {
          setShowAddOnPrompDialog(false);
          setViewAllTreats(true);
          modal.current?.dismiss();
        }}
        dismissHandler={() => {
          setShowAddOnPrompDialog(false);
          syncSelections(true);
          modal.current?.dismiss();
        }}
        confirmBtnLabel={`Yes${width > 340 ? ", show me" : ""}`}
        dismissBtnLabel={`No${width > 340 ? ", thank you" : ""}`}
        confirmBtnType={"primary"}
        confirmBtnClasses="half-padding"
        dismissBtnType={"outlined"}
        dismissBtnClasses="half-padding"
      /> */}

      <IonRow
        className={`${lastRowDimensionStyles} ion-justify-content-center`}
      >
        <IonCol size="auto">
          {navButtons?.map(
            ({ icon, txt, type, onClick, disabled, dimensions }) => (
              <BLButton
                key={`${txt}_${type}`}
                content={txt}
                type={type}
                icon={icon}
                onClick={() => onClick()}
                disabled={disabled}
                dimensions={dimensions}
              />
            )
          )}
        </IonCol>
      </IonRow>
    </>
  );
};

export default StayNavigator;
