import { useContext, useEffect, useState } from "react";
import { useDataContext } from "../../context/DataContext";
import { NavigatorContext } from "../../context/NavigatorContext";
import { SideMenuContext } from "../../context/SideMenuContext";
import RequestHook from "../../hooks/RequestHook";
import ValidateLoginHook from "../../hooks/ValidateLoginHook";
import { ROUTE_VIEWS } from "../../routes/routeViews";
import { PageLoaderContext } from "../../context/PageLoaderContext";

const LinkedReservationsBlockLogic = () => {
  const [invalidResDetails, setInvalidResDetails] = useState(null);
  const { reservationDetails, setIsLoadingHotelData } = useDataContext();
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const {
    isSideMenuOpened,
    setAreLinkResCardsCollapsed,
  } = useContext(SideMenuContext);
  const { setIsLoaderOpened, setLoaderMessage } =
  useContext(PageLoaderContext);
  const { handleLogin } = ValidateLoginHook();
  const { getItinerary, getReservation } =
    RequestHook();

  const linkedReservations = reservationDetails?.LinkedReservations ? JSON.parse(
    JSON.stringify(reservationDetails.LinkedReservations)
  ).sort((a, b) => b.HotelTitle.localeCompare(a.HotelTitle)) : [];

  const currentRes = linkedReservations.find(
    (res) => res.CRSNumber === reservationDetails?.CRSNumber
  );

  const allReservations = currentRes ? [
    ...linkedReservations.filter(
      (res) => res.CRSNumber !== currentRes?.CRSNumber
    ),
    currentRes,
  ] : [];

  const changeReservation = async (res) => {
    setIsLoadingHotelData(true);
    const loginData = {
      maestroId: res.HotelMaestroId,
      bookingId: res.CRSNumber,
      surname: res.LastName,
    };

    setIsLoaderOpened(true);
    setLoaderMessage("Logging you in");
    
    const result = await handleLogin(loginData, true);
    if (result.success) {
      redirectBetweenPages(ROUTE_VIEWS.LANDING_PAGE);
      await getReservation();
      getItinerary();
      setIsLoadingHotelData(false);
      setIsLoaderOpened(false);
    }
  };

  useEffect(() => {
    if (isSideMenuOpened) {
      setAreLinkResCardsCollapsed(true);
    }
  }, [isSideMenuOpened]);

  return {
    allReservations,
    invalidResDetails, 
    setInvalidResDetails,
    changeReservation,
  };
};

export default LinkedReservationsBlockLogic;
