import { menuController } from "@ionic/core/components";
import { IonGrid, IonMenu, IonRow } from "@ionic/react";
import { Box } from "@mui/material";
import { closeSharp } from "ionicons/icons";
import React, { useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import LinkedReservationsBlock from "../../../components/LinkReservationsBlock/LinkedReservationsBlock";
import {
  AUTHENTICATED_KEY, LEGAL_DOCS, TCLink
} from "../../../constants/constants";
import { useDataContext } from "../../../context/DataContext";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { SideMenuContext } from "../../../context/SideMenuContext";
import ValidateLoginHook from "../../../hooks/ValidateLoginHook";
import { ROUTE_VIEWS } from "../../../routes/routeViews";
import BLIcon from "../BLIcon";
import styles from "./SideMenu.module.scss";
import SideMenuButton from "./SideMenuButton";

const SideMenu = () => {
  const location = useLocation();
  const { logout } = ValidateLoginHook();
  const {
    reservationDetails,
    reservationHotelDetails,
    pagesConfiguration,
  } = useDataContext();
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const history = useHistory();
  const { pathname } = useLocation();
  const isHelpPage = pathname.includes(ROUTE_VIEWS.HELP);
  const menuRef = useRef(null);
  const {
    isSideMenuOpened,
    setIsSideMenuOpened,
    setAreLinkResCardsCollapsed,
    setFullScreenOverlayContent,
    setLegalDocDialogType,
  } = useContext(SideMenuContext);
  const isLoggedIn = localStorage.getItem(AUTHENTICATED_KEY);

  const setMenuOpened = () => {
    setIsSideMenuOpened(true);
  };

  const setMenuClosed = () => {
    setIsSideMenuOpened(false);
    setAreLinkResCardsCollapsed(true);
  };

  const closeMenu = () => {
    menuController.close();
    setMenuClosed();
  };

  const handleClick = (route) => {
    try {
      redirectBetweenPages(route);
      setFullScreenOverlayContent(null);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleClick func SideMenu.js ${
          error.message
        }`
      );
    }
  };

  return (
    <>
      {isSideMenuOpened && (
        <div
          style={{
            position: "absolute",
            top: `calc(env(safe-area-inset-top) + 5px)`,
            left: `calc(${menuRef?.current?.offsetWidth}px + 5px)`,
            zIndex: 3000,
          }}
        >
          <BLIcon
            newClasses={styles.SideMenu_CloseIcon}
            iconSource={closeSharp}
            color="white"
            iconSize="lg"
            onClick={closeMenu}
          />
        </div>
      )}
      <IonMenu
        id="sideMenu"
        style={{
          marginTop: "env(safe-area-inset-top)",
        }}
        contentId="content"
        side="start"
        onIonWillOpen={setMenuOpened}
        onIonWillClose={setMenuClosed}
        swipeGesture={false}
      >
        <Box
          ref={menuRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
            background: "var(--ion-color-light)",
          }}
        >
          {reservationDetails && reservationHotelDetails && (
            <IonGrid
              style={{
                flex: 0,
                height: "100%",
              }}
            >
              <LinkedReservationsBlock />
            </IonGrid>
          )}
          <IonGrid
            className="bl-margin-t-xs"
            style={{
              display: "flex",
              flexDirection: "column",
              background: "white",
              flex: 0,
              boxShadow: "0px 3px 6px var(--ion-color-box-shadow)",
              padding: "8px 16px",
            }}
          >
            <SideMenuButton
              onClick={() => handleClick(ROUTE_VIEWS.ITINERARY)}
              isSelected={location.pathname.includes(ROUTE_VIEWS.ITINERARY)}
              btnText={"View Itinerary"}
              noLines
            />
            <SideMenuButton
              onClick={() => handleClick(ROUTE_VIEWS.HOTEL_INFO)}
              isSelected={location.pathname.includes(ROUTE_VIEWS.HOTEL_INFO)}
              btnText={"Hotel Info"}
              noLines
            />
          </IonGrid>
          <IonGrid className="bl-margin-t-s">
            <IonRow
              style={{
                background: "white",
                boxShadow: "0px 3px 6px var(--ion-color-box-shadow)",
                padding: "8px 16px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SideMenuButton
                onClick={() => {
                  history.push(ROUTE_VIEWS.HELP);
                }}
                btnText="Help & support"
                noLines
                isSelected={isHelpPage}
              />
              <SideMenuButton
                onClick={() => {
                  setLegalDocDialogType(LEGAL_DOCS.PRIVACY);
                }}
                btnText="Privacy policy"
                noLines
              />
              <SideMenuButton
                onClick={() =>
                  window
                    .open(
                      isLoggedIn
                        ? pagesConfiguration?.TermsAndConditions?.Link
                        : localStorage.getItem(TCLink),
                      "_blank"
                    )
                    .focus()
                }
                btnText="Terms & conditions"
                noLines
              />
              <SideMenuButton
                onClick={() => {
                  setLegalDocDialogType(LEGAL_DOCS.COOKIE);
                }}
                btnText="Cookie policy"
                noLines
              />
            </IonRow>
          </IonGrid>

          <IonGrid
            className="bl-padding-s bl-margin-b-s bl-margin-t-s"
            style={{
              background: "white",
              flex: 0,
              boxShadow: "0px 3px 6px var(--ion-color-box-shadow)",
              padding: "8px 16px",
            }}
          >
            {reservationDetails && (
              <SideMenuButton onClick={logout} btnText="Logout" noLines />
            )}
          </IonGrid>
        </Box>
      </IonMenu>
    </>
  );
};

export default SideMenu;
