import React from "react";
import { useState, createContext } from "react";
export const MapImagePopupContext = createContext({});

export const MapImagePopupProvider = ({ children }) => {
  const [mapImage, setMapImage] = useState({
    url: '',
    type: null
  });

  return (
    <MapImagePopupContext.Provider
      value={{
        mapImage,
        setMapImage,
      }}
    >
      {children}
    </MapImagePopupContext.Provider>
  );
};
