import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { intervalToDuration } from "date-fns";
import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useDataContext } from "../../../../context/DataContext";

import { utcToZonedTime } from "date-fns-tz";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import "./CountdownTimer.scss";
import { useTheme } from "../../../../hooks/useTheme";

const CountdownTimer = () => {
  const { typography } = useTheme();
  const { reservationDetails, reservationHotelDetails } =
  useDataContext();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const zonedDate = utcToZonedTime(reservationDetails?.ArrivalDate, timeZone);

  const interval = intervalToDuration({
    start: new Date(),
    end: zonedDate,
  });
  
  const months = interval.years * 12 + interval.months;
  const weeks = Math.floor(interval.days / 7);
  const days = Math.ceil(interval.days % 7);

  const dayText = days === 1 ? "day" : "days";
  const weekText = weeks === 1 ? "week" : "weeks";
  const monthText = months === 1 ? "month" : "months";

  const getDays = () => {
    return (
      <div className="timer">
        <div className="value">{days}</div>
      </div>
    );
  };
  const getWeeks = () => {
    return (
      <div className="timer">
        <div className="value">{weeks}</div>
      </div>
    );
  };
  const getMonths = () => {
    return (
      <div className="timer">
        <div className="value">{months}</div>
      </div>
    );
  };

  const calculateMonthsDuration = () => {
    if (interval.years !== 0) {
      return Math.ceil(months / 12) * 12;
    } else {
      return 12;
    }
  };

  return (
    <IonGrid>
      <IonRow>
        <BLParagraph
          txt={reservationHotelDetails?.CountdownText}
          textVariant={typography.ITINERARY_BODY_TEXT}
          color="white"
          markDown
        />
      </IonRow>
      <IonRow
        style={{
          margin: "5px 0",
          height: "1px",
          backgroundColor: "var(--ion-color-accent-pale-grey)",
        }}
      />
      <IonRow className="ion-justify-content-center bl-padding-t-s">
        <IonCol className="timer-wrapper">
          <CountdownCircleTimer //months
            size={50}
            strokeWidth={6}
            trailStrokeWidth={6}
            colors={"var(--ion-color-warner-teal)"}
            duration={calculateMonthsDuration()} // calculate duration
            strokeLinecap="round"
            initialRemainingTime={months}
            trailColor={[["rgba(181, 182, 184, 0.5)"]]}
          >
            {getMonths}
          </CountdownCircleTimer>
          <div className="text">
            <BLParagraph 
              txt={monthText}
              textVariant={typography.TOOLTIP_HIGHLIGHT_TEXT}
            />
          </div>
        </IonCol>
        <IonCol className="timer-wrapper">
          <CountdownCircleTimer //weeks
            size={50}
            strokeWidth={6}
            trailStrokeWidth={6}
            colors={"var(--ion-color-warner-teal)"}
            duration={4} // 4 weeks in month
            initialRemainingTime={weeks}
            trailColor={[["rgba(181, 182, 184, 0.5)"]]}
          >
            {getWeeks}
          </CountdownCircleTimer>
          <div className="text">
            <BLParagraph 
              txt={weekText}
              textVariant={typography.TOOLTIP_HIGHLIGHT_TEXT}
            />
          </div>
        </IonCol>
        <IonCol className="timer-wrapper">
          <CountdownCircleTimer //days
            size={50}
            strokeWidth={6}
            trailStrokeWidth={6}
            colors={"var(--ion-color-warner-teal)"}
            duration={7} // 7 days in week
            initialRemainingTime={days}
            trailColor={[["rgba(181, 182, 184, 0.5)"]]}
          >
            {getDays}
          </CountdownCircleTimer>
          <div className="text">
            <BLParagraph 
              txt={dayText}
              textVariant={typography.TOOLTIP_HIGHLIGHT_TEXT}
            />
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default CountdownTimer;
