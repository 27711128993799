import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { BOOKING_CONFIRMATION_MSG } from "../../../constants/constants";
import BLParagraph from "../Paragraph/BLParagraph";
import BLContentCardWrapper from "../Wrappers/BLContentCardWrapper";
import ConfirmationScreenLogic from "./ConfirmationScreenLogic";
import BookedDetailsCardDesktop from "./ServiceCards/BookedDetailsCardDesktop";
import BookedDetailsCardMobile from "./ServiceCards/BookedDetailsCardMobile";
import { getTotalPriceSummaryText } from "../../../helpingFunctions/GetTotalPriceSummaryText";
import { isDesktop } from "../../../helpingFunctions/utilities";
import { useTheme } from "../../../hooks/useTheme";

const ConfirmationScreen = ({
  serviceDetails,
  isEditActionOnlyCancelled,
  retryFailsFunc,
  source,
  confirmationScreenSelections,
  setConfirmationScreenSelections,
  isProcessingBookings,
  daySelections,
  setPriceMsg,
  setIsEditMode
}) => {
  const { typography } = useTheme();
  const {
    areAllCancellations,
    processedRSResults,
    getActionButtonsRow,
    getProcessingScreenContent,
    getAreAllCancellationsScreenContent,
  } = ConfirmationScreenLogic(source, 
    retryFailsFunc, 
    confirmationScreenSelections, 
    setConfirmationScreenSelections, 
    isProcessingBookings, 
    daySelections,
    setPriceMsg,
    setIsEditMode);

  return (
    <BLContentCardWrapper
      background={isDesktop() ? "light" : "white"}
      dimensions={{ padding: ["b-l", "l-xs"] }}
    >
      <IonGrid
        className="bl-margin-b-s"
        style={{
          height: "100%",
          overflow: isProcessingBookings ? "hidden" : "auto",
        }}
      >
        {isProcessingBookings ? (
          getProcessingScreenContent()
        ) : (
          <>
            {areAllCancellations ? (
              getAreAllCancellationsScreenContent(serviceDetails)
            ) : (
              <>
                <IonRow className="bl-margin-t-m">
                  <IonCol className="ion-text-center">
                    <BLParagraph
                      txt={BOOKING_CONFIRMATION_MSG}
                      textVariant={typography.h4}
                      color="text-grey"
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {isDesktop() ? (
                      <BookedDetailsCardDesktop
                        serviceDetails={serviceDetails}
                        daySelections={processedRSResults}
                        priceTxt={getTotalPriceSummaryText(
                          "confirmationScreen",
                          serviceDetails.Price,
                          confirmationScreenSelections,
                          source,
                          isProcessingBookings,
                          typography
                        )}
                        source={source}
                      />
                    ) : (
                      <BookedDetailsCardMobile
                        serviceDetails={serviceDetails}
                        daySelections={processedRSResults}
                        priceTxt={getTotalPriceSummaryText(
                          "confirmationScreen",
                          serviceDetails.Price,
                          confirmationScreenSelections,
                          source,
                          isProcessingBookings,
                          typography
                        )}
                        source={source}
                      />
                    )}
                  </IonCol>
                </IonRow>

                {!isEditActionOnlyCancelled && getActionButtonsRow()}
              </>
            )}
          </>
        )}
      </IonGrid>
    </BLContentCardWrapper>
  );
};

export default ConfirmationScreen;
