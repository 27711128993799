import { IonGrid } from "@ionic/react";
import React, { useContext } from "react";
import BLAlertPopUp from "../../shared/Ionic/Boxes/Popup/BLAlertPopUp";
import ChainMethods from "./conflictChainingMethods/ChainMethodsLogic";
import { CONFLICT_TYPES } from "./conflictConstants";
import {
  getConflictResolverProgressText,
  negateAverageConflict,
  resolveAverageConflict,
  resolveSoftConflict,
  resolveStrongConflict,
} from "./resolveMethods/resolveMethods";
import { useDataContext } from "../../context/DataContext";
import { ConflictManagementContext } from "../../context/ConflictManagementContext";

const ConflictPopupBoxes = () => {
  const {
    spotlitConflict,
    foundConflicts,
    setFoundConflicts,
    setProhibitRQToMaestro,
    setReplacedBookings,
    setSpotlitConflict,
    disableReplaceInChainedSpaView
  } = useContext(ConflictManagementContext);
  const { reservationDetails } = useDataContext();
  const {
    getChainedRowDetails,
    shouldDisableChainedConflictReplaceBtn,
    canChainedConflictsBeResolved,
  } = ChainMethods();

  switch (spotlitConflict?.conflictType) {
    case CONFLICT_TYPES.SOFT:
      return (
        <BLAlertPopUp
          title={`Booking conflicts ${getConflictResolverProgressText(
            foundConflicts
          )}`}
          description={spotlitConflict?.conflictMsg}
          isOpen={!!spotlitConflict}
          dismissHandler={() =>
            resolveSoftConflict(
              setFoundConflicts,
              foundConflicts,
              spotlitConflict
            )
          }
          dismissBtnLabel={"OK"}
          dismissBtnType={"primary"}
        />
      );
    case CONFLICT_TYPES.AVERAGE:
      return (
        <BLAlertPopUp
          // modal={modal}
          title={`Booking conflicts ${getConflictResolverProgressText(
            foundConflicts
          )}`}
          description={spotlitConflict?.conflictMsg}
          isOpen={!!spotlitConflict}
          confirmBtnLabel={"Replace"}
          dismissBtnType={"underlined"}
          confirmBtnType={"primary"}
          dismissBtnLabel={"Cancel"}
          dismissHandler={() => {
            negateAverageConflict(
              setFoundConflicts,
              foundConflicts,
              setProhibitRQToMaestro
            );
            // modal.current?.dismiss();
          }}
          confirmHandler={() => {
            resolveAverageConflict(
              setFoundConflicts,
              foundConflicts,
              spotlitConflict,
              setReplacedBookings,
              CONFLICT_TYPES.AVERAGE
            );
            // modal.current?.dismiss();
          }}
        />
      );
    case CONFLICT_TYPES.STRONG:
      return (
        <BLAlertPopUp
          title={`Booking conflicts ${getConflictResolverProgressText(
            foundConflicts
          )}`}
          description={spotlitConflict?.conflictMsg}
          isOpen={!!spotlitConflict}
          dismissHandler={() =>
            resolveStrongConflict(
              setFoundConflicts,
              foundConflicts,
              spotlitConflict,
              setProhibitRQToMaestro,
              canChainedConflictsBeResolved, 
              disableReplaceInChainedSpaView
            )
          }
          dismissBtnLabel={"OK"}
          dismissBtnType={"primary"}
        />
      );
    case CONFLICT_TYPES.CHAINED:
      return (
        <BLAlertPopUp
          // modal={modal}
          title={`Booking conflicts ${getConflictResolverProgressText(
            foundConflicts
          )}`}
          description={spotlitConflict?.conflictMsg}
          isOpen={!!spotlitConflict}
          confirmBtnLabel={
            canChainedConflictsBeResolved(spotlitConflict, foundConflicts, disableReplaceInChainedSpaView) &&
            "Replace"
          }
          dismissBtnType={"underlined"}
          confirmBtnType={"primary"}
          dismissBtnLabel={
            canChainedConflictsBeResolved(spotlitConflict, foundConflicts, disableReplaceInChainedSpaView)
              ? "Cancel"
              : "Close"
          }
          dismissHandler={() => {
            negateAverageConflict(
              setFoundConflicts,
              foundConflicts,
              setProhibitRQToMaestro
            );
            // modal.current?.dismiss();
          }}
          confirmHandler={() => {
            resolveAverageConflict(
              setFoundConflicts,
              foundConflicts,
              spotlitConflict,
              setReplacedBookings,
              CONFLICT_TYPES.CHAINED
            );
            // modal.current?.dismiss();
          }}
          confirmBtnDisabled={shouldDisableChainedConflictReplaceBtn(
            spotlitConflict,
            reservationDetails,
            foundConflicts,
            disableReplaceInChainedSpaView
          )}
        >
          <IonGrid>
            {spotlitConflict.confirmedBookingDetails.map((obj, index) => {
              return getChainedRowDetails(
                obj,
                index,
                setSpotlitConflict,
                JSON.parse(JSON.stringify(spotlitConflict)),
                canChainedConflictsBeResolved(spotlitConflict, foundConflicts, disableReplaceInChainedSpaView)
              );
            })}
          </IonGrid>
        </BLAlertPopUp>
      );
    default:
      if (spotlitConflict) {
        // eslint-disable-next-line no-console
        console.warn(
          `Unfamiliar conflict type detected: ${spotlitConflict?.conflictType}`
        );
      }
      return <></>;
  }
};

export default ConflictPopupBoxes;
