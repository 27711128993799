import React, { useState, createContext } from "react";

export const PageLoaderContext = createContext({});

export const PageLoaderProvider = ({ children }) => {
  const [isLoaderOpened, setIsLoaderOpened] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");

  return (
    <PageLoaderContext.Provider
      value={{
        isLoaderOpened,
        setIsLoaderOpened,
        loaderMessage,
        setLoaderMessage,
      }}
    >
      {children}
    </PageLoaderContext.Provider>
  );
};
