import { useContext } from "react";
import { INITIAL_DATE } from "../../../../../../constants/constants";
import { ActivityContext } from "../../../../../../context/ActivityContext";
import { useDataContext } from "../../../../../../context/DataContext";
import {
  formatTimeDetailed,
  getCurrentDate,
  getDatesBetween,
  getDayPeriod,
} from "../../../../../../helpingFunctions/dateTimeUtilities";

const TimesRowLogic = () => {
  const { activityAvailability, activityViewType, selectedService } =
    useContext(ActivityContext);
  const { reservationDetails } = useDataContext();

  const extractTimeSelections = (date, dayTime) => {
    if (!activityAvailability) {
      return "loading";
    } else if (
      activityAvailability &&
      date >= getCurrentDate() && 
     (!activityAvailability[activityViewType] ||
      date === "" ||
      !activityAvailability[activityViewType][date]?.AvailabilityDetails)
    ) {
      return [];
    }

    let activityAvailabilityDetails;

    if (activityAvailability && activityAvailability[activityViewType]) {
      if (!activityAvailability[activityViewType][date]) {
        return "passedDay";
      }
      activityAvailabilityDetails = activityAvailability[activityViewType][
        date
      ].AvailabilityDetails.find(
        (row) => row.FacilityGUID === selectedService?.id
      );
    }

    return activityAvailabilityDetails
      ? activityAvailabilityDetails.Times[dayTime]
      : [];
  };

  const getTime = (row) => {
    const dayTime = getDayPeriod(`${INITIAL_DATE}T${row.time.substring(0, 5)}`);
    const times = extractTimeSelections(row.date, dayTime);

    if ((typeof times === "string" && times !== "passedDay") || !times) return;

    return formatTimeDetailed(
      getDatesBetween(
        reservationDetails.ArrivalDate,
        reservationDetails.DepartureDate
      ),
      `${row.date}T${row.time.substring(0, 5)}`
    );
  };

  return {
    extractTimeSelections,
    getTime,
  };
};

export default TimesRowLogic;
