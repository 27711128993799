import { IonCol, IonRow } from "@ionic/react";
import React, { useRef } from "react";
import { useDataContext } from "../../../context/DataContext";
import { replacePlaceholders } from "../../../helpingFunctions/replacePlaceholders";
import BLLoadingContainer from "../BLLoadingContainer";
import BLAlertPopUp from "../Boxes/Popup/BLAlertPopUp";
import Loader from "../Loader/Loader";
import BookedSummaryScreenLogic from "./BookedSummaryScreenLogic";
import BookedDetailsCardDesktop from "./ServiceCards/BookedDetailsCardDesktop";
import BookedDetailsCardMobile from "./ServiceCards/BookedDetailsCardMobile";
import { getTotalPriceSummaryText } from "../../../helpingFunctions/GetTotalPriceSummaryText";
import { isDesktop } from "../../../helpingFunctions/utilities";
import { useTheme } from "../../../hooks/useTheme";

const BookedSummaryScreen = ({
  serviceDetails,
  handleBookingRQ,
  selections,
  setSelections,
  source,
  hasCancellationPolicy,
  setHasCancellationPolicy,
  cancellationPolicyAlertsLenght,
  isProcessingBookings,
  setIsEditMode,
  daySelections
}) => {
  const { typography } = useTheme();
  const { isLoadingItinerary, dynamicTexts } = useDataContext();
  const modal = useRef(null);

  const {
    showCancelDialog,
    setShowCancelDialog,
    extractDaySelection,
    cancelAllBookings,
    getActionButtonsRow,
  } = BookedSummaryScreenLogic(
    source,
    selections,
    setSelections,
    handleBookingRQ,
    setIsEditMode,
    daySelections,
    typography
  );

  return (
    <>
      {isLoadingItinerary || (hasCancellationPolicy && cancellationPolicyAlertsLenght === 0) ? (
        <IonRow className={"ion-text-center"}>
          <IonCol>
            {hasCancellationPolicy && cancellationPolicyAlertsLenght === 0 ? (
              <Loader
                isOpen={
                  hasCancellationPolicy && cancellationPolicyAlertsLenght === 0
                }
                transparent
              />
            ) : (
              <BLLoadingContainer />
            )}
          </IonCol>
        </IonRow>
      ) : (
        <>
          {serviceDetails &&
            (isDesktop() ? (
              <BookedDetailsCardDesktop
                serviceDetails={serviceDetails}
                daySelections={extractDaySelection()}
                priceTxt={getTotalPriceSummaryText(
                  "summaryScreen",
                  serviceDetails.Price,
                  selections,
                  source,
                  isProcessingBookings,
                  typography
                )}
                headerTxt={"Your booking summary"}
              />
            ) : (
              <BookedDetailsCardMobile
                serviceDetails={serviceDetails}
                daySelections={extractDaySelection()}
                priceTxt={getTotalPriceSummaryText(
                  "summaryScreen",
                  serviceDetails?.Price,
                  selections,
                  source,
                  isProcessingBookings,
                  typography
                )}
                headerTxt={"Your booking summary"}
              />
            ))}
          {getActionButtonsRow()}
          <BLAlertPopUp
            title={`Cancel Booking`}
            description={replacePlaceholders(
              dynamicTexts?.Shared?.ActivitiesAndSpa
                ?.Popup_CancellationOfAllBookings,
              {
                SERVICE_NAME: serviceDetails?.Title,
              }
            )}
            isOpen={showCancelDialog}
            confirmHandler={() => {
              setShowCancelDialog(false);
              setHasCancellationPolicy(true);
              cancelAllBookings();
              modal.current?.dismiss();
            }}
            dismissHandler={() => {
              setShowCancelDialog(false);
              modal.current?.dismiss();
            }}
            confirmBtnLabel={"Confirm Cancellation"}
            dismissBtnLabel={"Back"}
            confirmBtnType={"primary"}
            dismissBtnType={"underlined"}
          />
        </>
      )}
    </>
  );
};

export default BookedSummaryScreen;
