import { IonCol, IonRow } from "@ionic/react";
import React from "react";
import { formatDate } from "../../../helpingFunctions/dateTimeUtilities";
import BLCheckBox from "../../../shared/Ionic/Boxes/CheckBox/BLCheckBox";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import { CONFLICT_TYPES } from "../conflictConstants";
import { retrieveConflictAlertMessage } from "../namingMethods/namingHelperMethods";
import { getConflictSelectionDetails } from "../resolveMethods/resolveMethods";
import { verifySpaSelectionReplaceCondition } from "./chainMethodsHelpers";
import { useDataContext } from "../../../context/DataContext";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../../../constants/constants";

const ChainMethodsLogic = (setDisableReplaceInChainedSpaView) => {
  const { conflictManagementTexts } = useDataContext();

  /** 
      Method for combining STRONG and AVERAGE conflicts (if atleast 2) in order to display a popup with check box selections
    */
  const chainConflictsResult = (
    conflictObj,
    reservationDetails,
    selection,
    configObj,
    isConfOccuringInSpa
  ) => {
    // Extract all soft conflicts. They would not change and will be shown one after the other
    const softConflicts = conflictObj.conflicts.filter(
      (row) => row.conflictType === CONFLICT_TYPES.SOFT
    );

    const restConflicts = conflictObj.conflicts.filter(
      (row) => row.conflictType !== CONFLICT_TYPES.SOFT
    );

    // Do NOT allow conflict to be handled like type CHAINED if its only 1 left
    if (restConflicts.length <= 1) {
      return conflictObj;
    }

    const chainedConflictObj = {
      conflictMsg: "",
      conflictType: CONFLICT_TYPES.CHAINED,
      conflictPriority: 1,
      isResolved: false,
      confirmedBookingDetails: restConflicts.map((row) => {
        return {
          ...row.confirmedBookingDetails,
          isSelected: false,
          originalConflictType: row.conflictType,
        };
      }),
    };

    const updatedConflictObj = {
      ...conflictObj,
      conflicts: [chainedConflictObj],
    };

    let hasMatchingNameSpaSel = false;
    if (isConfOccuringInSpa) {
      hasMatchingNameSpaSel = verifySpaSelectionReplaceCondition(
        restConflicts,
        selection,
        setDisableReplaceInChainedSpaView
      );
    }

    const chainedConflictMsg = retrieveConflictAlertMessage(
      null,
      selection,
      CONFLICT_TYPES.CHAINED,
      reservationDetails,
      canChainedConflictsBeResolved(chainedConflictObj, [updatedConflictObj], hasMatchingNameSpaSel),
      getExceededQuantity(chainedConflictObj, [updatedConflictObj]),
      // chainedConflictObj.confirmedBookingDetails.some(
      //   (row) => row.originalConflictType === CONFLICT_TYPES.STRONG
      // ),
      conflictManagementTexts,
      { ...configObj, hasMatchingNameSpaSel: hasMatchingNameSpaSel }
    );

    chainedConflictObj.conflictMsg = chainedConflictMsg;

    return {
      ...conflictObj,
      conflicts: [chainedConflictObj, ...softConflicts],
    };
  };

  /** 
            Method to construct chained conflicts checkbox row
          */
  const getChainedRowDetails = (
    obj,
    index,
    setSpotlitConflict,
    spotlitConflictCopy,
    canConflictsBeResolved
  ) => {
    let checkboxLabel = [];

    if (obj.originalConflictType === CONFLICT_TYPES.STRONG) {
      checkboxLabel.push(
        <BLParagraph
          txt="(late cancellation) "
          color="text-grey"
          //TODO: Unclear typography
          //newClasses={"bold"}
        />
      );
    }

    if (obj.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
      checkboxLabel.push(
        `${obj.Title} for 
        ${obj.ParticipantFirstName ? obj.ParticipantFirstName : obj.FirstName} 
        ${
  obj.ParticipantLastName ? obj.ParticipantLastName : obj.LastName
} on ${formatDate(obj.Date)} at ${obj.Time.substring(0, 5)}. `
      );
    } else {
      checkboxLabel.push(
        `${obj.Title} for ${obj.Quantity} adults on ${formatDate(
          obj.Date
        )} at ${obj.Time.substring(0, 5)}. `
      );
    }

    return (
      <IonRow key={`${obj.BookingNumber}_${obj.Id}`}>
        <IonCol className="bl-flex ion-align-items-center">
          <BLCheckBox
            checked={obj.isSelected}
            txt={checkboxLabel}
            labelColor="text-grey"
            newClasses="dining-party"
            labelNewClasses="ion-text-wrap"
            checkSlot="start"
            disabled={
              obj.originalConflictType === CONFLICT_TYPES.STRONG ||
              !canConflictsBeResolved
            }
            onClick={() => {
              spotlitConflictCopy.confirmedBookingDetails[index].isSelected =
                !spotlitConflictCopy.confirmedBookingDetails[index].isSelected;
              setSpotlitConflict(spotlitConflictCopy);
            }}
          />
        </IonCol>
      </IonRow>
    );
  };

  const shouldDisableChainedConflictReplaceBtn = (
    spotlitConflict,
    reservationDetails,
    foundConflicts,
    disableReplaceSpa
  ) => {
    const confirmedQuantityToRemain =
      getConfirmedQuantityToRemain(spotlitConflict);

    const confDetails = getConflictSelectionDetails(
      foundConflicts,
      spotlitConflict.confirmedBookingDetails[0]
    );

    // If the confict is occuring in spa and the chained conflicts are containing booked spa for the same person as the selection -
    // forbid replace until the user selects his other booking. All else is optional
    if (disableReplaceSpa) {
      let totalSameNameConfirmedSpaBookings = 0;
      let totalSameNameConfirmedSpaBookingsResolved = 0;

      spotlitConflict.confirmedBookingDetails.forEach((row) => {
        if (row.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
          if (row.isParticipant) {
            if (
              row.ParticipantFirstName === spotlitConflict.selFirstName &&
              row.ParticipantLastName === spotlitConflict.selLastName
            ) {
              totalSameNameConfirmedSpaBookings++;
              if (row.isSelected) {
                totalSameNameConfirmedSpaBookingsResolved++;
              }
            }
          } else {
            if (
              row.FirstName === spotlitConflict.selFirstName &&
              row.LastName === spotlitConflict.selLastName
            ) {
              totalSameNameConfirmedSpaBookings++;
              if (row.isSelected) {
                totalSameNameConfirmedSpaBookingsResolved++;
              }
            }
          }
        }
      });
      
      if (
        totalSameNameConfirmedSpaBookings ===
        totalSameNameConfirmedSpaBookingsResolved
      ) {
        return !(
          confirmedQuantityToRemain + confDetails.selectionQuantity <=
          reservationDetails.Adults
        );
      }

      return !(
        totalSameNameConfirmedSpaBookings ===
        totalSameNameConfirmedSpaBookingsResolved
      );
    } else {
      return !(
        confirmedQuantityToRemain + confDetails.selectionQuantity <=
        reservationDetails.Adults
      );
    }
  };

  const getExceededQuantity = (spotlitConflict, foundConflicts) => {
    const confirmedQuantityToRemain =
      getConfirmedQuantityToRemain(spotlitConflict);

    const confDetails = getConflictSelectionDetails(
      foundConflicts,
      spotlitConflict.confirmedBookingDetails[0]
    );

    return confirmedQuantityToRemain + confDetails.selectionQuantity;
  };

  const canChainedConflictsBeResolved = (spotlitConflict, foundConflicts, hasMatchingNameSpaSel) => {
    if (!spotlitConflict) return;
    let confirmedQuantityToRemain = 0;
    
    spotlitConflict.confirmedBookingDetails.forEach((row) => {
      if (row.originalConflictType !== CONFLICT_TYPES.STRONG) {
        if (row.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
          confirmedQuantityToRemain += 1;
        } else {
          confirmedQuantityToRemain += Number(row.Quantity);
        }
      }
    });

    const confDetails = getConflictSelectionDetails(
      foundConflicts,
      spotlitConflict.confirmedBookingDetails[0]
    );

    if (hasMatchingNameSpaSel) {
      let canBeResolved = true;
      
      spotlitConflict.confirmedBookingDetails.forEach((row) => {
        if (
          row.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA &&
          row.originalConflictType === CONFLICT_TYPES.STRONG
        ) {
          canBeResolved = false;
        }
      });

      return canBeResolved;
    } else {
      return confDetails.selectionQuantity <= confirmedQuantityToRemain;
    }
  };

  const getConfirmedQuantityToRemain = (spotlitConflict) => {
    let confirmedQuantityToRemain = 0;

    spotlitConflict.confirmedBookingDetails.forEach((row) => {
      if (row.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA) {
        if (!row.isSelected) {
          confirmedQuantityToRemain += 1;
        }
      } else {
        if (!row.isSelected) {
          confirmedQuantityToRemain += Number(row.Quantity);
        }
      }
    });

    return confirmedQuantityToRemain;
  };

  return {
    chainConflictsResult,
    getChainedRowDetails,
    shouldDisableChainedConflictReplaceBtn,
    canChainedConflictsBeResolved,
  };
};

export default ChainMethodsLogic;
