import { IonCol, IonRow } from "@ionic/react";
import React, { useContext } from "react";
import { useDataContext } from "../../../../../../context/DataContext";
import BLLoadingContainer from "../../../../../../shared/Ionic/BLLoadingContainer";
import BLInformationBox from "../../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import BLButton from "../../../../../../shared/Ionic/Buttons/BLButton";
import TimeRowSeparateSelectionLogic from "./Separate/TimeRowSeparateSelectionLogic";
import TimesRowLogic from "./TimesRowLogic";

import { format } from "date-fns";
import { ActivityContext } from "../../../../../../context/ActivityContext";
import {
  getCurrentDate,
  isDateWithinRange,
} from "../../../../../../helpingFunctions/dateTimeUtilities";
import { replacePlaceholders } from "../../../../../../helpingFunctions/replacePlaceholders";

const AvailableTimesForDate = ({ date, selIndex, selTimeIndex }) => {
  const {
    reservationDetails,
    dynamicTexts,
    isLoadingItinerary,
    itineraryDetails,
  } = useDataContext();
  const { activitySelections, selectedService } = useContext(ActivityContext);
  const { extractTimeSelections } = TimesRowLogic();
  const { handleSeparateSelection, isTimeSelected, getDayQtySelected } =
    TimeRowSeparateSelectionLogic();
  let availabilityAM = extractTimeSelections(date, "AM");
  let availabilityPM = extractTimeSelections(date, "PM");

  const isDisabled =
    date === getCurrentDate() &&
    itineraryDetails.Dates[date].Activity.find(
      (activity) => activity.Id === selectedService?.id
    ) &&
    activitySelections[selIndex].on[selTimeIndex].time !== "" &&
    activitySelections[selIndex].on[selTimeIndex].time
      .slice(0, 5)
      .replace(":", "") < format(new Date(), "kkmm");

  //Hide AM timeslots for the Arrival day
  if (date === reservationDetails.ArrivalDate.split("T")[0]) {
    availabilityAM = [];
  }

  if (
    !isDisabled &&
    date === getCurrentDate() &&
    availabilityAM &&
    availabilityPM &&
    typeof availabilityAM !== "string" &&
    typeof availabilityPM !== "string"
  ) {
    availabilityAM = availabilityAM.filter(
      (slot) =>
        slot.time.slice(0, 5).replace(":", "") > format(new Date(), "kkmm")
    );
    availabilityPM = availabilityPM.filter(
      (slot) =>
        slot.time.slice(0, 5).replace(":", "") > format(new Date(), "kkmm")
    );
  }

  const getNoAvailabilityForSelectionMsg = () => {
    if (!isDateWithinRange(reservationDetails.ArrivalDate, 90)) {
      return (
        <IonRow className={"bl-margin-t-s"}>
          <IonCol>
            <BLInformationBox
              content={replacePlaceholders(
                dynamicTexts?.Shared?.LackOfAvailability
                  ?.InfoBox_TimeSlot_NoAvailabilityOutside90DaysFromToday,
                {
                  PEOPLE_COUNT:
                    activitySelections[selIndex].on[selTimeIndex].quantity,
                }
              )}
              type={"warning"}
              markdown={
                !!dynamicTexts?.Shared?.LackOfAvailability
                  ?.InfoBox_TimeSlot_NoAvailabilityOutside90DaysFromToday
              }
            />
          </IonCol>
        </IonRow>
      );
    } else {
      return (
        <IonRow className={"bl-margin-t-s"}>
          <IonCol>
            <BLInformationBox
              content={replacePlaceholders(
                dynamicTexts?.Shared?.LackOfAvailability
                  ?.InfoBox_TimeSlot_NoAvailabilityWithin90DaysFromToday,
                {
                  PEOPLE_COUNT:
                    activitySelections[selIndex].on[selTimeIndex].quantity,
                }
              )}
              markdown={
                !!dynamicTexts?.Shared?.LackOfAvailability
                  ?.InfoBox_TimeSlot_NoAvailabilityWithin90DaysFromToday
              }
              type={"warning"}
            />
          </IonCol>
        </IonRow>
      );
    }
  };

  const notEnoughAvailability = (availabilityAM, availabilityPM) => {
    return (
      activitySelections[selIndex].on[selTimeIndex].quantity > 1 &&
      !availabilityAM?.some((row) => row.availability >= activitySelections[selIndex].on[selTimeIndex].quantity) &&
      !availabilityPM?.some((row) => row.availability >= activitySelections[selIndex].on[selTimeIndex].quantity)
    );
  };

  if (
    availabilityAM === "loading" ||
    availabilityPM === "loading" ||
    isLoadingItinerary
  ) {
    return (
      <IonRow className={"ion-text-center"}>
        <IonCol>
          <BLLoadingContainer />
        </IonCol>
      </IonRow>
    );
  } else if (availabilityAM === "passedDay" || availabilityPM === "passedDay") {
    return (
      <IonRow className={"bl-margin-t-s"}>
        <IonCol>
          <BLInformationBox
            content={
              dynamicTexts?.Shared?.LackOfAvailability
                ?.InfoBox_TimeSlot_PastTime
            }
            type={"warning"}
            markdown={true}
          />
        </IonCol>
      </IonRow>
    );
  } else if (availabilityAM?.length === 0 && availabilityPM?.length === 0) {
    return getNoAvailabilityForSelectionMsg();
  } else {
    return (
      <>
        {notEnoughAvailability(availabilityAM, availabilityPM) &&
          getNoAvailabilityForSelectionMsg()}
        {(availabilityAM?.length > 0 || availabilityPM?.length > 0) && (
          <>
            <IonRow className="bl-margin-t-m" style={{ gap: "16px" }}>
              {availabilityAM.map((obj) => (
                <BLButton
                  key={obj.shortTime}
                  content={`${obj.shortTime} AM`}
                  disabled={
                    isDisabled ||
                    (obj.availability <
                      getDayQtySelected(selIndex, selTimeIndex) &&
                      !isTimeSelected(obj, selIndex, selTimeIndex))
                  }
                  type={
                    isTimeSelected(obj, selIndex, selTimeIndex)
                      ? "time"
                      : "time-outlined"
                  }
                  onClick={() =>
                    handleSeparateSelection(obj, selIndex, selTimeIndex, "AM")
                  }
                  defaultCursor={
                    activitySelections[selIndex].on[selTimeIndex].action !==
                      "add" &&
                    activitySelections[selIndex].on[selTimeIndex].time ===
                      obj.time
                  }
                />
              ))}
              {availabilityPM.map((obj) => (
                <BLButton
                  key={obj.shortTime}
                  content={`${obj.shortTime} PM`}
                  disabled={
                    isDisabled ||
                    (obj.availability <
                      getDayQtySelected(selIndex, selTimeIndex) &&
                      !isTimeSelected(obj, selIndex, selTimeIndex))
                  }
                  type={
                    isTimeSelected(obj, selIndex, selTimeIndex)
                      ? "time"
                      : "time-outlined"
                  }
                  onClick={() =>
                    handleSeparateSelection(obj, selIndex, selTimeIndex, "PM")
                  }
                  defaultCursor={
                    activitySelections[selIndex].on[selTimeIndex].action !==
                      "add" &&
                    activitySelections[selIndex].on[selTimeIndex].time ===
                      obj.time
                  }
                />
              ))}
            </IonRow>
          </>
        )}
      </>
    );
  }
};

export default AvailableTimesForDate;
