import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { UNCATEGORIZED } from "../../../../../constants/constants";
import { SpaContext } from "../../../../../context/SpaContext";
import RequestHook from "../../../../../hooks/RequestHook";
import { ROUTE_VIEWS } from "../../../../../routes/routeViews";
import { NavigatorContext } from "../../../../../context/NavigatorContext";
import { useDataContext } from "../../../../../context/DataContext";

const SpaServicesLogic = (
  formatedSpaServices,
  setSpaIdExists,
  isFirstTimeBooker
) => {
  const location = useLocation();
  const { categoryId, spaId } = useParams();
  const { getSpaAvailability } = RequestHook();
  const [servicesToDisplay, setServicesToDisplay] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const { itineraryDetails } = useDataContext();

  const { 
    spaDetails, 
    setShowBookingSection, 
    setIsEditMode, 
    setConfirmationScreenSelections 
  } = useContext(SpaContext);

  useEffect(() => {
    setSelectedService(null);
    if (spaDetails) {
      let categoryExists;
      let redirectedTo;

      spaDetails.All?.forEach((item) => {
        if (spaId) {
          if (item.id === Number(spaId)) {
            redirectedTo = item;
            categoryExists = item.CategoryId === Number(categoryId);
          }
        } else {
          if (item.CategoryId === Number(categoryId)) {
            categoryExists = true;
          }
        }
      });
      redirect(redirectedTo, categoryExists);
    }
  }, [spaId, spaDetails?.All]);

  const loadSpaAvailability = useMemo(() => {
    if (selectedService && itineraryDetails) {
      return true;
    }
  }, [selectedService, itineraryDetails]);
  
  useEffect(() => {
    if (loadSpaAvailability) {
      getSpaAvailability(selectedService.id);
    }
  }, [loadSpaAvailability]);

  useEffect(() => {
    if (formatedSpaServices) {
      setServicesToDisplay(formatedSpaServices);
    }
  }, [formatedSpaServices]);

  const redirect = (redirectedTo, categoryExists) => {
    if (
      redirectedTo &&
      (redirectedTo.CategoryId === Number(categoryId) ||
        categoryId === UNCATEGORIZED)
    ) {
      setSpaIdExists(true);
      setSelectedService(redirectedTo);
      setShowBookingSection(true);
      if (location?.state?.isItineraryRedirect && !isFirstTimeBooker) {
        setIsEditMode(true);
      }
    } else {
      setSpaIdExists(false);
      setSelectedService(null);
      setShowBookingSection(false);
      setIsEditMode(false);
      setConfirmationScreenSelections(null);
      if (categoryId && !categoryExists) {
        redirectBetweenPages(ROUTE_VIEWS.NOT_FOUND);
      }
    }
  };

  return {
    servicesToDisplay,
    selectedService,
    setSelectedService,
  };
};

export default SpaServicesLogic;
