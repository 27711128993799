import React, { useEffect } from "react";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./css/app.module.scss";

// custom theme css
import "./theme/variables.scss";
import "./theme/theme.scss";
import "./theme/fonts.scss";
import "./theme/bl-dimensions.scss";
import "./theme/bl-custom.scss";
import "./theme/ion-overrides.scss";
import AppUrlListener from "./components/AppUrlListener/AppUrlListener";
import AppRoutes from "./routes/AppRoutes";
import { IonReactRouter } from "@ionic/react-router";
import BLLoader from "./shared/Ionic/Loader/BLLoader";
import { PageLoaderProvider } from "./context/PageLoaderContext";

// import useThemeDetector from "./hooks/useThemeDetector";

setupIonicReact();

// const lightSchemeIcon = document.querySelector("link#light-scheme-icon");
// const darkSchemeIcon = document.querySelector("link#dark-scheme-icon");

const App = () => {
  // const isDarkTheme = useThemeDetector();

  // useEffect(() => {
  //   if (isDarkTheme) {
  //     lightSchemeIcon?.remove();
  //     document.head.append(darkSchemeIcon);
  //   } else {
  //     document.head.append(lightSchemeIcon);
  //     darkSchemeIcon?.remove();
  //   }
  // }, [isDarkTheme]);

  window.addEventListener('keyboardDidShow', () => {
    document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  });

  useEffect(() => {
    setTimeout(() => {
      const doc = document.querySelector(".wcv-panel");
      if (doc) {
        doc.style.display = "none";
      }
    }, 100);
  }, []);

  return (
    <IonApp>
      <PageLoaderProvider>
        <BLLoader />
        <IonReactRouter>
          <IonRouterOutlet
            ionPage
            style={{ display: "flex", flexDirection: "column" }}
          >
            <AppUrlListener />
            <AppRoutes />
          </IonRouterOutlet>
        </IonReactRouter>
      </PageLoaderProvider>
    </IonApp>
  );
};

export default App;
