import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import CreateDimensions from "../../../../../helpingFunctions/DimensionsFactory";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";

import { checkmark, location, time } from "ionicons/icons";
import { INITIAL_DATE } from "../../../../../constants/constants";
import { useDataContext } from "../../../../../context/DataContext";
import { formatTime } from "../../../../../helpingFunctions/dateTimeUtilities";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import { EntertainmentsPageContext } from "../../EntertainmentsPageContext";
import { LAST_FEW_REMAINING_BREAKPOINT } from "../../constants";
import styles from "./ShowsCards.module.scss";
import { useTheme } from "../../../../../hooks/useTheme";
import BLLink from "../../../../../shared/Ionic/Links/BLAnchor/BLLink";

const btnProps = {
  deselect: {
    txt: "Deselect",
    type: "outlined",
  },
  select: {
    txt: "Select",
    type: "primary",
  },
};

const ShowsExpandedCard = ({
  title,
  timeText,
  locationText,
  description,
  image,
  handleSelection,
  selectedEntertainmentShow,
  setShowLocationDetails,
  setShowAdditionalDetails,
  isDisabled,
  availability,
  isAlreadyBooked
}) => {
  const { typography } = useTheme();
  const { entertainmentBookingSelections, selectedDate } = useContext(
    EntertainmentsPageContext
  );

  const { dynamicTexts, reservationDetails } = useDataContext();

  const { dimensions: commonDimensionsStyles } = CreateDimensions({
    padding: ["l-xs", "r-xs"],
  });

  const isLastFew = reservationDetails.Adults <= availability && availability <= LAST_FEW_REMAINING_BREAKPOINT;
  const isNotAvailable = reservationDetails.Adults > availability && !isAlreadyBooked;

  const isSelected =
    entertainmentBookingSelections.findIndex(
      (el) =>
        el.time === selectedEntertainmentShow.Time &&
        el.venueId === selectedEntertainmentShow.venueId &&
        el.date === selectedDate &&
        el.action !== "remove"
    ) !== -1;

  const buttonType = isSelected ? "deselect" : "select";
  const btn = btnProps[buttonType];

  const CheckTriangle = (
    <>
      <span className={styles.BLItem_check}>
        <IonIcon className={styles.BLItemCheckIcon} icon={checkmark} />
      </span>
      <span className={styles.BLItem_triangle}></span>
    </>
  );

  return (
    <BLContentCardWrapper
      background="primary"
      margin={0}
      dimensions={{ padding: [] }}
      width="100%"
      newStyles={{boxShadow: isSelected ? "0px 0px 0px 2px var(--ion-color-secondary)" : "none" }}
    >
      <IonGrid style={{ background: "white"}}>
        <IonRow
          className={` on-justify-content-between ion-align-items-center ion-no-padding`}
        >
          <IonCol style={{ height: "200px", width: "200px" }}>
            {image && <BLImageCarousel content={image}/>}
          </IonCol>
        </IonRow>
        <IonRow
          className={`${commonDimensionsStyles} ion-justify-content-between ion-align-items-center`}
        >
          <IonCol
            size="12"
            className={`${styles.ShowsExpandedCenterText} ${
              title.length > 36 ? "bl-padding-r-s" : ""
            }`}
          >
            <BLParagraph
              txt={title}
              textVariant={typography.h2}
              // newClasses={`crimson-pro bold large`}
              //TODO: Unclear typography
              color="primary"
            />
          </IonCol>
        </IonRow>
        <IonRow className="bl-margin-xs">
          <IonCol
            className="bl-flex ion-align-items-center ion-justify-content-center"
          >
            <BLIcon color="primary" iconSource={time} />
            <BLParagraph
              color="text-grey"
              txt={formatTime(`${INITIAL_DATE}T${timeText.slice(0, 5)}`, true)}
              textVariant={typography.BODY_TEXT}
            />
          </IonCol>
          <IonCol
            className="bl-flex ion-align-items-center ion-justify-content-center"
          >
            <BLIcon color="primary" iconSource={location} />
            <BLLink
              txt={locationText}
              onClick={() => setShowLocationDetails(true)}
            />
          </IonCol>
        </IonRow>
        <IonRow
          className={`${commonDimensionsStyles} bl-padding-t-m ion-justify-content-between`}
        >
          <IonCol size="12" className="bl-padding-r-s">
            <BLParagraph
              color="text-grey"
              txt={description || ""}
              textVariant={typography.BODY_TEXT}
              typographyStyles={{ display: "inline" }}
              ellipsisOn3rdRow
            />
          </IonCol>
        </IonRow>
        <IonRow
          className={`${commonDimensionsStyles} ion-justify-content-between`}
        >
          <IonCol size="12" className="bl-padding-r-s">
            <BLLink
              txt="Read more"
              onClick={() => setShowAdditionalDetails(true)}
            />
          </IonCol>
        </IonRow>

        <IonRow className="ion-justify-content-center">
          <IonCol size="auto">
            <BLButton
              content={btn.txt}
              type={btn.type}
              disabled={isDisabled() || isNotAvailable}
              dimensions={{ margin: ["t-xs", "b-xs"] }}
              onClick={() => handleSelection()}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      {isSelected && CheckTriangle}
      {(isLastFew || isNotAvailable) && 
        <div 
          className={`${styles.BLItem_lastfew_banner} bl-text-x-small`}
          style={{height: "40px", borderTopRadius: "none"}}
        >
          {isLastFew 
            ? dynamicTexts?.Shared?.Banners?.Banner_Card_LimitedAvailability
            : dynamicTexts?.Shared?.Banners?.Banner_Card_NoAvailabilityWithin90DaysFromToday}
        </div>
      }
    </BLContentCardWrapper>
  );
};

export default ShowsExpandedCard;
