import {
  IonButton,
  IonButtons,
  IonCol,
  IonHeader,
  IonMenuToggle,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { menu } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AUTHENTICATED_KEY } from "../../constants/constants";

import { SideMenuContext } from "../../context/SideMenuContext";
import ValidateLoginHook from "../../hooks/ValidateLoginHook";
import BLIcon from "../../shared/Ionic/BLIcon";
import BLParagraph from "../../shared/Ionic/Paragraph/BLParagraph";
import styles from "./Header.module.scss";
import { isDesktop } from "../../helpingFunctions/utilities";
import { useTheme } from "../../hooks/useTheme";

const Header = ({ isLoadingDeeplink }) => {
  const { typography } = useTheme();
  const { logout } = ValidateLoginHook();
  const { pathname } = useLocation();

  const isWhatsOn = pathname.includes("whatson");
  const [showActions, setShowActions] = useState(true);
  const isLoggedIn = localStorage.getItem(AUTHENTICATED_KEY);
  const { fullScreenOverlayContent, fullScreenMap, legalDocDialogType } =
    useContext(SideMenuContext);

  useEffect(() => {
    if (isLoadingDeeplink || isWhatsOn || !isLoggedIn) {
      setShowActions(false);
    } else {
      setShowActions(true);
    }
  }, [isLoadingDeeplink, isWhatsOn, isLoggedIn]);

  const logo = (
    <IonCol
      size={showActions ? "6" : ""}
      className="bl-flex ion-justify-content-center ion-text-center"
    >
      <BLParagraph
        txt="My Warner Stay"
        textVariant={typography.h2}
        newClasses="force-center"
      />
    </IonCol>
  );

  return (
    <IonHeader className={styles.MainTitleNew}>
      <IonToolbar color="primary">
        {showActions ? (
          <IonRow className="bl-flex">
            <IonCol size="3" className="bl-flex">
              {!fullScreenOverlayContent &&
              !legalDocDialogType &&
              !fullScreenMap && (
                <IonButtons>
                  <IonButtons>
                    <IonMenuToggle>
                      <IonButton>
                        <IonCol className={styles.MainTitleNew_iconWrapper}>
                          <BLIcon
                            newClasses={styles.MainTitleNew_icon}
                            iconSource={menu}
                            iconSize="lg"
                            newStyles={{ marginBottom: 2 }}
                          />
                        </IonCol>
                      </IonButton>
                    </IonMenuToggle>
                  </IonButtons>
                </IonButtons>
              )}
            </IonCol>
            {logo}
            <IonCol size="3" className="bl-flex ion-justify-content-end">
              {isDesktop() && (
                <>
                  <IonButton
                    onClick={logout}
                    className={styles.MainTitleNew_button}
                    size="small"
                  >
                    Logout
                  </IonButton>
                </>
              )}
            </IonCol>
          </IonRow>
        ) : (
          logo
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
