import { useContext } from "react";
import { ActivityContext } from "../../../../../../../context/ActivityContext";
import { useDataContext } from "../../../../../../../context/DataContext";

const DaysRowSeparateSelectionLogic = () => {
  const {
    activityAvailability,
    activitySelections,
    setActivitySelections,
    setActivityAvailability,
    handleAvailabilityOnSelection,
    selectedService
  } = useContext(ActivityContext);
  const { reservationDetails } = useDataContext();
  const handleSeparateSelection = (selIndex, dateIndex, val) => {
    const activitySelectionsCopy = JSON.parse(
      JSON.stringify(activitySelections)
    );
    const activityAvailabilityCopy = JSON.parse(
      JSON.stringify(activityAvailability)
    );

    activitySelectionsCopy[selIndex].on[dateIndex].isEdited = false;

    // Adjust availability
    const day = activitySelectionsCopy[selIndex].on[dateIndex].date;
    const time = activitySelectionsCopy[selIndex].on[dateIndex].time;
    const quantity = activitySelectionsCopy[selIndex].on[dateIndex].quantity;

    if (time !== "") {
      const dayTime = time.substring(0, 2) >= "12" ? "PM" : "AM";
      handleAvailabilityOnSelection(
        activityAvailabilityCopy,
        day,
        dayTime,
        time,
        "increment",
        selectedService?.id,
        quantity
      );
    }

    const firtSelectedDayQuantity = activitySelectionsCopy[selIndex].on[0].quantity;

    activitySelectionsCopy[selIndex].on[dateIndex].date = val.target.value;
    activitySelectionsCopy[selIndex].on[dateIndex].time = "";
    activitySelectionsCopy[selIndex].on[dateIndex].quantity = firtSelectedDayQuantity;

    if (
      activitySelectionsCopy[selIndex].action !== "add" &&
      activitySelectionsCopy[selIndex].on[dateIndex].action !== "add"
    ) {
      activitySelectionsCopy[selIndex].action = "edit";
      activitySelectionsCopy[selIndex].on[dateIndex].action = "edit";
    }

    setActivityAvailability(activityAvailabilityCopy);
    setActivitySelections(activitySelectionsCopy);
  };

  const addSeparateDays = (selIndex) => {
    const activitySelectionsCopy = JSON.parse(
      JSON.stringify(activitySelections)
    );
    activitySelectionsCopy[selIndex].on.push({
      date: "",
      time: "",
      bookedDate: "",
      bookedTime: "",
      action: "add",
      quantity: 1,
      bookedQuantity: "",
    });
    setActivitySelections(activitySelectionsCopy);
  };

  const removeSeparateSelection = (selIndex, dateIndex) => {
    const activitySelectionsCopy = JSON.parse(
      JSON.stringify(activitySelections)
    );

    // Adjust availability
    const day = activitySelectionsCopy[selIndex].on[dateIndex].date;
    const time = activitySelectionsCopy[selIndex].on[dateIndex].time;
    const quantity = activitySelectionsCopy[selIndex].on[dateIndex].quantity;

    if (time !== "") {
      const activityAvailabilityCopy = JSON.parse(
        JSON.stringify(activityAvailability)
      );
      const dayTime = time.substring(0, 2) >= "12" ? "PM" : "AM";
      handleAvailabilityOnSelection(
        activityAvailabilityCopy,
        day,
        dayTime,
        time,
        "increment",
        selectedService?.id,
        quantity
      );
      setActivityAvailability(activityAvailabilityCopy);
    }

    if (activitySelectionsCopy[selIndex].on[dateIndex].action === "add") {
      if (dateIndex === 0) {
        activitySelectionsCopy[selIndex].isEdited = false;
        activitySelectionsCopy[selIndex].on[dateIndex].date = "";
        activitySelectionsCopy[selIndex].on[dateIndex].time = "";
        activitySelectionsCopy[selIndex].on[dateIndex].quantity = 1;
      } else {
        activitySelectionsCopy[selIndex].on = activitySelectionsCopy[
          selIndex
        ].on.filter((row, index) => index !== dateIndex);
      }
    } else {
      activitySelectionsCopy[selIndex].on[dateIndex].action = "remove";
    }

    setActivitySelections(activitySelectionsCopy);
  };

  const hasDateSelected = (selectionIndex, dateIndex = 0) => {
    return activitySelections[selectionIndex].on[dateIndex].date !== "";
  };

  const updateStatus = (selIndex, dateIndex) => {
    const activitySelectionsCopy = JSON.parse(
      JSON.stringify(activitySelections)
    );
    activitySelectionsCopy[selIndex].on[dateIndex].isEdited = true;
    setActivitySelections(activitySelectionsCopy);
  };

  const updateGuestCount = (type, selIndex, dateIndex) => {
    const selectionsCopy = JSON.parse(JSON.stringify(activitySelections));
    let count = selectionsCopy[selIndex].on[dateIndex].quantity;

    if (count < 1 || count > reservationDetails.Adults) return;
    if (selectionsCopy[selIndex].on[dateIndex].time !== "") {
      // Adjust availability
      const activityAvailabilityCopy = JSON.parse(
        JSON.stringify(activityAvailability)
      );
      const day = selectionsCopy[selIndex].on[dateIndex].date;
      const time = selectionsCopy[selIndex].on[dateIndex].time;
      const quantity = selectionsCopy[selIndex].on[dateIndex].quantity;
      const dayTime = time.substring(0, 2) >= "12" ? "PM" : "AM";

      handleAvailabilityOnSelection(
        activityAvailabilityCopy,
        day,
        dayTime,
        time,
        "increment",
        selectedService?.id,
        quantity
      );
      selectionsCopy[selIndex].on[dateIndex].time = "";

      if (selectionsCopy[selIndex].on[dateIndex].action !== "add") {
        selectionsCopy[selIndex].on[dateIndex].action = "edit";
      }
      setActivityAvailability(activityAvailabilityCopy);
    }

    if (type === "minus") {
      count = count - 1;
    } else {
      count = count + 1;
    }
    selectionsCopy[selIndex].on[dateIndex].quantity = count;
    setActivitySelections(selectionsCopy);
  };

  return {
    hasDateSelected,
    handleSeparateSelection,
    addSeparateDays,
    removeSeparateSelection,
    updateStatus,
    updateGuestCount,
  };
};

export default DaysRowSeparateSelectionLogic;
