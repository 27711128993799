import React from "react";
import { IonCol, IonRow } from "@ionic/react";

const TabPanel = ({ value, newClasses, children }) => {
  return (
    <IonRow
      role="tabpanel"
      className={newClasses}
    >
      <IonCol className="ion-text-align-center bl-padding-s">
        {value && children}
      </IonCol>
    </IonRow>
  );
};

export default TabPanel;
