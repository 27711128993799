import React, { useContext, useEffect, useState } from "react";
import { IonGrid, IonCol, IonRow } from "@ionic/react";
import {
  getCurrentDate,
  getDayOfWeek,
} from "../../../../../../helpingFunctions/dateTimeUtilities";

import { useDataContext } from "../../../../../../context/DataContext";
import { EntertainmentsPageContext } from "../../../EntertainmentsPageContext";

import BLGeneralSlider from "../../../../../../shared/Ionic/Carousel/BLGeneralSlider";
import BLContentCardWrapper from "../../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import DaysLogic from "./DaysLogic";
import BLCheckBox from "../../../../../../shared/Ionic/Boxes/CheckBox/BLCheckBox";

import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLLoadingContainer from "../../../../../../shared/Ionic/BLLoadingContainer";
import BLButton from "../../../../../../shared/Ionic/Buttons/BLButton";
import { format } from "date-fns";
import { isDesktop } from "../../../../../../helpingFunctions/utilities";

const Days = ({ viewType }) => {
  const {
    prepareSelectionObjects,
    skippedDays,
    selectedDate,
    entertainmentBookingSelections,
    mobileDaySlider,
    setMobileDaySlider,
  } = useContext(EntertainmentsPageContext);

  const {
    entertainmentDates,
    handleDateChange,
    handleNoBookingNeeded,
    shouldDisableDesktop,
  } = DaysLogic(viewType);

  const {
    isLoadingItinerary,
    isLoadingAvailability,
    dynamicTexts,
    itineraryDetails,
  } = useDataContext();
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  const shouldHighlight = (date) => {
    return selectedDate === date;
  };

  const shouldDisableCheckbox = () => {
    const result =
      getCurrentDate() === selectedDate &&
      !!itineraryDetails?.Dates[selectedDate]?.Entertainment.find(
        (booking) =>
          booking.Time.slice(0, 5).replace(":", "") <=
          format(new Date(), "kkmm")
      );
    setCheckboxDisabled(result);
  };

  useEffect(() => {
    prepareSelectionObjects();
    shouldDisableCheckbox();
  }, []);

  useEffect(() => {
    shouldDisableCheckbox();
  }, [entertainmentDates]);

  return (
    <BLContentCardWrapper dimensions={{ margin: ["t-m"] }} center={!isDesktop()}>
      {isLoadingItinerary || isLoadingAvailability ? (
        <IonRow className="ion-text-center">
          <IonCol>
            <BLLoadingContainer />
          </IonCol>
        </IonRow>
      ) : (
        <IonGrid style={!isDesktop() ? { maxWidth: "310px" } : {}}>
          <IonRow>
            {isDesktop() ? (
              <IonCol size="12">
                <IonGrid>
                  <IonRow style={{ gap: "10px" }}>
                    {entertainmentDates?.map((date, index) => (
                      <BLButton
                        key={`day_button_${date.date}`}
                        content={getDayOfWeek(date.date, "E")}
                        onClick={() => handleDateChange(date)}
                        type={
                          shouldHighlight(date.date, index)
                            ? "primary"
                            : "outlined"
                        }
                        newStyles={{ minWidth: "80px", borderRadius: "7px" }}
                        disabled={shouldDisableDesktop(date.date)}
                      />
                    ))}
                  </IonRow>
                </IonGrid>
              </IonCol>
            ) : (
              <IonCol
                className="bl-flex bl-padding-xs"
                style={{ background: "white" }}
                size="12"
              >
                <BLGeneralSlider
                  sliderOptions={entertainmentDates}
                  slidesRef={mobileDaySlider}
                  setSlidesRef={setMobileDaySlider}
                  getActiveObject={handleDateChange}
                  disablePrev={
                    entertainmentDates[0]?.date === selectedDate ||
                    selectedDate === getCurrentDate()
                  }
                  disableNext={
                    (entertainmentBookingSelections?.findIndex(
                      (el) => el.date === selectedDate
                    ) === -1 &&
                      !skippedDays.includes(selectedDate)) ||
                    selectedDate ===
                    entertainmentDates[entertainmentDates.length - 1]?.date
                  }
                />
              </IonCol>
            )}
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <BLContentCardWrapper
                dimensions={{ margin: ["t-s"], padding: ["b-m"] }}
              >
                {isDesktop() ? (
                  <IonRow>
                    <IonCol
                      size="auto"
                      className="bl-flex ion-align-items-center"
                    >
                      <BLCheckBox
                        txt={dynamicTexts?.Shared?.DiningAndEntertainment?.Label_DaySelection_NoBookingNeededCheckBox}
                        labelColor="text-grey"
                        newClasses="dining-party"
                        labelNewClasses="regular"
                        checked={skippedDays.includes(selectedDate)}
                        onClick={() => handleNoBookingNeeded()}
                        checkSlot="start"
                        newStyles={{ minWidth: "fit-content" }}
                        disabled={checkboxDisabled}
                      />
                      {skippedDays.includes(selectedDate) && (
                        <BLParagraph
                          txt={
                            dynamicTexts?.Shared?.DiningAndEntertainment?.Paragraph_DaySelection_NoBookingNeededExplicitInfo
                          }
                        //newClasses="light"
                        //TODO: Unclear typography
                        />
                      )}
                    </IonCol>
                  </IonRow>
                ) : (
                  <>
                    <IonRow className="ion-justify-content-center">
                      <IonCol size="auto">
                        <BLCheckBox
                          txt={dynamicTexts?.Shared?.DiningAndEntertainment?.Label_DaySelection_NoBookingNeededCheckBox}
                          labelColor="text-grey"
                          newClasses="dining-party"
                          labelNewClasses="light"
                          checked={skippedDays.includes(selectedDate)}
                          onClick={handleNoBookingNeeded}
                          checkSlot="start"
                          disabled={checkboxDisabled}
                        />
                      </IonCol>
                    </IonRow>
                    {skippedDays.includes(selectedDate) && (
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <BLParagraph
                            txt={
                              dynamicTexts?.Shared?.DiningAndEntertainment?.Paragraph_DaySelection_NoBookingNeededExplicitInfo
                            }
                          />
                        </IonCol>
                      </IonRow>
                    )}
                  </>
                )}
              </BLContentCardWrapper>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </BLContentCardWrapper>
  );
};

export default Days;
