import { IonGrid, IonItem, IonLabel, IonRow } from "@ionic/react";
import React from "react";
import CreateDimensions from "../../../helpingFunctions/DimensionsFactory";
import BLIcon from "../BLIcon";
import BLButton from "../Buttons/BLButton";
import BLLabel from "./BLLabel";

const BLNumberInput = ({
  label,
  value,
  min = 0,
  max = 10,
  labelDimensions,
  inputDimensions = {
    margin: ["t-xs", "b-xs"],
  },
  selectRowIconSize,
  selectRowIconSource,
  beforeIconSource,
  beforeIconOnClick,
  minFunc,
  labelLeftMargin,
  plusFunc,
  itemBackground = "white",
  newClasses,
  center,
  numberFieldWidth,
  disableBtns,
}) => {
  const inputDimensionStyles = CreateDimensions(inputDimensions);

  return (
    <IonGrid>
      {label && (
        <IonRow className="ion-text-left">
          <BLLabel
            label={label}
            labelDimensions={labelDimensions}
            beforeIconSource={beforeIconSource}
            beforeIconOnClick={beforeIconOnClick}
            labelLeftMargin={labelLeftMargin}
          />
        </IonRow>
      )}
      <IonRow
        className={`ion-align-items-center ${
          center ? "ion-justify-content-center" : ""
        } ${inputDimensionStyles.dimensions}`}
      >
        {selectRowIconSource && (
          <BLIcon
            iconSource={selectRowIconSource}
            iconSize={selectRowIconSize}
          />
        )}

        <IonItem
          className={`ion-no-padding ${newClasses}`}
          style={{ minWidth: "95px" }}
          lines="none"
          color={itemBackground}
        >
          <BLButton
            type="secondary"
            slot="start"
            disabled={value === min || disableBtns}
            onClick={minFunc}
            size="small"
            content="-"
          />
          <IonLabel color="text-grey" className={`ion-text-center bl-padding-l-xs bl-padding-r-xs`} style={{width: numberFieldWidth}}>
            {value}
          </IonLabel>
          <BLButton
            type="secondary"
            slot="end"
            disabled={value === max || disableBtns}
            onClick={plusFunc}
            size="small"
            content="+"
          />
        </IonItem>
      </IonRow>
    </IonGrid>
  );
};

export default BLNumberInput;
