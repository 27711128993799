import React from "react";
import styles from "../BLMarkdown.module.scss";

const MarkdownQuote = ({children}) => {

  return (
    <blockquote
      className={styles.MarkdownQuote}
    >
      {children}
    </blockquote>
  );

};

export default MarkdownQuote;