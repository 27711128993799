import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { useDataContext } from "../../../context/DataContext";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import BLInformationBox from "../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import BookingFolioLogic from "./BookingFolioLogic";
import FolioExpandableBox from "./components/FolioExpandableBox";
import FolioSummaryBox from "./components/FolioSummaryBox";
import ShortFolioInfoBox from "./components/ShortFolioInfoBox";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { SERVICES_TYPES } from "../../../constants/constants";
import { useTheme } from "../../../hooks/useTheme";
import ServicePageHeader from "../../../shared/Ionic/Wrappers/ServicePageHeader";

const BookingFolio = () => {
  const { typography } = useTheme();
  const { dynamicTexts, isLoadingHotelData } = useDataContext();
  const { folioDetails, isLoadingFolio } = BookingFolioLogic();
  const { warnerServices } =
    useContext(NavigatorContext);
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);

  useEffect(() => {
    if (isLoadingFolio || warnerServices?.length === 0 || isLoadingHotelData) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Room Account Page");
    } else {
      setIsLoaderOpened(false);
    }
  }, [isLoadingFolio, warnerServices, isLoadingHotelData]);

  return (
    <IonPage style={{ overflow: "auto" }}>
      <ServicePageHeader service={SERVICES_TYPES.ROOM_ACCOUNT} />
      {!isLoadingFolio && (
        <IonGrid className="bl-padding-s">
          <IonRow className="bl-margin-b-s">
            <IonCol>
              <BLInformationBox
                markdown
                type={!folioDetails.FolioDetailInfo ? "warning" : "info"}
                content={
                  !folioDetails.FolioDetailInfo
                    ? "Sorry, something went wrong while retrieving your folio information. Please try again later!"
                    : folioDetails.FolioDetailInfo &&
                      Object.entries(folioDetails.FolioDetailInfo).length > 0
                      ? dynamicTexts?.RoomAccount?.InfoBox_Page_HasPostings
                      : dynamicTexts?.RoomAccount?.InfoBox_Page_NoPostings
                }
              />
            </IonCol>
          </IonRow>

          {folioDetails.FolioDetailInfo &&
            Object.entries(folioDetails.FolioDetailInfo).length > 0 && (
            <>
              <IonRow>
                <IonCol>
                  <ShortFolioInfoBox folioNumber={folioDetails.FolioNumber} />
                </IonCol>
              </IonRow>
              <IonRow className={"bl-margin-t-s"}>
                <IonCol>
                  <BLContentCardWrapper
                    dimensions={{
                      padding: ["t-xs", "s", "b-m"],
                      margin: ["b-s"],
                    }}
                  >
                    <IonGrid>
                      <IonRow className={"ion-text-center bl-padding-b-xs"}>
                        <IonCol>
                          <BLParagraph
                            txt={"Charges per date"}
                            color="primary"
                            textVariant={typography.h3}
                            // newClasses={`medium bold crimson-pro`}
                            //TODO: Unclear typography
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        {folioDetails.FolioDetailInfo &&
                            Object.entries(folioDetails.FolioDetailInfo).map(
                              ([key, value]) => {
                                return (
                                  <IonCol key={key} size="12">
                                    <FolioExpandableBox
                                      keyVal={key}
                                      value={value}
                                    />
                                  </IonCol>
                                );
                              }
                            )}
                      </IonRow>
                    </IonGrid>
                  </BLContentCardWrapper>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <FolioSummaryBox folioDetails={folioDetails} />
                </IonCol>
              </IonRow>
            </>
          )}
        </IonGrid>
      )}
    </IonPage>
  );
};

export default BookingFolio;
