import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";

import { useDataContext } from "../../../context/DataContext";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import { ItineraryProvider } from "./context";

import { Tab, Tabs } from "@mui/material";
import { MapImagePopupContext } from "../../../context/MapImagePopupContext";
import styles from "./Itinerary.module.scss";
import ItineraryLogic from "./ItineraryLogic";
import CalendarLinks from "./components/CalendarLinks/CalendarLinks";
import Header from "./components/Header/ItineraryHeader";
import MapPopUp from "./components/MapPopUp/MapPopUp";
import { ITINERARY_TABS } from "./constants";
import TabContent from "./components/TabContent/TabContent";
import RequestHook from "../../../hooks/RequestHook";

const Itinerary = () => {
  const {
    itinerary,
    calendarLinksData,
    setCalendarLinksData,
    filterItinerary,
  } = ItineraryLogic();
  const { isLoadingItinerary, reservationDetails } = useDataContext();
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const { mapImage } = useContext(MapImagePopupContext);
  const { getGroup } = RequestHook();

  const [currentTab, setCurrentTab] = useState(ITINERARY_TABS[0].value);
  const [filteredItinerary, setFilteredItinerary] = useState(itinerary);

  useEffect(() => {
    getGroup();
  }, []);

  useEffect(() => {
    if (isLoadingItinerary || !reservationDetails) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Itinerary");
    } else {
      setIsLoaderOpened(false);
    }
  }, [isLoadingItinerary, itinerary, reservationDetails]);

  useEffect(() => {
    const filteredItineraryTab = filterItinerary(itinerary, currentTab);
    setFilteredItinerary(filteredItineraryTab);
  }, [currentTab, itinerary]);

  const getSvgColor = (tabKey) =>
    currentTab === tabKey ? "var(--ion-color-tertiary)" : "#153246";

  return (
    <ItineraryProvider setCalendarLinksData={setCalendarLinksData}>
      <IonPage style={{ overflow: "auto" }}>
        {mapImage.url && <MapPopUp />}
        <CalendarLinks
          calendarLinksData={calendarLinksData}
          setCalendarLinksData={setCalendarLinksData}
        />
        {!isLoadingItinerary && reservationDetails && (
          <IonGrid
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100%",
            }}
          >
            <IonRow className={styles.header}>
              <Header />
            </IonRow>
            <IonRow className={styles.tabs}>
              <Tabs
                variant="scrollable"
                value={currentTab}
                TabIndicatorProps={{
                  style: { transition: "none" },
                }}
                onChange={(e, value) => setCurrentTab(value)}
                sx={{
                  "& .MuiButtonBase-root": {
                    minHeight: "60px",
                  },
                  "& .MuiButtonBase-root.MuiTab-root": {
                    color: "var(--ion-color-primary)",
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: 600,
                    padding: "0 !important",
                  },
                  "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                    color: "var(--ion-color-tertiary) !important",
                    textDecoration: "underline",
                    background: "#fff",
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    borderBottom: "0px !important",
                    border: 1,
                  },
                  "& .MuiTabs-indicator": {
                    visibility: "hidden !important",
                    backgroundColor: "var(--ion-color-tertiary) !important",
                  },
                }}
              >
                {ITINERARY_TABS.map(({ label, value, icon }) => {
                  return (
                    <Tab
                      icon={icon(28, getSvgColor(value))}
                      key={value}
                      value={value}
                      label={label}
                    />
                  );
                })}
              </Tabs>
            </IonRow>
            <IonRow className={styles.tab}>
              <IonCol>
                {filteredItinerary.length > 0 && (
                  <TabContent
                    itinerary={filteredItinerary}
                    currentTab={currentTab}
                  />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonPage>
    </ItineraryProvider>
  );
};

export default Itinerary;
