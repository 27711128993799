import { useEffect, useState } from "react";
import { useDataContext } from "../../../context/DataContext";

import axiosInstance from "../../../url/createAxios";
import { TCLink } from "../../../constants/constants";

const LoginLogic = () => {
  const instance = axiosInstance();
  const { loginPageData, setLoginPageData } = useDataContext();

  const [isLoading, setIsLoading] = useState(false);

  const getLogInPageData = async () => {
    try {
      setIsLoading(true);
      const { data } = await instance.get("/pages-configuration/login-page");

      const filteredHotels = data.Hotels;
      data.Hotels = filteredHotels?.sort((a, b) =>
        a.Title.toLowerCase().localeCompare(b.Title.toLowerCase())
      );

      setLoginPageData(data);
      localStorage.setItem(TCLink, data?.TermsAndConditions?.Link);
      window.dispatchEvent(new Event("storage"));
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(!loginPageData) {
      getLogInPageData();
    }
  }, [loginPageData]);

  return {
    loginPageData,
    isLoading,
  };
};

export default LoginLogic;
