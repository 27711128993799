import React, { createContext, useEffect, useState } from "react";
import { calcTotalGuests } from "../helpingFunctions/groups";
import RequestHook from "../hooks/RequestHook";
import axiosInstance from "../url/createAxios";
import { useDataContext } from "./DataContext";
import {
  CREATE_FORM_CONTENT,
  DINING_PARTY_DETAILS_INITIAL,
  GLOBAL_DINING_PARTY_ATTRS,
  INIT_FORM_CONTENT,
  MODIFY_DINING_PARTY_FORM_CONTENT,
  DINING_PARTY_ACTION_TYPES,
  DINING_PARTY_INITIAL_FORM_STATE,
  DINING_PARTY_MODALS,
  INIT_RESPONSE_DATA,
} from "../views/children/DiningPartyPage/diningPartyConstants";

export const DiningPartyContext = createContext({});

export const DiningPartyProvider = ({ children }) => {
  const instance = axiosInstance();
  const { reservationDetails, setReservationDetails } = useDataContext();
  const { getGroup } = RequestHook();

  const [diningPartyDisplay, setDiningPartyDisplay] = useState(null);
  const [diningPartyFormData, setDiningPartyFormData] = useState(
    DINING_PARTY_INITIAL_FORM_STATE
  );
  const [diningPartyDetails, setDiningPartyDetails] = useState(
    DINING_PARTY_DETAILS_INITIAL
  );
  const [diningPartySelections, setDiningPartySelections] =
    useState(diningPartyDetails);
  const [isGroupActionLoading, setIsGroupActionLoading] = useState(false);
  const [isUnchangedDiningParty, setIsUnchangedDiningParty] = useState(true);
  const [formContent, setFormContent] = useState(INIT_FORM_CONTENT);
  const [linkNewResPopUp, setLinkResNewPopUp] = useState(false);
  const [response, setResponse] = useState(INIT_RESPONSE_DATA);
  const [
    isPostGrCreationPartAddedLoading,
    setIsPostGrCreationPartAddedLoading,
  ] = useState(false);
  const [showDiningPartyParticipantForm, setShowDiningPartyParticipantForm] =
    useState(false);
  const [groupFetched, setGroupFetched] = useState(false);

  useEffect(() => {
    handleDiningPartyChanges();
  }, [diningPartySelections]);

  useEffect(() => {
    if (diningPartyDetails) {
      setDiningPartySelections(diningPartyDetails);
    }
  }, [diningPartyDetails]);

  useEffect(() => {
    if (groupFetched) {
      if (!diningPartySelections.id && reservationDetails) {
        setReservationDetails((prevState) => {
          return {
            ...prevState,
            IsGroupCreator: false,
            PartOfGroup: false,
          };
        });
      }
      if (
        reservationDetails?.GroupParticipants === 0 &&
        !reservationDetails?.PartOfGroup
      ) {
        setDiningPartyDetails(DINING_PARTY_DETAILS_INITIAL);
        setShowDiningPartyParticipantForm(false);
        setDiningPartyDisplay(DINING_PARTY_MODALS.INIT);
      }
    }
  }, [groupFetched]);

  const dataFillHandler = (type, value) => {
    try {
      setDiningPartyFormData((prevState) => {
        return {
          ...prevState,
          [type]: value,
        };
      });
    } catch (error) {
      console.error(
        `${new Date()} Error in dataFillHandler func in DiningBookingContext.js file ${
          error.message
        }`
      );
    }
  };

  const handleDiningPartyChanges = () => {
    try {
      const diningPartyParticipants =
        diningPartySelections[GLOBAL_DINING_PARTY_ATTRS.PARTICIPANTS];

      if (diningPartyParticipants.length === 0) {
        return;
      }

      const hasNewParticipant = diningPartyParticipants.some(
        ({ IsInGroup }) => !IsInGroup
      );

      setIsUnchangedDiningParty(!hasNewParticipant);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleDiningPartyChanges func in DiningBookingContext.js file ${
          error.message
        }`
      );
    }
  };

  const addMoreHandler = (isInDiningParty = null) => {
    try {
      if (isInDiningParty === null) {
        setFormContent(INIT_FORM_CONTENT);
        return;
      }
      setShowDiningPartyParticipantForm(true);

      const formStructure = isInDiningParty
        ? MODIFY_DINING_PARTY_FORM_CONTENT
        : CREATE_FORM_CONTENT;

      setFormContent(formStructure);
    } catch (error) {
      console.error(
        `${new Date()} Error in addMoreHandler func in DiningBookingContext.js ${
          error.message
        }`
      );
    }
  };

  const deleteGroupParticipant = async (deleteID) => {
    setResponse({
      actionType: DINING_PARTY_ACTION_TYPES.DELETE,
      isLoading: true,
      status: "Pending",
      message: null,
      infoBoxColor: null,
    });

    const { data } = await instance.delete(`/group/${deleteID}`, {
      withCredentials: true,
    });
    const cleanedItems = diningPartyDetails.participants.filter(
      ({ id }) => id !== deleteID
    );
    const totalGuestsNum = calcTotalGuests(cleanedItems);
    await getGroup(reservationDetails);

    setResponse({
      actionType: DINING_PARTY_ACTION_TYPES.DELETE,
      isLoading: false,
      status: "Finished",
      message: data.message,
      infoBoxColor: data.message === "Success" ? "success" : "danger",
    });

    setReservationDetails((prevState) => {
      return {
        ...prevState,
        GroupParticipants: totalGuestsNum,
        PartOfGroup: data.action === "destruct_all" ? false : true,
        IsGroupCreator: data.action === "destruct_all" ? false : true,
      };
    });
  };

  const removeDiningParty = async () => {
    try {
      const diningPartyParticipants =
        diningPartySelections[GLOBAL_DINING_PARTY_ATTRS.PARTICIPANTS];

      const allLegitParticipants = diningPartyParticipants.filter(
        ({ IsInGroup }) => IsInGroup
      );

      const allLegitParticipantsIds = allLegitParticipants.map(({ id }) => id);

      await deleteDiningParty(allLegitParticipantsIds);
      setDiningPartyDetails(DINING_PARTY_DETAILS_INITIAL);
      setDiningPartyDisplay(DINING_PARTY_MODALS.INIT);
      addMoreHandler(false);
    } catch (error) {
      console.error(
        `${new Date()} Error in removeDiningParty func DiningPartyContext.js ${
          error.message
        }`
      );
    }
  };

  const deleteDiningParty = async (listOfParticipantIds) => {
    try {
      setResponse({
        actionType: DINING_PARTY_ACTION_TYPES.DELETE,
        isLoading: true,
        status: "Pending",
        message: null,
        infoBoxColor: null,
      });
      const participantsDeleteResponse = [];
      let totalDels = 0;
      for (let index = 0; index < listOfParticipantIds.length; index++) {
        const id = listOfParticipantIds[index];
        const { data } = await instance.delete(`/group/${id}`);
        participantsDeleteResponse.push(data);
        if (data.message === "Success") totalDels++;
      }

      setResponse({
        actionType: DINING_PARTY_ACTION_TYPES.DELETE,
        isLoading: false,
        status: "Finished",
        message:
          totalDels === listOfParticipantIds.length
            ? "Successfully deleted dining party."
            : "Failed to delete 1 or more dining party participants. Please try again or contact the hotel reception directly.",
        infoBoxColor:
          totalDels === listOfParticipantIds.length ? "success" : "danger",
      });

      setReservationDetails((prevState) => {
        return {
          ...prevState,
          GroupParticipants: 0,
          PartOfGroup: false,
          IsGroupCreator: false,
        };
      });
      return participantsDeleteResponse;
    } catch (error) {
      console.error(
        `${new Date()} Error in deleteDiningParty func in RequestHook.js ${
          error.message
        }`
      );
    }
  };

  return (
    <DiningPartyContext.Provider
      value={{
        diningPartyDisplay,
        diningPartyFormData,
        isUnchangedDiningParty,
        formContent,
        response,
        linkNewResPopUp,
        showDiningPartyParticipantForm,
        isPostGrCreationPartAddedLoading,
        isGroupActionLoading,
        setIsGroupActionLoading,
        setIsUnchangedDiningParty,
        setIsPostGrCreationPartAddedLoading,
        deleteDiningParty,
        setShowDiningPartyParticipantForm,
        removeDiningParty,
        deleteGroupParticipant,
        setLinkResNewPopUp,
        setResponse,
        setDiningPartyDisplay,
        setFormContent,
        dataFillHandler,
        setDiningPartyFormData,
        addMoreHandler,
        diningPartySelections,
        setDiningPartySelections,
        diningPartyDetails,
        setDiningPartyDetails,
        groupFetched,
        setGroupFetched,
      }}
    >
      {children}
    </DiningPartyContext.Provider>
  );
};
