import {
  getEndTime,
  isOverallOverlaping,
} from "../globalConflictHelpersMethods";

/** 
    Method for retrieving all booked items that are overlaping by time AND BY name.
    At any given period of time, the user is not allowed to have multiple spa bookings for himself (same name) when they are overlaping.

    As per new requirment,
    Same person bookings are allowed if user1 spa1 end time is equal to the user1 spa2 start time
  */
const conflictSpaSamePersonDurationOverlapValidation = (
  confirmedSpaConflictsBookings,
  selectionDetails,
  selectedServiceDetails,
  facilitiesDataDetails
) => {
  const confirmedConflicts = [];

  const selectionStartTime = selectionDetails.time.substring(0, 5);
  const selectionEndTime = getEndTime(
    selectionDetails.time.substring(0, 5),
    selectedServiceDetails.Duration
  );
  const selectionFullName = `${selectionDetails.firstName} ${selectionDetails.lastName}`;

  for (let conflictedEntity of confirmedSpaConflictsBookings) {
    const bookingFirstName = conflictedEntity.isParticipant ? conflictedEntity.ParticipantFirstName : conflictedEntity.FirstName;
    const bookingLastName = conflictedEntity.isParticipant ? conflictedEntity.ParticipantLastName : conflictedEntity.LastName;
    const bookingFullName = `${bookingFirstName} ${bookingLastName}`;

    if (selectionFullName === bookingFullName) {
      const bookedServiceDetails = facilitiesDataDetails.find(
        (facilityDetail) => facilityDetail.id === conflictedEntity.Id
      ) || { Duration: 0 };

      const bookedServStartTime = conflictedEntity.Time.substring(0, 5);
      const bookedServEndTime = getEndTime(
        conflictedEntity.Time.substring(0, 5),
        bookedServiceDetails.Duration
      );

      if ( selectionEndTime !== bookedServStartTime && selectionStartTime !== bookedServEndTime ) {
        const isOverlaping = isOverallOverlaping(
          selectionStartTime,
          selectionEndTime,
          bookedServStartTime,
          bookedServEndTime
        );

        if (isOverlaping) {
          confirmedConflicts.push(conflictedEntity);
        }
      }
    }
  }

  return confirmedConflicts;
};

/** 
    Method for simplifying multiple persons spa bookings
  */
const combineMultiplePersonSpaBookings = (
  selections,
  facilitiesDataDetails
) => {
  const spaBookingsArr = [];
  // Combine all spa bookings into 1 array regardless of the name
  selections.forEach((sel) => {
    sel.on.forEach((when) => {
      spaBookingsArr.push({
        ...when,
        FirstName: sel.fName,
        LastName: sel.lName,
        spaId: sel.spaId,
      });
    });
  });

  // Check if every given selection is overlapping with any other
  selections.forEach((sel) => {
    sel.on.forEach((when) => {
      const matchingSelections = spaBookingsArr.filter(
        (row) =>
          (row.FirstName !== sel.fName && row.LastName !== sel.lName) &&
          row.time === when.time &&
          row.date === when.date
      );

      const serviceDetails = facilitiesDataDetails.find(
        (row) => row.id === sel.spaId
      ) || { Duration: 0 };
      const selectionServStartTime = when.time.substring(0, 5);
      const selectionServEndTime = getEndTime(
        when.time.substring(0, 5),
        serviceDetails.Duration
      );

      matchingSelections.forEach((row) => {
        const elseSelectionServStartTime = row.time.substring(0, 5);
        const elseSelectionServEndTime = getEndTime(
          row.time.substring(0, 5),
          serviceDetails.Duration
        );

        const isOverlaping = isOverallOverlaping(
          selectionServStartTime,
          selectionServEndTime,
          elseSelectionServStartTime,
          elseSelectionServEndTime
        );

        const spaBookingsArrIndex = spaBookingsArr.findIndex(
          (book) =>
            book.FirstName === row.FirstName &&
            book.LastName === row.LastName &&
            book.time === row.time &&
            book.date === row.date
        );

        if (isOverlaping) {
          // +1 to compensate for the currently compared selection
          spaBookingsArr[spaBookingsArrIndex].Quantity =
            matchingSelections.length + 1;
        } else {
          spaBookingsArr[spaBookingsArrIndex].Quantity = 1;
        }
      });
    });
  });

  return spaBookingsArr;
};

export {
  conflictSpaSamePersonDurationOverlapValidation,
  combineMultiplePersonSpaBookings,
};
