import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { useDataContext } from "../../../../context/DataContext";
import { formatDateDetailed } from "../../../../helpingFunctions/dateTimeUtilities";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import { useTheme } from "../../../../hooks/useTheme";

const ShortFolioInfoBox = ({ folioNumber }) => {
  const { typography } = useTheme();
  const { reservationDetails } = useDataContext();

  return (
    <BLContentCardWrapper dimensions={{ padding: ["s"] }}>
      <IonGrid>
        <IonRow
          className="bl-padding-b-xxs ion-text-end"
          style={{
            borderBottom: "2px solid var(--ion-color-primary)",
          }}
        >
          <IonCol>
            <BLParagraph
              txt={
                <>
                  <BLParagraph 
                    txt="Bill Ref "
                    //TODO: Unclear typography
                    //newClasses={`bold`}
                  />
                  <BLParagraph txt={folioNumber} />
                </>
              }
              color="primary"
              textVariant={typography.h3}
              // newClasses={`medium crimson-pro`}
              //TODO: Unclear typography
            />
          </IonCol>
        </IonRow>
        <IonRow className="bl-padding-t-xs bl-padding-b-xs">
          <IonCol>
            <BLParagraph
              txt={
                <>
                  <BLParagraph
                    txt="Arrival: "
                    textVariant={typography.h5}
                    dimensions={{ padding: ["xxs"] }}
                    color="primary"
                  />
                  <BLParagraph
                    color="text-grey"
                    txt={
                      <>
                        {formatDateDetailed(
                          [],
                          reservationDetails?.ArrivalDate.substring(0, 10),
                          true
                        )}
                      </>
                    }
                  />
                </>
              }
              textVariant={typography.h3}
              // newClasses={`medium crimson-pro`}
              //TODO: Unclear typography
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <BLParagraph
              txt={
                <>
                  <BLParagraph
                    txt="Departure: "
                    //TODO: Unclear typography
                    //newClasses={`bold`}
                    dimensions={{ padding: ["xxs"] }}
                    color="primary"
                    textVariant={typography.h5}
                  />
                  <BLParagraph
                    color="text-grey"
                    txt={
                      <>
                        {formatDateDetailed(
                          [],
                          reservationDetails?.DepartureDate.substring(0, 10),
                          true
                        )}
                      </>
                    }
                  />
                </>
              }
              textVariant={typography.h3}
              // newClasses={`medium crimson-pro`}
              //TODO: Unclear typography
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </BLContentCardWrapper>
  );
};

export default ShortFolioInfoBox;
