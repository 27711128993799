import React from "react";
import { IonGrid, IonRow, IonModal, IonCol } from "@ionic/react";
import BLContentCardWrapper from "../../Wrappers/BLContentCardWrapper";
import BLParagraph from "../../Paragraph/BLParagraph";

import { xSign } from "../../../../assets/SVGIcons";
import BLIcon from "../../BLIcon";
import { useTheme } from "../../../../hooks/useTheme";

const BLInlineModal = ({
  showAlert,
  dismissAlert,
  header,
  content,
  modalClasses = "",
}) => {
  const { typography } = useTheme();
  return (
    <IonModal
      className={`bl-inline-modal ${modalClasses}`}
      isOpen={showAlert}
      backdropDismiss={false}
      showBackdrop={true}
    >
      <BLContentCardWrapper
        background={"white"}
        newStyles={{ height: "100%" }}
        dimensions={{ padding: ["t-l", "b-l", "r-m", "l-m"] }}
      >
        <IonGrid className="bl-margin-b-s" style={{ height: "100%" }}>
          <IonRow className={"bl-margin-b-xs"}>
            <IonCol
              className={
                "bl-flex ion-justify-content-between ion-align-items-center"
              }
            >
              <BLParagraph
                txt={header}
                color={"primary"}
                textVariant={typography.h2}
                //newClasses={`x-large bold`}
                //TODO: Unclear typography
                dimensions={{
                  margin: ["l-lg"],
                  padding: ["t-md", "b-md"],
                }}
              />
              <div onClick={() => dismissAlert(false)}>
                <BLIcon customIconSource={xSign("24px", "var(--ion-color-secondary)", "var(--ion-color-white)")} />
              </div>
            </IonCol>
          </IonRow>
          <IonRow
            className="bl-padding-r-s"
            style={{ height: "90%", overflow: "auto" }}
          >
            <IonCol>{content}</IonCol>
          </IonRow>
        </IonGrid>
      </BLContentCardWrapper>
    </IonModal>
  );
};

export default BLInlineModal;
