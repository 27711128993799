import React from 'react';
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { shows } from "../../../../../../assets/SVGIcons";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import { useTheme } from '../../../../../../hooks/useTheme';

const ShowsRow = () => {
  const { typography } = useTheme();
  const showsRowTitle = (
    <IonGrid>
      <IonRow className="ion-align-items-center">
        {shows(32, "var(--ion-color-primary)")}
        <IonCol
          className={"bl-flex"}
          style={{ flexDirection: "column", gap: "5px" }}
        >
          <BLParagraph
            txt={"Shows"}
            color={"primary"}
            textVariant={typography.h3}
            // newClasses={`medium bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
            }}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );

  return { showsRowTitle };
};

export default ShowsRow;
