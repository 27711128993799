import { useContext, useEffect } from "react";
import { ActivityContext } from "../../../../../context/ActivityContext";
import { ConflictManagementContext } from "../../../../../context/ConflictManagementContext";
import { useDataContext } from "../../../../../context/DataContext";
import { getDatesBetween } from "../../../../../helpingFunctions/dateTimeUtilities";
import { generateKey } from "../../../../../helpingFunctions/helpingFunctions";
import RequestHook from "../../../../../hooks/RequestHook";
import axiosInstance from "../../../../../url/createAxios";
import { areAllConflictsResolved } from "../../../../../helpingFunctions/conflictManagement/resolveMethods/resolveMethods";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../../../../../constants/constants";
const { v4: uuidv4 } = require("uuid");

const ActivitiesProcessingMethods = (setIsFirstTimeBooker, setIsEditActionOnlyCancelled, setHasCancellationPolicy, setCancellationPolicyAlertsLength, setCancellationPolicyAlerts) => { 
  const instance = axiosInstance();
  const {
    activitySelections,
    setActivitySelections,
    activityViewType,
    selectedService,
    confirmationScreenSelections,
    setConfirmationScreenSelections,
    isProcessingBookings,
    setIsProcessingBookings,
  } = useContext(ActivityContext);
  const {
    setFoundConflicts,
    foundConflicts,
    prohibitRQToMaestro,
    loadNextConflict,
    setSpotlitConflict,
    replacedBookings,
  } = useContext(ConflictManagementContext);

  const {
    reservationDetails,
    setReservationDetails,
    itineraryDetails,
    isLoadingItinerary,
  } = useDataContext();

  const {
    getItinerary,
    refreshReservationState,
    getActivitiesAvailability,
  } = RequestHook();

  const handleActivityBookingRQ = async (
    activitySelectionsCopy,
    skipConflictValidation
  ) => {
    // If there are any unresolved conflicts - negate the method until all of them are resolved
    if (!areAllConflictsResolved(foundConflicts) && !skipConflictValidation) {
      loadNextConflict(foundConflicts);
      return;
    }

    setSpotlitConflict(null);
    setFoundConflicts(null);
    if (prohibitRQToMaestro) {
      // Do not allow the user to proceed with booking if the found conflict is STRONG
      return;
    }

    setIsProcessingBookings(true);

    let conflictItemsRS = {
      data: {
        bookings: [],
      },
    };
    if (replacedBookings.length > 0) {
      conflictItemsRS = await instance.post(
        `/Hotel/Booking/Facility/Availability/ConflictReplaced`,
        {
          request: {
            spaBookings: replacedBookings.filter((row) => row.type === MAESTRO_BACKEND_NAMING_VIEWS.SPA),
            activityBookings: replacedBookings.filter(
              (row) => row.type !== MAESTRO_BACKEND_NAMING_VIEWS.SPA
            ),
          },
        },
        { withCredentials: true }
      );
    }

    const preparedItems = contructRQItems(activitySelectionsCopy);

    const hasReplacedBookingsFailures = conflictItemsRS.data.bookings.find(
      (row) => row.status !== "success"
    );

    // If the replacements have failures - auto fail all upcoming requests
    if (hasReplacedBookingsFailures) {
      for (const index in preparedItems) {
        preparedItems[index].status = "failure";
      }
      const processedRS = processRS([
        ...preparedItems,
        ...conflictItemsRS.data.bookings,
      ]);
      setConfirmationScreenSelections(processedRS);
      setIsProcessingBookings(false);
      return;
    }

    const response = await instance.post(
      `/Hotel/Booking/Facility/Availability/Activity`,
      {
        request: preparedItems,
        reservationType: activityViewType,
      },
      { withCredentials: true }
    );

    if (
      response.data?.bookings?.some((item) => item.cancellationPolicyMessage)
    ) {
      const bookingsWithCancellationPolicy = response.data.bookings;
      setCancellationPolicyAlerts(bookingsWithCancellationPolicy);
      setCancellationPolicyAlertsLength(bookingsWithCancellationPolicy.length);
      setIsProcessingBookings(false);
      return;
    } else {
      setHasCancellationPolicy(false);
    }

    const processedRS = processRS([
      ...response.data.bookings,
      ...conflictItemsRS.data.bookings,
    ]);
    setConfirmationScreenSelections(processedRS);
    await getItinerary();
    await refreshReservationState();
    getActivitiesAvailability(reservationDetails?.Adults);
    setIsProcessingBookings(false);
  };

  const contructRQItems = (activitySelectionsCopy) => {
    const preparedRQItems = [];
    const selections = activitySelectionsCopy
      ? activitySelectionsCopy
      : activitySelections;

    selections.forEach((selection) => {
      let constructedObj = {};
      constructedObj.id = selectedService.id;
      constructedObj.ReservationNumber = reservationDetails.ReservationNumber;
      constructedObj.FirstName = reservationDetails.FirstName;
      constructedObj.LastName = reservationDetails.LastName;
      constructedObj.type = activityViewType;

      selection.on.forEach((whenSel) => {
        if (
          whenSel.uniqueBinder &&
          ["add", "remove"].includes(whenSel.action)
        ) {
          constructedObj.uniqueBinder = whenSel.uniqueBinder;
        }

        if (whenSel.action === "edit") {
          const uniqueBinder = uuidv4();
          const cxlObj = { ...constructedObj };
          const cxlDate = `${whenSel.bookedDate}T${whenSel.bookedTime}`;
          cxlObj.action = "remove";
          cxlObj.Quantity = whenSel.bookedQuantity;
          cxlObj.isEditReplacement = true;
          cxlObj.replacedWith = `${whenSel.date}T${whenSel.time}`;
          cxlObj.Date = cxlDate;
          cxlObj.BookingNumber = whenSel.bookingNumber;
          cxlObj.uniqueBinder = uniqueBinder;
          if (whenSel.ReasonCode) {
            cxlObj.ReasonCode = whenSel.ReasonCode;
          }

          const bookObj = { ...constructedObj };
          const bookedDate = `${whenSel.date}T${whenSel.time}`;
          bookObj.action = "add";
          bookObj.Quantity = whenSel.quantity;
          bookObj.isEdited = true;
          bookObj.Date = bookedDate;
          bookObj.uniqueBinder = uniqueBinder;

          cxlObj.NewDate = bookedDate;
          preparedRQItems.push(cxlObj);
          preparedRQItems.push(bookObj);
        } else if (whenSel.action === "remove") {
          const cxlObj = { ...constructedObj };
          const cxlDate = `${
            whenSel.bookedDate ? whenSel.bookedDate : whenSel.date
          }T${whenSel.bookedTime ? whenSel.bookedTime : whenSel.time}`;
          cxlObj.action = whenSel.action;
          cxlObj.Quantity = whenSel.bookedQuantity;
          cxlObj.Date = cxlDate;
          cxlObj.BookingNumber = whenSel.bookingNumber;
          if (whenSel.ReasonCode) {
            cxlObj.ReasonCode = whenSel.ReasonCode;
          }
          preparedRQItems.push(cxlObj);
        } else {
          const date = `${whenSel.date}T${whenSel.time}`;
          preparedRQItems.push({
            ...constructedObj,
            action: whenSel.action,
            Quantity: whenSel.quantity,
            Date: date,
          });
        }
      });
    });

    return preparedRQItems.filter((row) => row.action !== "none");
  };

  const processRS = (responseData) => {
    const activityObjs = [];
    setIsEditActionOnlyCancelled(
      responseData.filter((row) => row.action === "remove").length ===
        responseData.length
    );
    responseData.forEach((sel) => {
      if (sel.action !== "none") {
        const gName = `${sel.FirstName} ${sel.LastName}`;
        const dateTime = sel.Date.split("T");
        const isExistingIndex = activityObjs.findIndex(
          (row) => row.guestName === gName
        );

        if (isExistingIndex > -1) {
          activityObjs[isExistingIndex].on.push({
            date: dateTime[0],
            time: dateTime[1],
            action: sel.action,
            Status: sel.status,
            quantity: sel.Quantity,
            uniqueKey: generateKey(dateTime[0]),
            id: sel.id,
            type: sel.type,
            isReplacement: sel.isReplacement,
            isEditReplacement: sel.isEditReplacement,
            isEdited: sel.isEdited,
            replacedWith: sel.replacedWith,
            bookingNumber: sel.BookingNumber,
            uniqueBinder: sel.uniqueBinder,
          });
        } else {
          activityObjs.push({
            fName: sel.FirstName,
            lName: sel.LastName,
            guestName: gName,
            on: [
              {
                date: dateTime[0],
                time: dateTime[1],
                action: sel.action,
                Status: sel.status,
                quantity: sel.Quantity,
                uniqueKey: generateKey(dateTime[0]),
                id: sel.id,
                type: sel.type,
                isReplacement: sel.isReplacement,
                isEditReplacement: sel.isEditReplacement,
                isEdited: sel.isEdited,
                replacedWith: sel.replacedWith,
                bookingNumber: sel.BookingNumber,
                uniqueBinder: sel.uniqueBinder,
              },
            ],
            uniqueKey: generateKey(sel),
          });
        }
      }
    });

    return activityObjs;
  };

  const initialLoad = () => {
    if (itineraryDetails && selectedService) {
      const selections = [];
      const stayingDates = getDatesBetween(
        reservationDetails.ArrivalDate,
        reservationDetails.DepartureDate
      );

      let itinerary = [];
      stayingDates.forEach((date) => {
        itinerary = [
          ...itinerary,
          ...itineraryDetails.Dates[date][activityViewType],
        ];
      });

      const selectedServiceBookings = itinerary.filter(
        (row) => row.Id === selectedService.id
      );
      if (selectedServiceBookings.length > 0) {
        setIsFirstTimeBooker(false);
        const times = new Set();
        selectedServiceBookings.forEach((row) => {
          const gName = `${reservationDetails.FirstName} ${reservationDetails.LastName}`;
          const isAdded = selections.findIndex(
            (row) => row.guestName === gName
          );
          times.add(row.Time);
          if (isAdded === -1) {
            selections.push({
              fName: reservationDetails.FirstName,
              lName: reservationDetails.LastName,
              guestName: gName,
              activityId: selectedService.id,
              on: [
                {
                  date: row.Date,
                  time: row.Time,
                  bookedDate: row.Date,
                  bookedTime: row.Time,
                  action: row.action,
                  quantity: Number(row.Quantity),
                  bookedQuantity: Number(row.Quantity),
                  uniqueKey: generateKey(row.Date),
                  bookingNumber: row.BookingNumber,
                },
              ],
              uniqueKey: generateKey(row),
              action: row.action,
              isEdited: false,
            });
          } else {
            selections[isAdded].on.push({
              date: row.Date,
              time: row.Time,
              bookedDate: row.Date,
              bookedTime: row.Time,
              action: row.action,
              quantity: Number(row.Quantity),
              bookedQuantity: Number(row.Quantity),
              uniqueKey: generateKey(row.Date),
              bookingNumber: row.BookingNumber,
            });
          }
        });

      } else {
        setIsFirstTimeBooker(true);
        selections.push({
          fName: reservationDetails.FirstName,
          lName: reservationDetails.LastName,
          guestName: `${reservationDetails.FirstName} ${reservationDetails.LastName}`,
          activityId: selectedService.id,
          on: [
            {
              date: "",
              time: "",
              bookedTime: "",
              bookedDate: "",
              action: "add",
              quantity: 1,
              bookedQuantity: "",
              uniqueKey: generateKey(Date.now()),
            },
          ],
          uniqueKey: generateKey(reservationDetails),
          action: "add",
          isEdited: false,
        });
      }

      setActivitySelections(selections);
    }
  };

  const retryFailsFunc = async () => {
    const toRetry = [];
    confirmationScreenSelections.forEach((sel) => {
      const item = JSON.parse(JSON.stringify(sel));
      item.on = [];
      sel.on.forEach((row) => {
        if (row.Status !== "success") {
          item.on.push(row);
        }
      });

      toRetry.push(item);
    });
    await handleActivityBookingRQ(toRetry, true);
  };

  useEffect(() => {
    initialLoad();
  }, [isLoadingItinerary, selectedService, itineraryDetails]);

  useEffect(() => {
    if (!isProcessingBookings && itineraryDetails) {
      const hasActivityBookings =
        Object.values(itineraryDetails.Dates).findIndex(
          (booking) => booking.Activity.length > 0
        ) !== -1;
      setReservationDetails((prevState) => {
        return {
          ...prevState,
          Activities: hasActivityBookings,
        };
      });
    }
  }, [isProcessingBookings]);

  // Forced trigger to try to process the bookings to maestro when the foundConflicts state is updated
  useEffect(() => {
    if (foundConflicts) {
      handleActivityBookingRQ();
    }
  }, [foundConflicts]);

  return {
    initialLoad,
    handleActivityBookingRQ,
    retryFailsFunc,
  };
};

export default ActivitiesProcessingMethods;
