import { IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import { DataContext } from "../../../../../context/DataContext";
import { getDateInterval } from "../../../../../helpingFunctions/dateTimeUtilities";
import { useTheme } from "../../../../../hooks/useTheme";

const ItineraryHeader = () => {
  const { typography } = useTheme();
  const { reservationDetails, reservationHotelDetails } = useContext(DataContext);

  return (
    <IonGrid>
      <IonRow>
        <BLParagraph 
          txt={`${reservationHotelDetails?.Title} itinerary`}
          textVariant={typography.h1}
          color="primary"
        />
      </IonRow>
      <IonRow className="bl-padding-t-xs">
        <BLParagraph 
          txt={`Ref: ${reservationDetails.CRSNumber} / `}
          textVariant={typography.TOOLTIP_HIGHLIGHT_TEXT}
          color="primary"
        >
          {getDateInterval(reservationDetails.ArrivalDate, reservationDetails.DepartureDate)}
        </BLParagraph>
      </IonRow>
      <IonRow className="bl-padding-t-s bl-padding-b-xs">
        <BLParagraph 
          txt={`Filter the itinerary details using the buttons below.`}
          textVariant={typography.SMALL_PRINT_TEXT}
          color="primary"
        />
      </IonRow>
    </IonGrid>
  );
};

export default ItineraryHeader;