import React from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { pdfIcon, shareItinerary } from "../../../../../assets/SVGIcons";
import { GENERAL_ACTIONS } from "../../constants";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import styles from "./GeneralActions.module.scss";
import GeneralActionsLogic from "./GeneralActionsLogic";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import { getPlatform } from "../../../../../helpingFunctions/utilities";
import { isDesktop } from "../../../../../helpingFunctions/utilities";

const icons = {
  shareSocial: <BLIcon customIconSource={shareItinerary(20, "#153246")} />,
  pdfIcon: <BLIcon customIconSource={pdfIcon(20, "#153246")} />,
};

const filterActions = (actions) =>
  !isDesktop()
    ? actions.filter((item) => item.name !== "downloadPdf")
    : actions;

const GeneralActions = ({ itineraryData }) => {
  const { actionClickHandler } = GeneralActionsLogic(itineraryData);

  const ACTIONS = filterActions(GENERAL_ACTIONS);

  return (
    <IonGrid>
      <IonRow
        style={{ marginLeft: 8, marginTop: 16 }}
        className={`${
          styles[`GeneralActions_${getPlatform()}`]
        } ion-align-items-start ion-margin-bottom`}
      >
        {ACTIONS.map(({ icon, label, name }) => (
          <IonCol
            key={name}
            size="auto"
            className={`${styles.GeneralActions_text}`}
          >
            <BLButton
              textColor="primary"
              type="transparent"
              textSize="xxx-small"
              onClick={() => actionClickHandler(name)}
              content={label}
              customIcon={icons[icon]}
            />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default GeneralActions;
