import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";
import {
  ACTION_TYPES,
  SERVICES_TYPES,
  INITIAL_DATE,
} from "../../../../../constants/constants";

import { calendar, restaurant, time } from "ionicons/icons";
import { useDataContext } from "../../../../../context/DataContext";
import { DiningBookingContext } from "../../../../../context/DiningBookingContext";
import { formatTime } from "../../../../../helpingFunctions/dateTimeUtilities";
import { getRestaurantNameById } from "../../../../../helpingFunctions/facilityAndServices";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import {
  DINING_MODALS,
  DINING_SELECTIONS_ATTR,
} from "../../DiningBookingConstants";
import Days from "../Days/Days";
import RestaurantsNew from "../Restaurants/RestaurantsNew";
import Times from "../Times/Times";

import { formatPrice } from "../../../../../helpingFunctions/formatPrice";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import { useTheme } from "../../../../../hooks/useTheme";

const DefaultContentDisplayLogic = (viewType) => {
  const { typography } = useTheme();
  const { facilitiesDataDetails } = useDataContext();
  const { selectedDateSelections, getDayParagraph } =
    useContext(DiningBookingContext);

  const collapsedTitle = (title, type) => {
    try {
      const BOX_SELECTION_TYPE_LINK = {
        Day: "date",
        Restaurants: "restaurantId",
        TimeSlots: "time",
        Treats: "treatAddOns",
      };

      const selectionAttr = BOX_SELECTION_TYPE_LINK[type];
      const selectionValue = selectedDateSelections[selectionAttr];
      let TextEl = null;

      if (selectionValue || type === "Day") {
        switch (type) {
          case "Day":
            TextEl = (
              <BLParagraph
                txt={getDayParagraph(selectedDateSelections.date)}
                dimensions={{ padding: ["l-s"] }}
                //TODO: Unclear typography
                //newClasses={`crimson-pro bold`}
                textVariant={typography.BODY_TEXT}
                color="primary"
              />
            );
            break;
          case "Restaurants":
            {
              const resName = getRestaurantNameById(
                facilitiesDataDetails,
                selectionValue
              );

              TextEl = (
                <BLParagraph
                  txt={resName}
                  dimensions={{ padding: ["l-s"] }}
                  //TODO: Unclear typography
                  //newClasses={`crimson-pro bold`}
                  textVariant={typography.BODY_TEXT}
                  color="primary"
                />
              );
            }
            break;
          case "TimeSlots":
            TextEl = (
              <BLParagraph
                txt={formatTime(
                  `${INITIAL_DATE}T${selectionValue.substr(0, 5)}`,
                  false,
                  "p"
                )}
                dimensions={{ padding: ["l-s"] }}
                //TODO: Unclear typography
                //newClasses={`crimson-pro bold`}
                textVariant={typography.BODY_TEXT}
                color="primary"
              />
            );
            break;
          case SERVICES_TYPES.TREATS:
            TextEl =
              selectionValue.length > 0 ? (
                <IonGrid>
                  <IonRow>
                    {selectionValue.map(({ id, Title, Price }) => (
                      <IonCol size="12" key={id}>
                        <BLParagraph
                          txt={
                            <>
                              <strong>{Title}</strong>{" "}
                              <span>(+{formatPrice(Price)})</span>
                            </>
                          }
                          dimensions={{ padding: ["l-s"] }}
                          //TODO: Unclear typography
                          //newClasses={`crimson-pro bold`}
                          textVariant={typography.h5}
                          color="primary"
                        />
                      </IonCol>
                    ))}
                  </IonRow>
                </IonGrid>
              ) : (
                <BLParagraph
                  txt={title}
                  dimensions={{ padding: ["l-s"] }}
                  //TODO: Unclear typography
                  //newClasses={`crimson-pro bold`}
                  textVariant={typography.h5}
                  color="primary"
                />
              );
            break;
          default:
            TextEl = (
              <BLParagraph
                txt={selectionValue}
                dimensions={{ padding: ["l-s"] }}
                //TODO: Unclear typography
                //newClasses={`crimson-pro bold`}
                textVariant={typography.BODY_TEXT}
                color="primary"
              />
            );
            break;
        }
      } else {
        TextEl = (
          <BLParagraph
            txt={title}
            //TODO: Unclear typography
            //newClasses={`crimson-pro bold`}
            textVariant={typography.h5}
            dimensions={{ padding: ["l-s"] }}
            color="primary"
          />
        );
      }

      return TextEl;
    } catch (error) {
      console.error(
        `${new Date()} Error in collapsedTitle func in DefaultContentDisplayLogic.js file ${
          error.message
        }`
      );
    }
  };

  const displayByType = (diningModalType) => {
    try {
      switch (diningModalType) {
        case DINING_MODALS.DAY: {
          return <Days viewType={viewType} />;
        }
        case DINING_MODALS.RESTAURANTS: {
          return <RestaurantsNew viewType={viewType} />;
        }
        case DINING_MODALS.TIME: {
          return <Times viewType={viewType} />;
        }
        // TODO #6599: Hiding treats in dinner page
        // case DINING_MODALS.TREATS: {
        //   return <TreatYourself viewType={viewType} />;
        // }
        default: {
          return <p>Modal not found</p>;
        }
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in displayByType func in DefaultContentDisplayLogic.js file ${
          error.message
        }`
      );
    }
  };

  const handleDisability = (type) => {
    try {
      const isNoBookingNeeded =
        selectedDateSelections.action === ACTION_TYPES.NOT_REQ ||
        selectedDateSelections.isSkipped;

      switch (type) {
        case DINING_MODALS.DAY: {
          return false;
        }
        case DINING_MODALS.RESTAURANTS: {
          return isNoBookingNeeded;
        }
        case DINING_MODALS.TIME: {
          return isNoBookingNeeded
            ? isNoBookingNeeded
            : !selectedDateSelections[DINING_SELECTIONS_ATTR.RESTAURANT];
        }
        // TODO #6599: Hiding treats in dinner page
        // case DINING_MODALS.TREATS:
        //   return isNoBookingNeeded
        //     ? isNoBookingNeeded
        //     : !selectedDateSelections[DINING_SELECTIONS_ATTR.TIME];
        default:
          console.error(`Unknown type sent`);
          return true;
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in handleDisability func in DefaultContentDisplayLogic.js file ${
          error.message
        }`
      );
    }
  };

  const getExpandableIcon = (type) => {
    switch (type) {
      case DINING_MODALS.DAY:
        return (
          <BLIcon
            iconSource={calendar}
            iconSize="md"
          />
        );
      case DINING_MODALS.RESTAURANTS:
        return (
          <BLIcon
            iconSource={restaurant}
            iconSize="md"
          />
        );
      case DINING_MODALS.TIME:
        return (
          <BLIcon
            iconSource={time}
            iconSize="md"
          />
        );
      // TODO #6599: Hiding treats in dinner page
      // case DINING_MODALS.TREATS:
      //   return <BLIcon className={styles.BLDefaultContentIcon} icon={gift} iconSize="md" />;
      default:
        return null;
    }
  };

  const getSteps = (filteredDefaultContent) => {
    const steps = filteredDefaultContent.map((diningModal) => {
      switch (diningModal.type) {
        case DINING_MODALS.DAY:
          return { label: "", icon: calendar, type: DINING_MODALS.DAY };
        case DINING_MODALS.RESTAURANTS:
          return {
            label: "",
            icon: restaurant,
            type: DINING_MODALS.RESTAURANTS,
          };
        case DINING_MODALS.TIME:
          return { label: "", icon: time, type: DINING_MODALS.TIME };
        // TODO #6599: Hiding treats in dinner page
        // case DINING_MODALS.TREATS: {
        //   return { label: "", icon: gift , type: DINING_MODALS.TREATS };
        // }
        default: {
          return <p>Modal not found</p>;
        }
      }
    });
    return steps;
  };

  return {
    displayByType,
    handleDisability,
    collapsedTitle,
    getExpandableIcon,
    getSteps,
  };
};

export default DefaultContentDisplayLogic;
