import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { time } from "ionicons/icons";
import React, { Fragment, useContext } from "react";
import { ActivityContext } from "../../../../../../context/ActivityContext";
import { useDataContext } from "../../../../../../context/DataContext";
import { generateKey } from "../../../../../../helpingFunctions/helpingFunctions";
import BLIcon from "../../../../../../shared/Ionic/BLIcon";
import BLLoadingContainer from "../../../../../../shared/Ionic/BLLoadingContainer";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import TimesRowLogic from "./TimesRowLogic";
import { useTheme } from "../../../../../../hooks/useTheme";

const TimesRowTitle = ({ expanded }) => {
  const { typography } = useTheme();
  const { isLoadingItinerary } = useDataContext();
  const { getTime } = TimesRowLogic();
  const { activitySelections, isEditMode } = useContext(ActivityContext);

  return expanded ? (
    <IonGrid>
      <IonRow>
        <BLIcon iconSource={time} color={"primary"} iconSize={"md"} />
        <IonCol>
          <BLParagraph
            txt={"Time slot"}
            color={"primary"}
            textVariant={typography.h3}
            // newClasses={`medium bold crimson-pro`}
            //TODO: Unclear typography
            dimensions={{
              margin: ["l-s"],
              padding: ["t-md", "b-md"],
            }}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  ) : (
    <IonGrid>
      <IonRow>
        <BLIcon iconSource={time} color={"primary"} iconSize={"md"} />
        <IonCol
          className={"bl-flex bl-padding-l-s"}
          style={{ flexDirection: "column", gap: "5px" }}
        >
          {isLoadingItinerary ? (
            <BLLoadingContainer />
          ) : (
            activitySelections.map((sel) => (
              <Fragment key={generateKey(sel)}>
                {sel.on.some((sel) => !sel.time) &&
                  !sel.on.some((sel) => sel.time) && (
                  <BLParagraph
                    txt={"Time slot"}
                    color={"primary"}
                    textVariant={typography.h3}
                    // newClasses={`medium bold crimson-pro`}
                    //TODO: Unclear typography
                    dimensions={{
                      padding: ["t-md", "b-md"],
                    }}
                  />
                )}
                {sel.on.map(
                  (row) =>
                    row.time &&
                    (getTime(row) ? (
                      <IonRow key={generateKey(row)}>
                        <IonCol>
                          <BLParagraph
                            txt={getTime(row)}
                            color={"primary"}
                            textVariant={typography.h3}
                            typographyStyles={
                              row.action === "remove"
                                ? { textDecoration: "line-through" }
                                : {}
                            }
                            // newClasses={`medium bold ${
                            //   row.action === "remove" ? "line" : ""
                            // } crimson-pro`}
                            //TODO: Unclear typography
                            dimensions={{
                              padding: ["t-md", "b-md"],
                            }}
                          />
                          {!["none"].includes(row.action) &&
                            isEditMode &&
                            (row.time !== row.bookedTime ||
                              row.action === "remove") && (
                            <BLParagraph
                              txt={"*Updated*"}
                              color={"success"}
                              textVariant={typography.h3}
                              // newClasses={`medium bold crimson-pro`}
                              //TODO: Unclear typography
                              dimensions={{
                                margin: ["l-s"],
                                padding: ["t-md", "b-md"],
                              }}
                            />
                          )}
                        </IonCol>
                      </IonRow>
                    ) : (
                      <IonRow
                        key={generateKey(row)}
                        className={"ion-text-center"}
                        style={{
                          margin: 0,
                          marginLeft: "20px",
                        }}
                      >
                        <IonCol>
                          <BLLoadingContainer newClasses={"bl-margin-xxxs"} />
                        </IonCol>
                      </IonRow>
                    ))
                )}
              </Fragment>
            ))
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default TimesRowTitle;
