import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useDataContext } from "../../../../../context/DataContext";
import { getCurrentDate } from "../../../../../helpingFunctions/dateTimeUtilities";
import { DINING_SELECTIONS_ATTR } from "../../DiningBookingConstants";

import { DiningBookingContext } from "../../../../../context/DiningBookingContext";

const TimesLogic = (viewType) => {
  const {
    selectedDateSelections,
    restaurantOptions,
  } = useContext(DiningBookingContext);
  const [selectedRestaurantTimes, setSelectedRestaurantTimes] = useState({
    AM: [],
    PM: [],
  });
  const { itineraryDetails } = useDataContext();

  const extractSelectedRestaurantTimes = () => {
    try {
      const id = selectedDateSelections[DINING_SELECTIONS_ATTR.RESTAURANT];

      const items = restaurantOptions.find((row) => row.date === selectedDateSelections.date);
      const selectedRestaurant = items.restaurants.find(
        ({ restaurantId }) => restaurantId === id
      );
      
      if (!selectedRestaurant) {
        setSelectedRestaurantTimes({ AM: [], PM: [] });
        return;
      }

      const itineraryInfo =
        itineraryDetails.Dates[selectedDateSelections.date][viewType];
      const isPreviouslyBooked = itineraryInfo.length > 0;
      const isBookingInThePast =
        isPreviouslyBooked &&
        itineraryInfo[0].Time.slice(0, 5).replace(":", "") <=
          format(new Date(), "kkmm");
      const isInTheCurrentDate =
        getCurrentDate() === selectedDateSelections.date;
      const isTheDateInTheFuture =
        getCurrentDate() < selectedDateSelections.date;
      const isTimeInTheFuture = (time) => {
        return (
          format(new Date(), "kkmm") < time.Time.slice(0, 5).replace(":", "")
        );
      };

      const times = { AM: [], PM: [] };
      selectedRestaurant.times.forEach((time) => {
        if (
          isTheDateInTheFuture ||
          (isInTheCurrentDate &&
            ((isPreviouslyBooked &&
              (isBookingInThePast ||
                (!isBookingInThePast && isTimeInTheFuture(time)))) ||
              (!isPreviouslyBooked && isTimeInTheFuture(time))))
        ) {
          if (time.Time.substring(0, 2) >= "12") {
            times.PM.push(time);
          } else {
            times.AM.push(time);
          }
        }
      });

      setSelectedRestaurantTimes(times);
    } catch (error) {
      console.error(
        `${new Date()} Error in extractSelectedRestaurantTimes func in TimesLogic.js ${
          error.message
        }`
      );
    }
  };

  useEffect(() => {
    if (restaurantOptions.length > 0 && selectedDateSelections.date) {
      extractSelectedRestaurantTimes();
    }
  }, [restaurantOptions, selectedDateSelections]);

  return {
    selectedRestaurantTimes,
  };
};

export default TimesLogic;
