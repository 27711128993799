import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import { cafe, people } from "ionicons/icons";
import React, { useContext, useEffect } from "react";
import { dinner } from "../../../assets/SVGIcons";
import { SERVICES_TYPES } from "../../../constants/constants";
import { useDataContext } from "../../../context/DataContext";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { ROUTE_VIEWS } from "../../../routes/routeViews";
import BLIcon from "../../../shared/Ionic/BLIcon";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import CategoryCard from "../../../shared/Ionic/ServicesComponents/ServiceCards/CategoryCard";
import BLContentCardWrapper from "../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import styles from "../../../theme/parentPageBoxSizing.module.scss";
import { isDesktop } from "../../../helpingFunctions/utilities";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import { useTheme } from "../../../hooks/useTheme";
import ServicePageHeader from "../../../shared/Ionic/Wrappers/ServicePageHeader";
import { useHistory } from "react-router-dom";

const DiningPage = () => {
  const { typography } = useTheme();
  const history = useHistory();
  const { warnerServices, redirectBetweenPages } = useContext(NavigatorContext);
  const { reservationDetails } = useDataContext();
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);

  useEffect(() => {
    if (warnerServices.length === 0) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Dining Page");
    } else {
      setIsLoaderOpened(false);
    }
  }, [warnerServices]);

  const getIconType = (type) => {
    if (type === SERVICES_TYPES.DINNER) {
      return <BLIcon customIconSource={dinner()} />;
    } else {
      return (
        <BLIcon
          iconSource={
            type === SERVICES_TYPES.BREAKFAST
              ? cafe
              : type === SERVICES_TYPES.DINING_PARTY
                ? people
                : null
          }
          color="primary"
        />
      );
    }
  };

  const hasBooking = (type) => {
    return (
      (type === SERVICES_TYPES.DINING_PARTY && reservationDetails.PartOfGroup) ||
      (type === SERVICES_TYPES.BREAKFAST && reservationDetails.Breakfast) ||
      (type === SERVICES_TYPES.DINNER && reservationDetails.Dinner)
    );
  };

  const getDiningOptions = () => {
    const dining = warnerServices.find(
      (row) => row.type === SERVICES_TYPES.DINING
    );

    const obj = {};
    dining?.options.forEach((row) => {
      if (!obj.hasOwnProperty(row.InfoLabel)) {
        obj[row.InfoLabel] = [];
      }
      obj[row.InfoLabel].push(row);
    });

    return dining ? obj : {};
  };

  return (
    <IonPage>
      <BLContentCardWrapper
        newStyles={{ height: "100%", overflow: "auto" }}
        background={isDesktop() ? "white" : "light"}
      >
        <ServicePageHeader
          service={SERVICES_TYPES.DINING}
          serviceType="service"
          onClick={() => history.push(ROUTE_VIEWS.LANDING_PAGE)}
        />

        <IonRow className="bl-padding-s">
          <IonCol>
            <IonGrid>
              {Object.entries(getDiningOptions()).map(([key, value], index) => (
                <IonRow key={`${key.substring(0, 10)}_${index}`}>
                  <IonCol
                    size="12"
                    className={`bl-padding-t-s bl-padding-b-${
                      isDesktop() ? "xs" : "s"
                    }`}
                  >
                    <BLParagraph
                      txt={key}
                      textVariant={typography.BODY_TEXT}
                      color="text-grey"
                      markDown
                    />
                  </IonCol>
                  <IonCol>
                    <IonGrid>
                      <IonRow
                        className={styles["box-size-wrapper"]}
                        style={{ gap: "15px" }}
                      >
                        {value.map((opt, optIndex) => (
                          <IonCol
                            className={`${styles["box-size"]}`}
                            key={`${opt.Title}_${optIndex}`}
                            onClick={async (e) => {
                              e.stopPropagation();
                              await redirectBetweenPages(
                                `${ROUTE_VIEWS.DINING}/${opt.Type}`
                              );
                            }}
                          >
                            <CategoryCard
                              title={opt.Title}
                              categoryImg={
                                opt.Image?.formats?.small?.url || opt.Image?.url
                              }
                              icon={getIconType(opt.Type)}
                              hasBooking={hasBooking(opt.Type)}
                              titleContentStyle={{
                                background: isDesktop()
                                  ? "var(--ion-color-light)"
                                  : "var(--ion-color-white)",
                              }}
                            />
                          </IonCol>
                        ))}
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>
          </IonCol>
        </IonRow>
      </BLContentCardWrapper>
    </IonPage>
  );
};

export default DiningPage;
