import { IonCol, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import BLCheckBox from "../../../../../shared/Ionic/Boxes/CheckBox/BLCheckBox";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";

import {
  DINING_PARTY_CHECKBOX_TEXT,
  DINING_PARTY_TERMS_AND_CONDITIONS_ATTR,
} from "../../diningPartyConstants";

import { useDataContext } from "../../../../../context/DataContext";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";
import { useTheme } from "../../../../../hooks/useTheme";
import BLLink from "../../../../../shared/Ionic/Links/BLAnchor/BLLink";

const DiningTermsAndConditions = () => {
  const { typography } = useTheme();
  const { dataFillHandler, diningPartyFormData, isGroupActionLoading } =
    useContext(DiningPartyContext);
  const { pagesConfiguration } = useDataContext();
  const isTermsAgree =
    diningPartyFormData[DINING_PARTY_TERMS_AND_CONDITIONS_ATTR];

  return (
    <BLContentCardWrapper dimensions={{ margin: ["t-s"] }}>
      <IonRow>
        <IonCol size="12">
          <BLParagraph textVariant={typography.BODY_TEXT} color="text-grey">
            {"By proceeding to create a dinner party, you agree that you have read and accepted our "}
            <BLLink
              txt="T&C's"
              color="text-grey"
              href={pagesConfiguration?.TermsAndConditions?.Link}
            />
            {" and booking will be made on behalf of the whole group you have created."}
          </BLParagraph>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <BLCheckBox
            txt={DINING_PARTY_CHECKBOX_TEXT}
            dimensions={{ margin: ["t-m"] }}
            checkSlot="start"
            newClasses="dining-party"
            labelColor="text-grey"
            onClick={dataFillHandler.bind(
              null,
              DINING_PARTY_TERMS_AND_CONDITIONS_ATTR,
              !isTermsAgree
            )}
            checked={isTermsAgree}
            disabled={isGroupActionLoading}
          />
        </IonCol>
      </IonRow>
    </BLContentCardWrapper>
  );
};

export default DiningTermsAndConditions;
