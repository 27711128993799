import { IonPage, IonRouterOutlet } from "@ionic/react";
import React from "react";
import DiningPage from "../views/children/DiningPage/DiningPage";
import { ROUTE_VIEWS } from "./routeViews";
import { Route, Switch } from "react-router-dom";
import { ConflictManagementProvider } from "../context/ConflictManagementContext";
import { DiningPartyProvider } from "../context/DiningPartyContext";
import DiningBookingProvider from "../context/DiningBookingContext";
import DiningBooking from "../views/children/DiningBookingPage/DiningBooking";
import DiningParty from "../views/children/DiningPartyPage/DiningParty";
import FallbackRoute from "./FallbackRoute";
import { SERVICES_TYPES } from "../constants/constants";

const DiningRoutes = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Switch>
          <Route exact path={ROUTE_VIEWS.DINING} component={DiningPage} />
          <Route
            path={ROUTE_VIEWS.DINING_BREAKFAST}
            exact={true}
            render={() => (
              <ConflictManagementProvider
                conflictOccuringView={SERVICES_TYPES.BREAKFAST}
              >
                <DiningPartyProvider>
                  <DiningBookingProvider viewType={SERVICES_TYPES.BREAKFAST}>
                    <DiningBooking />
                  </DiningBookingProvider>
                </DiningPartyProvider>
              </ConflictManagementProvider>
            )}
          />

          <Route
            path={ROUTE_VIEWS.DINING_DINNER}
            exact={true}
            render={() => (
              <ConflictManagementProvider
                conflictOccuringView={SERVICES_TYPES.DINNER}
              >
                <DiningPartyProvider>
                  <DiningBookingProvider viewType={SERVICES_TYPES.DINNER}>
                    <DiningBooking />
                  </DiningBookingProvider>
                </DiningPartyProvider>
              </ConflictManagementProvider>
            )}
          />
          <Route
            path={ROUTE_VIEWS.DINING_DINING_PARTY}
            exact={true}
            render={() => (
              <DiningPartyProvider>
                <DiningBookingProvider viewType={SERVICES_TYPES.DINING_PARTY}>
                  <DiningParty />
                </DiningBookingProvider>
              </DiningPartyProvider>
            )}
          />
          <Route component={FallbackRoute} />
        </Switch>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default DiningRoutes;
