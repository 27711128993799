import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import { Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDataContext } from "../../../context/DataContext";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import TabPanel from "../../../shared/Ionic/TabPanel";
import { PageLoaderContext } from "../../../context/PageLoaderContext";

const HotelInfoPage = () => {
  const { reservationHotelDetails } = useDataContext();
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const [currentTab, setCurrentTab] = useState("");
  const [currentTabContent, setCurrentTabContent] = useState("");

  useEffect(() => {
    if (reservationHotelDetails) {
      setIsLoaderOpened(false);
      if (reservationHotelDetails.HotelInfo?.Tabs?.length > 0) {
        setCurrentTab(reservationHotelDetails?.HotelInfo?.Tabs[0].Title);
      }
    } else {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Hotel Info");
    }
  }, [reservationHotelDetails]);

  useEffect(() => {
    if (currentTab) {
      const content = reservationHotelDetails?.HotelInfo?.Tabs.find(
        (tab) => tab.Title === currentTab
      )?.Content;
      setCurrentTabContent(content);
    }
  }, [currentTab, reservationHotelDetails]);

  return (
    <IonPage style={{ overflow: "auto" }}>
      <IonGrid>
        {reservationHotelDetails?.HotelInfo?.MainSection && (
          <BLParagraph
            txt={reservationHotelDetails?.HotelInfo?.MainSection}
            color="primary"
            markDown
          />
        )}
        <IonRow className="bl-padding-t-s">
          <IonCol>
            <Tabs
              value={currentTab}
              onChange={(event, value) => setCurrentTab(value)}
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile
              sx={{
                "& .MuiButtonBase-root.MuiTab-root": {
                  color: "var(--ion-color-primary)",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: 600,
                  padding: "8px",
                },
                "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                  color: "var(--ion-color-tertiary) !important",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "var(--ion-color-tertiary) !important",
                },
              }}
            >
              {currentTab &&
                reservationHotelDetails?.HotelInfo.Tabs.map((tab) => (
                  <Tab key={tab.Title} value={tab.Title} label={tab.Title} />
                ))}
            </Tabs>

            <TabPanel value={currentTab}>
              <IonRow>
                <IonCol className="bl-padding-s">
                  <BLParagraph
                    txt={currentTabContent}
                    markDown
                    color="primary"
                  />
                </IonCol>
              </IonRow>
            </TabPanel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default HotelInfoPage;
