import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import CreateDimensions from "../../../../../helpingFunctions/DimensionsFactory";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import { RESTAURANT_MENU_LINK_TEXT } from "../../DiningBookingConstants";
import RestaurantExpandedCategories from "./RestaurantExpandedCategories";

import { informationCircle, openOutline } from "ionicons/icons";
import styles from "./Restaurants.module.scss";
import { DiningBookingContext } from "../../../../../context/DiningBookingContext";
import { useTheme } from "../../../../../hooks/useTheme";

const btnProps = {
  deselect: {
    txt: "Deselect",
    type: "outlined",
  },
  select: {
    txt: "Select",
    type: "primary",
  },
};

const RestaurantExpandedDesktop = ({
  restaurant,
  isSelected,
  isUnavailable,
  isInThePast,
  isOneRestaurant,
  handleResSelection,
}) => {
  const { typography } = useTheme();
  const {
    showRestaurantDetails,
    setShowRestaurantDetails,
    setRestaurantDetails,
  } = useContext(DiningBookingContext);

  const { dimensions: commonDimensionsStyles } = CreateDimensions({
    padding: ["l-xs", "r-xs"],
  });

  const buttonType = isSelected ? "deselect" : "select";
  const btn = btnProps[buttonType];

  const withContentCategories = restaurant.Details?.filter(({ icon }) => icon);

  return (
    <>
      <IonGrid style={{ width: "100%" }}>
        <IonRow
          className={`${commonDimensionsStyles} ion-justify-content-between ion-align-items-center`}
        >
          <IonCol
            size="12"
            className={`${styles.RestaurantExpandedCenterText} ${
              restaurant.Title.length > 36 ? "bl-padding-r-s" : ""
            }`}
          >
            <BLParagraph
              txt={restaurant.Title}
              textVariant={typography.h2}
              // newClasses={`crimson-pro bold large`}
              //TODO: Unclear typography
              color="primary"
            />
          </IonCol>
          <IonCol size="auto" style={{ position: "absolute", right: "5px" }}>
            <IonIcon
              className={styles.RestaurantExpandedIcon}
              onClick={() => {
                setShowRestaurantDetails(!showRestaurantDetails);
                setRestaurantDetails(restaurant);
              }}
              icon={informationCircle}
            />
          </IonCol>
        </IonRow>

        <IonRow
          className={`${commonDimensionsStyles} bl-padding-t-m ion-justify-content-between`}
        >
          <IonCol
            size="6.3"
            className="bl-padding-r-s"
            style={{
              borderRight: withContentCategories.length > 0 ? "1px solid var(--ion-color-primary)" : "none",
              textAlign: "left",
            }}
          >
            <BLParagraph
              txt={restaurant.ShortDescription}
              textVariant={typography.ITINERARY_BODY_TEXT}
              color="text-grey"
            />
          </IonCol>
          <IonCol size="5.5">
            <RestaurantExpandedCategories categories={withContentCategories} />
          </IonCol>
        </IonRow>

        <IonRow className={` ${styles.RestaurantExpanded_Menu}  bl-padding-xs`}>
          <IonCol size="auto" className="bl-padding-xs">
            <BLButton
              type="outlined-icon-text"
              slot="end"
              disabled={!restaurant.Menu}
              content={RESTAURANT_MENU_LINK_TEXT}
              icon={openOutline}
              onClick={() => window.open(restaurant.Menu, "_blank").focus()}
            />
          </IonCol>

          {!isOneRestaurant && (
            <IonCol size="auto" className="bl-padding-xs">
              <BLButton
                content={btn.txt}
                type={btn.type}
                onClick={handleResSelection.bind(null, restaurant.id)}
                disabled={isInThePast || isUnavailable}
              />
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </>
  );
};

export default RestaurantExpandedDesktop;
