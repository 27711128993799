import React, { useContext } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import DayItemLogic from "./DayItemLogic";
import {
  CHECKINCHECKOUT_TYPES,
  MAESTRO_BACKEND_NAMING_VIEWS,
  INITIAL_DATE,
} from "../../../../../constants/constants";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import { ENTERTAINMENT_TYPES } from "../../../../../constants/constants";
import {
  formatTime,
  isDateInThePast,
} from "../../../../../helpingFunctions/dateTimeUtilities";
import { NavigatorContext } from "../../../../../context/NavigatorContext";
import BLInformationBox from "../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import { isPastDate } from "../../../../../helpingFunctions/bookingHelp";
import { endOfDay } from "date-fns";
import {
  ENTERTAINMENT_INFORMATION_MSG_MARKDOWN,
  SPA_INFORMATION_MSG_MARKDOWN,
} from "../../constants";
import { mapTypeToRoute } from "../../../../../routes/routeViews";
import { useTheme } from "../../../../../hooks/useTheme";
import BLLink from "../../../../../shared/Ionic/Links/BLAnchor/BLLink";

const DayItem = ({ item, backgroundColor }) => {
  const { SINGLE_BOOKING_ACTIONS, bookedItemDetails } = DayItemLogic(item);

  const { typography } = useTheme();
  const { redirectFromItinerary } = useContext(NavigatorContext);

  const isPast =
    item.Type.includes("NotRequired") || item.Type === "Treat"
      ? isDateInThePast(endOfDay(new Date(item.Date)))
      : isPastDate(item.Date, item.Time?.slice(0, 5));

  const showButtons = !CHECKINCHECKOUT_TYPES.includes(item.Type) && !isPast;

  const showInfoMessage =
    !isPast &&
    (item.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA ||
      ENTERTAINMENT_TYPES.includes(item.Type));

  const infoMessage = showInfoMessage
    ? item.Type === MAESTRO_BACKEND_NAMING_VIEWS.SPA
      ? SPA_INFORMATION_MSG_MARKDOWN
      : ENTERTAINMENT_INFORMATION_MSG_MARKDOWN
    : null;

  return (
    <IonGrid style={{ backgroundColor, opacity: isPast ? 0.4 : 1 }}>
      <IonRow style={{ display: "flex", width: "100%" }}>
        <IonCol
          className="bl-padding-xs"
          style={{ display: "flex", flex: "0 0 80px" }}
        >
          {item.Time && (
            <IonGrid>
              <IonRow>
                <IonCol>
                  <BLParagraph
                    txt={formatTime(
                      `${INITIAL_DATE}T${item.Time.substring(0, 5)}`,
                      true,
                      "p"
                    )}
                    textVariant={typography.TOOLTIP_HIGHLIGHT_TEXT}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </IonCol>
        <IonCol
          className="bl-padding-t-xs bl-padding-l-xs bl-padding-b-xs"
          style={{ flex: 1 }}
        >
          <IonGrid>
            <IonRow className="bl-padding-r-xs">
              <IonCol size="12">
                <BLParagraph
                  textVariant={typography.TOOLTIP_HIGHLIGHT_TEXT}
                >
                  {`${bookedItemDetails?.title} `}
                  {showButtons && <BLLink
                    txt="(edit)"
                    color="tertiary"
                    onClick={() =>
                      redirectFromItinerary(
                        mapTypeToRoute(
                          item.Type.replace("NotRequired", ""),
                          item.Id,
                          item.Type.replace("NotRequired", "") !==
                            MAESTRO_BACKEND_NAMING_VIEWS.TREAT
                            ? item.CategoryId
                            : item.categoryId
                        ),
                        {
                          bookedDate: item.Date,
                        }
                      )
                    }
                  />
                  }
                </BLParagraph>
              </IonCol>
              {bookedItemDetails?.duration &&
                <IonCol size="12">
                  <BLParagraph
                    txt={bookedItemDetails?.duration}
                    textVariant={typography.ITINERARY_BODY_TEXT}
                  />
                </IonCol>
              }
              {bookedItemDetails?.location &&
                <IonCol size="12">
                  <BLParagraph
                    txt={bookedItemDetails?.location}
                    textVariant={typography.TOOLTIP_HIGHLIGHT_TEXT}
                  />
                </IonCol>
              }
              {bookedItemDetails?.guestsInfo?.text &&
              <IonCol size="12">
                <BLParagraph
                  txt={bookedItemDetails?.guestsInfo?.text}
                  textVariant={typography.TOOLTIP_HIGHLIGHT_TEXT}
                />
              </IonCol>
              }
              {bookedItemDetails?.guestsInfo?.names &&
                bookedItemDetails.guestsInfo.names.map((name) => (
                  <IonCol size="12" key={name}>
                    <BLParagraph 
                      txt={`- ${name}`}
                      textVariant={typography.ITINERARY_BODY_TEXT}
                    />
                  </IonCol>
                ))}
            </IonRow>
            <IonRow>
              {showButtons &&
                !item.Type.includes("NotRequired") &&
                item.Type !== "Treat" &&
                SINGLE_BOOKING_ACTIONS.map((action) => (
                  <IonCol key={action.id} size="auto">
                    <BLButton
                      textColor="primary"
                      type="transparent"
                      textSize="xxx-small"
                      content={action.label}
                      onClick={() => action.onClick(item.Type)}
                      customIcon={action.icon}
                    />
                  </IonCol>
                ))}
            </IonRow>
          </IonGrid>
        </IonCol>
      </IonRow>
      {item.conflicts &&
        !isPast &&
        item.conflicts.map((conflict, index) => (
          <IonRow
            key={index}
            className="bl-padding-l-xs bl-padding-r-xs bl-padding-b-xs"
          >
            <BLInformationBox
              content={conflict}
              type={"itinerary_info"}
              markdown
            />
          </IonRow>
        ))}
      {showInfoMessage && (
        <IonRow className="bl-padding-l-xs bl-padding-r-xs bl-padding-b-xs">
          <BLInformationBox
            content={infoMessage}
            type={"itinerary_info"}
            markdown
          />
        </IonRow>
      )}
    </IonGrid>
  );
};

export default DayItem;
