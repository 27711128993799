import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { addCircle } from "ionicons/icons";
import React, { useContext } from "react";
import { ActivityContext } from "../../../../../../context/ActivityContext";
import { useDataContext } from "../../../../../../context/DataContext";
import { getCurrentDate, getDiffTweenTodayTo } from "../../../../../../helpingFunctions/dateTimeUtilities";
import BLLoadingContainer from "../../../../../../shared/Ionic/BLLoadingContainer";
import BLInformationBox from "../../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import BLButton from "../../../../../../shared/Ionic/Buttons/BLButton";
import DaysRowSeparateSelection from "./Separate/DaysRowSeparateSelection";

const DaysRowContent = () => {
  const { isLoadingItinerary, dynamicTexts, reservationDetails } = useDataContext();
  const { selectedService, activitySelections, setActivitySelections } = useContext(ActivityContext);

  const passedDaysNumber =
    reservationDetails.ArrivalDate < getCurrentDate()
      ? Math.abs(getDiffTweenTodayTo(reservationDetails.ArrivalDate))
      : 0;

  const addSeparateDays = () => {
    const activitySelectionsCopy = JSON.parse(
      JSON.stringify(activitySelections)
    );
    activitySelectionsCopy[0]?.on.push({
      date: "",
      time: "",
      bookedDate: "",
      bookedTime: "",
      action: "add",
      quantity: 1,
      bookedQuantity: "",
    });
    setActivitySelections(activitySelectionsCopy);
  };

  if (isLoadingItinerary) {
    return (
      <IonCol className={"ion-align-items-center"}>
        <BLLoadingContainer />
      </IonCol>
    );
  }

  return (
    <IonCol className="ion-align-items-center">
      <IonGrid
        className="bl-flex"
        style={{
          flexWrap: "wrap",
          flexDirection: "column",
          minWidth: "240px",
          gap: "8px"
        }}
      >
        {dynamicTexts?.Activities?.Booking?.InfoBox_DaysAndNoOfGuests && (
          <IonRow>
            <IonCol>
              <BLInformationBox
                content={
                  dynamicTexts?.Activities?.Booking?.InfoBox_DaysAndNoOfGuests
                }
                type="info"
                markdown
              />
            </IonCol>
          </IonRow>
        )}
        {activitySelections[0]?.on.map((when, dateIndex) =>
          <DaysRowSeparateSelection 
            key={when.uniqueKey || dateIndex} 
            selIndex={0} 
            dateIndex={dateIndex} 
            when={when} 
          />
        )}
        {activitySelections[0]?.on[0].date !== "" &&
            activitySelections[0]?.action !== "remove" &&
            activitySelections[0]?.on.length <=
              reservationDetails.Nights -
                passedDaysNumber +
                activitySelections[0]?.on.filter(
                  (s) => s.date !== "" && s.date < getCurrentDate()
                ).length &&
            !isNaN(selectedService?.Price) &&
          <IonRow className={"bl-margin-b-xs"}>
            <IonCol>
              <BLButton
                type="icon-text"
                icon={addCircle}
                iconSize="md"
                content={"Add more days"}
                onClick={addSeparateDays}
              />
            </IonCol>
          </IonRow>
        }
      </IonGrid>
    </IonCol>
  );
};

export default DaysRowContent;