import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import BLIcon from "../BLIcon";
import { arrowBackCircle, arrowForwardCircle } from "ionicons/icons";

import "swiper/swiper.min.css";
import styles from "./BLImageCarousel.module.scss";

const autoplayConfig = {
  delay: 5000,
  disableOnInteraction: false,
};

const paginationConfig = {
  clickable: true,
};

const paginationStyles = {
  default: {
    "--swiper-pagination-color": "#FFF",
    "--swiper-pagination-bullet-inactive-color": "#FFFFFF",
    "--swiper-pagination-bullet-inactive-opacity": "0.7",
    "--swiper-pagination-bullet-size": "10px",
    "--swiper-pagination-bullet-horizontal-gap": "6px",
  }
};

const navgivationConfig = {
  nextEl: `.slider-next-arrow`,
  prevEl: ".slider-prev-arrow",
};

const BLImageCarousel = ({
  content = [],
  autoplay,
  hideNavigation,
  hidePagination,
  borderRadius = "0px 0px 0px 0px",
  paginationType = "default",
}) => {
  const [sliderRef, setSliderRef] = useState({});
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const hideNav = hideNavigation || content.length <= 1;

  const stopPropagation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const handleNext = (event) => {
    sliderRef?.slideNext();
    stopPropagation(event);
  };

  const handlePrev = (event) => {
    sliderRef?.slidePrev();
    stopPropagation(event);
  };
  const isFirst = currentSlideIndex === 0;
  const isLast = currentSlideIndex === content.length - 1;

  const prevBtn = (
    <BLIcon
      newClasses={styles["slider-prev-arrow"]}
      color={"white"}
      iconSource={arrowBackCircle}
      disable={isFirst}
      onClick={(event) => isFirst ? null : handlePrev(event)}
    />
  );

  const nextBtn = (
    <BLIcon
      newClasses={styles["slider-next-arrow"]}
      color={"white"}
      iconSource={arrowForwardCircle}
      disable={isLast}
      onClick={(event) => isLast ? null : handleNext(event)}
    />
  );
  
  return (
    <>
      {!hideNav && prevBtn}
      <Swiper
        className={styles.swiperContainer}
        style={{
          borderRadius,
          ...paginationStyles[paginationType],
        }}
        onInit={(ev) => {
          setSliderRef(ev);
          ev.on("slideChange", function () {
            setCurrentSlideIndex(ev.realIndex);
          });
        }}
        centeredSlides={true}
        initialSlide={0}
        autoplay={autoplay ? autoplayConfig : false}
        pagination={!hidePagination ? paginationConfig : false}
        navigation={hideNav ? false : navgivationConfig}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {content.map((img, index) => {
          return (
            <SwiperSlide
              key={index}
              className={styles.swiperSlide}
              style={{ backgroundImage: `url(${img?.formats?.small?.url || img?.url})` }}
            />
          );
        })}
      </Swiper>
      {!hideNav && nextBtn}
    </>
  );
};
export default BLImageCarousel;
