import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { ITEM_POPULAR_STATUSES, SERVICES_TYPES } from "../../../constants/constants";
import { useDataContext } from "../../../context/DataContext";
import BLInformationBox from "../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import BLExpandableBox from "../../../shared/Ionic/Cards/BLExpandableBox/BLExpandableBox";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import { EntertainmentsPageContext } from "./EntertainmentsPageContext";
import ShowsRow from "./components/BookingSection/ShowsRow/ShowsRow";
import ShowsExpandedCard from "./components/Cards/ShowsExpandedCard";
import ShowsSummaryCard from "./components/Cards/ShowsSummaryCard";
import ShowsSummaryCardMobile from "./components/Cards/ShowsSummaryCardMobile";

import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { getCurrentDate } from "../../../helpingFunctions/dateTimeUtilities";
import BLLoadingContainer from "../../../shared/Ionic/BLLoadingContainer";
import BLTooltip from "../../../shared/Ionic/Boxes/Popup/BLTooltip";
import BLButton from "../../../shared/Ionic/Buttons/BLButton";
import EntertainmentsPageLogic from "./EntertainmentsPageLogic";
import ShowsDetails from "./components/Cards/ShowsDetails";
import EntertainmentChange from "./components/EntertainmentPreview/EntertainmentChange";
import StayNavigator from "./components/StayNavigator/StayNavigator";
import { NO_SHOWS_TEXT } from "./constants";
import { expandCollapse } from "../../../helpingFunctions/helpingFunctions";
import ConflictPopupBoxes from "../../../helpingFunctions/conflictManagement/ConflictPopupBoxes";
import { isDesktop } from "../../../helpingFunctions/utilities";
import Days from "./components/BookingSection/Days/Days";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import { useTheme } from "../../../hooks/useTheme";
import ServicePageHeader from "../../../shared/Ionic/Wrappers/ServicePageHeader";
import { ROUTE_VIEWS } from "../../../routes/routeViews";

const EntertainmentsPage = () => {
  const { typography } = useTheme();
  const history = useHistory();
  const {
    dynamicTexts,
    isLoadingItinerary,
    isLoadingAvailability,
    reservationDetails,
    availability,
  } = useDataContext();
  const { warnerServices } = useContext(NavigatorContext);
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const [locationDetails, setLocationDetails] = useState();
  const { isSummaryCardDisabled, isThursdayOrSaturday, isShowAlreadyBooked, prepareShows } =
    EntertainmentsPageLogic();
  const { showsRowTitle } = ShowsRow();
  const {
    entertainmentShowHours,
    setEntertainmentShowHours,
    selectedEntertainmentShow,
    setSelectedEntertainmentShow,
    entertainmentBookingSelections,
    selectedDate,
    skippedDays,
    handleSelectShow,
    mainStatus,
    entertainmentDetails,
    entertainmentLocationDetails,
    daysRowCollapsedTitle,
    daysRowExpandedTitle,
    getAdjustedAvailability,
    clickedRow, 
    setClickedRow,
  } = useContext(EntertainmentsPageContext);

  useEffect(() => {
    if (warnerServices.length === 0 || !entertainmentDetails) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Entertainments Page");
    } else {
      setIsLoaderOpened(false);
    }
  }, [warnerServices, entertainmentDetails]);

  useEffect(() => {
    if (selectedDate && availability?.Venue) {
      const adjustedEntertainmentAvailability = getAdjustedAvailability();
      const shows = prepareShows(adjustedEntertainmentAvailability);
      setEntertainmentShowHours(shows);
    }
  }, [selectedDate, availability]);

  useEffect(() => {
    if (entertainmentShowHours.length) {
      if (isDesktop()) {
        setSelectedEntertainmentShow(entertainmentShowHours[0]);
      } else {
        setSelectedEntertainmentShow({});
      }
    }
  }, [selectedDate, entertainmentShowHours]);

  useEffect(() => {
    if (entertainmentLocationDetails) {
      let entertainment = (entertainmentLocationDetails ?? []).find(
        (ent) => ent.ActivityId === selectedEntertainmentShow?.venueId
      );
      setLocationDetails(entertainment);
    }
  }, [selectedEntertainmentShow]);

  return (
    reservationDetails && (
      <IonPage style={{ overflow: "auto" }}>
        <ConflictPopupBoxes />
        <ServicePageHeader
          service={SERVICES_TYPES.ENTERTAINMENT}
          onClick={() => history.push(history.location.state.isItineraryRedirect ? ROUTE_VIEWS.ITINERARY : ROUTE_VIEWS.THINGS_TO_DO)}
        />
        {getCurrentDate() === reservationDetails.DepartureDate.slice(0, 10) && (
          <IonGrid>
            <IonRow className="bl-margin-t-m bl-padding-m">
              <BLInformationBox
                content={
                  dynamicTexts?.Shared?.DiningAndEntertainment
                    ?.InfoBox_Intro_DepartureDateRestriction
                }
                type="info"
                markdown
              />
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <BLButton
                content="Go back"
                type="underlined"
                onClick={() => history.goBack()}
              />
            </IonRow>
          </IonGrid>
        )}
        {!showAdditionalDetails &&
          !showLocationDetails &&
          getCurrentDate() !==
          reservationDetails.DepartureDate.slice(0, 10) &&
          (
            <IonGrid className="bl-padding-s">
              {((mainStatus === ITEM_POPULAR_STATUSES.INIT &&
                !reservationDetails.Venue) ||
                mainStatus === ITEM_POPULAR_STATUSES.EDITING) &&
                (
                  <>
                    <IonRow>
                      <IonCol size="12" className="ion-text-left ion-padding-top">
                        <BLParagraph
                          color="text-grey"
                          textVariant={typography.BODY_TEXT}
                          txt={
                            dynamicTexts?.Entertainments
                              ?.Paragraph_IntroDescription
                          }
                          markDown={
                            !!dynamicTexts?.Entertainments
                              ?.Paragraph_IntroDescription
                          }
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow className="bl-margin-b-s">
                      <BLExpandableBox
                        value="daysRow"
                        newClass="ExpandableBox_second"
                        dimensions={{
                          padding: ["l-s", "r-s", "t-s"],
                        }}
                        collapsedTitle={daysRowCollapsedTitle}
                        expandedTitle={daysRowExpandedTitle}
                        isClicked={clickedRow === "daysRow"}
                        onClick={() => expandCollapse("daysRow", clickedRow, setClickedRow)}
                      >
                        <Days />
                      </BLExpandableBox>
                    </IonRow>
                    <IonRow className="bl-margin-b-s">
                      <BLExpandableBox
                        value="showsRow"
                        newClass="ExpandableBox_second"
                        dimensions={{
                          padding: ["l-s", "r-s", "t-s"],
                        }}
                        collapsedTitle={showsRowTitle}
                        expandedTitle={showsRowTitle}
                        isClicked={
                          clickedRow === "showsRow" &&
                          !skippedDays.includes(selectedDate)
                        }
                        onClick={() => expandCollapse("showsRow", clickedRow, setClickedRow)}
                        isDisabled={
                          skippedDays.includes(selectedDate) ||
                          isLoadingItinerary ||
                          isLoadingAvailability
                        }
                      >
                        {entertainmentShowHours &&
                          entertainmentShowHours.length === 0 ?
                          (
                            <IonGrid>
                              <IonRow className="bl-margin-t-s">
                                <IonCol size="12">
                                  <BLInformationBox
                                    content={NO_SHOWS_TEXT}
                                    type="warning"
                                  />
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          ) : isLoadingItinerary ? (
                            <div style={{ margin: "0 auto" }}>
                              <BLLoadingContainer />
                            </div>
                          ) : (
                            <>
                              {isDesktop() ? (
                                <>
                                  <IonGrid>
                                    <IonRow>
                                      <IonGrid>
                                        <IonRow
                                          className="bl-margin-t-s bl-padding-b-s"
                                        >
                                          <IonCol size="12">
                                            <BLInformationBox
                                              content={
                                                isThursdayOrSaturday
                                                  ? dynamicTexts?.Entertainments
                                                    ?.Booking
                                                    ?.InfoBox_Shows_ThuAndSatRestriction
                                                  : dynamicTexts?.Entertainments
                                                    ?.Booking
                                                    ?.InfoBox_Shows_EverydayRestriction
                                              }
                                              type="info"
                                              markdown
                                            />
                                          </IonCol>
                                        </IonRow>
                                      </IonGrid>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol size="4">
                                        <IonGrid>
                                          {entertainmentShowHours.map(
                                            (showHours) => {
                                              return (
                                                <IonRow
                                                  className="bl-padding-b-s"
                                                  id={
                                                    isSummaryCardDisabled(showHours)
                                                      .isDisabled
                                                      ? `${showHours.title}${showHours.Time}${showHours.venueId}`
                                                      : `no_trig${showHours.title}${showHours.Time}${showHours.venueId}`
                                                  }
                                                  key={`no_trig_${showHours.title}_${showHours.Time}_${showHours.venueId}`}
                                                >
                                                  <BLTooltip
                                                    txt={
                                                      isSummaryCardDisabled(
                                                        showHours
                                                      ).reason
                                                    }
                                                    disableHover={
                                                      !isSummaryCardDisabled(
                                                        showHours
                                                      ).reason
                                                    }
                                                  >
                                                    <Box style={{ width: "100%" }}>
                                                      <ShowsSummaryCard
                                                        onClick={() => {
                                                          setSelectedEntertainmentShow(
                                                            showHours
                                                          );
                                                        }}
                                                        isDisabled={() =>
                                                          isSummaryCardDisabled(
                                                            showHours
                                                          ).isDisabled
                                                        }
                                                        isClicked={() => {
                                                          return (
                                                            showHours.Time ===
                                                            selectedEntertainmentShow.Time &&
                                                            showHours.venueId ===
                                                            selectedEntertainmentShow.venueId
                                                          );
                                                        }}
                                                        isAlreadyBooked={isShowAlreadyBooked(
                                                          showHours
                                                        )}
                                                        title={showHours.title}
                                                        timeText={showHours.Time}
                                                        locationText={
                                                          showHours.location
                                                        }
                                                        availability={
                                                          showHours.Availability
                                                        }
                                                        isSelected={
                                                          entertainmentBookingSelections.findIndex(
                                                            (el) =>
                                                              el.time ===
                                                              showHours.Time &&
                                                              el.venueId ===
                                                              showHours.venueId &&
                                                              el.date ===
                                                              selectedDate &&
                                                              el.action !== "remove"
                                                          ) !== -1
                                                        }
                                                      />
                                                    </Box>
                                                  </BLTooltip>
                                                </IonRow>
                                              );
                                            }
                                          )}
                                        </IonGrid>
                                      </IonCol>
                                      <IonCol
                                        size="8"
                                        style={{ paddingLeft: 16, paddingRight: 2 }}
                                      >
                                        {selectedEntertainmentShow?.title && (
                                          <ShowsExpandedCard
                                            image={
                                              selectedEntertainmentShow.showImages &&
                                                selectedEntertainmentShow.showImages
                                                  .length > 0
                                                ? selectedEntertainmentShow.showImages
                                                : selectedEntertainmentShow.locationImages
                                            }
                                            title={selectedEntertainmentShow.title}
                                            timeText={
                                              selectedEntertainmentShow.Time
                                            }
                                            locationText={
                                              selectedEntertainmentShow.location
                                            }
                                            description={
                                              selectedEntertainmentShow.description
                                            }
                                            availability={
                                              selectedEntertainmentShow.Availability
                                            }
                                            isAlreadyBooked={isShowAlreadyBooked(
                                              selectedEntertainmentShow
                                            )}
                                            handleSelection={() => {
                                              setSelectedEntertainmentShow({
                                                ...selectedEntertainmentShow,
                                                isSelected: true,
                                              });
                                              handleSelectShow(
                                                selectedEntertainmentShow
                                              );
                                            }}
                                            isDisabled={() =>
                                              isSummaryCardDisabled(
                                                selectedEntertainmentShow
                                              ).isDisabled
                                            }
                                            selectedEntertainmentShow={
                                              selectedEntertainmentShow
                                            }
                                            setShowLocationDetails={
                                              setShowLocationDetails
                                            }
                                            setShowAdditionalDetails={
                                              setShowAdditionalDetails
                                            }
                                          />
                                        )}
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </>
                              ) : (
                                <IonGrid
                                  style={{
                                    display: "flex",
                                    gap: 5,
                                    flexDirection: "column",
                                  }}
                                >
                                  <IonRow>
                                    <IonGrid>
                                      <IonRow className="bl-margin-t-s">
                                        <IonCol
                                          size="12"
                                          style={{ paddingBottom: "10px" }}
                                        >
                                          <BLInformationBox
                                            content={
                                              isThursdayOrSaturday
                                                ? dynamicTexts?.Entertainments
                                                  ?.Booking
                                                  ?.InfoBox_Shows_ThuAndSatRestriction
                                                : dynamicTexts?.Entertainments
                                                  ?.Booking
                                                  ?.InfoBox_Shows_EverydayRestriction
                                            }
                                            type="info"
                                            markdown
                                          />
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </IonRow>
                                  <IonRow>
                                    <IonCol size="12">
                                      <IonGrid
                                        style={{
                                          display: "flex",
                                          gap: 16,
                                          flexDirection: "column",
                                        }}
                                      >
                                        {entertainmentShowHours.map(
                                          (showHours, index) => {
                                            return (
                                              <IonRow
                                                id={`${showHours.title}${showHours.Time}${showHours.venueId}`}
                                                key={`${showHours.title}_${showHours.Time}_${showHours.venueId}`}
                                              >
                                                <IonCol>
                                                  <IonGrid
                                                    style={{
                                                      display: "flex",
                                                      gap: 16,
                                                      flexDirection: "column",
                                                    }}
                                                  >
                                                    <IonCol size="12">
                                                      <ShowsSummaryCardMobile
                                                        id={`${showHours.title}${showHours.Time}${showHours.venueId}${selectedDate}`}
                                                        key={index}
                                                        onClick={() => {
                                                          setSelectedEntertainmentShow(
                                                            showHours
                                                          );
                                                        }}
                                                        showDetails={showHours}
                                                        isAlreadyBooked={isShowAlreadyBooked(
                                                          showHours
                                                        )}
                                                        locationDetails={
                                                          locationDetails
                                                        }
                                                        selectedShow={
                                                          selectedEntertainmentShow
                                                        }
                                                        availability={
                                                          showHours.Availability
                                                        }
                                                        handleSelection={() => {
                                                          setSelectedEntertainmentShow(
                                                            {
                                                              ...selectedEntertainmentShow,
                                                              isSelected: true,
                                                            }
                                                          );
                                                          handleSelectShow(
                                                            selectedEntertainmentShow
                                                          );
                                                        }}
                                                        isDisabled={() =>
                                                          isSummaryCardDisabled(
                                                            showHours
                                                          ).isDisabled
                                                        }
                                                        disabledText={
                                                          isSummaryCardDisabled(
                                                            showHours
                                                          ).reason
                                                        }
                                                        isClicked={() => {
                                                          return (
                                                            showHours.Time ===
                                                            selectedEntertainmentShow.Time &&
                                                            showHours.venueId ===
                                                            selectedEntertainmentShow.venueId
                                                          );
                                                        }}
                                                        isSelected={
                                                          entertainmentBookingSelections.findIndex(
                                                            (el) =>
                                                              el.time ===
                                                              showHours.Time &&
                                                              el.venueId ===
                                                              showHours.venueId &&
                                                              el.date ===
                                                              selectedDate &&
                                                              el.action !== "remove"
                                                          ) !== -1
                                                        }
                                                      />
                                                    </IonCol>
                                                  </IonGrid>
                                                </IonCol>
                                              </IonRow>
                                            );
                                          }
                                        )}
                                      </IonGrid>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              )}
                            </>
                          )}
                      </BLExpandableBox>
                    </IonRow>
                  </>
                )}
              {mainStatus === ITEM_POPULAR_STATUSES.INIT &&
                reservationDetails.Venue && <EntertainmentChange />}
              <StayNavigator />
            </IonGrid>
          )}

        {showAdditionalDetails && (
          <ShowsDetails
            showAlert={showAdditionalDetails}
            dismissAlert={() => setShowAdditionalDetails(false)}
            images={
              selectedEntertainmentShow.showImages
                ? selectedEntertainmentShow.showImages
                : selectedEntertainmentShow.locationImages
            }
            header={selectedEntertainmentShow.title}
            longDescription={selectedEntertainmentShow.description}
            location={locationDetails?.Location}
          />
        )}
        {showLocationDetails && (
          <ShowsDetails
            showAlert={showLocationDetails}
            dismissAlert={setShowLocationDetails}
            images={locationDetails?.LocationImages}
            header={locationDetails?.Location}
            longDescription={locationDetails?.LocationDescription}
          />
        )}
      </IonPage>
    )
  );
};

export default EntertainmentsPage;
