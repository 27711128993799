import { IonGrid, IonRow } from "@ionic/react";
import React, { Fragment, useContext } from "react";
import { useDataContext } from "../../../../../../../context/DataContext";
import { SpaContext } from "../../../../../../../context/SpaContext";
import BLParagraph from "../../../../../../../shared/Ionic/Paragraph/BLParagraph";
import TimeRowSeparateSelectionLogic from "./TimeRowSeparateSelectionLogic";
import { useTheme } from "../../../../../../../hooks/useTheme";

const TimeRowSeparateSelection = (extractTimeSelections, serviceDetails) => {
  const { typography } = useTheme();
  const { spaSelections, daySelections } = useContext(SpaContext);
  const { reservationDetails } = useDataContext();
  const { retrieveContent } = TimeRowSeparateSelectionLogic(
    extractTimeSelections,
    serviceDetails
  );

  const timeRowSeparatedContent = (
    <>
      {spaSelections.map((sel, selIndex) => (
        <IonGrid key={sel.uniqueKey || selIndex}>
          {sel.on.map((when, selTimeIndex) => (
            <Fragment key={when.uniqueKey || selTimeIndex}>
              {when.date !== "" && (
                <>
                  <IonRow
                    className="bl-margin-b-xs bl-margin-t-l"
                    style={{ gap: "8px", flexDirection: "column" }}
                  >
                    <BLParagraph
                      txt={`${
                        sel.guestName ===
                        reservationDetails.FirstName + " " + reservationDetails.LastName
                          ? sel.guestName + " (you)"
                          : sel.guestName
                      } `}
                      color="text-grey"
                      textVariant={typography.h4}
                      //newClasses="small bold"
                      //TODO: Unclear typography
                    />
                  </IonRow>
                  <IonRow
                    className="ion-justify-content-end bl-margin-t-xs"
                    style={{ borderBottom: "1px solid var(--ion-color-primary)" }}
                  >
                    <BLParagraph
                      txt={
                        daySelections?.find((row) => row.id === when.date)
                          ?.fullTitle
                      }
                      color="primary"
                      textVariant={typography.h4}
                    />
                  </IonRow>

                  {retrieveContent(
                    when.date,
                    selIndex,
                    selTimeIndex,
                    when.action
                  )}
                </>
              )}
            </Fragment>
          ))}
        </IonGrid>
      ))}
    </>
  );

  return {
    timeRowSeparatedContent,
  };
};

export default TimeRowSeparateSelection;
