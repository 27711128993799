import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { compare } from "../helpingFunctions/arrays";
import axiosInstance from "../url/createAxios";
import { ERROR_TEXTS } from "../constants/errorConstants";

export const WhatsOnDataContext = createContext({});

export const WhatsOnDataProvider = ({ children }) => {
  const instance = axiosInstance();
  const { pathname } = useLocation();
  const isAdminPage = pathname.includes("team");
  const isInPublicPage = pathname.includes("public");

  const [guide, setGuide] = useState([]);
  const [whatsOnGuideData, setWhatsOnGuideData] = useState([]);
  const [whatsOnGuideAllDayActivities, setWhatsOnGuideAllDayActivities] = useState([]);
  const [whatsOnGuideAllDayActivitiesStatic, setWhatsOnGuideAllDayActivitiesStatic] = useState([]);
  const [whatsOnGuideDataStatic, setWhatsOnGuideDataStatic] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [comingSoonData, setComingSoonData] = useState({});
  const [ads, setAds] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [dates, setDates] = useState([]);
  const [alert, setAlert] = useState({
    isError: false,
  });
  const [selectedDate, setSelectedDate] = useState(null);

  const manageFilters = (filters) => {
    setSelectedFilters(filters);
  };

  const getGuide = async (params) => {
    try {
      setIsLoading(true);
      const { data } = await instance.get(
        `/internal-activity-availabilities/hotel/range/${params.hotel}`,
        { params: { showDates: params.showDates } }
      );
      const [firstDate] = params.showDates;

      const allDayActivities = await instance.get(
        `/all-day-activities/${params.hotel}`
      );

      await handleAdditionalData(data.length === 0 && !isAdminPage);

      setWhatsOnGuideData(data);
      setWhatsOnGuideDataStatic(data);
      setWhatsOnGuideAllDayActivities(allDayActivities?.data.data);
      setWhatsOnGuideAllDayActivitiesStatic(allDayActivities?.data.data);
      setDates(params.showDates);
      dateSelectedHandler(firstDate);
      setIsLoading(false);
    } catch (error) {
      await handleAdditionalData(true);
    }
  };

  const handleAdditionalData = async (isEmpty) => {
    isEmpty ? await getComingSoonInfo() : await getAds();
  };

  const getComingSoonInfo = async () => {
    const { data } = await instance.get("/pages-configuration/whats-on-guide-page/coming-soon");

    setComingSoonData(data);
  };

  const getAds = async () => {
    const { data } = await instance.get(`/pages-configuration/whats-on-guide-page/ads`);

    setAds(data.Ads);
  };

  const dateSelectedHandler = (firstDate) => {
    if (selectedDate?.endsWith("I")) {
      setSelectedDate(selectedDate.substr(0, selectedDate.length - 1));
    } else {
      setSelectedDate(firstDate);
    }
  };

  const prepareCurrentDayGuide = (guideData, date) => {
    const hasFilteres = Object.keys(selectedFilters).length > 0;

    const availability = guideData
      .map(({ Availabletimes, id }) => {
        if (Availabletimes) {
          const availabilitiy = Availabletimes?.availability || [];
          const [item] = availabilitiy.filter((availableItem) =>
            availableItem.date.dates.includes(date?.replaceAll(/-/gi, "/"))
          );

          if (item) {
            const itemTimesDetails = item.availability
              .map(({ times }) => {
                for (let index = 0; index < times.length; index++) {
                  times[index].recordId = id;
                }
                return times;
              })
              .flat();

            return itemTimesDetails;
          }
        }
      })
      .filter((i) => i)
      .flat();

    const cleanedAvailability = availability.filter(({ time, activityId }) => {
      const hasRequiredAttrs = time && activityId;

      if (isInPublicPage) {
        const isInFuture =
          new Date().getTime() <=
          new Date(`${selectedDate}T${time}`)?.getTime();

        return isInFuture && hasRequiredAttrs;
      }

      return hasRequiredAttrs;
    });

    const sortedAvailability = cleanedAvailability.sort((a, b) =>
      compare(a.time, b.time, "asc")
    );

    if (sortedAvailability.length <= 0) {
      setAlert({
        isError: true,
        msg: hasFilteres ? ERROR_TEXTS.ACTIVITY_FILTER_ERROR : ERROR_TEXTS.ACTIVITY_NOT_CREATED_ERROR,
      });
      setGuide([]);
    } else {
      setAlert({
        isError: false,
      });
      setGuide(sortedAvailability);
    }
  };

  const updateWhatsOnGuideDisplay = (data) => {
    setWhatsOnGuideData([...data]);
    prepareCurrentDayGuide(data, selectedDate);
  };

  useEffect(() => {
    if (selectedDate && !selectedDate.endsWith("I") && dates.length > 0) {
      prepareCurrentDayGuide(whatsOnGuideData, selectedDate);
    }
  }, [dates, selectedDate, whatsOnGuideDataStatic]);

  return (
    <WhatsOnDataContext.Provider
      value={{
        whatsOnGuideAllDayActivities,
        setWhatsOnGuideAllDayActivities,
        whatsOnGuideAllDayActivitiesStatic,
        setWhatsOnGuideAllDayActivitiesStatic,
        getGuide,
        updateWhatsOnGuideDisplay,
        manageFilters,
        whatsOnGuideData,
        whatsOnGuideDataStatic,
        comingSoonData,
        ads,
        guide,
        selectedFilters,
        dates,
        alert,
        setAlert,
        isLoading,
        selectedDate,
        setSelectedDate
      }}
    >
      {children}
    </WhatsOnDataContext.Provider>
  );
};
