import { IonCol, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import { addCircle, removeCircle } from "ionicons/icons";
import { useDataContext } from "../../../../../context/DataContext";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";
import { NavigatorContext } from "../../../../../context/NavigatorContext";
import { ROUTE_VIEWS } from "../../../../../routes/routeViews";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import useDiningPartyRequests from "../../useDiningPartyRequests";
import ActionButtons from "../ActionButtons/ActionButtons";
import CreateDiningPartyLogic from "./CreateDiningPartyLogic";
import { DINING_PARTY_SAVE_CHANGES_TEXT } from "../../diningPartyConstants";

const ModifyDiningParty = () => {
  const { createGroup } = useDiningPartyRequests();
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const { cancelAddedParticipants } = CreateDiningPartyLogic();

  const {
    reservationDetails: { PartOfGroup, isGroupCreator },
    setReservationDetails,
  } = useDataContext();
  const {
    addMoreHandler,
    isUnchangedDiningParty,
    removeDiningParty,
    isGroupActionLoading,
    isPostGrCreationPartAddedLoading,
    response,
    setShowDiningPartyParticipantForm,
    diningPartyDetails
  } = useContext(DiningPartyContext);
  return (
    <>
      <IonRow className="ion-align-items-center bl-padding-t-s">
        <IonCol size="auto">
          <BLButton
            type="icon-text"
            iconSize="md"
            icon={addCircle}
            content="Add more guests"
            onClick={addMoreHandler.bind(null, PartOfGroup)}
            disabled={
              isGroupActionLoading ||
              isPostGrCreationPartAddedLoading ||
              response.isLoading
            }
          />
        </IonCol>
      </IonRow>
      <IonRow className="ion-align-items-center">
        <IonCol size="auto">
          <BLButton
            type="icon-text"
            iconSize="md"
            icon={removeCircle}
            content="Remove entire dining party"
            onClick={removeDiningParty}
            disabled={
              isGroupActionLoading ||
              isPostGrCreationPartAddedLoading ||
              response.isLoading
            }
          />
        </IonCol>
      </IonRow>

      <ActionButtons
        actionTxt={DINING_PARTY_SAVE_CHANGES_TEXT}
        shouldDisable={isUnchangedDiningParty}
        isActionInProgress={
          isGroupActionLoading ||
          isPostGrCreationPartAddedLoading ||
          response.isLoading
        }
        cancelFunction={() => {
          if (!diningPartyDetails.id) {
            setReservationDetails((prevState) => {
              return {
                ...prevState,
                IsGroupCreator: false,
                PartOfGroup: false,
              };
            });
          }
          cancelAddedParticipants();
          setShowDiningPartyParticipantForm(false);
          redirectBetweenPages(ROUTE_VIEWS.DINING);
        }}
        actionFunction={createGroup}
        cancelTxt="Go back"
        hideActionBtn={isGroupCreator}
      />
    </>
  );
};

export default ModifyDiningParty;
