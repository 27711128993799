import { IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import CreateDimensions from "../../../../helpingFunctions/DimensionsFactory";
import {
  CHECKBOX_CLASS_IDENTIFIER,
  TEXT_CSS_CLASS_IDENTIFIER,
} from "../../BLIonicConstants";
import { classFormatter, cssModuleClassConvert } from "../../BLIonicHelpers";

import styles from "./BLCheckBox.module.scss";

const BLCheckBox = ({
  txt,
  newClasses,
  dimensions,
  checkSlot,
  color,
  labelColor,
  onClick,
  checked,
  labelNewClasses,
  newStyles,
  checkboxClasses,
  disabled,
}) => {
  const { dimensions: checkBoxDimensionStyles } = CreateDimensions(dimensions);

  const checkBoxSpecificClasses = classFormatter(
    newClasses,
    CHECKBOX_CLASS_IDENTIFIER
  );
  const checkboxClaases = classFormatter(
    checkboxClasses,
    CHECKBOX_CLASS_IDENTIFIER
  );

  const labelSpecificClasses = classFormatter(
    labelNewClasses,
    TEXT_CSS_CLASS_IDENTIFIER
  );
  const moduleFriendlyClasses = cssModuleClassConvert(
    styles,
    checkBoxSpecificClasses
  );

  return (
    <IonItem
      className={`${styles.BLCheckBox} ${checkBoxDimensionStyles} ${moduleFriendlyClasses}`}
      color={color}
      lines="none"
      disabled={disabled}
      style={{ ...newStyles }}
    >
      <IonCheckbox
        className={checkboxClaases.split(" ")[0]}
        slot={checkSlot}
        checked={checked}
        onClick={onClick}
        style={{ minWidth: "24px" }}
        aria-label={txt}
      ></IonCheckbox>
      {txt && (
        <IonLabel
          color={labelColor}
          className={`${labelSpecificClasses} bl-margin-l-s bl-text-regular`}
        >
          {txt}
        </IonLabel>
      )}
    </IonItem>
  );
};

export default BLCheckBox;
