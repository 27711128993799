import { IonContent, createGesture, isPlatform } from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Route } from "react-router-dom";
import ScrollToTop from "../hooks/ScrollToTop";
import HelpPage from "../views/children/HelpPage_OLD/HelpPage";
import Kiosk from "../views/children/Kiosk_OLD/Kiosk";
import Login from "../views/children/LoginPage/Login";
import MaintenancePage from "../views/children/MaintenancePage/MaintenancePage";
import PromotionalPage from "../views/children/PromotionalPage_OLD/PromotionalPage";
import RedirectToLandingPage from "../views/children/RedirectToLandingPage/RedirectToLandingPage";
import WhatsOnGuide from "../views/children/WhatsOnGuidePage/WhatsOnGuide";

import { Redirect, Switch, useHistory } from "react-router-dom";
import DesktopFooter from "../components/Footer/DesktopFooter";
import MobileFooter from "../components/Footer/MobileFooter";
import LegalDocDialog from "../components/LegalDocDialog/LegalDocDialog";
import Header from "../components/MainTitleIonic/Header";
import { IOS_PLATFORM, SERVICES_TYPES } from "../constants/constants";
import { ConflictManagementProvider } from "../context/ConflictManagementContext";
import { DiningPartyProvider } from "../context/DiningPartyContext";
import { MaintenanceContext } from "../context/MaintenanceContext";
import { MapImagePopupProvider } from "../context/MapImagePopupContext";
import NavigatorProvider from "../context/NavigatorContext";
import { SideMenuProvider } from "../context/SideMenuContext";
import { WhatsOnDataProvider } from "../context/WhatsOnDataContext";
import { ThemeContextProvider } from "../context/ThemeContext";
import { isDesktop } from "../helpingFunctions/utilities";
import SideMenu from "../shared/Ionic/SideMenu/SideMenu";
import FullScreenWrapper from "../shared/Ionic/Wrappers/FullScreenWrapper/FullScreenWrapper";
import PlatformDependentPage from "../views/Wrappers/PlatformDependentPage";
import BookingFolioPage from "../views/children/BookingFolioPage/BookingFolioPage";
import EntertainmentsPage from "../views/children/EntertainmentsPage/EntertainmentsPage";
import EntertainmentsPageProvider from "../views/children/EntertainmentsPage/EntertainmentsPageContext";
import Golf from "../views/children/GolfPage/Golf";
import HotelInfoPage from "../views/children/HotelInfoPage/HotelInfoPage";
import Itinerary from "../views/children/ItineraryPage/Itinerary";
import LandingPage from "../views/children/LandingPage/LandingPage";
import MapPage from "../views/children/MapPage/MapPage";
import RegisterCardPage from "../views/children/RegisterCardPage/RegisterCardPage";
import ThingsToDoPage from "../views/children/ThingsToDoPage/ThingsToDoPage";
import ActivitiesRoutes from "./ActivitiesRoutes";
import DiningRoutes from "./DiningRoutes";
import SpaRoutes from "./SpaRoutes";
import TreatsRoutes from "./TreatsRoutes";
import FallbackRoute from "./FallbackRoute";
import RouteLogic from "./RouteLogic";
import { ROUTE_VIEWS } from "./routeViews";

const AppRoutes = () => {
  const { maintenanceData } = useContext(MaintenanceContext);
  const [isLoadingDeeplink, setIsLoadingDeeplink] = useState(false);
  const contentRef = useRef(null);
  const history = useHistory();
  const { isAuthenticated } = RouteLogic();
  //This is currently needed for iOS swipe back/forward functionality
  useEffect(() => {
    if (isPlatform(IOS_PLATFORM)) {
      gestureInit();
    }
  }, [contentRef]);

  const gestureInit = () => {
    if (contentRef?.current) {
      const gesture = createGesture({
        el: contentRef.current,
        gestureName: "swipe",
        onEnd: (e) => {
          if (e.deltaX > 100 && e.startX < 100) {
            history.goBack();
          } else if (e.deltaX < -100 && e.startX > 250) {
            history.goForward();
          }
        },
      });
      gesture.enable();
    }
  };

  const deepLinkRoute = (
    <Route
      path={ROUTE_VIEWS.DEEP_LINK}
      exact={true}
      render={() => (
        <RedirectToLandingPage setIsLoadingDeeplink={setIsLoadingDeeplink} />
      )}
    />
  );

  return (
    <ThemeContextProvider>
      <NavigatorProvider>
        <SideMenuProvider>
          <SideMenu />
          <FullScreenWrapper />
          <LegalDocDialog />
          <ScrollToTop />
          {!maintenanceData && <Header isLoadingDeeplink={isLoadingDeeplink} />}
          <IonContent
            id="content"
            ref={contentRef}
            scroll-y="false"
            style={{ "--keyboard-offset": "0px !important" }}
          >
            <Switch>
              {/* Routes without authentication needed */}
              <Route
                exact={true}
                path={"/"}
                render={() => <Redirect to={ROUTE_VIEWS.LOGIN} />}
              />
              <Route
                exact={true}
                path={ROUTE_VIEWS.LOGIN}
                render={() =>
                  isAuthenticated ? (
                    <Redirect to={ROUTE_VIEWS.LANDING_PAGE} />
                  ) : (
                    <Login />
                  )
                }
              />
              <Route
                path={ROUTE_VIEWS.MAINTENANCE}
                exact={true}
                component={MaintenancePage}
              />
              <Route
                path={ROUTE_VIEWS.HELP}
                exact={true}
                component={HelpPage}
              />
              <Route
                path={ROUTE_VIEWS.SERVE}
                exact={true}
                component={PromotionalPage}
              />
              <Route path={ROUTE_VIEWS.KIOSK} exact={true} component={Kiosk} />
              <Route
                exact
                path={ROUTE_VIEWS.WHATS_ON_GUIDE_PUBLIC}
                render={() => (
                  <WhatsOnDataProvider>
                    <WhatsOnGuide />
                  </WhatsOnDataProvider>
                )}
              />
              <Route
                exact
                path={ROUTE_VIEWS.WHATS_ON_GUIDE_TEAM}
                render={() => (
                  <WhatsOnDataProvider>
                    <WhatsOnGuide />
                  </WhatsOnDataProvider>
                )}
              />
              {/* Routes with authentication */}
              {isAuthenticated ? (
                <PlatformDependentPage>
                  <Switch>
                    <Route
                      exact={true}
                      path={ROUTE_VIEWS.LANDING_PAGE}
                      component={LandingPage}
                    />
                    <Route
                      path={ROUTE_VIEWS.DINING}
                      component={DiningRoutes}
                    />
                    <Route
                      path={ROUTE_VIEWS.THINGS_TO_DO}
                      exact={true}
                      component={ThingsToDoPage}
                    />
                    <Route
                      path={ROUTE_VIEWS.ENTERTAINMENT}
                      exact={true}
                      render={() => (
                        <ConflictManagementProvider
                          conflictOccuringView={SERVICES_TYPES.ENTERTAINMENT}
                        >
                          <EntertainmentsPageProvider>
                            <EntertainmentsPage />
                          </EntertainmentsPageProvider>
                        </ConflictManagementProvider>
                      )}
                    />
                    <Route
                      path={ROUTE_VIEWS.ACTIVITIES}
                      component={ActivitiesRoutes}
                    />
                    <Route
                      path={ROUTE_VIEWS.SPAS}
                      component={SpaRoutes}
                    />
                    <Route
                      path={ROUTE_VIEWS.TREATS}
                      component={TreatsRoutes}
                    />
                    <Route
                      path={ROUTE_VIEWS.GOLF}
                      exact={true}
                      component={Golf}
                    />
                    <Route
                      path={ROUTE_VIEWS.REGISTER_CARD}
                      exact={true}
                      component={RegisterCardPage}
                    />
                    <Route
                      path={ROUTE_VIEWS.HOTEL_INFO}
                      exact={true}
                      component={HotelInfoPage}
                    />
                    <Route
                      path={ROUTE_VIEWS.ROOM_ACCOUNT}
                      exact={true}
                      component={BookingFolioPage}
                    />
                    <Route
                      path={ROUTE_VIEWS.MAP}
                      exact={true}
                      component={MapPage}
                    />
                    <Route
                      path={ROUTE_VIEWS.ITINERARY}
                      exact={true}
                      render={() => (
                        <MapImagePopupProvider>
                          <ConflictManagementProvider
                            conflictOccuringView={SERVICES_TYPES.ITINERARY}
                          >
                            <DiningPartyProvider>
                              <Itinerary />
                            </DiningPartyProvider>
                          </ConflictManagementProvider>
                        </MapImagePopupProvider>
                      )}
                    />
                    {deepLinkRoute}
                    <Route component={FallbackRoute} />
                  </Switch>
                </PlatformDependentPage>
              ) : (
                <Switch>
                  {deepLinkRoute}
                  <Redirect to={ROUTE_VIEWS.LOGIN} />
                </Switch>
              )}
            </Switch>
          </IonContent>
          {!maintenanceData &&
            (!isDesktop() ? <MobileFooter /> : <DesktopFooter />)}
        </SideMenuProvider>
      </NavigatorProvider>
    </ThemeContextProvider>
  );
};

export default AppRoutes;
