import React, { useContext } from "react";
import { MapImagePopupContext } from "../../../../../context/MapImagePopupContext";
import ZoomImageContainer from "../../../../../shared/Ionic/ZoomImageContainer/ZoomImageContainer";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { IonModal, IonRow } from "@ionic/react";
import styles from "./MapPopUp.module.scss";
import { HEADER_SIZE } from "../../../../../constants/constants";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import { close } from "ionicons/icons";
import { SideMenuContext } from "../../../../../context/SideMenuContext";
import { useTheme } from "../../../../../hooks/useTheme";

const TYPES = {
  Dinner: "Restaurant",
  Dining: "Restaurant",
  Breakfast: "Restaurant",
  Spa: "Spa",
  Activity: "Activity",
  Venue: "Entertainment",
  Entertainment: "Entertainment",
  Hotel: "Hotel",
};

const MapPopUp = () => {
  const { typography } = useTheme();
  const { mapImage, setMapImage } = useContext(MapImagePopupContext);
  const { setFullScreenMap } = useContext(SideMenuContext);

  return (
    <IonModal
      className={styles.Modal}
      style={{
        top: isDesktop()
          ? HEADER_SIZE
          : `calc(${HEADER_SIZE} + env(safe-area-inset-top))`,
      }}
      isOpen={!!mapImage.url}
      backdropDismiss={false}
    >
      <IonRow
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 16,
        }}
      >
        <BLParagraph
          newClasses="bl-padding-b-l"
          textVariant={typography.h2}
          txt={`${TYPES[mapImage.type]} map`}
        />
        <BLButton
          type="ui-small"
          content="Close"
          icon={close}
          onClick={() => {
            setFullScreenMap(false);
            setMapImage((prev) => ({ ...prev, type: null, url: "" }));
          }}
        />
      </IonRow>
      <ZoomImageContainer image={mapImage.url} />
    </IonModal>
  );
};

export default MapPopUp;
