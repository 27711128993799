export const prepareDaysDropdownList = (date, selections, selIndex, daySelections) => {
  const foundSelDateElement = daySelections?.find((row) => row.id === date);

  const dropdownList = [];
  const dropdownSel = JSON.parse(JSON.stringify(daySelections));
  const selectedOpt = selections[selIndex].on;

  dropdownSel.forEach((row) => {
    const isSel = selectedOpt.find((opt) => opt.date === row.id);

    if (!isSel) {
      dropdownList.push(row);
    }
  });

  const dropdownListPlusCurrentElement = foundSelDateElement
    ? [...dropdownList, foundSelDateElement]
    : [...dropdownList];

  const sortedDropdownListPlusCurrentElement =
        dropdownListPlusCurrentElement.sort((a, b) => a.id.localeCompare(b.id));

  return sortedDropdownListPlusCurrentElement;
};