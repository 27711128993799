import React, { Fragment, useContext } from "react";
import { SpaContext } from "../../../../../../../context/SpaContext";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../../../../shared/Ionic/Paragraph/BLParagraph";
import TimeRowTogetherSelectionLogic from "./TimeRowTogetherSelectionLogic";
import { useTheme } from "../../../../../../../hooks/useTheme";

const TimeRowTogetherSelection = (extractTimeSelections, serviceDetails) => {
  const { typography } = useTheme();
  const { spaSelections, daySelections } = useContext(SpaContext);
  const { retrieveContent } = TimeRowTogetherSelectionLogic(
    extractTimeSelections,
    serviceDetails
  );

  const timeRowTogetherContent = (
    <IonRow style={{ gap: "15px" }}>
      <IonCol>
        <IonGrid>
          {spaSelections[0]?.on.map((when, selTimeIndex) => (
            <Fragment key={when.uniqueKey || selTimeIndex}>
              {when.date !== "" && (
                <>
                  <IonRow
                    className={"ion-justify-content-end bl-margin-t-m"}
                    style={{
                      borderBottom: "1px solid var(--ion-color-primary)",
                    }}
                  >
                    <BLParagraph
                      txt={
                        daySelections?.find((row) => row.id === when.date)
                          ?.fullTitle
                      }
                      color={"primary"}
                      textVariant={typography.h4}
                    />
                  </IonRow>

                  {retrieveContent(when.date, selTimeIndex, when.action)}
                </>
              )}
            </Fragment>
          ))}
        </IonGrid>
      </IonCol>
    </IonRow>
  );

  return {
    timeRowTogetherContent,
  };
};

export default TimeRowTogetherSelection;
