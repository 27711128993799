import React from "react";
import BLAlertPopUp from "../../../../../shared/Ionic/Boxes/Popup/BLAlertPopUp";
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { logoGoogle, logoMicrosoft, logoYahoo } from "ionicons/icons";

const CalendarLinks = ({calendarLinksData, setCalendarLinksData}) => {

  return (
    <BLAlertPopUp
      title={"Add to calendar"}
      enableHeaderLevelCloseBtn
      dismissHandler={() => setCalendarLinksData(null)}
      isOpen={!!calendarLinksData}
      enableBackdropDismiss
    >
      <IonRow>
        <IonCol className="bl-padding-s">
          <IonGrid>
            <IonButton
              expand="block"
              href={`https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${calendarLinksData?.startTimeGoogleAndYahoo}/${calendarLinksData?.endTimeGoogleAndYahoo}&details=${calendarLinksData?.description}&location=${calendarLinksData?.location}&text=${calendarLinksData?.title}`}
              target="_blank"
            >
              <IonIcon
                slot="start"
                icon={logoGoogle}
                className="ion-margin-end"
              ></IonIcon>
              Google
            </IonButton>
            <IonButton
              className="ion-margin-top"
              expand="block"
              href={`https://outlook.office365.com/calendar/deeplink/compose?body=${calendarLinksData?.description}&enddt=${calendarLinksData?.endTimeMicrosoft}&location=${calendarLinksData?.location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${calendarLinksData?.startTimeMicrosoft}&subject=${calendarLinksData?.title}`}
              target="_blank"
            >
              <IonIcon
                slot="start"
                icon={logoMicrosoft}
                className="ion-margin-end"
              ></IonIcon>
              Outlook
            </IonButton>
            <IonButton
              className="ion-margin-top"
              expand="block"
              href={`https://calendar.yahoo.com/?desc=${encodeURIComponent(calendarLinksData?.description)}&et=${calendarLinksData?.endTimeGoogleAndYahoo}&in_loc=${calendarLinksData?.location}&st=${calendarLinksData?.startTimeGoogleAndYahoo}&title=${encodeURIComponent(encodeURIComponent(calendarLinksData?.title))}&v=60`}
              target="_blank"
            >
              <IonIcon
                slot="start"
                icon={logoYahoo}
                className="ion-margin-end"
              ></IonIcon>
              Yahoo
            </IonButton>
          </IonGrid>
        </IonCol>
      </IonRow>
    </BLAlertPopUp>
  );
};

export default CalendarLinks;