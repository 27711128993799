import { IonCol, IonRow } from "@ionic/react";
import { pencil } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ConflictManagementContext } from "../../../../context/ConflictManagementContext";
import { useDataContext } from "../../../../context/DataContext";
import { SpaContext } from "../../../../context/SpaContext";
import BLLoadingContainer from "../../../../shared/Ionic/BLLoadingContainer";
import BLButton from "../../../../shared/Ionic/Buttons/BLButton";
import BLExpandableBox from "../../../../shared/Ionic/Cards/BLExpandableBox/BLExpandableBox";
import SpaExpandableRowsContentLogic from "./SpaExpandableRowsContentLogic";
import { expandCollapse } from "../../../../helpingFunctions/helpingFunctions";
import { isDesktop } from "../../../../helpingFunctions/utilities";
import { ROUTE_VIEWS, mapTypeToRoute } from "../../../../routes/routeViews";
import { NavigatorContext } from "../../../../context/NavigatorContext";
import { useParams } from "react-router-dom";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import { useTheme } from "../../../../hooks/useTheme";
import BLLink from "../../../../shared/Ionic/Links/BLAnchor/BLLink";

const SpaExpandableRowsContent = ({ serviceDetails }) => {
  const { typography } = useTheme();
  const { spaId } = useParams();
  const history = useHistory();
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const [clickedRow, setClickedRow] = useState("guestRow");
  const [areSelectionsCompleted, setAreSelectionsCompleted] = useState(false);
  const { findPageConflicts } = useContext(ConflictManagementContext);
  const [areAnyChanges, setAreAnyChanges] = useState(false);
  const {
    setShowContraindications,
    spaSelections,
    cancelChangesHandler,
    setOption,
    setShowBookingSection,
    isEditMode,
    spaDetails,
  } = useContext(SpaContext);
  const { pagesConfiguration, isLoadingItinerary } = useDataContext();

  const {
    guestsRowExpanded,
    guestsRowCollapsed,
    guestsRowContent,
    daysRowCollapsed,
    daysRowExpanded,
    daysRowContent,
    timeRowCollapsed,
    timeRowExpanded,
    timeRowContent,
  } = SpaExpandableRowsContentLogic(
    serviceDetails,
    setAreAnyChanges,
    setAreSelectionsCompleted
  );

  useEffect(() => {
    if (isEditMode) setClickedRow(null);
  }, [isEditMode]);

  return (
    <>
      <IonRow className="bl-margin-t-s">
        <IonCol>
          <BLExpandableBox
            value="guestRow"
            collapsedTitle={
              isLoadingItinerary ? <BLLoadingContainer /> : guestsRowCollapsed
            }
            expandedTitle={guestsRowExpanded}
            newClass={
              isDesktop() ? "ExpandableBox_main" : "ExpandableBox_second"
            }
            dimensions={{
              padding: [
                "l-s",
                "r-s",
                "t-s",
                clickedRow === "guestRow" ? "b-m" : "b-s",
              ],
            }}
            customIcon={isEditMode && pencil}
            isClicked={clickedRow === "guestRow"}
            onClick={() =>
              expandCollapse("guestRow", clickedRow, setClickedRow)
            }
          >
            {isLoadingItinerary ? <BLLoadingContainer /> : guestsRowContent}
          </BLExpandableBox>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <BLExpandableBox
            value="daysRow"
            collapsedTitle={
              isLoadingItinerary ? <BLLoadingContainer /> : daysRowCollapsed
            }
            expandedTitle={daysRowExpanded}
            newClass={
              isDesktop() ? "ExpandableBox_main" : "ExpandableBox_second"
            }
            dimensions={{ padding: ["l-s", "r-s", "t-s", "b-s"] }}
            isClicked={clickedRow === "daysRow"}
            customIcon={isEditMode && pencil}
            isDisabled={spaSelections.find(
              (row) => !row.isFullName || row.isEditingName
            )}
            onClick={() => expandCollapse("daysRow", clickedRow, setClickedRow)}
          >
            {isLoadingItinerary ? <BLLoadingContainer /> : daysRowContent}
          </BLExpandableBox>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <BLExpandableBox
            value="timeRow"
            collapsedTitle={
              isLoadingItinerary ? <BLLoadingContainer /> : timeRowCollapsed
            }
            expandedTitle={timeRowExpanded}
            newClass={
              isDesktop() ? "ExpandableBox_main" : "ExpandableBox_second"
            }
            dimensions={{ padding: ["l-s", "r-s", "t-s", "b-s"] }}
            isClicked={clickedRow === "timeRow"}
            customIcon={isEditMode && pencil}
            isDisabled={spaSelections.find((row) => {
              return row.on.find((time) => time.date === "");
            })}
            onClick={() => expandCollapse("timeRow", clickedRow, setClickedRow)}
          >
            {isLoadingItinerary ? <BLLoadingContainer /> : timeRowContent}
          </BLExpandableBox>
        </IonCol>
      </IonRow>
      <IonRow className="bl-margin-t-m">
        <IonCol className="ion-text-center">
          <BLParagraph
            newStyles={{ pointerEvents: "auto" }}
            textVariant={typography.BODY_TEXT}
            typographyStyles={{ display: "inline" }}
            color="text-grey"
          >
            {"Review our "}
            <BLLink
              txt="Terms & Conditions, Cancellation Policy"
              href={pagesConfiguration?.TermsAndConditions?.Link}
              color="secondary"
            />
            {" and "}
            <BLLink
              txt="Contraindications"
              onClick={() => setShowContraindications(true)}
              color="secondary"
            />
          </BLParagraph>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol
          style={{ gap: "20px" }}
          className="bl-flex ion-justify-content-center ion-align-items-center bl-margin-t-m"
        >
          <BLButton
            content={isEditMode ? "Cancel" : "Go back"}
            type="underlined"
            onClick={() => {
              if (isEditMode) {
                if (history.location.state.isItineraryRedirect) {
                  redirectBetweenPages(ROUTE_VIEWS.ITINERARY);
                }
                cancelChangesHandler();
              } else {
                setShowBookingSection(false);
                setOption(null);
                const redirectTo = spaDetails.All.find(
                  (row) => row.id === Number(spaId)
                );

                if (redirectTo?.CategoryId) {
                  redirectBetweenPages(
                    mapTypeToRoute(
                      redirectTo.ObjType,
                      null,
                      redirectTo.CategoryId
                    )
                  );
                } else {
                  redirectBetweenPages(ROUTE_VIEWS.SPAS);
                }
              }
            }}
          />
          <BLButton
            content={isEditMode ? "Update changes" : "Book now"}
            type="primary"
            onClick={() =>
              // When the user hits 'Confirm', first step would be to evaluate for conflicts
              // Whether there are conflicts or not, the 'handleSpaBookingRQ()' method will be called via useEffect in the SpaProcessingMethods
              findPageConflicts(spaSelections)
            }
            disabled={isEditMode ? !areAnyChanges : !areSelectionsCompleted}
          />
        </IonCol>
      </IonRow>
    </>
  );
};

export default SpaExpandableRowsContent;
