import React from "react";
import { IonCard } from "@ionic/react";

import styles from "./GrayWrapper.module.scss";

const GrayWrapper = ({ newStyles, newClass, children, reference }) => {
  return (
    <IonCard
      className={`${styles.GrayWrapper} ion-text-left ${newClass !== 'ItineraryWrapperBasic' ? 'ion-padding' : ''} ${styles[newClass]} `}
      style={{ ...newStyles }}
      color="wrapper"
      ref={reference}
    >
      {children}
    </IonCard>
  );
};

export default GrayWrapper;
