import { IonCol, IonRow } from "@ionic/react";
import React from "react";
import { ITEM_POPULAR_STATUSES } from "../../../../../constants/constants";

import CreateDimensions from "../../../../../helpingFunctions/DimensionsFactory";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import EntertainmentBookingPreview from "../EntertainmentPreview/EntertainmentBookingPreview";
import StayNavigatorLogic from "./StayNavigatorLogic";
import { pencil, repeat } from "ionicons/icons";
import { Box } from "@mui/system";

const StayNavigator = () => {
  const { navButtons, mainStatus } = StayNavigatorLogic();
  const { dimensions: lastRowDimensionStyles } = CreateDimensions({
    margin: ["t-xl", "b-m"],
  });

  return (
    <>
      {mainStatus !== ITEM_POPULAR_STATUSES.INIT && mainStatus !== ITEM_POPULAR_STATUSES.EDITING && (
        <IonRow
          className={`${lastRowDimensionStyles} ion-justify-content-center`}
        >
          <IonCol size="12">
            <EntertainmentBookingPreview></EntertainmentBookingPreview>
          </IonCol>
        </IonRow>
      )}
      <IonRow
        className={`${lastRowDimensionStyles} ion-justify-content-center`}
      >
        <IonCol size="auto" >
          <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
            {navButtons?.map(
              ({ txt, type, onClick, disabled, dimensions }) => (
                <React.Fragment key={`${txt}_${type}`}>
                  <BLButton
                    content={txt}
                    type={type}
                    onClick={() => onClick()}
                    disabled={disabled}
                    dimensions={dimensions}
                    icon={type === "icon-text" ? txt.includes('Retry') ? repeat : pencil : null}
                    iconSize="md"
                  />
                </React.Fragment>
              )
            )}
          </Box>
        </IonCol>
      </IonRow>
    </>
  );
};

export default StayNavigator;
