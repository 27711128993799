import React from "react";
import BLIcon from "../../BLIcon";
import styles from "./BLCheckTriangle.module.scss";
import { checkmarkSharp } from "ionicons/icons";

const BLCheckTriangle = ({
  size = "md",
  backgroundColor = "var(--ion-color-secondary)",
  checkColor,
  style,
}) => {
  return (
    <>
      <span
        className={styles[`BLItem_check_${size}`]}
        style={{ color: checkColor }}
      >
        <BLIcon iconSource={checkmarkSharp} iconSize={size} />
      </span>
      <span
        className={styles[`BLItem_triangle_${size}`]}
        style={{ ...style, borderRightColor: backgroundColor }}
      />
    </>
  );
};

export default BLCheckTriangle;
