import DaysRow from "./bookingSection/DaysRow/DaysRow";
import LocationsAndQuantity from "./bookingSection/LocationsAndQuantityRow/LocationsAndQuantityRow";

const TreatYourselfExpandableRowsContentLogic = () => {
  const { daysRowCollapsed, daysRowExpanded, daysRowContent } = DaysRow();
  const {
    locationsAndQtyRowCollapsed,
    locationsAndQtyRowExpanded,
    locationAndQtyRowContent,
  } = LocationsAndQuantity();

  return {
    daysRowCollapsed,
    daysRowExpanded,
    daysRowContent,
    locationsAndQtyRowCollapsed,
    locationsAndQtyRowExpanded,
    locationAndQtyRowContent,
  };
};

export default TreatYourselfExpandableRowsContentLogic;
