import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useState } from "react";
import { BOOKING_CONFIRMATION_MSG } from "../../../../constants/constants";
import { useDataContext } from "../../../../context/DataContext";
import { TreatsContext } from "../../../../context/TreatsContext";
import BLLoadingContainer from "../../../../shared/Ionic/BLLoadingContainer";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import TreatYourselfExpandableRowsContent from "./TreatYourselfExpandableRowsContent";
import TreatYourselfSelectedDetailsCardDesktop from "./cards/TreatYourselfSelectedDetailsCardDesktop";
import TreatYourselfSelectedDetailsCardMobile from "./cards/TreatYourselfSelectedDetailsCardMobile";
import TreatsSummaryScreen from "./screens/TreatsSummaryScreen";
import { isDesktop } from "../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../hooks/useTheme";

const TreatYourselfBookingFlow = ({ isFirstTimeBooker }) => {
  const { typography } = useTheme();
  const { itineraryDetails } = useDataContext();
  const { selectedService, confirmationScreenSelections, isProcessingBookings, isEditMode } = useContext(TreatsContext);
  const [clickedRow, setClickedRow] = useState(null);

  return (
    <IonGrid>
      {isDesktop() ? (
        <TreatYourselfSelectedDetailsCardDesktop
          treatDetails={selectedService}
        />
      ) : (
        <TreatYourselfSelectedDetailsCardMobile
          treatDetails={selectedService}
        />
      )}

      {!itineraryDetails ? (
        <IonRow className="ion-text-center bl-margin-t-m">
          <IonCol>
            <BLLoadingContainer />
          </IonCol>
        </IonRow>
      ) : (
        <>
          {(!isEditMode || (isEditMode && confirmationScreenSelections)) && (
            <IonGrid className={`${isDesktop() ? "bl-margin-t-m" : ""}`}>
              {(!isFirstTimeBooker || isProcessingBookings || confirmationScreenSelections) && (
                <>
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <BLParagraph
                        txt="Your booking summary"
                        textVariant={isDesktop() ? typography.h2 : typography.h4}
                        // newClasses={`crimson-pro ${
                        //   isDesktop() ? "x-large" : "small"
                        // } bold`}
                        //TODO: Unclear typography
                        color="primary"
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-text-center bl-margin-t-xs">
                      <BLParagraph
                        txt={BOOKING_CONFIRMATION_MSG}
                        textVariant={typography.BODY_TEXT}
                        color="text-grey"
                      />
                    </IonCol>
                  </IonRow>
                </>
              )}
            </IonGrid>
          )}

          {isProcessingBookings || confirmationScreenSelections ? (
            <TreatsSummaryScreen isSummaryScreen={false} clickedRow={clickedRow} setClickedRow={setClickedRow} />
          ) : (
            <>
              {isFirstTimeBooker || isEditMode ? (
                <TreatYourselfExpandableRowsContent clickedRow={clickedRow} setClickedRow={setClickedRow} />
              ) : (
                <TreatsSummaryScreen isSummaryScreen={true} clickedRow={clickedRow} setClickedRow={setClickedRow} />
              )}
            </>
          )}
        </>
      )}
    </IonGrid>
  );
};

export default TreatYourselfBookingFlow;
