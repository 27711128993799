import React, { useContext } from "react";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { LEGAL_DOCS } from "../../../constants/constants";
import BLLink from "../../../shared/Ionic/Links/BLAnchor/BLLink";

const LegalDocsLinks = ({ tcLink }) => {
  const { setLegalDocDialogType } = useContext(SideMenuContext);
  return (
    <div className="ion-align-items-center" style={{ color: "white" }}>
      <BLLink
        txt="Privacy policy"
        onClick={() => {
          setLegalDocDialogType(LEGAL_DOCS.PRIVACY);
        }}
        color="white"
        smallLink
      />
      {", "}
      <BLLink
        txt="Terms & Conditions"
        href={tcLink}
        color="white"
        smallLink
      />
      {", "}
      <BLLink
        txt="Cookie policy"
        onClick={() => {
          setLegalDocDialogType(LEGAL_DOCS.COOKIE);
        }}
        color="white"
        smallLink
      />
    </div>
  );
};

export default LegalDocsLinks;
