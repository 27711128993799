import React from "react";
import { restaurant } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import {
  MAESTRO_BACKEND_NAMING_VIEWS,
  SERVICES_TYPES,
} from "../../../constants/constants";
import {
  activitiesFilled,
  calendarFilled,
  spaFilled,
} from "../../../assets/SVGIcons";
const GENERAL_ACTIONS = [
  {
    icon: "shareSocial",
    label: "share itinerary",
    name: "shareItinerary",
  },
  {
    icon: "pdfIcon",
    label: "download as PDF",
    name: "downloadPdf",
  },
];

const PROMPTS_LINKS = {
  Breakfast_Title: "How about breakfast?",
  Breakfast_SubTitle: "View times and book now",
  Activity_Title: "No activities booked?",
  Activity_SubTitle: "View available activities",
  Spa_Title: "No treatments booked?",
  Spa_SubTitle: "View available treatments",
  Dinner_Title: "Dinner plans?",
  Dinner_SubTitle: "View times and book now",
  Venue_Title: "No entertainment booked?",
  Venue_SubTitle: "View available shows",
};

const BOOKING_PROMPTS = [
  {
    key: SERVICES_TYPES.BREAKFAST,
    title: PROMPTS_LINKS.Breakfast_Title,
    subTitle: PROMPTS_LINKS.Breakfast_SubTitle,
    option: SERVICES_TYPES.BREAKFAST,
    tabs: [SERVICES_TYPES.DINING, "All"],
  },
  {
    key: MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY,
    title: PROMPTS_LINKS.Activity_Title,
    subTitle: PROMPTS_LINKS.Activity_SubTitle,
    option: SERVICES_TYPES.ACTIVITIES,
    tabs: [SERVICES_TYPES.ACTIVITIES, "All"],
  },
  {
    key: MAESTRO_BACKEND_NAMING_VIEWS.SPA,
    title: PROMPTS_LINKS.Spa_Title,
    subTitle: PROMPTS_LINKS.Spa_SubTitle,
    route: SERVICES_TYPES.SPAS,
    option: SERVICES_TYPES.SPAS,
    tabs: [MAESTRO_BACKEND_NAMING_VIEWS.SPA, "All"],
  },
  {
    key: SERVICES_TYPES.DINNER,
    title: PROMPTS_LINKS.Dinner_Title,
    subTitle: PROMPTS_LINKS.Dinner_SubTitle,
    option: SERVICES_TYPES.DINNER,
    tabs: [SERVICES_TYPES.DINING, "All"],
  },
  {
    key: MAESTRO_BACKEND_NAMING_VIEWS.VENUE,
    title: PROMPTS_LINKS.Venue_Title,
    subTitle: PROMPTS_LINKS.Venue_SubTitle,
    route: SERVICES_TYPES.ENTERTAINMENT,
    option: SERVICES_TYPES.ENTERTAINMENT,
    tabs: ["All"],
  },
];

const ITINERARY_OBJECT_DATE_ATTR = "Data";

const ITINERARY_DINING = "Dining";
const ITINERARY_OTHERS = "Others";

const ARRIVAL_MSG = "Check-in from 3pm to 12am";
const DEPARTURE_MSG = "Check out";
const ARRIVAL_MSG_BASIC_VIEW = "Check-in";

const EXPANDABLE_BOXES_TYPES = {
  DAY: "day",
  BOOKING: "booking",
};

const BOOK_NOW_TEXT = "Book now";
const NOT_REQUIRED_BOOKING_TEXT = "Opted for no booking";

const ITINERARY_BOOKED_TYPE = {
  "Internal Activity": "Activity",
  Venue: "Entertainment",
  Spa: "Spa Treatment",
};

const ITINERARY_TABS = [
  { label: "View all", value: "All", icon: calendarFilled },
  {
    label: "Dining",
    value: "Dining",
    icon: () => <IonIcon icon={restaurant} style={{ fontSize: 28 }} />,
  },
  { label: "Activities", value: "Activities", icon: activitiesFilled },
  { label: "Spa", value: "Spa", icon: spaFilled },
];

const ENTERTAINMENT_WITHOUT_PUBLISHED_NAME = "Coming Soon";
const SPA_INFORMATION_MSG =
  "Attention: Please arrive 10 minutes prior, to complete the consultation form.";
const ENTERTAINMENT_INFORMATION_MSG =
  "Attention: Please note the gate opens 15 minutes before the actual show starting time.";

const SPA_INFORMATION_MSG_MARKDOWN =
  "**Attention:**<br>Please arrive 10 minutes prior, to complete the consultation form.";
const ENTERTAINMENT_INFORMATION_MSG_MARKDOWN =
  "**Attention:**<br>Please note the gate opens 15 minutes before the actual show starting time.";


export {
  BOOKING_PROMPTS,
  PROMPTS_LINKS,
  GENERAL_ACTIONS,
  ITINERARY_OBJECT_DATE_ATTR,
  ITINERARY_DINING,
  ITINERARY_OTHERS,
  ARRIVAL_MSG,
  ARRIVAL_MSG_BASIC_VIEW,
  DEPARTURE_MSG,
  BOOK_NOW_TEXT,
  EXPANDABLE_BOXES_TYPES,
  ITINERARY_BOOKED_TYPE,
  NOT_REQUIRED_BOOKING_TEXT,
  ENTERTAINMENT_WITHOUT_PUBLISHED_NAME,
  SPA_INFORMATION_MSG,
  SPA_INFORMATION_MSG_MARKDOWN,
  ENTERTAINMENT_INFORMATION_MSG,
  ENTERTAINMENT_INFORMATION_MSG_MARKDOWN,
  ITINERARY_TABS,
};
