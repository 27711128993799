import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import BookedDayDetails from "./BookedDayDetails/BookedDayDetails";

import {
  BOOKING_CONFIRMATION_MSG,
  ITEM_POPULAR_STATUSES,
} from "../../../../../constants/constants";
import { useDataContext } from "../../../../../context/DataContext";
import { DiningBookingContext } from "../../../../../context/DiningBookingContext";
import BLLoadingContainer from "../../../../../shared/Ionic/BLLoadingContainer";
import BLInformationBox from "../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import {
  GROUP_DINING_CHANGES_MESSAGE,
  PREVIEW_INFO_MESSAGES,
} from "../../DiningBookingConstants";
import { isDesktop } from "../../../../../helpingFunctions/utilities";
import { useTheme } from "../../../../../hooks/useTheme";

const DiningBookingPreview = ({ view }) => {
  const { typography } = useTheme();
  const {
    diningBookingSelectionsPreview: { selections, mainStatus },
  } = useContext(DiningBookingContext);
  const { notifyIconDisplay, reservationDetails } = useDataContext();

  const finalStatuses = [
    ITEM_POPULAR_STATUSES.SUCCESS,
    ITEM_POPULAR_STATUSES.FAILURE,
  ];
  const isFinalStatus = finalStatuses.includes(mainStatus);
  const isLoadingStatus = mainStatus === ITEM_POPULAR_STATUSES.LOAD;

  const getResolutionMsg = () => {
    if (isFinalStatus && selections.length > 0) {
      return BOOKING_CONFIRMATION_MSG;
    } else if (isFinalStatus && selections.length === 0) {
      return `We skipped your ${view.toLowerCase()}. You can amend anytime if you change your mind.`;
    } else {
      return selections?.length > 0
        ? PREVIEW_INFO_MESSAGES[mainStatus]
        : isLoadingStatus
          ? `We are skipping your ${view.toLowerCase()}.`
          : `By clicking on 'Confirm' you confirm that you want to skip your ${view.toLowerCase()}. You can amend anytime if you change your mind.`;
    }
  };

  return (
    <IonGrid className={isDesktop() ? "" : "bl-padding-l-xs bl-padding-r-xs"}>
      { (reservationDetails.IsGroupCreator && reservationDetails[view] && !isFinalStatus) && (
        <IonRow className="bl-margin-t-s bl-margin-b-s">
          <IonCol>
            <BLInformationBox
              content={GROUP_DINING_CHANGES_MESSAGE}
              type="info"
            />
          </IonCol>
        </IonRow>
      )}
      <IonRow>
        <IonCol>
          <BLParagraph
            txt={`${getResolutionMsg()}`}
            textVariant={typography.BODY_TEXT}
            color="text-grey"
          />
        </IonCol>
      </IonRow>
      {selections?.length === 0 && isLoadingStatus && (
        <IonRow className="ion-justify-content-center ion-padding-top ion-padding-bottom">         
          <BLLoadingContainer />
        </IonRow>
      )}
      {selections?.length > 0 && (
        <IonRow>
          <IonCol>
            <BLContentCardWrapper
              dimensions={{
                padding: ["t-s", "b-s", "l-xs", "r-xs"],
                margin: ["t-s"],
              }}
            >
              <IonGrid>
                {isDesktop() ? (
                  <>
                    <IonRow>
                      <IonCol size="8.5">
                        <BLParagraph
                          txt="Summary"
                          textVariant={typography.h3}
                          // newClasses={"medium bold"}
                          //TODO: Unclear typography
                          color="primary"
                        />
                      </IonCol>
                      <IonCol size="2.8" className="bl-padding-l-m">
                        <BLParagraph
                          txt="Status"
                          textVariant={typography.h3}
                          // newClasses={"medium bold"}
                          //TODO: Unclear typography
                          color="primary"
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                      {selections?.map((sel) => (
                        <React.Fragment
                          key={`frag_${sel.restaurantId}_${sel.date}`}
                        >
                          <IonCol size="8.5">
                            <BookedDayDetails
                              key={`${sel.id}_${sel.date}`}
                              view={view}
                              selDetails={sel}
                            />
                          </IonCol>
                          <IonCol
                            size="2.8"
                            className="bl-padding-l-m bl-margin-t-s"
                          >
                            {notifyIconDisplay(sel.status || sel.statusCancel)}
                          </IonCol>
                        </React.Fragment>
                      ))}
                    </IonRow>
                  </>
                ) : (
                  <IonRow>
                    <IonCol size="12">
                      {selections?.map((sel) => (
                        <BookedDayDetails
                          key={`${sel.restaurantId}_${sel.date}`}
                          view={view}
                          selDetails={sel}
                        />
                      ))}
                    </IonCol>
                  </IonRow>
                )}
              </IonGrid>
            </BLContentCardWrapper>
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};

export default DiningBookingPreview;
