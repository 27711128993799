import React from "react";
import Heading from "../../shared/Heading_OLD/Heading";
import InformationText from "../../shared/InformationText_OLD/InformationText";
import HelpItemLogic from "./HelpItemLogic";

import styles from "./help_item.module.css";

const HelpItem = ({
  question,
  answer,
  backgroundColor,
  button,
}) => {
  const { isClicked, itemClickHandler } = HelpItemLogic();

  return (
    <article
      className={`${styles.helpItem} ${styles.helpItem_purpleTitle} ${
        isClicked ? styles.helpItem_expand : null
      }`}
      style={{ backgroundColor }}
      onClick={itemClickHandler}
    >
      <Heading txt={question} fontSize={"20px"} paddingBottom={"15px"} />
      <InformationText content={answer} />
      {button}
      <img
        className={styles.helpItem__icon}
        src={"/img/drop-down-arrow_1.svg"}
        alt={""}
      />
    </article>
  );
};

export default HelpItem;
