import React from "react";

import { TIME_FILTER_OPTIONS } from "../../../../../../assets/StaticObjects";
import { INITIAL_DATE } from "../../../../../../constants/constants";

import DateDisplay from "../DateDisplay/DateDisplay";

import styles from "./DayTime.module.scss";

const DayTime = ({ highlightedDayTime }) => {
  return (
    <section className={styles.TVFilterAndDay}>
      <DateDisplay date={INITIAL_DATE} />
      <div className={styles.TVFilterAndDay__DaySplits}>
        {TIME_FILTER_OPTIONS.map((timeFilter) => (
          <span
            key={timeFilter.dayTime}
            className={`${styles.TVFilterAndDay__DaySplits_item} ${
              timeFilter.dayTime === highlightedDayTime
                ? styles.TVFilterAndDay__DaySplits_item_selected
                : ""
            }`}
          >
            {timeFilter.dayTime}
          </span>
        ))}
      </div>
    </section>
  );
};

export default DayTime;
