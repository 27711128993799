import { useState } from "react";

const HelpItemLogic = () => {
  const [isClicked, setIsClicked] = useState(false);

  const itemClickHandler = (e) => {
    if (e.target.nodeName === "DIV") {
      e.preventDefault();
      return;
    }

    setIsClicked((prevState) => !prevState);
  };

  return { isClicked, itemClickHandler };
};

export default HelpItemLogic;
