import { menuController } from "@ionic/core/components";
import { IonGrid, IonRow } from "@ionic/react";
import { bed } from "ionicons/icons";
import React, { useContext } from "react";
import { useDataContext } from "../../../context/DataContext";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { getDateInterval } from "../../../helpingFunctions/dateTimeUtilities";
import RequestHook from "../../../hooks/RequestHook";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import LinkReservationCardButton from "./LinkReservationCardButton";
import { useTheme } from "../../../hooks/useTheme";

const LinkReservationCard = ({ res, changeReservation, setInvalidResDetails, showRemoveIcon = false, setDeleteReservationDialog }) => {
  const { typography } = useTheme();
  const { reservationDetails } = useDataContext();
  const { areLinkResCardsCollapsed, setAreLinkResCardsCollapsed } =
    useContext(SideMenuContext);
  const { checkReservationValidity } = RequestHook();
  const currentReservation = res.CRSNumber === reservationDetails.CRSNumber;

  const handleClick = async () => {
    setAreLinkResCardsCollapsed(!areLinkResCardsCollapsed);
    if (
      !areLinkResCardsCollapsed ||
      reservationDetails.LinkedReservations.length === 1
    ) {
      if (!currentReservation) {
        const {status, ...rest} = await checkReservationValidity(res.id);

        if (status === "success" && (rest.isCancelled || rest.isExpired || rest.isRemoved)) {
          setInvalidResDetails({...res, ...rest});
          return;
        } else {
          changeReservation(res);
        }
      }
      menuController.close();
    }
  };

  return (
    <LinkReservationCardButton
      icon={bed}
      label={
        <IonGrid>
          <IonRow>
            <BLParagraph
              txt={res.HotelTitle}
              color="black"
              textVariant={typography.TOOLTIP_HIGHLIGHT_TEXT}
            />
          </IonRow>
          <IonRow>
            <BLParagraph
              txt={`Ref ${res.CRSNumber}`}
              color="black"
              textVariant={typography.ITINERARY_BODY_TEXT}
            />
          </IonRow>
        </IonGrid>
      }
      nights={res.Nights}
      iconBackground={currentReservation ? "danger" : "secondary  "}
      dateInterval={getDateInterval(res.ArrivalDate, res.DepartureDate)}
      border={
        currentReservation ? "1px solid var(--ion-color-secondary)" : "none"
      }
      onClick={handleClick}
      showRemoveIcon={showRemoveIcon}
      areLinkResCardsCollapsed={areLinkResCardsCollapsed}
      setDeleteReservationDialog={setDeleteReservationDialog}
      guestName={`${res.FirstName} ${res.LastName}`}
      HotelTitle={res.HotelTitle}
      strapiResId={res.id}
      CRSNumber={res.CRSNumber}
    />
  );
};

export default LinkReservationCard;
