import React from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import { alertCircle } from "../../../../assets/SVGIcons";

import { CUSTOM_MADE_CLASS_PREFIX } from "../../BLIonicConstants";

import CreateDimensions from "../../../../helpingFunctions/DimensionsFactory";

import styles from "./BLExpandableBox.module.scss";
import BLIcon from "../../BLIcon";

const BLExpandableBox = ({
  collapsedTitle,
  expandedTitle,
  onClick,
  isClicked,
  isConflicted,
  isDisabled = false,
  isReadOnly = false,
  children,
  dimensions = {},
  contentWrapperDimensions = {},
  newClass = "",
  colorClass = "",
  value = "accordion",
  customIcon,
  expandedTitleTooltip,
}) => {
  const dimensionStyles = CreateDimensions(dimensions);
  const contentWrapperDimensionStyles = CreateDimensions(
    contentWrapperDimensions
  );

  const title = isClicked ? expandedTitle : collapsedTitle;

  return (
    <IonAccordionGroup
      value={isClicked ? value : null}
      animated={customIcon ? false : true}
      disabled={isDisabled}
      className={`
          ${styles.BLExpandableBox} 
          ${styles[`${CUSTOM_MADE_CLASS_PREFIX}${newClass}`]} 
          ${styles[colorClass]}
          ${dimensionStyles.dimensions} 
          ion-margin-top 
          ion-padding`}
    >
      <IonAccordion
        value={value || null}
        readonly={isReadOnly ? true : false}
        toggleIcon={null}
        style={{ backgroundColor: "unset" }}
      >
        <IonItem
          slot="header"
          lines="none"
          className={`${styles.BLExpandableBox_headerBox} ion-no-padding `}
        >
          <IonLabel
            className={styles.BLExpandableBox_label}
            onClick={!isDisabled && !isReadOnly ? onClick : null}
          >
            <IonGrid>
              <IonRow
                className={`ion-nowrap ion-justify-content-between ion-text-wrap ${
                  isClicked ? "ion-margin-bottom" : ""
                }`}
                style={{ cursor: isReadOnly ? "default" : "pointer" }}
                onClick={!isDisabled && !isReadOnly ? onClick : null}
              >
                <IonCol className={styles.BLExpandableBox_title}>
                  {title}

                  {isClicked && expandedTitleTooltip && expandedTitleTooltip}
                </IonCol>

                <IonCol
                  size="auto"
                  style={{ alignItems: "center", paddingLeft: "8px" }}
                >
                  <IonGrid>
                    <IonRow>
                      {isConflicted && !isClicked && (
                        <IonCol
                          style={{
                            position: "relative",
                            top: "2px",
                            marginRight: "8px",
                          }}
                        >
                          <BLIcon
                            customIconSource={alertCircle(
                              30,
                              "var(--ion-color-warning)"
                            )}
                          />
                        </IonCol>
                      )}
                      <IonCol
                        className={`${styles.BLExpandableBox_arrow} ion-accordion-toggle-icon`}
                        style={{
                          alignSelf: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {!isDisabled &&
                          !isReadOnly &&
                          (customIcon && !isClicked ? (
                            <BLIcon
                              iconSource={customIcon}
                              newClasses={"bl-icon-md"}
                              color={"secondary"}
                            />
                          ) : (
                            <div>
                              <BLIcon
                                iconSource={chevronDown}
                                newClasses={styles.BLExpandableBox_arrow_icon}
                                style={{ alignSelf: "start" }}
                              />
                            </div>
                          ))}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonLabel>
        </IonItem>
        <IonGrid
          slot="content"
          style={{ pointerEvents: !isDisabled ? "auto" : "none" }}
          className={`
                ${contentWrapperDimensionStyles.dimensions} `}
          onMouseDown={(event) =>
            event.target.tagName.toUpperCase() == "INPUT"
              ? null
              : event.preventDefault()
          }
        >
          {children}
        </IonGrid>
      </IonAccordion>
    </IonAccordionGroup>
  );
};

export default BLExpandableBox;
