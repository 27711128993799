import { IonCol, IonFooter, IonRow, IonToolbar } from "@ionic/react";
import { home } from "ionicons/icons";
import React from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useDataContext } from "../../context/DataContext";
import { ROUTE_VIEWS } from "../../routes/routeViews";
import BLIcon from "../../shared/Ionic/BLIcon";
import BLParagraph from "../../shared/Ionic/Paragraph/BLParagraph";
import styles from "./Footer.module.scss";
import { isDesktop } from "../../helpingFunctions/utilities";

const MobileFooter = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { reservationDetails } = useDataContext();

  if (pathname.includes(ROUTE_VIEWS.LOGIN) || isDesktop()) {
    return null;
  }

  const isItinerary = pathname.includes(ROUTE_VIEWS.ITINERARY);
  const isInInfoPage = pathname.includes(ROUTE_VIEWS.HOTEL_INFO);
  const homeButtonClicked =
  pathname.includes(ROUTE_VIEWS.LANDING_PAGE);

  return (
    <IonFooter className="ion-no-border">
      <IonToolbar
        color="primary"
        className="ion-no-padding"
        style={{
          borderTopLeftRadius: isItinerary ? "5px" : "0px",
          borderTopRightRadius: isInInfoPage ? "5px" : "0px",
        }}
      >
        <IonRow className={`${styles.MainFooterNew} ion-align-items-center`}>
          {reservationDetails && (
            <>
              <IonCol
                size="4"
                className={`${
                  isItinerary
                    ? styles.MainFooter_iconWrapper_selected
                    : styles.MainFooter_iconWrapper
                }`}
                onClick={() => history.push(ROUTE_VIEWS.ITINERARY)}
              >
                <BLParagraph
                  txt={"Itinerary"}
                  color="white"
                  //TODO: Unclear typography
                  newClasses={isItinerary ? "semibold" : ""}
                />
              </IonCol>
              <IonCol
                size="4"
                className={`${
                  homeButtonClicked
                    ? styles.MainFooter_iconWrapper_selected
                    : styles.MainFooter_iconWrapper
                }`}
                onClick={() => history.push(ROUTE_VIEWS.LANDING_PAGE)}
              >
                <BLIcon newClasses={styles.MainFooter_icon} iconSource={home} />
                <BLParagraph
                  txt={"Home"}
                  color="white"
                  //TODO: Unclear typography
                  newClasses={homeButtonClicked ? "semibold" : ""}
                />
              </IonCol>

              <IonCol
                size="4"
                className={`${
                  isInInfoPage
                    ? styles.MainFooter_iconWrapper_selected
                    : styles.MainFooter_iconWrapper
                }`}
                onClick={() => history.push(ROUTE_VIEWS.HOTEL_INFO)}
              >
                <BLParagraph
                  txt={"Hotel info"}
                  color="white"
                  //TODO: Unclear typography
                  newClasses={isInInfoPage ? "semibold" : ""}
                />
              </IonCol>
            </>
          )}
        </IonRow>
      </IonToolbar>
    </IonFooter>
  );
};

export default MobileFooter;
