import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import { WhatsOnDataContext } from "../../../../../../../context/WhatsOnDataContext";

import { INITIAL_DATE } from "../../../../../../../constants/constants";

const AdminFiltersLogic = () => {
  const { getGuide } = useContext(WhatsOnDataContext);

  const { hotel } = useParams();
  const [date, setDate] = useState(INITIAL_DATE);

  const isValidDate = (selectedDate) => {
    try {
      if (selectedDate < INITIAL_DATE) {
        return false;
      }

      const year = selectedDate.getFullYear();
      if (isNaN(year) || year.toString().length > 4) {
        return false;
      }

      return true;
    } catch (error) {
      console.error(
        `${new Date()} Error in isValidDate func in AdminFiltersLogic.js ${
          error.message
        }`
      );
    }
  };

  const handleChange = (value) => {
    try {
      const selectedDate = new Date(value);
      const formatedDate = moment(selectedDate).format("YYYY-MM-DD");
      const isValid = isValidDate(selectedDate);

      if (!isValid) {
        setDate(moment(new Date()).format("YYYY-MM-DD"));
        return;
      }
      setDate(formatedDate);
      filterByDate(formatedDate);
    } catch (error) {
      console.error(
        `${new Date()} Error in handleChange func in AdminFiltersLogic.js ${
          error.message
        }`
      );
    }
  };

  const filterByDate = async (date) => {
    const params = {
      showDates: [date],
      hotel,
    };
    await getGuide(params);
  };

  return { handleChange, date };
};

export default AdminFiltersLogic;
