import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import {
  formatDate,
  getDatesBetween,
  getDayOfStay,
  isDateInThePast,
} from "../../../../../helpingFunctions/dateTimeUtilities";
import { DataContext } from "../../../../../context/DataContext";
import styles from "./DayBox.module.scss";
import DayItem from "../DayItem/DayItem";

import { datesCompare } from "../../../../../helpingFunctions/utilities";
import {
  COMPARE_RESULTS,
  MAESTRO_BACKEND_NAMING_VIEWS,
  SERVICES_TYPES,
} from "../../../../../constants/constants";
import BookingPrompt from "../BookingPrompt/BookingPrompt";
import { endOfDay } from "date-fns";
import { NavigatorContext } from "../../../../../context/NavigatorContext";
import { BOOKING_PROMPTS, NOT_REQUIRED_BOOKING_TEXT } from "../../constants";
import { mapTypeToRoute } from "../../../../../routes/routeViews";
import { useTheme } from "../../../../../hooks/useTheme";

const checkForBookedItem = (data, type) => {
  if (data.length === 0) return;
  return data.some((booking) => booking.Type === type);
};

const DayBox = ({ dayItinerary, isLastDay, currentTab }) => {
  const { typography } = useTheme();
  //extendedDayItinerary includes not required breakfast, dinner and entertainment
  const [extendedDayItinerary, setExtendedDayItinerary] =
    useState(dayItinerary);
  const { reservationDetails } = useContext(DataContext);
  const { redirectFromItinerary, warnerServices } =
    useContext(NavigatorContext);
  const isInArrivalDate =
    datesCompare(
      new Date(dayItinerary.Data),
      new Date(reservationDetails.ArrivalDate.split("T")[0])
    ) === COMPARE_RESULTS.EQUAL;

  const requiredBookings = reservationDetails?.diningDatesSkipped.filter(
    (item) => item.date === dayItinerary.Data
  );

  const isBreakfastNotRequired =
    !!requiredBookings[0]?.isBreakfastSkipped &&
    ["All", SERVICES_TYPES.DINING].includes(currentTab);

  const isDinnerNotRequired =
    !!requiredBookings[0]?.isDinnerSkipped &&
    ["All", SERVICES_TYPES.DINING].includes(currentTab);

  const isVenueNotRequired =
    reservationDetails?.Venue &&
    !checkForBookedItem(
      dayItinerary.bookings,
      MAESTRO_BACKEND_NAMING_VIEWS.VENUE
    ) &&
    !isLastDay &&
    ["All"].includes(currentTab);

  const isOptionAvailable = (key) => {
    return warnerServices?.some((item) =>
      item.options.some((record) => record.Type === key)
    );
  };

  const isPastDate = isDateInThePast(endOfDay(new Date(dayItinerary.Data)));

  useEffect(() => {
    const dayItineraryCopy = JSON.parse(JSON.stringify(dayItinerary));
    const nonTreatBookings = dayItineraryCopy.bookings.filter(
      (booking) => booking.Type !== "Treat"
    );
    const treatBookings = dayItineraryCopy.bookings.filter(
      (booking) => booking.Type === "Treat"
    );
    nonTreatBookings.sort((a, b) => a.Time.localeCompare(b.Time));

    if (isBreakfastNotRequired) {
      nonTreatBookings.unshift({
        Type: "BreakfastNotRequired",
        Title: SERVICES_TYPES.BREAKFAST,
        Info: NOT_REQUIRED_BOOKING_TEXT,
        Date: dayItineraryCopy.Data,
      });
    }
    if (isDinnerNotRequired) {
      nonTreatBookings.push({
        Type: "DinnerNotRequired",
        Title: SERVICES_TYPES.DINNER,
        Info: NOT_REQUIRED_BOOKING_TEXT,
        Date: dayItineraryCopy.Data,
      });
    }
    if (isVenueNotRequired) {
      nonTreatBookings.push({
        Type: "VenueNotRequired",
        Title: SERVICES_TYPES.ENTERTAINMENT,
        Info: NOT_REQUIRED_BOOKING_TEXT,
        Date: dayItineraryCopy.Data,
      });
    }

    dayItineraryCopy.bookings = [...nonTreatBookings, ...treatBookings];
    setExtendedDayItinerary(dayItineraryCopy);
  }, [dayItinerary]);

  return (
    <IonGrid
      style={{
        flexGrow: isLastDay ? 1 : 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <IonRow className="bl-padding-r-s">
        <IonCol
          className={styles.dayBoxTitle}
          style={{
            borderColor: isPastDate
              ? "var(--ion-color-mid-grey)"
              : "var(--ion-color-tertiary)",
          }}
        >
          <BLParagraph
            txt={`${getDayOfStay(
              getDatesBetween(
                reservationDetails.ArrivalDate.slice(0, 10),
                reservationDetails.DepartureDate.slice(0, 10)
              ),
              dayItinerary.Data
            )} ${formatDate(dayItinerary.Data)}`}
            textVariant={typography.h5}
            // newClasses="semibold x-small"
            //TODO: Unclear typography
            color={isPastDate ? "mid-grey" : "tertiary"}
          />
        </IonCol>
      </IonRow>
      <IonRow className={`bl-padding-l-s ${isLastDay && currentTab === 'All' ? 'bl-padding-r-s' : ''}`} style={{ flex: isLastDay ? 1 : 0 }}>
        <IonCol
          style={{
            paddingTop: "var(--bl-padding-xs)",
            paddingBottom: "var(--bl-padding-xs)",
            borderLeftStyle: "solid",
            borderLeftWidth: "2px",
            borderLeftColor: isPastDate
              ? "var(--ion-color-mid-grey)"
              : "var(--ion-color-tertiary)",
            borderBottomWidth: isLastDay && currentTab === 'All' ? "2px" : "0",
            borderBottomColor: isLastDay && currentTab === 'All' ? "var(--ion-color-tertiary)" : "inherit",
            borderBottomStyle: isLastDay && currentTab === 'All' ? "solid" : "inherit"
          }}
        >
          {extendedDayItinerary.bookings.map((item, index) => (
            <DayItem
              key={item.BookingNumber || item.ServiceCode || item.Type}
              item={item}
              backgroundColor={
                index % 2 === 0 ? "white" : "var(--ion-color-accent-pale-grey)"
              }
            />
          ))}
          {!isPastDate &&
            BOOKING_PROMPTS.map(
              (item) =>
                !checkForBookedItem(dayItinerary.bookings, item.key) &&
                !(
                  // Exclude breakfast on arrival date or if not required
                  (
                    (item.key === SERVICES_TYPES.BREAKFAST &&
                      (isBreakfastNotRequired || isInArrivalDate)) ||
                    // Exclude dinner on the last day or if not required
                    (item.key === SERVICES_TYPES.DINNER &&
                      (isDinnerNotRequired || isLastDay)) ||
                    // Exclude entertainment if not required or if its last day
                    (item.key === MAESTRO_BACKEND_NAMING_VIEWS.VENUE &&
                      (isVenueNotRequired || isLastDay))
                  )
                ) &&
                isOptionAvailable(item.option) &&
                item.tabs.includes(currentTab) && (
                  <BookingPrompt
                    key={item.key}
                    title={item.title}
                    subTitle={item.subTitle}
                    onClick={() => {
                      const route = item.route || item.key;
                      redirectFromItinerary(mapTypeToRoute(route), {
                        bookedDate: dayItinerary.Data,
                      });
                    }}
                  />
                )
            )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default DayBox;
