import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDataContext } from "../../../context/DataContext";
import { TreatsContext } from "../../../context/TreatsContext";
import { ROUTE_VIEWS } from "../../../routes/routeViews";
import BLHorizontalLineTitle from "../../../shared/Ionic/BLHorizontalLineTitle";
import BLButton from "../../../shared/Ionic/Buttons/BLButton";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import TreatYourselfPageLogic from "./TreatYourselfPageLogic";
import TreatYourselfCategories from "./Views/Categories/TreatYourselfCategories";
import TreatYourselfServices from "./Views/Single/TreatYourselfServices";
import { NavigatorContext } from "../../../context/NavigatorContext";
import { SERVICES_TYPES, UNCATEGORIZED } from "../../../constants/constants";
import { PageLoaderContext } from "../../../context/PageLoaderContext";
import { isMobile } from "../../../helpingFunctions/utilities";
import { chevronBack } from "ionicons/icons";
import { useTheme } from "../../../hooks/useTheme";
import { useHistory } from "react-router-dom";

const TreatYourselfPage = () => {
  const { typography } = useTheme();
  const location = useLocation();
  const history = useHistory();
  const { categoryId, treatId } = useParams();
  const { dynamicTexts } = useDataContext();
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const { warnerServices, getWarnerServiceOption } =
    useContext(NavigatorContext);
  const { selectedService, setSelectedService, treatsDetails } =
    useContext(TreatsContext);
  const { formatedTreats } = TreatYourselfPageLogic();

  useEffect(() => {
    if (warnerServices.length === 0 || !treatsDetails) {
      setIsLoaderOpened(true);
      setLoaderMessage("Loading Treats Page");
    } else {
      setIsLoaderOpened(false);
    }
  }, [warnerServices, treatsDetails]);

  return (
    <IonPage style={{ overflow: "auto" }}>
      <IonRow className="bl-margin-t-m">
        <IonCol
          size="12"
          className={`ion-text-center ${selectedService && "bl-padding-l-xs bl-padding-r-xs bl-margin-t-m"}`}
        >
          {!selectedService ? (
            <IonRow className="bl-flex">
              <IonCol size="3" className="bl-padding-l-s">
                {isMobile() && location?.pathname === ROUTE_VIEWS.TREATS && (
                  <BLButton
                    type="ui-small"
                    content="Back"
                    icon={chevronBack}
                    onClick={() => history.push(ROUTE_VIEWS.LANDING_PAGE)}
                  />
                )}
              </IonCol>
              <IonCol
                size="6"
                className="ion-justify-content-center ion-text-center"
              >
                <BLParagraph
                  txt={
                    location?.pathname === ROUTE_VIEWS.TREATS
                      ? getWarnerServiceOption(SERVICES_TYPES.TREATS)?.Title
                      : formatedTreats?.title
                  }
                  color="primary"
                  textVariant={typography.h2}
                // newClasses={`xx-large bold crimson-pro`}
                //TODO: Unclear typography
                />
              </IonCol>
              <IonCol size="3" />
            </IonRow>
          ) : (
            <BLHorizontalLineTitle
              txt={formatedTreats?.title || UNCATEGORIZED}
              color="primary"
              enableBackButton
              backButtonHandler={() => {
                setSelectedService(null);
                if (categoryId && categoryId !== UNCATEGORIZED) {
                  redirectBetweenPages(
                    ROUTE_VIEWS.TREATS_CATEGORY.replace(
                      ":categoryId",
                      categoryId
                    )
                  );
                } else redirectBetweenPages(ROUTE_VIEWS.TREATS);
              }}
              transperentBackground
            />
          )}
        </IonCol>
      </IonRow>

      <IonGrid>
        {!selectedService && (
          <IonRow className="bl-margin-t-m bl-margin-r-s bl-margin-l-s">
            <IonCol size="12" className="ion-text-center">
              <BLParagraph
                txt={
                  location?.pathname === ROUTE_VIEWS.TREATS
                    ? dynamicTexts?.Treats?.TreatCategory?.Paragraph_IntroText
                    : formatedTreats?.description
                }
                color="text-grey"
                markDown
              />
            </IonCol>
          </IonRow>
        )}

        <IonRow>
          <IonCol>
            <IonGrid className="bl-padding-s">
              {!categoryId && !treatId && treatsDetails && (
                <>
                  <IonRow>
                    <IonCol>
                      <TreatYourselfCategories
                        categories={treatsDetails.Categorized?.sort(
                          (a, b) =>
                            (a.categoryOrder === null) -
                            (b.categoryOrder === null) ||
                            a.categoryOrder - b.categoryOrder ||
                            (a.title > b.title ? 1 : -1)
                        )}
                      />
                    </IonCol>
                  </IonRow>
                </>
              )}

              {categoryId && (
                <>
                  <IonRow>
                    <IonCol>
                      <TreatYourselfServices formatedTreats={formatedTreats} />
                    </IonCol>
                  </IonRow>

                  {!selectedService && treatsDetails && (
                    <IonRow>
                      <IonCol className="bl-flex ion-justify-content-center">
                        <BLButton
                          content="Go back"
                          type="underlined"
                          onClick={() =>
                            redirectBetweenPages(ROUTE_VIEWS.TREATS)
                          }
                        />
                      </IonCol>
                    </IonRow>
                  )}
                </>
              )}
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default TreatYourselfPage;
