import { IonCol, IonRow } from "@ionic/react";
import { format } from "date-fns";
import React, { useContext } from "react";
import { INITIAL_DATE } from "../../../../../../../constants/constants";
import { useDataContext } from "../../../../../../../context/DataContext";
import { SpaContext } from "../../../../../../../context/SpaContext";
import {
  getCurrentDate,
  getDayPeriod,
  isDateWithinRange,
} from "../../../../../../../helpingFunctions/dateTimeUtilities";
import { generateKey } from "../../../../../../../helpingFunctions/helpingFunctions";
import { replacePlaceholders } from "../../../../../../../helpingFunctions/replacePlaceholders";
import BLLoadingContainer from "../../../../../../../shared/Ionic/BLLoadingContainer";
import BLInformationBox from "../../../../../../../shared/Ionic/Boxes/InformationBox/BLInformationBox";
import BLButton from "../../../../../../../shared/Ionic/Buttons/BLButton";

const TimeRowSeparateSelectionLogic = (extractTimeSelections, serviceDetails) => {
  const { reservationDetails, itineraryDetails, dynamicTexts, isLoadingItinerary } = useDataContext();
  const {
    spaSelections,
    spaAvailability,
    setSpaSelections,
    setSpaAvailability,
    handleProviderCode,
  } = useContext(SpaContext);

  const handleSeparateSelection = (obj, selIndex, selTimeIndex, dayTime) => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    const spaAvailabilityCopy = JSON.parse(JSON.stringify(spaAvailability));

    // If the same time is clicked - deselect
    if (spaSelectionsCopy[selIndex].on[selTimeIndex].time === obj.time) {
      if (spaSelectionsCopy[selIndex].on[selTimeIndex].action !== "add") return;
      // Handle availability
      const day = spaSelectionsCopy[selIndex].on[selTimeIndex].date;
      const providerCode =
        spaSelectionsCopy[selIndex].on[selTimeIndex].providerCode;
      handleProviderCode(
        spaAvailabilityCopy,
        day,
        dayTime,
        obj.time,
        "increment",
        providerCode
      );

      // Update time
      spaSelectionsCopy[selIndex].on[selTimeIndex].time = "";
      spaSelectionsCopy[selIndex].on[selTimeIndex].providerCode = "";

      if (spaSelectionsCopy[selIndex].on[selTimeIndex].action !== "add") {
        spaSelectionsCopy[selIndex].on[selTimeIndex].action = "remove";
      }
    } else {
      // Handle availability
      const selectedTime = spaSelectionsCopy[selIndex].on[selTimeIndex].time;
      let dayTimeString = INITIAL_DATE;
      if (selectedTime) {
        dayTimeString += `T${selectedTime.substring(0, 5)}`;
      }
      const when = getDayPeriod(dayTimeString);

      const day = spaSelectionsCopy[selIndex].on[selTimeIndex].date;
      const providerCode =
        spaSelectionsCopy[selIndex].on[selTimeIndex].providerCode;

      const availIndexToIncrement = when ? spaAvailability[
        spaSelectionsCopy[selIndex].on[selTimeIndex].date
      ][when].findIndex((avail) => avail.time === selectedTime) : -1;
      
      if (availIndexToIncrement > -1) {
        handleProviderCode(
          spaAvailabilityCopy,
          day,
          when,
          selectedTime,
          "increment",
          providerCode
        );
      }

      const newlySelProviderCode = handleProviderCode(
        spaAvailabilityCopy,
        day,
        dayTime,
        obj.time,
        "decrement",
        null
      );

      // Update time
      spaSelectionsCopy[selIndex].on[selTimeIndex].time = obj.time;
      spaSelectionsCopy[selIndex].on[selTimeIndex].providerCode =
        newlySelProviderCode;

      if (
        (["edit", "remove"].includes(spaSelectionsCopy[selIndex].action) ||
          ["edit", "remove"].includes(
            spaSelectionsCopy[selIndex].on[selTimeIndex].action
          )) &&
        spaSelectionsCopy[selIndex].on[selTimeIndex].date ===
          spaSelectionsCopy[selIndex].on[selTimeIndex].bookedDate &&
        spaSelectionsCopy[selIndex].on[selTimeIndex].time ===
          spaSelectionsCopy[selIndex].on[selTimeIndex].bookedTime &&
        spaSelectionsCopy[selIndex].on[selTimeIndex].providerCode ===
          spaSelectionsCopy[selIndex].on[selTimeIndex].bookedProviderCode &&
        spaSelectionsCopy[selIndex].fName ===
          spaSelectionsCopy[selIndex].bookedFName &&
        spaSelectionsCopy[selIndex].lName ===
          spaSelectionsCopy[selIndex].bookedLName
      ) {
        spaSelectionsCopy[selIndex].action = "none";
        spaSelectionsCopy[selIndex].on[selTimeIndex].action = "none";
      } else if (
        spaSelectionsCopy[selIndex].on[selTimeIndex].action !== "add" &&
        spaSelectionsCopy[selIndex].action !== "add"
      ) {
        spaSelectionsCopy[selIndex].on[selTimeIndex].action = "edit";
      }
    }

    setSpaAvailability(spaAvailabilityCopy);
    setSpaSelections(spaSelectionsCopy);
  };

  const isTimeSelected = (obj, selIndex, selTimeIndex) => {
    return spaSelections[selIndex].on[selTimeIndex].time === obj.time;
  };

  const retrieveContent = (date, selIndex, selTimeIndex) => {

    const isDisabled = 
      date === getCurrentDate() &&
      itineraryDetails.Dates[date].Spa.find(activity => activity.Id === serviceDetails.id) &&
      spaSelections[selIndex].on[selTimeIndex].time !== "" && 
      spaSelections[selIndex].on[selTimeIndex].time.slice(0, 5).replace(":", "") < format(new Date(), "kkmm");
   
    let availabilityAM = extractTimeSelections(date, "AM");
    let availabilityPM = extractTimeSelections(date, "PM");
    
    if (!isDisabled && date === getCurrentDate() && availabilityAM && availabilityPM && typeof availabilityAM !== "string" && typeof availabilityPM !== "string") {
      availabilityAM = availabilityAM.filter(slot => slot.time.slice(0, 5).replace(":", "") > format(new Date(), "kkmm"));
      availabilityPM = availabilityPM.filter(slot => slot.time.slice(0, 5).replace(":", "") > format(new Date(), "kkmm"));
    }

    if (date < getCurrentDate()) {
      return (
        <IonRow className="bl-margin-t-s">
          <IonCol>
            <BLInformationBox
              content={dynamicTexts?.Shared?.LackOfAvailability?.InfoBox_TimeSlot_PastTime}
              type="warning"
              markdown
            />
          </IonCol>
        </IonRow>
      );
    } else if (!availabilityAM && !availabilityPM) {
      return (
        <IonRow className="bl-margin-t-s">
          <IonCol>
            <BLInformationBox
              content={dynamicTexts?.Shared?.LackOfAvailability?.InfoBox_TimeSlot_AvailabilityRetrievalFailure}
              type="warning"
              markDown={!!dynamicTexts?.Shared?.LackOfAvailability?.InfoBox_TimeSlot_AvailabilityRetrievalFailure}
            />
          </IonCol>
        </IonRow>
      );
    } else if (availabilityAM === "loading" || availabilityPM === "loading" || isLoadingItinerary) {
      return (
        <IonRow className="ion-text-center">
          <IonCol>
            <BLLoadingContainer />
          </IonCol>
        </IonRow>
      );
    } else if (availabilityAM.length === 0 && availabilityPM.length === 0) {
      if (!isDateWithinRange(reservationDetails.ArrivalDate, 90)) {
        return (
          <IonRow className="bl-margin-t-s">
            <IonCol>
              <BLInformationBox
                content={replacePlaceholders(
                  dynamicTexts?.Shared?.LackOfAvailability
                    ?.InfoBox_TimeSlot_NoAvailabilityOutside90DaysFromToday,
                  {
                    PEOPLE_COUNT: 1,
                  }
                )}
                type="warning"
                markdown={
                  !!dynamicTexts?.Shared?.LackOfAvailability
                    ?.InfoBox_TimeSlot_NoAvailabilityOutside90DaysFromToday
                }
              />
            </IonCol>
          </IonRow>
        );
      } else {
        return (
          <IonRow className="bl-margin-t-s">
            <IonCol>
              <BLInformationBox
                content={replacePlaceholders(
                  dynamicTexts?.Shared?.LackOfAvailability
                    ?.InfoBox_TimeSlot_NoAvailabilityWithin90DaysFromToday,
                  {
                    PEOPLE_COUNT: 1,
                  }
                )}
                markdown={
                  !!dynamicTexts?.Shared?.LackOfAvailability
                    ?.InfoBox_TimeSlot_NoAvailabilityWithin90DaysFromToday
                }
                type="warning"
              />
            </IonCol>
          </IonRow>
        );
      }
    } else {
      return (
        <>
          {isDisabled && (
            <IonRow style={{ padding: "16px 0" }}>
              <BLInformationBox
                content={
                  dynamicTexts?.Shared?.LackOfAvailability
                    ?.InfoBox_TimeSlot_PastTime
                }
                type="warning"
                markdown
              />
            </IonRow>
          )}
          {(availabilityAM.length > 0 || availabilityPM.length > 0) && (
            <>
              <IonRow className="bl-margin-t-m" style={{ gap: "16px" }}>
                {availabilityAM.map((obj) => (
                  <BLButton
                    key={generateKey(obj)}
                    content={`${obj.shortTime} AM`}
                    disabled={
                      isDisabled ||
                      (obj.availability < 1 &&
                        !isTimeSelected(obj, selIndex, selTimeIndex))
                    }
                    type={
                      isTimeSelected(obj, selIndex, selTimeIndex)
                        ? "time"
                        : "time-outlined"
                    }
                    onClick={() =>
                      handleSeparateSelection(obj, selIndex, selTimeIndex, "AM")
                    }
                    defaultCursor={
                      spaSelections[selIndex].on[selTimeIndex].action !==
                        "add" &&
                      spaSelections[selIndex].on[selTimeIndex].time === obj.time
                    }
                  />
                ))}
                {availabilityPM.map((obj) => (
                  <BLButton
                    key={generateKey(obj)}
                    content={`${obj.shortTime} PM`}
                    disabled={
                      isDisabled ||
                      (obj.availability < 1 &&
                        !isTimeSelected(obj, selIndex, selTimeIndex))
                    }
                    type={
                      isTimeSelected(obj, selIndex, selTimeIndex)
                        ? "time"
                        : "time-outlined"
                    }
                    onClick={() =>
                      handleSeparateSelection(obj, selIndex, selTimeIndex, "PM")
                    }
                    defaultCursor={
                      spaSelections[selIndex].on[selTimeIndex].action !==
                        "add" &&
                      spaSelections[selIndex].on[selTimeIndex].time === obj.time
                    }
                  />
                ))}
              </IonRow>
            </>
          )}
        </>
      );
    }
  };

  return { retrieveContent };
};

export default TimeRowSeparateSelectionLogic;
