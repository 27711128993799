import React, { useContext, useEffect, useState } from "react";
import { TreatsContext } from "../../../../../../context/TreatsContext";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import { useTheme } from "../../../../../../hooks/useTheme";

const LocationsAndQuantityRowLogic = () => {
  const { typography } = useTheme();
  const { treatsSelections, getLocationLabel, daySelections, isEditMode } = useContext(TreatsContext);
  const [collapsedRowContent, setCollapsedRowContent] = useState(null);
  const [hasMultipleSel, setHasMultipleSel] = useState(false);

  const handleCollapsedRowSummary = () => {
    const content = [];

    const hasUnselectedDays = treatsSelections[0]?.options.find(
      (sel) => sel.serviceCode === "" || !sel.serviceCode
    );

    if (hasUnselectedDays) {
      content.push(
        <BLParagraph
          key="Locations & Quantities"
          txt="Locations & Quantities"
          color="primary"
          textVariant={typography.h3}
          // newClasses={`medium bold crimson-pro`}
          //TODO: Unclear typography
          dimensions={{
            margin: ["l-s"],
            padding: ["t-md", "b-md"],
          }}
        />
      );
    } else {
      const treatsSelCopy = JSON.parse(JSON.stringify(treatsSelections));
      treatsSelCopy.forEach((row, index) => {
        if (row.date !== "") {
          const dayObj = (
            <BLParagraph
              key={daySelections?.find((daySel) => daySel.id === row.date)
                ?.dayOfStay}
              txt={
                daySelections?.find((daySel) => daySel.id === row.date)
                  ?.dayOfStay
              }
              textVariant={typography.h3}
              // newClasses={`medium bold crimson-pro`}
              //TODO: Unclear typography
            />
          );

          const optObj = [];
          row.options.forEach((opt, index) => {
            if (opt.serviceCode !== "") {
              optObj.push(
                <BLParagraph
                  key={`${opt.quantity} x ${getLocationLabel(
                    opt.serviceCode
                  )}`}
                  txt={
                    <>
                      <BLParagraph
                        txt={`${opt.quantity} x ${getLocationLabel(
                          opt.serviceCode
                        )}`}
                        textVariant={typography.h3}
                      />
                      {isEditMode &&
                        (!["none"].includes(opt.action) ||
                          opt.quantity !== opt.bookedQuantity ||
                          opt.serviceCode !== opt.bookedServiceCode) && (
                        <BLParagraph
                          txt=" *Updated* "
                          color="success"
                          textVariant={typography.h3}
                          // newClasses={`medium bold crimson-pro`}
                          //TODO: Unclear typography
                        />
                      )}
                      <BLParagraph
                        txt={`${
                          index < row.options.length - 1 ? " and " : ""
                        }`}
                        textVariant={typography.h3}
                      />
                    </>
                  }
                  //newClasses={`bold`}
                  //TODO: Unclear typography
                />
              );
            }
          });

          if (optObj.length > 0) {
            content.push(
              <BLParagraph
                key={index}
                txt={
                  <>
                    {dayObj}
                    {optObj}
                  </>
                }
                color="primary"
                textVariant={typography.h3}
                // newClasses={`medium crimson-pro`}
                //TODO: Unclear typography
                dimensions={{
                  margin: ["l-s"],
                }}
              />
            );
          }
        }
      });
    }

    setHasMultipleSel(content.length > 1);
    setCollapsedRowContent(content);
  };

  useEffect(() => {
    handleCollapsedRowSummary();
  }, [treatsSelections]);

  return { collapsedRowContent, hasMultipleSel };
};

export default LocationsAndQuantityRowLogic;
