import { useContext } from "react";
import { SpaContext } from "../../../../../../../context/SpaContext";
import { INITIAL_DATE } from "../../../../../../../constants/constants";
import { getDayPeriod } from "../../../../../../../helpingFunctions/dateTimeUtilities";

const DaysRowSeparateSelectionLogic = () => {
  const {
    spaSelections,
    setSpaSelections,
    handleProviderCode,
    setSpaAvailability,
    spaAvailability,
  } = useContext(SpaContext);

  const handleSeparateSelection = (selIndex, dateIndex, val) => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));

    spaSelectionsCopy[selIndex].isEdited = false;
    spaSelectionsCopy[selIndex].on[dateIndex].isEdited = false;

    const providerCode = spaSelectionsCopy[selIndex].on[dateIndex].providerCode;
    if (providerCode !== "") {
      const spaAvailabilityCopy = JSON.parse(JSON.stringify(spaAvailability));
      const day = spaSelectionsCopy[selIndex].on[dateIndex].date;
      const time = spaSelectionsCopy[selIndex].on[dateIndex].time;
      let dayString = INITIAL_DATE;
      if (time) {
        dayString += `T${time.substring(0, 5)}`;
      }
      const dayTime = getDayPeriod(dayString);
      handleProviderCode(
        spaAvailabilityCopy,
        day,
        dayTime,
        time,
        "increment",
        providerCode
      );
      setSpaAvailability(spaAvailabilityCopy);
    }

    spaSelectionsCopy[selIndex].on[dateIndex].date = val.target.value;
    spaSelectionsCopy[selIndex].on[dateIndex].time = "";
    spaSelectionsCopy[selIndex].on[dateIndex].providerCode = "";

    if (
      spaSelectionsCopy[selIndex].action !== "add" &&
      spaSelectionsCopy[selIndex].on[dateIndex].action !== "add"
    ) {
      spaSelectionsCopy[selIndex].on[dateIndex].action = "edit";
    }

    setSpaSelections(spaSelectionsCopy);
  };

  const addSeparateDays = (selIndex) => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    spaSelectionsCopy[selIndex].on.push({
      date: "",
      time: "",
      bookedDate: "",
      bookedTime: "",
      action: "add",
      providerCode: "",
      bookedProviderCode: "",
    });
    setSpaSelections(spaSelectionsCopy);
  };

  const removeSeparateSelection = (selIndex, dateIndex) => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    const spaAvailabilityCopy = JSON.parse(JSON.stringify(spaAvailability));

    const providerCode = spaSelectionsCopy[selIndex].on[dateIndex].providerCode;
    const day = spaSelectionsCopy[selIndex].on[dateIndex].date;
    const time = spaSelectionsCopy[selIndex].on[dateIndex].time;
    let dayString = INITIAL_DATE;
    if (time) {
      dayString += `T${time.substring(0, 5)}`;
    }
    const dayTime = getDayPeriod(dayString);
    if (providerCode !== "" && spaAvailabilityCopy[day]) {
      handleProviderCode(
        spaAvailabilityCopy,
        day,
        dayTime,
        time,
        "increment",
        providerCode
      );
    }

    if (spaSelectionsCopy[selIndex].on[dateIndex].action === "add") {
      if (dateIndex === 0) {
        spaSelectionsCopy[selIndex].isEdited = false;
        spaSelectionsCopy[selIndex].on[dateIndex].date = "";
        spaSelectionsCopy[selIndex].on[dateIndex].time = "";
        spaSelectionsCopy[selIndex].on[dateIndex].providerCode = "";
      } else {
        spaSelectionsCopy[selIndex].on = spaSelectionsCopy[selIndex].on.filter(
          (row, index) => index !== dateIndex
        );
      }
    } else {
      spaSelectionsCopy[selIndex].on[dateIndex].action = "remove";
      spaSelectionsCopy[selIndex].on[dateIndex].providerCode = "";
    }

    setSpaAvailability(spaAvailabilityCopy);
    setSpaSelections(spaSelectionsCopy);
  };

  const hasDateSelected = (selectionIndex, dateIndex) => {
    return spaSelections[selectionIndex].on[dateIndex].date !== "";
  };

  const updateStatus = (selIndex, dateIndex) => {
    const spaSelectionsCopy = JSON.parse(JSON.stringify(spaSelections));
    spaSelectionsCopy[selIndex].isEdited = true;
    spaSelectionsCopy[selIndex].on[dateIndex].isEdited = true;
    setSpaSelections(spaSelectionsCopy);
  };

  return {
    hasDateSelected,
    handleSeparateSelection,
    addSeparateDays,
    removeSeparateSelection,
    updateStatus,
  };
};

export default DaysRowSeparateSelectionLogic;
