/**
	formatDuration
	
	@brief Formats the duration depending on the value
	@params
		* duration - String number or number
	@return String - if the the duration is 60 or less - returns minutes, else - decimal hours, ex. 30 min, 1.5hr, 2hr
**/
const formatDuration = (duration) => {
  const durationNum =  Number(duration);
  if (durationNum > 60) {
    const durationHours = durationNum / 60;
    return Number(durationHours.toFixed(1)) === Number(durationHours.toFixed())
      ? `${durationHours.toFixed()}hr`
      : `${durationHours.toFixed(1)}hr`;
  }

  return `${durationNum || 0} min`;
};

export {
  formatDuration
};