import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLIcon from "../BLIcon";
import BLParagraph from "../Paragraph/BLParagraph";
import { closeCircle, time, pricetag } from "ionicons/icons";
import { formatPrice } from "../../../helpingFunctions/formatPrice";
import { useTheme } from "../../../hooks/useTheme";
import { formatDuration } from "../../../helpingFunctions/formatDuration";

const BookingSelectionHeaderMobile = ({
  serviceDetails,
  onXSignClick,
  hideAddInfo,
}) => {
  const { typography } = useTheme();
  return (
    serviceDetails && (
      <IonGrid style={{ borderBottom: "2px solid var(--ion-color-primary)" }}>
        <IonRow className="bl-margin-b-xs">
          <IonCol className="bl-flex ion-align-items-center bl-margin-r-xxs">
            <BLParagraph
              txt={serviceDetails.Title}
              color="text-grey"
              textVariant={typography.h2}
              // newClasses={`xx-large bold crimson-pro`}
              //TODO: Unclear typography
              dimensions={{
                margin: ["l-lg"],
                padding: ["t-md", "b-md"],
              }}
            >
            </BLParagraph>
          </IonCol>
          <IonCol size="auto" className="bl-flex ion-justify-content-end">
            <BLIcon
              color="secondary"
              iconSize="md"
              iconSource={closeCircle}
              onClick={onXSignClick}
            />
          </IonCol>
        </IonRow>

        {hideAddInfo && (
          <IonRow className="bl-margin-t-xs bl-margin-b-xs">
            <IonCol
              className="ion-align-items-center bl-flex"
              style={{ maxWidth: "100px" }}
            >
              <BLIcon
                color="primary"
                iconSource={time}
                newClasses="bl-padding-r-xs"
              />
              <BLParagraph
                txt={formatDuration(serviceDetails?.Duration)}
                textVariant={typography.BODY_TEXT}
              />
            </IonCol>
            <IonCol className="ion-align-items-center bl-flex">
              <BLIcon
                color="primary"
                iconSource={pricetag}
                newClasses="bl-padding-r-xs"
              />
              <BLParagraph
                txt={`${
                  serviceDetails?.Price === "Inclusive"
                    ? "Inclusive"
                    : formatPrice(serviceDetails?.Price)
                }`}
                textVariant={typography.BODY_TEXT}
              />
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    )
  );
};

export default BookingSelectionHeaderMobile;
