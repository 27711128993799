import {
  COMPARE_RESULTS,
} from "../../../constants/constants";
import { datesCompare } from "../../../helpingFunctions/utilities";
import { DINING_TYPES } from "../../../constants/constants";
import { ENTERTAINMENT_TYPES } from "../../../constants/constants";

const convertItineraryObject = (itineraryDetails) => {
  const itineraryObject = {
    ArrivalDate: itineraryDetails.ArrivalDate,
    DepartureDate: itineraryDetails.DepartureDate,
    Hotel: itineraryDetails.Hotel,
    ReferenceNumber: itineraryDetails.ReferenceNumber,
    itineraryDates: [],
  };

  for (const date of Object.keys(itineraryDetails.Dates)) {
    itineraryObject.itineraryDates.push({
      Data: date,
      Entertainment: itineraryDetails.Dates[date].Entertainment.map(x => {
        const Title = x.Location;
        delete x.action;
        delete x.ShowTitle;
        return {...x, Title};
      }),
      Spa: itineraryDetails.Dates[date].Spa,
      Treats: itineraryDetails.Dates[date].Treats,
      Facilities: [
        ...itineraryDetails.Dates[date].Breakfast,
        ...itineraryDetails.Dates[date].Dinner,
        ...itineraryDetails.Dates[date].Activity,
        ...itineraryDetails.Dates[date].WellnessActivity,
      ],
      All: itineraryDetails.Dates[date].All,
    });
  }

  return itineraryObject;
};

const getBookedItemName = (bookedItem, entertainments, dynamicTexts) => {
  if (DINING_TYPES.includes(bookedItem.Type)) {
    return `${bookedItem.Type} at ${bookedItem.Title}`;
  }  else if (ENTERTAINMENT_TYPES.includes(bookedItem.Type)) {
    const entertainment = entertainments?.find(
      (ent) =>
        datesCompare(
          new Date(`${ent.ShowDate}T${ent.ShowTime.substr(0, 5)}`),
          new Date(`${bookedItem.Date}T${bookedItem.Time.substr(0, 5)}`)
        ) === COMPARE_RESULTS.EQUAL && ent.Location === bookedItem.Location
    );

    return entertainment?.ShowTitle
      ? entertainment.ShowTitle
      : dynamicTexts?.Entertainments?.Booking?.Paragraph_CardTitle;
  }
  return bookedItem.Title;
};

const isSpaGroup = (bookedItem) => {
  if (bookedItem.Type === 'Spa' && bookedItem.others?.length > 0) {
    return true;
  }
  return false;
};

const getBookedItemDescription = (bookedItem, CRSNumber, entertainments, dynamicTexts) => {
  try {
    let description = '';
    if (!isSpaGroup(bookedItem)) {
      description = `MWS - ${getBookedItemName(bookedItem, entertainments, dynamicTexts)} - Holiday Reference Number: ${CRSNumber} - Booking Number: ${bookedItem.BookingNumber}`;
    } else {
      let numberOfPeople = bookedItem.others.length;
      if (!bookedItem.isParticipant) {
        numberOfPeople += 1;
      }

      description = `
      MWS - ${getBookedItemName(bookedItem, entertainments, dynamicTexts)} - Holiday Reference Number: ${CRSNumber} - Booking Number: ${bookedItem.BookingNumber}
      This treatment has been booked for ${numberOfPeople} ${numberOfPeople > 1 ? `people` : 'person'}${!bookedItem.isParticipant ? ` (you and ${numberOfPeople - 1} other${numberOfPeople - 1 === 1 ? ') - ' : 's) - '}` : ' - '}`;
      for (let [index, person] of bookedItem.others.entries()) {
        description += `${person.ParticipantFirstName} ${person.ParticipantLastName}`;
        description += index + 1 < bookedItem.others.length ? ", ": "";
      }
    }
    return description;
  } catch (error) {
    console.error(
      `${new Date()} Error in getBookedItemDescription func in helpers.js file ${
        error.message
      }`
    );
  }
};

export { getBookedItemDescription, getBookedItemName, isSpaGroup, convertItineraryObject };
