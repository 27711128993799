import React from "react";

import BlurryWrapper from "../../../../../components/UI/BulurryWrapper_OLD/BlurryWrapper";
import Heading from "../../../../../shared/Heading_OLD/Heading";
import InformationText from "../../../../../shared/InformationText_OLD/InformationText";
import styles from "./LoginInfoBox.module.scss";

const LoginInfoBox = ({
  title,
  dsc,
  loginInfoClickHandler,
  isLoginInfoOpened,
  navigator,
}) => {
  const infoHighliter = () => {
    loginInfoClickHandler(!isLoginInfoOpened);
  };
  
  return (
    <BlurryWrapper
      new_styles={{
        transition: "1s all ease-in-out",
        width: "600px",
        minHeight: "200px",
        position: "absolute",
        bottom: "-85px",
        marginLeft: "50vw",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        backdropFilter: "blur(0px)",
        borderRadius: "5px 5px 0 0",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
        cursor: "pointer",
      }}
      clickFunction={infoHighliter}
      whiteShadowStyle={{
        padding: "30px 25px 90px",
        background: `rgba(255, 255, 255, ${
          isLoginInfoOpened ? "0.5" : "0.2"
        })`,
      }}
    >
      <Heading
        txt={title}
        customHeadingClass={styles.heading}
      />
      <InformationText
        content={dsc}
        className={styles.informationText}
      />
      {navigator}
    </BlurryWrapper>
  );
};

export default LoginInfoBox;
