import React, { useEffect, useState } from "react";
import { useDataContext } from "../../../context/DataContext";
import BLLoadingContainer from "../../../shared/Ionic/BLLoadingContainer";
import AxiosInstance from "../../../url/createAxios";
import CardAuthorisation from "./components/CardAuthorisation";
import CardRegistration from "./components/CardRegistration";
import CardSummary from "./components/CardSummary";
import RegisterCardInitial from "./components/RegisterCardInitial";

const RegisterCardPageLogic = () => {
  const instance = AxiosInstance();

  const { reservationDetails, setReservationDetails, dynamicTexts } =
    useDataContext();

  const [pageData, setPageData] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const changePage = (data) => {
    try {
      setPageData(data);
    } catch (error) {
      console.error(
        `${new Date()} Error in changeModalPage func inside RegisteredCardLogic.js file ${
          error.message
        }`
      );
    }
  };

  const pxpPage = async (isReturned) => {
    try {
      const { data } = await instance.get(`/pxp/checkout`, {
        withCredentials: true,
      });

      if (data.hasRegisteredCard) {
        if (reservationDetails.CreditCard) {
          changePage({ page: "CardSummary", details: data, addedNow: false });
        } else {
          changePage({
            page: "CardAuthorisation",
            details: data,
            authorizationStatus: "success",
            reservationCardStatus: "failure",
          });
        }
      } else {
        if (isReturned) {
          changePage({ page: "CardRegistration", details: data });
          setIsLoading(true);
        } else {
          changePage({ page: "RegisterCardInitial", details: data });
        }
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in pxpPage in RegisteredCardLogic.js ${
          error.message
        }`
      );
    }
  };

  const pageDisplayHandler = async () => {
    try {
      let page = null;

      switch (pageData.page) {
        case "RegisterCardInitial": {
          page = (
            <RegisterCardInitial
              details={pageData.details}
              changePage={changePage}
            />
          );
          setIsLoading(false);
          break;
        }
        case "CardRegistration": {
          page = (
            <CardRegistration
              details={pageData.details}
            />
          );
          setIsLoading(true);
          break;
        }
        case "CardSummary": {
          page = (
            <CardSummary
              details={pageData.details}
              changePage={changePage}
              addedNow={pageData.addedNow}
              staticTexts={dynamicTexts?.CreditCard}
            />
          );
          break;
        }
        case "CardAuthorisation": {
          page = (
            <CardAuthorisation
              details={pageData.details}
              changePage={changePage}
              authorizationStatus={pageData.authorizationStatus}
              reservationCardStatus={pageData.reservationCardStatus}
              retryAddingToMaestro={retryAddingToMaestro}
              pxpPage={pxpPage}
              staticTexts={dynamicTexts?.CreditCard}
            />
          );
          break;
        }
        default: {
          page = <BLLoadingContainer />;
        }
      }

      setCurrentPage(page);
    } catch (error) {
      console.error(
        `${new Date()} Error in pageDisplayHandler func inside RegisteredCardLogic.js file ${
          error.message
        }`
      );
    }
  };

  const cleanCardStatus = async () => {
    try {
      await instance.post("/pxp/cleanStatus", {
        withCredentials: true,
      });
    } catch (error) {
      console.error(
        `${new Date()} Error in cleanStatus func at CardAuthorisation.jsx file ${
          error.message
        }`
      );
    }
  };

  const retryAddingToMaestro = async () => {
    try {
      changePage({
        page: "CardAuthorisation",
        details: pageData.details,
        authorizationStatus: "success",
        reservationCardStatus: "loading",
      });

      const { data: status } = await instance.post(`/pxp/callback`, {
        withCredentials: true,
      });

      if (status === "success") {
        setReservationDetails((prevState) => {
          return {
            ...prevState,
            CreditCard: true,
          };
        });
        changePage({
          page: "CardSummary",
          details: pageData.details,
          addedNow: true,
        });
      } else {
        changePage({
          page: "CardAuthorisation",
          details: pageData.details,
          authorizationStatus: "success",
          reservationCardStatus: "failure",
          retryAddingToMaestro,
        });
      }
    } catch (e) {
      changePage({
        page: "CardAuthorisation",
        details: pageData.details,
        authorizationStatus: "success",
        reservationCardStatus: "failure",
        retryAddingToMaestro,
      });
    }
  };

  useEffect(() => {
    if (pageData !== null) {
      pageDisplayHandler();
    }
  }, [pageData]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(async () => {
        const { data } = await instance.get(`/pxp/getLastTransactionStatus`, {
          withCredentials: true,
        });

        if (data.hasRegisteredCard) {
          clearInterval(interval);
          try {
            changePage({
              page: "CardAuthorisation",
              details: data,
              authorizationStatus: "success",
              reservationCardStatus: "loading",
              retryAddingToMaestro,
            });

            const { data: status } = await instance.post(`/pxp/callback`, {
              withCredentials: true,
            });

            if (status === "success") {
              setReservationDetails((prevState) => {
                return {
                  ...prevState,
                  CreditCard: true,
                };
              });
              changePage({
                page: "CardSummary",
                details: data,
                addedNow: true,
              });
            } else {
              changePage({
                page: "CardAuthorisation",
                details: data,
                authorizationStatus: "success",
                reservationCardStatus: "failure",
                retryAddingToMaestro,
              });
            }
          } catch (e) {
            changePage({
              page: "CardAuthorisation",
              details: data,
              authorizationStatus: "success",
              reservationCardStatus: "failure",
              retryAddingToMaestro,
            });
          }
        } else {
          if (data.status === "error" || data.status === "refused") {
            clearInterval(interval);
            changePage({
              page: "CardAuthorisation",
              details: data,
              authorizationStatus: "failure",
            });
            setIsLoading(false);
            cleanCardStatus();
          }
        }
      }, 4000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isLoading]);

  useEffect(() => {
    pxpPage();
  }, []);

  return { currentPage, pageData };
};

export default RegisterCardPageLogic;
