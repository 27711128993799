import axios from "axios";
import { useHistory } from "react-router-dom";

import { CapacitorHttp } from "@capacitor/core";
import { ERROR_TYPES } from "../constants/errorConstants";
import { useDataContext } from "../context/DataContext";
import { ROUTE_VIEWS } from "../routes/routeViews";

const AxiosInstance = () => {
  let lastTimeRedirectedToLoginPage = 0;
  const history = useHistory();
  const { setHasReservationExpired, setExpiredSessionErrMsg, clearOnLogOut, prepLoginPageErrMsg } =
  useDataContext();

  const validateSession = (statusCode) => {
    if (
      statusCode === 403 &&
      history.location.pathname !== ROUTE_VIEWS.LOGIN &&
      Date.now() - lastTimeRedirectedToLoginPage > 2000
    ) {
      lastTimeRedirectedToLoginPage = Date.now();
      clearOnLogOut();
      setHasReservationExpired(true);
      const expMsg = prepLoginPageErrMsg(ERROR_TYPES.ERR_DEFAULT);
      setExpiredSessionErrMsg(expMsg);
      history.push(ROUTE_VIEWS.LOGIN);
      return false;
    }

    return true;
  };

  let instance = null;
  if (process.env.REACT_APP_USE_IONIC) {
    // console.log("USING IONIC VERSION. TODO: remove when we move to fully functional ionic usage");
    instance = {
      get(url, params) {
        const options = {
          url: process.env.REACT_APP_BACK_ENDPOINT + url,
          params: params?.params ? params.params : {},
          webFetchExtra: {
            credentials: "include",
          },
        };

        return CapacitorHttp.get(options)
          .then((res) => {
            if (validateSession(res.status, res.data)) {
              return res;
            }
          })
          .catch((error) => {
            validateSession(error?.response?.status);
          });
      },

      post(url, data) {
        const options = {
          url: process.env.REACT_APP_BACK_ENDPOINT + url,
          headers: { "Content-Type": "application/json" },
          data: { ...data },
          webFetchExtra: {
            credentials: "include",
          },
        };

        return CapacitorHttp.post(options)
          .then((res) => {
            if (validateSession(res.status, res.data)) {
              return res;
            }
          })
          .catch((error) => {
            validateSession(error?.response?.status);
          });
      },

      delete(url) {
        const options = {
          url: process.env.REACT_APP_BACK_ENDPOINT + url,
          webFetchExtra: {
            credentials: "include",
          },
        };

        return CapacitorHttp.delete(options)
          .then((res) => {
            if (validateSession(res.status, res.data)) {
              return res;
            }
          })
          .catch((error) => {
            validateSession(error?.response?.status);
          });
      },
    };
  } else {
    //TODO: completely remove this code when we have full portability
    //for ionic Http compared with current axios implementation
    instance = axios.create({
      baseURL: process.env.REACT_APP_BACK_ENDPOINT,
      withCredentials: true,
      crossDomain: true,
    });

    instance.interceptors.request.use((x) => {
      //console.log(`AX REQ: ${util.inspect(x, {depth: 7})}`);
      return x;
    });

    // Add a response interceptor
    instance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        //console.log(`AX RESP: ${util.inspect(response, {depth: 7})}`);
        return response;
      },
      function (error) {
        //console.log(`AX ERR: ${util.inspect(error, {depth: 7})}`);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        switch (error?.response?.status) {
          case 403: {
            validateSession(403);
            break;
          }
          default:
            break;
        }

        return Promise.reject(error);
      }
    );
  }

  return instance;
};

export default AxiosInstance;
