import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../Paragraph/BLParagraph";
import BLItem from "../../Cards/BLItem/BLItem";
import BLImageCarousel from "../../Carousel/BLImageCarousel";
import BLCheckTriangle from "../../Cards/BLCheckTriangle/BLCheckTriangle";
import { useTheme } from "../../../../hooks/useTheme";

const CategoryCard = ({
  categoryImg,
  title,
  hasBooking,
  icon,
  titleContentStyle,
}) => {
  const { typography } = useTheme();
  return (
    <BLItem
      containerType="category"
      contentType="category"
      imageType="summary"
      imageContent={
        <>
          {hasBooking && (
            <BLCheckTriangle
              size="sm"
            />
          )}
          <BLImageCarousel
            content={[
              { id: categoryImg, formats: { small: { url: categoryImg } } },
            ]}
          />
        </>
      }
      mainContent={
        <>
          {icon ? (
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {icon && icon}
                  <BLParagraph
                    color="text-grey"
                    txt={title}
                    textVariant={typography.h2}
                    // newClasses={`medium bold crimson-pro`}
                    //TODO: Unclear typography
                    newStyles={{
                      minHeight: "50px",
                      display: "grid",
                      alignItems: "center",
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <BLParagraph
              color="text-grey"
              txt={title}
              // newClasses={`x-large bold crimson-pro`}
              //TODO: Unclear typography
              textVariant={typography.h2}
              newStyles={{
                minHeight: "50px",
                display: "grid",
                alignItems: "center",
              }}
            />
          )}
        </>
      }
      contentDimensions={{ padding: [] }}
      newStylesMainContentRow={titleContentStyle}
      newWrapperStyles={{ height: "100%" }}
    />
  );
};

export default CategoryCard;
