import React from "react";
import { IonModal, IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import BLContentCardWrapper from "../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import CreateDimensions from "../../../../../helpingFunctions/DimensionsFactory";
import { closeCircle, openOutline } from "ionicons/icons";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import styles from "./Restaurants.module.scss";
import RestaurantExpandedCategories from "./RestaurantExpandedCategories";
import { RESTAURANT_MENU_LINK_TEXT } from "../../DiningBookingConstants";
import { useTheme } from "../../../../../hooks/useTheme";

const AdditionalRestaurantDetailsPopUp = ({
  showAlert,
  dismissAlert,
  restDetails,
  onSelectClick,
  isSelected,
}) => {
  const { typography } = useTheme();
  const { dimensions: HeaderDimensionsStyles } = CreateDimensions({
    padding: ["xxs"],
  });

  const withContentCategories = restDetails?.Details?.filter(({ icon }) => icon);

  return (
    <IonModal
      className="bl-popup-details"
      isOpen={!!showAlert}
      backdropDismiss={false}
      showBackdrop
    >
      <BLContentCardWrapper
        newClasses={`${styles.additional_details_background} ion-text-end bl-padding-xxs`}
      >
        <BLIcon
          iconSource={closeCircle}
          color="secondary"
          onClick={() => dismissAlert(null)}
          iconSize="md"
        />
      </BLContentCardWrapper>
      <IonGrid
        style={{
          height: `calc(100% - 40px)`,
          overflowY: "auto",
        }}
        className={styles.additional_details_background}
      >
        <IonRow className={`${HeaderDimensionsStyles} ion-align-items-center`}>
          <IonCol className="ion-text-center">
            <BLParagraph
              txt={restDetails?.Title}
              color="primary"
              textVariant={typography.h3}
              // newClasses={`large bold`}
              //TODO: Unclear typography
            />
          </IonCol>
        </IonRow>
        <IonRow className="ion-margin-bottom">
          <IonCol style={{ height: "328px" }}>
            <BLImageCarousel content={restDetails?.Image} />
          </IonCol>
        </IonRow>

        <IonRow className={`${styles.additional_details_button_container}`}>
          <IonCol
            size="auto"
            className="bl-padding-xs"
            onClick={() =>
              restDetails?.Menu &&
              window.open(restDetails?.Menu, "_blank").focus()
            }
          >
            <BLButton
              type="outlined-icon-text"
              slot="end"
              icon={openOutline}
              iconSize="md"
              disabled={!restDetails?.Menu}
              content={RESTAURANT_MENU_LINK_TEXT}
            />
          </IonCol>
          <IonCol
            size="auto"
            className="bl-padding-xs"
            onClick={() => {
              onSelectClick(restDetails.id);
            }}
          >
            <BLButton
              type={isSelected ? "outlined" : "primary"}
              content={isSelected ? "Deselect" : "Select"}
            />
          </IonCol>
        </IonRow>

        {restDetails?.Details && (
          <IonRow className="bl-margin-xs bl-margin-b-s">
            <IonCol>
              <RestaurantExpandedCategories categories={withContentCategories} />
            </IonCol>
          </IonRow>
        )}

        {restDetails?.LongDescription && (
          <IonRow className="bl-margin-xs">
            <IonCol>
              <BLParagraph
                txt={restDetails?.LongDescription}
                color="text-grey"
                textVariant={typography.BODY_TEXT}
                markDown={!!restDetails?.LongDescription}
              />
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </IonModal>
  );
};

export default AdditionalRestaurantDetailsPopUp;
