import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../../../shared/Ionic/Paragraph/BLParagraph";
import BLImageCarousel from "../../../../../../shared/Ionic/Carousel/BLImageCarousel";
import { xSign, PriceTag } from "../../../../../../assets/SVGIcons";
import BLContentCardWrapper from "../../../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import BLIcon from "../../../../../../shared/Ionic/BLIcon";
import { formatPrice } from "../../../../../../helpingFunctions/formatPrice";
import { useTheme } from "../../../../../../hooks/useTheme";

const AdditionalTreatsDetails = ({
  dismissAlert,
  images,
  header,
  price,
  longDescription,
}) => {
  const { typography } = useTheme();
  return (
    <IonCol>
      <BLContentCardWrapper dimensions={{ padding: ["s"] }}>
        <IonGrid className="bl-flex">
          <IonRow
            className="ion-text-center bl-flex ion-align-items-center ion-justify-content-center"
            style={{ height: "50px" }}
          >
            <IonCol style={{ height: "500px", width: "350px" }}>
              <BLImageCarousel content={images} />
            </IonCol>
          </IonRow>
          <IonRow style={{ overflow: "auto", height: "500px", width: "100%" }}>
            <IonCol>
              <IonGrid className="bl-margin-xs" style={{ marginRight: 0 }}>
                <IonRow className="ion-margin-bottom">
                  <IonCol
                    className="bl-flex ion-align-items-center ion-justify-content-between"
                  >
                    <BLParagraph
                      txt={header}
                      color="primary"
                      textVariant={typography.h2}
                      // newClasses={`xx-large bold`}
                      //TODO: Unclear typography
                      dimensions={{
                        margin: ["l-lg"],
                        padding: ["t-md", "b-md"],
                      }}
                    />
                    <div
                      style={{ marginLeft: "8px", display: "flex" }}
                      onClick={() => dismissAlert(false)}
                    >
                      <BLIcon
                        customIconSource={xSign("24px", "var(--ion-color-secondary)", "var(--ion-color-white)")}
                      />
                    </div>
                  </IonCol>
                </IonRow>

                <IonRow className="bl-margin-b-s" style={{ gap: "15px" }}>
                  <IonCol
                    className="bl-flex ion-align-items-center ion-justify-content-start"
                    style={{ gap: "5px" }}
                    size={4}
                  >
                    <BLIcon customIconSource={PriceTag(24, "var(--ion-color-primary)")} />
                    <BLParagraph txt={formatPrice(price)} />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <BLParagraph
                      txt={longDescription}
                      color="text-grey"
                      textVariant={typography.BODY_TEXT}
                      dimensions={{
                        margin: ["l-lg"],
                        padding: ["t-md", "b-md"],
                      }}
                    />
                  </IonCol>
                </IonRow>

                {/* <IonRow style={{position: "absolute", bottom: 0}}>
                  <IonCol>
                    <BLInformationBox
                      content={TREATS_ALERT_MESSAGE}
                      type={"info"}
                    />
                  </IonCol>
                </IonRow> */}
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </BLContentCardWrapper>
    </IonCol>
  );
};

export default AdditionalTreatsDetails;
