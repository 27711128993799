import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useHistory } from "react-router-dom";
import ValidateLoginHook from "../../../hooks/ValidateLoginHook";
import { ROUTE_VIEWS } from "../../../routes/routeViews";

const RedirectToLandingPageLogic = (setIsLoadingDeeplink) => { 
  const { handleLogin, logout } = ValidateLoginHook();
  const { hotelId, bookingId, surname } = useParams();
  const history = useHistory();

  const handleRequest = async () => {
    const loginData = { maestroId: hotelId, bookingId, surname };
    const res = await handleLogin(loginData);

    if (res.success) {
      history.replace(ROUTE_VIEWS.LANDING_PAGE);
    } else {
      await logout();
    }
    setIsLoadingDeeplink(false);
  };

  useEffect(() => {
    setIsLoadingDeeplink(true);
    handleRequest();
  }, []);
};

export default RedirectToLandingPageLogic;
