import React from "react";
import moment from "moment";

import { getDayOfWeek } from "../../helpingFunctions/utilities";
import WindowWidth from "../../hooks/WindowWidth";
import DayButtonLogic from "./DayButtonLogic";

import styles from "./day_button.module.css";

const DayButton = ({
  date,
  timeSelected,
  handleClick,
  isSelected,
  beforeSelected,
  length,
  view,
}) => {
  const { width } = WindowWidth();
  const { widthStyle, isPreparing, getEntWidth, whatsOnGuideDay } =
    DayButtonLogic(view, length);

  const isInMidRange = width > 700 && length <= 5;
  const isInBigRange = width > 1324;
  const isInEntAndSelected = view === "Entertainment" && isSelected;
  const isInFullDisplayRange =
    isInMidRange || isInBigRange || isInEntAndSelected;
  const isInWhatsOnGuide = view === "WhatsOnGuide";

  return (
    <>
      {isPreparing ? (
        <></>
      ) : (
        <div
          className={`${styles.dayBtn} ${isSelected ? styles.focused : ""} ${
            beforeSelected ? styles.beforeSelect : null
          }`}
          onClick={handleClick}
          style={{
            width:
              view === "Entertainment"
                ? getEntWidth(width, isSelected).width
                : widthStyle.width,
            height: isInWhatsOnGuide || isSelected ? "75px" : null,
          }}
        >
          <div className={styles.day}>
            <span
              className={`${styles.dayWrapper} ${styles.dayWrapper_desktop}`}
            >
              {isInWhatsOnGuide
                ? whatsOnGuideDay(date)
                : isInFullDisplayRange
                  ? getDayOfWeek(date)
                  : moment(date).format("ddd")}
            </span>
            {isInWhatsOnGuide ? (
              <span
                style={{
                  fontWeight: "100",
                }}
              >
                {getDayOfWeek(date)}
              </span>
            ) : null}
            <span
              className={styles.timeDisplayed}
              style={{
                fontWeight: timeSelected === "not selected" ? "100" : "bold",
                fontStyle: timeSelected === "not selected" ? "italic" : null,
              }}
            >
              {isInWhatsOnGuide ? moment(date).format("DD.MM") : timeSelected}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default DayButton;
