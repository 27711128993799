import React, { createContext, useState } from "react";

import { ACTIVITIES_TYPES } from "../../../constants/constants";
import { DINING_TYPES } from "../../../constants/constants";
import { EXPANDABLE_BOXES_TYPES, ITINERARY_BOOKED_TYPE } from "./constants";

const selectedBoxesInit = {
  [EXPANDABLE_BOXES_TYPES.DAY]: null,
  [EXPANDABLE_BOXES_TYPES.BOOKING]: null,
};

export const ItineraryContext = createContext({});

export const ItineraryProvider = ({ children, calendarLinksData, setCalendarLinksData }) => {
  const [selectedBoxes, setSelectedBoxes] = useState(selectedBoxesInit);

  const boxSelectionHandler = (selectedBoxValue, selectedBoxType) => {
    try {
      if (selectedBoxes[selectedBoxType] === selectedBoxValue) {
        setSelectedBoxes((prevState) => {
          if (selectedBoxType === EXPANDABLE_BOXES_TYPES.DAY) {
            return selectedBoxesInit;
          }
          return {
            ...prevState,
            [selectedBoxType]: null,
          };
        });

        return;
      }

      setSelectedBoxes((prevState) => {
        if (selectedBoxType === EXPANDABLE_BOXES_TYPES.DAY) {
          return {
            ...selectedBoxesInit,
            [selectedBoxType]: selectedBoxValue,
          };
        }

        return {
          ...prevState,
          [selectedBoxType]: selectedBoxValue,
        };
      });
    } catch (error) {
      console.error(
        `${new Date()} Error in boxSelectionHandler func in ItineraryProvider.js file ${
          error.message
        }`
      );
    }
  };

  const bookingTypeDisplay = (bookedItem) => {
    try {
      if (!bookedItem) {
        return null;
      }

      const { Type, Title } = bookedItem;

      if (DINING_TYPES.includes(Type)) {
        return Type;
      } else if (ACTIVITIES_TYPES.includes(Type)) {
        return Title;
      } else if (Type === 'Checkin') {
        return 'Check in';
      } else if (Type === 'Checkout') {
        return 'Check out';
      }

      return ITINERARY_BOOKED_TYPE[Type];
    } catch (error) {
      console.error(
        `${new Date()} Error in bookingTypeDisplay func in ItineraryProvider.js file ${
          error.message
        }`
      );
    }
  };

  return (
    <ItineraryContext.Provider
      value={{
        selectedBoxes,
        boxSelectionHandler,
        bookingTypeDisplay,
        calendarLinksData,
        setCalendarLinksData,
      }}
    >
      {children}
    </ItineraryContext.Provider>
  );
};
