import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import CreateDimensions from "../../../../../helpingFunctions/DimensionsFactory";
import styles from "./Restaurants.module.scss";
import { useTheme } from "../../../../../hooks/useTheme";

const RestaurantExpandedCategories = ({ categories }) => {
  const { typography } = useTheme();
  const { dimensions: categoryDimensionsStyles } = CreateDimensions({
    margin: ["t-xxs"],
  });

  return (
    <IonGrid>
      <IonRow
        style={{ gap: "8px" }}
        className={`${categoryDimensionsStyles} ion-align-items-center`}
      >
        {categories?.map(({ icon, iconDescription }) => (
          <IonCol
            key={`${iconDescription}_${icon?.id}`}
            className={`bl-flex ion-align-items-center ${styles.additional_details_icon_categories}`}
          >
            <img
              className={styles.RestaurantExpandedInfoIcons}
              src={icon?.url}
              alt={icon?.alternativeText}
            />
            <BLParagraph
              txt={iconDescription}
              textVariant={typography.BODY_TEXT}
              dimensions={{ margin: ["l-xs"] }}
              color="text-grey"
            />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default RestaurantExpandedCategories;
