import React from "react";
import { IonCol, IonRow } from "@ionic/react";
import { useTheme } from "../../../hooks/useTheme";

import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";

const LoginPageTitle = ({ title }) => {
  const { typography } = useTheme();

  return (
    <IonRow>
      <IonCol>
        <BLParagraph
          txt={title}
          color="accent-pale-grey"
          textVariant={typography.h1}
        />
      </IonCol>
    </IonRow>
  );
};

export default LoginPageTitle;
