import React from "react";
import CreateDimensions from "../../../helpingFunctions/DimensionsFactory";

import styles from "./Loader.module.scss";

const LocalLoader = ({ loaderSize, dimensions }) => {
  const { dimensions: localLoaderDimensionStyles } =
    CreateDimensions(dimensions);
  return (
    <img
      src={"/img/info_icons/loading_.svg"}
      className={`${styles.loader} ${localLoaderDimensionStyles}`}
      style={{ width: loaderSize, height: loaderSize }}
      alt="loader"
    />
  );
};

export default LocalLoader;
