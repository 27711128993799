import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import React from "react";

import { checkmark } from "ionicons/icons";
import { useDataContext } from "../../../../context/DataContext";
import CreateDimensions from "../../../../helpingFunctions/DimensionsFactory";
import { classFormatter, cssModuleClassConvert } from "../../BLIonicHelpers";
import BLParagraph from "../../Paragraph/BLParagraph";
import styles from "./BLItem.module.scss";
import { useTheme } from "../../../../hooks/useTheme";

const BLItem = ({
  imageContent,
  mainContent,
  isUnavailable = false,
  unavailableBannerText = "Not available",
  isSelected = {
    status: false,
    showBorder: false,
    position: "right",
  },
  newClasses = "",
  dimensions = {},
  contentDimensions = {
    padding: ["t-xs", "b-s"],
  },
  isLastFew = false,
  isNotAvailable = false,
  onClick = () => {},
  containerType,
  contentType,
  imageType,
  newWrapperStyles,
  newStylesMainContentRow,
}) => {
  const { typography } = useTheme();
  const itemSpecificClasses = classFormatter(newClasses);
  const moduleFriendlyClasses = cssModuleClassConvert(
    styles,
    itemSpecificClasses
  );
  const { dynamicTexts } = useDataContext();
  const { dimensions: BLItemDimensionStyles } = CreateDimensions(dimensions);

  const { dimensions: mainContentDefaultDimensions } =
    CreateDimensions(contentDimensions);

  const CheckTriangle = (
    <>
      <span
        className={
          isSelected.position === "right"
            ? styles.BLItem_check
            : styles.BLItem_check_left
        }
      >
        <IonIcon
          className={
            isSelected.position === "right"
              ? styles.BLItemCheckIcon
              : styles.BLItemCheckIcon_left
          }
          icon={checkmark}
        />
      </span>
      <span
        className={
          isSelected.position === "right"
            ? styles.BLItem_triangle
            : styles.BLItem_triangle_left
        }
      ></span>
    </>
  );

  const IsUnavailableBanner = (
    <div className={styles.BLItem_unavailable_banner}>
      <BLParagraph
        txt={unavailableBannerText}
        textVariant={typography.BODY_TEXT}
      />
    </div>
  );

  const CardBanner = (
    <div className={styles.BLItem_lastfew_banner}>
      <BLParagraph
        txt={
          isLastFew
            ? dynamicTexts?.Shared?.Banners?.Banner_Card_LimitedAvailability
            : dynamicTexts?.Shared?.Banners
              ?.Banner_Card_NoAvailabilityWithin90DaysFromToday
        }
        textVariant={typography.BODY_TEXT}
      />
    </div>
  );

  return (
    <IonGrid
      className={`${styles.BLItem} 
        ${moduleFriendlyClasses} ${BLItemDimensionStyles} 
        ${styles[`bl-${containerType}-card-container`]}`}
      style={{
        ...newWrapperStyles,
        justifyContent: "stretch",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={onClick}
    >
      {(isLastFew || isNotAvailable) && CardBanner}
      <IonRow>
        <IonCol size="12" className={`${styles[`bl-${imageType}-card-image`]}`}>
          {imageContent}
          {isSelected.status && <div className={styles.BLItem_isSelected} />}
        </IonCol>
      </IonRow>
      <IonRow
        className={`ion-justify-content-center ${mainContentDefaultDimensions} ${
          styles[`bl-${contentType}-card-content`]
        } ${styles.BLItemCenterText}`}
        style={{ height: "100%", ...newStylesMainContentRow }}
      >
        <IonCol size="12">{mainContent}</IonCol>
      </IonRow>
      {isSelected.status && CheckTriangle}
      {isUnavailable && IsUnavailableBanner}
    </IonGrid>
  );
};

export default BLItem;
