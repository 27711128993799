import React from "react";
import { IonGrid, IonRow } from "@ionic/react";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import BLContentCardWrapper from "../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import { CheckCircle, alertCircle } from "../../../../assets/SVGIcons";
import BLIcon from "../../../../shared/Ionic/BLIcon";
import BLLoadingContainer from "../../../../shared/Ionic/BLLoadingContainer";
import BLButton from "../../../../shared/Ionic/Buttons/BLButton";
import { repeat } from "ionicons/icons";
import { NavigatorContext } from "../../../../context/NavigatorContext";
import { useContext } from "react";
import { ROUTE_VIEWS } from "../../../../routes/routeViews";
import { useTheme } from "../../../../hooks/useTheme";

const CardAuthorisation = ({
  details,
  changePage,
  authorizationStatus,
  reservationCardStatus,
  retryAddingToMaestro,
  pxpPage,
  staticTexts,
}) => {
  const { typography } = useTheme();
  const { redirectBetweenPages } = useContext(NavigatorContext);

  const pxpStatusCard = (
    <BLContentCardWrapper newClasses="ion-padding">
      <IonGrid>
        <IonRow
          className="ion-justify-content-start ion-align-items-center"
          style={{ gap: "16px" }}
        >
          {authorizationStatus === "loading" && (
            <>
              <BLLoadingContainer newClasses="ion-no-margin" />
              <BLParagraph
                txt="Your card is being authorized"
                textVariant={typography.BODY_TEXT}
                color="text-grey"
              />
            </>
          )}
          {authorizationStatus === "success" && (
            <>
              <BLIcon
                customIconSource={CheckCircle(32, 1.3335)}
                newClasses="ion-no-padding"
              />
              <BLParagraph
                txt="Authorized successfully"
                textVariant={typography.BODY_TEXT}
                color="text-grey"
              />
            </>
          )}
          {authorizationStatus === "failure" && (
            <>
              <BLIcon
                customIconSource={alertCircle(35, "var(--ion-color-danger)")}
              />
              <BLParagraph
                txt="Your card has failed authorization"
                textVariant={typography.BODY_TEXT}
                color="text-grey"
              />
            </>
          )}
        </IonRow>
      </IonGrid>
    </BLContentCardWrapper>
  );

  const maestroStatusCard = (
    <BLContentCardWrapper newClasses="ion-padding">
      <IonGrid>
        <IonRow
          className="ion-justify-content-start ion-align-items-center"
          style={{ gap: "16px" }}
        >
          {reservationCardStatus === "loading" && (
            <>
              <BLLoadingContainer newClasses="ion-no-margin" />
              <BLParagraph
                txt="Adding your card to your reservation"
                textVariant={typography.BODY_TEXT}
                color="text-grey"
              />
            </>
          )}
          {reservationCardStatus === "failure" && (
            <>
              <BLIcon
                customIconSource={alertCircle(40, "var(--ion-color-danger)")}
              />
              <BLParagraph
                txt="Failed to add card to booking"
                textVariant={typography.BODY_TEXT}
                color="text-grey"
              />
            </>
          )}
        </IonRow>
      </IonGrid>
    </BLContentCardWrapper>
  );

  return (
    <IonGrid
      className="ion-padding"
      style={{
        width: "100%",
        maxWidth: "568px",
      }}
    >
      <IonRow
        style={{
          marginTop: "40px",
          marginBottom: "10px",
        }}
      >
        <BLParagraph
          txt="Step 1 - Card Authorization"
          textVariant={typography.h5}
          // newClasses={`x-small semibold`}
          //TODO: Unclear typography
        />
      </IonRow>
      <IonRow>{pxpStatusCard}</IonRow>
      {authorizationStatus === "failure" && (
        <>
          <IonRow className="ion-padding-top">
            {/* Change text to come from strapi */}
            <BLParagraph
              txt={staticTexts?.Paragraph_FailedPxpRegistrationInfo}
              textVariant={typography.BODY_TEXT}
              color="text-grey"
              markDown={!!staticTexts?.Paragraph_FailedPxpRegistrationInfo}
            />
          </IonRow>
          <IonRow
            className="ion-justify-content-center bl-margin-t-l"
            style={{ gap: "16px" }}
          >
            <BLButton
              content="Cancel"
              type="underlined"
              onClick={() => pxpPage()}
            />
            <BLButton
              content="Return"
              type="primary"
              onClick={() => {
                changePage({ page: "", details });
                pxpPage(true);
              }}
            />
          </IonRow>
        </>
      )}
      {reservationCardStatus &&
        <>
          <IonRow
            style={{
              marginTop: "16px",
              marginBottom: "10px",
            }}
          >
            <BLParagraph
              txt="Step 2 - Card added to booking"
              textVariant={typography.h5}
              // newClasses={`x-small semibold`}
              //TODO: Unclear typography
            />
          </IonRow>
          <IonRow>{maestroStatusCard}</IonRow>
          {reservationCardStatus === "failure" && (
            <>
              <IonRow className="ion-padding-top">
                <BLParagraph
                  txt={staticTexts?.Paragraph_FailedMaestroRegistrationInfo}
                  textVariant={typography.BODY_TEXT}
                  color="text-grey"
                  markDown={
                    !!staticTexts?.Paragraph_FailedMaestroRegistrationInfo
                  }
                />
              </IonRow>
              <IonRow
                className="ion-justify-content-center bl-margin-t-l"
                style={{ gap: "16px" }}
              >
                <BLButton
                  content="Cancel"
                  type="underlined"
                  onClick={() => redirectBetweenPages(ROUTE_VIEWS.LANDING_PAGE)}
                />
                <BLButton
                  content="Retry"
                  type="icon-text"
                  icon={repeat}
                  onClick={() => retryAddingToMaestro()}
                />
              </IonRow>
            </>
          )}
        </>
      }
    </IonGrid>
  );
};

export default CardAuthorisation;
