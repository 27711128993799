import { IonCol, IonRow } from "@ionic/react";
import { addCircle, closeCircle, pencil } from "ionicons/icons";
import React, { Fragment, useContext } from "react";
import { useDataContext } from "../../../../../../../context/DataContext";
import { SpaContext } from "../../../../../../../context/SpaContext";
import { getCurrentDate } from "../../../../../../../helpingFunctions/dateTimeUtilities";
import BLIcon from "../../../../../../../shared/Ionic/BLIcon";
import BLButton from "../../../../../../../shared/Ionic/Buttons/BLButton";
import BLSelect from "../../../../../../../shared/Ionic/Inputs/BLSelect";
import BLDateTimeFormatParagraph from "../../../../../../../shared/Ionic/Paragraph/BLDateTimeFormatParagraph";
import DaysRowTogetherSelectionLogic from "./DaysRowTogetherSelectionLogic";
import { prepareDaysDropdownList } from "../../../../../../../helpingFunctions/prepareDaysDropdownList";
import { isDesktop } from "../../../../../../../helpingFunctions/utilities";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../../../../../../../constants/constants";

const DaysRowTogetherSelection = () => {
  const { spaSelections, spaAvailability, setSpaSelections, daySelections, isEditMode } = useContext(SpaContext);
  const { reservationDetails } = useDataContext();
  const {
    updateStatus,
    handleTogetherSelection,
    addTogetherDays,
    removeTogetherSelection,
  } = DaysRowTogetherSelectionLogic();

  const getField = (when, index) => {
    const isNotSelecting = when.date !== "" && !when.isEdited;

    return (
      <IonRow
        className={`bl-flex ion-align-items-center bl-margin-b-xs ${
          index !== 0 && "bl-margin-t-s"
        } bl-margin-b-s`}
        style={{
          maxWidth: "350px",
          minWidth: "270px",
        }}
      >
        <IonCol
          className={`bl-flex ion-align-items-center 
          bl-margin-r-s`}
          style={{
            background:
              isDesktop() && isNotSelecting
                ? "var(--ion-color-light)"
                : when.date !== "" && !isDesktop() && "white",
            height: isNotSelecting && "48px",
            borderRadius: isDesktop() && "5px",
          }}
        >
          {isNotSelecting ? (
            <BLDateTimeFormatParagraph
              date={when.date}
              view={MAESTRO_BACKEND_NAMING_VIEWS.SPA}
            />
          ) : (
            <BLSelect
              placeholder="Select a day"
              dropdownList={prepareDaysDropdownList(
                when.date,
                spaSelections,
                0,
                daySelections
              ).filter((day) => day.id >= getCurrentDate())}
              disable={when.action === "remove"}
              value={when.date}
              onChange={(v) => handleTogetherSelection(index, v)}
              onBlur={(v) => {
                if (when.date === v.target.value && v.target.value !== "") {
                  const selectionsCopy = JSON.parse(
                    JSON.stringify(spaSelections)
                  );
                  selectionsCopy[0].on[index].isEdited = false;
                  setSpaSelections(selectionsCopy);
                }
              }}
            />
          )}
        </IonCol>
        <IonCol size="1">
          {isNotSelecting ? (
            <>
              {(!isEditMode ||
                (isEditMode &&
                  spaAvailability &&
                  spaAvailability.hasOwnProperty(when.date))) && (
                <BLIcon
                  iconSource={pencil}
                  color={"secondary"}
                  onClick={() => updateStatus(index)}
                />
              )}
            </>
          ) : index !== 0 ? (
            <BLIcon
              iconSource={closeCircle}
              color={"secondary"}
              onClick={() => removeTogetherSelection(index)}
            />
          ) : null}
        </IonCol>
      </IonRow>
    );
  };

  const daysRowTogetherContent = (
    <>
      {spaSelections[0]?.on.map((when, index) => 
        <Fragment key={when.uniqueKey || index}>
          {getField(when, index)}
        </Fragment>
      )}

      {spaSelections[0]?.on[0].date !== "" &&
        spaSelections[0]?.on.length <= reservationDetails.Nights && (
        <IonRow>
          <IonCol>
            <BLButton
              type="icon-text"
              icon={addCircle}
              iconSize="md"
              content={"Add more days"}
              onClick={() => addTogetherDays()}
            />
          </IonCol>
        </IonRow>
      )}
    </>
  );

  return {
    daysRowTogetherContent,
  };
};

export default DaysRowTogetherSelection;
