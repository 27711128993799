import React from 'react';
import styles from "../BLMarkdown.module.scss";

const MarkdownLink = ({href, children}) => {

  return (
    <a 
      className={styles.MarkdownLink}
      href={href}
      target="_blank" rel="noreferrer"
    >
      {children}
    </a>
  );

};

export default MarkdownLink;