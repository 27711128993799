import { useIonLoading } from "@ionic/react";
import React, { useEffect } from "react";
import styles from "./Loader.module.scss";

const Loader = ({ isOpen = false, message, transparent = false }) => {
  const [present, dismiss] = useIonLoading();

  useEffect(() => {
    if (isOpen) {
      present({
        message: message,
        cssClass:transparent ? styles.Loader_transparent : "",
        spinner: transparent ? "crescent" : "lines",
      });
    } else {
      dismiss();
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      dismiss();
    };
  },[]);
  return <div></div>;
};

export default Loader;
