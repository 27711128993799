import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { alertCircle, informationCircle } from "ionicons/icons";
import BLIcon from "../../BLIcon";
import BLParagraph from "../../Paragraph/BLParagraph";
import BLContentCardWrapper from "../../Wrappers/BLContentCardWrapper";
import { useTheme } from "../../../../hooks/useTheme";

const confObj = {
  warning: {
    boxColor: "warning",
    textColor: "text-grey",
    iconColor: "text-grey",
    iconSource: alertCircle,
    iconSize: "md",
  },
  danger: {
    boxColor: "danger",
    textColor: "light",
    iconColor: "light",
    iconSource: alertCircle,
    iconSize: "md",
  },
  info: {
    boxColor: "info",
    textColor: "tertiary",
    iconColor: "tertiary",
    iconSource: informationCircle,
    iconSize: "md",
  },
  success: {
    boxColor: "success",
    textColor: "light",
    iconColor: "light",
    iconSource: informationCircle,
    iconSize: "md",
  },
  tip: {
    boxColor: "primary",
    textColor: "white",
    customizationStyles: {
      border: "1px solid white",
      boxShadow: "0px 3px 6px var(--ion-color-box-shadow)",
    },
  },
  login_info: {
    boxColor: "primary",
    textColor: "white",
    iconColor: "white",
    iconSource: alertCircle,
    iconSize: "md",
    customizationStyles: {
      border: "1px solid white",
      boxShadow: "0px 3px 6px var(--ion-color-box-shadow)",
    },
  },
  itinerary_info: {
    boxColor: "primary",
    textColor: "white",
    iconColor: "white",
    iconSource: alertCircle,
    iconSize: "sm",
    customizationStyles: {
      borderRadius: "4px",
    },
  },
};

const BLInformationBox = ({ content, type, markdown }) => {
  const { typography } = useTheme();
  return (
    <BLContentCardWrapper
      newClasses={"ion-align-items-center bl-flex"}
      background={confObj[type].boxColor}
      dimensions={{ padding: [type === "tip" || type === "itinerary_info" ? "xs" : "s"] }}
      newStyles={
        confObj[type].customizationStyles
          ? confObj[type].customizationStyles
          : {}
      }
    >
      <IonGrid>
        <IonRow className={"ion-align-items-center"}>
          {confObj[type].iconSource && (
            <IonCol
              className={confObj[type].iconSize === "sm" ? "bl-margin-r-xs" : "bl-margin-r-s"}
              size="auto"
            >
              <BLIcon
                iconSource={confObj[type].iconSource}
                color={confObj[type].iconColor}
                iconSize={confObj[type].iconSize}
              />
            </IonCol>
          )}
          <IonCol>
            <BLParagraph
              txt={content}
              color={confObj[type].textColor}
              newClasses={"break-word"}
              textVariant={typography.BODY_TEXT}
              markDown={content && markdown}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </BLContentCardWrapper>
  );
};

export default BLInformationBox;
