import { IonCol, IonGrid, IonModal, IonRow } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import React from "react";
import BLIcon from "../../../../../shared/Ionic/BLIcon";
import BLImageCarousel from "../../../../../shared/Ionic/Carousel/BLImageCarousel";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import { useTheme } from "../../../../../hooks/useTheme";

const EntertainmentDetailsPopupBox = ({
  showAlert,
  dismissAlert,
  images,
  header,
  longDescription,
  location,
}) => {
  const { typography } = useTheme();
  return (
    <IonModal
      className="bl-popup-details"
      isOpen={showAlert}
      backdropDismiss={false}
      showBackdrop
    >
      <IonRow
        className="bl-flex ion-justify-content-center ion-align-items-center"
      >
        <IonCol
          className="ion-text-center bl-flex ion-align-items-center ion-justify-content-center"
          style={{ minHeight: "50px", marginRight: "40px" }}
        >
          <BLParagraph
            txt={header}
            color="text-grey"
            textVariant={typography.h2}
            // newClasses={`xx-large bold crimson-pro`}
            //TODO: Unclear typography
          />
        </IonCol>
        <IonCol style={{ position: "absolute", right: "40px", width: "0" }}>
          <BLIcon
            iconSource={closeCircle}
            color="secondary"
            iconSize="md"
            onClick={() => dismissAlert(false)}
          />
        </IonCol>
      </IonRow>
      <IonRow
        className="ion-margin-bottom"
        style={{ overflow: "auto", height: "92%" }}
      >
        <IonCol>
          <IonGrid>
            <IonRow className="ion-margin-bottom">
              <IonCol style={{ height: "328px" }}>
                <BLImageCarousel
                  content={images}
                />
              </IonCol>
            </IonRow>

            <IonRow className="bl-margin-xs bl-margin-l-m">
              <IonCol>
                <BLParagraph
                  txt={longDescription}
                  color="text-grey"
                  textVariant={typography.BODY_TEXT}
                  markDown={!!longDescription}
                />
              </IonCol>
            </IonRow>

            {location && (
              <>
                <IonRow className="bl-margin-xs bl-margin-l-m">
                  <IonCol>
                    <BLParagraph
                      txt={
                        <>
                          <BLParagraph
                            txt="Show is being played at "
                            color="text-grey"
                            textVariant={typography.BODY_TEXT}
                          />
                          <BLParagraph
                            txt={location}
                            color="text-grey"
                            textVariant={typography.TEXT_LINK}
                            typographyStyles={{ cursor: "pointer" }}
                            //newClasses={`x-small underline pointer`}
                            //TODO: Unclear typography
                          />
                        </>
                      }
                      color="text-grey"
                      textVariant={typography.BODY_TEXT}
                    />
                  </IonCol>
                </IonRow>
                <IonRow className="bl-margin-xs bl-margin-l-m">
                  <IonCol>
                    <BLParagraph
                      txt="Check in the booking flow for available dates and times."
                      color="text-grey"
                      textVariant={typography.BODY_TEXT}
                    />
                  </IonCol>
                </IonRow>
              </>
            )}
          </IonGrid>
        </IonCol>
      </IonRow>
    </IonModal>
  );
};

export default EntertainmentDetailsPopupBox;
