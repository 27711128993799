import { IonCol, IonGrid, IonModal, IonRow } from "@ionic/react";
import React, { useContext } from "react";

import {
  DINING_PARTY_CREATE_TEXT,
  DINING_PARTY_TERMS_AND_CONDITIONS_ATTR,
} from "../../diningPartyConstants";

import { useDataContext } from "../../../../../context/DataContext";
import { DiningPartyContext } from "../../../../../context/DiningPartyContext";
import { NavigatorContext } from "../../../../../context/NavigatorContext";
import { ROUTE_VIEWS } from "../../../../../routes/routeViews";
import BLButton from "../../../../../shared/Ionic/Buttons/BLButton";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import useDiningPartyRequests from "../../useDiningPartyRequests";
import ActionButtons from "../ActionButtons/ActionButtons";
import DiningTermsAndConditions from "./DiningTermsAndConditions";
import { useTheme } from "../../../../../hooks/useTheme";

const CreateDiningParty = () => {
  const { typography } = useTheme();
  const { createGroup, isGroupNameInvalid, setIsGroupNameInvalid } =
    useDiningPartyRequests();
  const { redirectBetweenPages } = useContext(NavigatorContext);

  const {
    diningPartyFormData,
    isGroupActionLoading,
    setShowDiningPartyParticipantForm,
    diningPartySelections,
  } = useContext(DiningPartyContext);
  const { setReservationDetails } = useDataContext();

  const isTermsAgree =
    diningPartyFormData[DINING_PARTY_TERMS_AND_CONDITIONS_ATTR];

  return (
    <>
      <IonModal
        isOpen={isGroupNameInvalid}
        backdropDismiss={false}
        showBackdrop={true}
        className="bl-popup"
        onDidDismiss={() => setIsGroupNameInvalid(false)}
      >
        <BLParagraph
          txt="Please enter the name for your dining party!"
          color="text-grey"
          textVariant={typography.BODY_TEXT}
          dimensions={{ margin: ["md"], padding: ["t-md", "b-md"] }}
          center
        />

        <IonGrid>
          <IonRow className="ion-text-center ion-justify-content-center">
            <IonCol>
              <BLButton
                content="OK"
                type="outlined"
                dimensions={{
                  margin: ["b-xs", "t-m"],
                }}
                onClick={() => {
                  setIsGroupNameInvalid(false);
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>

      <IonRow>
        <IonCol size="12">
          <DiningTermsAndConditions />
        </IonCol>
      </IonRow>
      <ActionButtons
        actionTxt={DINING_PARTY_CREATE_TEXT}
        cancelFunction={() => {
          if (!diningPartySelections.id) {
            setReservationDetails((prevState) => {
              return {
                ...prevState,
                IsGroupCreator: false,
                PartOfGroup: false,
              };
            });
          }
          setShowDiningPartyParticipantForm(false);
          redirectBetweenPages(ROUTE_VIEWS.DINING);
        }}
        shouldDisable={!isTermsAgree}
        isActionInProgress={isGroupActionLoading}
        actionFunction={createGroup}
        cancelTxt="Go back"
      />
    </>
  );
};

export default CreateDiningParty;
