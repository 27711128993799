import { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import ValidateLoginHook from "../../../hooks/ValidateLoginHook";
import { useDataContext } from "../../../context/DataContext";
import { SideMenuContext } from "../../../context/SideMenuContext";
import {
  CRSNumberAttr,
  hotelAttr,
  surnameAttr,
} from "../LoginPage/constants/constants";
import { ROUTE_VIEWS } from "../../../routes/routeViews";
import RequestHook from "../../../hooks/RequestHook";
import { ERROR_TEXTS } from "../../../constants/errorConstants";
import { PageLoaderContext } from "../../../context/PageLoaderContext";

const LinkReservationsPageLogic = () => {
  const { handleLogin } = ValidateLoginHook();
  const { getItinerary, getReservation } = RequestHook();
  const history = useHistory();

  const { setFullScreenOverlayContent } = useContext(SideMenuContext);
  const { setIsLoaderOpened, setLoaderMessage } = useContext(PageLoaderContext);
  const {
    reservationDetails,
    pagesConfiguration,
    prepLoginPageErrMsg,
  } = useDataContext();
  const [hotelsDropdownOpts, setHotelsDropdownOpts] = useState([]);
  const [isWaitingToLogIn, setIsWaitingToLogin] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const prepareHotelsDropdownOpt = () => {
    try {
      const data = pagesConfiguration?.LinkReservationsPage;

      const hotelsData = data.Hotels;
      hotelsData.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      setHotelsDropdownOpts(hotelsData);
    } catch (error) {
      console.error(error);
    }
  };

  const checkIfReservationDataIsAlreadyLinked = (formData) => {

    if (
      reservationDetails.LinkedReservations.find(
        (res) =>
          res.HotelId === formData[hotelAttr] &&
          res.LastName.toLowerCase() === formData[surnameAttr].toLowerCase() &&
          res.CRSNumber === formData[CRSNumberAttr]
      )
    ) {
      return true;
    }
    return false;
  };

  const handleRequest = async (formData) => {
    setErrMsg("");

    const alreadyLinked = checkIfReservationDataIsAlreadyLinked(formData);
    if (alreadyLinked) {
      setErrMsg(
        ERROR_TEXTS.ALREADY_LINKED_RESERVATION
      );
      return;
    }

    setIsWaitingToLogin(true);
    const res = await handleLogin(formData, "addReservation");
    setIsWaitingToLogin(false);

    if (res.success) {
      setIsLoaderOpened(true);
      setLoaderMessage("Logging you in");
      setFullScreenOverlayContent(null);
      getItinerary();
      getReservation();
      history.push(ROUTE_VIEWS.LANDING_PAGE);
    } else if (res.error) {
      const message = prepLoginPageErrMsg(res.error);
      setErrMsg(message);
    }
  };

  useEffect(() => {
    prepareHotelsDropdownOpt();
  }, []);

  return {
    hotelsDropdownOpts,
    isWaitingToLogIn,
    errMsg,
    setErrMsg,
    handleRequest,
  };
};

export default LinkReservationsPageLogic;
