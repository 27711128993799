import { IonText } from "@ionic/react";
import React from "react";

const DisabledCardText = ({ text, newClasses }) => {
  return (
    <div
      style={{
        backgroundColor: "rgba(97, 97, 97, 0.92)",
        padding: 16,
        position: "absolute",
        bottom: "40%",
        height: "auto",
        width: "100%",
        zIndex: 2,
        color: "var(--ion-color-white)",
        fontWeight: 500,
        letterSpacing: 0.5,
        textAlign: "center",
      }}
      className={newClasses}
    >
      <IonText>{text}</IonText>
    </div>
  );
};

export default DisabledCardText;
