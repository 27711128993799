const DURATIONS = [
  {
    mins: "15",
    display: "15 mins",
  },
  {
    mins: "30",
    display: "30 mins",
  },
  {
    mins: "45",
    display: "45 mins",
  },
  {
    mins: "60",
    display: "1 hour",
  },
  {
    mins: "75",
    display: "1 hour 15 mins",
  },
  {
    mins: "90",
    display: "1 hour 30 mins",
  },
  {
    mins: "720",
    display: "Half Day",
  },
  {
    mins: "1440",
    display: "Full Day",
  },
];

const SORT_OPTIONS = [
  {
    attr: "Price",
    type: "asc",
    value: "Price low to high",
  },
  {
    attr: "Price",
    type: "desc",
    value: "Price high to low",
  },
  {
    attr: "Title",
    type: "asc",
    value: "Title A to Z",
  },
  {
    attr: "Title",
    type: "desc",
    value: "Title Z to A",
  },
];

const TIME_FILTER_OPTIONS = [
  {
    value: "All day activities",
    dayTime: "All day activities"
  },
  {
    startHour: "08:00",
    endHour: "12:00", 
    value: "Morning [8am - 12pm]",
    dayTime: "Morning"
  },
  {
    startHour: "12:00",
    endHour: "18:00", 
    value: "Afternoon [12pm - 6pm]",
    dayTime: "Afternoon"
  },
  {
    startHour: "18:00",
    endHour: "23:59", 
    value: "Evening [6pm - 12am]",
    dayTime: "Evening"
  },
];

export {
  DURATIONS,
  SORT_OPTIONS,
  TIME_FILTER_OPTIONS
};
