import React from "react";
import { IonRow, IonCol } from "@ionic/react";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import { useTheme } from "../../../../hooks/useTheme";

const FolioPostingRow = ({ isHeader, description, refId, price }) => {
  const { typography } = useTheme();
  const colsContentColor = isHeader ? "primary" : "text-grey";

  return (
    <IonRow
      className="bl-padding-xs"
      style={{
        background: isHeader
          ? "var(--ion-color-white)"
          : "var(--ion-color-light)",
        gap: "4px",
      }}
    >
      <IonCol
        style={{
          minWidth: "100px",
          flex: "1 0 calc(calc(1 / var(--ion-grid-columns, 12)) * 100%)",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <BLParagraph
          txt={description}
          color={colsContentColor}
          textVariant={typography.BODY_TEXT}
          //newClasses={"x-small"}
          //newStyles={{ overflowWrap: "anywhere" }}
          //TODO: Unclear typography
        />
      </IonCol>
      <IonCol
        style={{
          minWidth: "100px",
          flex: "0 0 calc(calc(1 / var(--ion-grid-columns, 12)) * 100%)",
        }}
      >
        <BLParagraph
          txt={refId}
          color={colsContentColor}
          textVariant={typography.BODY_TEXT}
          //newClasses={"x-small"}
          //newStyles={{ overflowWrap: "anywhere" }}
          //TODO: Unclear typography
        />
      </IonCol>
      <IonCol
        className="ion-text-end"
        style={{
          maxWidth: "70px",
          minWidth: "66px",
          flex: "0 0 calc(calc(1 / var(--ion-grid-columns, 12)) * 100%)",
        }}
      >
        <BLParagraph
          txt={price}
          color={colsContentColor}
          textVariant={typography.BODY_TEXT}
        />
      </IonCol>
    </IonRow>
  );
};

export default FolioPostingRow;
