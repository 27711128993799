import { person, ticket, informationCircle } from "ionicons/icons";

const LINK_AND_BOOK_ALERT_MESSAGE = `By clicking "Continue" you will be booking the same time slots for the new reservation!`;

const DINING_PARTY_NAME_ATTR = "diningPartyName";
const PARTICIPANT_SURNAME_ATTR = "Surname";
const PARTICIPANT_CRS_NUMBER_ATTR = "CRSNum";
const DINING_PARTY_TERMS_AND_CONDITIONS_ATTR = "isTermsAgreed";

const ADD_MORE_GUESTS = "Add more guests";
const PARTICIPANT_DISPLAY_TITLE = "Confirmed reservation numbers";

const DINING_PARTY_LEADER_TITLE = "Party leader";
const DINING_PARTY_PARTICIPANT_TITLE = "Party guests";

const DINING_PARTY_CHECKBOX_TEXT = "Yes, I agree";
const DINING_PARTY_VALIDATE_TEXT = "Validate";
const DINING_PARTY_CREATE_TEXT = "Create a dining party";
const DINING_PARTY_SAVE_CHANGES_TEXT = "Save changes";

const GLOBAL_DINING_PARTY_ATTRS = Object.freeze({
  ID: "id",
  NAME: "name",
  PARTICIPANTS: "participants",
});

const DINING_PARTY_DETAILS_INITIAL = Object.freeze({
  [GLOBAL_DINING_PARTY_ATTRS.ID]: "",
  [GLOBAL_DINING_PARTY_ATTRS.NAME]: "",
  [GLOBAL_DINING_PARTY_ATTRS.PARTICIPANTS]: [],
});

const DINING_PARTY_MODALS = Object.freeze({
  INIT: "Before Creating Group",
  DINING_PARTY_LEADER: "Dining Party Leader",
  DINING_PARTY_PARTICIPANT: "Dining Party Participant",
});

const DINING_PARTY_ACTION_TYPES = Object.freeze({
  VALIDATE: "Validate",
  CREATE: "Create",
  MODIFY: "MODIFY",
  DELETE: "Delete"
});

const DINING_PARTY_INITIAL_FORM_STATE = {
  [DINING_PARTY_NAME_ATTR]: "",
  [DINING_PARTY_TERMS_AND_CONDITIONS_ATTR]: false,
  [PARTICIPANT_SURNAME_ATTR]: "",
  [PARTICIPANT_CRS_NUMBER_ATTR]: "",
};

const INIT_RESPONSE_DATA = Object.freeze({
  actionType: "",
  isLoading: false,
  status: null,
  message: null,
  infoBoxColor: null,
});

const VALIDATE_INPUTS = {
  mainLabel: "Please enter booking details",
  mainIcon: "",
  inputs: [
    {
      label: "Surname",
      placeHolder: "e.g. Smith",
      icon: person,
      type: "text",
      labelNewClasses: "x-small",
      labelColor: "text-grey",
      dataAttrType: PARTICIPANT_SURNAME_ATTR,
    },
    {
      label: "Holiday Reference Number",
      placeHolder: "e.g. XYZ908756",
      icon: ticket,
      type: "text",
      labelNewClasses: "x-small",
      labelColor: "text-grey",
      dataAttrType: PARTICIPANT_CRS_NUMBER_ATTR,
    },
  ],
};

const DINING_PARTY_GROUP_LEADER_FORM = [VALIDATE_INPUTS];

const DINING_PARTY_NAME_INPUT = {
  mainLabel: "Please enter a dining party name",
  mainIcon: informationCircle,
  inputs: [
    {
      placeHolder: "e.g. Tom’s group",
      type: "text",
      dataAttrType: DINING_PARTY_NAME_ATTR,
    },
  ],
};
const DINING_PARTY_INIT_FORM = [DINING_PARTY_NAME_INPUT, VALIDATE_INPUTS];

const INIT_FORM_CONTENT = {
  data: [],
  isEntireForm: false,
};

const CREATE_FORM_CONTENT = {
  data: DINING_PARTY_INIT_FORM,
  isEntireForm: true,
};

const MODIFY_DINING_PARTY_FORM_CONTENT = {
  data: DINING_PARTY_GROUP_LEADER_FORM,
  isEntireForm: true,
};

export {
  DINING_PARTY_DETAILS_INITIAL,
  GLOBAL_DINING_PARTY_ATTRS,
  INIT_FORM_CONTENT,
  CREATE_FORM_CONTENT,
  MODIFY_DINING_PARTY_FORM_CONTENT,
  DINING_PARTY_INIT_FORM,
  DINING_PARTY_GROUP_LEADER_FORM,
  DINING_PARTY_MODALS,
  DINING_PARTY_NAME_ATTR,
  PARTICIPANT_SURNAME_ATTR,
  PARTICIPANT_CRS_NUMBER_ATTR,
  ADD_MORE_GUESTS,
  PARTICIPANT_DISPLAY_TITLE,
  DINING_PARTY_LEADER_TITLE,
  DINING_PARTY_PARTICIPANT_TITLE,
  DINING_PARTY_CHECKBOX_TEXT,
  DINING_PARTY_VALIDATE_TEXT,
  DINING_PARTY_CREATE_TEXT,
  DINING_PARTY_TERMS_AND_CONDITIONS_ATTR,
  DINING_PARTY_ACTION_TYPES,
  DINING_PARTY_SAVE_CHANGES_TEXT,
  DINING_PARTY_INITIAL_FORM_STATE,
  DINING_PARTY_NAME_INPUT,
  VALIDATE_INPUTS,
  LINK_AND_BOOK_ALERT_MESSAGE,
  INIT_RESPONSE_DATA
};
