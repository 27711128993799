import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { TreatsContext } from "../../../context/TreatsContext";

const TreatYourselfPageLogic = () => {
  const location = useLocation();
  const { categoryId } = useParams();
  const { treatsDetails } = useContext(TreatsContext);
  const [formatedTreats, setFormatedTreats] = useState(null);

  useEffect(() => {
    // Get maestro categories
    if (categoryId) {
      const foundCategory = treatsDetails?.Categorized.find(
        (category) => category.categoryId === Number(categoryId)
      );
      foundCategory?.items.sort(
        (a, b) =>
          (a.itemOrder === null) - (b.itemOrder === null) ||
          a.itemOrder - b.itemOrder ||
          (a.Title > b.Title ? 1 : -1)
      );
      setFormatedTreats(foundCategory);
    }
  }, [location, treatsDetails]);

  return {
    formatedTreats,
  };
};

export default TreatYourselfPageLogic;
