import React from "react";
import { FormControl, TextField } from "@mui/material";

const BLInput = ({
  placeholder,
  type,
  value,
  disableInput,
  name,
  readonly,
  required,
  onChange,
  boxHeightClass,
  classes,
  inputClasses,
}) => {
  return (
    <FormControl fullWidth className={`${classes}`}>
      <TextField
        className={`${inputClasses} ${`bl-box-${
          boxHeightClass ? boxHeightClass : "height-default"
        }`}`}
        value={value}
        placeholder={placeholder}
        type={type}
        required={required}
        name={name}
        disabled={disableInput}
        onChange={onChange}
        InputProps={{
          readOnly: readonly,
        }}
        sx={{
          backgroundColor: "white",
          borderRadius: "7px",
          color: "var(--ion-color-text-grey)",
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            height: "inherit",
            borderRadius: "inherit",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid var(--ion-color-secondary) !important",
          },
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            paddingInlineStart: "calc(16px + var(--ion-safe-area-left, 0px))",
            paddingTop: 0,
            paddingBottom: 0,
            height: "inherit",
          },
        }}
      />
    </FormControl>
  );
};

export default BLInput;
