import React from "react";
import {
  differenceInHours,
  format,
  differenceInCalendarDays,
  isPast,
  subDays,
  add,
  isBefore,
  eachDayOfInterval,
  getMonth,
  getYear,
  parseISO,
} from "date-fns";
import { SERVICES_TYPES } from "../constants/constants";

const MINUTE_IN_SECONDS = 60;
const SECOND_IN_MILLISECONDS = 1000;

const checkCurrentTime = (arrivalDate) => {
  return differenceInHours(new Date(arrivalDate), new Date()) <= 0;
};

const convertUTCDateToLocal = (date) => {
  const parsedInputDate = parseISO(date);
  const correctedMonth = parsedInputDate.getMonth() + 1;
  const month = correctedMonth > 9 ? correctedMonth : `0${correctedMonth}`;

  const correctedDay = parsedInputDate.getDate();
  const day = correctedDay > 9 ? correctedDay : `0${correctedDay}`;
  return `${parsedInputDate.getFullYear()}-${month}-${day}`;
};

/**
	getDateInterval
	
	@brief Retrieves human friendly format of a interval between 2 dates
	@params
		* from - String formated date
		* to - String formated date
	@return html object formated as 1st - 6th May 2023 or 30th April - 6th May 2023 or 29 Dec 2022 - 5th Jan 2023
**/

const getDateInterval = (from, to) => {
  try {
    if (!from || !to) return;

    const fromDateobj = new Date(from);
    const toDateObj = new Date(to);

    const fromDateOnly = new Date(fromDateobj.valueOf() + fromDateobj.getTimezoneOffset() * MINUTE_IN_SECONDS * SECOND_IN_MILLISECONDS);
    const toDateOnly = new Date(toDateObj.valueOf() + toDateObj.getTimezoneOffset() * MINUTE_IN_SECONDS * SECOND_IN_MILLISECONDS);

    const fromSUP = format(fromDateOnly, "do").slice(-2);
    const toSUP = format(toDateOnly, "do").slice(-2);

    /**
     * The problem
     Previous solutions was utilizing the 'format' method from date-fns. The problem there was, that
     the 'format' method always converts the date in the local time zone;

     * The solution
     For our current needs (preserved the date, ignoring the timezone),
     the 'format' method could work if we manipulate the parsed Date (
      subtract the time zone offset of our local time zone via the getTimezoneOffset method OR use "date.substring(0, 10)T00:00:00"
     ) (as we can see so above)

     * The alternatives
     Please note the date-fns 'parseISO' method does not produce the expected result when changing timezones
     and
     getting the UTC Date from the Date object directly would work, but that work around would be to complex and pointless to execute
    **/ 
    const fromDayOfMonth = format(fromDateOnly, "d");
    const toDayOfMonth = format(toDateOnly, "d");

    const fromMonth = format(fromDateOnly, "MMM");
    const toMonth = format(toDateOnly, "MMM");

    const fromYear = format(fromDateOnly, "yyy");
    const toYear = format(toDateOnly, "yyy");

    return (
      <>
        {fromDayOfMonth}
        <sup>{fromSUP}</sup>
        {getMonth(fromDateOnly) !== getMonth(toDateOnly) && " " + fromMonth}
        {getYear(fromDateOnly) !== getYear(toDateOnly) && " " + fromYear}
        {" - "}
        {toDayOfMonth}
        <sup>{toSUP}</sup> {toMonth} {toYear}
      </>
    );
  } catch (e) {
    console.error(
      `Error in getDateInterval func in dateTimeUtilities.js - ${
        e.message
      } for input ${(from, to)}`
    );
  }
};

/**
	getCurrentDate
	
	@brief Retrieves current date string
	
	@return String of the current date in the format of yyyy-MM-dd like "2023-05-22"
**/

const getCurrentDate = () => {
  try {
    return format(new Date(), "yyyy-MM-dd");
  } catch (e) {
    console.error(
      `Error in getCurrentDate func in dateTimeUtilities.js - ${
        e.message
      }`
    );
  }
};

/**
	formatTime
	
	@brief Retrieves time string in a specified format
	@params
		* date - String formated date
		* is12HoursClock - Boolean
        * formatSchema - String of time schema equivalent to the ones in date-fns library - https://date-fns.org/v2.0.0-alpha.9/docs/format
	@return String of formated time
**/

const formatTime = (date, is12HoursClock, formatSchema = "HH:mm") => {
  try {
    if (!date || date.includes("undefined")) return;

    if (is12HoursClock) {
      return format(new Date(date), "p");
    } else {
      return format(new Date(date), formatSchema);
    }
  } catch (e) {
    console.error(
      `Error in formatTime func in dateTimeUtilities.js - ${
        e.message
      } for input ${(date, is12HoursClock, formatSchema)}`
    );
  }
};

/**
	getDayPeriod
	
	@brief Retrieves period (AM/PM) of a given day
	@params
		* date - String formated date
	@return String of period of a day AM or PM
**/

const getDayPeriod = (date) => {
  try {
    if (!date) return;

    return format(new Date(date), "a");
  } catch (e) {
    console.error(
      `Error in getDayPeriod func in dateTimeUtilities.js - ${e.message} for input ${date}`
    );
  }
};

/**
	getDiffTweenTodayTo
	
	@brief Retrieves the day differences between today and a given day
	@params
		* to - String formated date
		* subtractDayNum - Number via which the end date (to) is manipulated
	@return Number of day difference
**/

const getDiffTweenTodayTo = (to, subtractDayNum = 0) => {
  try {
    if (!to) return;

    const today = new Date();
    const toAdjusted = subDays(new Date(to), subtractDayNum);
    return differenceInCalendarDays(toAdjusted, today);
  } catch (e) {
    console.error(
      `Error in getDiffTweenTodayTo func in dateTimeUtilities.js - ${
        e.message
      } for input ${(to, subtractDayNum)}`
    );
  }
};

/**
	getDayOfStay
	
	@brief Retrieves human friendly format of a given numbered day
	@params
		* stayDates - Array of days in the format yyyy-MM-dd
		* searchedDate - String of a day in the format yyyy-MM-dd
	@return String in the format of 'Day 2 -'
**/

const getDayOfStay = (stayDates, searchedDate, isBreakfast=false) => {
  const foundIndex = stayDates.findIndex((row) => row === searchedDate);

  if (foundIndex >= 0) {
    return `Day ${isBreakfast ? foundIndex + 2 : foundIndex + 1} - `;
  }
};

/**
	formatDateDetailed
	
	@brief Retrieves a human friendly format of given date in detailed view
	@params
		* stayDates - Array of days in the format yyyy-MM-dd
		* searchedDate - String of a day in the format yyyy-MM-dd
    * ignoreDayOfStay - Boolean which will ignore the dayOfStay part
	@return String like Day 1 - Mon 21st Aug
**/

const formatDateDetailed = (stayDates, searchedDate, ignoreDayOfStay, isBreakfast=false) => {
  try {
    if (!searchedDate) return;

    const searchedDateObj = new Date(searchedDate.substring(0, 16));
    let dayOfStay = "";
    if (!ignoreDayOfStay) {
      dayOfStay = getDayOfStay(stayDates, searchedDate.substring(0, 10), isBreakfast);
    }
    const searchedMonth = format(searchedDateObj, "MMM");
    const searchedDayOfWeek = format(searchedDateObj, "E");
    const searchedDay = format(searchedDateObj, "d");
    const searchDateSUP = format(searchedDateObj, "do").slice(-2);

    return (
      <>
        {!ignoreDayOfStay && (
          <>
            {dayOfStay}{" "}
          </>
        )}
        {searchedDayOfWeek} {searchedDay}
        <sup>{searchDateSUP}</sup> {searchedMonth}
      </>
    );
  } catch (e) {
    console.error(
      `Error in formatDateDetailed func in dateTimeUtilities.js - ${
        e.message
      } for input ${(stayDates, searchedDate, ignoreDayOfStay)}`
    );
  }
};

/**
	formatDate
	
	@brief Retrieves a human friendly format of given date in simple view
	@params
		* date - String of a day in the format yyyy-MM-dd
	@return String like Mon 21st Aug
**/

const formatDate = (date) => {
  try {
    if (!date) return;

    const searchedDateObj = new Date(date.substring(0, 16));
    const searchedMonth = format(searchedDateObj, "MMM");
    const searchedDayOfWeek = format(searchedDateObj, "E");
    const searchedDay = format(searchedDateObj, "d");
    const searchDateSUP = format(searchedDateObj, "do").slice(-2);

    return `${searchedDayOfWeek} ${searchedDay}${searchDateSUP} ${searchedMonth}`;
  } catch (e) {
    console.error(
      `Error in formatDate func in dateTimeUtilities.js - ${
        e.message
      } for input ${(date)}`
    );
  }
};

/**
	formatTimeDetailed
	
	@brief Retrieves a human friendly format of given dates time in detailed view
	@params
		* stayDates - Array of days in the format yyyy-MM-dd
		* searchedDate - String of a day in the format yyyy-MM-dd
	@return String like Day 1 - 11:00 AM
**/

const formatTimeDetailed = (stayDates, searchedDate) => {
  try {
    if (!searchedDate) return;

    const dayOfStay = getDayOfStay(stayDates, searchedDate.substring(0, 10));

    return (
      <>
        {dayOfStay}{" "}
        {formatTime(searchedDate.substring(0, 16), true)}
      </>
    );
  } catch (e) {
    console.error(
      `Error in formatTimeDetailed func in dateTimeUtilities.js - ${
        e.message
      } for input ${(stayDates, searchedDate)}`
    );
  }
};

/**
	getDayOfWeek
	
	@brief Retrieves a detailed view of day by given date
	@params
		* day - String of a day in the format yyyy-MM-dd
	@return String like Friday
**/

const getDayOfWeek = (day, dayOfWeekFormat = "EEEE") => {
  try {
    if (!day) return;

    const searchedDateObj = new Date(day.substring(0, 16));
    return format(searchedDateObj, dayOfWeekFormat);
  } catch (e) {
    console.error(
      `Error in getDayOfWeek func in dateTimeUtilities.js - ${e.message} for input ${day}`
    );
  }
};

/**
	isDateInThePast
	
	@brief Verifies if a given date is in the past
	@params
		* date - String of a day in the format yyyy-MM-dd
	@return Boolean true/false
**/

const isDateInThePast = (date) => {
  try {
    if (!date) return;

    return isPast(new Date(date));
  } catch (e) {
    console.error(
      `Error in isDateInThePast func in dateTimeUtilities.js - ${e.message} for input ${date}`
    );
  }
};

/**
	isDateWithinRange
	
	@brief Verifies if a given date is between today and a number of days in the future
	@params
		* date - String of a day in the format yyyy-MM-dd
        * numOfDaysInFuture - Number
	@return Boolean true - if the given date is before the future date | false - if the given date is after the future date
**/

const isDateWithinRange = (date, numOfDaysInFuture) => {
  try {
    if (!date) return;

    const dateObj = new Date(date);

    const futureDate = add(new Date(), {
      years: 0,
      months: 0,
      weeks: 0,
      days: numOfDaysInFuture,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });

    return isBefore(dateObj, futureDate);
  } catch (e) {
    console.error(
      `Error in isDateWithinRange func in dateTimeUtilities.js - ${
        e.message
      } for input ${(date, numOfDaysInFuture)}`
    );
  }
};

/**
	getDatesBetween
	
	@brief Retrieves all the days between two dates in the format yyyy-MM-dd
	@params
		* from - String of a day in the format yyyy-MM-dd
		* to - String of a day in the format yyyy-MM-dd
		* view - String of current page we are currently in
	@return Array of strings with dates in the format yyyy-MM-dd
**/

const getDatesBetween = (from, to, view) => {
  try {
    if (!from || !to) return;

    const intervalSimple = [];
    const dateObjInterval = eachDayOfInterval({
      start: new Date(from),
      end: new Date(to),
    });

    switch (view) {
      case SERVICES_TYPES.BREAKFAST:
        dateObjInterval.shift();
        dateObjInterval.forEach((row) => {
          intervalSimple.push(format(row, "yyyy-MM-dd"));
        });
        return intervalSimple;
      case SERVICES_TYPES.DINNER:
        dateObjInterval.pop();
        dateObjInterval.forEach((row) => {
          intervalSimple.push(format(row, "yyyy-MM-dd"));
        });
        return intervalSimple;
      case SERVICES_TYPES.ENTERTAINMENT:
        dateObjInterval.pop();
        dateObjInterval.forEach((row) => {
          intervalSimple.push(format(row, "yyyy-MM-dd"));
        });
        return intervalSimple;
      default:
        dateObjInterval.forEach((row) => {
          intervalSimple.push(format(row, "yyyy-MM-dd"));
        });
        return intervalSimple;
    }
  } catch (e) {
    console.error(
      `Error in getDatesBetween func in dateTimeUtilities.js - ${
        e.message
      } for input ${(from, to, view)}`
    );
  }
};

const isChristmassEve = (date) => {
  return date.includes("-12-25");
};

const isNewYearsEve = (date) => {
  return date.includes("-12-31");
};

export {
  getCurrentDate,
  checkCurrentTime,
  getDateInterval,
  formatTime,
  getDayPeriod,
  getDiffTweenTodayTo,
  formatDateDetailed,
  formatDate,
  formatTimeDetailed,
  getDayOfWeek,
  isDateInThePast,
  isDateWithinRange,
  getDatesBetween,
  getDayOfStay,
  isChristmassEve,
  isNewYearsEve,
  convertUTCDateToLocal,
};
