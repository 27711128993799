import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ActivityContext } from "../../../../context/ActivityContext";
import { useDataContext } from "../../../../context/DataContext";
import { NavigatorContext } from "../../../../context/NavigatorContext";
import {
  formatDate,
  formatTime,
} from "../../../../helpingFunctions/dateTimeUtilities";
import { formatPrice } from "../../../../helpingFunctions/formatPrice";
import { replacePlaceholders } from "../../../../helpingFunctions/replacePlaceholders";
import { ROUTE_VIEWS } from "../../../../routes/routeViews";
import BLAlertPopUp from "../../../../shared/Ionic/Boxes/Popup/BLAlertPopUp";
import BLSelect from "../../../../shared/Ionic/Inputs/BLSelect";
import BookedSummaryScreen from "../../../../shared/Ionic/ServicesComponents/BookedSummaryScreen";
import BookingSelectionHeaderDesktop from "../../../../shared/Ionic/ServicesComponents/BookingSelectionHeaderDesktop";
import BookingSelectionHeaderMobile from "../../../../shared/Ionic/ServicesComponents/BookingSelectionHeaderMobile";
import ConfirmationScreen from "../../../../shared/Ionic/ServicesComponents/ConfirmationScreen";
import BLContentCardWrapper from "../../../../shared/Ionic/Wrappers/BLContentCardWrapper";
import ActivityExpandableRowsContent from "./ActivityExpandableRowsContent";
import { isDesktop } from "../../../../helpingFunctions/utilities";
import { MAESTRO_BACKEND_NAMING_VIEWS } from "../../../../constants/constants";

const ActivityBookingFlow = ({
  handleActivityBookingRQ,
  retryFailsFunc,
  isFirstTimeBooker,
  isEditActionOnlyCancelled,
  hasCancellationPolicy,
  setHasCancellationPolicy,
  cancellationPolicyAlertsLength,
  cancellationPolicyAlerts,
  setCancellationPolicyAlerts,
}) => {
  const history = useHistory();

  const [clickedRow, setClickedRow] = useState(null);
  const [showCancellationPolicyAlert, setShowCancellationPolicyAlert] =
    useState(false);
  const [selectedCancellationReason, setSelectedCancellationReason] =
    useState(null);
  const { redirectBetweenPages } = useContext(NavigatorContext);
  const {
    activitySelections,
    setActivitySelections,
    cancelChangesHandler,
    selectedService,
    setShowBookingSection,
    confirmationScreenSelections,
    setConfirmationScreenSelections,
    isProcessingBookings,
    setPriceMsg,
    daySelections,
    isEditMode,
    setIsEditMode,
  } = useContext(ActivityContext);
  const {
    dynamicTexts,
    cancellationReasons,
  } = useDataContext();

  useEffect(() => {
    setPriceMsg(null);
  }, []);

  useEffect(() => {
    if (cancellationPolicyAlerts.length > 0) {
      setShowCancellationPolicyAlert(true);
      setSelectedCancellationReason(null);
    } else {
      setShowCancellationPolicyAlert(false);
    }
  }, [cancellationPolicyAlerts]);

  const replaceMsgPlaceholders = (params) => {
    if (!params) {
      return "";
    }

    const activityDate = formatDate(params.date);
    const time = formatTime(params.date.slice(0, 16), "h:mm");
    const price = params.activityPrice * params.percentage;

    const text =
      dynamicTexts?.Activities?.CancellationDetails
        ?.Popup_InsideActivityCancellationWindow || "";
    const result = replacePlaceholders(text, {
      SERVICE_NAME: params.serviceName,
      DATE: activityDate,
      TIME: time,
      PRICE: formatPrice(price),
      PERCENTAGE: params.percentage * 100 + "%",
      POLICY_HOURS: params.policyHours,
    });

    return result;
  };

  const getDropdownReasons = () => {
    const options = [];
    const buildingCancellationReasons = cancellationReasons.Activity.filter(
      (reason) => reason.BuildingCode === selectedService.BuildingCode
    );
    for (const reason of buildingCancellationReasons) {
      options.push({
        id: reason.ReasonCode,
        Title: reason.ReasonDescription,
      });
    }
    return options;
  };

  return (
    <BLContentCardWrapper
      background={isDesktop() ? "light" : "white"}
      newStyles={{ height: "100%" }}
      dimensions={{ padding: ["t-l", "b-l", "l-m"] }}
    >
      <IonGrid
        className="bl-margin-b-s"
        style={{
          height: "100%",
          overflow: "auto",
          overflowX: "hidden",
          paddingRight: "25px",
          width: "100%",
        }}
      >
        <IonRow>
          <IonCol>
            {isDesktop() ? (
              <BookingSelectionHeaderDesktop
                serviceDetails={selectedService}
                onXSignClick={() => {
                  if (isEditMode) {
                    cancelChangesHandler();
                  }
                  setShowBookingSection(false);
                  setConfirmationScreenSelections(null);
                  setIsEditMode(false);
                  setClickedRow(null);
                  redirectBetweenPages(isEditMode && history.location.state.isItineraryRedirect ? ROUTE_VIEWS.ITINERARY : ROUTE_VIEWS.ACTIVITIES);
                }}
                hideAddInfo={isFirstTimeBooker || isEditMode}
              />
            ) : (
              <BookingSelectionHeaderMobile
                serviceDetails={selectedService}
                onXSignClick={() => {
                  if (isEditMode) {
                    cancelChangesHandler();
                  }
                  setShowBookingSection(false);
                  setConfirmationScreenSelections(null);
                  setIsEditMode(false);
                  setClickedRow(null);
                  redirectBetweenPages(isEditMode && history.location.state.isItineraryRedirect ? ROUTE_VIEWS.ITINERARY : ROUTE_VIEWS.ACTIVITIES);
                }}
                hideAddInfo={isFirstTimeBooker || isEditMode}
              />
            )}
          </IonCol>
        </IonRow>
        {(isProcessingBookings && !hasCancellationPolicy) ||
          confirmationScreenSelections ?
          (
            <ConfirmationScreen
              serviceDetails={selectedService}
              retryFailsFunc={retryFailsFunc}
              isEditActionOnlyCancelled={isEditActionOnlyCancelled}
              source={MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY}
              confirmationScreenSelections={confirmationScreenSelections}
              setConfirmationScreenSelections={setConfirmationScreenSelections}
              isProcessingBookings={isProcessingBookings}
              daySelections={daySelections}
              setIsEditMode={setIsEditMode}
              setPriceMsg={setPriceMsg}
            />
          ) : (
            <>
              {isFirstTimeBooker || isEditMode ? (
                <ActivityExpandableRowsContent
                  clickedRow={clickedRow}
                  setClickedRow={setClickedRow}
                />
              ) : (
                <BookedSummaryScreen
                  serviceDetails={selectedService}
                  source={MAESTRO_BACKEND_NAMING_VIEWS.ACTIVITY}
                  handleBookingRQ={handleActivityBookingRQ}
                  selections={activitySelections}
                  setSelections={setActivitySelections}
                  cancellationPolicyAlertsLenght={cancellationPolicyAlertsLength}
                  hasCancellationPolicy={hasCancellationPolicy}
                  setHasCancellationPolicy={setHasCancellationPolicy}
                  isProcessingBookings={isProcessingBookings}
                  setIsEditMode={setIsEditMode}
                  daySelections={daySelections}
                />
              )}
            </>
          )}
      </IonGrid>
      {showCancellationPolicyAlert && (
        <BLAlertPopUp
          title={
            cancellationPolicyAlertsLength > 1
              ? `Late cancellation (${cancellationPolicyAlertsLength -
              cancellationPolicyAlerts.length +
              1
              }/${cancellationPolicyAlertsLength})`
              : "Late cancellation"
          }
          description={replaceMsgPlaceholders(
            cancellationPolicyAlerts[0]?.cancellationPolicyMessage
          )}
          isOpen={showCancellationPolicyAlert}
          confirmBtnLabel="Confirm"
          dismissBtnType="underlined"
          confirmBtnType="primary"
          dismissBtnLabel="Skip"
          dismissHandler={() => {
            setHasCancellationPolicy(false);
            const cancellationPolicyAlertsCopy = JSON.parse(
              JSON.stringify(cancellationPolicyAlerts)
            );
            const activity = cancellationPolicyAlertsCopy.shift();
            if (activity) {
              const date = activity.Date.slice(0, 10);
              const time = activity.Date.split("T")[1].slice(0, 5);
              const activitySelectionsCopy = JSON.parse(
                JSON.stringify(activitySelections)
              );
              const activityToCancel = activitySelectionsCopy[0].on.find(
                (item) =>
                  item.bookedDate === date &&
                  item.bookedTime.slice(0, 5) === time
              );
              activityToCancel.action = "none";

              //When editing, return the changed time and date to the initial values
              if (activityToCancel.bookedDate !== activityToCancel.date) {
                activityToCancel.date = activityToCancel.bookedDate;
              }
              if (activityToCancel.bookedTime !== activityToCancel.time) {
                activityToCancel.time = activityToCancel.bookedTime;
              }

              setActivitySelections(activitySelectionsCopy);
              if (cancellationPolicyAlertsCopy.length === 0) {
                if (
                  activitySelectionsCopy[0].on.some(
                    (item) => item.action !== "none"
                  )
                ) {
                  handleActivityBookingRQ(activitySelectionsCopy);
                }
                setCancellationPolicyAlerts([]);
              } else {
                setCancellationPolicyAlerts(cancellationPolicyAlertsCopy);
              }
              setShowCancellationPolicyAlert(false);
            }
          }}
          confirmHandler={async () => {
            setHasCancellationPolicy(false);
            const cancellationPolicyAlertsCopy = JSON.parse(
              JSON.stringify(cancellationPolicyAlerts)
            );
            const activity = cancellationPolicyAlertsCopy[0];
            const date = activity.Date.slice(0, 10);
            const time = activity.Date.split("T")[1].slice(0, 5);
            const activitySelectionsCopy = JSON.parse(
              JSON.stringify(activitySelections)
            );
            const activityToCancel = activitySelectionsCopy[0].on.find(
              (item) =>
                item.bookedDate === date && item.bookedTime.slice(0, 5) === time
            );
            activityToCancel.ReasonCode = selectedCancellationReason;
            setActivitySelections(activitySelectionsCopy);
            if (cancellationPolicyAlertsCopy.length === 1) {
              setCancellationPolicyAlerts([]);
              await handleActivityBookingRQ(activitySelectionsCopy);
            } else if (cancellationPolicyAlertsCopy.length > 1) {
              cancellationPolicyAlertsCopy.shift();
              setCancellationPolicyAlerts(cancellationPolicyAlertsCopy);
            }

            setShowCancellationPolicyAlert(false);
          }}
          confirmBtnDisabled={!selectedCancellationReason}
        >
          <IonGrid>
            <IonRow>
              <BLSelect
                placeholder="Select cancellation reason"
                dropdownList={getDropdownReasons()}
                value={selectedCancellationReason}
                onChange={(e) => setSelectedCancellationReason(e.target.value)}
              />
            </IonRow>
          </IonGrid>
        </BLAlertPopUp>
      )}
    </BLContentCardWrapper>
  );
};

export default ActivityBookingFlow;
