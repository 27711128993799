import React from "react";

import styles from "./information-alert.module.css";

const Information = ({
  txt,
  backgroundColor,
  width,
  height,
  lineBackgroundColor,
  marginLeft,
  flexDirection = "column",
  fullyColoredClass,
  icon,
  pageWidth,
  new_styles,
  className
}) => {
  return (
    <div
      className={`${
        styles.information
      } ${className}`}
      style={{
        width,
        height,
        backgroundColor,
        txt,
        ...new_styles
      }}
    >
      {!fullyColoredClass ? (
        <div
          className={styles.line}
          style={{
            backgroundColor: lineBackgroundColor,
            marginBottom: pageWidth < 1325 ? "0" : "25px",
          }}
        />
      ) : null}
      <div
        className={`
          ${pageWidth < 1325
      ? styles.informationWrapperMobile
      : styles.informationWrapperDesktop} ${styles[fullyColoredClass]}
        `}
        style={{ flexDirection }}
      >
        <div className={styles.information_icon_wrapper}>
          <img
            className={styles.information_icon}
            src={`/img/info_icons/${icon}`}
            alt=""
          />
        </div>

        <p className={styles.information_msg} style={{ marginLeft }}>
          {txt}
        </p>
      </div>
    </div>
  );
};

export default Information;
